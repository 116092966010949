import React from 'react';
import Alert from '../../../App/Components/Alert';
import WarehouseEntryForm from './WarehouseEntryForm';
import useWarehouseEntry from '../../hooks/useWarehouseEntry';
import { updateWareHouseEntryAction } from '../../../App/Redux/materialValuesStor/warehouseEntrySlices/useAction';
import { useDispatch } from 'react-redux';

const EventEditForm = ({ handleClose, rowData, params }) => {
  const { matInputDocID, excelInvoiceID, partnerID } = rowData || {};
  const { errorMess, setErrorMess, addWarehouseEntry, warehouseEntryForm } =
    useWarehouseEntry();

  const dispatch = useDispatch();
  const onUpdateHandler = async (values) => {
    const modifiedData = {
      matInput: {
        ...values,
        editInput: true,
        docNum: Number(values.docNum),
      },
      inputs: warehouseEntryForm?.inputs,
    };

    try {
      await addWarehouseEntry(modifiedData, params, handleClose);
      dispatch(updateWareHouseEntryAction(modifiedData));
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <>
      <WarehouseEntryForm
        matInputDocID={matInputDocID}
        excelInvoiceID={excelInvoiceID}
        partnerID={partnerID}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventEditForm;
