import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';

export const fetchAdditionsTypes = async (currentCompanyID) => {
  try {
    const response = await getRequest(
      `getASTypes?companyID=${currentCompanyID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addAdditionsTypes = async (additionsType) => {
  try {
    const response = await postRequest('addASType', {
      ...additionsType,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding additionsType:', error);
    throw error;
  }
};

export const updateEAdditionsTypes = async (newAdditionsType) => {
  try {
    const response = await putRequest(`editASType`, newAdditionsType);
    return response.data;
  } catch (error) {
    console.error('Error updating additionsType:', error);
    throw error;
  }
};

export const deleteAdditionsTypes = async (salaryTypeID) => {
  try {
    const response = await deleteRequest(`deleteASType/${salaryTypeID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting additionsType:', error);
    throw error;
  }
};

export const fetchAdditionsFormData = async (
  currentCompanyID,
  salaryTypeID,
) => {
  if (!currentCompanyID) {
    return;
  }
  try {
    const response = await getRequest(
      `getASType?companyID=${currentCompanyID}&salaryTypeID=${salaryTypeID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
