import React from 'react';

const InputTitle = ({ requiredFiled = true, inputTitle = '' }) => {
  return (
    <div className="G-s-input-title">
      {requiredFiled && <span className="G-asterisk">*</span>}
      {inputTitle && <p className="G-title-p">{inputTitle}</p>}
    </div>
  );
};

export default InputTitle;
