import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNonWorkingDayAction,
  fetchNonWorkingDayFormAction,
  fetchNonWorkingDaysAction,
  updateNonWorkingDayAction,
} from '../../App/Redux/SalaryStor/nonWorkingDays/useAction';
import { useEffect, useState } from 'react';
import {
  addNewNonWorkingDay,
  deleteNonWorkingDays,
  fetchNewNonWorkingDays,
  fetchNonWorkingDayForm,
  fetchNonWorkingDays,
  updateNonWorkingDays,
} from '../services/fetchNonWorkingDays';

const useNonWorkingDays = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState('');
  const { currentCompanyID } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const nonWorkingDays = useSelector(
    (state) => state.nonWorkingDays?.nonWorkingDays,
  );
  const nonWorkingDayForm = useSelector(
    (state) => state.nonWorkingDays?.nonWorkingDayForm,
  );

  const getNonWorkingDaysData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchNonWorkingDays(currentCompanyID);
      dispatch(fetchNonWorkingDaysAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getNonWorkingDaysFormData = async (nwDayID) => {
    setIsLoading(true);
    try {
      const data = await fetchNonWorkingDayForm(currentCompanyID, nwDayID);
      dispatch(fetchNonWorkingDayFormAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addNonWorkingDay = async (newNonWorkingDay, handleClose) => {
    try {
      const { errorCode, message } = await addNewNonWorkingDay(
        newNonWorkingDay,
      );
      if (message) {
        setErrorMess(message);
      }
      if (errorCode === 0) {
        getNonWorkingDaysData();
        handleClose();
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateNonWorkingDay = async (newNonWorkingDay, handleClose) => {
    setIsLoading(true);

    try {
      const { errorCode, message } = await updateNonWorkingDays(
        newNonWorkingDay,
      );
      if (message) {
        setErrorMess(message);
      }
      if (errorCode === 0) {
        dispatch(updateNonWorkingDayAction(newNonWorkingDay));
        handleClose();
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeNonWorkingDay = async (nwDayID, handleClose) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await deleteNonWorkingDays(nwDayID);
      if (message) {
        setErrorMess(message);
      }
      if (errorCode === 0) {
        dispatch(deleteNonWorkingDayAction(nwDayID));
        handleClose();
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateNonWorkDays = async () => {
    setIsLoading(true);
    try {
      const data = await fetchNewNonWorkingDays(currentCompanyID);
      dispatch(fetchNonWorkingDaysAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    getNonWorkingDaysData,
    getNonWorkingDaysFormData,
    addNonWorkingDay,
    updateNonWorkingDay,
    removeNonWorkingDay,
    nonWorkingDays,
    nonWorkingDayForm,
    updateNonWorkDays,
    errorMess,
    setErrorMess,
  };
};

export default useNonWorkingDays;
