import { createAction } from '../../actionCreators';
import {
  DELETE_WAREHOUSE,
  FETCH_WAREHOUSE,
  UPDATE_WAREHOUSE,
} from './constants';

export const fetchWarehouseAction = createAction(FETCH_WAREHOUSE);
export const updateWarehouseAction = createAction(UPDATE_WAREHOUSE);
export const deleteWarehouseAction = createAction(DELETE_WAREHOUSE);
