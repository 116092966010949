import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewPartner,
  fetchPartners,
  fetchPartnerTypes,
  removePartner,
  updatePartner,
} from '../services/fetchPartners';
import {
  deletePartnerAction,
  editPartnerAction,
  getPartnerAction,
} from '../../App/Redux/partnerSlices/useAction';

const usePartner = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState('');
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { partnerData } = useSelector((state) => state.getPartnerData);
  const [partnerTypes, setPartnerTypes] = useState([]);

  const dispatch = useDispatch();

  const getPartners = async (query) => {
    setIsLoading(true);
    try {
      const data = await fetchPartners(currentCompanyID, query);
      dispatch(getPartnerAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPartnerTypes = async (query) => {
    setIsLoading(true);
    try {
      const data = await fetchPartnerTypes(currentCompanyID, query);
      setPartnerTypes({ partnerTypes: data });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addPartner = async (newPartner, closeFormCallback, query) => {
    try {
      const { errorCode, message, id } = await addNewPartner(newPartner);

      if (errorCode === 0) {
        getPartners(query);
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
      return id;
    } catch (error) {
      setError(error);
    }
  };

  const updateCurrentPartner = async (updatePartnerData, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await updatePartner(updatePartnerData);
      if (message) {
        setErrorMess(message);
      } else if (errorCode === 0) {
        dispatch(editPartnerAction({ ...updatePartnerData }));
        closeFormCallback();
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeCurrentPartner = async (partnerID, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await removePartner(partnerID);

      if (errorCode === 0) {
        dispatch(deletePartnerAction(partnerID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    error,
    isLoading,
    errorMess,
    setErrorMess,
    getPartners,
    partnerData,
    addPartner,
    getPartnerTypes,
    partnerTypes,
    updateCurrentPartner,
    removeCurrentPartner,
  };
};

export default usePartner;
