import React, { useEffect, useState } from 'react';
import { getRequest } from '../../Api/Api';
import Modal from '../../Components/Modal/Modal';
import Table from '../../Components/Table/Table';
import { Commas, dataTimeFormat, dateFormat } from '../../Utilities/Utilities';

const PartnerAccountEntries = ({ closeModal, rowData }) => {
  const { partnerID, accountCode, balanceDate } = rowData || {};
  const handleClose = () => closeModal(false);
  const [accountEntriesData, setAccountEntriesData] = useState([]);

  useEffect(() => {
    getRequest(
      `getPartnerAccountEntries?partnerID=${partnerID}&accountCode=${accountCode}&dateEnd=${dataTimeFormat(
        balanceDate,
      )} `,
    )
      .then((res) => {
        setAccountEntriesData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  }, [accountCode, balanceDate, partnerID]);

  const columnConfig = [
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Արժույթը',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Դեբետ',
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 90 },
    },

    {
      title: 'Արժույթը',
      cell: (row) => row.credCurrCode,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 130 },
    },

    {
      title: 'Գումարը AMD',
      cell: (row) => Commas(row.amountAMD),
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Նշումները',
      cell: (row) => row.description,
      customStyle: { maxWidth: 700 },
    },
  ];

  return (
    <Modal
      closeHandler={handleClose}
      onDrag={true}
      customClass="L-partner-account-entries"
    >
      <>
        {accountEntriesData !== null &&
        accountEntriesData &&
        accountEntriesData?.length ? (
          <Table
            customClass="G-table-has-scroll"
            data={accountEntriesData}
            columnConfig={columnConfig}
          />
        ) : (
          ''
        )}
      </>
    </Modal>
  );
};

export default PartnerAccountEntries;
