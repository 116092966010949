import React, { useMemo, useEffect } from 'react';
import SearchBackground from '../../../../App/Components/SearchForm/SearchBackground';
import Input from '../../../components/Input/Input';
import { SelectControl } from '../../../../App/Components/SelectControl/SelectControl';
import { getGroupOptions } from './optionsUtils';
import { useDebounce } from '../../../../App/Hooks/useDebounce';

const AuthenticatedFilter = ({ prodGroups, setSearchParam }) => {
  const monthOptions = useMemo(() => getGroupOptions(prodGroups), [prodGroups]);
  const [searchString, setSearchString] = React.useState(
    prodGroups?.searchString || '',
  );

  const debouncedSearchTerm = useDebounce(searchString, 1500);

  useEffect(() => {
    if (debouncedSearchTerm !== prodGroups?.searchString) {
      setSearchParam((prevQuery) => ({
        ...prevQuery,
        searchString: debouncedSearchTerm,
      }));
    }
  }, [debouncedSearchTerm, prodGroups?.searchString, setSearchParam]);

  const handleDropdownChange = (selectedOption) => {
    const { value = '', id = null } = selectedOption || {};
    setSearchParam((prevQuery) => ({
      ...prevQuery,
      groupName: value,
      groupNo: id,
    }));
  };

  const handleSearchChange = ({ target }) => {
    setSearchString(target.value);
  };

  return (
    <SearchBackground>
      <div className="L-authenticated-filter">
        <SelectControl
          selectBoxTitle="ՆԱ Խումբ"
          onChange={handleDropdownChange}
          options={monthOptions || []}
        />
        <Input
          inputTitle="Անվանում"
          name="searchString"
          requiredFiled={false}
          onChange={handleSearchChange}
          maxLength={30}
        />
      </div>
    </SearchBackground>
  );
};

export default AuthenticatedFilter;
