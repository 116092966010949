import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { putRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import { editProductAction } from '../../Redux/productSlices/useAction';
import ProductForm from './ProductForm';

const EventEditForm = ({ rowData, closeModal }) => {
  const handleClose = () => closeModal(false);
  const [errorHandler, setErrorHandler] = useState('');
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values) => {
      putRequest('editProductName', values)
        .then((res) => {
          if (res.data.errorCode === 0) {
            dispatch(editProductAction(values));
            closeModal(false);
          } else if (res.data.errorCode > 0) {
            setErrorHandler(res.data.message);
          }
        })
        .catch((err) => {
          console.log('err' + err);
        });
    },
    [dispatch, closeModal],
  );

  return (
    <>
      <ProductForm
        onSubmit={onSubmit}
        initialData={rowData}
        closeModal={handleClose}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventEditForm;
