import { removeCommas } from '../../../App/Utilities/Utilities';

const updateRow = (
  newMakeAnAddition,
  setNewMakeAnAddition,
  row,
  updatedValues,
) => {
  const updatedRows = newMakeAnAddition.map((r) =>
    r.salaryEntryID === row.salaryEntryID ? { ...r, ...updatedValues } : r,
  );
  setNewMakeAnAddition(updatedRows);
};

const calculateAmount = (item, hours, coeff) => {
  let amount = 0;
  if (item.formulaID === 1) {
    amount =
      item.formSign === 2
        ? item.salary * item.workHours * coeff
        : item.salary * coeff;
  } else if (item.formulaID === 2) {
    amount =
      item.formSign === 2
        ? Math.round(item.salary * hours * coeff, 2)
        : hours > 0
        ? Math.round((item.salary / item.workHours) * hours * coeff, 2)
        : 0;
  } else if (item.formulaID === 4) {
    amount =
      item.formSign === 2
        ? Math.round(item.hgAmount * hours * coeff, 2)
        : hours > 0
        ? Math.round((item.hgAmount / item.workHours) * hours * coeff, 2)
        : 0;
  } else if (item.formulaID === 3) {
    amount = item.hgAmount * coeff;
  }
  return amount;
};

export const handleChangeHours = (
  e,
  row,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const newHours = Number(e.target.value);
  if (row.formulaID !== 5) {
    const updatedValues = {
      hours: newHours,
      amount: calculateAmount(row, newHours, row.coeff),
      isModifed: true,
    };
    updateRow(newMakeAnAddition, setNewMakeAnAddition, row, updatedValues);
    setIsDisabled(false);
  }
};

export const handleChangeCoefficient = (
  e,
  row,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const newCoefficient = parseFloat(e.target.value) || 0;
  if (row.formulaID !== 5) {
    const updatedValues = {
      coeff: newCoefficient,
      amount: calculateAmount(row, row.hours, newCoefficient),
      isModifed: true,
    };
    updateRow(newMakeAnAddition, setNewMakeAnAddition, row, updatedValues);
    setIsDisabled(false);
  }
};

export const onAmountChange = (
  e,
  targetRow,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const newAmount = removeCommas(e.target.value);
  if (targetRow.formulaID !== 5) {
    const updatedValues = {
      amount: newAmount,
      sign: true,
      isModifed: true,
    };
    updateRow(
      newMakeAnAddition,
      setNewMakeAnAddition,
      targetRow,
      updatedValues,
    );
    setIsDisabled(false);
  }
};

export const handleCheckboxChange = (
  e,
  row,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const { checked } = e.target;
  setIsDisabled(false);
  const updatedRows = newMakeAnAddition.map((item) => {
    if (checked) {
      const updatedValues = {
        ...item,
        sign: true,
        amount: calculateAmount(item, item.hours, item.coeff),
        isModifed: true,
      };
      return updatedValues;
    } else {
      return {
        ...item,
        sign: false,
        amount: 0,
        hours: 0,
        isModifed: true,
      };
    }
  });
  setNewMakeAnAddition(updatedRows);
};

export const onCheckCurrentCheckbox = (
  e,
  row,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const { checked } = e.target;
  setIsDisabled(false);

  const updatedList = newMakeAnAddition.map((item) => {
    if (item.salaryEntryID === row.salaryEntryID) {
      const updatedValues = checked
        ? {
            sign: true,
            amount: calculateAmount(item, item.hours, item.coeff),
            isModifed: true,
          }
        : {
            sign: false,
            amount: 0,
            hours: 0,
            coeff: 0,
            isModifed: true,
          };
      return { ...item, ...updatedValues };
    }
    return item;
  });

  setNewMakeAnAddition(updatedList);
};
