import React from 'react';
import DateComponent from '../../components/dateComponent/DateComponent';
import { dateFormat } from '../../../App/Utilities/Utilities';
import SelectAccount from '../../../App/Components/SelectBox/SelectAccount';

const EntryOperationExecutionData = ({
  formData,
  setFormData,
  entryOperationExecution,
  setErrorMessage,
}) => {
  const {
    supplyName,
    docTypeName,
    inputTypeName,
    storeName,
    partnerName,
    docNum,
    partnerAccCode,
    docEntryDate,
    creditAcc,
    docDate,
  } = formData || {};

  const executionDataArr = [
    { name: 'Մատակարարվել է', value: supplyName },
    { name: 'Փաստաթղթի տեսակը', value: docTypeName },
    { name: 'Ձեռք բերման տեսակը', value: inputTypeName },
    { name: 'Պահեստ', value: storeName },
    { name: 'Մատակարար', value: partnerName },
    { name: 'Փաստաթուղթ N', value: docNum },
    { name: 'Ամսաթիվը', value: dateFormat(docEntryDate) },
    { name: 'Կրեդիտ հաշիվ', value: partnerAccCode },
    {
      name: '',
      value: creditAcc,
      type: 'dropdown',
    },
    { name: '', value: docDate, type: 'date' },
  ];

  const onChangeDate = (key, date) => {
    if (new Date(date) > new Date(docEntryDate)) {
      setErrorMessage(
        'Ընտրված ամսաթիվը չի կարող ավելի ուշ լինել փաստաթղթի մուտքագրման ամսաթվից:',
      );
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [key]: date,
    }));
  };

  const handleChangeAccount = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFormData((prevData) => ({
      ...prevData,
      creditAcc: values.value,
    }));
  };

  return (
    <div className="L-entry-execution-container">
      <ul className="list">
        {executionDataArr.map((item, index) => (
          <li key={index} className="G-flex list-item">
            <strong className="label">{item.name} </strong>
            {item?.type === 'date' ? (
              <DateComponent
                value={dateFormat(formData?.docDate || '')}
                onChange={(date) => onChangeDate('docDate', date)}
                name="docDate"
                title="Ամսաթիվ"
                requiredFiled={true}
                openToDate={new Date(item.value || new Date())}
              />
            ) : item?.type === 'dropdown' ? (
              formData?.inputTypeId === 3 || formData?.inputTypeId === 4 ? (
                <SelectAccount
                  customClass=""
                  title="Եկամտային հարկի կրեդիտ հաշիվը"
                  accountData={entryOperationExecution?.creditAccounts}
                  // accountVal={{
                  //   label: formData?.accountName,
                  //   value: formData?.accountName,
                  // }}
                  selectChangeAccount={(values) => handleChangeAccount(values)}
                />
              ) : (
                ''
              )
            ) : (
              <span className="value">{item.value || ''}</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EntryOperationExecutionData;
