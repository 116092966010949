import React, { useMemo } from 'react';

import { randomID, removeCommas } from '../../Utilities/Utilities';
import { addInvoiceGoodsAction } from '../../Redux/invoiceSlices/useAction';
import ProductForm from './ProductForm';
import { useDispatch } from 'react-redux';

const EventAddForm = ({ closeModal }) => {
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  const initialValue = useMemo(
    () => ({
      goodID: randomID(),
      productNameID: '',
      unitID: '',
      price: '',
      amount: '',
      totalPrice: 0,
    }),
    [],
  );

  const onSubmit = (values, resetForm) => {
    if (values) {
      dispatch(
        addInvoiceGoodsAction({
          ...values,
          price: removeCommas(values.price),
          totalPrice: values.amount * removeCommas(values.price),
        }),
      );
      resetForm();
      handleClose();
    } else {
      return null;
    }
  };

  return (
    <ProductForm
      initialValue={initialValue}
      closeModal={closeModal}
      onSubmit={onSubmit}
      handleClose={handleClose}
    />
  );
};

export default EventAddForm;
