import React from 'react';
import { ExportExcel } from '../../../App/Components/Excelexport/Excelexport';

const MaterialNameExcel = ({ getMaterialNameExcelData, params }) => {
  return (
    <div className="G-flex-justify-end">
      <ExportExcel
        fileName="Նյութական արժեքների անվանումներ"
        getExcelData={() => getMaterialNameExcelData(params)}
      />
    </div>
  );
};

export default MaterialNameExcel;
