import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRequest, putRequest } from '../../Api/Api';
import { editOperationsAction } from '../../Redux/operationsSlices/useAction';
import {
  editAgreeText,
  removeCommas,
  typOfString,
} from '../../Utilities/Utilities';
import OperationsForm from './OperationsForm';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';

const EventEditForm = ({ rowData, closeModal, setErrorHandler }) => {
  const { entryID } = rowData || {};
  const [isAllowEdit, setIsAllowEdit] = useState(false);
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  useEffect(() => {
    async function checkAllowEdit() {
      try {
        const url = `preDeleteEditEntry?entryID=${entryID}`;
        const res = await getRequest(url);
        if (res.data.errorCode === 100) {
          setIsAllowEdit(true);
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    if (entryID) {
      checkAllowEdit();
    }
  }, [entryID]);

  const onSubmit = async (values) => {
    if (isAllowEdit) {
      return;
    }

    try {
      const res = await putRequest('editentry', {
        ...values,
        docNum: typOfString(values.docNum),
        amount: removeCommas(values.amount),
        amountAMD: removeCommas(values.amountAMD),
        exchangeRate: removeCommas(values.exchangeRate),
      });

      if (res.data.errorCode === 0) {
        dispatch(editOperationsAction(values));
        handleClose();
      }
      if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    } catch (err) {
      console.error('Error submitting form:', err);
    }
  };

  return (
    <>
      <OperationsForm
        closeModal={handleClose}
        rowData={rowData}
        onSubmit={onSubmit}
      />

      {isAllowEdit && (
        <AcceptOrCancelModal
          closeModal={closeModal}
          confirmClick={() => setIsAllowEdit(false)}
          modalContent={editAgreeText}
        />
      )}
    </>
  );
};

export default EventEditForm;
