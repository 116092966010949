import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import CalendarTime from '../../Components/Calendar/CalendarTime';
import Header from '../../Components/Header/Header';
import Pagination from '../../Components/Pagination/Pagination';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import {
  getInvoicingOperationAction,
  loadingAction,
} from '../../Redux/eInvoicingOperationsSlices/useAction';
import {
  correctDate,
  dataTimeFormat,
  inCorrectDateMess,
  initialDate,
} from '../../Utilities/Utilities';
import EInvoicingOperationsTable from './EInvoicingOperationsTable';
import FormulationsType from './FormulationsType';
import './index.scss';

const GetEInvoicingOperations = () => {
  const { currentCompanyID, reportStartDate } = useSelector(
    (state) => state.auth,
  );
  const { invoicingOperationData, isLoading } = useSelector(
    (state) => state.getInvoicingOperation,
  );
  const { currentId } = useSelector((state) => state.filteredId);
  const [pageNumber, setPageNumber] = useState(0);
  const [receivedWrittenOff, setReceivedWrittenOff] = useState(0);

  const [dateStart, setDateStart] = useState(new Date(reportStartDate));
  const [dateEnd, setDateEnd] = useState(initialDate());
  const [errorHandler, setErrorHandler] = useState('');

  const [generalData, setGeneralData] = useState(
    invoicingOperationData?.invList,
  );

  useEffect(() => {
    setGeneralData(invoicingOperationData?.invList);
  }, [invoicingOperationData?.invList]);

  const dispatch = useDispatch();
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
  const getData = async (pageNumber) => {
    dispatch(loadingAction(true));

    try {
      const response = await getRequest(
        `getExcelInvoices?companyID=${currentCompanyID}&recivedWrittenOff=${receivedWrittenOff}&pageNumber=${
          pageNumber + 1
        }&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}&documentID=${currentId}`,
      );

      dispatch(getInvoicingOperationAction(response.data));
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      dispatch(loadingAction(false));
    }
  };

  useEffect(() => {
    getData(pageNumber);
  }, [
    currentCompanyID,
    receivedWrittenOff,
    pageNumber,
    dateStartFormat,
    dateEndFormat,
    currentId,
    dispatch,
  ]);

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      setErrorHandler(inCorrectDateMess);
      return;
    }
    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };

  return (
    <>
      <Header headerBtn={false} pageTitle="E-Invoicing գործառնություններ" />
      <SearchBackground>
        <div className="L-EInvoicing-operations-flex">
          <CalendarTime
            dateStart={dateStart}
            setDateStart={setDateStart}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
            onChangeHandler={timeChangeHandler}
          />
          <FormulationsType
            setReceivedWrittenOff={setReceivedWrittenOff}
            setPageNumber={setPageNumber}
          />
        </div>
      </SearchBackground>

      <EInvoicingOperationsTable
        generalData={generalData}
        setGeneralData={setGeneralData}
        loading={isLoading}
        setErrorHandler={setErrorHandler}
      />
      <Pagination
        pageCount={invoicingOperationData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default GetEInvoicingOperations;
