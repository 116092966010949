import React from 'react';
import { Commas } from '../../../App/Utilities/Utilities';

const CalculationDataTotal = ({ total }) => {
  const { totalAdd, totalDed } = total || {};

  return (
    <div className="L-calculation-data-block vacation-total-section">
      <h4 className="vacation-total-label">Ընդամենը</h4>
      <p className="vacation-total-value">{Commas(totalAdd)}</p>
      <p className="vacation-total-value">{Commas(totalDed)}</p>
    </div>
  );
};

export default CalculationDataTotal;
