export const DocumentNumber = 'Փաստաթղթի համարը';
export const DocumentN = 'Փաստ․ N';

export const InitBalancesAllowDeleteText =
  'Ջնջե՞լ սկզբնական մնացորդը, կա կատարված գործառնություն';

export const InitBalancesAllowEditText =
  'Խմբագրե՞լ սկզբնական մնացորդը, կա կատարված գործառնություն';

export const InitBalancesAllowAddText =
  'Ավելացնել սկզբնական մնացորդը, կա կատարված գործառնություն';
