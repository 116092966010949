import { createAction } from "../actionCreators";
import {
  DELETE_INITIAL_BALANCES,
  EDIT_INITIAL_BALANCES,
  GET_INITIAL_BALANCES,
  LOADING,
} from "./constants";

export const getInitialBalancesAction = createAction(GET_INITIAL_BALANCES);
export const loadingAction = createAction(LOADING, false);
export const deleteInitialBalancesAction = createAction(
  DELETE_INITIAL_BALANCES
);
export const editInitialBalancesAction = createAction(EDIT_INITIAL_BALANCES);
