import { dateFormat } from '../../App/Utilities/Utilities';

export const ConvertPositionExcel = (data) => {
  return (
    data &&
    data?.map(({ positionNo, positionName }) => {
      return {
        Կոդը: positionNo,
        Անվանումը: positionName,
      };
    })
  );
};

export const ConvertSubdivisionExcel = (data) => {
  return (
    data &&
    data?.map(({ depNo, depName }) => {
      return {
        Կոդը: depNo,
        Անվանումը: depName,
      };
    })
  );
};

export const ConvertSavingExcel = (data) => {
  return (
    data &&
    data?.map(({ stNo, stName, sfAcc, tAcc }) => {
      return {
        Կոդը: stNo,
        Անվանումը: stName,
        'Կրեդիտ հաշիվ': sfAcc,
        'Դեբետ հաշիվ': tAcc,
      };
    })
  );
};

export const ConvertTableExcel = (data) => {
  return (
    data &&
    data?.map(({ graphNo, graphName, sign56, hours }) => {
      return {
        Կոդը: graphNo,
        Անվանումը: graphName,
        Տեսակը: sign56,
        'Օրական ժամերը': hours,
      };
    })
  );
};

export const ConvertAdditionsExcel = (data) => {
  return (
    data &&
    data?.map(({ stNo, graphName, graphNo, tAcc }) => {
      return {
        Կոդը: stNo,
        Անվանումը: graphName,
        Տաբել: graphNo,
        'Ծախսի հաշիվ': tAcc,
      };
    })
  );
};

export const ConvertCalculationDataExcel = (data) => {
  return (
    data &&
    data?.map(
      ({
        depName,
        empNo,
        calcDate,
        empName,
        stNo,
        stName,
        days,
        hours,
        addAmount,
        dedAmount,
      }) => {
        return {
          Ստորաբաժանում: depName,
          Ամսաթիվը: dateFormat(calcDate),
          'Տաբել N': empNo,
          'Ազգանուն, Անուն, Հայրանուն': empName,
          'Հ/Պ կոդ': stNo,
          'Հ/Պ անվանումը': stName,
          Օրը: days,
          Ժամ: hours,
          Հավելում: addAmount,
          Պահում: dedAmount,
        };
      },
    )
  );
};

export const ConvertBankPaymentListDataExcel = (data) => {
  return (
    data &&
    data?.map(({ depName, num, empNo, empName, calcDate, stNo, dedAmount }) => {
      return {
        Ստորաբաժանում: depName,
        'Վճ N': num,
        'Տաբել N': empNo,
        'Ազգանուն, Անուն, Հայրանուն': empName,
        Ամսաթիվը: dateFormat(calcDate),
        'Հ/Պ': stNo,
        Գումար: dedAmount,
      };
    })
  );
};

export const ConvertSummariesDataExcel = (data) => {
  return (
    data &&
    data?.map(
      ({
        depName,
        empNo,
        empName,
        salary,
        sT014,
        sT015,
        sT016,
        sT019,
        otherAdd,
        totalAdd,
        sT101,
        sT102,
        sT103,
        otherDed,
        sT107,
        totalDed,
        toBePayed,
      }) => {
        return {
          Ստորաբաժանում: depName,
          'Տաբել N': empNo,
          'Ազգանուն, Անուն, Հայրանուն': empName,
          Հաստիք: salary,
          Արձակուրդ: sT014,
          Վերջնահաշվարկ: sT015,
          Նպաստ: sT016,
          'Հղիության նպաստ': sT019,
          'Այլ հավելում': otherAdd,
          'Ընդ. հավելում': totalAdd,
          'Եկամտային հարկ': sT101,
          'Սոց. վճար': sT102,
          'Դրոշմ. վճար': sT103,
          'Արհմիութենական վճար': sT103,
          'Այլ պահում': otherDed,
          'Փոխ. է բանկ': sT107,
          'Ընդ. պահում': totalDed,
          'Վճ. ենթակա': toBePayed,
        };
      },
    )
  );
};

export const ConvertAccountDataExcel = (data) => {
  return (
    data &&
    data?.map(
      ({ accountDebit, accountCredit, amount, docDate, correspName }) => {
        return {
          Դեբետ: accountDebit,
          Կրեդիտ: accountCredit,
          Գումարը: amount,
          Ամսաթիվը: dateFormat(docDate),
          Նշումներ: correspName,
        };
      },
    )
  );
};
