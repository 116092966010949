import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSubdivisions from '../../hooks/useSubdivisions';
import Header from '../../../App/Components/Header/Header';
import SubdivisionTable from './SubdivisionTable';
import SearchForm from './SearchForm';
import EventAddForm from './EventAddForm';
import { useDebounce } from '../../../App/Hooks/useDebounce';
import { ConvertSubdivisionExcel } from '../../Config/excelConfig';

const Subdivisions = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [query, setQuery] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const debouncedSearchTerm = useDebounce(query, 1500);
  const { subDivision, isLoading, fetchSubdivisionsData } = useSubdivisions();

  useEffect(() => {
    fetchSubdivisionsData(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };
  const dataToExcel = ConvertSubdivisionExcel(subDivision?.workDeps);

  return (
    <div className="G-small-wrap">
      <Header pageTitle="Ստորաբաժանումներ" handelClick={onClose} />
      <SearchForm query={query} setQuery={setQuery} dataToExcel={dataToExcel} />
      <SubdivisionTable mainData={subDivision?.workDeps} loading={isLoading} />

      {showAddForm && (
        <EventAddForm handleClose={onClose} companyID={currentCompanyID} />
      )}
    </div>
  );
};

export default Subdivisions;
