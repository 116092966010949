import React from 'react';
import SubdivisionForm from './SubdivisionForm';
import useGetWorkDepNo from '../../hooks/useGetWorkDepNo';
import useSubdivisions from '../../hooks/useSubdivisions';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';

const EventAddForm = ({ companyID, handleClose }) => {
  const { workDepNo } = useGetWorkDepNo(companyID);
  const { addSubdivisionItem, errorMess, setErrorMess } = useSubdivisions();
  const isMounted = useIsMounted();

  const initialValues = {
    companyID: companyID,
    depNo: workDepNo,
    depName: '',
  };

  const onAddHandler = async (values, resetForm) => {
    try {
      await addSubdivisionItem(values, handleClose);
      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <SubdivisionForm
        initialValues={initialValues}
        onSubmit={onAddHandler}
        handleClose={handleClose}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
