import { useFormik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import InputFiled from '../../Components/InputFiled/InputFiled';
import Modal from '../../Components/Modal/Modal';
import { SelectControl } from '../../Components/SelectControl/SelectControl';
import { myBakValidation } from '../../Validation/BankValidation';
import { textFiledLength } from '../../Utilities/Utilities';

const CompanyBankForm = ({ closeModal, initialData, onSubmit }) => {
  const { organizationBankData } = useSelector(
    (state) => state.getOrganizationData,
  );
  const bankOptions = useMemo(() => {
    if (!organizationBankData) return [];

    return organizationBankData.banks.map(({ bankName, bankID, bankCode }) => ({
      label: bankName,
      value: bankName,
      id: bankID,
      bankCode: bankCode,
    }));
  }, [organizationBankData]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    setFieldValue,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    initialValues: initialData,
    validationSchema: myBakValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const changeBankHandler = useCallback(
    (values) => {
      if (!values) {
        values = {
          value: '',
          id: '',
          bankCode: '',
        };
      }

      setFieldValue('bankName', values.label);
      setFieldValue('bankID', values.id);
      setFieldValue('bankCode', values.bankCode);
      setFieldValue('bankingAccount', values.bankCode);
    },
    [setFieldValue],
  );

  let defaultValue = values?.bankingAccount;

  const bankingAccountHandler = useCallback(
    (e) => {
      if (
        e.target.value.indexOf(defaultValue) === 0 ||
        e.target.value.length >= 5
      ) {
        handleChange(e);
      }
    },
    [defaultValue, handleChange],
  );

  return (
    <Modal closeHandler={closeModal} onDrag={true} customClass="G-form-modal">
      <div className="G-form-content">
        <div className="G-modal-block-title">
          <h3>Ավելացնել բանկ</h3>
        </div>
        <form onSubmit={onSubmit}>
          <div className="G-form-select-wrapper">
            <SelectControl
              matchFromParam=""
              requiredFiled={true}
              selectBoxTitle="Բանկ"
              options={bankOptions}
              value={{
                label: values.bankName,
                value: values.bankName,
              }}
              onChange={changeBankHandler}
              onBlur={handleBlur}
            />
            {touched.bankName && errors.bankName && (
              <ErrorFiled error={errors.bankName} />
            )}
          </div>
          <div className="G-form-input-wrapper">
            <InputFiled
              requiredFiled={true}
              inputTitle="Կազմակերպության հաշվարկային հաշիվ"
              name="bankingAccount"
              value={values.bankingAccount}
              changeHandler={bankingAccountHandler}
              onBlur={handleBlur}
              maxLength={25}
            />
            {touched.bankingAccount && errors.bankingAccount && (
              <ErrorFiled error={errors.bankingAccount} />
            )}
          </div>

          <div className="G-form-input-wrapper">
            <InputFiled
              inputTitle="Նշումներ"
              name="comment"
              value={values.comment}
              changeHandler={handleChange}
              onBlur={handleBlur}
              maxLength={textFiledLength}
            />
          </div>

          <GetSentDataBtn
            cancelPrintClick={closeModal}
            confirmExecuteClick={handleSubmit}
            executeClass={!isValid || !dirty ? 'G-disabled-button' : ''}
            executeDisabled={!isValid || !dirty}
          />
        </form>
      </div>
    </Modal>
  );
};

export default CompanyBankForm;
