import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { deleteChartOfAccountAction } from '../../Redux/chartOfAccountSlices/useAction';

const DeleteChartOfAccount = ({ closeModal, deleteRowId, setErrorHandler }) => {
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  const deleteRowDate = () => {
    deleteRequest(`deleteAccount/${deleteRowId}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deleteChartOfAccountAction(deleteRowId));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={closeModal}
        confirmClick={deleteRowDate}
      />
    </>
  );
};

export default DeleteChartOfAccount;
