export const SalaryHelpers = [
  {
    path: '/Subdivisions',
    text: `Կազմակերպության կառուցվածքն է՝ ավելացնելու, խմբագրելու, հեռացնելու և աշխատակիցներ ավելացնելու հնարավորությամբ:`,
  },
  {
    path: '/EmployeesDashboard',
    text: `Աշխատավարձի և հարկերի հաշվարկման հաշվիչը և աշխատակիցների ամփոփ տվյալները՝ կազմակերպության կողմից վճարման ենթակա գումարներով:`,
  },
  {
    path: '/Positions',
    text: `Կազմակերպությունում  հաստատված պաշտոնների ցանկն է՝ ավելացնելու, խմբագրելու և հեռացնելու հնարավորությամբ:`,
  },
  {
    path: '/Employees',
    text: `Աշխատակիցների տվյալների մուտքի ձևն է՝ ավելացնելու, խմբագրելու և հեռացնելու հնարավորությամբ:`,
  },
  {
    path: '/Non_working_days',
    text: `Ընթացիկ տարվա ոչ աշխատանքային օրերի ցանկն է՝ ավելացնելու, թարմացնելու հնարավորությամբ:`,
  },
  {
    path: '/Working_time_tables',
    text: `Աշխատաժամանակի տաբելների ցանկն է՝ ավելացնելու, խմբագրելու ու հեռացնելու հնարավորությամբ:`,
  },
  {
    path: '/Additions',
    text: `Նախատեսված է աշխատանքի վճարման, հավելումների տեսակների մուտքագրման համար՝ խմբագրելու, նոր հավելումներ ավելացնելու հնարավորությամբ:`,
  },
  {
    path: '/Savings',
    text: `Հարկերի և այլ պահումների տեսակների ցանկն է՝ ավելացնելու, խմբագրելու, հեռացնելու հնարավորությամբ։:`,
  },
  {
    path: '/Provisions',
    text: `Դրույթները աշխատավարձի հաշվարկում կիրառվող բոլոր սանդղակներն են ընթացիկ տարվա դրությամբ:`,
  },
  {
    path: '/Actions_with_employees',
    text: `Իրականացվում են յուրաքանչյուր աշխատակցի աշխատանքի վճարման հետ կապված բոլոր գործողությունները:`,
  },
  {
    path: '/Assign_positions',
    text: `Բոլոր աշխատակիցներին, կամ ընտրվածներին նոր հաստիքներ տրամադրելու համար է:`,
  },
  {
    path: '/Confirm_positions',
    text: `Բոլոր աշխատակիցների, կամ ընտրվածների,ըստ աշխատած օրերի, հաստիքները հաստատելու համար է:`,
  },
  {
    path: '/Make_an_addition',
    text: `Բոլոր աշխատակիցներին, կամ ընտրվածներին, հաշվարկման ընտրված մեթոդով, հավելում տրամադրելու համար է:`,
  },
  {
    path: '/Salary_calculation',
    text: `Ընտրված հաշվարկային ամսվա համար  բոլոր աշխատակիցների, կամ առանձին ընտրված աշխատակիցների աշխատավարձի հաշվարկն է:`,
  },
  {
    path: '/Accounting_formulations',
    text: `Աշխատավարձի հաշվարկից հետո այդ հաշվարկային ամսվա համար ձևավորված հաշվապահական ձևակերպումներն են:`,
  },
  {
    path: '/Bank_paymentLists',
    text: `Աշխատակիցներին, բանկային  փոխանցման միջոցով, վճարվող աշխատավարձի վճարացուցակներն են:`,
  },
  {
    path: '/Calculation_data_viewing',
    text: `Ըստ աշխատակիցների, ընտրված ժամանակահատվածում, աշխատավարձի վճարմանն ուղղված բոլոր տեսակի հաշվարկները դիտելու, Excel արտահանելու համար է:`,
  },
  {
    path: '/Calculation_sheets_viewing',
    text: `Ընտրված տարվա ընտրված ամսում կատարված, աշխատավարձի վճարմանն  ուղղված միջոցների, ամփոփ հաշվարկները, վճարման ենթակա գումարը դիտելու և տպելու համար է:`,
  },
  {
    path: '/Summary_data',
    text: `Ընտրված ժամանակահատվածում  կատարված, աշխատավարձի վճարմանն ուղղված միջոցների, ամփոփ հաշվարկները դիտելու և տպելու համար է:`,
  },
  {
    path: '/General_summary',
    text: `Աշխատավարձի հաշվարկային տեղեկագիրն է ընտրված ժամանակահատվածում՝ ըստ աշխատակիցների:`,
  },
];
