import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Action from '../../../App/Components/Table/Action';
import { tableActionTitle } from '../../../App/Utilities/Utilities';
import { savingsActionItems } from '../../Config/tableConfig';
import Forms from './Forms';
const SavingsTable = ({ mainData }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Կոդը',
      cell: (row) => row.sTypeNo,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.sTypeName,
      customStyle: { maxWidth: 900, overflow: 'hidden' },
    },
    {
      title: 'Կրեդիտ հաշիվ',
      cell: (row) => row.tAcc,
      customStyle: { maxWidth: 120 },
    },

    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(value, row) =>
            setShowForm({
              type: value,
              value: row?.salaryTypeID,
            })
          }
          actionItem={savingsActionItems(row)}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : null}
      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default SavingsTable;
