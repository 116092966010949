import React from 'react';
import './img.scss';

const Image = ({ src, toggleTab, name, className, title }) => {
  return (
    <div
      onClick={() => toggleTab(name)}
      className={`L-header-icon-wrap ${className}`}
    >
      <img src={src} alt="" className="L-header-img" />
      <h3 className="L-header-title">{title}</h3>
    </div>
  );
};

export default Image;
