import React, { useEffect, useState } from 'react';
import PartnerBalancesTable from './PartnerBalancesTable';
import { getRequest } from '../../Api/Api';
import Modal from '../../Components/Modal/Modal';

const PartnerBalances = ({ partnerID, handleClose }) => {
  const [balanceData, setBalanceData] = useState(null);

  const fetchPartnerBalance = () => {
    getRequest(`GetOnePartnerBalanceBook?PartnerID=${partnerID}`)
      .then((res) => {
        setBalanceData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchPartnerBalance();
  }, []);

  return (
    <Modal
      closeHandler={handleClose}
      onDrag={true}
      customClass="L-partner-balances"
    >
      <div className="G-modal-block-title">
        <h3>{balanceData?.partnerName}</h3>
      </div>

      <PartnerBalancesTable mainData={balanceData?.balances} />
    </Modal>
  );
};

export default PartnerBalances;
