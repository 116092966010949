import * as Yup from 'yup';
import { InputFiledMessage, SelectFieldMessage } from './ErrorMessage';

export const InitialBalancesValidation = () => {
  return Yup.object().shape({
    currencyCode: Yup.string().required(SelectFieldMessage),
    accountID: Yup.string().required(SelectFieldMessage).nullable(),
    amountDebit: Yup.string().when(['type'], (type, schema) => {
      if (type === '1') {
        return schema
          .required(InputFiledMessage)
          .test('ոչ զրոյական', 'Գումարը չպետք է լինի 0', (value) => {
            const parsedValue = parseFloat(value);
            return !isNaN(parsedValue) && parsedValue > 0;
          });
      }
      return schema;
    }),
    amountCredit: Yup.string().when(['type'], (type, schema) => {
      if (type === '2') {
        return schema
          .required(InputFiledMessage)
          .test('ոչ զրոյական', 'Գումարը չպետք է լինի 0', (value) => {
            const parsedValue = parseFloat(value);
            return !isNaN(parsedValue) && parsedValue > 0;
          });
      }

      return schema;
    }),
  });
};
