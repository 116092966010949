import { createAction } from "../actionCreators";
import {
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCT,
  LOADING,
} from "./constants";

export const getProductAction = createAction(GET_PRODUCT);
export const loadingAction = createAction(LOADING, false);
export const editProductAction = createAction(EDIT_PRODUCT);
export const deleteProductAction = createAction(DELETE_PRODUCT);
