import React from 'react';
import AssignAdditionalStorage from './assignAdditionalStorage/AssignAdditionalStorage';
import AssignPositionModal from './assignPosition/AssignPositionModal';
import EditEmployee from '../employees/EventEditForm';
import CalculationSheetModal from './calculationSheet/CalculationSheetModal';
import SalaryCalculationModal from './salaryCalculationForm/SalaryCalculationModal';
import VacationCalculationModal from './vacationCalculationModal/VacationCalculationModal';
import BenefitCalculationModal from './benefitCalculationModal/BenefitCalculationModal';
import ConfirmEmployeePositionModal from './confirmEmployeePositionModal/ConfirmEmployeePositionModal';
import MakeAdditionalSaveModal from './makeAdditionalSaveModal/MakeAdditionalSaveModal';

const Forms = ({ formType, closeModal }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};

  switch (type) {
    case 'assignSupplement':
      return (
        <AssignAdditionalStorage rowValues={value} handleClose={handleClose} />
      );
    case 'assignPosition':
      return (
        <AssignPositionModal rowValues={value} handleClose={handleClose} />
      );
    case 'makeAdditionalSave':
      return (
        <MakeAdditionalSaveModal rowValues={value} handleClose={handleClose} />
      );

    case 'confirmPosition':
      return (
        <ConfirmEmployeePositionModal
          rowValues={value}
          handleClose={handleClose}
        />
      );
    case 'calculationSheet':
      return (
        <CalculationSheetModal rowValues={value} handleClose={handleClose} />
      );
    case 'salaryCalculation':
      return (
        <SalaryCalculationModal rowValues={value} handleClose={handleClose} />
      );

    case 'vacationCalculation':
      return (
        <VacationCalculationModal rowValues={value} handleClose={handleClose} />
      );

    case 'benefitCalculation':
      return (
        <BenefitCalculationModal rowValues={value} handleClose={handleClose} />
      );

    case 'editingEmployeeData':
      return (
        <EditEmployee
          employeeID={value?.employeeID}
          handleClose={handleClose}
          title="Աշխատակցի տվյալների խմբագրում"
        />
      );

    default:
      return null;
  }
};

export default Forms;
