export const GET_CALENDAR_DATA = 'GET_CALENDAR_DATA';
export const EDIT_EVENT = 'EDIT_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const LOADING = 'LOADING';
export const UPDATE_CALENDAR_TYPE = 'UPDATE_CALENDAR_TYPE';
export const UPDATE_EVENT_DROP = 'UPDATE_EVENT_DROP';



export const GET_DAY_NOTFICATION = 'GET_DAY_NOTFICATION';

export const IS_NOT_LOADING = 'IS_NOT_LOADING';
export const ADD_DAY_NOTIFICATION = 'ADD_DAY_NOTIFICATION';
export const UDPADE_DAY_NOTFICATION = 'UDPADE_DAY_NOTFICATION';
export const REMOVE_DAY_NOTIFICATION = 'REMOVE_DAY_NOTIFICATION';