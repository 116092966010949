import React from 'react';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
import MaterialNameForm from './MaterialNameForm';
import useMaterialValuesName from '../../hooks/useMaterialValuesName';

const EventAddForm = ({
  handleClose,
  formIsUsedExternally = false,
  setFieldValue,
  addToCurrentData = false,
  updateSelectValues,
}) => {
  const { addMaterialName, errorMess, setErrorMess } = useMaterialValuesName();
  const isMounted = useIsMounted();

  const onAddHandler = async (values, resetForm) => {
    const newValues = {
      ...values,
      groupNo: Number(values.groupNo),
    };

    try {
      await addMaterialName(newValues, handleClose);

      if (formIsUsedExternally && !errorMess) {
        setFieldValue('matValueName', values.matValueName);
        setFieldValue('matValueNo', values.matValueNo);
        setFieldValue('unit', values.unit);
        setFieldValue('accCode', values.accCode);
      }

      if (addToCurrentData && !errorMess) {
        updateSelectValues({
          matValueName: values.matValueName,
          matValueNo: values.matValueNo,
          groupName: values.groupName,
        });
      }

      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <MaterialNameForm onSubmit={onAddHandler} handleClose={handleClose} />
      <Alert description={errorMess} onClose={() => setErrorMess('')} />
    </>
  );
};

export default EventAddForm;
