import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, putRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import {
  getPurchaseAddFormAction,
  updatePurchaseTableAction,
} from '../../Redux/purchaseActSlices/useAction';
import PurchaseView from './PurchaseView';

const EditPurchaseWrapper = ({ closeModal, rowId }) => {
  const { purchaseFormData } = useSelector((state) => state.getPurchaseActData);
  const { purchase, goods, hasIncomeTax } = useMemo(
    () => purchaseFormData || {},
    [purchaseFormData],
  );

  const handleClose = useCallback(() => closeModal(false), []);

  const [errorHandler, setErrorHandler] = useState('');
  const dispatch = useDispatch();

  const createFormWrapper = useCallback(() => {
    if (rowId?.partnerID && rowId?.documentID !== 0) {
      getRequest(
        `getGoods?partnerID=${rowId?.partnerID}&purchaseID=${rowId?.documentID}`,
      )
        .then((res) => {
          dispatch(getPurchaseAddFormAction(res.data));
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [dispatch, rowId?.documentID, rowId?.partnerID]);

  useEffect(() => {
    createFormWrapper();
  }, [createFormWrapper]);

  const saveDocumentData = useCallback(() => {
    putRequest('editPurchase', {
      purchase: {
        ...purchase,
      },
      goods: [...goods],
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(updatePurchaseTableAction(purchase));
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  }, [dispatch, purchase, goods]);

  return (
    <>
      <PurchaseView
        closeModal={handleClose}
        mainData={purchaseFormData}
        saveDocumentHandler={saveDocumentData}
        disabledPartnerName={true}
        isDisabledRegBtn={true}
        hasIncomeTax={hasIncomeTax}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EditPurchaseWrapper;
