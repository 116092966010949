import {
  ADD_FORM_DATA,
  ADD_POSITIONS,
  DELETE_POSITIONS,
  FETCH_POSITIONS,
  UPDATE_POSITIONS,
} from './constants';

const initialState = {
  positions: null,
  positionsForm: null,
};

export const positionsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_POSITIONS:
      return {
        ...state,
        positions: payload,
      };
    case ADD_POSITIONS:
      return {
        ...state,
        positions: payload,
      };
    case ADD_FORM_DATA:
      return {
        ...state,
        positionsForm: payload,
      };
    case UPDATE_POSITIONS:
      return {
        ...state,
        positions: {
          ...state.positions,
          positions: state.positions.positions?.map((i) => {
            return i.positionID === payload.positionID ? payload : i;
          }),
        },
      };
    case DELETE_POSITIONS:
      return {
        ...state,
        positions: {
          ...state.positions,
          positions: state.positions.positions.filter(
            (item) => item.positionID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
