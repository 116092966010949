import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../Api/Api';
import Modal from '../../Components/Modal/Modal';
import { dataTimeFormat, dateFormat } from '../../Utilities/Utilities';
import { SelectControl } from '../../Components/SelectControl/SelectControl';
import InputFiled from '../../Components/InputFiled/InputFiled';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import Calendar from '../../Components/Calendar/Calendar';

const OperationForm = ({
  closeModal,
  selectRowData,
  getCashMoneyId,
  setErrorHandler,
}) => {
  const handleClose = () => closeModal(false);
  const { cashMoneyID, expenseID } = selectRowData || {};
  const useCashMoneyId = getCashMoneyId ? getCashMoneyId : cashMoneyID;
  const [operationData, setOperationData] = useState([]);
  const { partnerName, docNum, creationDate } = operationData || {};
  const [operationValue, setOperationValue] = useState({});
  const [initialCreationDate, setInitialCreationDate] = useState(creationDate);
  const [initialAmount, setInitialAmount] = useState(operationData?.amount);
  useEffect(() => {
    setInitialAmount(operationData?.amount);
    setInitialCreationDate(creationDate);
  }, [operationData?.amount, creationDate]);

  const [creditVal, setCreditVal] = useState({
    label: '',
    value: '',
    id: '',
  });

  const [debitVal, setDebitVal] = useState({
    label: '',
    value: '',
    id: '',
  });

  useEffect(() => {
    const fetchOperationData = () => {
      getRequest(
        `getCashMoneyForEntry?cashMoneyID=${useCashMoneyId}&expenseID=${expenseID}`,
      )
        .then((res) => {
          setOperationData(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    };
    fetchOperationData();
  }, [useCashMoneyId, expenseID]);

  const operationTitle = [
    'Գործընկերներ',
    'Փաստաթուղթ N',
    'Ամսաթիվը',
    'Կրեդիտ հաշիվ',
    'Դեբետ հաշիվ',
    'Գումարը',
  ];

  const creditOptions = operationData?.creditAccounts?.map(
    ({ accountCode, accountID, accountName }) => {
      return {
        label: accountCode + ' ' + accountName,
        value: accountCode,
        id: accountID,
      };
    },
  );

  const debitOptions = operationData?.debitAccounts?.map((item) => {
    return {
      label: item.accountCode + ' ' + item.accountName,
      value: item.accountCode,
      id: item.accountID,
    };
  });

  const handleChangeAccount = (value, id) => {
    if (!value) {
      value = {
        value: '',
      };
    }
    if (id === 'credit') {
      setCreditVal(value);
    }
    if (id === 'debit') {
      setDebitVal(value);
    }
  };

  useEffect(() => {
    const valueObj = {
      cashMoneyID: useCashMoneyId,
      expenseID: expenseID,
      creditAccount: creditVal.value,
      debitAccount: debitVal.value,
      amount: Number(initialAmount),
      creationDate: initialCreationDate,
    };
    setOperationValue({ ...valueObj });
  }, [
    creditVal.value,
    debitVal.value,
    expenseID,
    initialAmount,
    initialCreationDate,
    useCashMoneyId,
  ]);

  const disableBtn = (val) => {
    if (val?.amount > initialAmount) {
      return true;
    }
    if (!initialAmount) {
      return true;
    }
    if (val.creditAccount === '') {
      return true;
    }
    if (val.debitAccount === '') {
      return true;
    } else {
      return false;
    }
  };

  const postOperationData = () => {
    postRequest('saveCashMoneyEntry', operationValue)
      .then((res) => {
        if (res.data.errorCode === 0) {
          handleClose();
        }
        if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <Modal
      closeHandler={handleClose}
      onDrag={true}
      customClass="L-operation-form-block"
    >
      {operationData?.amount > 0 ? (
        <div className="L-operation-form-content">
          <div className="G-flex">
            <div className="L-operation-left-panel">
              {operationTitle?.map((title) => {
                return <h3 key={title}>{title}</h3>;
              })}
            </div>
            <div className="L-operation-right-panel">
              <p>{partnerName}</p>
              <p>{docNum}</p>
              <div className="L-calendar">
                <Calendar
                  value={dateFormat(initialCreationDate)}
                  name="creationDate"
                  changeHandler={(e) =>
                    setInitialCreationDate(dataTimeFormat(e))
                  }
                />
              </div>
              <div className="L-operation-select-box">
                <SelectControl
                  id="credit"
                  options={creditOptions}
                  value={creditVal}
                  onChange={handleChangeAccount}
                />
              </div>
              <div className="L-operation-select-box">
                <SelectControl
                  id="debit"
                  options={debitOptions}
                  value={debitVal}
                  onChange={handleChangeAccount}
                />
              </div>
              <div className="L-operations-amount">
                <InputFiled
                  value={initialAmount}
                  name="amount"
                  changeHandler={(e) => setInitialAmount(e.target.value)}
                />
              </div>
            </div>
          </div>

          <GetSentDataBtn
            cancelPrintClick={handleClose}
            confirmExecuteClick={postOperationData}
            executeClass={disableBtn ? 'G-disabled-button' : ''}
            executeDisabled={disableBtn(operationValue)}
          />
        </div>
      ) : (
        <p className="G-flex-justify-center">Գործառնությունը կատարված է</p>
      )}
    </Modal>
  );
};

export default OperationForm;
