import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetch } from '../../Hooks/useFetch';
import { checkBeforeDelete, dateFormat } from '../../Utilities/Utilities';
import Button from '../../Components/Button/Button';
import './style.scss';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../Components/Alert';
import { postRequest } from '../../Api/Api';
import { updateUser } from '../../Redux/authSlices/useAction';
const ResidueFormation = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [newPeriod] = useFetch(`getNewPeriod?companyID=${currentCompanyID}`);
  const { newStartDate, newEndDate } = newPeriod || {};
  const [agreePeriodDate, setAgreePeriodDate] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const dispatch = useDispatch();
  const formHandler = () => {
    checkBeforeDelete(`letSaveNewBalances?companyID=${currentCompanyID}`).then(
      (res) => {
        if (res.errorCode === 0) {
          dispatch(
            updateUser({
              startDate: newStartDate,
            }),
          );
          setAgreePeriodDate(true);
        }
        if (res.errorCode > 0) {
          setErrorHandler(res.message);
        }
      },
    );
  };
  const confirmHandler = () => {
    postRequest(`saveNewBalances?companyID=${currentCompanyID}`)
      .then((res) => {
        setAgreePeriodDate(false);

        if (res.data.errorCode === 0) {
          dispatch(
            updateUser({
              startDate: res.data.startDate,
              endDate: res.data.endDate,
            }),
          );
        }
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  };
  return (
    <>
      <div className="L-residue-formation-box">
        <h3>Նոր հաշվետու տարվա</h3>
        <div className="L-accounting-period-box">
          <strong>Սկիզբը՝</strong>
          <p> {dateFormat(newStartDate)}</p>
        </div>
        <div className="L-accounting-period-box">
          <strong>Վերջը՝</strong>
          <p> {dateFormat(newEndDate)}</p>
        </div>
        <div className="G-flex-justify-end">
          <div className="G-view-btn">
            <Button text="Ձևավորել" onClick={formHandler} />
          </div>
        </div>
      </div>
      {agreePeriodDate && (
        <AcceptOrCancelModal
          closeModal={setAgreePeriodDate}
          confirmClick={confirmHandler}
          modalContent="Ձևավորե՞լ նոր սկզբնական մնացորդները"
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default ResidueFormation;
