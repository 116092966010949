import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import Table from '../Table/Table';

import { dateFormat, formatNumberWithCommas } from '../../Utilities/Utilities';
import { getRequest } from '../../Api/Api';
import Loading from '../Loading/Loading';
import Tooltip from '../Tooltip/Tooltip';

const GetDocumentEntries = ({ currentDocumentID, isClose }) => {
  const [documentEntries, setDocumentEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => isClose(false);
  useEffect(() => {
    setIsLoading(true);
    getRequest(`getDocumentEntries?documentID=${currentDocumentID} `)
      .then((res) => {
        setDocumentEntries(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentDocumentID]);

  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Փաստաթղթի տեսակը',
      cell: (row) => row.docTypeName,
      customStyle: { maxWidth: 300 },
    },
    {
      title: <Tooltip content="Փաստաթուղթ N">Փաստ․ N</Tooltip>,
      cell: (row) => row.docNum,
      customStyle: { maxWidth: 83 },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 240 },
    },
    {
      title: 'Նշումներ',
      cell: (row) => row.description,
      customStyle: { maxWidth: 150, overflow: 'hidden' },
    },

    {
      title: 'Արժույթ',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 50 },
    },
    {
      title: <Tooltip content="Դեբետ հաշիվ">Դեբետ</Tooltip>,
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 72 },
    },
    {
      title: 'Արժույթ',
      cell: (row) => row.credCurrCode,
      customStyle: { maxWidth: 52 },
    },
    {
      title: <Tooltip content="Կրեդիտ հաշիվ">Կրեդիտ</Tooltip>,
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 72 },
    },

    {
      title: 'Փոխարժեք',
      cell: (row) => row.exchangeRate,
      customStyle: { maxWidth: 75 },
    },
    {
      title: 'Գումարը',
      cell: (row) => formatNumberWithCommas(row.amount),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Գումար AMD',
      cell: (row) =>
        !formatNumberWithCommas(row.amountAMD)
          ? formatNumberWithCommas(row.amount * row.exchangeRate)
          : formatNumberWithCommas(row.amountAMD),
      customStyle: { maxWidth: 130 },
    },
  ];

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Modal
      customClass="L-document-entries-block"
      onDrag={true}
      closeHandler={handleClose}
    >
      <div className="">
        {documentEntries !== null &&
        documentEntries &&
        documentEntries?.length ? (
          <Table
            customClass="L-operations-table"
            data={documentEntries}
            columnConfig={columnConfig}
          />
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
};

export default GetDocumentEntries;
