import { useState } from 'react';
import { getRequest } from '../Api/Api';

const useFetchExcel = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchExcelData = async (apiUrl) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getRequest(apiUrl);

      return response.data;
    } catch (err) {
      setError(err.message || 'Something went wrong');
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, fetchExcelData };
};

export default useFetchExcel;
