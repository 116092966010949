import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import './App/Assets/Scss/main.scss';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store from './App/Redux/store';
import AccountStore from './FinHelp/store/AccountStore';
import CurrencyStore from './FinHelp/store/CurrencyStore';
import AspectStore from './FinHelp/store/AspectStore';
import EntrStore from './FinHelp/store/EntrStore';
import StandardAspectsStore from './FinHelp/store/StandardAspectStore';
const persister = persistStore(store);
export const Context = createContext();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <Context.Provider
          value={{
            account: new AccountStore(),
            currency: new CurrencyStore(),
            aspect: new AspectStore(),
            entr: new EntrStore(),
            standardAspects: new StandardAspectsStore(),
          }}
        >
          <App />
        </Context.Provider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
