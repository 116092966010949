import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Action from '../../Components/Table/Action';
import { EDList } from '../../Components/Table/ActionLIst';
import Table from '../../Components/Table/Table';
import { deleteProductAction } from '../../Redux/purchaseActSlices/useAction';
import {
  Commas,
  formatNumberWithCommas,
  removeCommas,
} from '../../Utilities/Utilities';
import EventEditForm from './EventEditForm';

const ProductTable = ({ showActionList }) => {
  const { purchaseFormData } = useSelector((state) => state.getPurchaseActData);
  const [editRow, setEditRow] = useState(false);
  const dispatch = useDispatch();
  const columnConfig = [
    {
      title: 'Ապրանքի անվանումը',
      cell: (row) => row.productName,
      customStyle: { maxWidth: 500 },
    },
    {
      title: 'Չափման միավորը',
      cell: (row) => row.unitName,
      customStyle: { maxWidth: 200 },
    },
    {
      title: 'Քանակը',
      cell: (row) => row.quantity,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գինը',
      cell: (row) => formatNumberWithCommas(row.price),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գումարը',
      cell: (row) =>
        !row.amount
          ? formatNumberWithCommas(row.quantity * removeCommas(row.price))
          : formatNumberWithCommas(row.amount),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountCode,
      customStyle: { maxWidth: 120 },
    },
    {
      title: `${showActionList ? '' : 'Գործողություն'}`,
      cell: (row) =>
        showActionList ? null : (
          <Action
            row={row}
            handelChange={handelChange}
            actionItem={EDList}
            modalIsOpen={editRow}
          />
        ),
      customStyle: { maxWidth: 150 },
    },
  ];

  const handelChange = (value, row) => {
    switch (value) {
      case 'delete':
        return deleteHandler(row?.productID);
      case 'edit':
        return editHandler(row);
      default:
        return null;
    }
  };

  const editHandler = (row) => {
    setEditRow(row);
  };
  const deleteHandler = (deleteId) => {
    dispatch(deleteProductAction(deleteId));
  };

  return (
    <>
      {purchaseFormData !== null &&
      purchaseFormData &&
      purchaseFormData?.goods?.length ? (
        <Table
          customClass="L-purchase-act-product-table"
          data={purchaseFormData?.goods}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}
      {editRow && (
        <EventEditForm editRowData={editRow} modalClick={setEditRow} />
      )}
    </>
  );
};

export default ProductTable;
