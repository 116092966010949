import React from 'react';
import { Commas } from '../../Utilities/Utilities';
import './style.scss';

const TotalAmount = ({ totalAmount }) => {
  return (
    <div className="G-total-wrap">
      <p>Հանրագումարը {Commas(totalAmount)} դրամ</p>
    </div>
  );
};

export default TotalAmount;
