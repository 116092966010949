import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import Pagination from '../../../App/Components/Pagination/Pagination';
import EventAddForm from './EventAddForm';
import './style.scss';
import usePartner from '../../hooks/usePartner';
import PartnersTable from './PartnersTable';
import PartnerFilter from './PartnerFilter';
const Partners = () => {
  const { getPartners, partnerData, isLoading, errorMess, setErrorMess } =
    usePartner();
  const [showAddForm, setShowAddForm] = useState(false);
  const [partnerParam, setPartnersParam] = useState({
    searchString: '',
    partnerType: 0,
    pageNumber: 0,
  });
  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };
  useEffect(() => {
    getPartners(partnerParam);
  }, [partnerParam]);

  const handlePageChange = (newPageNumber) => {
    setPartnersParam((prevState) => ({
      ...prevState,
      pageNumber: newPageNumber,
    }));
  };

  return (
    <>
      <Header pageTitle="Գործընկերներ" handelClick={onClose} />

      <PartnerFilter
        partnerParam={partnerParam}
        setPartnersParam={setPartnersParam}
      />
      <PartnersTable
        partnersList={partnerData?.partners}
        isLoading={isLoading}
      />

      <Pagination
        pageCount={partnerData?.pageCount?.pageCount}
        setPageNumber={handlePageChange}
        currentPage={partnerParam.pageNumber}
      />
      {showAddForm && (
        <EventAddForm
          handleClose={onClose}
          params={partnerParam}
          errorMess={errorMess}
          setErrorMess={setErrorMess}
        />
      )}
    </>
  );
};

export default Partners;
