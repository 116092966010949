import React, { useEffect, useState } from 'react';
import Modal from '../../../../App/Components/Modal/Modal';
import CalculationSheetTable from './CalculationSheetTable';
import CalculationSheetAction from './CalculationSheetAction';
import useCalculationSheet from '../../../hooks/useCalculationSheet';
import CalculationSheetHeader from './CalculationSheetHeader';
import CalculationSheetTotal from './CalculationSheetTotal';
import CalculationSheetPrintForm from './CalculationSheetPrintForm';
import './style.scss';
const CalculationSheetModal = ({ handleClose, rowValues }) => {
  const { empNo } = rowValues || {};
  const {
    getCalculationSheet,
    calculationSheet,
    calculationData,
    getSalaryCalculator,
    getSalaryCalculatorDate,
    totals,
    setTotals,
    removeCalculationSheetItem,
    getCalculationPrint,
    calculationPrintData,
    errorMess,
    setErrorMess,
  } = useCalculationSheet();
  const [salaryMonthValue, setSalaryMonthValue] = useState({});
  const [showPrintForm, setShowPrintForm] = useState(false);
  const isValid = calculationSheet?.yearMonth !== salaryMonthValue?.value;

  const onTogglePrintForm = () => {
    setShowPrintForm((prevState) => !prevState);
  };

  useEffect(() => {
    if (empNo) {
      getCalculationSheet(empNo);
    }
  }, [empNo]);

  const onToCalculate = async (yearMonth) => {
    try {
      await getSalaryCalculator(empNo, yearMonth);
    } catch (error) {}
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-calculation-sheet-modal"
      title="Հաշվարկային թերթիկ"
    >
      <CalculationSheetHeader calculationSheet={calculationSheet} />
      <CalculationSheetAction
        calculationSheet={calculationSheet}
        onToCalculate={onToCalculate}
        getSalaryCalculatorDate={getSalaryCalculatorDate}
        salaryMonthValue={salaryMonthValue}
        setSalaryMonthValue={setSalaryMonthValue}
        isValid={isValid}
        onToCalculatePrint={onTogglePrintForm}
      />
      <CalculationSheetTable
        mainData={calculationData}
        isValid={isValid}
        removeCalculationSheetItem={removeCalculationSheetItem}
        empNo={empNo}
        errorMess={errorMess}
        setErrorMess={setErrorMess}
      />
      {calculationData && calculationData?.length > 0 && (
        <CalculationSheetTotal total={totals} setTotals={setTotals} />
      )}
      {showPrintForm && (
        <CalculationSheetPrintForm
          handleClose={onTogglePrintForm}
          empNo={empNo}
          getCalculationPrint={getCalculationPrint}
          calculationPrintData={calculationPrintData}
        />
      )}
    </Modal>
  );
};

export default CalculationSheetModal;
