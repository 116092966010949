import React from 'react';
import { useFormik } from 'formik';
import { fieldConfigs } from './fieldConfigs';
import './organization.scss';
import useOrganizationDetails from '../../Hooks/organizationDetails/useOrganizationDetails';
import InputField from '../../Components/InputFiled/InputFiled';
import { SelectControl } from '../../Components/SelectControl/SelectControl';
import RadioButton from '../../Components/RadioButton/RadioButton';
import Checkbox from '../../Components/Checkbox/Checkbox';
import Button from '../../Components/Button/Button';
import { register } from '../../Utilities/Utilities';

const OrganizationForm = ({ onSubmit }) => {
  const { organizationData } = useOrganizationDetails();
  const mainClassName = 'G-organization-form-field';
  let dropDownOptions = [];
  const formik = useFormik({
    initialValues: { ...organizationData?.company },
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
    enableReinitialize: true,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    isValid,
    dirty,
  } = formik;
  const onDropdownChange = (fieldName, dropdownId, selectedOption) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: '',
      };
    }
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id;

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
  };

  return (
    <form className="G-organization-form" onSubmit={handleSubmit}>
      <div className="G-organization-form-content">
        {fieldConfigs.map((field, index) => {
          const {
            fieldType,
            fieldName,
            label,
            name,
            required,
            Children,
            optionName,
            dropdownTitle,
            type,
            dropdownId,
            radioBtn,
            radioTitle,
            maxLength,
            requiredFiled,
            title,
          } = field;
          const isRequired =
            typeof required === 'function' ? required(values) : required;
          const isLength =
            typeof maxLength === 'function' ? maxLength(values) : maxLength;
          const fieldError = touched[name] && errors[name];

          if (typeof Children === 'function' && organizationData) {
            dropDownOptions = Children(organizationData[optionName]);
          }
          switch (fieldType) {
            case 'input':
              return (
                <div key={index} className={mainClassName}>
                  <InputField
                    type={type}
                    inputTitle={title}
                    name={name}
                    requiredField={isRequired}
                    changeHandler={handleChange}
                    value={values[name]}
                    maxLength={isLength}
                    error={fieldError}
                    onBlur={handleBlur}
                    requiredFiled={requiredFiled}
                  />
                </div>
              );
            case 'dropdown':
              return (
                <div key={index} className={mainClassName}>
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={requiredFiled}
                    value={{
                      label: values[fieldName] || '',
                      value: values[fieldName] || '',
                    }}
                    onChange={(selectedOption) =>
                      onDropdownChange(fieldName, dropdownId, selectedOption)
                    }
                    options={dropDownOptions || []}
                  />
                </div>
              );
            // case 'checkbox':
            //   return (
            //     <div key={index} className={`${mainClassName} `}>
            //       <Checkbox
            //         name={name}
            //         label={label}
            //         value={values[name]}
            //         checked={values[name]}
            //         onChange={() => setFieldValue(name, !values[name])}
            //         onBlur={handleBlur}
            //       />
            //     </div>
            //   );
            case 'radio':
              return (
                <div key={index} className={`${mainClassName} `}>
                  <p className="G-title-p">{radioTitle}</p>
                  <div className="radio-btn-flex">
                    {radioBtn?.map(({ value, label }, i) => (
                      <div className="G-radio-but-wrap" key={i}>
                        <RadioButton
                          name={name}
                          value={value}
                          checked={value === values[name]}
                          onChange={() => setFieldValue(name, value)}
                          label={label}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );

            default:
              return null;
          }
        })}
      </div>
      <div className="G-organization-submit-form">
        <div className="G-organization-submit-form-btn">
          <Button
            text={register}
            customClass={!isValid || !dirty ? 'G-disabled-button' : ''}
            disabled={!isValid || !dirty}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </form>
  );
};

export default OrganizationForm;
