import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import Pagination from '../../Components/Pagination/Pagination';
import SearchBar from '../../Components/SearchForm/SearchBar';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import {
  getServicesAction,
  loadingAction,
} from '../../Redux/serviceWork/useAction';
import EventAddForm from './EventAddForm';
import ServiceWorkTable from './ServiceWorkTable';
import Button from '../../Components/Button/Button';
import { printHandler, printText } from '../../Utilities/Utilities';
import useFetchExcel from '../../Hooks/useFetchExcel';
import ServicesWorkExcel from './ServicesWorkExcel';

const GetServiceWorkData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { serviceData, isLoading } = useSelector(
    (state) => state.getServiceData,
  );

  const [pageNumber, setPageNumber] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const dispatch = useDispatch();
  const { fetchExcelData } = useFetchExcel();
  const getData = (pageNumber, searchValue = '') => {
    const trimmedSearchValue = searchValue?.trim();
    dispatch(loadingAction(true));
    getRequest(
      `getServiceNames?companyID=${currentCompanyID}&pageNumber=${
        pageNumber + 1
      }&searchstring=${trimmedSearchValue}`,
    )
      .then((res) => {
        dispatch(getServicesAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    getData(pageNumber, searchValue);
  }, [pageNumber, dispatch]);

  const openAddForm = () => {
    setOpenAddModal(true);
  };

  const handlePrint = (id) => {
    printHandler(`printServiceNames?companyID=${currentCompanyID}`, dispatch);
  };

  const getExcelData = () => {
    const trimmedSearchValue = searchValue?.trim();
    const url = `getServiceNameForExcel?companyID=${currentCompanyID}&searchString=${trimmedSearchValue}`;

    return fetchExcelData(url);
  };

  return (
    <div className="G-small-wrap">
      <Header pageTitle="Ծառայություն / աշխատանք" handelClick={openAddForm} />
      <SearchBackground>
        <div className="G-flex">
          <SearchBar
            getData={getData}
            pageNumber={pageNumber}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setPageNumber={setPageNumber}
            name="searchstring"
          />
          <div className="G-view-btn">
            <Button text={printText} onClick={handlePrint} />
          </div>
          <ServicesWorkExcel getExcelData={getExcelData} />
        </div>
      </SearchBackground>
      <ServiceWorkTable
        mainData={serviceData?.serviceNames}
        loading={isLoading}
      />
      <Pagination
        pageCount={serviceData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />

      {openAddModal && (
        <EventAddForm
          closeModal={setOpenAddModal}
          getData={getData}
          pageNumber={pageNumber}
        />
      )}
    </div>
  );
};

export default GetServiceWorkData;
