import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchCalculationData,
  fetchExecuteCalculation,
} from '../services/fetchCalculationData';

const useCalculationData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [calculationData, setCalculationData] = useState([]);
  const [calculationTableData, setCalculationTableData] = useState(null);

  const getCalculationData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchCalculationData(currentCompanyID);
      setCalculationData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExecuteCalculationData = async (params) => {
    setIsLoading(true);
    try {
      const data = await fetchExecuteCalculation(currentCompanyID, params);
      setCalculationTableData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCalculationData();

    return () => {
      setError(null);
      setIsLoading(false);
    };
  }, [currentCompanyID]);

  return {
    calculationData,
    isLoading,
    error,
    setError,
    getExecuteCalculationData,

    calculationTableData,
    setCalculationTableData,
  };
};

export default useCalculationData;
