import React from "react";
import { useDispatch } from "react-redux";
import Calendar from "../../Components/Calendar/Calendar";
import InputFiled from "../../Components/InputFiled/InputFiled";
import { updateInvoiceDateAction } from "../../Redux/invoiceSlices/useAction";
import { dataTimeFormat, dateFormat } from "../../Utilities/Utilities";

const InvoiceCompilationDate = ({ handleClose, mainData }) => {
  const dispatch = useDispatch();

  const changeSerialNumberHandler = (e) => {
    const { name, value } = e.currentTarget;
    dispatch(updateInvoiceDateAction({ [name]: value }));
  };

  const onChangeInvoiceDate = (date) => {
    dispatch(updateInvoiceDateAction({ invoiceDate: dataTimeFormat(date) }));
  };

  return (
    <div className="L-invoice-compilation-date-block G-flex ">
      <div className="L-invoice-compilation-input">
        <InputFiled
          inputTitle="Հ/ա համարը"
          name="serialNumber"
          value={mainData?.serialNumber}
          changeHandler={changeSerialNumberHandler}
        />
      </div>
      <div className="G-form-data-picker">
        <p className="G-title-p">Կազմման ամսաթիվը</p>
        <Calendar
          value={dateFormat(mainData?.invoiceDate)}
          changeHandler={(date) => onChangeInvoiceDate(date)}
          name="invoiceDate"
        />
      </div>
      <div className="G-modal-partner-name">
        <h3>{mainData?.buyerName}</h3>
      </div>
    </div>
  );
};

export default InvoiceCompilationDate;
