import { getRequest, postRequest } from '../../App/Api/Api';
import { dataTimeFormat } from '../../App/Utilities/Utilities';

export const fetchBenefitCalculation = async (companyID, salaryDate, empNo) => {
  try {
    const response = await getRequest(
      `getEmployeeBenefit?companyID=${companyID}&salaryDate=${dataTimeFormat(
        salaryDate,
      )}&empNo=${empNo}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchBenefitIsValid = async (
  companyID,
  empNo,
  stNo,
  benStartDate,
  benEndDate,
) => {
  const formatBenStartDate = dataTimeFormat(benStartDate);
  const formatBenEndDate = dataTimeFormat(benEndDate);
  try {
    const response = await getRequest(
      `benefitDateValid?companyID=${companyID}&empNo=${empNo}&stNo=${stNo}&benStartDate=${formatBenStartDate}&benEndDate=${formatBenEndDate}
      `,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const saveBenefitCalculation = async (data) => {
  try {
    const response = await postRequest('saveEmployeeBenefit', data);

    return response.data;
  } catch (error) {
    console.error('Error adding benefitCalculation:', error);
    throw error;
  }
};

export const saveBenefitAverages = async (data) => {
  try {
    const response = await postRequest('saveAverages', data);

    return response.data;
  } catch (error) {
    console.error('Error adding saveAverages:', error);
    throw error;
  }
};

export const fetchBenAverages = async (
  companyId,
  empNo,
  vacStartDate,
  avgDays,
) => {
  try {
    let url = `getAverages?companyID=${companyId}&empNo=${empNo}&vacStartDate=${dataTimeFormat(
      vacStartDate,
    )}&avgDays=${avgDays}`;
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchBenefitNonWorkings = async (companyId, empNo) => {
  try {
    let url = `getBenefitNonWorkings?companyID=${companyId}&empNo=${empNo}`;

    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
