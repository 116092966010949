import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, putRequest } from '../../Api/Api';

import {
  getInvoiceFormAction,
  updateInvoiceTableDataAction,
} from '../../Redux/invoiceSlices/useAction';
import { dataTimeFormat, windowReload } from '../../Utilities/Utilities';
import InvoiceForm from './InvoiceForm';

const EditInvoiceWrapper = ({ closeModal, rowData, setErrorHandler }) => {
  const { invoiceID, partnerID, currencyCode } = rowData || {};
  const { invoiceFormData } = useSelector((state) => state.getInvoiceData);
  const { invoice, invoiceGoods } = invoiceFormData || {};
  const { currentCompanyID } = useSelector((state) => state.auth);

  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = () => {
      getRequest(
        `getInvoiceGoods?companyID=${currentCompanyID}&partnerID=${partnerID}&invoiceID=${invoiceID}`,
      )
        .then((res) => {
          dispatch(getInvoiceFormAction(res.data));
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    };

    fetchData();
  }, [currentCompanyID, dispatch, invoiceID, partnerID]);

  const cloneInvoice = {
    companyID: currentCompanyID,
    partnerID: partnerID,
    currencyID: invoice?.currencyID,
    invoiceID: invoiceID,
    invoiceDate: dataTimeFormat(invoice?.invoiceDate),
    serialNumber: invoice?.serialNumber,
    exchangeRate: Number(invoice?.exchangeRate),
    companyBankingAccount: invoice?.supplierBankAccount,
    companyBankName: invoice?.supplierBankName,
    partnerBankingAccount: invoice?.buyerBankAccount,
    partnerBankName: invoice?.buyerBankName,
    prePayment: Number(invoice?.prePayment),
    prePaymentAMD: Number(invoice?.prePayment),
  };

  const editInvoiceData = () => {
    putRequest('editInvoice', {
      invoice: {
        ...cloneInvoice,
      },
      invoiceGoods: [...invoiceGoods],
    })
      .then((res) => {
        if (res.data.errorCode === 0 && invoice.currencyCode !== currencyCode) {
          windowReload();
        }
        if (res.data.errorCode === 0) {
          dispatch(updateInvoiceTableDataAction(cloneInvoice));
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <InvoiceForm
        closeModal={handleClose}
        partnerID={partnerID}
        registerInvoiceData={editInvoiceData}
        cloneInvoice={cloneInvoice}
      />
    </>
  );
};

export default EditInvoiceWrapper;
