import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import UnitOfMeasureForm from './UnitOfMeasureForm';

const EventAddForm = ({
  getData,
  pageNumber,
  closeModal,
  updateUnitOfMeasureAction,
  updateUnitOfMeasureOutside,
  customUnitOfMeasureData,
}) => {
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = () => closeModal(false);

  const dispatch = useDispatch();
  const initialValue = {
    unitCode: '',
    unitName: '',
  };

  const onSubmit = (values, resetForm) => {
    postRequest('addunit', values)
      .then((res) => {
        if (res.data.errorCode === 0 && !updateUnitOfMeasureOutside) {
          getData(pageNumber);
          resetForm();
          handleClose();
        }
        if (res.data.errorCode === 0 && updateUnitOfMeasureOutside) {
          dispatch(updateUnitOfMeasureAction([values]));
          customUnitOfMeasureData({ ...values, unitID: res.data.id });
          resetForm();
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <UnitOfMeasureForm
        onSubmit={onSubmit}
        initialData={initialValue}
        closeModal={handleClose}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventAddForm;
