import React from 'react';
import deleteIcon from '../../Assets/icons/deleteIcon.png';
import pencil from '../../Assets/icons/pencil.svg';
import defaultUserImg from '../../Assets/image/initUserP.png';

const UserImage = ({ img, onDeleteUserImg, openModal }) => (
  <div className="user-img user-data-block-solid">
    <div className="user-img-block G-flex">
      <img
        src={img ? `data:image/jpeg;base64,${img}` : defaultUserImg}
        alt="User"
      />
      <div className="delete-icon-box" onClick={onDeleteUserImg}>
        <img src={deleteIcon} alt="delete icon" />
      </div>
    </div>
    <span className="pencil-box" onClick={() => openModal('img')}>
      <img src={pencil} alt="edit icon" />
    </span>
  </div>
);

export default UserImage;
