import React from 'react';
import { NavLink } from 'react-router-dom';
import DataEntry from '../../icons/dataEntry.png';
import Report from '../../icons/report.png';
import { ReactComponent as OpenClose } from '../../icons/openClose.svg';
import './menu.scss';

import TopArrow from '../TopArrow/TopArrow';
const Menu = () => {
  const router = [
    {
      path: '/DataEntry',
      name: 'Տվյալների մուտքագրում',
      icon: DataEntry,
      activeIcon: OpenClose,
      id: 99,
    },

    {
      path: '/BudgetSummary',
      name: 'Բյուջեի ամփոփում',
      icon: Report,
      activeIcon: OpenClose,
      id: 55,
    },
  ];

  return (
    <div className="menu-bar">
      <div className="menu-list">
        <ul>
          <li className="personal-budget">
            <a href="Home">
              <TopArrow />
            </a>
          </li>
          <div className="router-bar">
            {router.map((item) => (
              <li key={item.id} className="menu-item">
                <NavLink to={item.path} exact={item.path === '/DataEntry'}>
                  <img src={item.icon} alt="" />
                  <span className="hidden">{item.name}</span>
                </NavLink>
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
