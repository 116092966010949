import React from 'react';
import Table from '../../../../App/Components/Table/Table';
import { Commas } from '../../../../App/Utilities/Utilities';
import Tooltip from '../../../../App/Components/Tooltip/Tooltip';
const EmployeeTableList = ({ mainData }) => {
  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 160 },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ազգանուն, Անուն',
      cell: (row) => row.emplName,
      customStyle: { maxWidth: 170 },
    },

    {
      title: 'Պաշտոնը',
      cell: (row) => row.positionName,
      customStyle: { maxWidth: 150 },
    },
    {
      title: <Tooltip content="Վճարման ենթակա">Վճ. ենթակա</Tooltip>,
      cell: (row) => Commas(row.toBePayed),
      customStyle: { maxWidth: 140 },
    },
  ];
  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-employee-table-list"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : null}
    </>
  );
};

export default EmployeeTableList;
