import React from "react";
import isEqual from "lodash/isEqual";
import { useDispatch } from "react-redux";
import { editSpendingMoneyDealsAction } from "../../Redux/accountForSpendingMoneySlices/useAction";
import SpendingForm from "./SpendingForm";
import { removeCommas } from '../../Utilities/Utilities';

const EventEditForm = ({ rowData, closeModal, setSubmitted }) => {
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(
      editSpendingMoneyDealsAction({
        ...values,
        price: removeCommas(values.price),
        amount: values.quantity * removeCommas(values.price),
      }),
    );
    const equalObj = isEqual(values, rowData);
    setSubmitted(!equalObj);
    handleClose();
  };

  return (
    <SpendingForm
      initialValue={{ ...rowData, isNew: true }}
      onSubmit={onSubmit}
      closeModal={handleClose}
    />
  );
};

export default EventEditForm;
