import { createAction } from "../actionCreators";
import {
  GET_CIRCULATION_DATA,
  LOADING,
  CHECK_ALL_DEBIT,
  CHECK_DEBIT,
  CHECK_ALL_CREDIT,
  CHECK_CREDIT,
  GET_SUMMARY_DATA,
} from './constants';

export const getCirculationAction = createAction(GET_CIRCULATION_DATA);
export const loadingAction = createAction(LOADING, false);


export const checkAllDebitAction = createAction(CHECK_ALL_DEBIT);
export const checkSingleDebitAction = createAction(CHECK_DEBIT);
export const checkAllCreditAction = createAction(CHECK_ALL_CREDIT);
export const checkSingleCreditAction = createAction(CHECK_CREDIT);
export const getSummaryAction = createAction(GET_SUMMARY_DATA);
