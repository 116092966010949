import { useState, useEffect } from 'react';
import { getRequest } from '../Api/Api';

const useGetDocNum = (currentCompanyID, docDate) => {
  const [docNum, setDocNum] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDocNum = async () => {
      try {
        const response = await getRequest(
          `getDocNum?companyID=${currentCompanyID}&docTypeID=${3}&docDate=${docDate}`,
        );
        setDocNum(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchDocNum();

    return () => {};
  }, [currentCompanyID, docDate]);

  return { docNum, setDocNum, loading, error };
};

export default useGetDocNum;
