import {
  DELETE_SPENDING_MONEY,
  GET_SPENDING_MONEY_DATA,
  LOADING,
  GET_SPENDING_DEALS_DATA,
  UPDATE_CASH_MONEY_DATA,
  ADD_SPENDING_MONEY_DEALS_DATA,
  EDIT_SPENDING_MONEY_DEALS_DATA,
  DELETE_SPENDING_MONEY_DEALS_DATA,
  UPDATE_PARTNER_SPENDING_MONEY,
  IS_CLOSE_DOCUMENT,
  UPDATE_ACCOUNT_MONEY_FORM,
} from './constants';

const initialState = {
  spendingMoneyData: null,
  isLoading: false,
  spendingDealsData: null,
};

export const spendingMoneyReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case GET_SPENDING_MONEY_DATA:
      return {
        ...state,
        spendingMoneyData: payload,
        isLoading: true,
      };
    case DELETE_SPENDING_MONEY:
      const filteredData = state.spendingMoneyData?.cashMoneys.filter(
        (item) => item.cashMoneyID !== payload,
      );
      return {
        ...state,
        spendingMoneyData: {
          ...state.spendingMoneyData,
          cashMoneys: filteredData,
        },
      };

    case UPDATE_ACCOUNT_MONEY_FORM:
      return {
        ...state,
        spendingMoneyData: {
          ...state?.spendingMoneyData,
          cashMoneys: state.spendingMoneyData?.cashMoneys?.map((item) => {
            return item.cashMoneyID === payload.cashMoneyID ? payload : item;
          }),
        },
      };
    case UPDATE_PARTNER_SPENDING_MONEY:
      return {
        ...state,
        spendingMoneyData: {
          ...state.spendingMoneyData,
          partners: [...state.spendingMoneyData.partners, ...payload],
        },
      };
    case GET_SPENDING_DEALS_DATA:
      return {
        ...state,
        spendingDealsData: payload,
      };
    case UPDATE_CASH_MONEY_DATA:
      return {
        ...state,
        spendingDealsData: {
          ...state.spendingDealsData,
          cashMoney: { ...state.spendingDealsData.cashMoney, ...payload },
        },
      };
    case ADD_SPENDING_MONEY_DEALS_DATA:
      return {
        ...state,
        spendingDealsData: {
          ...state.spendingDealsData,
          expenses: [...state.spendingDealsData.expenses, payload],
        },
      };
    case EDIT_SPENDING_MONEY_DEALS_DATA:
      return {
        ...state,
        spendingDealsData: {
          ...state.spendingDealsData,
          expenses: state.spendingDealsData.expenses.map((item) => {
            return item.expenseID === payload.expenseID ? payload : item;
          }),
        },
      };
    case DELETE_SPENDING_MONEY_DEALS_DATA:
      return {
        ...state,
        spendingDealsData: {
          ...state.spendingDealsData,
          expenses: state.spendingDealsData?.expenses?.filter(
            (item) => item.expenseID !== payload,
          ),
        },
      };

    case IS_CLOSE_DOCUMENT:
      return {
        ...state,
        spendingMoneyData: {
          ...state.spendingMoneyData,
          cashMoneys: state.spendingMoneyData.cashMoneys.map((item) => {
            return item.cashMoneyID == payload
              ? { ...item, isClosed: !item.isClosed }
              : item;
          }),
        },
      };

    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return { ...state };
  }
};
