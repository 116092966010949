import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { authReducer } from './authSlices/reducer';
import { dashboardReducer } from './dashboardSlices/reducer';
import { organizationReducer } from './organizationSlices/reducer';
import { circulationReducer } from './circulationNewsletterSlices/reducer';
import { operationsReducer } from './operationsSlices/reducer';
import { partnerCirculationBulletinReducer } from './partnerCirculationBulletinSlices/reducer';
import { purchaseActReducer } from './purchaseActSlices/reducer';
import { spendingMoneyReducer } from './accountForSpendingMoneySlices/reducer';
import { cashWarrantReducer } from './cashWarrantSlices/reducer';
import { cashOutflowReducer } from './cashOutflowOrderSlices/reducer';
import { chartOfAccountReducer } from './chartOfAccountSlices/reducer';
import { paymentOrderReducer } from './paymentOrderSlices/reducer';
import { partnerReducer } from './partnerSlices/reducer';
import { invoicingAccountReducer } from './eInvoicingAccountsSlices/reducer';
import { invoicingOperationReducer } from './eInvoicingOperationsSlices/reducer';
import { bankReducer } from './bankSlices/reducer';
import { initialBalancesReducer } from './InitialBalancesSlices/reducer';
import { serviceReducer } from './serviceWork/reducer';
import { productReducer } from './productSlices/reducer';
import { unitOfMeasureReducer } from './unitOfMeasureSlices/reducer';
import { invoiceReducer } from './invoiceSlices/reducer';
import { currenciesReducer } from './currenciesSlices/reducer';
import { filterRowDataReducer } from './FilterRowData/reducer';
import { helpDetailReducer } from './helpDetailSlices/reducer';
import { printReducer } from './printSlices/printSlices';
import { calendarReducer } from './calendarSlices/reducer';
import { subDivisionReducer } from './SalaryStor/subdivisionsSlices/reducer';
import { employeesReducer } from './SalaryStor/employeesSlices/reducer';
import { positionsReducer } from './SalaryStor/positionsSlices/reducer';
import { savingsReducer } from './SalaryStor/savingsSlices/reducer';
import { nonWorkingDayReducer } from './SalaryStor/nonWorkingDaysSlices/reducer';
import { additionsReducer } from './SalaryStor/additionsTypesSlices/reducer';
import { tablesReducer } from './SalaryStor/tablesSlices/reducer';
import { assignAdditionalStorageReducer } from './SalaryStor/assignAdditionalStorage/reducer';
import { nonWorkingDaysReducer } from './SalaryStor/nonWorkingDays/reducer';
import { workWithEmployeesReducer } from './SalaryStor/workWithEmployeesSlices/reducer';
import { materialValuesGroupReducer } from './materialValuesStor/materialValueGroupSlices/warehouseSlices/reducer';
import { warehouseReducer } from './materialValuesStor/warehouseSlices/reducer';
import { materialValueNameReducer } from './materialValuesStor/materialValuesNameSlices/reducer';
import { wareHouseEntryReducer } from './materialValuesStor/warehouseEntrySlices/reducer';
import { materialValuesEInvoicingReducer } from './materialValuesStor/matValuesReceivedEInvoicing/reducer';

const config = {
  key: 'root',
  //storage: AsyncStorage,
  storage,
};
const configPrint = {
  key: 'printUrl',
  storage,
};

export default combineReducers({
  auth: persistReducer(config, authReducer),
  getPrintParam: persistReducer(configPrint, printReducer),
  getDashboardData: dashboardReducer,
  getOrganizationData: organizationReducer,
  getChartOfAccountData: chartOfAccountReducer,
  getBankData: bankReducer,
  getInitialBalances: initialBalancesReducer,
  getServiceData: serviceReducer,
  getProductData: productReducer,
  getUnitOfMeasureData: unitOfMeasureReducer,
  getInvoicingAccount: invoicingAccountReducer,
  getInvoicingOperation: invoicingOperationReducer,
  getPartnerData: partnerReducer,
  getCashWarrantData: cashWarrantReducer,
  getPurchaseActData: purchaseActReducer,
  getOperationsData: operationsReducer,
  getCirculationData: circulationReducer,
  getPartnerCirculationBulletin: partnerCirculationBulletinReducer,
  getSpendingMoneyData: spendingMoneyReducer,
  getCashOutflowData: cashOutflowReducer,
  getPaymentOrderData: paymentOrderReducer,
  getInvoiceData: invoiceReducer,
  getCurrenciesData: currenciesReducer,
  filteredId: filterRowDataReducer,
  helpDetailName: helpDetailReducer,
  getCalendarData: calendarReducer,
  subDivisionData: subDivisionReducer,
  employeesData: employeesReducer,
  positionsData: positionsReducer,
  savingData: savingsReducer,
  nonWorkingDayData: nonWorkingDayReducer,
  additionsTypes: additionsReducer,
  tablesData: tablesReducer,
  assignStorage: assignAdditionalStorageReducer,
  nonWorkingDays: nonWorkingDaysReducer,
  workWithEmployees: workWithEmployeesReducer,
  materialValuesGroup: materialValuesGroupReducer,
  warehouse: warehouseReducer,
  materialValueName: materialValueNameReducer,
  wareHouseEntry: wareHouseEntryReducer,
  materialValEInvoicing: materialValuesEInvoicingReducer,
});
