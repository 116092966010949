import React, { useState } from 'react';
import Input from '../../../components/Input/Input';
import AddNew from '../../../../App/Elements/Add/AddNew';
import EventAddForm from '../../materialValuesNames/EventAddForm';

const AuthenticatedHeader = ({
  matValueName,
  selectMatValueNo,
  setSelectMatValueNo,
  setAuthenticatedData,
}) => {
  const [openNewItemForm, setOpenNewItemForm] = useState(false);

  const toggleMatValueForm = () => setOpenNewItemForm((prev) => !prev);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSelectMatValueNo((prev) => ({
      ...prev,
      matValueNo: Number(value),
    }));
  };

  const updateSelectValues = (newValue) => {
    setAuthenticatedData((prev) => ({
      ...prev,
      matValues: [
        ...(prev.matValues || []),
        {
          matValueName: newValue.matValueName,
          matValueNo: newValue.matValueNo,
          groupName: newValue.groupName,
        },
      ],
    }));
  };

  return (
    <>
      <div className="L-authenticated-header">
        <h3>{matValueName}</h3>
        <div className="L-identification-code-wrap">
          <Input
            requiredField={true}
            type="number"
            inputTitle="Կոդը"
            name="matValueNo"
            value={selectMatValueNo?.matValueNo || ''}
            maxLength={16}
            onChange={handleInputChange}
          />
          <AddNew onClick={toggleMatValueForm} content="Կոդ" />
        </div>
      </div>
      {openNewItemForm && (
        <EventAddForm
          formIsUsedExternally={true}
          handleClose={toggleMatValueForm}
          setFieldValue={(key, value) =>
            setSelectMatValueNo((prev) => ({
              ...prev,
              [key]: value,
            }))
          }
          addToCurrentData={true}
          updateSelectValues={updateSelectValues}
        />
      )}
    </>
  );
};

export default AuthenticatedHeader;
