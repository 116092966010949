import React from 'react';
import Arrow from '../Arrow/Arrow';
import Toolbar from '../Toolbar/Toolbar';
import './action.scss';

const Action = ({
  handelChange,
  row,
  actionItem,
  mainAction = 'edit',
  mainActionName = 'Խմբագրել',
  show = false,
  actionMess = null,
  modalIsOpen,
  onClick,
  showArrow = true,
}) => {
  return (
    <>
      {show ? (
        <div
          className={`${actionMess ? 'partnerAccText' : 'G-flex-justify-end'}`}
          style={{ width: '100%' }}
          onClick={() => onClick(row)}
        >
          {actionMess !== null ? actionMess : showArrow ? <Arrow /> : null}
        </div>
      ) : (
        <div className="G-action">
          <p onClick={() => handelChange(mainAction, row)}>{mainActionName}</p>
          <Toolbar modalIsOpen={modalIsOpen} list={actionItem}>
            <ul className="G-toolbar-block">
              {actionItem?.map((item, index) => {
                return (
                  <li
                    title={item.name}
                    key={index}
                    onClick={() => handelChange(item.value, row)}
                  >
                    {item.name}
                  </li>
                );
              })}
            </ul>
          </Toolbar>
        </div>
      )}
    </>
  );
};

export default Action;
