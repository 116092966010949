import {
  ADD_FORM_DATA,
  ADD_SAVING,
  DELETE_SAVING,
  FETCH_SAVINGS,
  UPDATE_SAVING,
} from './constants';

const initialState = {
  savings: null,
  savingsForm: null,
};

export const savingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SAVINGS:
      return {
        ...state,
        savings: payload,
      };
    case ADD_SAVING:
      return {
        ...state,
        savings: payload,
      };
    case ADD_FORM_DATA:
      return {
        ...state,
        savingsForm: payload,
      };
    case UPDATE_SAVING:
      return {
        ...state,
        savings: {
          ...state.savings,
          dedTypes: state.savings.dedTypes?.map((i) => {
            return i.salaryTypeID === payload.salaryTypeID ? payload : i;
          }),
        },
      };
    case DELETE_SAVING:
      return {
        ...state,
        savings: {
          ...state.savings,
          dedTypes: state.savings.dedTypes.filter(
            (item) => item.salaryTypeID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
