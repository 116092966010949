import React from "react";
import { useDispatch } from "react-redux";
import { addSpendingMoneyDealsAction } from "../../Redux/accountForSpendingMoneySlices/useAction";
import { randomID, removeCommas } from '../../Utilities/Utilities';
import SpendingForm from './SpendingForm';

const EventAddForm = ({ closeModal, setSubmitted }) => {
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  const initialValue = {
    expenseID: randomID(),
    documentName: '',
    purpose: '',
    quantity: '',
    price: '',
    fromData: false,
    isNew: true,
  };

  const onSubmit = (values, resetForm) => {
    if (values) {
      dispatch(
        addSpendingMoneyDealsAction({
          ...values,
          price: removeCommas(values.price),
          amount: values.quantity * removeCommas(values.price),
        }),
      );
      setSubmitted(true);
      resetForm();
      handleClose();
    }
  };

  return (
    <SpendingForm
      initialValue={initialValue}
      onSubmit={onSubmit}
      closeModal={handleClose}
    />
  );
};

export default EventAddForm;
