import React, { useMemo } from 'react';
import Calendar from '../../../Components/Calendar/Calendar';
import Seducement from '../../../Assets/icons/exchange.svg';
import { ReactComponent as RateIncreaseDecrease } from '../../../Assets/icons/rateDecrease.svg';
import DashboardTitle from '../DashboardTitle';
import Table from '../../../Components/Table/Table';

const ExchangeRate = (props) => {
  const { dateStart, data, setDateStart } = props;
  const ratesData = data !== null ? data?.rate : [];

  const columnConfig = useMemo(
    () => [
      {
        title: '',
        cell: (row) => row.iso,
        customStyle: { maxWidth: 120 },
      },
      {
        title: '',
        cell: (row) => row.amount,
        customStyle: { maxWidth: 80 },
      },
      {
        title: '',
        cell: (row) => row.rate,
        customStyle: { maxWidth: 120 },
      },
      {
        title: '',
        cell: (row) => (
          <div className="L-rate-increase-decrease G-flex">
            <span
              className={`L-rate-level-up  ${
                row.difference > 0 ? 'L-rate-level-down' : ''
              }`}
            >
              <RateIncreaseDecrease />
            </span>
            <p>{row.difference}</p>
          </div>
        ),
        customStyle: { maxWidth: 100 },
      },
    ],
    [],
  );

  return (
    <div className="L-exchange-rate G-block-style G-table-has-scroll">
      <div className="G-flex">
        <DashboardTitle blockTitle="ՀՀ ԿԲ փոխարժեքը դրամով" icon={Seducement} />
        <div className="G-dashboard-calendar">
          <Calendar
            selected={dateStart}
            name="dateStart"
            changeHandler={(e) => setDateStart(e, 'dateStart')}
            maxDate={new Date()}
            removeMinDate={true}
          />
        </div>
      </div>
      {ratesData !== null && ratesData && ratesData?.rates?.length && (
        <Table
          customClass="L-exchange-rate-table"
          data={ratesData?.rates}
          columnConfig={columnConfig}
        />
      )}
    </div>
  );
};

export default ExchangeRate;
