import React from "react";
import { useDispatch } from "react-redux";
import { deleteRequest } from "../../Api/Api";
import AcceptOrCancelModal from "../../Components/AcceptOrCancelModal/AcceptOrCancelModal";
import { deleteInvoiceActAction } from '../../Redux/invoiceSlices/useAction';

const DeleteInvoice = ({ closeModal, deleteRowId, setErrorHandler }) => {
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  const deleteRowDate = () => {
    deleteRequest(`deleteInvoice/${deleteRowId}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deleteInvoiceActAction(deleteRowId));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  };

  return (
    <AcceptOrCancelModal closeModal={closeModal} confirmClick={deleteRowDate} />
  );
};

export default DeleteInvoice;
