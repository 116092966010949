import React from "react";
import { useMemo } from "react";
import { SelectControl } from "../SelectControl/SelectControl";
import "./main.scss";

const SelectCurrency = (props) => {
  const {
    currencyVal,
    currencyData,
    selectChangeCurrency,
    disabled,
    requiredFiled = true,
    customClass,
  } = props;

  const currencyOptions = useMemo(() => {
    return (
      currencyData &&
      currencyData?.map(
        ({ currencyCode, currencyID, credCurrCode, credCurrID }) => {
          return {
            label: currencyCode || credCurrCode,
            id: currencyID || credCurrID,
            value: currencyCode || credCurrCode,
          };
        },
      )
    );
  }, [currencyData]);

  return (
    <div className={`${customClass} G-currency-wrapper`}>
      <SelectControl
        requiredFiled={requiredFiled}
        selectBoxTitle="Արժույթ"
        id="currency"
        options={currencyOptions}
        value={currencyVal}
        onChange={selectChangeCurrency}
        disabled={disabled}
        isSearchable={false}
      />
    </div>
  );
};

export default SelectCurrency;
