import { createAction } from "../actionCreators";
import {
  DELETE_BANK,
  EDIT_BANK_DATA,
  GET_BANK_DATA,
  LOADING,
} from "./constants";

export const getBankDataAction = createAction(GET_BANK_DATA);
export const loadingAction = createAction(LOADING, false);
export const deleteBankDataAction = createAction(DELETE_BANK);
export const editBankDataAction = createAction(EDIT_BANK_DATA);
