import * as Yup from 'yup';
import {
  EnterNumberOnly,
  InputFiledMessage,
  SelectFieldMessage,
} from './ErrorMessage';
import {
  curNum,
  emailRegex,
  positiveNumberValidation,
  regexUseStrNum,
  textValidation,
} from './REG_EXP';

export const EmployeeValidation = () =>
  Yup.object().shape({
    lastName: textValidation.required(InputFiledMessage),
    firstName: textValidation.required(InputFiledMessage),
    empNo: curNum.required(InputFiledMessage),
    socPercent: positiveNumberValidation.required(InputFiledMessage),
    depName: Yup.string().required(SelectFieldMessage),
    positionName: Yup.string().required(SelectFieldMessage),
    genderName: Yup.string().required(SelectFieldMessage),
    rentName: Yup.string().required(SelectFieldMessage),
    cardAcc: Yup.string()
      .matches(/^\d*(\.\d+)?$/, EnterNumberOnly)
      .max(25, 'Առավելագույնը 25 նիշ')
      .required(InputFiledMessage),
    socCardNo: Yup.string()
      .matches(/^\d*(\.\d+)?$/, EnterNumberOnly)
      .length(10, 'Պարտադիր 10 նիշ')
      .required(InputFiledMessage),
    hireDate: Yup.date().required(InputFiledMessage),
    birthDate: Yup.date().required(InputFiledMessage),
    patrName: textValidation,
    profession: textValidation,
    tradeUnionPerc: positiveNumberValidation,
    tAcc: positiveNumberValidation,
    cardNo: positiveNumberValidation,
    salaryFund: Yup.string().required(SelectFieldMessage),

    fireNum: Yup.string(),
    hireNum: Yup.string().required(InputFiledMessage),
    email: Yup.string().matches(emailRegex, 'Էլ․ հասցեն պետք է լինի վավեր'),
    phone: Yup.string().matches(/^[\d+()]+$/, 'Անհրաժեշտ է Թիվ'),
    enName: Yup.string().matches(/^[a-z ]+$/, 'Մուտքագրել միայն լատինատառ'),
  });
