import React from "react";
import { useMemo } from "react";
import { SelectControl } from "../SelectControl/SelectControl";

const SelectBank = (props) => {
  const {
    bankData,
    bankVal,
    selectChangeBank,
    title = 'Բանկային հաշիվը',
    id,
    customClass,
    requiredFiled = false,
    onBlur,
  } = props;

  const bankOptions = useMemo(() => {
    return (
      bankData &&
      bankData?.map(({ bankingAccount, bankName, companyBankID, comment }) => {
        return {
          label: bankingAccount + ' ' + bankName + ' ' + comment,
          id: companyBankID,
          value: bankingAccount,
          bankName: bankName,
        };
      })
    );
  }, [bankData]);

  return (
    <div className={`G-bank-wrapper  ${customClass}`}>
      <SelectControl
        selectBoxTitle={title}
        requiredFiled={requiredFiled}
        id={id}
        options={bankOptions}
        value={bankVal}
        onChange={selectChangeBank}
        onBlur={onBlur}

      />
    </div>
  );
};

export default SelectBank;
