import React from 'react';
import { useDispatch } from 'react-redux';
import OrganizationForm from './OrganizationForm';
const CreateOrganization = () => {
  // const { errorMess, setErrorMess,  } =
  // useWarehouseEntry();
  const dispatch = useDispatch();

  const handleCreateOrganization = (values, resetForm) => {
    try {
      //await addWarehouseEntry(values);
      // if (errorMess) {
      //   resetForm();
      // }
    } catch (error) {
      console.error(error.message);
    }
  };

  return <OrganizationForm onSubmit={handleCreateOrganization} />;
};

export default CreateOrganization;
