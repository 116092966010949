import React, { useEffect, useState } from 'react';
import AdditionsTypeTable from './AdditionsTypeTable';
import ExcelForm from './ExcelForm';
import Header from '../../../App/Components/Header/Header';
import useAdditionsTypes from '../../hooks/useAdditionsTypes';
import EventAddForm from './EventAddForm';
import './index.scss';
import { ConvertAdditionsExcel } from '../../Config/excelConfig';
const AdditionsType = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const { fetchAdditionsTypesData, additionsType } = useAdditionsTypes();
  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };
  useEffect(() => {
    fetchAdditionsTypesData();
  }, []);

  const dataToExcel = ConvertAdditionsExcel(additionsType?.asTypes);

  return (
    <>
      <Header pageTitle="Հավելումների տեսակները" handelClick={onClose} />
      <ExcelForm dataToExcel={dataToExcel} />
      <AdditionsTypeTable mainData={additionsType?.asTypes} />
      {showAddForm && <EventAddForm handleClose={onClose} />}
    </>
  );
};

export default AdditionsType;
