import React, { useState } from 'react';
import { useFormik } from 'formik';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import InputFiled from '../../Components/InputFiled/InputFiled';
import Modal from '../../Components/Modal/Modal';
import { SelectControl } from '../../Components/SelectControl/SelectControl';
import { PurchaseProductValidation } from '../../Validation/PurchaseValidation';
import { useSelector } from 'react-redux';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import SelectAccount from '../../Components/SelectBox/SelectAccount';
import AddNew from '../../Elements/Add/AddNew';
import AddProductForm from '../../Pages/Product/EventAddForm';
import AddUnitOfMeasureForm from '../../Pages/UnitOfMeasure/EventAddForm';
import {
  updateProductAction,
  updateUnitOfMeasureAction,
} from '../../Redux/purchaseActSlices/useAction';
import NumericFormatFiled from '../../Components/NumericFormat/NumericFormatFiled';
import SelectUnitsOfMeasurement from '../../Components/SelectBox/SelectUnitsOfMeasurement';

const ProductForm = (props) => {
  const { handleClose, initialValue, onSubmit } = props;
  const { purchaseFormData } = useSelector((state) => state.getPurchaseActData);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openUnitOfMeasureModal, setOpenUnitOfMeasureModal] = useState(false);
  const [productData, setProductData] = useState({});
  const [unitOfMeasureData, setUnitOfMeasureData] = useState({});

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...initialValue,
      ...productData,
      ...unitOfMeasureData,
    },
    validationSchema: PurchaseProductValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const productOptions =
    purchaseFormData &&
    purchaseFormData?.productNames?.map(({ productName, productNameID }) => {
      return {
        label: productName,
        value: productName,
        id: productNameID,
      };
    });

  const handleChangeProduct = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('productNameID', values.id);
    setFieldValue('productName', values.value);
  };

  const handleChangeUnit = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('unitID', values.id);
    setFieldValue('unitName', values.value);
  };

  const handleChangeDebitAccount = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('accountID', values.id);
    setFieldValue('accountCode', values.value);
  };

  const openProductForm = () => {
    setOpenProductModal(true);
  };

  const openUnitOfMeasureForm = () => {
    setOpenUnitOfMeasureModal(true);
  };

  return (
    <Modal
      closeHandler={handleClose}
      onDrag={true}
      customClass="L-purchase-product-modal"
    >
      <div className="L-purchase-product-form">
        <form onSubmit={onSubmit}>
          <div className="G-flex L-product-wrap">
            <div className="L-product-box L-product-select-wrapper G-relative">
              <SelectControl
                requiredFiled={true}
                selectBoxTitle="Ապրանքի անվանումը"
                matchFromParam="start"
                id="productNameID"
                options={productOptions}
                onBlur={handleBlur}
                value={{ label: values.productName, value: values.productName }}
                onChange={(values) => handleChangeProduct(values)}
              />
              {touched.productName && errors.productName && (
                <ErrorFiled error={errors.productName} />
              )}
            </div>
            <AddNew content="ապրանք" onClick={openProductForm} />
          </div>
          <div className="G-flex L-product-wrap">
            <div className="L-unitName-box L-product-select-wrapper G-relative">
              <SelectUnitsOfMeasurement
                options={purchaseFormData?.units}
                value={{ label: values.unitName, value: values.unitName }}
                onChange={(values) => handleChangeUnit(values)}
              />
              {touched.unitName && errors.unitName && (
                <ErrorFiled error={errors.unitName} />
              )}
            </div>
            <AddNew content="Չափման միավոր" onClick={openUnitOfMeasureForm} />
          </div>

          <div className="L-debit-box  G-relative">
            <SelectAccount
              customClass="L-product-select-wrapper"
              title="Դեբետ"
              accountData={purchaseFormData?.debetAccounts}
              accountVal={{
                label: values.accountCode,
                value: values.accountCode,
              }}
              selectChangeAccount={(values) => handleChangeDebitAccount(values)}
            />

            {touched.accountID && errors.accountID && (
              <ErrorFiled error={errors.accountID} />
            )}
          </div>
          <div className="L-purchase-product-input G-relative">
            <NumericFormatFiled
              value={values.price}
              name="price"
              onBlur={handleBlur}
              changeHandler={handleChange}
              inputTitle="Գինը"
              requiredFiled={true}
            />
            {touched.price && errors.price && (
              <ErrorFiled error={errors.price} />
            )}
          </div>
          <div className="L-purchase-product-input G-relative">
            <InputFiled
              inputTitle="Քանակը"
              requiredFiled={true}
              type="number"
              name="quantity"
              value={values.quantity}
              onBlur={handleBlur}
              changeHandler={handleChange}
            />
            {touched.quantity && errors.quantity && (
              <ErrorFiled error={errors.quantity} />
            )}
          </div>
          <GetSentDataBtn
            cancelPrintClick={handleClose}
            confirmExecuteClick={handleSubmit}
            executeClass={!(isValid && dirty) ? 'G-disabled-button' : ''}
            executeDisabled={!(isValid && dirty)}
          />
        </form>
      </div>
      {openProductModal && (
        <AddProductForm
          closeModal={setOpenProductModal}
          updateProductOutside={true}
          updateProductAction={updateProductAction}
          customProductState={setProductData}
        />
      )}
      {openUnitOfMeasureModal && (
        <AddUnitOfMeasureForm
          closeModal={setOpenUnitOfMeasureModal}
          updateUnitOfMeasureOutside={true}
          updateUnitOfMeasureAction={updateUnitOfMeasureAction}
          customUnitOfMeasureData={setUnitOfMeasureData}
        />
      )}
    </Modal>
  );
};

export default ProductForm;
