import React from 'react';
import { Commas } from '../../../App/Utilities/Utilities';

const EmployeePositionTotal = ({ total }) => {
  const { addAmount, hours } = total || {};

  return (
    <div className="vacation-total-section">
      <h4 className="vacation-total-label">Ընդամենը</h4>
      <p className="vacation-total-value">{hours?.toFixed(2)}</p>
      <p className="vacation-total-value">{Commas(addAmount)}</p>
    </div>
  );
};

export default EmployeePositionTotal;
