import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSavings,
  deleteSavings,
  fetchSavings,
  fetchSavingsForm,
  updateSavings,
} from '../services/fetchSavings';
import {
  addSavingFormAction,
  deleteSavingAction,
  fetchSavingsAction,
  updateSavingAction,
} from '../../App/Redux/SalaryStor/savingsSlices/useAction';

const useSavingsForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState('');
  const { currentCompanyID } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const savingData = useSelector((state) => state.savingData?.savings);
  const savingFormData = useSelector((state) => state.savingData?.savingsForm);

  const fetchSavingsData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchSavings(currentCompanyID);
      dispatch(fetchSavingsAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addSaving = async (savingData, closeFormCallback) => {
    try {
      const { errorCode, message } = await addSavings(savingData);
      if (errorCode === 0) {
        fetchSavingsData();
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateSaving = async (savingData, closeFormCallback) => {
    setIsLoading(true);

    try {
      const { errorCode, message } = await updateSavings(savingData);
      if (message) {
        setErrorMess(message);
      } else if (errorCode === 0) {
        dispatch(updateSavingAction(savingData));
        closeFormCallback();
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeSaving = async (salaryTypeID, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await deleteSavings(salaryTypeID);
      if (errorCode === 0) {
        dispatch(deleteSavingAction(salaryTypeID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSavingFormData = async (salaryTypeID) => {
    setIsLoading(true);
    try {
      const data = await fetchSavingsForm(currentCompanyID, salaryTypeID);
      dispatch(addSavingFormAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    fetchSavingsData,
    addSaving,
    updateSaving,
    removeSaving,
    fetchSavingFormData,
    savingData,
    savingFormData,
    errorMess,
    setErrorMess,
  };
};

export default useSavingsForm;
