export const getBankOptions = (banks) => {
  return (
    banks?.map(({ bankName, bankCode, companyBankID }) => ({
      label: bankName,
      value: bankCode,
      id: companyBankID,
    })) || []
  );
};

export const getPaymentNumOptions = (paymentNums) => {
  return (
    paymentNums?.map(({ paymentsNumName, paymentsNum }) => ({
      label: paymentsNumName,
      value: paymentsNumName,
      id: paymentsNum,
    })) || []
  );
};
