import React from "react";
import Modal from "../../Components/Modal/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorFiled from "../../Components/ErrorFiled/ErrorFiled";
import InputFiled from "../../Components/InputFiled/InputFiled";
import GetSentDataBtn from "../../Components/GetSentDataBtn/GetSentDataBtn";
import { InputFiledMessage } from "../../Validation/ErrorMessage";
import { textFiledLength } from '../../Utilities/Utilities';

const ServicesWorkForm = ({ closeModal, onSubmit, initialData }) => {
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    initialValues: initialData,
    validationSchema: Yup.object({
      serviceName: Yup.string().max(100).required(InputFiledMessage),
    }),
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  return (
    <Modal closeHandler={closeModal} onDrag={true} customClass="G-form-modal">
      <div className="G-form-content">
        <form onSubmit={onSubmit}>
          <div className="G-form-input-wrapper">
            <InputFiled
              requiredFiled={true}
              inputTitle="Ծառայություն / աշխատանք"
              name="serviceName"
              value={values.serviceName}
              changeHandler={handleChange}
              onBlur={handleBlur}
              maxLength={textFiledLength}
            />
            {touched.serviceName && errors.serviceName && (
              <ErrorFiled error={errors.serviceName} />
            )}
          </div>

          <GetSentDataBtn
            cancelPrintClick={closeModal}
            confirmExecuteClick={handleSubmit}
            executeClass={!isValid || !dirty ? 'G-disabled-button' : ''}
            executeDisabled={!isValid || !dirty}
          />
        </form>
      </div>
    </Modal>
  );
};

export default ServicesWorkForm;
