import React from 'react';
import { useState } from 'react';
import SalaryCalculationDateForm from './SalaryCalculationDateForm';
import './style.scss';
import useSalaryCalculationDate from '../../hooks/useSalaryCalculationDate';
import { dateFormat } from '../../../App/Utilities/Utilities';

const SalaryCalculationDate = () => {
  const [showModal, setShowModal] = useState(false);
  const { calculationDate, saveNewCalculationDate } =
    useSalaryCalculationDate();

  const onShow = () => {
    setShowModal((prevState) => !prevState);
  };

  return (
    <>
      <div className="G-salary-calculation-date-block">
        <p className="G-title-p">Հաշվարկի ամսաթիվը</p>
        <button onClick={onShow}>
          {dateFormat(calculationDate?.salaryDate)}
        </button>
      </div>

      {showModal && (
        <SalaryCalculationDateForm
          calculationDate={calculationDate}
          onClose={onShow}
          saveNewCalculationDate={saveNewCalculationDate}
        />
      )}
    </>
  );
};

export default SalaryCalculationDate;
