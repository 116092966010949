import React from 'react';
import Header from '../../../App/Components/Header/Header';

const MaterialValuesDashboard = () => {
  return (
    <div>
      <Header headerBtn={false} />
    </div>
  );
};

export default MaterialValuesDashboard;
