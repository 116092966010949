import React, { useCallback } from 'react';
import Calendar from '../../Components/Calendar/Calendar';
import Checkbox from '../../Components/Checkbox/Checkbox';
import SelectCurrency from '../../Components/SelectBox/SelectCurrency';
import SelectPartnerType from '../../Components/SelectBox/SelectPartnerType';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import { dateFormat, viewPrintTitle } from '../../Utilities/Utilities';
import Button from '../../Components/Button/Button';
import SearchFiled from '../../Components/SearchFiled/SearchFiled';
import { ExportExcel } from '../../Components/Excelexport/Excelexport';
import ConvertDataExcel from './ConvertDataExcel';

const PartnerBalanceBookAction = React.memo((props) => {
  const {
    printHandler,
    currencyVal,
    setCurrencyVal,
    date,
    setDate,
    viewAccounts,
    setViewAccounts,
    partnerTypeVal,
    setPartnerTypeVal,
    searchValue,
    setSearchValue,
    mainData,
  } = props;

  const { data } = useFetchCurrency();

  const changeCurrency = useCallback(
    (value) => {
      if (!value) {
        value = {
          value: '',
        };
      }
      setCurrencyVal(value);
    },
    [setCurrencyVal],
  );

  const changePartnerType = useCallback(
    (value) => {
      if (!value) {
        value = {
          value: '',
          id: 0,
        };
      }
      setPartnerTypeVal(value);
    },
    [setPartnerTypeVal],
  );

  const dataForExcel = ConvertDataExcel(mainData?.balances);
  return (
    <div className="partner-balance-book">
      <div className="G-form-data-picker">
        <p className="G-title-p">Ամսաթիվ</p>
        <Calendar
          value={dateFormat(date)}
          changeHandler={(value) => setDate(value)}
        />
      </div>

      <div className="G-partner-type-box">
        <SelectPartnerType
          partnerTypeVal={partnerTypeVal}
          selectChangePartner={changePartnerType}
        />
      </div>
      <SearchFiled searchValue={searchValue} setSearchValue={setSearchValue} />
      <SelectCurrency
        currencyData={data?.currencies}
        currencyVal={currencyVal}
        selectChangeCurrency={changeCurrency}
      />
      <div className="L-book-check-account">
        <Checkbox
          value={!viewAccounts}
          onChange={(e) => setViewAccounts(e.target.checked)}
          label="Դիտել հաշիվներով"
        />
      </div>

      <div className="G-print-excel-block">
        <div className="G-view-btn ">
          <Button text={viewPrintTitle} onClick={printHandler} />
        </div>
        <ExportExcel excelData={dataForExcel} />
      </div>
    </div>
  );
});

export default PartnerBalanceBookAction;
