import React from "react";
import { dateFormat } from "../../Utilities/Utilities";
import Tooltip from "../../Components/Tooltip/Tooltip";
import Loading from "../../Components/Loading/Loading";
import Table from "../../Components/Table/Table";

const SettlementDocumentTable = ({ loading, mainData }) => {
  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.supplierDate),
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Գործընկերներ',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 1600 },
    },
    {
      title: 'ՀՎՀՀ',
      cell: (row) => row.partnerTaxCode,
      customStyle: { maxWidth: 100 },
    },
    {
      title: <Tooltip content="Սերիա և համարը">Ս/Հ</Tooltip>,
      cell: (row) => row.invoiceSerialNumber,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Արժեքը',
      cell: (row) => row.amount,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'ԱԱՀ',
      cell: (row) => row.valueAddedTax,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Ստորագրված է',
      cell: (row) => row.signedByTheBuyer,
      customStyle: { maxWidth: 150 },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-settlement-document-table G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default SettlementDocumentTable;
