import {
  DELETE_BANK,
  EDIT_BANK_DATA,
  GET_BANK_DATA,
  LOADING,
} from "./constants";

const initialState = {
  bankData: null,
  isLoading: false,
};

export const bankReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BANK_DATA:
      return {
        ...state,
        bankData: payload,
        isLoading: true,
      };
    case EDIT_BANK_DATA:
      return {
        ...state,
        bankData: {
          ...state.bankData,
          banks: state.bankData.banks.map((bank) => {
            return bank.bankID === payload.bankID ? payload : bank;
          }),
        },
      };
    case DELETE_BANK:
      return {
        ...state,
        bankData: {
          ...state.bankData,
          banks: state.bankData?.banks.filter(
            (item) => item.bankID !== payload
          ),
        },
      };
    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return { ...state };
  }
};
