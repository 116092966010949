import React, { useEffect, useState } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import './style.scss';
import useWarehouseEntryOperations from '../../hooks/useWarehouseEntryOperations';
import EntryOrderOperationTable from './EntryOrderOperationTable';
import RemoveEntryOperation from './RemoveEntryOperation';
import Button from '../../../App/Components/Button/Button';
const EntryOrderOperations = ({ handleClose, matInputDocID }) => {
  const {
    getEntryOrderOperations,
    entryOperationData,
    isLoading,
    setEntryOperationData,
  } = useWarehouseEntryOperations();
  const [showRemoveForm, setShowRemoveForm] = useState(null);
  useEffect(() => getEntryOrderOperations(matInputDocID), [matInputDocID]);

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-entry-order-operations-form"
      title="Գործառնություններ"
    >
      <EntryOrderOperationTable
        entriesOperation={entryOperationData}
        isLoading={isLoading}
      />

      {entryOperationData?.length > 0 && (
        <div className="L-entry-order-delete-btn">
          <Button
            text="Ջնջել"
            onClick={() => setShowRemoveForm(matInputDocID)}
          />
        </div>
      )}
      {showRemoveForm && (
        <RemoveEntryOperation
          handleClose={() => setShowRemoveForm(false)}
          matInputDocID={showRemoveForm}
          setEntryOperationData={setEntryOperationData}
        />
      )}
    </Modal>
  );
};

export default EntryOrderOperations;
