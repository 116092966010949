import React, { useEffect, useMemo } from 'react';
import { SelectControl } from '../../../../App/Components/SelectControl/SelectControl';
import GetSentDataBtn from '../../../../App/Components/GetSentDataBtn/GetSentDataBtn';

const CalculationSheetAction = ({
  calculationSheet,
  onToCalculate,
  onToCalculatePrint,
  getSalaryCalculatorDate,
  salaryMonthValue,
  setSalaryMonthValue,
  isValid,
}) => {
  const salaryMonthOptions = useMemo(() => {
    return (
      calculationSheet &&
      calculationSheet?.salaryMonths?.map(
        ({ salaryMonthName, year, month, yearMonth }) => {
          return {
            label: salaryMonthName,
            value: yearMonth,
            year: year,
            month: month,
            yearMonth: yearMonth,
          };
        },
      )
    );
  }, [calculationSheet]);

  const closureDateHandler = async (values) => {
    const query = {
      empNo: calculationSheet.empNo,
      year: values.year,
      month: values.month,
      yearMonth: values.yearMonth,
    };
    await getSalaryCalculatorDate(query);
    setSalaryMonthValue(values);
  };

  useEffect(() => {
    if (calculationSheet?.currentMonth) {
      const currentMonth = {
        label: calculationSheet?.currentMonth,
        value: calculationSheet?.yearMonth,
      };
      setSalaryMonthValue(currentMonth);
    }
  }, [calculationSheet, salaryMonthOptions]);

  return (
    <div className="L-calculation-sheet-action">
      <SelectControl
        selectBoxTitle="Հաշվարկի  ամսաթիվը"
        requiredFiled={false}
        value={{ label: salaryMonthValue.label }}
        onChange={closureDateHandler}
        options={salaryMonthOptions}
        id="salaryMonth"
        isClearable={false}
      />
      <GetSentDataBtn
        cancelPrintClickText="Հաշվարկ"
        confirmExecuteText="Տպել"
        cancelPrintClick={() => onToCalculate(salaryMonthValue.value)}
        confirmExecuteClick={onToCalculatePrint}
        customClass={isValid ? 'G-disabled-button' : ''}
        disabled={isValid}
      />
    </div>
  );
};

export default CalculationSheetAction;
