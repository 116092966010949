import { FETCH_WORK_WITH_EMPLOYEES, UPDATE_EMPLOYEE_SALARY } from './constants';

const initialState = {
  employeesInfo: null,
};

export const workWithEmployeesReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_WORK_WITH_EMPLOYEES:
      return {
        ...state,
        employeesInfo: payload,
      };

    case UPDATE_EMPLOYEE_SALARY:
      return {
        ...state,
        employeesInfo: {
          ...state.employeesInfo,
          employees: state.employeesInfo.employees?.map((employee) => {
            if (employee.empNo === payload.empNo) {
              const updatedEmployee = { ...employee, ...payload };

              if (payload.sAmount !== undefined) {
                updatedEmployee.salary = payload.sAmount;
                updatedEmployee.graphName = payload.graphName;
              }

              return updatedEmployee;
            }

            return employee;
          }),
        },
      };

    default:
      return { ...state };
  }
};
