import * as Yup from "yup";
import {
  SelectFieldMessage,
  InputFiledMessage,
  positiveDigitMes,
} from './ErrorMessage';
import { positiveDigits } from './REG_EXP';

export const InvoiceGoodsValidation = () =>
  Yup.object().shape({
    productNameID: Yup.string().required(SelectFieldMessage),
    unitID: Yup.string().required(SelectFieldMessage),
    amount: Yup.number()
      .required(InputFiledMessage)
      .positive('Մուտքագրեք 0-ից մեծ թիվ')
      .moreThan(0, 'Մուտքագրեք 0-ից մեծ թիվ'),
    price: Yup.string().required(InputFiledMessage),
  });


  

  export const InvoiceValidation = () =>
    Yup.object().shape({
      productNameID: Yup.string().required(SelectFieldMessage),
      unitID: Yup.string().required(SelectFieldMessage),
      amount: Yup.string()
        .matches(positiveDigits, positiveDigitMes)
        .required(InputFiledMessage),
      price: Yup.number().required(InputFiledMessage),
    });

  