export const entryOrderFormationFields = [
  {
    fieldType: 'radio',
    requiredRadioBtn: true,
    radioTitle: 'Փաստաթղթի տեսակը',
    label: 'Մուտք',
    value: 2,
    name: 'docTypeID',
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'docNum',
    label: 'Փաստաթուղթ N',
    value: '',
    required: true,
    maxLength: 8,
    disabled: (v) => {
      return !v.storeName;
    },
  },
  {
    fieldType: 'date',
    dateName: 'docDate',
    dateTitle: 'Կազմման ամսաթիվ',
    required: true,
  },
  {
    fieldType: 'fieldNameAndValue',
    title: 'Ձեռք բերման տեսակը',
    fieldValue: 'inputTypeName',
  },
  {
    fieldType: 'fieldNameAndValue',
    title: 'Մատակարար',
    fieldValue: 'partnerName',
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Պահեստ',
    required: true,
    fieldName: 'storeName',
    dropdownId: 'storeNo',
    optionName: 'stores',
    addNewItem: true,
    Children: (props) => {
      return props?.map((i) => ({
        label: i.storeName,
        id: i.storeNo,
        value: i.storeName,
      }));
    },
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'Մատակարարի հաշիվ',
    fieldName: 'partnerAccName',
    dropdownId: 'partnerAccCode',
    optionName: 'partnerAccounts',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.partnerAccName,
        id: i.partnerAccCode,
        value: i.partnerAccName,
        ident: i.ident,
      }));
    },
    required: (i) => {
      return i.docTypeID === 2;
    },
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'note',
    label: 'Նշումներ',
    value: '',
    required: false,
    maxLength: 100,
  },
];
