import React from 'react';
import Modal from '../../Components/Modal/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import { InputFiledMessage } from '../../Validation/ErrorMessage';
import InputFiled from '../../Components/InputFiled/InputFiled';
import { useSelector } from 'react-redux';
import { UnitsMeasureValidation } from '../../Validation/UnitsMeasureValidation';

const UnitOfMeasureForm = ({ closeModal, onSubmit, initialData }) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...initialData,
      companyID: currentCompanyID,
    },
    validationSchema: UnitsMeasureValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  return (
    <Modal closeHandler={closeModal} onDrag={true} customClass="G-form-modal">
      <div className="G-form-content">
        <form onSubmit={onSubmit}>
          <div className="G-form-input-wrapper">
            <InputFiled
              requiredFiled={true}
              inputTitle="Չափման միավորի համառոտ անվանումը"
              name="unitCode"
              value={values.unitCode}
              changeHandler={handleChange}
              onBlur={handleBlur}
              maxLength={20}
            />
            {touched.unitCode && errors.unitCode && (
              <ErrorFiled error={errors.unitCode} />
            )}
          </div>

          <div className="G-form-input-wrapper">
            <InputFiled
              requiredFiled={true}
              inputTitle="Անվանումը"
              name="unitName"
              value={values.unitName}
              changeHandler={handleChange}
              onBlur={handleBlur}
              maxLength={40}
            />
            {touched.unitName && errors.unitName && (
              <ErrorFiled error={errors.unitName} />
            )}
          </div>

          <GetSentDataBtn
            cancelPrintClick={closeModal}
            confirmExecuteClick={handleSubmit}
            executeClass={!isValid || !dirty ? 'G-disabled-button' : ''}
            executeDisabled={!isValid || !dirty}
          />
        </form>
      </div>
    </Modal>
  );
};

export default UnitOfMeasureForm;
