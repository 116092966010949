import React from 'react';
import { useState } from 'react';
import {  useSelector } from 'react-redux';
import {  putRequest } from '../../Api/Api';
import { useFormik } from 'formik';
import { useFetch } from '../../Hooks/useFetch';
import Button from '../../Components/Button/Button';
import Calendar from '../../Components/Calendar/Calendar';

import {
  dataTimeFormat,
  dateFormat,
  windowReload,
} from '../../Utilities/Utilities';
import './reportingPeriod.scss';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../Components/Alert';

const ReportingPeriod = () => {
  const { currentCompanyID, } = useSelector((state) => state.auth);
  const [data] = useFetch(`getSetting?companyID=${currentCompanyID} `);
  const [errorHandler, setErrorHandler] = useState('');
  const [presentClosure, setPresentClosure] = useState(false);

  const {
    handleSubmit,
    values,
    setFieldValue,
    isValid,
    dirty,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...data },

    onSubmit: (values) => onSubmit(values, setSubmitting),
  });

  const onChangeDate = (date, name) => {
    if (name === 'periodStart') {
      setFieldValue('periodStart', date);
    }
    if (name === 'periodEnd') {
      setFieldValue('periodEnd', date);
    }
  };

  const onSubmit = (values) => {
    putRequest('saveSetting', {
      ...values,
      periodStart: dataTimeFormat(values?.periodStart),
      periodEnd: dataTimeFormat(values?.periodEnd),
    })
      .then((res) => {
        setPresentClosure(false);
        if (res.data.errorCode === 0) {
          setErrorHandler('Ձեր փոփոխությունը հաջողությամբ գրանցվել է');
        }

        if (res.data.errorCode > 0) {
          setTimeout(() => {
            windowReload();
          }, 2000);
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <div className="L-reporting-period-wrapper">
        <div className="L-reporting-period-flex G-flex">
          <div className="L-reporting-period-form">
            <div className="G-form-data-picker">
              <p className="G-title-p">Հաշվետու ժամանակաշրջանի սկիզբը</p>
              <Calendar
                value={dateFormat(values.periodStart)}
                changeHandler={(date) => onChangeDate(date, 'periodStart')}
                name="periodStart"
              />
            </div>
            <div className="G-form-data-picker">
              <p className="G-title-p">Հաշվետու ժամանակաշրջանի վերջը</p>
              <Calendar
                value={dateFormat(values.periodEnd)}
                changeHandler={(date) => onChangeDate(date, 'periodEnd')}
                name="periodEnd"
              />
            </div>
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <div className="L-reporting-period-btn-flex">
            <div className="G-register-btn">
              <Button
                text="Հաստատել"
                onClick={handleSubmit}
                customClass={
                  !isValid || !dirty || isSubmitting ? 'G-disabled-button' : ''
                }
                disabled={!isValid || !dirty || isSubmitting}
              />
            </div>
          </div>
        </form>

        {presentClosure && (
          <AcceptOrCancelModal
            modalContent="հաշվետու ժամանակաշրջանի վերջում
           հաշիվների մնացորդների փակում է կատարված՝
           փոփոխության դեպքում ինքնաշխատ  կհեռացվեն 
            հաշիվների փակված մնացորդները, հաստատել փոփոխությունը"
            closeModal={setPresentClosure}
            confirmClick={handleSubmit}
          />
        )}
      </div>
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default ReportingPeriod;
