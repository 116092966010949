import React from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import usePositionsForm from '../../hooks/usePositionsForm';
import Alert from '../../../App/Components/Alert';
const DeletePositions = ({ id, handleClose }) => {
  const { removePositions, errorMess, setErrorMess } = usePositionsForm();
  const onRemoveHandler = async () => {
    try {
      await removePositions(id, handleClose);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default DeletePositions;
