import React from 'react';
import BenefitCalculation from '../../benefitCalculation/BenefitCalculation';
import Modal from '../../../../App/Components/Modal/Modal';

const BenefitCalculationModal = ({ handleClose, rowValues }) => {
  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-benefit-calculation-modal"
      title="Նպաստի հաշվարկ"
    >
      <BenefitCalculation rowValues={rowValues} handleClose={handleClose} />
    </Modal>
  );
};

export default BenefitCalculationModal;
