import React from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';

import Alert from '../../../App/Components/Alert';
import useNonWorkingDays from '../../hooks/useNonWorkingDays';

const DeleteNonWorkingDay = ({ id, handleClose }) => {
  const { removeNonWorkingDay, errorMess, setErrorMess } = useNonWorkingDays();
  const onRemoveHandler = async () => {
    try {
      await removeNonWorkingDay(id, handleClose);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default DeleteNonWorkingDay;
