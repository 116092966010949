import React from 'react';
import { useSelector } from 'react-redux';
import SelectAccount from '../../Components/SelectBox/SelectAccount';
import InputFiled from '../../Components/InputFiled/InputFiled';
const PurchaseSelectBox = ({
  data,
  selectChange,
  onChangeFromWhom,
  docBase,
}) => {
  const { purchaseFormData } = useSelector((state) => state.getPurchaseActData);
  const { purchase } = purchaseFormData || {};

  return (
    <div className="">
      <div className="L-purchase-select-wrapper">
        <SelectAccount
          title="Կրեդիտ"
          accountData={data?.creditAccounts}
          accountVal={{
            value: purchase?.accountCredit,
            label: purchase?.accountCredit,
          }}
          selectChangeAccount={(values) =>
            selectChange(values, 'accountCredit')
          }
        />
      </div>
      <div className="L-from-whom-form">
        <InputFiled
          inputTitle="Ումից"
          requiredFiled={true}
          name="docBase"
          value={docBase}
          changeHandler={onChangeFromWhom}
        />
      </div>
    </div>
  );
};

export default PurchaseSelectBox;
