import React from 'react';
import HeaderItem from '../assignAdditionalStorage/HeaderItem';

const CalculationSheetHeader = ({ calculationSheet }) => {
  const { depName, empNo, empName } = calculationSheet || {};

  const headerList = [
    {
      value: depName,
    },
    {
      name: 'Տաբել N',
      value: empNo,
    },
    {
      value: empName,
    },
  ];
  return (
    <div className="assign-storage-header">
      {headerList?.map((item) => (
        <HeaderItem key={item.value} name={item.name} value={item.value} />
      ))}
    </div>
  );
};

export default CalculationSheetHeader;
