import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import ThreeDigitBalanceTable from './ThreeDigitBalanceTable';
import { getRequest } from '../../Api/Api';
import { useDispatch, useSelector } from 'react-redux';
import {
  viewPrintTitle,
  dataTimeFormat,
  dateFormat,
  printHandler,
} from '../../Utilities/Utilities';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import Calendar from '../../Components/Calendar/Calendar';
import Button from '../../Components/Button/Button';
import { ExportExcel } from '../../Components/Excelexport/Excelexport';
import Alert from '../../Components/Alert/Alert';
import './style.scss';
import ConvertDataExcel from './ConvertDataExcel';

const ThreeDigitBalances = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [digitBalancesData, setDigitBalancesData] = useState([]);
  const [dateFormulation, setDateFormulation] = useState(new Date());
  const [errorHandler, setErrorHandler] = useState('');
  const formatDate = dataTimeFormat(dateFormulation);
  const dispatch = useDispatch();
  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await getRequest(
        `GetUnionAccounts?companyID=${currentCompanyID}&endDate=${formatDate}`,
      );

      if (response.data.message) {
        setErrorHandler(response.data.message);
      } else {
        setDigitBalancesData(response.data);
      }
    } catch (error) {
      console.log('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [currentCompanyID, formatDate]);

  const handlePrint = () => {
    printHandler(
      `printUnionAccounts?companyID=${currentCompanyID}&endDate=${formatDate}`,
      dispatch,
    );
  };

  const dataForExcel = ConvertDataExcel(digitBalancesData);
  return (
    <div>
      <Header
        headerBtn={false}
        pageTitle="Եռանիշ հաշիվների մնացորդների ստացում"
      />
      <SearchBackground>
        <div className="G-flex G-justify-between-align-center">
          <div className="G-form-data-picker">
            <p className="G-title-p">Ամսաթիվ</p>
            <Calendar
              openToDate={new Date(dateFormulation)}
              value={dateFormat(dateFormulation)}
              changeHandler={(date) => setDateFormulation(date)}
              name="endDate"
            />
          </div>
          <div className="G-print-excel-block">
            <div className="G-normal-btn">
              <Button onClick={handlePrint} text={viewPrintTitle} />
            </div>
            <ExportExcel excelData={dataForExcel} />
          </div>
        </div>
      </SearchBackground>
      <ThreeDigitBalanceTable
        mainData={digitBalancesData}
        loading={isLoading}
        dateFormulation={dateFormulation}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </div>
  );
};

export default ThreeDigitBalances;
