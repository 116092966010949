import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Action from '../../../App/Components/Table/Action';
import { tableActionTitle } from '../../../App/Utilities/Utilities';

import { tablesActionItems } from '../../Config/tableConfig';
import Forms from './Forms';
const TablesTable = ({ mainData }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Հ/Հ',
      cell: (row) => row.graphNo,
      customStyle: { maxWidth: 50 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.graphName,
      customStyle: { maxWidth: 500, overflow: 'hidden' },
    },
    {
      title: 'Տեսակը',
      cell: (row) => row.signName,
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Վճարման ձևը',
      cell: (row) => row.formName,
      customStyle: { maxWidth: 180 },
    },
    {
      title: 'Օրական ժամերը',
      cell: (row) => row.hours,
      customStyle: { maxWidth: 120 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(value, row) =>
            setShowForm({
              type: value,
              value: row,
            })
          }
          actionItem={tablesActionItems(row)}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 160 },
    },
  ];

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table customClass="" data={mainData} columnConfig={columnConfig} />
      ) : null}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default TablesTable;
