import React from 'react';
import Table from '../../../../App/Components/Table/Table';

const AuthenticatedTable = ({ authenticatedData, setSelectMatValueNo }) => {
  const columnConfig = [
    {
      title: 'ՆԱ Խումբը',
      cell: (row) => row.groupName,
      customStyle: { minWidth: 350, overflow: 'hidden' },
    },
    {
      title: 'Կոդը',
      cell: (row) => row.matValueNo,
      customStyle: { maxWidth: 140 },
    },
    {
      title: 'ՆԱ անվանումը',
      cell: (row) => row.matValueName,
      customStyle: { minWidth: 350, overflow: 'hidden' },
    },
  ];

  const handleRowClick = (row) => {
    setSelectMatValueNo((prev) => ({
      ...prev,
      matValueNo: row.matValueNo,
    }));
  };

  return (
    <>
      <Table
        customClass="G-table-has-scroll"
        data={authenticatedData}
        columnConfig={columnConfig}
        onTableClick={(row) => handleRowClick(row)}
      />
    </>
  );
};

export default AuthenticatedTable;
