import React, { useMemo } from 'react';
import { format } from 'date-fns';
import CalendarForm from '../CalendarForm/CalendarForm';
import { postRequest } from '../../App/Api/Api';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDayNoteAction } from '../../App/Redux/calendarSlices/useAction';


const EventAddForm = ({ closeHandler, info, fetchCalendarData }) => {
  const { userID } = useSelector((state) => state.auth);

  const { date, time } = info;

  const formattedCurrentDay = format(date, 'yyyy-MM-dd');
  const dispatch = useDispatch();
  const initialValues = useMemo(
    () => ({
      userId: userID,
      calId: 0,
      calName: '',
      title: '',
      description: '',
      label: 'green',
      href: '',
      date: formattedCurrentDay,

      delta: 0,
      id: 0,
      time: time,
      notAllow: false,
      allDay: false,
    }),
    [userID, formattedCurrentDay, time],
  );

  const onSubmit = useCallback(
    async (values, resetForm) => {
      let valDate = new Date(values.date).toDateString();
      let curDate = new Date().toDateString();

      try {
        const res = await postRequest('addCalendarEvent', {
          ...values,

          delta: Number(values.delta),
        });

        const { errorCode, message } = res.data;

        if (errorCode === 0) {
          fetchCalendarData();

          resetForm();
          closeHandler();
        }
        if (errorCode === 0 && valDate == curDate) {
          dispatch(
            addDayNoteAction({
              ...values,
              delta: Number(values.delta),
            }),
          );
        } else {
          console.error('Server error:', message);
        }
      } catch (err) {
        console.error('An error occurred:', err);
      }
    },
    [closeHandler, fetchCalendarData],
  );

  return (
    <CalendarForm
      closeHandler={closeHandler}
      currentDay={date}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
};

export default EventAddForm;
