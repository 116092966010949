import { dateFormat } from '../../Utilities/Utilities';

export const ConvertDataExcel = (data) => {
  return (
    data !== null &&
    data?.length > 0 &&
    data?.map((i) => {
      return {
        Ամսաթիվ: dateFormat(i.docDate),
        Փաստաթուղթ: i.docNum,
        Մեկնաբանություն: i.description,
        'Դեբետոր/Կրեդիտոր': i.partnerName,
        Հաշիվ: i.accountCode,
        Դեբետ: i.debitAmount,
        Կրեդիտ: i.creditAmount,
      };
    })
  );
};

export default ConvertDataExcel;
