import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest, getRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../Components/Alert';
import { deleteInitialBalancesAction } from '../../Redux/InitialBalancesSlices/useAction';
import { modalContent } from '../../Utilities/Utilities';
import { InitBalancesAllowDeleteText } from '../../GlobalTitle/GlobalTitle';

const DeleteInitialBalances = ({ closeModal, rowData }) => {
  const { initBalanceID, accountCode, companyID } = rowData || {};
  const [isAllowDelete, setIsAllowDelete] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAllowDelete() {
      try {
        const url = `preDeleteEditInitBalance?companyID=${companyID}&accountCode=${accountCode}`;
        const res = await getRequest(url);
        if (res.data.errorCode === 1) {
          setIsAllowDelete(true);
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    checkAllowDelete();
  }, [companyID, accountCode]);

  const deleteRowDate = () => {
    deleteRequest(`deleteInitBalance/${initBalanceID}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deleteInitialBalancesAction(initBalanceID));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  };

  console.log(isAllowDelete);

  return (
    <>
      <AcceptOrCancelModal
        closeModal={closeModal}
        confirmClick={deleteRowDate}
        modalContent={
          isAllowDelete ? InitBalancesAllowDeleteText : modalContent
        }
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default DeleteInitialBalances;
