export const HelperTextArr = [
  {
    path: '/userData',
    text: `Օգտվողի տվյալների փոփոխում։ Նկարագրություն՝
- Նկարի տեղադրում կամ փոփոխում: Փոփոխությունը պահպանեք է Հաստատել կոճակով, չեղարկեք Ընդհատել կոճակով, նկարը հեռացրեք աղբամանի վրա սեղմելով,
- Անձնական տվյալներ՝ օգտվողի անուն, ազգանուն, հեռախոսահամարն է: Փոփոխությունը պահպանեք  Գրանցել կոճակով,
- Մուտքանուն՝ էլեկտրոնային հասցեն է:  Փոփոխությունը պահպանեք  Գրանցել կոճակով, դրան հաջորդում է  նույնականացման գործընթացը, որից հետո գրանցեք  նոր հասցեն,
- Գաղտնագիր: Գաղտնագրի փոփոխության համար  մուտքագրեք ընթացիկ գաղտնաբառը, հետո նորը, ևս մեկ անգամ  լրացրեք նոր գաղտնաբառը  և պահպանեք Գրանցել կոճակով
`,
  },
  {
    path: '/Dashboard',
    text: `Վահանակում  ներկայացված են օպերատիվ տեղեկատվության աղյուսակներ, որոնք արտացոլում են, ընթացիկ օրվա դրությամբ, կազմակերպության ներքոհիշյալ տվյալները՝ 
- դրամական միջոցների մնացորդը,
- արտարժույթների փոխարժեքները՝  կենտրոնական բանկի տվյալներով,
- գործընկերների ընթացիկ մնացորդները,
- կիրառված արտարժույթների տասնօրյա փոփոխման գրաֆիկները
 Ամսաթվի փոփոխությամբ կարող եք  ստանալ վերը նշված տեղեկատվությունը ցանկացած օրվա դրությամբ:
`,
  },
  {
    path: '/Organization',
    text: `Կազմակերպության  տվյալների մուտքագրում: 
Տվյալների պարտադիր լրացման դաշտերը ձախից նշված են *-ով: Տվյալները ցանկացած պահին կարող եք խմբագրել  և սեղմել Գրանցել  կոճակը փոփոխությունը պահպանելու համար:
Տվյալների գրանցումից հետո  կբացվի Կազմակերպության տվյալները ձևը, որտեղ ավելանում են լրացման ենթակա դաշտեր՝  Հարկման համակարգի փոփոխման ամսաթիվը, Հաշվետու ժամանակաշրջան և Իմ բանկերը, Իմ գործընկերները, Իմ սկզբնական մնացորդները կետերը:
Որպես հարկման համակարգի փոփոխման ամսաթիվ՝ նախնական լրացված է հաշվետու ժամանակաշրջանի սկզբի ամսաթիվը։ Հարկման համակարգը կարող եք փոխել հաշվետու տարվա ընթացքում՝ համաձայն հարկային օրենսգրքի, հարկման հատուկ համակարգերից (միկրոձեռնարկատիրություն, շրջանառության հարկ,) հարկման ընդհանուր համակարգ (ԱԱՀ/Շահութահարկ), իսկ հակառակը  միայն հաշվետու տարվա սկզբին։
`,
  },
  {
    path: '/CompanyBank',
    text: `Կազմակերպության բանկային տվյալները։ Մուտքագրման համար սեղմեք Ավելացնել կոճակը: ՀՀ Բանկեր ցանկից ընտրեք բանկը, մուտքագրեք հաշվարկային հաշիվը։ Նշումներ դաշտը լրացուցիչ տեղեկությունների համար է՝ պարտադիր չէ լրացնել։ Բանկային տվյալները խմբագրեք Գործողություն սյան Խմբագրել կետը սեղմելով: Խմբագրումից հետո Գրանցել կոճակով պահպանեք փոփոխությունները:`,
  },
  {
    path: '/ChartOfAccounts',
    text: `Հաշիվների դիտում, ավելացում, խմբագրում: Հաշվային պլանի միանիշ, երկնիշ և եռանիշ հաշիվները օգտվողը հեռացնել չի կարող։ Եռանիշ հաշիվներին կարող եք ավելացնել ենթահաշիվներ։ Քառանիշ և ավել նիշ ունեցող հաշիվները կարող եք ավելացնել, հեռացնել, խմբագրել, արտարժույթ ավելացնել՝ եթե հաշիվը արտարժութային է։ Հաշվային պլանում ենթահաշիվ ավելացնելու համար պետք է մկնիկը պահեք այն անալիտիկ հաշվի տողի վրա, որին ավելացվելու է ենթահաշիվ, սեղմեք  տողի վերջին սյունակի Գործողություն սյան Ավելացնել կետը: Լրացրեք բացված ձևի դաշտերը՝ Հաշիվը տվյալ տողի հաշիվն է, փոփոխել չեք կարող, միայն կարող եք աջից մեկ նիշ ավելացնել, ընդ որում չկրկնվող, մուտքագրեք հաշվի անվանումը, սեղմեք Հաստատել կոճակը՝ ավելացումը պահպանելու համար:
    `,
  },
  {
    path: '/AccountCorrespondence',
    text: `Հաշիվների թույլատրելի թղթակցությունների ցանկը:
Որոնել դաշտում լրացրեք անհրաժեշտ հաշվի եռանիշ համարը, ընտրեք դեբետում թե կրեդիտում պետք է լինի որոնվող հաշիվը, համապատասխան դաշտում նշում կատարեք, կստանաք տվյալ հաշվի հետ թղթակցող հաշիվների ցանկը:
`,
  },
  {
    path: '/Banks',
    text: `ՀՀ տարածքում գործող բանկերի և մասնաճյուղերի ցանկն է: Տեղեկատուն  կազմակերպության և նրա գործընկերների բանկային տվյալները մուտքագրելու համար է, այն փոփոխման ենթակա չէ: Նոր բանկ կամ մասնաճյուղ մուտքագրելու համար պետք է դիմեք ադմինիստրատորին:`,
  },
  {
    path: '/InitialBalances',
    text: `Հաշվետու ժամանակաշրջանի սկզբի ամսաթվով ՀՀ դրամով և/կամ արտարժույթով սկզբնական մնացորդները:  Այստեղ հնարավոր է մուտքագրել  միայն հաշվային պլանում  նշված ոչ գործընկերային (դեբիտոր/կրեդիտոր) հաշիվների  սկզբնական մնացորդները ( պարտադիր լրացման ենթակա դաշտերը ձախից նշված են *-ով): Հաշվային պլանի  գործընկերային հաշիվների սկզբնական մնացորդները նախապես մուտքագրեք գործընկերներ տեղեկատուի գործողություն սյան Սկզբ․մնացորդ կետով. այդ հաշիվների հանրագումարները ինքնաշխատ կլրացվեն սկզբնական մնացորդների համապատասխան հաշիվներում:
    `,
  },
  {
    path: '/Partners',
    text: `Գործընկերների ավելացում, խմբագրում: Գործընկերոջ մուտքագրման համար սեղմեք Ավելացնել կոճակը,  
    մուտքագրեք պարտադիր լրացման դաշտերը (ձախից նշված են *-ով), սեղմեք Հաստատել  կոճակը՝ կբացվի
      գործընկերոջ բանկային տվյալները ավելացնելու պատուհանը։
Գործողություն սյան Սկզբ․ Մնացորդ կետը սեղմեք՝ մուտքագրեք գործընկերոջ սկզբնական մնացորդները,
 Բանկ կետով ավելացրեք գործընկերոջ բանկը։
Արտերկրի գործընկերոջ  դեպքում ՝ նշում կատարեք համապատասխան  շրջանակում և  մուտքագրեք տվյալները։
`,
  },
  {
    path: '/ServiceWork',
    text: `Ծառայություն/աշխատանքի  անվանումների մուտքագրում, խմբագրում: Առաջին անգամ մուտք գործելիս ցանկը դատարկ է: Սեղմեք Ավելացնել կոճակը,   լրացրեք դաշտը, սեղմեք Հաստատել կոճակը, ավելացրած ծառայությունը գրանցված կերևա աղյուսակում։ Գործողություն սյան Խմբագրել կետը Ծառայություն/աշխատանքի անվանումը փոփոխելու համար է: Մուտքագրված ծառայություն/աշխատանքների անվանումները կիրառվում են գնման ակտ և կանխավճարային/ փախանցման հաշիվ  փաստաթղթերում՝ բացվող ցուցակից ընտրելու համար:`,
  },
  {
    path: '/Product',
    text: `Օգտագործվող ապրանքների  անվանումների մուտքագրում, խմբագրում:       
Սեղմեք Ավելացնել կոճակը, լրացրեք ապրանքի անվանում դաշտը, սեղմեք Հաստատել կոճակը: 
Գործողություն սյան Խմբագրել կետը սեղմելով՝ խմբագրվում է Ապրանքի անվանումը,
 Հաստատել կոճակով պահպանել փոփոխությունը: Մուտքագրված ապրանքների անվանումները 
 կիրառվում են գնման ակտ և հաշիվ ապրանքագիր փաստաթղթերում՝ բացվող ցուցակից ընտրելու համար։
`,
  },
  {
    path: '/UnitOfMeasure',
    text: 'Չափի միավորների ստանդարտ/տիպային ցանկը` ավելացման, խմբագրման հնարավորությամբ: Չափման միավորներ մուտքագրելու համար սեղմեք Ավելացնել կոճակը,   մուտքագրեք չափի միավորի կրճատ անվանումը, սեղմեք Հաստատել: Գործողություն սյան Խմբագրել կետով խմբագրեք Չափի միավորների անվանումները: Մուտքագրված չափի միավորների կրճատ անվանումները կիրառվում են գնման ակտ և կանխավճարային/ փախանցման հաշիվ  փաստաթղթերում՝ բացվող ցուցակից ընտրելու համար:',
  },
  {
    path: '/ReportingPeriod',
    text: `Հաշվետու  ժամանակաշրջան՝  տարեսկզբի օր՝ 01․01, տարեվերջի օր՝ 31․12:   
Հաշվետու ժամանակաշրջանի  սկիզբը՝  ընթացիկ տարվա առաջին օրվա ամսաթիվն է, նախնական լրացված առաջարկվում է: Այն  կարող եք փոխել, եթե չկան  մուտքագրված սկզբնական մնացորդներ: Հաշվետու ժամանակաշրջանի վերջը՝  ընթացիկ տարվա վերջին օրվա ամսաթիվն  է,  այն կարող եք փոխել՝ մեծացնել հաշվետու ժամանակաշրջանի միջակայքը։
`,
  },
  {
    path: '/Currencies',
    text: `ՀՀ Կենտրոնական Բանկի կողմից հաստատված՝ միջազգայնորեն սահմանված բոլոր արտարժույթների ցանկը: Կարող եք ավելացնել , ջնջել  արտարժույթները։  Օգտվողի կիրառած արտարժույթների տասնօրյա փոփոխման գրաֆիկները ներկայացված են վահանակում։`,
  },
  {
    path: '/CashWarrant',
    text: `Դրամարկղի մուտքի օրդերի մուտքագրում: Դրամարկղի մուտքի օրդեր ստեղծելու համար սեղմեք Ավելացնել կոճակը, լրացրեք *-ով նշված պարտադիր դաշտերը: Փաստաթուղթը հաստատելուց ինքնաշխատ տրվում է գործառնություն, որը գրանցվում է ընդհանուր էջի Գործառնություններ կետում: Գործողություն սյան Խմբագրել կետը սեղմեք՝ խմբագրվում են փաստաթղթի տվյալները: Հաստատել կոճակով պահպանեք փոփոխությունները: Տպել կետը ստեղծում է  փաստաթղթի թղթային տարբերակը:  Սեղմեք Պատճենել կետը՝ կբացվի տվյալ տողի լրացված փաստաթուղթը՝ փոփոխված  ընթացիկ օրվա ամսաթվով և դրամարկղի մուտքի օրդերի հերթական հաջորդական համարով: Ջնջել կետով հեռացվում է և փաստաթուղթը, և գործառնությունը:`,
  },

  {
    path: '/CashOutflowOrders',
    text: `Դրամարկղի ելքի օրդերի մուտքագրում: Դրամարկղի ելքի օրդեր ստեղծելու համար սեղմեք Ավելացնել կոճակը, լրացրեք *-ով նշված պարտադիր դաշտերը: Փաստաթուղթը հաստատելուց ինքնաշխատ տրվում է գործառնություն, որը գրանցվում է ընդհանուր էջի Գործառնություններ կետում: Գործողություն սյան Խմբագրել կետը սեղմեք՝  խմբագրվում են փաստաթղթի տվյալները: Հաստատել կոճակով պահպանեք փոփոխությունները:  Տպել կետը ստեղծում է փաստաթղթի թղթային տարբերակը: Սեղմեք Պատճենել կետը՝ կբացվի տվյալ տողի լրացված փաստաթուղթը՝ փոփոխված  ընթացիկ օրվա ամսաթվով և դրամարկղի  ելքի օրդերի հերթական հաջորդական համարով: Ջնջել կետով հեռացվում է  և փաստաթուղթը, և գործառնությունը:`,
  },
  {
    path: '/PaymentOrder',
    text: `Վճարման հանձնարարագրի մուտքագրում: Վճարման հանձնարարագիր ստեղծելու համար սեղմեք Ավելացնել կոճակը, լրացրեք *-ով նշված պարտադիր դաշտերը: Վճարման հանձնարարագիր փաստաթղթի Ստացողի մասում նախատեսված է 3 տարբերակ՝
1․ Գործընկեր է,
2․ Իմ բանկային հաշիվների միջև,
3․ Գործընկեր չէ
Տարբերակներից յուրաքանչյուրի ընտրության դեպքում փաստաթղթում ակտիվ են միայն տարբերակին համապատասխանող լրացման ենթակա դաշտերը: Բոլոր դեպքերում էլ՝  ինքնաշխատ,  կրեդիտում  կցված է 2521 հաշիվը «հաշվարկային հաշիվ»: Եթե նշված հաշվին ունեք ավելացված ենթահաշիվներ, ապա բացված ցուցակից ընտրեք համապատասխան  ենթահաշիվը: 
Փաստաթուղթը հաստատելուց ինքնաշխատ տրվում է գործառնություն, որը գրանցվում է ընդհանուր էջի Գործառնություններ կետում: Գործողություն սյան Խմբագրել կետով խմբագրեք  փաստաթղթի տվյալները: Հաստատել կոճակով պահպանեք փոփոխությունները: Տպել կետը ստեղծում է փաստաթղթի թղթային տարբերակը՝ 2 օրինակից: Սեղմեք Պատճենել կետը՝ կբացվի տվյալ տողի լրացված փաստաթուղթը՝ փոփոխված  ընթացիկ օրվա ամսաթվով և վճարային հանձնարարագրի հերթական հաջորդական համարով: Ջնջել կետով հեռացվում է և՚ փաստաթուղթը, և՚ գործառնությունը:
 `,
  },
  {
    path: '/PurchaseAct',
    text: 'Հաշվետու անձի  ներկայացրած գնումների  ակտը: Գնման ակտ փաստաթուղթը առհաշիվ անձի շուկայից կատարած գնումների ներկայացման համար է: Ընտրեք հաշվետու անձին՝ առհաշիվ անձ բացվող ցուցակից, ընտրեք կրեդիտ հաշիվը (228), սեղմեք Ավելացնել կոճակը, լրացրեք *-ով նշված պարտադիր դաշտերը, սեղմեք Հաստատել կոճակը: Մուտքագրեք բոլոր գնումները: Սեղմեք Գրանցել փաստաթուղթը, որից հետո ինքնաշխատ կտրվի հաշվապահական ձևակերպում` կատարված գործարքի վերաբերյալ։ Ջնջել կետով հեռացվում է և՛ փաստաթուղթը, և՛ գործառնությունը:  Եթե օգտվողը հանդիսանում է ԱԱՀ վճարող կամ միկրոձեռնարկատեր, ապա Գրանցել փաստաթուղթը սեղմելուց ակտիվանում է եկամտային հարկ կոճակը, որը սեղմեք կբացվի եկամտային հարկի մասով գործառնության ձևը, լրացրեք դաշտերը, սեղմեք Հաստատել կոճակը: Տպել կետը ստեղծում է փաստաթղթի թղթային տարբերակը:',
  },
  {
    path: '/Invoice',
    text: 'Կանխավճարային/փոխանցման հաշիվը նախատեսված է կանխավճարի վճարման փաստաթուղթ ստեղծելու համար: Փաստաթուղթը լրացնելու համար ընտրեք գործընկերոջը, սեղմեք Ավելացնել կոճակը, լրացրեք բացվող ձևի *-ով նշված պարտադիր դաշտերը, սեղմեք Հաստատել կոճակը։ Այս փաստաթղթով գործառնություն չի տրվում: Տպել կետը ստեղծում է փաստաթղթի թղթային տարբերակը:',
  },
  {
    path: '/AccountForSpendingMoney',
    text: `Հաշվետու անձի   ծախսած  գումարների  հաշվետվություն: Այս փաստաթուղթը հաշվետվություն է առհաշիվ անձի ստացած գումարների և կատարած ծախսերի վերաբերյալ: Ընտրեք առհաշիվ անձին: Նախապես կազմեք նրան տրամադրված գումարների փաստաթղթերը ՝ դրամարկղի ելքի օրդեր, վճարման հանձնարարագիր կամ հիշարար օրդեր, ինքնաշխատ տրվում է հաշվապահական ձևակերպումներ՝ կրեդիտում դրամական միջոցների և  դեբետում 228 հաշվի, համապատասխան հաշիվները: Անպայման նշում կատարեք «Գրանցել առհաշիվ գումարների ծախսման մասին հաշվետվության մեջ» դաշտում, որպեսզի այդ գումարները ինքնաշխատ արտացոլվեն  հաշվետվության բացված ձևում՝ վերևի տողում, ամսաթվերի աճման կարգով։  Ամսաթվերի նախատեսված 4 վանդակները լրանալուց հետո, պետք է փակել փաստաթուղթը և բացել նորը։
Կատարված ծախսերը գրանցելու համար սեղմեք Ավելացնել կոճակը, լրացրեք  *-ով նշված պարտադիր դաշտերը: Սեղմեք Հաստատել  կոճակը՝ տողը կգրանցվի հաշվետվության երկրորդ աղյուսակում: Բոլոր ծախսերը մուտքագրելուց հետո սեղմեք Գրանցել փաստաթուղթը կոճակը։ Փաստաթղթի Գործողություն սյան Խմբագրել կետով կվերադառնաք ծախսերի աղյուսակին, որտեղ ամեն մի տողի համար Գործողություն սյան Խմբագրել կետը սեղմելով, բացվող ցանկից 
 կարող եք  գործառնություն տալ: Այն  կպահպանվի գործառնությունների աղյուսակում։ Ուշադրություն, եթե ծախսը կատարվել է գնման ակտով, ապա դրա համար գործառնություն չպետք է տրվի, քանի որ գնման ակտի հաստատման ժամանակ ինքնաշխատ տրվել է :
    `,
  },
  {
    path: '/DownloadEInvoicingAccounts',
    text: `e-invoicing ծրագրից   Excel   արտահանված  հաշվարկային  փաստաթղթերի  ներբեռնում: 
Կարող եք  e-invoicing ծրագրից   ներբեռնել ստացված, դուրս գրված   հարկային հաշիվներ և հաշիվ  վավերագրեր: Պետք է  ներբեռնեք առանձին  ֆայլերով կամ խմբերով՝ ըստ հաշիվների տեսակների։ Յուրաքանչյուր ֆայլը  ներբեռնելուց հետո հաստատեք: Եթե գործընկերը բացակայում է, ավելացրեք գործընկերոջը՝ սեղմելով տողի վերջի «+» նշանը:  Ընտրեք  գործընկերոջ թղթակցող հաշիվը՝  առաջարկվող համապատասխան հաշիվների ցանկից, սեղմեք Գրանցել կոճակը։ Հաշվարկային փաստաթղթերի կրկնակի ներբեռնման դեպքում, աղյուսակի նշումներ դաշտում գրվում է՝ «Հաշվարկային փաստաթուղթը մուտքագրված է»։

    `,
  },
  {
    path: '/E-InvoicingOperations',
    text: `Ներբեռնված  հաշվարկային  փաստաթղթերի  գործառնությունների  գրանցում: Յուրաքանչյուր  հաշվարկային փաստաթղթի համար ստեղծվում է մեկ տող համապատասխան գործառնությունները տալու համար: Յուրաքանչյուր գործառնության համար ընտրում եք թղթակցող հաշիվը՝ առաջարկվող թույլատրելի ցանկից: Դուրս գրված հաշվարկային փաստաթղթերի ներբեռնումից և համապատասխան գործառնություններից հետո, երբ գործընկերը վճարել է այդ գործարքի համար՝ (բանկի քաղվածքի առկայության դեպքում),  համապատասխան տողի, Գործողություն սյան ցանկից ընտրեք վճարում / մուտքագրում կետը, կբացվի բանկի մուտքի օրդեր ձևը՝ հնարավոր դաշտերը նախապես լրացված, որոնք փոփոխման հնարավորություն ունեն:
Երկու դեպքում  էլ  ինքնաշխատ տրվում  են գործառնություններ, որոնք գրանցվում են ընդհանուր էջի  գործառնություններ կետում։ 
    `,
  },
  {
    path: '/Operations',
    text: ` Գրանցված  գործառնությունների  ցանկ հիշարար օրդերի(ների)  ավելացմամբ: Այս ձևում կուտակվում են բոլոր գործառնությունները՝ լրացված մուտքային փաստաթղթերի, e-invoicing ծրագրից ներբեռնած հաշիվների, գնման ակտի, տրված առհաշիվ գումարների ծախսերի հիման վրա ։ Բոլոր այն   գործառնությունները, որոնք նշված  փաստաթղթերում  չեք տվել, կարող եք կատարել  այստեղ ստեղծվող  հիշարար օրդերում: Սեղմեք  Ավելացնել կոճակը, լրացրեք *-ով նշված պարտադիր դաշտերը, ընտրեք թղթակցող հաշիվները՝ առաջարկվող  հաշիվների համապատասխան ցանկից: Տրված գործառնությունը  նույնպես  պահպանվում  է գործառնություններ կետում:
 Հիշարար օրդերով կարող եք տալ նաև բոլոր արտարժութային գործառնությունները: Դրամարկղ մուտքագրվող և ելքագրվող արտարժույթով արտահայտված գումարները նույպես գրանցվում են  որպես հիշարար օրդերներ, բայց թղթային տարբերակը տպվում  է փաստաթղթերի դասական տեսքով։ Գործողություն սյունը՝  > նշանով,  այն բոլոր մուտքային փաստաթղթերի համար է, որոնք այլ էջերում են ստեղծվում : > նշանի վրա սեղմելով բացվում է տվյալ փաստաթուղթը մեկ տողով, որը կարող եք փոփոխել, տպել, ջնջել կամ պատճենե: Միայն հիշարար օրդեր փաստաթղթի համար է գործողություն սյունը պարունակում Խմբագրել կետը:
Նախատեսված է ընդլայնված որոնում բոլոր ցուցանիշներով
`,
  },
  {
    path: '/ClosingAccountBalances',
    text: `Մուտքագրված օրվա  դրությամբ եկամուտ/ծախս հաշիվների մնացորդների փակում:
Մինչ հաշիվների փակելը, հաղորդագրություն է գալիս՝ 8-րդ դասի հաշիվների մնացորդները,  անհրաժեշտության դեպքում, տեղափոխել 7-րդ դասի համապատասխան հաշիվների վրա։ 
Ընտրելով ամսաթիվը՝ հաշվետու տարվա դեկտեմբերի 31-ը, կատարվում է  հաշվետու տարվա եկամուտների, ծախսերի հաշիվների մնացորդների տեղափոխում 331՝ շահույթ կամ վնաս հաշվին: Ինքնաշխատ հավաքվում են 6-րդ և 7-րդ դասի հաշիվների մնացորդները, բացվող ցուցակից պետք է ընտրել շահույթ կամ վնաս հաշիվը։ Հաշվի ընտրությամբ ակտիվանում է փակել կոճակը: Սեղմեք այն՝ գալիս է հաղորդագրություն, որ կատարվեց հաշիվների մնացորդների փակում տվյալ ամսաթվով: Ինքաշխատ ձևավորվում է  հաշվետու տարվա զուտ շահույթը կամ վնասը: Եկամուտներ և ծախսեր հաշվառող հաշիվները, փակման ամսաթվի դրությամբ,  մնացորդներ չեն ունենում ։

`,
  },
  {
    path: '/FormationOfNewInitialBalances',
    text: `Նոր հաշվետու տարվա  սկզբնական մնացորդների ձևավորում: Սկզբնական մնացորդներ ձևավորվում են միայն հաշվետու տարվա  համար՝ սկիզբը ինքնաշխատ լրացվում է հաջորդ հաշվետու տարվա 01.01.-ը ժամը 00.01 ,իսկ վերջը՝ հաջորդ հաշվետու տարվա 31.12.-ը ժամը 23.59: Ձևավորված սկզբնական մնացորդները ինքնաշխատ լրացվում են  հաշիվների սկզբնական մնացորդներ աղյուսակում: Սկզբնական մնացորդներ չի ձևավորվում, եթե կան չփակված հաշիվներ: Միջանկյալ սկզբնական մնացորդներ չեն ձևավորվում`,
  },
  {
    path: '/Documents',
    text: `Օգտվողի ստեղծած բոլոր փաստաթղթերի  ցանկը: Աշխատանքի ընթացքում ստեղծած բոլոր փաստաթղթերի ցանկն է տարբեր պարամետրերով՝ որոնելու, դիտելու, տպելու հնարավորությամբ: Եթե արժույթ ընտրված չէ, ապա բոլոր արժույթներով փաստաթղթերն են:`,
  },
  {
    path: '/BalanceBook',
    text: `Հաշիվների մնացորդներն են օրվա  և արժույթի ընտրության հնարավորությամբ: Այստեղ կարող եք վերագնահատել արտարժութային ոչ գործընկերային հաշիվների մնացորդները՝ վերջին սյան V  նշանի վրա սեղմելով։`,
  },
  {
    path: '/CirculationNewsletter',
    text: `Ընտրված ժամանակահատվածում շրջանառության  տեղեկագիրը:   Շրջանառության տեղեկագիր հաշվետվությունը կազմվում է ընտրված ժամանակահատվածում ու ընտրված արժույթով տրված գործառնությունների բազայի վրա ։
 Հաշվետվության ձևում, յուրաքանչյուր հաշվի տողում կա 2  վանդակ նշելու համար: Հաշվետվությունը ստանալու համար գոնե մեկ վանդակ պետք է նշված լինի։
Շրջանառության  տեղեկագիրը՝ դա ընտրված ժամանակահատվածում յուրաքանչյուր հաշվի դեբետային ու  կրեդիտային սկզբնական մնացորդն է, շրջանառության գումարը ու  վերջնական մնացորդը:
`,
  },
  {
    path: '/AccountStatement',
    text: `Ընտրված ժամանակահատվածում ընտրված հաշվի շարժը:  T–Հաշիվ հաշվետվությունում մուտքագրեք կամ ընտրեք հաշիվը (ձախից *-ը պարտադիր լրացման դաշտ է) և սեղմեք Կատարել կոճակը:
Հաշվետվության վերևում տպվում է հաշվի սկզբնական մնացորդը, սկզբնական ամսաթվի դրությամբ ընտրված ժամանակահատվածի համար: Արտացոլվում են  այդ հաշվի կրեդիտում  և դեբետում մասնակցած հաշիվների հանրագումարային տողերը, վերջում ՝ հաշվի վերջնական մնացորդը։
`,
  },
  {
    path: '/AccountAnalysis',
    text: `Ընտրված ժամանակահատվածում ընտրված հաշվի շարժը  գործառնություններով:
Հաշվետվությունում մուտքագրեք կամ ընտրեք հաշիվը (ձախից *-ը պարտադիր լրացման դաշտ է) և սեղմեք Կատարել կոճակը: 
Այս հաշվետվությունը նախորդից տարբերվում է նրանով, որ այստեղ տպվում են նաև գործառնությունները:

`,
  },
  {
    path: '/TransactionLog',
    text: `Ընտրված ժամանակահատվածում  բոլոր գործառնությունների  ցանկը: Նախատեսված է  տարբեր պարամետրերով որոնելու հնարավորություն: Եթե արժույթ ընտրված չէ, ապա, բոլոր արժույթներով գործառնություններն են:
    `,
  },
  {
    path: '/PartnerBalanceBook',
    text: `Ընտրված օրվա դրությամբ գործընկերների մնացորդները: Ընտրված օրվա դրությամբ գործընկերոջ մնացորդն է՝ հաշիվներով ընտրելու հնարավորությամբ: Արտարժույթի ընտրության դեպքում՝ վերջին սյան V նշանի վրա սեղմեք, կստանաք տվյալ տողի հաշվի վերագնահատման բացված ձևը:  Սեղմեք Հաստատել կոճակը՝ հաշվի մնացորդը կվերագնահատվի։
    `,
  },
  {
    path: '/PartnerCirculationBulletin',
    text: `Ընտրված ժամանակահատվածում  գործընկերների  շրջանառության  տեղեկագիրը: Գործընկերների շրջանառության տեղեկագիրը դիտելու համար անհրաժեշտ է առնվազն մեկ գործընկերոջ նշում (գործընկերոջ տողում տեղադրված վանդակում):     
Հաշվետվության տեղեկատվության հիմք են ծառայում ընտրված ժամանակահատվածում ու ընտրված արժույթով գործընկերների մասնակցությամբ տված գործառնությունները։
Արտահանվում է ընտրված յուրաքանչյուր գործընկերոջ դեբետային ու  կրեդիտային սկզբնական մնացորդը, շրջանառությունը ու  վերջնական մնացորդը
`,
  },
  {
    path: '/PartnersTAccountAccountStatement',
    text: `Ընտրված ժամանակահատվածում   ընտրված գործընկերոջ(գործընկերների)  հաշիվների  շարժը: Գործընկերների T – հաշիվ (քաղվածք հաշվից) հաշվետվությունը դիտելու և տպելու համար անհրաժեշտ է առնվազն մեկ գործընկերոջ նշում (գործընկերոջ տողում տեղադրված վանդակում):`,
  },
  {
    path: '/SettlementDocuments',
    text: `E-invoicing ծրագրից  ներբեռնված հաշվարկային  փաստաթղթերի ցանկը: Նախատեսված է ըստ ժամանակահատվածի, գործընկերոջ տեսակի, դիտելու և տպելու հնարավորություն:`,
  },

  {
    path: '/Obtaining_three_digit_account_balances',
    text: `Եռանիշ հաշիվների մնացորդների ստացում տրված ամսաթվով: Սեղմեք Դիտել/Տպել ինքնաշխատ կստանաք եռանիշ հաշիվների մնացորդները, հնարավոր է նաև տպել:`,
  },
];
