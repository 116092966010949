import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PrivateRoute from '../Components/PrivateRoute/PrivateRoute';
import MainComponent from '../Components/MainComponent/MainComponent';
import GetDashboardData from '../Pages/Dashboard/GetDashboardData';
import OrganizationUpdateForm from '../Pages/Organization/CreateOrganization';
import GetDocumentData from '../Pages/Document/GetDocumentData';
import GetAccountAnalysisData from '../Pages/AccountAnalysis/GetAccountAnalysisData';
import GetAccountStatement from '../Pages/AccountStatement/GetAccountStatement';
import GetCirculationNewsletterData from '../Pages/CirculationNewsletter/GetCirculationNewsletterData';
import GetOperationsData from '../Pages/Operations/GetOperationsData';
import GetBalanceBookData from '../Pages/BalanceBook/GetBalanceBookData';
import GetPartnerBalanceBookData from '../Pages/PartnerBalanceBook/GetPartnerBalanceBookData';
import GetPartnerCirculationData from '../Pages/PartnerCirculationBulletin/GetPartnerCirculationData';
import GetPurchaseActData from '../Pages/PurchaseAct/GetPurchaseActData';
import GetCashWarrantData from '../Pages/CashOrders/CashWarrant/GetCashWarrantData';
import GetCashOutflowOrdersData from '../Pages/CashOrders/CashOutflowOrders/GetCashOutflowOrdersData';
import GetChartOfAccountData from '../Pages/ChartOfAccounts/GetChartOfAccountData';
import GetPaymentOrderData from '../Pages/PaymentOrder/GetPaymentOrderData';
import GetPartnerData from '../Pages/Partners/GetPartnerData';
import GetClosingAccountBalances from '../Pages/ClosingAccountBalances/GetClosingAccountBalances';
import GetTransactionLogData from '../Pages/TransactionLog/GetTransactionLogData';
import GetPartnersAccountStatement from '../Pages/PartnersTAccountAccountStatement/GetPartnersAccountStatement';
import GetSettlementDocument from '../Pages/SettlementDocuments/GetSettlementDocument';
import GetEInvoicingAccountsData from '../Pages/DownloadEInvoicingAccounts/GetEInvoicingAccountsData';
import GetEInvoicingOperations from '../Pages/EInvoicingOperations/GetEInvoicingOperations';
import GetAccountingCorrespondence from '../Pages/TypicalAccountingCorrespondence/GetAccountingCorrespondence';
import GetBankData from '../Pages/Banks/GetBankData';
import GetInitialBalancesData from '../Pages/InitialBalances/GetInitialBalancesData';
import GetServiceWorkData from '../Pages/ServiceWork/GetServiceWorkData';
import GetProductData from '../Pages/Product/GetProductData';
import GetUnitOfMeasureData from '../Pages/UnitOfMeasure/GetUnitOfMeasureData';
import GetInvoiceData from '../Pages/Invoice/GetInvoiceData';
import GetOrganization from '../Pages/Company/GetOrganization';
import ReportingPeriod from '../Pages/ReportingPeriod/ReportingPeriod';
import OrganizationAddForm from '../Pages/Company/OrganizationAddForm';
import OneWindowView from '../Pages/OneWindowView/OneWindowView';
import GetCurrencies from '../Pages/Currencies/GetCurrencies';
import UsefulTool from '../Pages/OneWindowView/UsefulTool';
import Login from '../Pages/Login/Login';
import Register from '../Pages/Register/Register';
import GetCompanyBank from '../Pages/CompanyBank/GetCompanyBank';
import GetAccountForSpendingMoney from '../Pages/AccountForSpendingMoney/GetAboutSpendingMoneyData';
import ResidueFormation from '../Pages/ResidueFormation/ResidueFormation';
import UserData from '../Pages/UserData/UserData';
import WorkGuide from '../Pages/WorkGuide/WorkGuide';
import ThreeDigitBalances from '../Pages/ThreeDigitBalances/ThreeDigitBalances';
import HelpDetails from '../Components/HelpDetails/HelpDetails';
import PrintDetails from '../Components/PrintDetails/PrintDetails';
import Aspect from '../../FinHelp/pages/Aspect';
import Rep from '../../FinHelp/pages/Rep';
import FullCalendar from '../../FullCalendar/FullCalendar';
import { mainPage } from '../Utilities/Utilities';
import SalaryComponent from '../../Salary/components/salaryComponent/SalaryComponent';
import Subdivisions from '../../Salary/pages/subdivisions/Subdivisions';
import Employees from '../../Salary/pages/employees/Employees';
import Positions from '../../Salary/pages/positions/Positions';
import Savings from '../../Salary/pages/savings/Savings';
import Provisions from '../../Salary/pages/provisions/Provisions';
import WorkCalendar from '../../Salary/pages/workCalendar/WorkCalendar';
import AdditionsType from '../../Salary/pages/additionsType/AdditionsType';
import Tables from '../../Salary/pages/tables/Tables';
import WorkWithEmployees from '../../Salary/pages/workWithEmployees/WorkWithEmployees';
import EmployeesDashboard from '../../Salary/pages/employeesDashboard/EmployeesDashboard';
import ConfirmPositions from '../../Salary/pages/confirmPositions/ConfirmPositions';
import AssignPositions from '../../Salary/pages/assignPositions/AssignPositions';
import NonWorkingDays from '../../Salary/pages/nonWorkingDays/NonWorkingDays';
import MakeAnAddition from '../../Salary/pages/makeAnAddition/MakeAnAddition';
import SalaryCalculation from '../../Salary/pages/salaryCalculation/SalaryCalculation';
import AccountingFormulations from '../../Salary/pages/accountingFormulations/AccountingFormulations';
import BankPaymentLists from '../../Salary/pages/bankPaymentLists/BankPaymentLists';
import CalculationDataViewing from '../../Salary/pages/calculationDataViewing/CalculationDataViewing';
import CalculationSheetsViewing from '../../Salary/pages/calculationSheetsViewing/CalculationSheetsViewing';
import SummaryData from '../../Salary/pages/summaryData/SummaryData';
import GeneralSummary from '../../Salary/pages/generalSummary/GeneralSummary';
import MaterialValuesWrap from '../../MaterialValues/components/MaterialValuesWrap/MaterialValuesWrap';
import MaterialValuesDashboard from '../../MaterialValues/pages/materialValuesDashboard/MaterialValuesDashboard';
import MaterialValuesPartners from '../../MaterialValues/pages/partners/Partners';
import MaterialValueGroups from '../../MaterialValues/pages/materialValueGroups/MaterialValueGroups';
import Warehouse from '../../MaterialValues/pages/warehouse/Warehouse';
import MaterialValuesUnitsMeasure from '../../MaterialValues/pages/materialValues​UnitsMeasure/MaterialValuesUnitsMeasure';
import MaterialValuesNames from '../../MaterialValues/pages/materialValuesNames/MaterialValuesNames';
import WarehouseEntry from '../../MaterialValues/pages/warehouseEntry/WarehouseEntry';
import CompanyTaxationSystemForm from '../Pages/CompanyTaxationSystem/CompanyTaxationSystemForm';
const userData = localStorage.getItem('persist:root');
const currentCompanyID = userData && JSON.parse(userData).currentCompanyID;

const Navigation = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to="/Home" />
    </Route>
    <Route path="/userData">
      <UserData />
    </Route>
    {/* <Route path="/Create_organization"></Route> */}
    <Route path="/OneWindow">
      <OneWindowView />
    </Route>
    <Route path="/Login">
      <Login />
    </Route>
    <Route path="/Register">
      <Register />
    </Route>
    <PrivateRoute path={mainPage}>
      <UsefulTool />
    </PrivateRoute>
    <PrivateRoute path="/Calendar">
      <FullCalendar />
    </PrivateRoute>
    <PrivateRoute path="/DataEntry">
      <Aspect />
    </PrivateRoute>
    <PrivateRoute path="/BudgetSummary">
      <Rep />
    </PrivateRoute>
    <PrivateRoute path="/PrintDetails">
      <PrintDetails />
    </PrivateRoute>
    <Route
      path={[
        '/EmployeesDashboard',
        '/Actions_with_employees',
        '/Employees',
        '/Subdivisions',
        '/Positions',
        '/Savings',
        '/Work_calendar',
        '/Provisions',
        '/Additions',
        '/Working_time_tables',
        '/Assign_supplement_retention',
        '/Confirm_positions',
        '/Assign_positions',
        '/Non_working_days',
        '/Make_an_addition',
        '/Salary_calculation',
        '/Accounting_formulations',
        '/Bank_paymentLists',
        '/Calculation_data_viewing',
        '/Calculation_sheets_viewing',
        '/Summary_data',
        '/General_summary',
      ]}
      exact
    >
      <SalaryComponent>
        <Route path="/EmployeesDashboard">
          <PrivateRoute>
            <EmployeesDashboard />
          </PrivateRoute>
        </Route>

        <Route path="/Employees">
          <PrivateRoute>
            <Employees />
          </PrivateRoute>
        </Route>

        <Route path="/Actions_with_employees">
          <PrivateRoute>
            <WorkWithEmployees />
          </PrivateRoute>
        </Route>
        <Route path="/Subdivisions">
          <PrivateRoute path="/Subdivisions">
            <Subdivisions />
          </PrivateRoute>
        </Route>

        <Route path="/Positions">
          <PrivateRoute path="/Positions">
            <Positions />
          </PrivateRoute>
        </Route>
        <Route path="/Savings">
          <PrivateRoute path="/Savings">
            <Savings />
          </PrivateRoute>
        </Route>
        <Route path="/Work_calendar">
          <PrivateRoute path="/Work_calendar">
            <WorkCalendar />
          </PrivateRoute>
        </Route>

        <Route path="/Provisions">
          <PrivateRoute path="/Provisions">
            <Provisions />
          </PrivateRoute>
        </Route>
        <Route path="/Additions">
          <PrivateRoute path="/Additions">
            <AdditionsType />
          </PrivateRoute>
        </Route>
        <Route path="/Working_time_tables">
          <PrivateRoute path="/Working_time_tables">
            <Tables />
          </PrivateRoute>
        </Route>
        <Route path="/Confirm_positions">
          <PrivateRoute path="/Confirm_positions">
            <ConfirmPositions />
          </PrivateRoute>
        </Route>
        <Route path="/Assign_positions">
          <PrivateRoute path="/Assign_positions">
            <AssignPositions />
          </PrivateRoute>
        </Route>

        <Route path="/Non_working_days">
          <PrivateRoute path="/Non_working_days">
            <NonWorkingDays />
          </PrivateRoute>
        </Route>
        <Route path="/Make_an_addition">
          <PrivateRoute path="/Make_an_addition">
            <MakeAnAddition />
          </PrivateRoute>
        </Route>

        <Route path="/Salary_calculation">
          <PrivateRoute>
            <SalaryCalculation />
          </PrivateRoute>
        </Route>
        <Route path="/Accounting_formulations">
          <PrivateRoute>
            <AccountingFormulations />
          </PrivateRoute>
        </Route>

        <Route path="/Bank_paymentLists">
          <PrivateRoute>
            <BankPaymentLists />
          </PrivateRoute>
        </Route>

        <Route path="/Calculation_data_viewing">
          <PrivateRoute>
            <CalculationDataViewing />
          </PrivateRoute>
        </Route>
        <Route path="/Calculation_sheets_viewing">
          <PrivateRoute>
            <CalculationSheetsViewing />
          </PrivateRoute>
        </Route>
        <Route path="/Summary_data">
          <PrivateRoute>
            <SummaryData />
          </PrivateRoute>
        </Route>
        <Route path="/General_summary">
          <PrivateRoute>
            <GeneralSummary />
          </PrivateRoute>
        </Route>
      </SalaryComponent>
    </Route>
    <Route
      path={[
        '/MaterialValuesDashboard',
        '/Material_values_partners',
        '/Material_value_groups',
        '/Material_values_warehouse',
        '/Material_values_units_measure',
        '/Material_values_names',
        '/Warehouse_entry',
      ]}
      exact
    >
      <MaterialValuesWrap>
        <Route path="/MaterialValuesDashboard">
          <PrivateRoute>
            <MaterialValuesDashboard />
          </PrivateRoute>
        </Route>
        <Route path="/Material_value_groups">
          <PrivateRoute>
            <MaterialValueGroups />
          </PrivateRoute>
        </Route>
        <Route path="/Material_values_warehouse">
          <PrivateRoute>
            <Warehouse />
          </PrivateRoute>
        </Route>

        <Route path="/Material_values_partners">
          <PrivateRoute>
            <MaterialValuesPartners />
          </PrivateRoute>
        </Route>
        <Route path="/Material_values_units_measure">
          <PrivateRoute>
            <MaterialValuesUnitsMeasure />
          </PrivateRoute>
        </Route>
        <Route path="/Material_values_names">
          <PrivateRoute>
            <MaterialValuesNames />
          </PrivateRoute>
        </Route>
        <Route path="/Warehouse_entry">
          <PrivateRoute>
            <WarehouseEntry />
          </PrivateRoute>
        </Route>
      </MaterialValuesWrap>
    </Route>
    <Route>
      <MainComponent>
        <Switch>
          <PrivateRoute path="/company">
            <GetOrganization />
          </PrivateRoute>

          <PrivateRoute path="/CompanyBank">
            <GetCompanyBank />
          </PrivateRoute>
          <PrivateRoute
            path={`${currentCompanyID ? '/OrganizationAddForm' : '#'}`}
            //path='/OrganizationAddForm'
          >
            <OrganizationAddForm />
          </PrivateRoute>
          <PrivateRoute path="/Dashboard">
            <GetDashboardData />
          </PrivateRoute>
          <PrivateRoute path="/Organization">
            <OrganizationUpdateForm />
          </PrivateRoute>
          <PrivateRoute path="/Company_reporting_period">
            <CompanyTaxationSystemForm />
          </PrivateRoute>

          <PrivateRoute path="/ChartOfAccounts">
            <GetChartOfAccountData />
          </PrivateRoute>
          <PrivateRoute path="/AccountCorrespondence">
            <GetAccountingCorrespondence />
          </PrivateRoute>
          <PrivateRoute path="/Banks">
            <GetBankData />
          </PrivateRoute>
          <PrivateRoute path="/InitialBalances">
            <GetInitialBalancesData />
          </PrivateRoute>
          <PrivateRoute path="/ServiceWork">
            <GetServiceWorkData />
          </PrivateRoute>
          <PrivateRoute path="/Product">
            <GetProductData />
          </PrivateRoute>
          <PrivateRoute path="/UnitOfMeasure">
            <GetUnitOfMeasureData />
          </PrivateRoute>
          <PrivateRoute path="/ClosingAccountBalances">
            <GetClosingAccountBalances />
          </PrivateRoute>
          <PrivateRoute path="/DownloadEInvoicingAccounts">
            <GetEInvoicingAccountsData />
          </PrivateRoute>
          <PrivateRoute path="/E-InvoicingOperations">
            <GetEInvoicingOperations />
          </PrivateRoute>
          <PrivateRoute path="/Partners">
            <GetPartnerData />
          </PrivateRoute>
          <PrivateRoute path="/Operations">
            <GetOperationsData />
          </PrivateRoute>
          <PrivateRoute path="/CashWarrant">
            <GetCashWarrantData />
          </PrivateRoute>
          <PrivateRoute path="/CashWithdrawalOrder">
            <GetCashOutflowOrdersData />
          </PrivateRoute>
          <PrivateRoute path="/PaymentOrder">
            <GetPaymentOrderData />
          </PrivateRoute>
          <PrivateRoute path="/ActOfPurchase">
            <GetPurchaseActData />
          </PrivateRoute>
          <PrivateRoute path="/AccountForSpendingMoney">
            <GetAccountForSpendingMoney />
          </PrivateRoute>
          <PrivateRoute path="/Documents">
            <GetDocumentData />
          </PrivateRoute>
          <PrivateRoute path="/BalanceBook">
            <GetBalanceBookData />
          </PrivateRoute>
          <PrivateRoute path="/CirculationNewsletter">
            <GetCirculationNewsletterData />
          </PrivateRoute>
          <PrivateRoute path="/AccountStatement">
            <GetAccountStatement />
          </PrivateRoute>
          <PrivateRoute path="/AccountAnalysis">
            <GetAccountAnalysisData />
          </PrivateRoute>
          <PrivateRoute path="/PartnerBalanceBook">
            <GetPartnerBalanceBookData />
          </PrivateRoute>
          <PrivateRoute path="/TransactionLog">
            <GetTransactionLogData />
          </PrivateRoute>
          <PrivateRoute path="/PartnerCirculationBulletin">
            <GetPartnerCirculationData />
          </PrivateRoute>
          <PrivateRoute path="/PartnersTAccountAccountStatement">
            <GetPartnersAccountStatement />
          </PrivateRoute>
          <PrivateRoute path="/SettlementDocuments">
            <GetSettlementDocument />
          </PrivateRoute>
          <PrivateRoute path="/PrepaidTransferAccount">
            <GetInvoiceData />
          </PrivateRoute>
          <PrivateRoute path="/ReportingPeriod">
            <ReportingPeriod />
          </PrivateRoute>
          <PrivateRoute path="/Currencies">
            <GetCurrencies />
          </PrivateRoute>
          <PrivateRoute path="/FormationOfNewInitialBalances">
            <ResidueFormation />
          </PrivateRoute>

          <PrivateRoute path="/WorkGuide">
            <WorkGuide />
          </PrivateRoute>
          <PrivateRoute path="/Obtaining_three_digit_account_balances">
            <ThreeDigitBalances />
          </PrivateRoute>
          <PrivateRoute path="/HelpDetails">
            <HelpDetails />
          </PrivateRoute>
        </Switch>
      </MainComponent>
    </Route>
  </Switch>
);

export default Navigation;
