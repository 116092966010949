import {
  GET_INVOICING_ACCOUNT_DATA,
  LOADING,
  UPDATE_INVOICING_ACCOUNT_VALUE,
  GET_OLD_INVOICING_DATA,
  UPDATE__OLD_INVOICING_ACCOUNT_VALUE,
} from './constants';

const initialState = {
  invoicingAccountData: [],
  isLoading: false,
  oldInvoicingData: [],
};

export const invoicingAccountReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case GET_INVOICING_ACCOUNT_DATA:
      return {
        ...state,
        invoicingAccountData: payload,
        isLoading: true,
      };
    case GET_OLD_INVOICING_DATA:
      return {
        ...state,
        oldInvoicingData: payload,
        isLoading: true,
      };

    case UPDATE__OLD_INVOICING_ACCOUNT_VALUE:
      return {
        ...state,
        oldInvoicingData: {
          ...state.oldInvoicingData,
          excelList: [
            ...state.oldInvoicingData?.excelList.map((item) => {
              if (item.invoiceSerialNumber === payload.invoiceSerialNumber) {
                return { ...item, account: payload.account };
              } else {
                return item;
              }
            }),
          ],
        },
      };
    case UPDATE_INVOICING_ACCOUNT_VALUE:
      return {
        ...state,
        invoicingAccountData: {
          ...state.invoicingAccountData,
          excelList: [
            ...state.invoicingAccountData?.excelList.map((item) => {
              if (item.invoiceSerialNumber === payload.invoiceSerialNumber) {
                return { ...item, account: payload.account };
              } else {
                return item;
              }
            }),
          ],
        },
      };
    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return { ...state };
  }
};
