import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import Pagination from '../../Components/Pagination/Pagination';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import SearchBar from '../../Components/SearchForm/SearchBar';
import {
  getPartnerAction,
  loadingAction,
} from '../../Redux/partnerSlices/useAction';
import {
  dataTimeFormat,
  newDate,
  startOfMonth,
} from '../../Utilities/Utilities';
import EventAddForm from './EventAddForm';
import PartnerTable from './PartnerTable';
import './partner.scss';
import SelectPartnerType from '../../Components/SelectBox/SelectPartnerType';

const GetPartnerData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { partnerData, isLoading } = useSelector(
    (state) => state.getPartnerData,
  );
  const dateStartFormat = dataTimeFormat(startOfMonth);
  const dateEndFormat = dataTimeFormat(newDate);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchValue, setSearchValues] = useState('');
  const [openPartnerForm, setOpenPartnerForm] = useState(false);
  const [partnerTypeVal, setPartnerTypeVal] = useState({
    label: '',
    value: '',
    id: 0,
  });
  const dispatch = useDispatch();

  const getData = (pageNumber, searchValue = '') => {
    dispatch(loadingAction(true));
    getRequest(
      `getPartners?companyID=${currentCompanyID}&pageNumber=${
        pageNumber + 1
      }&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}&partnerType=${
        partnerTypeVal.id
      }&searchstring=${searchValue}`,
    )
      .then((res) => {
        dispatch(getPartnerAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    getData(pageNumber, searchValue);
  }, [pageNumber, partnerTypeVal.id]);

  const addFormHandler = () => {
    setOpenPartnerForm(true);
  };
  const selectChangePartner = (values) => {
    if (!values) {
      values = {
        value: '',
        id: 0,
      };
    }
    setPartnerTypeVal(values);
  };

  return (
    <>
      <Header pageTitle="Իմ գործընկերները" handelClick={addFormHandler} />
      <SearchBackground>
        <div className="G-flex-align-center L-partner-page-flex">
          <SelectPartnerType
            partnerTypeVal={partnerTypeVal}
            selectChangePartner={(values) => selectChangePartner(values)}
          />
          <SearchBar
            getData={getData}
            pageNumber={pageNumber}
            searchValue={searchValue}
            setSearchValue={setSearchValues}
            setPageNumber={setPageNumber}
            name="searchstring"
            searchFiledTitle="Գործընկերոջ անվանումը"
          />
        </div>
      </SearchBackground>
      <PartnerTable mainData={partnerData?.partners} loading={isLoading} />
      <Pagination
        pageCount={partnerData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />

      {openPartnerForm && (
        <EventAddForm
          getData={getData}
          pageNumber={pageNumber}
          closeModal={setOpenPartnerForm}
        />
      )}
    </>
  );
};

export default GetPartnerData;
