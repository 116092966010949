export const PersonalCardFields = [
  {
    fieldType: 'input',
    type: 'text',
    name: 'empNo',
    label: 'Տաբել N',
    value: '',
    required: true,
    maxLength: 3,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Ստորաբաժանումը',
    required: true,
    fieldName: 'depName',
    dropdownId: 'depNo',
    optionName: 'workDeps',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.depName,
        id: i.depNo,
        value: i.depName,
      }));
    },
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Պաշտոնը',
    required: true,
    fieldName: 'positionName',
    dropdownId: 'positionNo',
    optionName: 'positions',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.positionName,
        id: i.positionNo,
        value: i.positionName,
      }));
    },
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'lastName',
    label: 'Ազգանունը',
    value: '',
    required: true,
    maxLength: 20,
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'firstName',
    label: 'Անունը',
    value: '',
    required: true,
    maxLength: 20,
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'patrName',
    label: 'Հայրանունը',
    value: '',
    required: false,
    maxLength: 20,
  },
  {
    fieldType: 'date',
    dateName: 'birthDate',
    dateTitle: 'Ծննդյան ամսաթիվը',
    required: true,
    maxDate: new Date(
      new Date().getFullYear() - 1,
      new Date().getMonth(),
      new Date().getDate(),
    )
      .toISOString()
      .split('T')[0],
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Սեռը',
    fieldName: 'genderName',
    optionName: 'genders',
    dropdownId: 'genderNo',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.genderName,
        id: i.genderNo,
        value: i.genderName,
      }));
    },
    required: true,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'profession',
    label: 'Մասնագիտությունը',
    value: '',
    required: false,
    maxLength: 30,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Աշխատանքի ընդունման հիմքը',
    fieldName: 'rentName',
    optionName: 'rents',
    dropdownId: 'rentNo',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.rentName,
        id: i.rentNo,
        value: i.rentName,
      }));
    },
    required: true,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'hireNum',
    label: 'Ընդունման հրամանը',
    value: '',
    required: true,
    maxLength: 20,
  },
  {
    fieldType: 'date',
    dateName: 'hireDate',
    dateTitle: 'Ընդունման ամսաթիվը',
    required: true,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'fireNum',
    label: 'Ազատման հրամանը',
    value: '',
    required: false,
    maxLength: 30,
  },
  {
    fieldType: 'date',
    dateName: 'fireDate',
    dateTitle: 'Ազատման ամսաթիվը',
    required: false,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'fireBasis',
    label: 'Ազատման հիմքը',
    value: '',
    required: false,
    maxLength: 30,
  },
];

export const CalculationDataFields = [
  {
    fieldType: 'checkbox',
    name: 'taxSign',
    label: 'Եկամտային հարկը պահվում  է',
    value: '',
  },

  {
    fieldType: 'checkbox',
    name: 'rezidentSign',
    label: 'Ռեզիդենտ',
    value: '',
  },
  {
    fieldType: 'checkbox',
    name: 'pensionSign',
    label: 'Սոցիալական վճարը պահվում է',
    value: '',
  },
  {
    fieldType: 'checkbox',
    name: 'stampSign',
    label: 'Դրոշմանիշային վճարը պահվում է',
    value: '',
  },

  {
    fieldType: 'radio',
    required: true,
    radioTitle: 'Նպաստի հաշվարկի %',
    radioBtn: [
      {
        label: 80,
        value: 80,
        name: 'socPercent',
      },
      {
        label: 100,
        value: 100,
        name: 'socPercent',
      },
    ],
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'tuPercent',
    label: 'Արհմիութենական անդամավճարի %',
    value: '',
    required: false,
    maxLength: 3,
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'Աշխատավարձի հաշիվ (կրեդիտ)',
    fieldName: 'salaryFund',
    optionName: 'creditAccounts',
    dropdownId: 'accountCode',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.accountCode + ' ' + i.accountName,
        id: i.accountCode,
        value: i.accountCode,
      }));
    },
    required: true,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Թղթակցող հաշիվ (դեբետ)',
    fieldName: 'tAcc',
    optionName: 'debitAccounts',
    dropdownId: 'accountCode',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.accountCode + ' ' + i.accountName,
        id: i.accountCode,
        value: i.accountCode,
      }));
    },
    required: false,
  },
];

export const OtherDataFields = [
  {
    fieldType: 'input',
    type: 'text',
    name: 'cardAcc',
    label: 'Հաշիվը բանկում',
    value: '',
    required: true,
    maxLength: 25,
  },
  {
    fieldType: 'input',
    type: 'number',
    name: 'socCardNo',
    label: 'ՀԾՀ',
    value: '',
    required: true,
    maxLength: 10,
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'bankName',
    label: 'Բանկի Անվանումը',
    value: '',
    required: false,
    disabled: true,
    maxLength: 35,
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'passport',
    label: 'Անձը հաստատող փաստաթուղթ',
    value: '',
    required: false,
    maxLength: 20,
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'enName',
    label: 'Անունը ազգանունը լատինատառ',
    value: '',
    required: false,
    maxLength: 30,
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'resAddress',
    label: 'Բնակության հասցեն',
    value: '',
    required: false,
    maxLength: 60,
  },
  {
    fieldType: 'input',
    type: 'number',
    name: 'cardNo',
    label: 'Քարտի համարը',
    value: '',
    required: false,
    maxLength: 25,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'regAddress',
    label: 'Հաշվառման հասցեն',
    value: '',
    required: false,
    maxLength: 60,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'phone',
    label: 'Հեռախոսը',
    value: '',
    required: false,
    maxLength: 15,
  },
  {
    fieldType: 'input',
    type: 'email',
    name: 'email',
    label: 'Էլեկտրոնային հասցեն',
    value: '',
    required: false,
    maxLength: 35,
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'note',
    label: 'Լրացուցիչ տվյալներ',
    value: '',
    required: false,
    maxLength: 80,
  },
];
