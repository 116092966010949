import React from 'react';
import Button from '../../Components/Button/Button';
import './style.scss';

const DynamicFontSize = ({ setSize, size }) => {
  const sizeChangeHandler = (name) => {
    switch (name) {
      case 'normal':
        setSize('normal');
        break;
      case 'medium':
        setSize('medium');
        break;
      case 'large':
        setSize('large');
        break;
      default:
        break;
    }
  };

  return (
    <div className="dynamic-font-size-block G-flex">
      <div className={`L-small-btn ${size === 'large' ? 'isActive' : ''}`}>
        <Button text="Ա" onClick={() => sizeChangeHandler('large')} />
      </div>
      <div className={`L-small-btn ${size === 'medium' ? 'isActive' : ''}`}>
        <Button text="Ա" onClick={() => sizeChangeHandler('medium')} />
      </div>
      <div className={`L-small-btn ${size === 'normal' ? 'isActive' : ''}`}>
        <Button text="Ա" onClick={() => sizeChangeHandler('normal')} />
      </div>
    </div>
  );
};

export default DynamicFontSize;
