import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from '../../Components/Checkbox/Checkbox';
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';
import {
  checkAllCreditAction,
  checkAllDebitAction,
  checkSingleCreditAction,
  checkSingleDebitAction,
} from '../../Redux/circulationNewsletterSlices/useAction';

const CirculationNewsletterTable = ({ mainData, loading }) => {
  const dispatch = useDispatch();
  const columnConfig = [
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountCode,
      customStyle: { maxWidth: 110 },
    },
    {
      title: 'Անվանում',
      cell: (row) => row.accountName,
      customStyle: { maxWidth: 1300, overflow: 'hidden' },
    },

    {
      title: (
        <span className="G-flex">
          Դեբետ
          <Checkbox onChange={(e) => selectAllDebitSign(e)} />
        </span>
      ),

      cell: (row) => (
        <Checkbox
          checked={row.debitSign}
          value={row.debitSign}
          onChange={(e) => checkedSingleDebitSign(e, row)}
        />
      ),
      customStyle: { maxWidth: 160 },
    },
    {
      title: (
        <span className="G-flex">
          Կրեդիտ
          <Checkbox onChange={(e) => selectAllCreditSign(e)} />
        </span>
      ),
      cell: (row) => (
        <Checkbox
          checked={row.creditSign}
          value={row.creditSign}
          onChange={(e) => checkedSingleCreditSign(e, row)}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];

  const selectAllDebitSign = useCallback(
    (e) => {
      dispatch(checkAllDebitAction(e.target.checked));
    },
    [dispatch],
  );

  const checkedSingleDebitSign = useCallback(
    (e, row) => {
      dispatch(checkSingleDebitAction(row));
    },
    [dispatch],
  );

  const selectAllCreditSign = useCallback(
    (e) => {
      dispatch(checkAllCreditAction(e.target.checked));
    },
    [dispatch],
  );

  const checkedSingleCreditSign = useCallback(
    (e, row) => {
      dispatch(checkSingleCreditAction(row));
    },
    [dispatch],
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length && (
        <Table
          customClass="G-table-has-scroll L-circulation-newsletter-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      )}
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.mainData === nextProps.mainData &&
    prevProps.loading === nextProps.loading
  );
};

export default React.memo(CirculationNewsletterTable, areEqual);
