import React from 'react';
import CalendarTime from '../../App/Components/Calendar/CalendarTime';
import { correctDate, dataTimeFormat } from '../../App/Utilities/Utilities';
import InputFiled from '../../App/Components/InputFiled/InputFiled';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getRequest } from '../../App/Api/Api';
import { useEffect } from 'react';
import SearchBtn from '../../App/Components/SearchBtn/SearchBtn';
import CalendarFilteredData from './CalendarFilteredData';
const FilterArea = ({ colorString }) => {
  const { userID } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [getFilterData, setGetFilterData] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [openFilterResForm, setOpenFilterResForm] = useState(false);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  );

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(lastDayOfMonth);

  useEffect(() => {
    if (!getFilterData) {
      setSearchValue('');
    }
  }, [getFilterData]);

  const dateStartFormat = dataTimeFormat(startDate);
  const dateEndFormat = dataTimeFormat(endDate);

  const buildUrl = () => {
    const params = {
      userId: userID,
    };

    if (dateStartFormat) {
      params.startDate = dateStartFormat;
    }

    if (dateEndFormat) {
      params.endDate = dateEndFormat;
    }

    if (searchValue && searchValue.length > 0) {
      params.searchString = searchValue;
    }
    if (colorString && colorString.length > 0 && !colorString.includes(0)) {
      params.colorString = colorString;
    }

    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    return `getCalendarEvents?${queryString}`;
  };

  const onFilterData = () => {
    setLoading(true);
    const url = buildUrl();
    getRequest(url)
      .then((res) => {
        setGetFilterData(res.data);

        setOpenFilterResForm(true);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {}, [
    userID,
    dateStartFormat,
    dateEndFormat,
    colorString,
    searchValue,
  ]);

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, startDate, endDate);

    if (!isCorrect) {
      //setErrorHandler(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setStartDate(val);
    }
    if (name === 'dateEnd') {
      setEndDate(val);
    }
  };

  return (
    <>
      <div className="L-filter-area-block">
        <CalendarTime
          dateStart={startDate}
          setDateStart={setStartDate}
          dateEnd={endDate}
          onChangeHandler={timeChangeHandler}
          removeMinDate={true}
        />

        <InputFiled
          placeholder="Որոնում"
          customClass="G-input-border-style"
          name="searchString"
          value={searchValue}
          changeHandler={(e) => setSearchValue(e.target.value)}
        />

        <SearchBtn onClick={onFilterData} text="Փնտրել" />
      </div>

      {openFilterResForm && (
        <CalendarFilteredData
          filteredData={getFilterData}
          closeModal={setOpenFilterResForm}
          loading={loading}
        />
      )}
    </>
  );
};

export default FilterArea;
