import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { postRequest } from '../../../Api/Api';
import {
  dataTimeFormat,
  removeCommas,
  typOfString,
} from '../../../Utilities/Utilities';
import CashOutflowOrderForm from './CashOutflowOrderForm';

const EventAddForm = ({
  modalClick,
  getData,
  pageNumber,
  setErrorHandler,
  isCopyData = {},
}) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { cashOutflowData } = useSelector((state) => state.getCashOutflowData);
  const { amdCurrCode, amdCurrID, isDrop } = cashOutflowData || {};
  const handleClose = () => modalClick(false);

  const initialData = useMemo(() => {
    return {
      companyID: currentCompanyID,
      docTypeID: 2,
      partnerID: 0,
      currencyID: amdCurrID,
      currencyCode: amdCurrCode,
      docNum: '',
      docDate: new Date(),
      amount: 0,
      accountCredit: isDrop ? '' : '2511',
      recipient: '',
      correspAccount: '',
      areAttached: '',
      exchangeRate: 1,
      credCurrID: amdCurrID,
      credCurrCode: amdCurrCode,
    };
  }, []);

  const onSubmit = (values, resetForm) => {
    postRequest('addcashout', {
      ...values,
      docDate: dataTimeFormat(values?.docDate),
      docNum: typOfString(values.docNum),
      amount: removeCommas(values.amount),
      amountAMD: removeCommas(values.amountAMD),
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          getData(pageNumber);
          resetForm();
          handleClose();
        }
        if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <CashOutflowOrderForm
        handleClose={handleClose}
        initialData={
          Object?.keys(isCopyData)?.length === 0 ? initialData : isCopyData
        }
        onSubmit={onSubmit}
        addEvent={true}
        setErrorHandler={setErrorHandler}
      />
    </>
  );
};

export default EventAddForm;
