import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest, getRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { deletePaymentOrderAction } from '../../Redux/paymentOrderSlices/useAction';
import {
  StandardDocumentsDeleteMessage,
  deleteAgreeText,
} from '../../Utilities/Utilities';
import Alert from '../../Components/Alert';

const DeletePaymentOrder = ({ closeModal, deleteRowId }) => {
  const [isAllowDelete, setIsAllowDelete] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAllowDelete() {
      try {
        if (deleteRowId) {
          const url = `preDeleteEditPaymentOrder?documentID=${deleteRowId}`;
          const res = await getRequest(url);
          if (res.data.errorCode === 100) {
            setIsAllowDelete(true);
          }
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    checkAllowDelete();
  }, [deleteRowId]);

  const deleteRowDate = () => {
    deleteRequest(`deletePaymentOrder/${deleteRowId}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deletePaymentOrderAction(deleteRowId));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={closeModal}
        confirmClick={deleteRowDate}
        modalContent={
          isAllowDelete ? deleteAgreeText : StandardDocumentsDeleteMessage
        }
      />

      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default DeletePaymentOrder;
