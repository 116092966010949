import React from 'react';
import { useContext, useState } from 'react';
import './index.scss';
import { Context } from '../../../index.js';
import { create } from '../../http/aspectAPI.js';
import ItemImg from '../../components/imgSvg/ItemImg.js';
import Checkbox from '../Checkbox/Checkbox';
import defCircle from '../../icons/defCircle.png';
import { useSelector } from 'react-redux';
import Modal from '../Modal/Modal.jsx';
import FormControl from '../FormControl/FormControl.jsx';
import Button from '../Button/Button.jsx';
import Dropdown from '../Dropdown/Dropdown';

const CreateAspect = ({ closeModal }) => {
  const handleClose = () => closeModal(false);
  const { aspect } = useContext(Context);
  const { userID } = useSelector((state) => state.auth);

  const [name, setName] = useState('');
  const [imgColor, setImgColor] = useState('#7B93A4');
  const [imgPath, setImgPath] = useState('defCircle.png');
  const [imgIcon, setImgIcon] = useState(defCircle);

  const [income, setIncome] = useState(true);
  const [cost, setCost] = useState(true);

  const checkBoxHandler = (name, e) => {
    if (name === 'income') {
      setIncome(e.target.checked);
    }
    if (name === 'cost') {
      setCost(e.target.checked);
    }
  };

  const validationHandler = () => {
    return !(
      (income || cost) &&
      typeof name === 'string' &&
      name.trim().length > 0
    );
  };

  const addAspect = async () => {
    const formData = {
      name: name,
      imgPath: imgPath,
      imgColor: imgColor,
      singE: income,
      singT: cost,
      userId: userID,
    };

    const data = await create(formData);
    setName('');
    setImgColor('');
    setImgPath('');
    setIncome(true);
    setCost(true);

    if (data.data.id) {
      aspect.setAspect([...aspect.Aspect, data.data]);
      handleClose();
    } else {
      alert(JSON.stringify('Մուտքագրված տվյալների սխալ...'));
    }
  };

  const categoryName = [
    {
      label: 'Առողջություն',
      name: 'Առողջություն',
    },
    {
      label: 'Ուսում',
      name: 'Ուսում',
    },
    {
      label: 'Մեքենա',
      name: 'Մեքենա',
    },
    {
      label: 'Կոմունալ վճարներ',
      name: 'Կոմունալ վճարներ',
    },
    {
      label: 'Հարկեր',
      name: 'Հարկեր',
    },
    {
      label: 'Վարկեր',
      name: 'Վարկեր',
    },
    {
      label: 'Տրանսպորտ',
      name: 'Տրանսպորտ',
    },
    {
      label: 'Ռեստորան/սրճարան',
      name: 'Ռեստորան/սրճարան',
    },
    {
      label: 'Պարտք',
      name: 'Պարտք',
    },
    {
      label: 'Անձնական',
      name: 'Անձնական',
    },
  ];

  return (
    <Modal customClass="create-aspect-modal" closeModal={closeModal}>
      <h3 className="enter-form-title">Ավելացնել տիպ</h3>
      <div className="create-aspect-continer">
        <form>
          <ItemImg
            imgPath={imgPath}
            setImgPath={setImgPath}
            imgColor={imgColor}
            setImgColor={setImgColor}
            imgIcon={imgIcon}
            setImgIcon={setImgIcon}
          />
          <div className="category-name-flex">
            <div className="category-name-input">
              <FormControl
                placeholder="Մուտքագրեք տիպի անվանումը"
                name=""
                value={name}
                onChange={(e) => setName(e.target.value)}
                required={true}
              />
            </div>
            <Dropdown
              customClass="expense-income-types-block"
              options={categoryName}
              defaultInputValue="Ընտրել տիպը"
              value={{ label: name, value: name }}
              onChange={(e) => {
                setName(e.name);
              }}
            />
          </div>
          <div className="checkbox-box">
            <div className="checkbox-wrapper">
              <Checkbox
                label="Ծախս"
                value={cost}
                checked={cost}
                name="cost"
                onChange={(val) => checkBoxHandler('cost', val)}
              />
            </div>
            <div className="checkbox-wrapper">
              <Checkbox
                label="Եկամուտ"
                value={income}
                checked={income}
                name="income"
                onChange={(val) => checkBoxHandler('income', val)}
              />
            </div>
          </div>
          <Button
            type="button"
            onClick={addAspect}
            customClass=""
            text="Ավելացնել"
            disabled={validationHandler()}
          />
        </form>
      </div>
    </Modal>
  );
};
export default CreateAspect;
