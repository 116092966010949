import React from 'react';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
import WarehouseForm from './WarehouseForm';
import useWarehouse from '../../hooks/useWarehouse';
import { useDispatch } from 'react-redux';
import { addNewWarehouseDropData } from '../../../App/Redux/materialValuesStor/warehouseEntrySlices/useAction';

const EventAddForm = ({
  handleClose,
  params,
  formIsUsedExternally,
  customName,
  customId,
  setFieldValue,
}) => {
  const { addNewWarehouse, errorMess, setErrorMess } = useWarehouse();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const onAddHandler = async (values, resetForm) => {
    try {
      await addNewWarehouse(values, handleClose, params);
      if (formIsUsedExternally) {
        const docStores = {
          storeName: values.storeName,
          storeNo: values.storeNo,
        };

        dispatch(addNewWarehouseDropData({ stores: docStores }));
        setFieldValue(customName, values.storeName);
        setFieldValue(customId, values.storeNo);
      }

      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <WarehouseForm onSubmit={onAddHandler} handleClose={handleClose} />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
