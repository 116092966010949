import { getRequest, postRequest } from '../../App/Api/Api';
import { dataTimeFormat } from '../../App/Utilities/Utilities';

export const fetchEmployeeVacation = async (companyId, salaryDate, empNo) => {
  try {
    let url = `getEmployeeVacation?companyID=${companyId}&salaryDate=${salaryDate}&empNo=${empNo}`;

    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const saveEmployeeVacation = async (employeeVacation) => {
  try {
    const response = await postRequest('saveEmployeeVacation', {
      ...employeeVacation,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding employeeVacation:', error);
    throw error;
  }
};
export const saveAverages = async (newAverages) => {
  try {
    const response = await postRequest('saveAverages', newAverages);

    return response.data;
  } catch (error) {
    console.error('Error adding SaveAverages:', error);
    throw error;
  }
};

export const fetchNonWorkings = async (companyId, empNo) => {
  try {
    let url = `getNonWorkings?companyID=${companyId}&empNo=${empNo}`;
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchVacationEndDate = async (
  companyId,
  empNo,
  vacStartDate,
  vacDays,
  stNo,
) => {
  if (!empNo) {
    return;
  }
  try {
    let url = `getVacationEndDate?companyID=${companyId}&empNo=${empNo}&vacStartDate=${dataTimeFormat(
      vacStartDate,
    )}&vacDays=${vacDays}&stNo=${stNo}`;
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
