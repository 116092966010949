import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { putRequest, getRequest } from '../../../Api/Api';
import Alert from '../../../Components/Alert';
import { editPartnerBalanceAction } from '../../../Redux/partnerSlices/useAction';
import { removeCommas } from '../../../Utilities/Utilities';
import PartnerBalanceForm from './PartnerBalanceForm';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { InitBalancesAllowEditText } from '../../../GlobalTitle/GlobalTitle';
const EventEditForm = ({ rowData, closeModal }) => {
  const { accountCode, companyID, cashSign, partnerID } = rowData || {};
  const [isAllowEdit, setIsAllowEdit] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAllowEdit() {
      try {
        const url = `preDeleteEditPartnerInitBalance?partnerID=${partnerID}&accountCode=${accountCode}&cashSign=${cashSign}`;
        const res = await getRequest(url);
        const { errorCode, message } = res.data;
        if (errorCode === 1) {
          setIsAllowEdit(true);
        } else if (errorCode === 100) {
          setErrorHandler(message);
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    if (accountCode) {
      checkAllowEdit();
    }
  }, [accountCode, cashSign, companyID, partnerID]);

  const onSubmit = (values) => {
    putRequest('editPartnerInitBalance', {
      ...values,
      amountDebit: removeCommas(values.amountDebit),
      amountCredit: removeCommas(values.amountCredit),
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(editPartnerBalanceAction(values));
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };
  return (
    <>
      {isAllowEdit ? null : (
        <PartnerBalanceForm
          initialData={rowData}
          closeModal={handleClose}
          onSubmit={onSubmit}
        />
      )}

      {isAllowEdit && (
        <AcceptOrCancelModal
          closeModal={closeModal}
          confirmClick={() => setIsAllowEdit(false)}
          modalContent={InitBalancesAllowEditText}
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventEditForm;
