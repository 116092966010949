import React, { useState, useCallback, useEffect } from 'react';
import Modal from '../../App/Components/Modal/Modal';
import { dateFormat } from '../../App/Utilities/Utilities';
import './CalendarType.scss';
import { getRequest } from '../../App/Api/Api';

const CalendarType = ({ closeModal, calendarInfo }) => {
  const { calId, calenarType } = calendarInfo;
  const handleClose = () => closeModal(false);
  const [calendarList, setCalendarList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCalendar = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getRequest(`getCalendar?calId=${calId}`);
      if (res.data) {
        setCalendarList(res.data);
      }
    } catch (err) {
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  }, [calId]);

  useEffect(() => {
    fetchCalendar();
  }, [fetchCalendar]);

  if (isLoading) {
    return (
      <Modal
        closeHandler={handleClose}
        customClass="L-calendarType-modal"
        onDrag={true}
      >
        <p>Բեռնվում է...</p>
      </Modal>
    );
  }

  return (
    <Modal
      closeHandler={handleClose}
      customClass="L-calendarType-modal"
      onDrag={true}
    >
      <div className="G-modal-block-title">
        <h3>{calenarType}</h3>
      </div>
      <div className="L-calendarType-wrap">
        {calendarList?.map(({ calName, title, description, date }) => (
          <div className="L-calendar-list G-column" key={date}>
            <strong>{dateFormat(date)}</strong>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default CalendarType;
