import { FormattedDateHy } from '../FormattedDateHy';

export const ConvertDataExcel = (filteredData) => {
  return (
    filteredData &&
    filteredData?.map((i) => {
      return {
        Ամսաթիվ: FormattedDateHy(i.date),
        Տեսակը: i.description,
        Տեքստ: i.title,
        Ժամ: i.time,
      };
    })
  );
};

export default ConvertDataExcel;
