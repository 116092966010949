import React from 'react';
import OneWindowLogo from '../../../Components/OneWindowLogo/OneWindowLogo';
import './header.scss';
import Image from '../ImageBlock';
import { v4 as uuidv4 } from 'uuid';
import { tabItems } from './tabItemsList';

const Header = ({ toggleTabNameHandler, selectTab }) => {
  const toggleHandler = () => {
    toggleTabNameHandler('window');
  };

  return (
    <div className="G-one-window-view-header-block">
      <div className="G-one-window-view-header-content">
        <OneWindowLogo
          handleClick={toggleHandler}
          className={'window' === selectTab && 'isActiveTab'}
        />
        <div className="G-justify-around-align-center G-window-header-icon-tab">
          {tabItems?.map(({ tabImg, tabName, title }) => {
            const id = uuidv4();
            return (
              <Image
                src={tabImg}
                toggleTab={toggleTabNameHandler}
                name={tabName}
                selectTab={selectTab}
                className={tabName === selectTab && 'isActiveTab'}
                title={title}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Header;
