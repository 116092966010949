import { createAction } from '../../actionCreators';
import {
  DELETE_ADDITIONAL_STORAGE,
  FETCH_ADDITIONAL_STORAGE,
  FETCH_ADDITIONAL_STORAGE_FORM_DATA,
  UPDATE_ADDITIONAL_STORAGE,
} from './constants';

export const fetchAssignAdditionalStorageAction = createAction(
  FETCH_ADDITIONAL_STORAGE,
);
export const deleteAssignAdditionalStorageAction = createAction(
  DELETE_ADDITIONAL_STORAGE,
);
export const updateAssignAdditionalStorageAction = createAction(
  UPDATE_ADDITIONAL_STORAGE,
);
export const fetchAssignAdditionalStorageFormDataAction = createAction(
  FETCH_ADDITIONAL_STORAGE_FORM_DATA,
);
