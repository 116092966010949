import React, { useState } from "react";
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';
import DeleteCurrencies from './DeleteCurrencies';

const CurrenciesTable = ({ mainData, loading }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const columnConfig = [
    {
      title: 'Կոդը',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.currencyName,
      customStyle: { maxWidth: 900 },
    },

    {
      title: 'Գործողություն',
      cell: (row) => (
        <span
          className="G-delete-span"
          onClick={() => openDeleteForm(row?.currencyID)}
        >
          {row.currencyCode === 'AMD' ? '' : 'Ջնջել'}
        </span>
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  const openDeleteForm = (deleteRowId) => {
    setOpenDeleteModal(deleteRowId);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-currencies-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {openDeleteModal && (
        <DeleteCurrencies
          deleteRowId={openDeleteModal}
          closeModal={setOpenDeleteModal}
        />
      )}
    </>
  );
};

export default CurrenciesTable;
