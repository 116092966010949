import React from 'react';
import { ReactComponent as SearchIcon } from '../../Assets/icons/search.svg';

const SearchInput = ({
  value,
  onChange,
  name,
  placeholder = 'Որոնել',
  searchFiledTitle = '',
}) => {
  const inputId = `${name}-input`;

  return (
    <div className="G-search-input-wrapper">
      {searchFiledTitle && <p className="G-title-p">{searchFiledTitle}</p>}
      <div className="G-search-input-content">
        <label htmlFor={inputId}>
          <input
            type="search"
            id={inputId}
            placeholder={placeholder}
            defaultValue={value}
            name={name}
            onChange={onChange}
            autocomplete="off"
          />
          <span className="G-search-icon">
            <SearchIcon />
          </span>
        </label>
      </div>
    </div>
  );
};

export default SearchInput;
