import React from 'react';
import Select, { createFilter } from 'react-select';
import styles from './select-control.module.scss';
import './index.scss';

export const SelectControl = ({
  id,
  options,
  value,
  disabled = false,
  noOptionsMessage = '',
  onChange,
  onBlur,
  onFocus,
  requiredFiled = false,
  selectBoxTitle = '',
  matchFromParam = 'start',
  isMulti = false,
  components,
  closeMenuOnSelect = true,
  hideSelectedOptions = true,
  placeholder = '',
  isClearable = true,
  isSearchable = true,
}) => {
  return (
    <div className="G-select-control">
      <div className="G-select-title">
        {requiredFiled ? <span className="G-asterisk">*</span> : null}
        {selectBoxTitle && <p className="G-title-p">{selectBoxTitle}</p>}
      </div>
      <div className="G-select-wrapper G-flex">
        <div className={styles.selectControl}>
          <Select
            closeMenuOnSelect={closeMenuOnSelect}
            isSearchable={isSearchable}
            hideSelectedOptions={hideSelectedOptions}
            components={components}
            isMulti={isMulti}
            filterOption={createFilter({ matchFrom: matchFromParam })}
            value={value}
            isClearable={isClearable}
            onChange={(e) => onChange(e, id)}
            options={options}
            isDisabled={disabled}
            placeholder={placeholder && placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            styles={{
              option: (base) => ({ ...base, fontSize: '13px' }),
              noOptionsMessage: (base) => ({ ...base, fontSize: '12px' }),
            }}
            noOptionsMessage={() => noOptionsMessage}
          />
        </div>
      </div>
    </div>
  );
};
