import React from 'react';
import './style.scss';
import { SelectControl } from '../../App/Components/SelectControl/SelectControl';

const YearView = ({ selectedYear, handleYearChange }) => {
  const currentYear = new Date().getFullYear() + 1;
  const endYear = currentYear + 2;
  const yearOptions = [];

  for (let year = currentYear; year <= endYear; year++) {
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  return (
    <div className="year-view">
      <SelectControl
        selectBoxTitle=""
        options={yearOptions}
        value={{ value: selectedYear, label: selectedYear }}
        onChange={handleYearChange}
        isClearable={true}
      />
    </div>
  );
};

export default YearView;
