import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import SummaryDataTable from './SummaryDataTable';
import useSummaryData from '../../hooks/useSummaryData';
import SummaryDataAction from './SummaryDataAction';
import SummaryDataTotal from './SummaryDataTotal';
import './style.scss';

const SummaryData = () => {
  const {
    getSummaryData,
    isLoading,
    summaryData,
    summaryTableData,
    getExecuteSummaryData,
    setSummaryTableData,
  } = useSummaryData();

  const { salaryTotals, totals } = summaryTableData || {};
  const [query, setQuery] = useState({
    eContract: '',
    cContract: '',
  });

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      eContract: summaryData?.eContract,
      cContract: summaryData?.cContract,
      startDate: summaryData?.startDate,
      endDate: summaryData?.endDate,
    }));
  }, [
    summaryData?.cContract,
    summaryData?.eContract,
    summaryData?.endDate,
    summaryData?.startDate,
  ]);

  useEffect(() => {
    getSummaryData();
  }, []);
  const resetTableData = () => {
    setSummaryTableData(null);
  };
  return (
    <div className="L-summary-data-block">
      <Header
        pageTitle="Ամփոփ տվյալներ"
        headerBtn={false}
        showCalculationDate={true}
      />
      <SummaryDataAction
        query={query}
        setQuery={setQuery}
        summaryData={summaryData}
        getExecuteSummaryData={getExecuteSummaryData}
        resetTableData={resetTableData}
      />
      <div className="G-medium-wrap">
        <SummaryDataTable isLoading={isLoading} summaryData={salaryTotals} />
        {salaryTotals && <SummaryDataTotal totals={totals} />}
      </div>
    </div>
  );
};

export default SummaryData;
