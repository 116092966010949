import React from 'react';

const TabButtons = ({ handleTabChange, activeTab }) => {
  const tabs = {
    GeneralVacationForm: 'Ընդհանուր',
    VacationAveragesForm: 'Միջիններ',
    VacationStory: 'Պատմություն',
  };

  return (
    <div className="tab-buttons">
      {Object.keys(tabs).map((tab) => (
        <button
          key={tab}
          onClick={() => handleTabChange(tab)}
          className={tab === activeTab ? 'tab-btn-isActive' : 'tab-btn'}
        >
          {tabs[tab]}
        </button>
      ))}
    </div>
  );
};

export default TabButtons;
