import React, { useState } from 'react';
import DynamicFontSize from '../../../Elements/DynamicFontSize';
import './whoAreWe.scss';

const WhoAreWe = ({ onToggleTab }) => {
  const [size, setSize] = useState('normal');
  const whoAreWeText =
    'Մենք՝ տեղեկատվական տեխնոլոգիաների, համակարգչային ու Web ծրագրավորման, հաշվապահական հաշվառման և կառավարման ոլորտներում փորձառու մասնագետների հետաքրքիր խումբ ենք։ Ինչու՞ հետաքրքիր. մեր թիմը բաղկացած է մասնագետների երեք սերնդի ներկայացուցիչներից, որոնց աշխատանքային գործունեության փորձը տատանվում է 5-ից մինչև 50 տարի։ Կարելի է ասել, որ ավանդական հայկական ընտանիքի նման ենք, հիմնված փոխադարձ հարգանքի և վստահության վրա։';

  return (
    <>
      <div className="L-who-are-we-block" id={`${`G-${size}`}`}>
        <div className="G-flex">
          <DynamicFontSize setSize={setSize} size={size} />
          <h3>1Պ հարթակի մասին</h3>
        </div>

        <p>
          Հարթակը ստեղծվել է անհատներին, փոքր բիզնեսով զբաղվողներին,
          ձեռներեցներին օգնելու համար։ Այն ծնվել է անցյալի հարուստ փորձի և դեպի
          ապագան միտված նպատակների միահյուսման արդյունքում։ Այն դինամիկ զարգացող
          հարթակ է՝ բաղկացած երեք մասից։
        </p>
        <p>Առաջին՝ «Հիմնական գործիքներ»</p>
        <p className="has-dot">
          Մեր կողմից մշակված «Հաշվապահական հաշվառում» և «Անձնական բյուջե»
          հաշվողական ծրագրեր, ինչպես նաև «Օրացույց» ծրագիր։
        </p>
        <p>Երկրորդ՝ «Կարևոր կայքեր»</p>
        <p className="has-dot">
          ՀՀ Կենտրոնական բանկի և ՊԵԿ-ի (Պետական եկամուտների կոմիտե) կողմից
          ստեղծած կարևոր կայքէջերի հղումներ
        </p>
        <p>Երրորդ՝ «Օգտակար հղումներ»</p>
        <p className="has-dot">
          Պետական և մասնավոր սեկտորի տեղեկատվական կայքեր, որոնց մեջ պարունակվող
          տեղեկատվության օգտագործումը, մեր կարծիքով, կարող է բարձրացնել բիզնեսի
          վարման արդյունավետությունը։
        </p>
        <p>
          Հարթակում ներկայացվող տեղեկատվությունը պարբերաբար թարմացվում և
          ընդլայնվում է, ներկա պահին հարթակից արվող հղումների թիվն անցնում է
          10-ից։ Ձեզ հետաքրքրող շատ հարցերի պատասխանը կգտնեք այցելելով մեր
          հաջորդ էջը՝{' '}
          <span onClick={() => onToggleTab('Questions')}>
            «Հաճախ տրվող հարցեր»
          </span>
          ։
        </p>
      </div>
      <div className="L-who-are-we-block" id={`${`G-${size}`}`}>
        <h3>Ով ենք մենք</h3>
        <p>{whoAreWeText}</p>
        <p>
          1Պ հարթակի հարգելի օգտատեր, քանի որ սպասարկման բարձր որակի ապահովումը
          մեր թիմի գերխնդիրն է՝ կանենք ամեն ինչ Ձեր վստահությունը ձեռք բերելու
          համար, կլինենք մշտական կապի մեջ, հաշվի կառնվեն Ձեր կողմից արված
          դիտողություններն ու առաջարկները։
        </p>
        <p>
          Հարթակի ծառայություններից օգտվելու համար սեղմեք{' '}
          <span onClick={() => onToggleTab('Login')}> «Մուտք»</span> բառի վրա։
        </p>
      </div>
    </>
  );
};

export default WhoAreWe;
