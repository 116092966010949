import { CANCEL, CONFIRM } from '../../constant';

export const partnerFormFields = [
  {
    fieldType: 'input',
    type: 'text',
    name: 'partnerName',
    label: 'Գործընկերոջ անվանումը',
    value: '',
    required: true,
    maxLength: 50,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Տեսակը',
    required: true,
    fieldName: 'partnerTypeName',
    dropdownId: 'partnerType',
    optionName: 'partnerTypes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.partnerTypeName,
        id: i.partnerTypeID,
        value: i.partnerTypeName,
      }));
    },
  },
  {
    fieldType: 'checkbox',
    name: 'partnerForm',
    label: 'ֆիզիկական անձ ',
    value: '',
    checkboxTitle: 'Տիպը',
    required: false,
  },

  {
    fieldType: 'radio',
    required: false,
    radioTitle: '',
    radioBtn: [
      {
        label: 'ՀՀ',
        value: 0,
        name: 'countryType',
      },
      {
        label: 'Արտերկիր',
        value: 1,
        name: 'countryType',
      },
    ],
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'taxCode',
    label: 'ՀՎՀՀ',
    value: '',
    required: (v) => {
      return !v.partnerForm;
    },
    maxLength: (v) => {
      return v.countryType === 0 ? 8 : 20;
    },
  },
  {
    fieldType: 'personalData',
    required: false,
    personalData: [
      {
        fieldType: 'input',
        type: 'text',
        name: 'taxeServiceNumber',
        label: 'ՀԾՀ',
        value: '',
        maxLength: (v) => {
          return v.countryType === 0 ? 10 : 20;
        },
      },
      {
        fieldType: 'input',
        type: 'text',
        name: 'passport',
        label: 'Անձնագիր',
        value: '',
        maxLength: (v) => {
          return v.countryType === 0 ? 9 : 20;
        },
      },
      {
        fieldType: 'input',
        type: 'text',
        name: 'idCard',
        label: 'ID քարտ',
        value: '',
        maxLength: (v) => {
          return v.countryType === 0 ? 9 : 20;
        },
      },
    ],
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'basis',
    label: 'Հիմքը (Պայմանագիր N կամ փաստաթուղթ N )',
    value: '',
    required: false,
    maxLength: 60,
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'description',
    label: 'Հասցեն',
    value: '',
    required: false,
    maxLength: 60,
  },

  {
    fieldType: 'button',
    personalData: [
      {
        buttonText: 'Ընդհատել',
        actionType: CANCEL,
        onClickHandler: (query) => {},
        // disabled: (query) => {
        //   return
        // },
      },
      {
        buttonText: 'Հաստատել',
        actionType: CONFIRM,
        onClickHandler: (query) => {},
        disabled: (isValid) => {
          return isValid;
        },
      },
    ],
  },
];
