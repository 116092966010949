export const CALCULATE = 'calculate';
export const DELETE_CALCULATE = 'deleteCalculation';

export const actionConfigs = [
  {
    title: 'Ստորաբաժանում',
    type: 'dropdown',
    optionName: 'workDeps',
    optionID: 'depNo',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.depName,
        id: i.depNo,
        value: i.depName,
      }));
    },
  },
  {
    type: 'button',
    buttonText: 'Հաշվել',
    actionType: CALCULATE,
    onClickHandler: (query) => {},
    // disabled: (query) => {
    //   return
    // },
  },
  {
    type: 'button',
    buttonText: 'Ջնջել հաշվարկը',
    actionType: DELETE_CALCULATE,
    onClickHandler: (query) => {},
    // disabled: (query) => {
    //   return
    // },
  },
];
