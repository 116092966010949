import React from "react";
import { useDispatch } from "react-redux";
import { deleteRequest } from "../../Api/Api";
import AcceptOrCancelModal from "../../Components/AcceptOrCancelModal/AcceptOrCancelModal";
import { deletePurchaseActAction } from "../../Redux/purchaseActSlices/useAction";
import { StandardDocumentsDeleteMessage } from "../../Utilities/Utilities";

const DeletePurchaseAct = ({ deleteRowId, closeModal }) => {
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  const deleteRowDate = () => {
    deleteRequest(`deletePurchase/${deleteRowId}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deletePurchaseActAction(deleteRowId));
        handleClose();
      }
    });
  };

  return (
    <AcceptOrCancelModal
      closeModal={closeModal}
      confirmClick={deleteRowDate}
      modalContent={StandardDocumentsDeleteMessage}
    />
  );
};

export default DeletePurchaseAct;
