import React, { useEffect } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import { useFormik } from 'formik';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import useAdditionsTypes from '../../hooks/useAdditionsTypes';
import { additionsTypeField } from './FieldsConfig';
import Input from '../../Input/Input';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import CalculatesFromAdditions from './CalculatesFromAdditions';
import InputTitle from '../../Input/InputTitle';
import RadioButton from '../../../App/Components/RadioButton/RadioButton';
import { AdditionsValidation } from '../../../App/Validation/AdditionsValidation';
import useIsMounted from '../../hooks/useIsMounted';

const AdditionsTypeForm = ({ handleClose, onSubmit, salaryTypeID = 0 }) => {
  const { fetchAdditionsForm, additionsTypesForm } = useAdditionsTypes();
  const isMounted = useIsMounted();
  useEffect(() => {
    fetchAdditionsForm(salaryTypeID);
  }, [salaryTypeID]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...additionsTypesForm?.asType,
    },
    validationSchema: AdditionsValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
      if (isMounted) {
        resetForm();
      }
    },
  });

  const updateFieldValueAndDropdown = (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: fieldName === 'tAcc' ? ' ' : 0,
      };
    }
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id;

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="addition-form-block"
    >
      <form onSubmit={handleSubmit}>
        {additionsTypeField.map((field) => {
          const {
            fieldType,
            fieldName,
            label,
            name,
            required,
            Children,
            optionName,
            dropdownTitle,
            type,
            dropdownId,
            radioBtn,
            radioTitle,
            maxlength,
            disabled,
            icon,
          } = field;

          const fieldError = touched[name] && errors[name];
          let dropDownOptions = [];

          if (typeof Children === 'function' && additionsTypesForm) {
            dropDownOptions = Children(additionsTypesForm[optionName]);
          }

          switch (fieldType) {
            case 'input':
              return (
                <div key={name} className="saving-input-container">
                  <Input
                    type={type}
                    inputTitle={label}
                    name={name}
                    requiredFiled={required}
                    onChange={handleChange}
                    value={values[name]}
                    maxLength={maxlength}
                  />
                </div>
              );
            case 'dropdown':
              return (
                <div key={fieldName} className="select-container">
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={required}
                    value={{
                      label: values[fieldName] || '',
                      value: values[fieldName] || '',
                    }}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      updateFieldValueAndDropdown(
                        fieldName,
                        selectedOption,
                        dropdownId,
                      )
                    }
                    onBlur={handleBlur}
                  />
                  {required && !values[fieldName] && fieldError && (
                    <ErrorFiled error={fieldError} />
                  )}
                </div>
              );
            case 'checkbox':
              return (
                <Checkbox
                  key={name}
                  name={name}
                  label={label}
                  value={values[name]}
                  checked={values[name]}
                  onChange={() => setFieldValue(name, !values[name])}
                  onBlur={handleBlur}
                  disabled={disabled ? disabled(values.graphNo) : false}
                />
              );
            case 'radio':
              return (
                <div key={fieldName} className="additions-radio-wrap">
                  <InputTitle
                    requiredFiled={required}
                    inputTitle={radioTitle}
                  />
                  <div className="radio-btn-flex">
                    {radioBtn?.map(({ value, name, label, disabled }) => (
                      <RadioButton
                        key={value}
                        name={name}
                        value={value}
                        checked={value === values[name]}
                        onChange={() => setFieldValue(name, value)}
                        label={label}
                        disabled={disabled ? disabled(values.graphNo) : false}
                      />
                    ))}
                  </div>
                </div>
              );
            case 'upArrow':
              return (
                <div
                  key={fieldName}
                  className="G-up-arrow-block"
                  onClick={() => setFieldValue(name, !values[name])}
                >
                  <img
                    src={icon}
                    alt=""
                    style={{
                      transform: values?.calcSign && 'rotate(1deg)',
                    }}
                  />
                  <p>{label}</p>
                </div>
              );

            case 'onHoldList':
              return (
                values?.calcSign && (
                  <div key={fieldName} className="additions-radio-wrap">
                    <CalculatesFromAdditions
                      data={additionsTypesForm?.additions}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                )
              );
            default:
              return null;
          }
        })}

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
          executeDisabled={!dirty || !isValid}
        />
      </form>
    </Modal>
  );
};

export default AdditionsTypeForm;
