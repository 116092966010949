export const dropdownConfigs = [
  {
    title: 'Հաշվարկի ամսից',
    type: 'dropdown',
    optionName: 'salaryStartMonths',
    optionID: 'startYearMonth',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.salaryMonthName,
        id: i.yearMonth,
        value: i.salaryMonthName,
      }));
    },
    required: true,
  },
  {
    title: 'Հաշվարկի ամիս',
    type: 'dropdown',
    optionName: 'salaryEndMonths',
    optionID: 'endYearMonth',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.salaryMonthName,
        id: i.yearMonth,
        value: i.salaryMonthName,
      }));
    },
    required: true,
  },
];
