import React from 'react';
import Table from '../../../App/Components/Table/Table';
import ProvisionWrap from '../../HOC/provisionWrap/ProvisionWrap';
import { onlyCommas } from '../../../App/Utilities/Utilities';

const IncomeTaxScaleTable = ({ mainData }) => {
  const columnConfig = [
    {
      title: 'Ներքին սահմանը',
      cell: (row) => onlyCommas(row.lowerAmount),
      customStyle: { maxWidth: 500 },
    },
    {
      title: 'Վերին սահմանը',
      cell: (row) => onlyCommas(row.upperAmount),
      customStyle: { maxWidth: 500 },
    },
    {
      title: 'Տոկոսը',
      cell: (row) => row.percent,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գումարը',
      cell: (row) => onlyCommas(row.amount),
      customStyle: { maxWidth: 410 },
    },
  ];

  return (
    <>
      {mainData !== null && mainData && mainData?.length && (
        <Table
          customClass="G-provisions-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      )}
    </>
  );
};

const IncomeTaxScaleTableWrap = ProvisionWrap(IncomeTaxScaleTable);

export default IncomeTaxScaleTableWrap;
