import React from 'react';
import InputFiled from '../InputFiled/InputFiled';
import './main.scss';

const SelectDocNum = (props) => {
  const {
    name = 'docNum',
    value,
    changeHandler,
    customClass = 'G-input-border-style',
    inputTitle = 'Փաստաթուղթ N',
  } = props;
  return (
    <div className="G-docNum-input">
      <InputFiled
        inputTitle={inputTitle}
        customClass={customClass}
        name={name}
        value={value}
        changeHandler={changeHandler}
        autocomplete="off"
      />
    </div>
  );
};

export default SelectDocNum;
