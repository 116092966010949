import React from "react";
import Button from '../../Components/Button/Button';
import CalendarTime from '../../Components/Calendar/CalendarTime';
import SelectCurrency from '../../Components/SelectBox/SelectCurrency';
import SelectPartnerType from '../../Components/SelectBox/SelectPartnerType';
import Spinner from '../../Components/Spinner/Spinner';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import {
  correctDate,
  inCorrectDateMess,
  viewPrintTitle,
} from '../../Utilities/Utilities';
import SearchFiled from '../../Components/SearchFiled/SearchFiled';

const PartnersAccountStatementAction = (props) => {
  const {
    dateStart,
    setDateStart,
    dateEnd,
    setDateEnd,
    currencyVal,
    setCurrencyVal,
    clickHandlerView,
    setPartnerTypeVal,
    partnerTypeVal,
    disableViewBtn,
    isSubmitting,
    searchValue,
    setSearchValue,

  } = props;
 const { data } = useFetchCurrency();

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      alert(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };

  const changeCurrency = (value) => {
    if (!value) {
      value = {
        value: '',
        id: 0,
      };
    }
    setCurrencyVal(value);
  };

  const selectChangePartner = (values) => {
    if (!values) {
      values = {
        value: '',
        id: 0,
      };
    }
    setPartnerTypeVal(values);
  };

  return (
    <div className="G-justify-between-align-center">
      <CalendarTime
        dateStart={dateStart}
        setDateStart={setDateStart}
        dateEnd={dateEnd}
        setDateEnd={setDateEnd}
        onChangeHandler={timeChangeHandler}
      />
      <SelectPartnerType
        partnerTypeVal={partnerTypeVal}
        selectChangePartner={(values) => selectChangePartner(values)}
      />
      <SearchFiled searchValue={searchValue} setSearchValue={setSearchValue} />

      <SelectCurrency
        requiredFiled={false}
        currencyData={data?.currencies}
        currencyVal={currencyVal}
        selectChangeCurrency={(value) => changeCurrency(value)}
      />

      <div className="G-view-btn">
        <Button
          text={!isSubmitting ? viewPrintTitle : <Spinner />}
          onClick={clickHandlerView}
          disabled={disableViewBtn || isSubmitting}
          customClass={
            disableViewBtn || isSubmitting ? 'G-disabled-button' : ''
          }
        />
      </div>
    </div>
  );
};

export default PartnersAccountStatementAction;
