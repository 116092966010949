import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Action from '../../../App/Components/Table/Action';
import { tableActionTitle } from '../../../App/Utilities/Utilities';
import { globalActionItems } from '../../Config/tableConfig';
import Forms from './Forms';
import Loading from '../../../App/Components/Loading/Loading';
const MaterialGroupsTable = ({ materialGroupData, isLoading }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Կոդը',
      cell: (row) => row.groupNo,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.groupName,
      customStyle: { maxWidth: 600, overflow: 'hidden' },
    },

    {
      title: 'Հաշիվ',
      cell: (row) => row.accCode,
      customStyle: { maxWidth: 100 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(type, row) =>
            setShowForm({
              type: type,
              value: row,
            })
          }
          actionItem={globalActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {materialGroupData !== null &&
      materialGroupData &&
      materialGroupData?.length ? (
        <Table
          customClass=""
          data={materialGroupData}
          columnConfig={columnConfig}
        />
      ) : null}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default MaterialGroupsTable;
