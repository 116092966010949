import React from 'react';
import Checkbox from '../../../../App/Components/Checkbox/Checkbox';

const radioOptions = {
  itSign: 'ՏՏ ոլորտի հարկային արտոնություններից օգտվող',
  socialSign: 'Մասնակցում է պարտադիր կուտակային կենսաթոշակային համակարգին',
};

const SalaryCalcCheckedBtn = ({ formData, handleOptionChange }) => {
  return (
    <div className="checkbox-buttons">
      {Object.entries(radioOptions).map(([name, label]) => (
        <Checkbox
          key={name}
          name={name}
          label={label}
          checked={formData[name]}
          onChange={handleOptionChange}
          className="checkbox-input"
        />
      ))}
    </div>
  );
};

export default SalaryCalcCheckedBtn;
