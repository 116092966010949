import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { postRequest } from '../../Api/Api';
import {
  getFinalBalance,
  getTotalExpenditure,
} from '../../Utilities/Utilities';
import SpendingMoneyWrapper from './SpendingMoneyWrapper';

const AddSendingMoneyWrapper = ({
  closeModal,
  getData,
  pageNumber,
  setErrorHandler,
}) => {
  const handleClose = () => closeModal(false);
  const { spendingDealsData } = useSelector(
    (state) => state.getSpendingMoneyData,
  );
  const { cashMoney, expenses } = spendingDealsData || {};
  const { basis } = cashMoney || {};
  const [getCashMoneyId, setGetCashMoney] = useState(null);
  const disabledSaveDocBtn =
    !(expenses?.length > 0 && basis?.length) > 0 ? true : false;
  const [submitted, setSubmitted] = useState(true);

  const addSpendingMoneyData = () => {
    postRequest('addCashMoney', {
      ...spendingDealsData,
      cashMoney: {
        ...cashMoney,
        expense: getTotalExpenditure(spendingDealsData.expenses),
        finalBalance: getFinalBalance(cashMoney, spendingDealsData.expenses),
      },
    })
      .then((res) => {
        setGetCashMoney(res.data.id);
        if (res.data.errorCode === 0) {
          getData(pageNumber);
          setSubmitted(false);
          setErrorHandler('Փաստաթուղթը գրանցվեց');
          setTimeout(() => {
            handleClose();
          }, 1000);
        }
        if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <SpendingMoneyWrapper
      closeModal={handleClose}
      onSubmitData={addSpendingMoneyData}
      disabledOperationBtn={submitted}
      setSubmitted={setSubmitted}
      disabledSaveDocBtn={disabledSaveDocBtn}
      getCashMoneyId={getCashMoneyId}
      showInput={true}
    />
  );
};

export default AddSendingMoneyWrapper;
