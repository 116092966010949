import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Modal from '../../Components/Modal/Modal';
import HistoryTable from './HistoryTable';
import Loading from '../../Components/Loading/Loading';

const HistoryTaxationSystem = ({ closeModal }) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [historyTaxationSystemData, setHistoryTaxationSystemData] = useState(
    [],
  );
  const [loading, setLoading] = useState(false);
  const handleClose = () => closeModal(false);

  const getData = useCallback(() => {
    setLoading(true);
    getRequest(`getTaxSystemDates?companyID=${currentCompanyID}`)
      .then((res) => {
        setHistoryTaxationSystemData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentCompanyID]);

  useEffect(() => {
    getData();
  }, [currentCompanyID]);

  if (loading) {
    return <Loading />;
  }
  return (
    <Modal
      customClass="L-history-taxation-system"
      closeHandler={handleClose}
      onDrag={true}
    >
      <div className="G-modal-block-title">
        <h3> Հարկման համակարգի փոփոխման պատմություն</h3>
      </div>
      <HistoryTable mainData={historyTaxationSystemData} />
    </Modal>
  );
};

export default HistoryTaxationSystem;
