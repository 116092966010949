import { deleteRequest, getRequest, postRequest } from '../../App/Api/Api';

export const fetchPaymentList = async (currentCompanyID) => {
  try {
    const response = await getRequest(
      `getPaymentList?companyID=${currentCompanyID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchExecutePaymentList = async (currentCompanyID, paymentNum) => {
  try {
    const response = await getRequest(
      `getSalaryPaymentList?companyID=${currentCompanyID}&number=${paymentNum}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchNewPaymentListForm = async (currentCompanyID, bankCode) => {
  if (!currentCompanyID || !bankCode) {
    return;
  }
  try {
    const response = await getRequest(
      `getDataForNewPaymentList?companyID=${currentCompanyID}&bankCode=${bankCode}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
export const fetchNewPaymentList = async (currentCompanyID, params = {}) => {
  try {
    let url = `getNewPaymentList?companyID=${currentCompanyID}`;

    const queryString = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    if (queryString) {
      url += `&${queryString}`;
    }

    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const savePaymentList = async (newPaymentList) => {
  try {
    const response = await postRequest('savePaymentList', newPaymentList);
    return response.data;
  } catch (error) {
    console.error('Error adding paymentList:', error);
    throw error;
  }
};

export const deletedPaymentList = async (companyID, paymentNum) => {
  try {
    const response = await deleteRequest(
      `deletePaymentList?companyID=${companyID}&number=${paymentNum} `,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting PaymentList:', error);
    throw error;
  }
};

export const fetchPaymentListNumber = async (currentCompanyID, bankCode) => {
  if (!currentCompanyID || !bankCode) {
    return;
  }
  try {
    const response = await getRequest(
      `getPaymentListNums?companyID=${currentCompanyID}&bankCode=${bankCode}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
