import React, { useEffect } from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../../App/Components/Alert';
import useMaterialValueGroups from '../../hooks/useMaterialValueGroups';

const EventRemove = ({ prodGroupID, handleClose }) => {
  const { deleteMaterialGroup, errorMess, setErrorMess } =
    useMaterialValueGroups();
  useEffect(() => {
    return () => {
      setErrorMess('');
    };
  }, []);

  const onRemoveHandler = async () => {
    try {
      await deleteMaterialGroup(prodGroupID, handleClose);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventRemove;
