import React, { useState } from 'react';
import Menu from '../Menu/Menu';
import RightPanel from '../RightPanel/RightPanel';
import './style.scss';
const MainComponent = ({ children }) => {
  const [sidebar, setSidebar] = useState(false);
  return (
    <div className="G-main-component">
      <Menu sidebar={sidebar} setSidebar={setSidebar} />
      <RightPanel children={children} sidebar={sidebar} />
    </div>
  );
};

export default MainComponent;
