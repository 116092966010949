export const getEInvoicingActions = (row) => {
  const baseOperations = [
    { name: 'Գործառնության կատարում', value: 'operation' },
    { name: 'Բովանդակություն', value: 'operationByRows' },
    { name: 'Վճարում / Մուտքագրում', value: 'payment' },
    { name: 'Գործառնություններ', value: 'operations' },
    { name: 'Ջնջել', value: 'delete' },
  ];

  const additionalOperations = [
    ...(row.isSigned === false ? [{ name: 'Ստորագրել', value: 'sign' }] : []),
    ...(row.recivedWrittenOff === 1
      ? [{ name: 'Պահեստ', value: 'warehouse' }]
      : []),
  ];

  return [...baseOperations, ...additionalOperations];
};
