import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRequest, putRequest } from '../../Api/Api';
import { editPaymentOrderAction } from '../../Redux/paymentOrderSlices/useAction';
import {
  editAgreeText,
  removeCommas,
  typOfString,
} from '../../Utilities/Utilities';
import PaymentOrderForm from './PaymentOrderForm';
import Alert from '../../Components/Alert';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';

const EventEditForm = ({ closeModal, rowData }) => {
  const [errorHandler, setErrorHandler] = useState('');
  const [isAllowEdit, setIsAllowEdit] = useState(false);
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  useEffect(() => {
    async function checkAllowEdit() {
      try {
        const url = `PreDeleteEditPaymentOrder?documentID=${rowData.documentID}`;
        const res = await getRequest(url);
        if (res.data.errorCode === 100) {
          setIsAllowEdit(true);
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    if (rowData.documentID) {
      checkAllowEdit();
    }
  }, [rowData.documentID]);

  const onSubmit = async (values) => {
    if (isAllowEdit) {
      return;
    }
    try {
      const res = await putRequest('editPaymentOrder', {
        ...values,
        docNum: typOfString(values.docNum),
        amount: removeCommas(values.amount),
        amountAMD: removeCommas(values.amountAMD),
        areAttached: typOfString(values.areAttached),
        taxNumber:
          values?.taxNumber?.length === 2 ? Number(values.taxNumber) : 0,
      });
      if (res.data.errorCode === 0) {
        dispatch(editPaymentOrderAction(values));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      } else {
        setErrorHandler('Անսպասելի սխալ տեղի ունեցավ.');
      }
    } catch (err) {
      console.error('Error submitting form:', err);
    }
  };

  return (
    <>
      <PaymentOrderForm
        closeModal={handleClose}
        initialData={rowData}
        onSubmit={onSubmit}
        setErrorHandler={setErrorHandler}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
      {isAllowEdit && (
        <AcceptOrCancelModal
          closeModal={closeModal}
          confirmClick={() => setIsAllowEdit(false)}
          modalContent={editAgreeText}
        />
      )}
    </>
  );
};

export default EventEditForm;
