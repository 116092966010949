import React from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../../App/Components/Alert';
import useWarehouseEntryOperations from '../../hooks/useWarehouseEntryOperations';

const RemoveEntryOperation = ({
  handleClose,
  matInputDocID,
  setEntryOperationData,
}) => {
  const { errorMess, setErrorMess, removeEntryOperation } =
    useWarehouseEntryOperations();

  const onRemoveHandler = async () => {
    try {
      await removeEntryOperation(matInputDocID, handleClose);
      if (!errorMess) {
        setEntryOperationData([]);
      }
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default RemoveEntryOperation;
