import React from 'react';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { deleteRequest } from '../../../Api/Api';
import { deleteAgreeText } from '../../../Utilities/Utilities';

const DeleteSpendingOperation = ({
  entryID,
  closeModal,
  setErrorHandler,
  generalData,
  setGeneralData,
  isClosed,
}) => {
  const handleClose = () => closeModal(false);
  const removeHandler = () => {
    deleteRequest(`deleteCashMoneyEntry/${entryID}`).then((res) => {
      if (res.data.errorCode === 0) {
        const newList = generalData.filter((item) => item.entryID !== entryID);
        setGeneralData(newList);
        handleClose();
      }
      if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={closeModal}
        confirmClick={removeHandler}
        modalContent={
          isClosed ? deleteAgreeText : 'Համոզվա՞ծ եք որ ուզում եք ջնջել այն'
        }
      />
    </>
  );
};

export default DeleteSpendingOperation;
