import React from 'react';
import PositionsForm from './PositionsForm';
import usePositionsForm from '../../hooks/usePositionsForm';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
const EventAddForm = ({ handleClose }) => {
  const { addPosition, errorMess, setErrorMess } = usePositionsForm();
  const isMounted = useIsMounted();
  const onAddHandler = async (values, resetForm) => {
    try {
      await addPosition(values, handleClose);

      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <PositionsForm onSubmit={onAddHandler} handleClose={handleClose} />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
