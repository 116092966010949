export const GET_PURCHASE_ACT_DATA = "GET_PURCHASE_ACT_DATA";
export const DELETE_PURCHASE_ACT = "DELETE_PURCHASE_ACT";
export const LOADING = "LOADING";
export const GET_PURCHASE_ADD_FORM_DATA = "GET_PURCHASE_ADD_FORM_DATA";
export const ADD_PURCHASE_PRODUCT = "ADD_PURCHASE_PRODUCT ";
export const EDIT_PURCHASE_PRODUCT = "EDIT_PURCHASE_PRODUCT";
export const DELETE_PURCHASE_PRODUCT = "DELETE_PURCHASE_PRODUCT";

export const EDIT_PURCHASE_DATA = "EDIT_PURCHASE_DATA";

export const CHECKED_ALL_TAX_SING = "CHECKED_ALL_TAX_SING";
export const CHECKED_ROW_TAX_SING = "CHECKED_ROW_TAX_SING";
export const EDIT_PURCHASE_TABLE_DATA = "EDIT_PURCHASE_TABLE_DATA";
export const UPDATE_PARTNER_PURCHASE_FORM = "UPDATE_PARTNER_PURCHASE_FORM";
export const UPDATE_PRODUCT_DATA = 'UPDATE_PRODUCT_DATA';
export const UPDATE_UNIT_OF_MEASURE = 'UPDATE_UNIT_OF_MEASURE';
export const UPDATE_INCOME_TAX = 'UPDATE_INCOME_TAX';


