import { useEffect, useState } from 'react';
import { getRequest } from '../../App/Api/Api';
import { useSelector } from 'react-redux';
import { dataTimeFormat } from '../../App/Utilities/Utilities';

const useSalaryCalc = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [salaryCalcData, setSalaryCalcData] = useState(null);
  const [employeesList, setEmployeesList] = useState([]);

  const calculateSalary = async ({ calcType, itSign, socialSign, amount }) => {
    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams({
        calcType: calcType,
        itSign: itSign,
        socialSign: socialSign,
        amount: amount,
      }).toString();

      const response = await getRequest(
        `getSalaryCalculationData?${queryParams}`,
      );
      setSalaryCalcData(response.data);
    } catch (err) {
      setError('Failed to fetch salary data');
    } finally {
      setLoading(false);
    }
  };
  const getEmployeesList = async (salaryDate) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getRequest(
        `getEmployeesList?companyID=${currentCompanyID}&salaryDate=${dataTimeFormat(
          salaryDate,
        )}`,
      );
      setEmployeesList(response.data);
    } catch (err) {
      setError('Failed to fetch EmployeesList data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setLoading(false);
    };
  }, []);

  return {
    calculateSalary,
    loading,
    error,
    salaryCalcData,
    getEmployeesList,
    employeesList,
  };
};

export default useSalaryCalc;
