import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Loading from '../../Components/Loading/Loading';
import {
  getOrganizationAction,
  loadingAction,
} from '../../Redux/organizationSlices/useAction';
import EventEditForm from './EventEditForm';
import './organization.scss';
import Header from '../../Components/Header/Header';

const GetOrganization = () => {
  const { currentCompanyID, settingSign } = useSelector((state) => state.auth);
  const isLoading = useSelector((state) => state.getOrganizationData.isLoading);
  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    if (currentCompanyID === 0) {
      return;
    }

    dispatch(loadingAction(true));
    await getRequest(`getCompany/${currentCompanyID}`)
      .then((res) => {
        dispatch(getOrganizationAction(res?.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  }, [currentCompanyID, dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="L-company-wrap">
      <Header headerBtn={false} pageTitle="Կազմակերպության տվյալները" />
      <div className="L-organization-wrapper">
        {settingSign && (
          <div className="L-organization-title">
            <h3>Կազմակերպությունը հաջողությամբ գրանցվեց</h3>
          </div>
        )}

        <div className="L-organization-form ">
          {useMemo(
            () => (isLoading ? <Loading /> : <EventEditForm />),
            [isLoading],
          )}
        </div>
      </div>
    </div>
  );
};

export default GetOrganization;
