import { useState, useEffect } from 'react';
import { fetchBankCode } from '../services/fetchBankByCode';
import { useDebounce } from '../../App/Hooks/useDebounce';

const useGetBankByCode = (bankCode) => {
  const debouncedBankCode = useDebounce(bankCode, 1500);
  const [bankByCode, setBankByCode] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const trimmedBankCode = debouncedBankCode.substring(0, 5);

        const response = await fetchBankCode(trimmedBankCode);
        if (response && response.bankName) {
          setBankByCode(response.bankName);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      setBankByCode(null);
    };
  }, [debouncedBankCode]);

  return { bankByCode };
};

export default useGetBankByCode;
