import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewUnitsMeasure,
  fetchMaterialUnitsMeasure,
  removeUnitsMeasure,
  updateUnitsMeasure,
} from '../services/fetchMaterialUnitsMeasure';
import {
  deleteUnitOfMeasureAction,
  editUnitOfMeasureAction,
  getUnitOfMeasureAction,
  loadingAction,
} from '../../App/Redux/unitOfMeasureSlices/useAction';

const useUnitsMeasure = () => {
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState('');
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { unitOfMeasureData, isLoading } = useSelector(
    (state) => state.getUnitOfMeasureData,
  );
  const dispatch = useDispatch();

  const getUnitsMeasure = async (query) => {
    dispatch(loadingAction(true));
    try {
      const data = await fetchMaterialUnitsMeasure(currentCompanyID, query);
      dispatch(getUnitOfMeasureAction(data));
    } catch (error) {
      setError(error);
    } finally {
      dispatch(loadingAction(false));
    }
  };

  const addUnitsMeasure = async (newUnits, closeFormCallback, param) => {
    try {
      const { errorCode, message } = await addNewUnitsMeasure(newUnits);
      if (errorCode === 0) {
        getUnitsMeasure(param);
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateCurrUnitsMeasure = async (updateUnits, closeFormCallback) => {
    try {
      const { errorCode, message } = await updateUnitsMeasure(updateUnits);
      if (message) {
        setErrorMess(message);
      } else if (errorCode === 0) {
        dispatch(editUnitOfMeasureAction({ ...updateUnits }));
        closeFormCallback();
      }
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  const removeCurrUnitsMeasure = async (unitID, closeFormCallback) => {
    try {
      const { errorCode, message } = await removeUnitsMeasure(unitID);

      if (errorCode === 0) {
        dispatch(deleteUnitOfMeasureAction(unitID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
    };
  }, []);

  return {
    error,
    isLoading,
    errorMess,
    setErrorMess,
    getUnitsMeasure,
    addUnitsMeasure,
    updateCurrUnitsMeasure,
    removeCurrUnitsMeasure,
    unitOfMeasureData,
  };
};

export default useUnitsMeasure;
