import React, { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import Action from "../../Components/Table/Action";
import Table from "../../Components/Table/Table";
import { editDeleteList } from "../../OptionsList/OptionsList";
import { numbering, tableActionTitle } from '../../Utilities/Utilities';
import DeleteProduct from './DeleteProduct';
import EventEditForm from './EventEditForm';

const ProductTable = ({ mainData, loading }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handelChange = (value, row) => {
    switch (value) {
      case 'edit':
        return openEditForm(row);
      case 'delete':
        return openDeleteForm(row?.productNameID);
      default:
        return null;
    }
  };

  const columnConfig = [
    {
      title: numbering,
      cell: (row) => row.sequenceNumber,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Ապրանքի անվանումը',
      cell: (row) => row.productName,
      customStyle: { maxWidth: 1250, overflow: 'hidden' },
    },

    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={handelChange}
          actionItem={editDeleteList}
          modalIsOpen={openEditModal || openDeleteModal}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];

  const openEditForm = (rowValue) => {
    setOpenEditModal(rowValue);
  };

  const openDeleteForm = (deleteRowId) => {
    setOpenDeleteModal(deleteRowId);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-product-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {openEditModal && (
        <EventEditForm rowData={openEditModal} closeModal={setOpenEditModal} />
      )}
      {openDeleteModal && (
        <DeleteProduct
          deleteRowId={openDeleteModal}
          closeModal={setOpenDeleteModal}
        />
      )}
    </>
  );
};

export default ProductTable;
