import * as Yup from "yup";
import { FillAmount, SelectFieldMessage } from './ErrorMessage';

export const OperationsValidation = () =>
  Yup.object().shape({
    amount: Yup.number()
      .required(FillAmount)
      .nullable()
      .test('ոչ զրոյական', 'Գումարը չպետք է լինի 0', (value) => value !== 0),
    currencyCode: Yup.string().required(SelectFieldMessage).nullable(),
    firstAccount: Yup.string().required(SelectFieldMessage).nullable(),
    credCurrCode: Yup.string().required(SelectFieldMessage).nullable(),
    secondAccount: Yup.string().required(SelectFieldMessage).nullable(),
  });
