import { createAction } from '../actionCreators';
import {
  GET_CALENDAR_DATA,
  EDIT_EVENT,
  DELETE_EVENT,
  LOADING,
  UPDATE_CALENDAR_TYPE,
  UPDATE_EVENT_DROP,
  GET_DAY_NOTFICATION,
  IS_NOT_LOADING,
  UDPADE_DAY_NOTFICATION,
  ADD_DAY_NOTIFICATION,
  REMOVE_DAY_NOTIFICATION,
} from './constants';

export const getCalendarAction = createAction(GET_CALENDAR_DATA);
export const loadingAction = createAction(LOADING, false);
export const editCalendarAction = createAction(EDIT_EVENT);
export const deleteCalendarAction = createAction(DELETE_EVENT);
export const updateCalendarTyeAction = createAction(UPDATE_CALENDAR_TYPE);
export const updateEventDropAction = createAction(UPDATE_EVENT_DROP);

export const getDayNoteAction = createAction(GET_DAY_NOTFICATION);
export const notLoadingAction = createAction(IS_NOT_LOADING);
export const addDayNoteAction = createAction(ADD_DAY_NOTIFICATION);
export const updateDayNoteAction = createAction(UDPADE_DAY_NOTFICATION);
export const removeDayNoteAction = createAction(REMOVE_DAY_NOTIFICATION);