import React from 'react';
import './button.scss';

const Button = ({
  text = '',
  customClass = '',
  onClick,
  disabled,
  type = 'submit',
}) => {
  return (
    <div className={`button-wrap ${customClass}`}>
      <button onClick={onClick} type={type} disabled={disabled}>
        <p>{text}</p>
      </button>
    </div>
  );
};

export default Button;
