import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { postRequest } from '../../Api/Api';
import CompanyBankForm from './CompanyBankForm';

const EventAddForm = ({ closeModal, getData, pageNumber, setErrorHandler }) => {
  const handleClose = useCallback(() => closeModal(false), [closeModal]);
  const { currentCompanyID } = useSelector((state) => state.auth);

  const initialValues = useMemo(
    () => ({
      companyID: currentCompanyID,
      bankCode: '',
      bankingAccount: '',
    }),
    [currentCompanyID],
  );

  const onSubmit = useCallback(
    (values, resetForm) => {
      postRequest('addCompanyBank', values)
        .then((res) => {
          const { errorCode, message } = res.data;
          if (errorCode === 0) {
            getData(pageNumber);
            handleClose();
            resetForm();
          } else if (errorCode > 0) {
            setErrorHandler(message);
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    },
    [getData, handleClose, pageNumber, setErrorHandler],
  );

  return (
    <>
      <CompanyBankForm
        closeModal={handleClose}
        initialData={initialValues}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default EventAddForm;
