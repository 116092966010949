import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { putRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import { editUnitOfMeasureAction } from '../../Redux/unitOfMeasureSlices/useAction';
import UnitOfMeasureForm from './UnitOfMeasureForm';

const EventEditForm = ({ rowData, closeModal }) => {
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    putRequest('editunit', values)
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(editUnitOfMeasureAction(values));
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <UnitOfMeasureForm
        onSubmit={onSubmit}
        initialData={rowData}
        closeModal={handleClose}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventEditForm;
