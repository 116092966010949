import { deleteRequest, getRequest, postRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';
export const fetchCalculationPrint = async (companyID, empNo) => {
  try {
    const response = await getRequest(
      `getEmplPrintData?companyID=${companyID}&empNo=${empNo}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchCalculationSheet = async (companyID, empNo) => {
  try {
    const response = await getRequest(
      `getEmplData?companyID=${companyID}&empNo=${empNo}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchSalaryCalculator = async (companyID, empNo, salaryMonth) => {
  try {
    const response = await postRequest(
      `salaryCalc?companyID=${companyID}&empNo=${empNo}&displaySign=${true}&salaryMonth=${salaryMonth}`,
      [],
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchSalaryCalculatorDate = async (companyID, query) => {
  const queryRoot = urlEncodeQueryParams(query);
  try {
    const response = await getRequest(
      `getEmployeeData?companyID=${companyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const deleteSalaryCalculator = async (salaryEntryID) => {
  try {
    const response = await deleteRequest(`deleteSalaryCalc/${salaryEntryID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting deleteSalaryCalculator:', error);
    throw error;
  }
};
