export const ConvertMaterialGroupExcel = (data) => {
  return (
    data &&
    data?.map(({ groupNo, groupName, accCode }) => {
      return {
        Կոդը: groupNo,
        Անվանումը: groupName,
        Հաշիվ: accCode,
      };
    })
  );
};
export const ConvertWarehouseExcel = (data) => {
  return (
    data &&
    data?.map(({ storeNo, storeName, person }) => {
      return {
        Կոդը: storeNo,
        Անվանումը: storeName,
        'Պատասխանատու անձ': person,
      };
    })
  );
};
