import { createAction } from '../../actionCreators';
import { FETCH_WORK_WITH_EMPLOYEES, UPDATE_EMPLOYEE_SALARY } from './constants';

export const fetchWorkWithEmployeesAction = createAction(
  FETCH_WORK_WITH_EMPLOYEES,
);

export const updateWithEmployeesSalaryAction = createAction(
  UPDATE_EMPLOYEE_SALARY,
);
