import React, { useEffect, useState } from 'react';
import PositionsTable from './PositionsTable';
import EventAddForm from './EventAddForm';
import Header from '../../../App/Components/Header/Header';
import './index.scss';
import usePositionsForm from '../../hooks/usePositionsForm';
import { useDebounce } from '../../../App/Hooks/useDebounce';
import SearchPositionForm from './SearchPositionForm';
import { ConvertPositionExcel } from '../../Config/excelConfig';

const Positions = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const { positions, fetchPositionsData, isLoading } = usePositionsForm();
  const [query, setQuery] = useState('');
  const debouncedSearchTerm = useDebounce(query, 1500);

  useEffect(() => {
    fetchPositionsData(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };

  const dataToExcel = ConvertPositionExcel(positions?.positions);

  return (
    <div className="G-small-wrap">
      <Header pageTitle="Պաշտոններ" handelClick={onClose} />
      <SearchPositionForm
        query={query}
        setQuery={setQuery}
        dataToExcel={dataToExcel}
      />
      <PositionsTable mainData={positions?.positions} loading={isLoading} />
      {showAddForm && <EventAddForm handleClose={onClose} />}
    </div>
  );
};

export default Positions;
