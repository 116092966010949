export const optionType = [
  {
    typeName: 'Օրվա նշումներ',
    typeColor: 'green',
  },
  {
    typeName: 'Շաբաթվա հիշեցումներ',
    typeColor: 'gray',
  },
  {
    typeName: 'Ամսական հիշեցումներ',
    typeColor: 'red',
  },
  {
    typeName: 'Ամսական հիշեցումներ աշխ.օրում',
    typeColor: 'skyBlue',
  },
  {
    typeName: 'Տարեկան հիշեցումներ',
    typeColor: 'blue',
  },
];

export const colorList = [
  {
    colorName: 'green',
    hex: '008000',
  },
  {
    colorName: 'gray',
    hex: '808080',
  },
  {
    colorName: 'red',
    hex: 'FF0000',
  },
  {
    colorName: 'skyBlue',
    hex: '87CEEB',
  },
  {
    colorName: 'blue',
    hex: '0000FF',
  },
];




export const dayList = Array.from({ length: 10 }, (_, i) => ({
  label: `${i + 1}`,
  id: i + 1,
}));