import { deleteRequest, getRequest, postRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';

export const fetchAssignPositions = async (companyID) => {
  try {
    const response = await getRequest(`getAssignSalary?companyID=${companyID}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchExecuteAssignPositions = async (companyID, query) => {
  const queryRoot = urlEncodeQueryParams(query);
  try {
    const response = await getRequest(
      `getAssignSalaryEmployees?companyID=${companyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addAssignPosition = async (assignSalaries) => {
  try {
    const response = await postRequest('saveAssignSalaries ', assignSalaries);

    return response.data;
  } catch (error) {
    console.error('Error adding saveAssignSalaries:', error);
    throw error;
  }
};

export const deleteAssignPosition = async (companyID, query) => {
  const queryRoot = urlEncodeQueryParams(query);
  try {
    const response = await deleteRequest(
      `deleteAssignSalaryEmployees?companyID=${companyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting delete Assign Position:', error);
    throw error;
  }
};
