import { useEffect, useState, useCallback } from 'react';

import { getRequest } from '../Api/Api';
import { dataTimeFormat } from '../Utilities/Utilities';

const useDayCalendar = (userID) => {
  const [notificationData, setNotificationData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchDayCalendar = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getRequest(
        `getDayCalendar?userId=${userID}&date=${dataTimeFormat(new Date())}`,
      );
      if (response.status === 200) {
        setNotificationData(response.data);
      } else {
        setError(new Error(response.statusText || 'Failed to fetch'));
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [userID]);

  useEffect(() => {
    fetchDayCalendar();
  }, [fetchDayCalendar]);

  return { notificationData, error, loading };
};

export default useDayCalendar;
