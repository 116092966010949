import React from "react";
import "./index.scss";

const InitialFinalBalanceTitle = ({ showTitle = true }) => {
  return (
    <div className="G-initial-final-balance G-flex-align-end G-flex-justify-end">
      {showTitle ? (
        <>
          <strong>Սկզբնական մնացորդ</strong>
          <strong>Շրջանառություն</strong>
          <strong>Վերջնական մնացորդ</strong>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default InitialFinalBalanceTitle;
