import React, { useMemo } from 'react';
import Table from '../../../Components/Table/Table';
import { Commas } from '../../../Utilities/Utilities';

const CashRegisters = ({ data }) => {
  const cashboxData = data !== null ? data?.remainderCashbox : [];
  const columnConfig = useMemo(
    () => [
      {
        title: 'Հաշիվ',
        cell: (row) => row.accountCode,
        customStyle: { maxWidth: 80 },
      },

      {
        title: 'Հաշվի անվանումը',
        cell: (row) => row.accountName,
        customStyle: { maxWidth: 650 },
      },

      {
        title: 'Արժույթ',
        cell: (row) => row.currencyName,
        customStyle: { maxWidth: 60, justifyContent: 'center' },
      },
      {
        title: 'Օրվա մնացորդը',
        cell: (row) => Commas(row.amountDebit),
        customStyle: { maxWidth: 120 },
      },
    ],
    [],
  );

  return (
    <>
      {cashboxData && (
        <Table
          customClass="L-cash-registers-table L-dashboard-tables"
          data={cashboxData}
          columnConfig={columnConfig}
        />
      )}
    </>
  );
};

export default CashRegisters;
