import { deleteRequest, getRequest, postRequest } from '../../App/Api/Api';

export const fetchEntryOrderOperations = async (
  currentCompanyID,
  matInputDocID,
) => {
  try {
    const response = await getRequest(
      `GetMatInputEntries?companyID=${currentCompanyID}&matInputDocID=${matInputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const removeEntryOrderOperation = async (matInputDocID) => {
  try {
    const response = await deleteRequest(
      `deleteMatInputEntries?matInputDocID=${matInputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting matInputDocID:', error);
    throw error;
  }
};
// create service for entryOrderOperationExecution

export const fetchEntryOperationExecution = async (
  currentCompanyID,
  matInputDocID,
) => {
  try {
    const response = await getRequest(
      `getMatInputForEntry?companyID=${currentCompanyID}&matInputDocID=${matInputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
export const addEntryOpExecution = async (newEnter) => {
  try {
    const response = await postRequest('saveMatInputEntries', newEnter);
    return response.data;
  } catch (error) {
    console.error('Error adding newEnter:', error);
    throw error;
  }
};
