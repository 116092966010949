import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPositions,
  deletePositions,
  fetchPositionForm,
  fetchPositions,
  updatePositions,
} from '../services/fetchPositions';
import {
  addPositionsFormAction,
  deletePositionsAction,
  fetchPositionsAction,
  updatePositionsAction,
} from '../../App/Redux/SalaryStor/positionsSlices/useAction';

const usePositionsForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState('');
  const { currentCompanyID } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const positions = useSelector((state) => state.positionsData?.positions);
  const positionsFormData = useSelector(
    (state) => state.positionsData?.positionsForm,
  );
  const fetchPositionsData = async (query) => {
    setIsLoading(true);
    try {
      const data = await fetchPositions(currentCompanyID, query);
      dispatch(fetchPositionsAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addPosition = async (positionsData, closeFormCallback, query) => {
    try {
      const { errorCode, message } = await addPositions(positionsData);
      if (errorCode === 0) {
        fetchPositionsData(query);
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const updatePosition = async (positionsData, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await updatePositions(positionsData);
      if (message) {
        setErrorMess(message);
      } else if (errorCode === 0) {
        dispatch(updatePositionsAction(positionsData));
        closeFormCallback();
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removePositions = async (positionID, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await deletePositions(positionID);

      if (errorCode === 0) {
        dispatch(deletePositionsAction(positionID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPositionFormData = async (positionID) => {
    setIsLoading(true);
    try {
      const data = await fetchPositionForm(currentCompanyID, positionID);
      dispatch(addPositionsFormAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);
  return {
    isLoading,
    fetchPositionsData,
    addPosition,
    updatePosition,
    removePositions,
    fetchPositionFormData,
    positions,
    positionsFormData,
    errorMess,
    setErrorMess,
  };
};

export default usePositionsForm;
