import { getRequest, postRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';
export const fetchMakeAnAddition = async (companyID) => {
  try {
    const response = await getRequest(`getMakeSalary?companyID=${companyID}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const executeMakeAnAddition = async (companyID, query) => {
  const queryRoot = urlEncodeQueryParams(query);
  try {
    const response = await getRequest(
      `getEmployeesForMakeSalary?companyID=${companyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const saveMakeAnAddition = async (data) => {
  try {
    const response = await postRequest('saveMakeSalaryEntries ', data);

    return response.data;
  } catch (error) {
    console.error('Error adding saveMakeAnAddition:', error);
    throw error;
  }
};

export const fetchSalaryTypeCoefficient = async (companyID, param) => {
  const { stNo, formulaID } = param || {};
  if (!stNo || !formulaID) {
    return;
  }
  try {
    const response = await getRequest(
      `getSalaryTypeCoeff?companyID=${companyID}&stNo=${stNo}&formulaID=${formulaID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
