import React from 'react';
import { Commas, dateFormat } from '../../Utilities/Utilities';
import Modal from '../Modal/Modal';
import './style.scss';
import Button from '../Button/Button';

const AspectAllEnters = ({ aspectAllEnters, setShowModal, deleteItem }) => {
  return (
    <Modal
      closeModal={setShowModal}
      customClass="aspect-all-enters-modal scrolling-table has-scroll"
    >
      <div className="aspect-all-enters-content">
        <table className="my-table">
          <thead className="table-thead">
            <tr>
              <th>Ամսաթիվ</th>
              <th>Գործառնություն</th>
              <th>Արժույթ</th>
              <th>Գումար</th>
              <th>Նշում</th>
            </tr>
          </thead>
          <tbody className="table-tbody">
            {aspectAllEnters?.map((en) => (
              <tr key={en.id} className="tr-size">
                <td>{dateFormat(en.crAt)}</td>
                <td>{en.accountName}</td>
                <td>{en.curr}</td>

                {en.accountName === 'Եկամուտ' ? (
                  <td style={{ color: 'green', textAlign: 'right' }}>
                    {Commas(en.amount)}
                  </td>
                ) : (
                  <td style={{ color: 'red', textAlign: 'right' }}>
                    {Commas(en.amount)}
                  </td>
                )}

                <td
                  title={en.comment}
                  style={{ maxWidth: '200px', overflow: 'hidden' }}
                >
                  {en.comment}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="accept-or-cancel-content">
          <p>
            Ընտրված տիպը հեռացնելու դեպքում կհեռացվեն նրան կցված
            ծախս-եկամուտները
          </p>
          <div className="accept-or-cancel-btn-block">
            <Button onClick={deleteItem} text="Այո" />

            <Button onClick={() => setShowModal(false)} text="Ոչ" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AspectAllEnters;
