import React, { useState } from 'react';
import { useEffect } from 'react';
import Modal from '../../../Components/Modal/Modal';
import Table from '../../../Components/Table/Table';
import { useFetch } from '../../../Hooks/useFetch';
import { dateFormat } from '../../../Utilities/Utilities';
import DeleteSpendingOperation from './DeleteSpendingOperation';

const GetSpendingOperations = ({ closeModal, rowData, setErrorHandler }) => {
  const { cashMoneyID, isClosed } = rowData || {};

  const handleClose = () => closeModal(false);
  const [data] = useFetch(`getCashMoneyEntries?purchaseID=${cashMoneyID} `);
  const [generalData, setGeneralData] = useState(data);
  const [deleteSpendingOperation, setDeleteSpendingOperation] = useState(data);
  useEffect(() => {
    setGeneralData(data);
  }, [data]);

  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 80 },
    },

    {
      title: 'Փաստաթուղթ N',
      cell: (row) => row.docNum,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 650 },
    },
    {
      title: 'Դեբետ',
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Գումար',
      cell: (row) => row.amount,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Գործողություն',
      cell: (row) => (
        <span
          className="G-delete-span"
          onClick={() => setDeleteSpendingOperation(row.entryID)}
        >
          Ջնջել
        </span>
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  return (
    <>
      <Modal
        closeHandler={handleClose}
        customClass="L-purchase-act-operations-modal"
        onDrag={true}
      >
        <div className="L-purchase-act-operations-content">
          <div className="G-modal-block-title">
            <h3>Առհաշիվ գումարների ծախսման գործառնություններ</h3>
          </div>

          {generalData !== null && generalData && generalData?.length ? (
            <Table
              customClass="L-purchase-act-table"
              data={generalData}
              columnConfig={columnConfig}
            />
          ) : (
            ''
          )}
        </div>
      </Modal>

      {deleteSpendingOperation && (
        <DeleteSpendingOperation
          entryID={deleteSpendingOperation}
          closeModal={setDeleteSpendingOperation}
          generalData={generalData}
          setGeneralData={setGeneralData}
          setErrorHandler={setErrorHandler}
          isClosed={isClosed}
        />
      )}
    </>
  );
};

export default GetSpendingOperations;
