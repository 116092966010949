import React from 'react';
import Modal from '../../Components/Modal/Modal';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import { getRequest } from '../../Api/Api';
import './forgotPassword.scss';

const ForgotPassword = ({ closeModal, showError, setFieldValue }) => {
  const currentEmail = localStorage.getItem('email');
  const cancelHandler = () => closeModal(false);
  const confirmHandler = () => {
    getRequest(`forgotPassword?email=${currentEmail}`)
      .then((res) => {
        if (res.data.errorCode === 0) {
          cancelHandler();
          setFieldValue('password', '');
        } else if (res.data.message) {
          showError(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };
  return (
    <Modal customClass="L-forgot-password-modal" closeHandler={cancelHandler}>
      <div className="L-forgot-password-modal-content">
        <h3>
          Հաստատելու դեպքում Ձեր {currentEmail} էլեկտրոնային հասցեին կուղարկվի
          նոր գաղտնաբառը:
        </h3>

        <GetSentDataBtn
          cancelPrintClick={cancelHandler}
          confirmExecuteClick={confirmHandler}
        />
      </div>
    </Modal>
  );
};

export default ForgotPassword;
