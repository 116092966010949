import React, { useCallback, useMemo, useState } from 'react';
import Loading from '../../Components/Loading/Loading';
import Action from '../../Components/Table/Action';
import Table from '../../Components/Table/Table';
import { editDeleteList } from '../../OptionsList/OptionsList';
import { checkBeforeDelete, tableActionTitle } from '../../Utilities/Utilities';
import DeleteCompanyBank from './DeleteCompanyBank';
import EventEditForm from './EventEditForm';

const CompanyBankTable = ({ mainData, loading, setErrorHandler }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const openEditForm = useCallback((rowValue) => {
    setOpenEditModal(rowValue);
  }, []);

  const openDeleteForm = useCallback(
    (companyBankID) => {
      checkBeforeDelete(`letDeleteCompanyBank?companyBankID=${companyBankID}`)
        .then((res) => {
          const { errorCode, message } = res || {};
          if (errorCode === 0) {
            setOpenDeleteModal(companyBankID);
          } else if (errorCode > 0) {
            setErrorHandler(message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [setErrorHandler],
  );
  const handelChange = useCallback(
    (value, row) => {
      switch (value) {
        case 'edit':
          return openEditForm(row);
        case 'delete':
          return openDeleteForm(row?.companyBankID);
        default:
          return null;
      }
    },
    [openEditForm, openDeleteForm],
  );

  const columnConfig = useMemo(
    () => [
      {
        title: 'Բանկի կոդը',
        cell: (row) => row.bankCode,
        customStyle: { maxWidth: 100 },
      },
      {
        title: 'Բանկի անվանումը',
        cell: (row) => row.bankNameOnly,
        customStyle: { maxWidth: 1250, overflow: 'hidden' },
      },
      {
        title: 'Հ-հաշիվը',
        cell: (row) => row.bankingAccount,
        customStyle: { maxWidth: 220 },
      },
      {
        title: 'Նշումներ',
        cell: (row) => row.comment,
        customStyle: { maxWidth: 450, overflow: 'hidden' },
      },
      {
        title: tableActionTitle,
        cell: (row) => (
          <Action
            row={row}
            handelChange={handelChange}
            actionItem={editDeleteList}
            modalIsOpen={openDeleteModal || openEditModal}
          />
        ),
        customStyle: { maxWidth: 150 },
      },
    ],
    [openDeleteModal, openEditModal],
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-organization-bank-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {openDeleteModal && (
        <DeleteCompanyBank
          deleteRowId={openDeleteModal}
          closeModal={setOpenDeleteModal}
          setErrorHandler={setErrorHandler}
        />
      )}

      {openEditModal && (
        <EventEditForm
          closeModal={setOpenEditModal}
          rowData={openEditModal}
          setErrorHandler={setErrorHandler}
        />
      )}
    </>
  );
};

export default CompanyBankTable;
