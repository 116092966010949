import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest, getRequest } from '../../../Api/Api';
import { deleteCashWarrantAction } from '../../../Redux/cashWarrantSlices/useAction';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import {
  StandardDocumentsDeleteMessage,
  deleteAgreeText,
} from '../../../Utilities/Utilities';

const DeleteCashWarrant = ({ deleteRowId, closeModal, setErrorHandler }) => {
  const [isAllowDelete, setIsAllowDelete] = useState(false);
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAllowDelete() {
      try {
        if (deleteRowId) {
          const url = `PreDeleteEditCashRegister?documentID=${deleteRowId}`;
          const res = await getRequest(url);
          setIsAllowDelete(res.data.errorCode === 100);
        }
      } catch (err) {
        console.error('Error checking allow delete:', err);
      }
    }
    checkAllowDelete();
  }, [deleteRowId]);

  const deleteRowDate = () => {
    deleteRequest(`deleteCashRegister/${deleteRowId}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deleteCashWarrantAction(deleteRowId));
        handleClose();
      }
    });
  };

  return (
    <AcceptOrCancelModal
      closeModal={closeModal}
      confirmClick={deleteRowDate}
      modalContent={
        isAllowDelete ? deleteAgreeText : StandardDocumentsDeleteMessage
      }
    />
  );
};

export default DeleteCashWarrant;
