import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import Pagination from '../../Components/Pagination/Pagination';
import CalendarTime from '../../Components/Calendar/CalendarTime';
import {
  correctDate,
  dataTimeFormat,
  inCorrectDateMess,
  initialDate,
} from '../../Utilities/Utilities';
import SelectPartner from '../../Components/SelectBox/SelectPartner';
import {
  getSpendingMoneyAction,
  getSpendingMoneyDataAction,
  loadingAction,
  updatePartnerAction,
} from '../../Redux/accountForSpendingMoneySlices/useAction';
import AccountSpendingMoneyTable from './AccountSpendingMoneyTable';
import AddSendingMoneyWrapper from './AddSendingMoneyWrapper';
import './spendingMoney.scss';
import AddNew from '../../Elements/Add/AddNew';
import PartnerForm from '../Partners/EventAddForm';
import Alert from '../../Components/Alert';

const GetAccountForSpendingMoney = () => {
  const { currentCompanyID, reportStartDate } = useSelector(
    (state) => state.auth,
  );
  const { spendingMoneyData, isLoading } = useSelector(
    (state) => state.getSpendingMoneyData,
  );
  const { currentId } = useSelector((state) => state.filteredId);
  const dispatch = useDispatch();
  const [dateStart, setDateStart] = useState(new Date(reportStartDate));
  const [errorHandler, setErrorHandler] = useState('');
  const [dateEnd, setDateEnd] = useState(initialDate());
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
  const [pageNumber, setPageNumber] = useState(0);
  const [showPartnerForm, setShowPartnerForm] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState({});
  const [partnerVal, setPartnerVal] = useState({
    label: '',
    value: '',
    id: '',
  });
  const dateUrl = `dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}&documentID=${currentId}`;

  const [openSpendingMoneyForm, setOpenSpendingMoneyForm] = useState(false);

  const getData = (pageNumber) => {
    dispatch(loadingAction(true));
    getRequest(
      `getCashMoneys?companyID=${currentCompanyID}&pageNumber=${
        pageNumber + 1
      }&${dateUrl}`,
    )
      .then((res) => {
        dispatch(getSpendingMoneyAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, dispatch, dateUrl]);

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      setErrorHandler(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };

  const selectChange = (value, id) => {
    if (!value) {
      value = {
        value: '',
      };
    }
    if (id === 'partner') {
      setPartnerVal(value);
    }
  };
  const openPartnerAddForm = () => {
    setShowPartnerForm(true);
  };

  const getAccountMoneyExpensesAddData = () => {
    if (partnerVal.id || partnerInfo.partnerID) {
      getRequest(
        `getExpenses?partnerID=${partnerVal.id || partnerInfo.partnerID}`,
      )
        .then((res) => {
          if (res.data.message === null && res.data) {
            dispatch(getSpendingMoneyDataAction(res.data));
            setOpenSpendingMoneyForm(true);
          }
          if (res.data.message !== null) {
            setOpenSpendingMoneyForm(false);
            setErrorHandler(res.data.message);
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }

    if (!showPartnerForm) {
      setPartnerVal({
        value: '',
      });
    } else {
      setOpenSpendingMoneyForm(false);
    }
  };

  useEffect(() => {
    if (partnerVal.id || partnerInfo.partnerID) {
      getAccountMoneyExpensesAddData();
    } else {
      return [];
    }
  }, [partnerVal.id, partnerInfo.partnerID]);

  return (
    <>
      <Header headerBtn={false} pageTitle="Առհաշիվ գումարների ծախսման մասին" />
      <SearchBackground>
        <div className="G-time-partner-flex G-flex">
          <CalendarTime
            dateStart={dateStart}
            setDateStart={setDateStart}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
            onChangeHandler={timeChangeHandler}
          />
          <SelectPartner
            title="Հաշվետու անձ"
            partnerData={spendingMoneyData?.partners}
            partnerVal={partnerVal}
            selectChangePartner={selectChange}
            requiredFiled={true}
          />

          <AddNew onClick={openPartnerAddForm} />
        </div>
      </SearchBackground>
      <AccountSpendingMoneyTable
        mainData={spendingMoneyData?.cashMoneys}
        loading={isLoading}
      />
      <Pagination
        pageCount={spendingMoneyData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
      {openSpendingMoneyForm && (
        <AddSendingMoneyWrapper
          closeModal={setOpenSpendingMoneyForm}
          getData={getData}
          pageNumber={pageNumber}
          setErrorHandler={setErrorHandler}
        />
      )}

      {showPartnerForm && (
        <PartnerForm
          closeModal={setShowPartnerForm}
          updateUserOutside={true}
          updatePartnerAction={updatePartnerAction}
          setPartnerInfo={setPartnerInfo}
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default GetAccountForSpendingMoney;
