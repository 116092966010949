import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';

export const fetchMaterialValuesNames = async (currentCompanyID, params) => {
  const updatedParams = {
    ...params,
    pageNumber: params.pageNumber + 1,
  };
  const queryRoot = urlEncodeQueryParams(updatedParams);
  try {
    const response = await getRequest(
      `getMatValues?companyID=${currentCompanyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchMaterialNameForm = async (currentCompanyID, matValueID) => {
  try {
    const response = await getRequest(
      `getMatValue?companyID=${currentCompanyID}&matValueID=${matValueID} `,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
export const addNewMaterialName = async (newMathValue) => {
  try {
    const response = await postRequest('addMatValue', newMathValue);
    return response.data;
  } catch (error) {
    console.error('Error adding MaterialName:', error);
    throw error;
  }
};
export const updateCurrentMaterialName = async (updateMaterialName) => {
  try {
    const response = await putRequest('editMatValue', updateMaterialName);
    return response.data;
  } catch (error) {
    console.error('Error adding MaterialName:', error);
    throw error;
  }
};

export const removeCurrentMaterialName = async (matValueID) => {
  try {
    const response = await deleteRequest(`deleteMatValue/${matValueID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting MaterialName:', error);
    throw error;
  }
};

export const fetchMaterialNameExcelData = async (currentCompanyID, params) => {
  const queryRoot = urlEncodeQueryParams(params);
  let url = `getMatValuesForExcel?companyID=${currentCompanyID}&${queryRoot}`;
  try {
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchMaterialNameAccount = async (currentCompanyID, groupNo) => {
  try {
    const response = await getRequest(
      `getMatValueGroupAcc?companyID=${currentCompanyID}&groupNo=${groupNo} `,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
