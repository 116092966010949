import React, { useEffect, useState } from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import SearchBar from '../../components/searchBar/SearchBar';
import { ExportExcel } from '../../../App/Components/Excelexport/Excelexport';
import { useDebounce } from '../../../App/Hooks/useDebounce';

const MaterialGroupsFilter = ({ param, setParam, dataToExcel }) => {
  const [searchString, setSearchString] = useState(param);
  const debouncedSearchTerm = useDebounce(searchString, 1500);

  useEffect(() => {
    setParam(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const onInputChange = (searchString) => {
    setSearchString(searchString);
  };

  return (
    <SearchBackground>
      <div className="G-search-form-flex">
        <SearchBar query={searchString} setQuery={onInputChange} />
        <ExportExcel
          excelData={dataToExcel}
          fileName="Նյութական արժեքների խմբեր"
        />
      </div>
    </SearchBackground>
  );
};

export default MaterialGroupsFilter;
