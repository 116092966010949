import { removeCommas } from '../../../App/Utilities/Utilities';

export const updateMakeAnAdditionData = (data, row, field, value) => {
  const updatedRows = data.map((r) => {
    if (r.empNo === row.empNo) {
      let newAmount = 0;

      if (field === 'hours') {
        newAmount = calculateAmount({ ...r, hours: value });
        return { ...r, hours: value, amount: newAmount, isModifed: true };
      }

      if (field === 'coeff') {
        newAmount = calculateAmount({ ...r, coeff: value });
        return { ...r, coeff: value, amount: newAmount, isModifed: true };
      }

      if (field === 'amount') {
        return {
          ...r,
          amount: removeCommas(value),
          sign: true,
          isModifed: true,
        };
      }
      return r;
    }
    return r;
  });
  return updatedRows;
};

export const calculateAmount = (row) => {
  let amount = 0;
  switch (row.formulaID) {
    case 1:
      amount =
        row.formSign === 2
          ? row.salary * row.workHours * row.coeff
          : row.salary * row.coeff;
      break;
    case 2:
      amount =
        row.formSign === 2
          ? Math.round(row.salary * row.hours * row.coeff, 2)
          : row.workHours > 0
          ? Math.round((row.salary / row.workHours) * row.hours * row.coeff, 2)
          : 0;
      break;
    case 3:
      amount = row.hgAmount * row.coeff;
      break;
    case 4:
      amount =
        row.formSign === 2
          ? Math.round(row.hgAmount * row.hours * row.coeff, 2)
          : row.workHours > 0
          ? Math.round(
              (row.hgAmount / row.workHours) * row.hours * row.coeff,
              2,
            )
          : 0;
      break;
    default:
      break;
  }
  return amount;
};
