import React from 'react';
import './style.scss';
import MenuBar from '../menuBar/MenuBar';
import RenderSalaryPage from '../renderSalaryPage/RenderSalaryPage';
import '../../assets/style/global.scss';

const SalaryComponent = ({ children }) => {
  return (
    <div className="G-salary-component">
      <MenuBar />
      <RenderSalaryPage children={children} />
    </div>
  );
};

export default SalaryComponent;
