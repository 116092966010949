import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';

export const fetchSubdivisions = async (companyId, query) => {
  try {
    const response = await getRequest(
      `getWorkDeps?companyID=${companyId}&searchString=${query}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchDepCode = async (companyId) => {
  try {
    const response = await getRequest(`getWorkDepNo?companyID=${companyId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addSubdivision = async (subdivisionData) => {
  try {
    const response = await postRequest('addWorkDep', {
      ...subdivisionData,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding subdivision:', error);
    throw error;
  }
};

export const updateSubdivision = async (subdivisionData) => {
  try {
    const response = await putRequest(`editWorkDep`, subdivisionData);
    return response.data;
  } catch (error) {
    console.error('Error updating subdivision:', error);
    throw error;
  }
};

export const deleteSubdivision = async (subdivisionId) => {
  try {
    const response = await deleteRequest(`deleteWorkDep/${subdivisionId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting subdivision:', error);
    throw error;
  }
};
