import dashboard from '../assets/menuIcons/dashboard.svg';
import informants from '../assets/menuIcons/Informants.svg';
import documentEntry from '../assets/menuIcons/documentEntry.svg';
import reports from '../assets/menuIcons/reports.svg';
export const routing = [
  {
    path: '/MaterialValuesDashboard',
    name: '',
    icon: dashboard,
  },

  {
    path: '#',
    name: 'Տեղեկատուներ',
    icon: informants,
    chillers: [
      {
        path: '/Material_value_groups',
        name: 'ՆԱ խմբեր',
      },
      {
        path: '/Material_values_names',
        name: 'ՆԱ անվանումներ',
      },

      {
        path: '/Material_values_warehouse',
        name: 'Պահեստներ',
      },
      {
        path: '/Material_values_partners',
        name: 'Գործընկերներ',
      },
      {
        path: '/Material_values_units_measure',
        name: 'Չափի միավորներ',
      },
    ],
  },
  {
    path: '#',
    name: 'Փաստաթղթեր',
    icon: reports,
    chillers: [
      {
        path: '/Warehouse_entry',
        name: 'Պահեստի մուտքի օրդեր',
      },

      {
        path: '#',
        name: 'Պահեստի ելքի օրդեր',
      },

      {
        path: '#',
        name: 'Հավելյալ ծախսերի բաշխում',
      },
      {
        path: '#',
        name: 'Ներմուծում',
      },
      {
        path: '#',
        name: 'ՆԱ տեղաշարժ',
      },
      {
        path: '#',
        name: 'ՆԱ կոմպլեկտավորում',
      },
      {
        path: '#',
        name: 'ՆԱ ապակոմպլեկտավորում',
      },
      {
        path: '#',
        name: 'ՆԱ գույքագրում',
      },
    ],
  },
  {
    path: '#',
    name: 'Հաշվետվություններ',
    icon: documentEntry,
    chillers: [
      {
        path: '#',
        name: 'ՆԱ մնացորդներ',
      },
      {
        path: '#',
        name: 'ՆԱ շրջանառու տեղեկագիր',
      },

      {
        path: '#',
        name: 'ՆԱ T հաշիվ',
      },
      {
        path: '#',
        name: 'ՆԱ փաստաթղթերի մատյան',
      },
    ],
  },
];
