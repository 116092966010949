import React from 'react';
import { Commas } from '../../../App/Utilities/Utilities';

const SalaryCalculationTotal = ({ totals }) => {
  return (
    <div className="salary-calculation-total">
      <h4>Ընդամենը</h4>
      <p>{totals?.hours?.toFixed(2)}</p>
      <p>{Commas(totals?.salary)}</p>
      <p>{Commas(totals?.totalAdd)}</p>
      <p> {Commas(totals?.totalDed)}</p>
      <p>{Commas(totals?.toBePayed)}</p>
    </div>
  );
};

export default SalaryCalculationTotal;
