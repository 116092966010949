import {
  DELETE_ADDITIONAL_STORAGE,
  FETCH_ADDITIONAL_STORAGE,
  FETCH_ADDITIONAL_STORAGE_FORM_DATA,
  UPDATE_ADDITIONAL_STORAGE,
} from './constants';

const initialState = {
  assignStorage: null,
  assignStorageForm: null,
};

export const assignAdditionalStorageReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_ADDITIONAL_STORAGE:
      return {
        ...state,
        assignStorage: payload,
      };

    case FETCH_ADDITIONAL_STORAGE_FORM_DATA:
      return {
        ...state,
        assignStorageForm: payload,
      };
    case UPDATE_ADDITIONAL_STORAGE:
      return {
        ...state,
        assignStorage: {
          ...state.assignStorage,
          salarySets: state.assignStorage.salarySets?.map((i) => {
            return i.salarySetID === payload.salarySetID ? payload : i;
          }),
        },
      };
    case DELETE_ADDITIONAL_STORAGE:
      return {
        ...state,
        assignStorage: {
          ...state.assignStorage,
          salarySets: state.assignStorage.salarySets.filter(
            (item) => item.salarySetID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
