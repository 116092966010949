export const ConvertDataExcel = (data) => {
  return (
    data !== null &&
    data?.length > 0 &&
    data?.map((i) => {
      return {
        Հաշիվ: i.accountCode,
        'Գործընկերոջ անվանումը': i.partnerName,
        Դեբետային: i.initialDebit,
        Կրեդիտային: i.initialCredit,
        Դեբետային: i.calculationDebit,
        Կրեդիտային: i.calculationCredit,
        Դեբետային: i.finalDebit,
        Կրեդիտային: i.finalCredit,
      };
    })
  );
};

export default ConvertDataExcel;
