import React from 'react';
import EventEditForm from './EventEditForm';
import DeleteAdditionsType from './DeleteAdditionsType';

const Forms = ({ formType, closeModal }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};

  switch (type) {
    case 'delete':
      return <DeleteAdditionsType id={value} handleClose={handleClose} />;
    case 'edit':
      return <EventEditForm salaryTypeID={value} handleClose={handleClose} />;

    default:
      return null;
  }
};

export default Forms;
