import React from 'react';
import './helperModal.scss';
import Modal from '../Modal/Modal';

const HelperModal = ({ closeHelpBox, helperMessage = '' }) => {
  const max = helperMessage?.indexOf(':');

  return (
    <Modal customClass="help-modal" closeModal={closeHelpBox}>
      <div className="helper-content">
        {max >= 0 ? (
          helperMessage.split(':').map((line, index) => (
            <p className="helper-message" key={index}>
              {line} :
            </p>
          ))
        ) : (
          <p className="helper-message">{helperMessage}</p>
        )}
      </div>
    </Modal>
  );
};

export default HelperModal;
