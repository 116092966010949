import React from 'react';
import Modal from '../../../../App/Components/Modal/Modal';
import Button from '../../../../App/Components/Button/Button';
import useSalaryCalculation from '../../../hooks/useSalaryCalculation';
import Alert from '../../../../App/Components/Alert';

const SalaryCalculationModal = ({ handleClose, rowValues }) => {
  const { getExecuteSalaryCalculator, errorMess, setErrorMess } =
    useSalaryCalculation();
  const { empNo, depName, depNo, emplName } = rowValues || {};

  const currentSalaryItem = [
    {
      name: '',
      value: depName,
    },
    {
      name: '',
      value: emplName,
    },
    {
      name: 'Տաբել N',
      value: empNo,
    },
  ];
  const onExecuteHandler = async () => {
    const values = {
      empNo,
      depName,
      depNo,
    };
    await getExecuteSalaryCalculator(values, handleClose);
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-salary-calculation-modal"
      title="Աշխատավարձի հաշվարկ"
    >
      {currentSalaryItem?.map((item, index) => (
        <div className="L-salary-calculation-list" key={index}>
          <strong>{item.name}</strong>
          <p>{item.value}</p>
        </div>
      ))}

      <Button
        onClick={onExecuteHandler}
        text="Կատարել"
        // disabled={isButtonDisabled}
        // customClass={isButtonDisabled && 'G-disabled-button'}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </Modal>
  );
};

export default SalaryCalculationModal;
