import React from "react";
import NumericFormatFiled from '../../Components/NumericFormat/NumericFormatFiled';
import {
  dateFormat,
  getFinalBalance,
  getTotalExpenditure,
} from '../../Utilities/Utilities';
const SpendingMoneyDealsInfo = ({ mainData, initInputs }) => {
  const { cashMoney } = mainData || {};
  const {
    initBalance,
    date1,
    date2,
    date3,
    date4,
    amount1,
    amount2,
    amount3,
    amount4,
    total,
    inputs,
  } = cashMoney || {};


  return (
    <>
      {cashMoney ? (
        <div className="L-account-money-received-block">
          <div className="G-modal-block-title">
            <h3>Առհաշիվ գումարները ստացվել է</h3>
          </div>

          <div className="L-account-money-received-content">
            <div className="L-received-and-money-spent-flow G-flex">
              <div className="L-sub-deals">
                <p className="G-title-p">Սկզբնական Մնացորդ</p>
                <div className="G-account-input">
                  <NumericFormatFiled
                    value={initBalance}
                    name="initBalance"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="L-received-and-money-spent-flow-flex G-flex">
                <div className="L-received-and-money-spent-flow">
                  <div className="L-date-amount-block G-flex">
                    <p className="G-title-p">Ամսաթիվ</p>
                    <div className="L-filter-by-amount L-spending-money-date G-flex">
                      <span>{date1 && dateFormat(date1)}</span>
                      <span>{date2 && dateFormat(date2)}</span>
                      <span>{date3 && dateFormat(date3)}</span>
                      <span>{date4 && dateFormat(date4)}</span>
                    </div>
                  </div>
                  <div className="L-date-amount-block G-flex">
                    <p className="G-title-p">Գումարը</p>
                    <div className="L-filter-by-amount G-flex">
                      <div className="L-sub-amount">
                        <NumericFormatFiled
                          value={amount1}
                          name="amount1"
                          disabled={true}
                        />
                      </div>
                      <div className="L-sub-amount">
                        <NumericFormatFiled
                          value={amount2}
                          name="amount2"
                          disabled={true}
                        />
                      </div>
                      <div className="L-sub-amount">
                        <NumericFormatFiled
                          value={amount3}
                          name="amount3"
                          disabled={true}
                        />
                      </div>
                      <div className="L-sub-amount">
                        <NumericFormatFiled
                          value={amount4}
                          name="amount4"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="L-sub-deals L-total G-flex">
                  <div className="G-account-input">
                    <NumericFormatFiled
                      value={total}
                      name="total"
                      disabled={true}
                      inputTitle="Ընդամենը"
                    />
                  </div>
                  <div className="G-account-input L-total">
                    <NumericFormatFiled
                      value={inputs}
                      name="inputs"
                      disabled={true}
                      inputTitle="Վերադարձ"
                    />
                  </div>
                </div>
              </div>
              <div className="L-total-expenditure-block G-flex">
                <div className="L-sub-deals">
                  <div className="G-account-input">
                    <NumericFormatFiled
                      value={getTotalExpenditure(mainData?.expenses)}
                      name="expense"
                      disabled={true}
                      inputTitle="Ծախսել է"
                    />
                  </div>
                </div>
                <div className="L-sub-deals">
                  <p className="G-title-p"></p>
                  <div className="G-account-input">
                    <NumericFormatFiled
                      value={getFinalBalance(cashMoney, mainData?.expenses)}
                      name="finalBalance"
                      disabled={true}
                      inputTitle="Վերջնական Մնացորդ"
                      allowNegative={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SpendingMoneyDealsInfo;
