import React from 'react';
import FormulationBtn from './FormulationBtn';

const AccountingFormulationBtn = ({
  onConfirmHandler,
  onRemoveHandler,
  isDisabledButtons,
  saveSign,
}) => {
  const { remove, confirm } = isDisabledButtons;
  const buttonsConfig = [
    {
      text: 'Ջնջել',
      onClick: () => onRemoveHandler(),
      type: 'submit',
      disabled: remove,
    },
    {
      text: 'Հաստատել',
      onClick: () => onConfirmHandler(),
      type: 'submit',
      disabled: confirm || !saveSign,
    },
  ];
  return (
    <FormulationBtn
      buttonsConfig={buttonsConfig}
      className="L-formulation-remove-conf-btn"
    />
  );
};

export default AccountingFormulationBtn;
