import React from 'react';
import classNames from 'classnames';
import './input.scss';

const InputField = ({
  type,
  name,
  value,
  customClass,
  changeHandler,
  placeholder,
  disabled = false,
  onBlur,
  requiredFiled = false,
  inputTitle = '',
  onKeyDown,
  onFocus,
  maxLength,
}) => {
  return (
    <div className="G-input-wrapper">
      <div className="G-input-title-flex">
        {requiredFiled ? <span className="G-asterisk">*</span> : null}
        {inputTitle && <p className="G-title-p">{inputTitle}</p>}
      </div>
      <label className="G-input-label">
        <input
          className={classNames('G-input-block', customClass, {
            'L-input-disabled': disabled,
          })}
          onChange={changeHandler}
          onFocus={onFocus}
          autocomplete="off"
          type={type || 'text'}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
        />
      </label>
    </div>
  );
};

export default InputField;
