import React from 'react';

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
const CurrencyTracker = ({ data }) => {
  const currentData = data?.rateList;

  let ratePresent = null;
  const dataMap = {};
  const currentRateMap = {};
  const colorMap = {};
  currentData?.forEach((d) => {
    const day = d.rateMonthDay;
    d.rates.forEach((r) => {
      const iso = r.iso;
      const rate = r.rate;
      const currentRate = r.currentRate;
      const color = r.isoColor;

      ratePresent =
        r.firstRate -
        (r.seq * r.firstRate) / 50 +
        (r.rate * r.firstRate) / 5000;

      if (!dataMap[iso]) {
        dataMap[iso] = {};
      }
      if (!currentRateMap[iso]) {
        currentRateMap[iso] = {};
      }
      if (!colorMap[iso]) {
        colorMap[iso] = color;
      }
      dataMap[iso][day] = rate;
      currentRateMap[iso][day] = currentRate;
    });
  });

  const dataSeries = Object.keys(dataMap).map((iso) => {
    const rateData = Object.keys(dataMap[iso]).map((rDay) => {
      return {
        x: rDay,
        currency: dataMap[iso][rDay],
        rate: currentRateMap[iso][rDay],
        iso: iso,
      };
    });
    return {
      id: iso,
      data: rateData,
      iso: iso,
      isoColor: colorMap[iso],
    };
  });

  const reversedData = dataSeries.reverse();
  const formatYAxisTick = (value) => {
    const reversedValue = value.split('.').reverse().join('.');
    return reversedValue;
  };

  return (
    <div className="L-exchange-rate L-currency-tracker G-block-style">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={reversedData}
          margin={{
            top: 5,
            left: 2,
            right: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="x"
            allowDuplicatedCategory={false}
            // minTickGap={31}
            tickFormatter={formatYAxisTick}
            interval={1}
          />
          <YAxis
            yAxisId="left"
            dataKey="currency"
            domain={[0, 500]}
            orientation="left"
          />

          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            formatter={(value, name, props) => {
              return ` ${props.payload.rate}`;
            }}
            labelFormatter={(value) => {
              return `Ամսաթիվ: ${value
                .toString()
                .split('.')
                .reverse()
                .join('.')}`;
            }}
          />

          {reversedData?.map((ds) => (
            <Line
              name={ds.iso}
              yAxisId="left"
              type="linear"
              dataKey="currency"
              data={ds.data}
              stroke={ds.isoColor}
              key={ds.id}
              strokeWidth={'2px'}
              dot={false}
            />
          ))}
          <Legend
            align="left"
            layout="middle"
            verticalAlign="middle"
            iconType=""
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CurrencyTracker;
