import React from 'react';
import { Commas } from '../../../../App/Utilities/Utilities';

const CalculationSheetTotal = ({ total }) => {
  const { totalAdd, totalDed, toBePayed } = total || {};

  return (
    <div className="calculation-total">
      <div className="calculation-total-section">
        <h4 className="calculation-total-label">Ընդամենը</h4>
        <p className="calculation-total-value">{Commas(totalAdd)}</p>
        <p className="calculation-total-value">{Commas(totalDed)}</p>
      </div>
      <div className="calculation-total-section">
        <h4 className="calculation-total-label">Առձեռն գումար</h4>
        <p className="calculation-total-value">{Commas(toBePayed)}</p>
      </div>
    </div>
  );
};

export default CalculationSheetTotal;
