import React, { useEffect } from 'react';
import Modal from '../Modal/Modal';
import './alert.scss';

const Alert = (props) => {
  const { onClose, title = '', description = '' } = props || {};
  const handleClose = () => onClose(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (description) {
        onClose('');
      }
    }, 6000);
    return () => clearTimeout(timer);
  }, [description, onClose]);
  return (
    description && (
      <Modal closeHandler={handleClose} customClass="G-alert-dialog-modal">
        <div className="G-alert-dialog">
          {title && <h3 className="">{title}</h3>}
          {description && <p>{description}</p>}
        </div>
      </Modal>
    )
  );
};

export default Alert;
