import React from 'react';
import Calendar from './Calendar';
import './calendar.scss';

const CalendarTime = (props) => {
  const {
    calendarTitle = true,
    dateStart,
    dateEnd,
    onChangeHandler,
    removeMinDate,
  } = props;

  return (
    <div className="G-calendar-wrapper">
      {calendarTitle ? <p className="G-title-p">Ժամանակահատված</p> : ''}
      <div className="G-calendar-box-flex G-flex">
        <div className="G-calendar-box">
          <Calendar
            selected={dateStart}
            value={dateStart}
            changeHandler={(val) => onChangeHandler(val, 'dateStart')}
            name="dateStart"
            removeMinDate={removeMinDate}
          />
        </div>
        <div className="G-calendar-box">
          <Calendar
            selected={dateEnd}
            value={dateEnd}
            changeHandler={(val) => onChangeHandler(val, 'dateEnd')}
            name="dateEnd"
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarTime;
