import React from 'react';
import RadioButton from '../../Components/RadioButton/RadioButton';
import Button from '../../Components/Button/Button';

const UploadFile = (props) => {
  const {
    fileUpload,
    filename,
    setFilename,
    setSelectedFile,
    setReceivedWrittenOff,
    disableButton,
    setDisableButton,
    excelList,
    receivedWrittenOff,
    disabledUploadFiled,
  } = props;
  const radioBtnDisabled = excelList && excelList?.length > 0 ? true : false;

  const handleFileInput = (e) => {
    if (e.target.files.length === 1) {
      setSelectedFile(e.target.files[0]);
      setFilename(e.target.files[0].name);
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const handelChecked = (e) => {
    setReceivedWrittenOff(e.target.value);
  };
  return (
    <div className="L-invoicing-upload-file-block">
      <div className="L-invoicing-radio-btn">
        <RadioButton
          value={1}
          id="recivedWrittenOff"
          name="recivedWrittenOff"
          label="Ստացված"
          onChange={handelChecked}
          disabled={radioBtnDisabled}
        />
        <RadioButton
          value={2}
          id="recivedWrittenOff"
          name="recivedWrittenOff"
          label="Դուրս գրված"
          onChange={handelChecked}
          disabled={radioBtnDisabled}
        />
      </div>
      <div className="L-invoicing-file-uploader-input">
        <div className="L-custom-label" htmlForm="customFile">
          {filename}
        </div>
        <label htmlFor="customFile" className="L-upload-label">
          <input
            type="file"
            id="customFile"
            accept=".xls, .xlsx"
            onChange={handleFileInput}
            className="L-uploader-input"
            disabled={disabledUploadFiled === null}
          />
        </label>
      </div>

      <div className="L-invoicing-btn">
        <Button
          type="submit"
          text="Հաստատել"
          onClick={fileUpload}
          disabled={disableButton}
          customClass={disableButton ? 'G-disabled-button' : ''}
        />
      </div>
      <h3 className="invoicing-vers">Հին տարբերակ</h3>
    </div>
  );
};

export default UploadFile;
