import React from 'react';
import { routing } from '../../routing/Routing';
import { NavLink, useHistory } from 'react-router-dom';
import './menu.scss';
import TopArrow from '../../../App/Components/TopArrow/TopArrow';
import { mainPage } from '../../../App/Utilities/Utilities';

const MenuBar = () => {
  const history = useHistory();

  const clearUser = () => {
    history.push(mainPage);
  };

  return (
    <div className="L-material-values-menu-bar">
      <div className="logo-wrap" onClick={clearUser}>
        <TopArrow />
      </div>
      <ul className="L-menu-list">
        {routing.map((item) => (
          <li className="L-menu-item" key={item.name}>
            <NavLink to={item.path}>
              <img src={item.icon} alt="alt" />
            </NavLink>
            {item.chillers && (
              <div className="L-menu-children">
                <h4 className="G-menu-title">{item.name}</h4>
                <ul>
                  {item.chillers.map((elem) => (
                    <li key={elem.name} className="L-item-child">
                      <NavLink to={elem.path}>{elem.name}</NavLink>
                      {elem.grandchildren && (
                        <ul className="L-menu-grandchildren">
                          {elem.grandchildren.map((grandchild) => (
                            <li key={grandchild.name}>
                              <NavLink to={grandchild.path}>
                                {grandchild.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MenuBar;
