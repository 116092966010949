import React from "react";
import RadioButton from "../../Components/RadioButton/RadioButton";

const CheckDebitCreditAccount = ({ isCheck, setIsCheck }) => {
  return (
    <div className="L-chose-debit-or-credit G-flex">
      <div className="L-debit-credit-box">
        <RadioButton
          defaultChecked={isCheck}
          value={isCheck}
          id="debitCredit"
          name="debitCredit"
          label="Դեբետ"
          onChange={(e) => setIsCheck(true)}
        />
      </div>
      <div className="L-debit-credit-box">
        <RadioButton
          value={isCheck}
          id="debitCredit"
          name="debitCredit"
          label="Կրեդիտ"
          onChange={(e) => setIsCheck(false)}
        />
      </div>
    </div>
  );
};

export default CheckDebitCreditAccount;
