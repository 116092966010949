import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import useTables from '../../hooks/useTables';
import TablesTable from './TablesTable';
import EventAddForm from './EventAddForm';
import './style.scss';
import ExcelForm from './ExcelForm';
import { ConvertTableExcel } from '../../Config/excelConfig';
const Tables = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const { fetchTablesData, tables } = useTables();
  useEffect(() => {
    fetchTablesData();
  }, []);

  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };
  const dataToExcel = ConvertTableExcel(tables?.graphs);
  return (
    <div className="G-small-wrap">
      <Header pageTitle="Աշխատաժամանակի տաբելներ" handelClick={onClose} />
      <ExcelForm dataToExcel={dataToExcel} />
      <TablesTable mainData={tables?.graphs} />
      {showAddForm && <EventAddForm handleClose={onClose} />}
    </div>
  );
};

export default Tables;
