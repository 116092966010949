import React from 'react';
import SearchInput from '../SearchForm/SearchInput';

const SearchFiled = ({
  searchValue,
  setSearchValue,
  inputTitle = 'Գործընկերոջ անվանումը',
}) => {
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  return (
    <div className="L-search-filed">
      <p className="G-title-p">{inputTitle}</p>
      <SearchInput
        value={searchValue}
        onChange={handleSearch}
        name="searchstring"
      />
    </div>
  );
};

export default SearchFiled;
