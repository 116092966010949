import { getRequest, putRequest } from '../../App/Api/Api';

export const fetchSalaryCalculationDate = async (companyID) => {
  try {
    const response = await getRequest(`getSetting?companyID=${companyID}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const saveSalaryCalculationDate = async (date) => {
  try {
    const response = await putRequest('saveSalaryDate', date);

    return response.data;
  } catch (error) {
    console.error('Error adding saveSalaryCalculationDate:', error);
    throw error;
  }
};
