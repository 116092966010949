import React, { useEffect, useState } from 'react';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import { GeneralVacationFields } from './FieldsConfig';
import Input from '../../Input/Input';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import DateComponent from '../../components/dateComponent/DateComponent';
import {
  dataTimeFormat,
  dateFormat,
  getArmenianMonthAndDay,
} from '../../../App/Utilities/Utilities';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import InputTitle from '../../Input/InputTitle';
import YearPicker from '../../components/yearPicker/YearPicker';

const GeneralVacationForm = ({
  handleClose,
  handleChange,
  touched,
  setFieldValue,
  values,
  handleBlur,
  errors,
  handleSubmit,
  dirty,
  isValid,
  generalVacationFormData,
  getVacationEndDate,
  vacationDateMess,
  setVacationDateMess,
}) => {
  const [isDisConfirm, setIsDisConfirm] = useState(false);

  const onClose = () => setVacationDateMess('');

  let dropDownOptions = [];

  useEffect(() => {
    setFieldValue('amount', Math.round(values.vacDays * values.dailyAvg, 2));
  }, [values.dailyAvg, values.vacDays]);

  const updateFieldValueAndDropdown = async (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    const { empNo, vacStartDate, vacDays } = values || {};
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id;

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
    //drop-i change jamanak el er ashxatum hanel tvecin chgidem xi dra hamar em comment are
    // if (dropdownId) {
    //   const res = await getVacationEndDate({
    //     empNo,
    //     vacStartDate,
    //     vacDays,
    //     stNo: dropdownItemId,
    //   });
    //   setVacationDateMess(res?.message);
    //   setFieldValue('vacEndDate', res?.vacationEndDate);
    // }
  };

  const onChangeDate = async (date, name) => {
    if (!date) {
      setFieldValue(name, null);
      return;
    }

    const formattedDate = dataTimeFormat(date);
    setFieldValue(name, formattedDate);
    const { empNo, vacDays, stNo } = values || {};
    if (name === 'vacStartDate') {
      const res = await getVacationEndDate({
        empNo,
        vacStartDate: date,
        vacDays,
        stNo,
      });

      setVacationDateMess(res?.message);
      setFieldValue('vacEndDate', res?.vacationEndDate);
    }
  };
  const handleYearChange = async (date, name) => {
    if (!date) {
      setFieldValue(name, null);
      const relatedField = name === 'periodStart' ? 'periodEnd' : 'periodStart';
      setFieldValue(relatedField, null);
      return;
    }

    const newYear = new Date(date).getFullYear();
    const currentFieldDate = values[name] ? new Date(values[name]) : new Date();
    const updatedDate = new Date(currentFieldDate);
    updatedDate.setFullYear(newYear);

    setFieldValue(name, dataTimeFormat(updatedDate));

    const relatedField = name === 'periodStart' ? 'periodEnd' : 'periodStart';
    const relatedFieldDate = values[relatedField]
      ? new Date(values[relatedField])
      : null;

    if (relatedFieldDate) {
      const adjustedRelatedDate = new Date(relatedFieldDate);
      adjustedRelatedDate.setFullYear(
        newYear + (name === 'periodStart' ? 1 : -1),
      );
      setFieldValue(relatedField, dataTimeFormat(adjustedRelatedDate));
    }
  };

  const onCustomChange = async (e) => {
    const { name, value } = e.target;
    const { empNo, vacStartDate, stNo } = values || {};
    setFieldValue(name, value);
    if (name === 'vacDays' && value) {
      const res = await getVacationEndDate({
        empNo,
        vacStartDate,
        vacDays: value,
        stNo,
      });

      setVacationDateMess(res.message);
      setFieldValue('vacEndDate', res?.vacationEndDate);
    } else {
      handleChange(e);
    }
  };
  const onConfirmClick = () => {
    setIsDisConfirm(false);
    onClose();
  };
  const onCancelClick = () => {
    setIsDisConfirm(true);
    onClose();
    setFieldValue('vacDays', 0);
  };
  const getArmenianMonthName = getArmenianMonthAndDay(values.periodStart);

  return (
    <div className="L-general-vacation-form">
      <form onSubmit={handleSubmit}>
        {GeneralVacationFields?.map((field) => {
          const {
            fieldType,
            fieldName,
            label,
            name,
            required,
            Children,
            optionName,
            dropdownTitle,
            type,
            dropdownId,
            dateName,
            dateTitle,
            maxLength,
            maxDate,
            dateFields,
            disabled,
          } = field;
          const isDisabled =
            typeof disabled === 'function' ? disabled(values) : disabled;
          const fieldError = touched[name] && errors[name];

          if (typeof Children === 'function' && generalVacationFormData) {
            dropDownOptions = Children(generalVacationFormData[optionName]);
          }

          switch (fieldType) {
            case 'input':
              return (
                <div key={`input-${name}`} className="L-general-vacation-filed">
                  <Input
                    type={type}
                    inputTitle={label}
                    name={name}
                    requiredFiled={required}
                    onChange={onCustomChange}
                    value={values[name]}
                    maxLength={maxLength}
                    disabled={disabled}
                  />
                </div>
              );
            case 'amount':
              return (
                <div key={name} className="L-general-vacation-filed">
                  <NumericFormatFiled
                    inputTitle={label}
                    name={name}
                    requiredFiled={required}
                    changeHandler={handleChange}
                    value={values[name]}
                    onBlur={handleBlur}
                    disabled={disabled}
                  />
                  {fieldError && <ErrorFiled error={fieldError} />}
                </div>
              );
            case 'dropdown':
              return (
                <div
                  key={`dropdown-${fieldName}`}
                  className="L-general-vacation-filed"
                >
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={required}
                    value={{
                      label: values[fieldName] || '',
                      value: values[fieldName] || '',
                    }}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      updateFieldValueAndDropdown(
                        fieldName,
                        selectedOption,
                        dropdownId,
                      )
                    }
                  />
                  {required && !values[fieldName] && fieldError && (
                    <ErrorFiled error={fieldError} />
                  )}
                </div>
              );
            case 'vacationDate':
              return (
                <div className="L-general-vacation-filed ">
                  <InputTitle requiredField={required} inputTitle={dateTitle} />

                  <div className="L-years-flex L-vacation-date-wrap">
                    <p title={getArmenianMonthName}> {getArmenianMonthName}</p>

                    {dateFields.map(({ dateName }) => {
                      const dateValue = values[dateName];
                      const initialYear = dateValue
                        ? new Date(dateValue)
                        : new Date();

                      return (
                        <YearPicker
                          key={dateName}
                          initialYear={initialYear}
                          onYearChange={(date) =>
                            handleYearChange(date, dateName)
                          }
                          yearItemNumber={4}
                          disabled={isDisabled}
                        />
                      );
                    })}
                  </div>
                </div>
              );

            case 'date':
              return (
                <div
                  className="L-general-vacation-filed"
                  key={`date-${dateName}`}
                >
                  <DateComponent
                    value={dateFormat(values[dateName] || '')}
                    onChange={(date) => onChangeDate(date, dateName)}
                    name={dateName}
                    title={dateTitle}
                    requiredFiled={required}
                    maxDate={maxDate}
                    disabled={disabled}
                    openToDate={new Date(values[dateName])}
                  />
                  {required && !values[dateName] && fieldError && (
                    <ErrorFiled error={fieldError} />
                  )}
                </div>
              );
            default:
              return null;
          }
        })}

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={
            !dirty || !isValid || isDisConfirm ? 'G-disabled-button' : ''
          }
          executeDisabled={!dirty || !isValid || isDisConfirm}
        />
      </form>

      {vacationDateMess && (
        <AcceptOrCancelModal
          modalContent={vacationDateMess + 'Ցանկանում եք շարունակել'}
          closeModal={onCancelClick}
          confirmClick={onConfirmClick}
        />
      )}
    </div>
  );
};

export default GeneralVacationForm;
