import React, { useState, useEffect, useCallback } from 'react';
import { getRequest, putRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { useDispatch } from 'react-redux';
import { closeDocumentAction } from '../../Redux/accountForSpendingMoneySlices/useAction';
import Alert from '../../Components/Alert';

const CloseDocument = ({ handleCloseDoc, closeDocumentData }) => {
  const { cashMoneyID, isClosed } = closeDocumentData || {};

  const [errorHandler, setErrorHandler] = useState('');
  const [modalConfig, setModalConfig] = useState(null);
  const dispatch = useDispatch();

  const handleError = (message) => setErrorHandler(message);

  const fetchAllowCloseStatus = useCallback(async () => {
    try {
      const url = isClosed
        ? `letOpenCashMoney?CashMoneyID=${cashMoneyID}`
        : `letCloseCashMoney?CashMoneyID=${cashMoneyID}`;
      const { data: { errorCode, message } = {} } = await getRequest(url);

      if (errorCode === 1 || errorCode === 0) {
        setModalConfig({
          confirmAction: isClosed ? agreeOpenDocument : closeDocumentHandler,
          content: isClosed
            ? 'Համոզվա՞ծ եք, որ ուզում եք բացել փակված փաստաթուղթը'
            : 'Համոզվա՞ծ եք, որ ուզում եք փակել փաստաթուղթ',
        });
      } else if ([111, 112, 108].includes(errorCode)) {
        handleError(message || 'Error in document status');
      }
    } catch {
      handleError('Error fetching close status');
    }
  }, [cashMoneyID, isClosed]);

  const agreeOpenDocument = async () => {
    try {
      const url = `openCashMoney?CashMoneyID=${cashMoneyID}`;
      const { data: { errorCode, message } = {} } = await putRequest(url);

      if (errorCode === 1) {
        dispatch(closeDocumentAction(cashMoneyID));
        setModalConfig(null);
        handleCloseDoc();
      } else {
        handleError(message);
      }
    } catch {
      handleError('Error in agreeOpenDocument');
    }
  };

  const closeDocumentHandler = async () => {
    try {
      const url = `closeCashMoney?cashMoneyID=${cashMoneyID}`;
      const { data: { errorCode, message } = {} } = await putRequest(url);

      if (errorCode === 1) {
        dispatch(closeDocumentAction(cashMoneyID));
        handleCloseDoc();
      } else {
        handleError(message);
      }
    } catch {
      handleError('Error in closeDocumentHandler');
    }
  };

  useEffect(() => {
    if (cashMoneyID) fetchAllowCloseStatus();
  }, [cashMoneyID, fetchAllowCloseStatus]);

  return (
    <>
      {modalConfig && (
        <AcceptOrCancelModal
          closeModal={handleCloseDoc}
          confirmClick={modalConfig.confirmAction}
          modalContent={modalConfig.content}
        />
      )}
      <Alert description={errorHandler} onClose={() => setErrorHandler('')} />
    </>
  );
};

export default CloseDocument;
