import React, { useState } from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import SelectUserPicture from '../SelectUserPicture/SelectUserPicture';
import ChangePassword from '../ChangePassword/ChangePassword';
import CurrentEmailField from '../../Elements/CurrentEmailFiled/CurrentEmailFiled';
import Alert from '../../Components/Alert';
import CheckNewEmailMes from '../../Elements/CurrentEmailFiled/CheckNewEmailMes';
import userSections from './userSections';
import UserInfo from '../../Elements/UserInfo/UserInfo';
import useUserImage from './useUserImage';
import UserImage from './UserImage';
import UpdateOrganization from '../../Pages/Organization/UpdateOrganization';
import Modal from '../../Components/Modal/Modal';
import UserDataHeader from './UserDataHeader';
import RenderUserDataSections from './RenderUserDataSections';
import './style.scss';
import HelpBtn from '../../Components/HelpBtn/HelpBtn';
import HelperContent from '../../Components/HelperContent/HelperContent';

const UserData = () => {
  const [modals, setModals] = useState({});
  const [sentEmail, setSentEmail] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const [helperText, setHelperText] = useState('');
  const {
    userID,
    firstName,
    lastName,
    phone,
    email,
    picture,
    currentCompanyName,
  } = useSelector((state) => state.auth);
  const history = useHistory();
  const { img, onDeleteUserImg, getUserImg } = useUserImage(userID, picture);

  let location = useLocation();

  const toggleModal = (modalType) => {
    setModals((prev) => ({ ...prev, [modalType]: !prev[modalType] }));
  };
  const text = `Օգտվողի տվյալների փոփոխում։ Նկարագրություն՝
- Նկարի տեղադրում կամ փոփոխում: Փոփոխությունը պահպանեք է Հաստատել կոճակով, չեղարկեք Ընդհատել կոճակով, նկարը հեռացրեք աղբամանի վրա սեղմելով,
- Անձնական տվյալներ՝ օգտվողի անուն, ազգանուն, հեռախոսահամարն է: Փոփոխությունը պահպանեք  Գրանցել կոճակով,
- Մուտքանուն՝ էլեկտրոնային հասցեն է:  Փոփոխությունը պահպանեք  Գրանցել կոճակով, դրան հաջորդում է  նույնականացման գործընթացը, որից հետո գրանցեք  նոր հասցեն,
- Գաղտնագիր: Գաղտնագրի փոփոխության համար  մուտքագրեք ընթացիկ գաղտնաբառը, հետո նորը, ևս մեկ անգամ  լրացրեք նոր գաղտնաբառը  և պահպանեք Գրանցել կոճակով
`;
  const clearUser = () => {
    localStorage.removeItem('persist:root');
    history.push('/OneWindow');
    window.location.reload();
  };

  const helpHandler = () => {
    setHelperText(text);
  };

  return (
    <>
      <UserDataHeader />

      <div className="G-user-data-block">
        <HelpBtn helpHandler={helpHandler} />
        <UserImage
          img={img}
          onDeleteUserImg={onDeleteUserImg}
          openModal={() => toggleModal('userImg')}
        />

        <RenderUserDataSections
          userSections={userSections(
            firstName,
            lastName,
            phone,
            email,
            currentCompanyName,
          )}
          toggleModal={toggleModal}
        />

        {modals.userImg && (
          <SelectUserPicture
            closeModal={() => toggleModal('userImg')}
            img={img}
            getUserImg={getUserImg}
          />
        )}
        {modals.userData && (
          <UserInfo closeModal={() => toggleModal('userData')} />
        )}
        {modals.userPass && (
          <ChangePassword closeModal={() => toggleModal('userPass')} />
        )}
        {modals.userEmail && (
          <CurrentEmailField
            closeModal={() => toggleModal('userEmail')}
            setErrorHandler={setErrorHandler}
            setSentEmail={setSentEmail}
          />
        )}
        {modals.organizationData && (
          <Modal
            customClass="L-organization-form from-user-data"
            closeHandler={() => toggleModal('organizationData')}
          >
            <UpdateOrganization />
          </Modal>
        )}

        <Alert description={errorHandler} onClose={() => setErrorHandler('')} />
      </div>

      {sentEmail && (
        <CheckNewEmailMes
          onRemoveUserData={clearUser}
          closeModal={() => setSentEmail(false)}
          currentEmail={sentEmail}
        />
      )}

      {helperText && (
        <HelperContent
          closeHelpBox={setHelperText}
          helperMessage={helperText}
          locationPathname={location.pathname}
        />
      )}
    </>
  );
};

export default UserData;
