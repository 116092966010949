import { createAction } from "../actionCreators";
import {
  DELETE_OPERATIONS,
  EDIT_OPERATIONS_DATA,
  GET_OPERATIONS_DATA,
  LOADING,
  UPDATE_PARTNER_OPERATIONS,
} from "./constants";

export const getOperationsAction = createAction(GET_OPERATIONS_DATA);
export const deleteOperationsAction = createAction(DELETE_OPERATIONS);
export const editOperationsAction = createAction(EDIT_OPERATIONS_DATA);
export const loadingAction = createAction(LOADING);
export const updatePartnerAction = createAction(UPDATE_PARTNER_OPERATIONS);
