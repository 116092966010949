export const assignPositionFields = [
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Հավելում',
    fieldName: 'stNo',
    dropdownId: 'stNo',
    optionName: 'sTypes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.stName,
        id: i.stNo,
        value: i.stName,
        graphNo: i.graphNo,
      }));
    },
    required: true,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Ստորաբաժանում',
    fieldName: 'depNo',
    dropdownId: 'depNo',
    optionName: 'workDeps',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.depName,
        id: i.depNo,
        value: i.depName,
      }));
    },
    required: false,
  },
];
