import React from "react";
import "./footer.scss";
import facebook from "../../../Assets/icons/facebook.png";


const Footer = () => {
    const currentYear = new Date().getFullYear();
  return (
    <div className="G-one-window-view-footer">
      <p>
        © {currentYear} "Մեկ Պատուհան" ՍՊԸ, Բոլոր իրավունքները պաշտպանված են
      </p>
      <span className="L-social-icon">
        <a
          href="https://www.facebook.com/profile.php?id=100083201448711"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="" />
        </a>
      </span>
    </div>
  );
};

export default Footer;
