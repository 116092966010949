import React from "react";
import "./index.scss";

export const TotalList = ({ arr }) => {
  return (
    <div className="G-total-list-block ">
      <h4>Ընդամենը</h4>
      {arr?.map((val) => {
        return <strong key={val}>{val}</strong>;
      })}
    </div>
  );
};
