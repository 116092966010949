import { removeCommas } from '../../../../App/Utilities/Utilities';

export const updatePriceAndTax = (
  values,
  inputTypeId,
  incomeSign,
  setFieldValue,
) => {
  const parsedPurPrice = removeCommas(values.purPrice) || 0;
  const parsedAmount = removeCommas(values.amount) || 0;
  const parsedPrices = removeCommas(values.price) || 0;

  let incomeTax = 0;
  let price = parsedPurPrice;
  let totalPrice = parsedAmount * parsedPrices;

  if (incomeSign) {
    if (inputTypeId === 3) {
      incomeTax = Math.round(parsedPurPrice - parsedPurPrice / 1.2, 2);
    } else if (inputTypeId === 4) {
      incomeTax = Math.round(parsedPurPrice - parsedPurPrice / 1.1, 2);
    }
  } else {
    if (inputTypeId === 3) {
      incomeTax = Math.round((parsedPurPrice * 20) / 100, 2);
      price = parsedPurPrice + incomeTax;
    } else if (inputTypeId === 4) {
      incomeTax = Math.round((parsedPurPrice * 10) / 100, 2);
      price = parsedPurPrice + incomeTax;
    }
  }

  totalPrice = parsedAmount * price;

  setFieldValue('incomeTax', incomeTax);
  setFieldValue('price', price);
  setFieldValue('totalPrice', totalPrice);
};
