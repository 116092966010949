import React from 'react';
import TopArrowIcon from '../../Assets/menuIcons/exit.svg';
import './style.scss';

const TopArrow = ({ onClick, customClass = '' }) => {
  return (
    <div className={`L-log-out  ${customClass}`} onClick={onClick}>
      <img src={TopArrowIcon} alt="" />
    </div>
  );
};

export default TopArrow;
