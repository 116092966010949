import React, { useEffect, useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import { Commas, dateFormat } from '../../../App/Utilities/Utilities';
import Alert from '../../../App/Components/Alert';
import useConfirmEmployeePosition from '../../hooks/useConfirmEmployeePosition';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import EmployeePositionTotal from './EmployeePositionTotal';
import checkIcon from '../../assets/icons/check.svg';
import DeleteEmployeePosition from './DeleteEmployeePosition';

const EmployeePositionTable = ({
  mainData,
  isDisabledConfBtn,
  setIsDisabledConfBtn,
  handleClose,
  empNo,
  addConfirmEmployeePosition,
  getDeleteEmployeePosition,
  errorMess,
  setErrorMess,
}) => {
  const [newPosition, setNewPosition] = useState(mainData);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isCountTotal, setIsCountTotal] = useState({
    addAmount: 0,
    hours: 0,
  });

  useEffect(() => {
    setNewPosition(mainData);
  }, [mainData]);

  useEffect(() => {
    const newTotals = newPosition?.reduce(
      (acc, item) => {
        acc.addAmount += item.addAmount || 0;
        acc.hours += item.hours || 0;
        return acc;
      },
      { addAmount: 0, hours: 0 },
    );
    setIsCountTotal(newTotals);
  }, [newPosition]);

  const handleChangeDays = (e, targetRow) => {
    const { value } = e.target;
    const newDays = Number(value);
    if (newDays > targetRow.calcDays) {
      setErrorMess('Գերազանցում է ամսվա աշխատանքային օրերի թվին՝ փոխեք ');
      return;
    }
    if (isNaN(newDays) || newDays < 0 || newDays > targetRow.calcDays) {
      return;
    }
    setIsDisabledConfBtn(false);
    const updatedRows = newPosition?.map((row) => {
      if (targetRow.positionID === row.positionID) {
        const newAmount = (row.salary / row.calcDays) * newDays;

        const updatedRow = {
          ...row,
          hours: +(row.graphHours * newDays).toFixed(2),
          addAmount: Math.round(newAmount),
          days: newDays,
          isModifed: true,
        };

        return updatedRow;
      }
      return row;
    });

    setNewPosition(updatedRows);
  };

  const handleChangeHours = (e, targetRow) => {
    const { value } = e.target;
    const newHours = Number(value);
    if (newHours > targetRow.calcHours) {
      return;
    }
    if (isNaN(newHours) || newHours < 0 || newHours > targetRow.calcHours) {
      return;
    }
    setIsDisabledConfBtn(false);
    const updatedRows = newPosition?.map((row) => {
      if (row.positionID === targetRow.positionID) {
        const newDay = newHours / row.graphHours;

        const newAddAmount = row.salary * newHours;

        const upDays = newDay % 2 > 0 ? Math.trunc(newDay) + 1 : newDay;

        const updatedRow = {
          ...row,
          hours: newHours,
          days: upDays,
          addAmount: Math.round(newAddAmount),
          isModifed: true,
        };
        return updatedRow;
      }
      return row;
    });

    setNewPosition(updatedRows);
  };

  const columnConfig = [
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.salaryDate),
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Հ/Պ',
      cell: (row) => row.stNo,
      customStyle: { maxWidth: 60 },
    },
    {
      title: 'Հ/Պ անվանումը',
      cell: (row) => row.stName,
      customStyle: { maxWidth: 800, overflow: 'hidden' },
    },
    {
      title: 'Դրույքաչափը',
      cell: (row) => Commas(row.salary),
      customStyle: { maxWidth: 150, justifyContent: 'end' },
    },
    {
      title: 'Օրը',
      cell: (row) => (
        <input
          type="number"
          value={Math.round(row?.days)}
          onChange={(e) => handleChangeDays(e, row)}
          disabled={row?.formSign === 2}
          style={{ fontWeight: row.tableID > 0 ? 'bold' : 'inherit' }}
        />
      ),
      isEdit: true,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ժամ',
      cell: (row) => (
        <input
          type="number"
          value={row?.hours}
          onChange={(e) => handleChangeHours(e, row)}
          disabled={row?.formSign === 1}
          maxLength={4}
          style={{ fontWeight: row.tableID > 0 ? 'bold' : 'inherit' }}
        />
      ),
      isEdit: true,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Գումար',
      cell: (row) => Commas(row.addAmount),
      customStyle: { maxWidth: 150, justifyContent: 'end' },
    },
    {
      title: 'Հաստատված',
      cell: (row) =>
        row.tableID > 0 && !row.isModifed ? (
          <img className="G-confirm-icon" src={checkIcon} alt="checkIcon" />
        ) : null,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գործողություն',
      cell: (row) => (
        <span
          className="G-delete-span"
          onClick={() => openDeleteForm(row?.tableID)}
        >
          {row.tableID > 0 ? 'Ջնջել' : ''}
        </span>
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  const onSaveHandler = async () => {
    try {
      await addConfirmEmployeePosition(newPosition, empNo);
      setIsDisabledConfBtn(true);
    } catch (error) {
      console.error('Error occurred while saving:', error);
    }
  };
  const openDeleteForm = (deleteRowId) => {
    setOpenDeleteModal(deleteRowId);
  };

  return (
    <>
      {newPosition !== null && newPosition?.length ? (
        <Table
          customClass="L-confirm-positions-table G-table-has-scroll"
          data={newPosition}
          columnConfig={columnConfig}
        />
      ) : null}
      {newPosition && newPosition?.length > 0 && (
        <>
          <EmployeePositionTotal total={isCountTotal} />
          <GetSentDataBtn
            cancelPrintClick={handleClose}
            confirmExecuteClick={onSaveHandler}
            executeClass={isDisabledConfBtn ? 'G-disabled-button' : ''}
            executeDisabled={isDisabledConfBtn}
          />
        </>
      )}
      {openDeleteModal && (
        <DeleteEmployeePosition
          tableID={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          getDeleteEmployeePosition={getDeleteEmployeePosition}
          empNo={empNo}
        />
      )}
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EmployeePositionTable;
