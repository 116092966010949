import React from 'react';
import './style.css';

const Spinner = () => {
  return (
    <div class="spinner-wrap ">
      <span class="material spinner" />
    </div>
  );
};

export default Spinner;
