import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SelectAccount from '../../Components/SelectBox/SelectAccount';

const FilteredBalanceData = ({
  getData,
  pageNumber,
  initBalancesItem,
  setInitBalancesItem,
  setPageNumber,
}) => {
  const { initialBalancesData } = useSelector(
    (state) => state.getInitialBalances,
  );

  useEffect(() => {
    if (initBalancesItem.value !== '') {
      setPageNumber(0);
      getData(pageNumber, initBalancesItem?.value);
    } else if (initBalancesItem.value === '') {
      getData(pageNumber);
    }
  }, [initBalancesItem.value, pageNumber]);

  const changeAccountHandler = (value) => {
    if (!value) {
      value = {
        value: '',
      };
    }
    setInitBalancesItem(value);
  };

  return (
    <SelectAccount
      requeuedAccount={false}
      isClearable={true}
      title=""
      customClass="L-initial-balance-account-wrapper"
      accountData={initialBalancesData?.accounts}
      accountVal={initBalancesItem}
      selectChangeAccount={(values) => changeAccountHandler(values)}
    />
  );
};

export default FilteredBalanceData;
