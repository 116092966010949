import React, { useEffect } from 'react';
import usePositionsForm from '../../hooks/usePositionsForm';
import { positionFields } from './FieldsConfig';
import Input from '../../Input/Input';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import { useFormik } from 'formik';
import Modal from '../../../App/Components/Modal/Modal';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import { PositionValidation } from '../../../App/Validation/PositionValidation';
import useIsMounted from '../../hooks/useIsMounted';
const PositionsForm = ({ handleClose, onSubmit, positionID = 0 }) => {
  const { positionsFormData, fetchPositionFormData } = usePositionsForm();
  const isMounted = useIsMounted();

  useEffect(() => {
    fetchPositionFormData(positionID);
  }, [positionID]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    dirty,
    handleBlur,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...positionsFormData,
    },
    validationSchema: PositionValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
      if (isMounted) {
        resetForm();
      }
    },
  });

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="position-form-block"
    >
      <form onSubmit={onSubmit}>
        <div className="position-content">
          {positionFields?.map((field) => {
            const { label, name, required, type } = field;
            const fieldError = touched[name] && errors[name];
            return (
              <div key={name} className="input-container">
                <Input
                  type={type}
                  inputTitle={label}
                  name={name}
                  requiredField={required}
                  onChange={handleChange}
                  value={values[name]}
                  onBlur={handleBlur}
                />
                {fieldError && <ErrorFiled error={fieldError} />}
              </div>
            );
          })}
        </div>
        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
          executeDisabled={!dirty || !isValid}
        />
      </form>
    </Modal>
  );
};

export default PositionsForm;
