import React from 'react';
import Logo from '../../Assets/image/1P.png';

import './index.scss';

const OneWindowLogo = ({ handleClick, className }) => {
  return (
    <div className="L-one-window-logo">
      <div className={`L-logo ${className}`} onClick={handleClick}>
        <img src={Logo} alt="" />
      </div>
    </div>
  );
};

export default OneWindowLogo;
