import React from 'react';
import MakeAdditionalSave from '../../makeAdditionalSave/MakeAdditionalSave';
import Modal from '../../../../App/Components/Modal/Modal';

const MakeAdditionalSaveModal = ({ handleClose, rowValues }) => {
  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-make-additional-save-modal"
      title="Կատարել հավելում/պահում"
    >
      <MakeAdditionalSave rowValues={rowValues} handleClose={handleClose} />
    </Modal>
  );
};

export default MakeAdditionalSaveModal;
