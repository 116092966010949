import React from 'react';
import { Commas } from '../../../App/Utilities/Utilities';

const TotalAmountForm = ({ mainData }) => {
  const { totalAmount, totalAm12, totalCount } = mainData || {};

  return (
    <div className="vacation-total-section">
      <h4 className="vacation-total-label">Ընդամենը լրիվ ամիսներ</h4>
      <p className="vacation-total-value">{Commas(totalAmount)}</p>
      <p className="vacation-total-value">{Commas(totalAm12)}</p>
      <p className="vacation-total-value">{totalCount}</p>
    </div>
  );
};

export default TotalAmountForm;
