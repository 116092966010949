import {
  DELETE_PAYMENT_ORDER,
  EDIT_PAYMENT_ORDER,
  GET_PAYMENT_ORDER_DATA,
  LOADING,
  UPDATE_PARTNER_PAYMENT_ORDER,
} from "./constants";

const initialState = {
  paymentOrderData: null,
  isLoading: false,
};

export const paymentOrderReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_PAYMENT_ORDER_DATA:
      return {
        ...state,
        paymentOrderData: payload,
        isLoading: true,
      };

    case UPDATE_PARTNER_PAYMENT_ORDER:
      return {
        ...state,
        paymentOrderData: {
          ...state.paymentOrderData,
          partners: [...state.paymentOrderData.partners, ...payload],
        },
      };
    case DELETE_PAYMENT_ORDER:
      return {
        ...state,
        paymentOrderData: {
          ...state.paymentOrderData,
          paymentOrders: state.paymentOrderData?.paymentOrders.filter(
            (item) => item.documentID !== payload
          ),
        },
      };

    case EDIT_PAYMENT_ORDER:
      return {
        ...state,
        paymentOrderData: {
          ...state.paymentOrderData,
          paymentOrders: state.paymentOrderData?.paymentOrders.map((item) => {
            return item.documentID === payload.documentID ? payload : item;
          }),
        },
      };

    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return { ...state };
  }
};
