import { useEffect, useState } from 'react';
import {
  fetchBenefitCalculation,
  fetchBenefitNonWorkings,
  saveBenefitCalculation,
  saveBenefitAverages,
  fetchBenAverages,
  fetchBenefitIsValid,
} from '../services/fetchBenefitCalculation';
import { useSelector } from 'react-redux';

const useBenefitCalculation = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [error, setError] = useState('');
  const [errorMess, setErrorMess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [benefitCalculation, setBenefitCalculation] = useState(null);
  const [isVacationDateValid, setIsVacationDateValid] = useState('');
  const getBenefitCalculation = async (salaryDate, empNo) => {
    setIsLoading(true);
    try {
      const data = await fetchBenefitCalculation(
        currentCompanyID,
        salaryDate,
        empNo,
      );
      setBenefitCalculation(data);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getNonWorkings = async (empNo) => {
    setIsLoading(true);
    try {
      const data = await fetchBenefitNonWorkings(currentCompanyID, empNo);
      setBenefitCalculation((prevFormData) => ({
        ...prevFormData,
        nonWorkings: [...data],
      }));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBenefitIsValid = async (values) => {
    const { empNo, stNo, benStartDate, benEndDate } = values || {};
    setIsLoading(true);
    try {
      const response = await fetchBenefitIsValid(
        currentCompanyID,
        empNo,
        stNo,
        benStartDate,
        benEndDate,
      );

      const {
        averages = [],
        averagesTotal = [],
        forBenefitSave = [],
        message,
      } = response || {};

      if (!message) {
        setBenefitCalculation((prevFormData) => ({
          ...prevFormData,
          averages,
          averagesTotal,
          forBenefitSave,
        }));
      } else {
        setIsVacationDateValid(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addBenefitCalculation = async (newData) => {
    const { empNo, salaryDate } = newData || {};
    const { percAmount, scaleAmount, amount, ...filteredData } = newData;
    try {
      const { errorCode, message } = await saveBenefitCalculation(filteredData);
      if (errorCode === 0) {
        getBenefitCalculation(salaryDate, empNo);
        getNonWorkings(empNo);
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };
  const getBenAverages = async (empNo, vacStartDate, avgDays) => {
    setIsLoading(true);
    try {
      const data = await fetchBenAverages(
        currentCompanyID,
        empNo,
        vacStartDate,
        avgDays,
      );
      setBenefitCalculation((prevFormData) => ({
        ...prevFormData,
        averages: [...prevFormData?.averages, ...data?.averages],
      }));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addAverages = async (newAverages, values) => {
    const { empNo, vacStartDate, avgDays } = values || {};
    try {
      const { errorCode, message } = await saveBenefitAverages(newAverages);
      if (errorCode === 0) {
        getBenAverages(empNo, vacStartDate, avgDays);
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    getBenefitCalculation,
    benefitCalculation,
    addBenefitCalculation,
    addAverages,
    getBenefitIsValid,
    isLoading,
    error,
    errorMess,
    setErrorMess,
    isVacationDateValid,
    setIsVacationDateValid,
    setBenefitCalculation,
  };
};

export default useBenefitCalculation;
