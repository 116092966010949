import React, { useEffect, useState } from 'react';
import './style.scss';
import UploadFile from './UploadFile';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOldInvoicingAction,
  loadingAction,
} from '../../Redux/eInvoicingAccountsSlices/useAction';
import { postRequest } from '../../Api/Api';
import OldEInvoicingTable from './OldEInvoicingTable';
import Alert from '../../Components/Alert';

const OldEInvoicingUpload = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { oldInvoicingData, isLoading, invoicingAccountData } = useSelector(
    (state) => state.getInvoicingAccount,
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [filename, setFilename] = useState('Չկա ընտրված ֆայլ');
  const [disableButton, setDisableButton] = useState(true);
  const [receivedWrittenOff, setReceivedWrittenOff] = useState(null);
  const [registerBtnDisabled, setRegisterBtnDisabled] = useState(true);
  const [errorHandler, setErrorHandler] = useState('');
  const dispatch = useDispatch();
  const { excelList } = oldInvoicingData || [];
  const accountTitle =
    receivedWrittenOff == 1 ? ' Կրեդիտ հաշիվ' : 'Դեբետ հաշիվ';

  const uploadFileHandler = () => {
    const formData = new FormData();
    formData.append('name', filename);
    formData.append('file', selectedFile);
    dispatch(loadingAction(true));
    postRequest(
      `importexcel?companyID=${currentCompanyID}&recivedwrittenOff=${receivedWrittenOff}`,
      formData,
    )
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(getOldInvoicingAction(res.data));
          setFilename('Չկա ընտրված ֆայլ');
        } else if (res.data.errorCode > 0 && res.data.messageExcel) {
          setErrorHandler(res.data.messageExcel);
          setDisableButton(true);
          setFilename('Չկա ընտրված ֆայլ');
          setRegisterBtnDisabled(true);
        }
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    return () => {
      dispatch(getOldInvoicingAction([]));
    };
  }, [dispatch]);

  return (
    <>
      {invoicingAccountData?.excelList ? null : (
        <div className="L-old-EInvoicing-upload">
          <UploadFile
            fileUpload={uploadFileHandler}
            filename={filename}
            setFilename={setFilename}
            setSelectedFile={setSelectedFile}
            setReceivedWrittenOff={setReceivedWrittenOff}
            disableButton={disableButton}
            setDisableButton={setDisableButton}
            excelList={excelList}
            disabledUploadFiled={receivedWrittenOff}
            receivedWrittenOff={receivedWrittenOff}
          />
          <OldEInvoicingTable
            mainData={oldInvoicingData}
            loading={isLoading}
            registerBtnDisabled={registerBtnDisabled}
            setRegisterBtnDisabled={setRegisterBtnDisabled}
            accountTitle={accountTitle}
            setDisableButton={setDisableButton}
            setErrorHandler={setErrorHandler}
            fetchData={uploadFileHandler}
          />
          <Alert description={errorHandler} onClose={setErrorHandler} />
        </div>
      )}
    </>
  );
};

export default OldEInvoicingUpload;
