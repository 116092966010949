import { deleteRequest, getRequest, postRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';
import { dataTimeFormat } from '../../App/Utilities/Utilities';

export const fetchWarehouseEntry = async (currentCompanyID, query) => {
  const updatedParams = {
    ...query,
    pageNumber: query.pageNumber + 1,
    startDate: dataTimeFormat(query.startDate),
    endDate: dataTimeFormat(query.endDate),
  };
  const queryRoot = urlEncodeQueryParams(updatedParams);
  try {
    const response = await getRequest(
      `getMatInputs?companyID=${currentCompanyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
export const fetchWarehouseTimePeriod = async (userID) => {
  try {
    const response = await getRequest(
      `getMatInputSearchDates?userID=${userID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchWarehouseEntryForm = async (
  currentCompanyID,
  matInputDocID,
) => {
  try {
    const response = await getRequest(
      `getMatInput?companyID=${currentCompanyID}&matInputDocID=${matInputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
export const fetchInvoiceEntryForm = async (
  currentCompanyID,
  partnerID,
  matInputDocID,
) => {
  try {
    const response = await getRequest(
      `getInvMatInput?companyID=${currentCompanyID}&partnerID=${partnerID}&matInputDocID=${matInputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addNewWarehouseEntry = async (newWarehouseEntry) => {
  try {
    const response = await postRequest('saveMatInput', newWarehouseEntry);
    return response.data;
  } catch (error) {
    console.error('Error adding warehouseEntry:', error);
    throw error;
  }
};

export const fetchIsRemoveWarehouseEntry = async (matInputDocID) => {
  try {
    const response = await getRequest(
      `preDeleteEditMatInput?matInputDocID=${matInputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting warehouseEntry:', error);
    throw error;
  }
};

export const removeWarehouseEntry = async (matInputDocID) => {
  try {
    const response = await deleteRequest(
      `deleteMatInput?matInputDocID=${matInputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting warehouseEntry:', error);
    throw error;
  }
};

export const fetchWarehouseEntryExcel = async (currentCompanyID, query) => {
  const queryRoot = urlEncodeQueryParams(query);

  try {
    const response = await getRequest(
      `getMatInputsForExcel?companyID=${currentCompanyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchWarehouseEntryDocNum = async (
  currentCompanyID,
  storeNo,
  year,
) => {
  if (!storeNo) return;
  try {
    const response = await getRequest(
      `getMatInputNo?companyID=${currentCompanyID}&storeNo=${storeNo}&year=${year}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
