import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import { dataTimeFormat, initialDate } from '../../Utilities/Utilities';
import { getDashboardDataAction } from '../../Redux/dashboardSlices/useAction';
import PartnerBalance from './PartnerBalance/PartnerBalance';
import FinancialMeans from './FinancialMeans/FinancialMeans';
import ExchangeRate from './ExchangeRate/ExchangeRate';
import './dashboard.scss';
import CurrencyTracker from './CurrencyTracker/CurrencyTracker';

const GetDashboardData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { dashboardData } = useSelector((state) => state.getDashboardData);

  const [dateRemainderPartner, setDateRemainderPartner] = useState(
    initialDate(),
  );
  const [dateRemainderFinancial, setDateRemainderFinancial] = useState(
    initialDate(),
  );

  const [rateDate, setRateDate] = useState(initialDate());
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        const dateRemainderFinancialFormate = dataTimeFormat(
          dateRemainderFinancial,
        );
        const dateRemainderPartnerFormate =
          dataTimeFormat(dateRemainderPartner);

        const rateDateFormate = dataTimeFormat(rateDate);

        const res = await getRequest(
          `getdashboard?companyID=${currentCompanyID}&dateRemainderFinancial=${dateRemainderFinancialFormate}&dateRemainderPartner=${dateRemainderPartnerFormate}&rateDate=${rateDateFormate}`,
        );
        dispatch(getDashboardDataAction(res.data));

        return res;
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [
    currentCompanyID,
    dateRemainderFinancial,
    dateRemainderPartner,
    rateDate,
    dispatch,
  ]);

  const dashboardBoxes = [
    <FinancialMeans
      data={dashboardData}
      dateStart={dateRemainderFinancial}
      setDateStart={setDateRemainderFinancial}
    />,
    <ExchangeRate
      data={dashboardData}
      dateStart={rateDate}
      setDateStart={setRateDate}
    />,

    <PartnerBalance
      data={dashboardData}
      dateStart={dateRemainderPartner}
      setDateStart={setDateRemainderPartner}
    />,
    <CurrencyTracker data={dashboardData} />,
  ];

  return (
    <div className="L-dashboard-wrap">
      <Header headerBtn={false} />

      <div className="L-dashboard-main-block">
        <div className="L-dashboard-boxes-parent G-flex G-flex-wrap">
          {dashboardBoxes?.map((box, index) => {
            return (
              <div key={index} className="L-dashboard-warper">
                {box}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GetDashboardData;
