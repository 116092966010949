import React from 'react';
import Calendar from '../../Components/Calendar/Calendar';
import { dateFormat, register } from '../../Utilities/Utilities';
import Button from '../../Components/Button/Button';

const ReportingPeriodForm = ({
  initialReportingPeriod,
  handleReportingPeriodChange,
  isButtonDisabled,
  onSubmit,
}) => {
  const { startDate, endDate } = initialReportingPeriod;

  const renderCalendar = (label, value, name) => (
    <div className="L-reporting-period-box">
      <p className="G-title-p">{label}</p>
      <Calendar
        value={dateFormat(value)}
        changeHandler={(date) => handleReportingPeriodChange(name, date)}
        name={name}
        openToDate={new Date(value)}
      />
    </div>
  );

  return (
    <div className="L-company-taxation-system-form">
      <div className="taxation-form-section">
        <p className="G-title-p">Հաշվետու ժամանակաշրջան</p>
        <div className="taxation-form-date-flex">
          {renderCalendar('Սկիզբ', startDate, 'startDate')}
          {renderCalendar('Վերջ', endDate, 'endDate')}
        </div>
        <div className="G-company-taxationSystem-form-actions">
          <Button
            customClass={!isButtonDisabled ? 'G-disabled-button' : ''}
            disabled={!isButtonDisabled}
            text={register}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportingPeriodForm;
