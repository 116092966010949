import { useState, useEffect } from 'react';

function useIsMounted() {
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  return isMounted;
}

export default useIsMounted;
