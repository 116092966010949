import React from 'react';
import './style.scss';
import InputTitle from '../Input/InputTitle';
const Textarea = ({
  //placeholder = 'Մուտքագրեք տեքստ...',
  value,
  onChange,
  rows = 3,
  cols = 60,
  disabled = false,
  maxLength = 100,
  title,
  name,
}) => {
  return (
    <>
      <InputTitle inputTitle={title} />
      <textarea
        className="G-textarea"
        name={name}
        // placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows={rows}
        cols={cols}
        disabled={disabled}
        maxLength={maxLength}
      />
    </>
  );
};

export default Textarea;
