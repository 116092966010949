import React from 'react';
import Modal from '../../App/Components/Modal/Modal';
import './CalendarFilteredData.scss';
import Loading from '../../App/Components/Loading/Loading';
import { FormattedDateHy } from '../FormattedDateHy';
import { ExportExcel } from '../../App/Components/Excelexport/Excelexport';
import ConvertDataExcel from './ConvertDataExcel';

const CalendarFilteredData = ({ filteredData, closeModal, loading }) => {
  const handleClose = () => closeModal(false);
  if (loading) {
    return <Loading />;
  }

  const dataForExcel = ConvertDataExcel(filteredData);

  return (
    <Modal
      customClass="calendar-filtered-data-block"
      closeHandler={handleClose}
      onDrag={true}
    >
      {filteredData.length >= 1 ? (
        <div className="calendar-filtered-data-content">
          <ExportExcel excelData={dataForExcel} fileName="Օրացույց" />

          {filteredData.map(({ title, date, id, description }) => {
            return (
              <div className="filtered-data-box" key={id}>
                <strong>{FormattedDateHy(date)}</strong>
                <h3>{title}</h3>

                <p>{description}</p>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="G-flex-justify-center"> Տվյալներ չկան</p>
      )}
    </Modal>
  );
};

export default CalendarFilteredData;
