import React from 'react';
import './style.scss';
import Checkbox from '../Checkbox/Checkbox';

const RegisterCalculationInReport = ({
  values,
  setFieldValue,
  defValue = null,
}) => {
  const isIncludesAccount = defValue ? defValue : values?.correspAccount;
  return (
    <>
      {values.partnerID && isIncludesAccount?.includes('228') ? (
        <div className="G-register-calculationIn-report-block">
          <Checkbox
            checked={values.cashSign}
            label="Գրանցել առհաշիվ գումարների ծախսման մասին հաշվետվության մեջ"
            name="cashSign"
            value={values.cashSign}
            onChange={() => setFieldValue('cashSign', !values.cashSign)}
          />
        </div>
      ) : null}
    </>
  );
};

export default RegisterCalculationInReport;
