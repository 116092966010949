export const ConvertDataExcel = (data) => {
  return (
    data !== null &&
    data?.length > 0 &&
    data?.map((i) => {
      return {
        Հաշիվ: i.accountCode + ' ' + i.accountName,
        'Դեբետային մնացորդ': i.balanceAmountDebit,
        'Կրեդիտային մնացորդ': i.balanceAmountCredit,
        'Դեբետ AMD': i.balanceAmountDebitAMD,
        'Կրեդիտ AMD': i.balanceAmountCreditAMD,
        Արժույթ: i.currencyCode,
      };
    })
  );
};

export default ConvertDataExcel;
