import React from 'react';
import './style.css';
import MainLogo from '../MainLogo/MainLogo';

const Header = ({ pageTitle }) => {
  return (
    <header className="header">
      <div className="header-block">
        <MainLogo />
        <div className="page-title">
          <h3>{pageTitle}</h3>
        </div>
      </div>
    </header>
  );
};

export default Header;
