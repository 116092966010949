import React, { useState } from 'react';
import { getRequest } from '../../Api/Api';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../Components/Header/Header';
import {
  dataTimeFormat,
  initialDate,
  MAIN_URL,
  printHandler,
} from '../../Utilities/Utilities';
import AccountStatementAction from './AccountStatementAction';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import AccountStatementTable from './AccountStatementTable';
import TAccountBalance from '../../Components/TAccountBalance/TAccountBalance';
import './index.scss';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';

const GetAccountStatement = () => {
  const { currentCompanyID, reportStartDate } = useSelector(
    (state) => state.auth,
  );
  const [accountStatementData, setAccountStatementData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateStart, setDateStart] = useState(new Date(reportStartDate));
  const [dateEnd, setDateEnd] = useState(initialDate());
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
  const { data } = useFetchCurrency();
  const [accountVal, setAccountVal] = useState({
    label: '',
    value: '',
    id: '',
  });
  const [currencyVal, setCurrencyVal] = useState({
    label: 'AMD',
    value: 'AMD',
    id: 0,
  });
  const dispatch = useDispatch();
  const generalUrl = `companyID=${currentCompanyID}&account=${
    accountVal.value
  }&currencyID=${
    currencyVal.id === 0 ? data?.amdCurrencyID : currencyVal.id
  }&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}`;

  const getData = () => {
    setLoading(true);
    getRequest(`gettaccountstatement?${generalUrl}`)
      .then((res) => {
        setAccountStatementData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePrint = () => {
    printHandler(`printtaccountstatement?${generalUrl}`, dispatch);
  };
  const executeHandler = () => {
    getData();
  };

  return (
    <>
      <Header pageTitle="T – Հաշիվ (քաղվածք հաշվից)" headerBtn={false} />
      <SearchBackground>
        <AccountStatementAction
          printHandler={handlePrint}
          executeHandler={executeHandler}
          accountVal={accountVal}
          setAccountVal={setAccountVal}
          dateStart={dateStart}
          setDateStart={setDateStart}
          setDateEnd={setDateEnd}
          dateEnd={dateEnd}
          setAccountStatementData={setAccountStatementData}
          currencyVal={currencyVal}
          setCurrencyVal={setCurrencyVal}
          accountStatementData={accountStatementData?.entries}
        />
      </SearchBackground>
      <TAccountBalance mainData={accountStatementData}>
        <AccountStatementTable
          loading={loading}
          mainData={accountStatementData?.entries}
        />
      </TAccountBalance>
    </>
  );
};

export default GetAccountStatement;
