import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import Pagination from '../../Components/Pagination/Pagination';
import {
  getPurchaseAddFormAction,
  getPurchaseDataActAction,
  loadingAction,
  updatePartnerAction,
} from '../../Redux/purchaseActSlices/useAction';
import {
  correctDate,
  dataTimeFormat,
  inCorrectDateMess,
  initialDate,
} from '../../Utilities/Utilities';
import PurchaseActTable from './PurchaseActTable';
import CalendarTime from '../../Components/Calendar/CalendarTime';
import './purchase.scss';
import AddPurchaseWrapper from './AddPurchaseWrapper';
import SelectPartner from '../../Components/SelectBox/SelectPartner';
import AddNew from '../../Elements/Add/AddNew';
import PartnerForm from '../../Pages/Partners/EventAddForm';
import Alert from '../../Components/Alert';
import { useMemo } from 'react';

const GetPurchaseActData = () => {
  const { currentCompanyID, reportStartDate } = useSelector(
    (state) => state.auth,
  );
  const { purchaseData, isLoading } = useSelector(
    (state) => state.getPurchaseActData,
  );
  const { currentId } = useSelector((state) => state.filteredId);
  const dispatch = useDispatch();

  const [dateStart, setDateStart] = useState(new Date(reportStartDate));
  const [dateEnd, setDateEnd] = useState(initialDate);
  const [pageNumber, setPageNumber] = useState(0);
  const [partnerInfo, setPartnerInfo] = useState({});
  const [partnerVal, setPartnerVal] = useState({
    label: '',
    value: '',
    id: '',
  });
  const [showPartnerForm, setShowPartnerForm] = useState(false);
  const [openPurchaseAddWrapper, setOpenPurchaseAddWrapper] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');

  const dateStartFormat = useMemo(() => dataTimeFormat(dateStart), [dateStart]);
  const dateEndFormat = useMemo(() => dataTimeFormat(dateEnd), [dateEnd]);

  const buildPurchaseDataUrl = () => {
    return `getPurchases?companyID=${currentCompanyID}&pageNumber=${
      pageNumber + 1
    }&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}&documentID=${currentId}`;
  };

  const getData = () => {
    dispatch(loadingAction(true));
    const purchaseUrl = buildPurchaseDataUrl();

    getRequest(purchaseUrl)
      .then((res) => {
        dispatch(getPurchaseDataActAction(res.data));
      })
      .catch((err) => {
        setErrorHandler('An error occurred: ' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, dateStartFormat, dateEndFormat, currentId]);

  useEffect(() => {
    const partnerID = partnerVal.id || partnerInfo.partnerID;
    if (partnerID) {
      getRequest(`getGoods?partnerID=${partnerID}&purchaseID=0`)
        .then((res) => {
          dispatch(getPurchaseAddFormAction(res.data));
          setOpenPurchaseAddWrapper(true);
        })
        .catch((err) => {
          setErrorHandler('An error occurred: ' + err);
        });
    } else {
      setOpenPurchaseAddWrapper(false);
    }
  }, [partnerInfo.partnerID, partnerVal.id]);

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      setErrorHandler(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };

  const onChangePartner = (value) => {
    if (!value) {
      value = { value: '', id: 0 };
    }
    setPartnerVal(value);
  };

  const openPartnerAddForm = () => {
    setShowPartnerForm(true);
  };

  return (
    <>
      <Header pageTitle="Գնման ակտ" headerBtn={false} />
      <SearchBackground>
        <div className="G-time-partner-flex G-flex">
          <CalendarTime
            dateStart={dateStart}
            setDateStart={setDateStart}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
            onChangeHandler={timeChangeHandler}
          />

          <div className="G-flex-align-center G-purchase-act-partner-flex">
            <SelectPartner
              title="Հաշվետու անձ"
              partnerData={purchaseData?.partners}
              partnerVal={partnerVal}
              selectChangePartner={onChangePartner}
              requiredFiled={true}
            />
            <AddNew onClick={openPartnerAddForm} />
          </div>
        </div>
      </SearchBackground>

      <PurchaseActTable
        mainData={purchaseData?.purchases}
        loading={isLoading}
        setErrorHandler={setErrorHandler}
      />

      <Pagination
        pageCount={purchaseData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />

      {openPurchaseAddWrapper && (
        <AddPurchaseWrapper
          modalClick={setOpenPurchaseAddWrapper}
          getData={getData}
          pageNumber={pageNumber}
          setErrorHandler={setErrorHandler}
          setPartnerVal={setPartnerVal}
        />
      )}

      {showPartnerForm && (
        <PartnerForm
          closeModal={setShowPartnerForm}
          updateUserOutside={true}
          updatePartnerAction={updatePartnerAction}
          setPartnerInfo={setPartnerInfo}
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default GetPurchaseActData;
