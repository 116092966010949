import axios from 'axios';
import { MAIN_URL } from '../Utilities/Utilities';
const user = JSON.parse(localStorage.getItem('persist:root'));
const accessToken = user?.token ? JSON?.parse(user?.token) : null;

const getRequest = (url, params) => {
  return request('GET', url, params);
};

const postRequest = (url, data, params) => {
  return request('POST', url, params, data);
};

const putRequest = (url, data, params) => {
  return request('PUT', url, params, data);
};

const deleteRequest = (url) => {
  return request('DELETE', url);
};

const request = (method, url, params, data) => {
  const finalUrl = `${MAIN_URL}/${url}`;
  let options = {
    url: finalUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method,
    params,
    data,
    // cancelToken: this.source.token
  };

  return axios(options);
};

export { deleteRequest, putRequest, postRequest, getRequest };
