export const GET_SPENDING_MONEY_DATA = "GET_SPENDING_MONEY_DATA";
export const DELETE_SPENDING_MONEY = "DELETE_SPENDING_MONEY";
export const LOADING = "LOADING";
export const GET_SPENDING_DEALS_DATA = "GET_SPENDING_DEALS_DATA";
export const UPDATE_CASH_MONEY_DATA = "UPDATE_CASH_MONEY_DATA";
export const ADD_SPENDING_MONEY_DEALS_DATA = "ADD_SPENDING_MONEY_DEALS_DATA";
export const EDIT_SPENDING_MONEY_DEALS_DATA = "EDIT_SPENDING_MONEY_DEALS_DATA";
export const DELETE_SPENDING_MONEY_DEALS_DATA =
  "DELETE_SPENDING_MONEY_DEALS_DATA";
export const UPDATE_PARTNER_SPENDING_MONEY = "UPDATE_PARTNER_SPENDING_MONEY";
export const IS_CLOSE_DOCUMENT = 'IS_CLOSE_DOCUMENT';
export const UPDATE_ACCOUNT_MONEY_FORM = 'UPDATE_ACCOUNT_MONEY_FORM';
