import React from 'react';
import { dateFormat } from '../../../App/Utilities/Utilities';

const EInvoiceEntryHeader = ({ details }) => {
  const { partnerName, serialNumber, invoiceDate } = details || {};

  const executionDataArr = [
    { label: 'Գործընկերը', value: partnerName },
    { label: 'Հաշիվ ապրանքագրի սերիա, համար', value: serialNumber },
    {
      label: 'Հաշիվ ապրանքագրի ստացման ամսաթիվը',
      value: dateFormat(invoiceDate),
    },
  ];

  return (
    <div className="L-EInvoice-entry-form-header">
      <ul className="list">
        {executionDataArr.map((item, index) => (
          <li key={index} className="G-flex list-item">
            <strong className="label">{item.label}</strong>
            <span className="value">{item.value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EInvoiceEntryHeader;
