import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { putRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import { editServicesAction } from '../../Redux/serviceWork/useAction';
import ServicesWorkForm from './ServicesWorkForm';

const EventEditForm = ({ rowData, closeModal }) => {
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    putRequest('editservicename', values)
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(editServicesAction(values));
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <ServicesWorkForm
        onSubmit={onSubmit}
        initialData={rowData}
        closeModal={handleClose}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventEditForm;
