import React from "react";
import CalendarTime from "../../Components/Calendar/CalendarTime";
import SelectPartnerType from "../../Components/SelectBox/SelectPartnerType";
import Button from "../../Components/Button/Button";
import { correctDate, inCorrectDateMess } from '../../Utilities/Utilities';
import { ExportExcel } from '../../Components/Excelexport/Excelexport';

const SettlementDocumentAction = (props) => {
  const {
    dateStart,
    setDateStart,
    dateEnd,
    setDateEnd,
    setPartnerTypeVal,
    partnerTypeVal,
    viewPrintHandler,
    dataForExcel,
  } = props;

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      alert(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };

  const selectChangePartner = (values) => {
    if (!values) {
      values = {
        value: '',
        id: 0,
      };
    }
    setPartnerTypeVal(values);
  };

  return (
    <div className="G-justify-between-align-center">
      <CalendarTime
        dateStart={dateStart}
        setDateStart={setDateStart}
        dateEnd={dateEnd}
        setDateEnd={setDateEnd}
        onChangeHandler={timeChangeHandler}
      />

      <SelectPartnerType
        partnerTypeVal={partnerTypeVal}
        selectChangePartner={(values) => selectChangePartner(values)}
      />

      <div className="G-print-excel-block">
        <div className="G-view-btn">
          <Button text="Դիտել / տպել" onClick={viewPrintHandler} />
        </div>
        <ExportExcel excelData={dataForExcel} />
      </div>
    </div>
  );
};

export default SettlementDocumentAction;
