import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import useWorkCalendar from '../../hooks/useWorkCalendar';
import Button from '../../../App/Components/Button/Button';
import { useEffect } from 'react';

const WorkCalendarTable = ({ graphNo, handleClose, mainData }) => {
  const { addNewWorkCalendar } = useWorkCalendar(graphNo);
  const [updatedMainData, setUpdatedMainData] = useState(mainData);

  useEffect(() => {
    setUpdatedMainData(mainData);
  }, [mainData]);

  const handleChange = (e, month) => {
    const { value } = e.target;

    const updatedData = updatedMainData.map((item) => {
      if (item.month === month) {
        const updatedItem = { ...item, workDays: Number(value) };
        updatedItem.workHours = updatedItem.workDays * item.hours;
        return updatedItem;
      }
      return item;
    });

    setUpdatedMainData(updatedData.map((item) => ({ ...item })));
  };

  const columnConfig = [
    {
      title: 'Ամիս',
      cell: (row) => row.month,
      customStyle: { maxWidth: 60 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.montName,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Աշխ. օրեր',
      cell: (row) => (
        <input
          type="text"
          value={row.workDays}
          onChange={(e) => handleChange(e, row.month)}
          maxLength={2}
        />
      ),
      isEdit: true,
      customStyle: { maxWidth: 160 },
    },
    {
      title: 'Աշխ. ժամեր',
      cell: (row) => row.workHours,
      customStyle: { maxWidth: 100 },
    },
  ];
  const onSaveHandler = async () => {
    try {
      await addNewWorkCalendar(updatedMainData, handleClose);
    } catch (error) {}
  };

  return (
    <>
      {updatedMainData !== null &&
      updatedMainData &&
      updatedMainData?.length ? (
        <Table
          customClass="G-table-has-scroll"
          data={updatedMainData}
          columnConfig={columnConfig}
        />
      ) : null}
      <Button
        onClick={onSaveHandler}
        //disabled={!workCalendarData?.isCreated}
        text="Հաստատել"
        type="submit"
        //customClass={!workCalendarData?.isCreated && 'G-disabled-button'}
      />
    </>
  );
};

export default WorkCalendarTable;
