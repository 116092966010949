import React from "react";
import Button from "../Button/Button";
import "./style.scss";

const GetSentDataBtn = (props) => {
  const {
    hasCancelPrintBtn = true,
    cancelPrintClickText = 'Ընդհատել',
    cancelPrintCustomClass = '',
    cancelPrintClick,
    hasConfirmExecuteBtn = true,
    confirmExecuteText = 'Հաստատել',
    confirmExecuteCustomClass = '',
    confirmExecuteClick,
    disabled,
    customClass = '',
    customClassForBlock = '',
    executeClass = '',
    executeDisabled,
  } = props;

  return (
    <div className={`G-cancel-print-btn-block ${customClassForBlock}`}>
      {hasCancelPrintBtn ? (
        <div
          className={`G-cancel-print-btn G-btn-style ${cancelPrintCustomClass}`}
        >
          <Button
            customClass={customClass}
            disabled={disabled}
            type="button"
            text={cancelPrintClickText}
            onClick={cancelPrintClick}
          />
        </div>
      ) : (
        ''
      )}
      {hasConfirmExecuteBtn ? (
        <div
          className={`G-confirm-execute-btn G-btn-style ${confirmExecuteCustomClass}`}
        >
          <Button
            disabled={executeDisabled}
            customClass={executeClass}
            text={confirmExecuteText}
            onClick={confirmExecuteClick}
            type="submit"
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default GetSentDataBtn;
