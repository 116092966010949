import React, { useState } from 'react';
import Calendar from '../../../Components/Calendar/Calendar';
import DashboardTitle from '../DashboardTitle';
import Seducement from '../../../Assets/icons/resources.svg';
import Button from '../../../Components/Button/Button';
import CashRegisters from './CashRegisters';
import Banks from './Banks';

const FinancialMeans = (props) => {
  const { dateStart, data, setDateStart } = props;
  const [toggleTab, setToggleTab] = useState('registers');

  const toggleTabs = (name) => {
    setToggleTab(name);
  };

  const selectTab = () => {
    switch (toggleTab) {
      case 'registers':
        return <CashRegisters data={data} />;
      case 'banks':
        return <Banks data={data} />;
      default:
        return '';
    }
  };

  return (
    <div className="L-financial-means G-block-style">
      <div className="G-flex">
        <DashboardTitle blockTitle="Իմ դրամական միջոցները" icon={Seducement} />
        <div className="G-dashboard-calendar">
          <Calendar
            selected={dateStart}
            name="dateStart"
            changeHandler={(e) => setDateStart(e)}
            maxDate={new Date()}
            removeMinDate={true}
          />
        </div>
      </div>
      <div className="L-financial-means-content">
        <div className="L-financial-means-tabs G-flex">
          <div
            className={`L-tab-wrapper ${
              toggleTab === 'registers' ? ' L-tab-wrapper' : ' L-active-tab'
            }`}
            onClick={() => toggleTabs('registers')}
          >
            <Button text="Դրամարկղ" />
          </div>
          <div
            className={`L-tab-wrapper ${
              toggleTab === 'banks' ? ' L-tab-wrapper' : ' L-active-tab'
            }`}
            onClick={() => toggleTabs('banks')}
          >
            <Button text="Բանկ" />
          </div>
        </div>
        {selectTab()}
      </div>
    </div>
  );
};

export default FinancialMeans;
