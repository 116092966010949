import React from 'react';
import AcceptOrCancelModal from '../../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
const DeleteEntryOrderFormation = ({
  invGoodID,
  deleteEntryOrder,
  handleClose,
}) => {
  const onRemoveHandler = async () => {
    try {
      await deleteEntryOrder(invGoodID, handleClose);
      handleClose();
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };
  return (
    <AcceptOrCancelModal
      closeModal={handleClose}
      confirmClick={onRemoveHandler}
    />
  );
};

export default DeleteEntryOrderFormation;
