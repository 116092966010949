import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRequest } from "../Api/Api";

export const useFetchBalanceDate = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [balanceDate, setBalanceDate] = useState([]);

  useEffect(() => {
    getRequest(`getPeriod/${currentCompanyID}`)
      .then((res) => {
        setBalanceDate(res.data);
      })
      .catch((err) => {
        console.log("Err" + err);
      });
  }, [currentCompanyID]);

  return [balanceDate];
};
