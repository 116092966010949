import React, { useState } from 'react';
import { deleteRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../Components/Alert/Alert';

const DeleteHistoryTaxationSystem = ({
  taxSystemDateID,
  closeModal,
  generalData,
  setGeneralData,
}) => {
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = () => closeModal(false);
  const removeHandler = () => {
    deleteRequest(`deleteTaxSystemDate/${taxSystemDateID}`).then((res) => {
      if (res.data.errorCode === 0) {
        const newList = generalData.filter(
          (item) => item.taxSystemDateID !== taxSystemDateID,
        );
        setGeneralData(newList);
        handleClose();
      }
      if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={closeModal}
        confirmClick={removeHandler}
        modalContent="Համոզվա՞ծ եք որ ուզում եք ջնջել այն"
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default DeleteHistoryTaxationSystem;
