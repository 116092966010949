import {
  DELETE_ORGANIZATION_BANK,
  EDIT_ORGANIZATION,
  EDIT_ORGANIZATION_BANK,
  GET_ORGANIZATION,
  GET_ORGANIZATION_BANK,
  LOADING,
} from './constants';

const initialState = {
  organizationData: null,
  organizationBankData: null,
  isLoading: false,
};

export const organizationReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case GET_ORGANIZATION:
      return {
        ...state,
        organizationData: payload,
        isLoading: true,
      };
    case EDIT_ORGANIZATION:
      return {
        ...state,
        organizationData: {
          ...state.organizationData,
          company: {
            ...state.organizationData.company,
            ...payload,
          },
        },
      };

    case EDIT_ORGANIZATION_BANK:
      return {
        ...state,
        organizationBankData: {
          ...state.organizationBankData,
          companyBanks: state.organizationBankData.companyBanks.map((bank) => {
            return bank.companyBankID === payload.companyBankID
              ? payload
              : bank;
          }),
        },
      };

    case DELETE_ORGANIZATION_BANK:
      return {
        ...state,
        organizationBankData: {
          ...state.organizationBankData,
          companyBanks: state.organizationBankData?.companyBanks.filter(
            (item) => item.companyBankID !== payload,
          ),
        },
      };

    case GET_ORGANIZATION_BANK:
      return {
        ...state,
        organizationBankData: payload,
        isLoading: true,
      };

    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return state;
  }
};
