import { createAction } from "../actionCreators";
import {
  IS_CHECK_ALL_PRINT_SING,
  IS_CHECK_SINGLE_PRINT_SING,
  GET_CIRCULATION_BULLETIN_DATA,
  GET_PARTNER__BULLETIN_VIEW_DATA,
  LOADING,
} from "./constants";

export const getCirculationBulletinAction = createAction(
  GET_CIRCULATION_BULLETIN_DATA
);
export const loadingAction = createAction(LOADING, false);
export const checkAllPrintSignAction = createAction(IS_CHECK_ALL_PRINT_SING);
export const checkSinglePrintSignAction = createAction(
  IS_CHECK_SINGLE_PRINT_SING
);
export const getPartnerNewsletterViewAction = createAction(
  GET_PARTNER__BULLETIN_VIEW_DATA
);
