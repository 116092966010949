import React, { useState } from "react";
import Table from "../../../Components/Table/Table";
import { Commas, dateFormat } from '../../../Utilities/Utilities';
import DeleteEInvoicingOperations from "./DeleteEInvoicingOperations";

const OperationsTable = ({ mainData, setOperationsData }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Փաստաթղթի Տեսակը',
      cell: (row) => row.docTypeName,
      customStyle: { maxWidth: 300 },
    },
    {
      title: 'Փաստաթուղթ N',
      cell: (row) => row.docNum,
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 600, overflow: 'hidden' },
    },
    {
      title: 'Դեբետ',
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գումար',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 100 },
    },

    {
      title: 'Գործողություն',
      cell: (row) => (
        <span
          className="G-delete-span"
          onClick={() => setDeleteId(row.entryID)}
        >
          Ջնջել
        </span>
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  const setDeleteId = (entryID) => {
    setOpenDeleteModal(entryID);
  };

  return (
    <>
      {mainData !== null &&
      mainData &&
      mainData?.excelInvoiceEntries?.length ? (
        <Table
          customClass=""
          data={mainData?.excelInvoiceEntries}
          columnConfig={columnConfig}
        />
      ) : (
        ""
      )}

      {openDeleteModal && (
        <DeleteEInvoicingOperations
          closeModal={setOpenDeleteModal}
          deleteRowId={openDeleteModal}
          operationData={mainData}
          setOperationsData={setOperationsData}
        />
      )}
    </>
  );
};

export default OperationsTable;
