import React from "react";
import Button from '../../Components/Button/Button';

const EInvoicingFileUploader = (props) => {
  const {
    fileUpload,
    filename,
    setFilename,
    setSelectedFile,
    disableButton,
    setDisableButton,
  } = props;

  const handleFileInput = (e) => {
    if (e.target.files.length === 1) {
      setSelectedFile(e.target.files[0]);
      setFilename(e.target.files[0].name);
      setDisableButton(false);
    }
  };

  return (
    <div className="L-invoicing-upload-file-block">
      <div className="L-invoicing-file-uploader-input">
        <div className="L-custom-label" htmlForm="customFile">
          {filename}
        </div>
        <label htmlFor="customFile" className="L-upload-label">
          <input
            type="file"
            id="customFile"
            accept=".xls, .xlsx"
            onChange={handleFileInput}
            className="L-uploader-input"
          />
        </label>
      </div>

      <div className="L-invoicing-btn">
        <Button
          type="submit"
          text="Հաստատել"
          onClick={fileUpload}
          disabled={disableButton}
          customClass={disableButton ? 'G-disabled-button' : ''}
        />
      </div>
      <h3 className="invoicing-vers">Նոր տարբերակ</h3>
    </div>
  );
};

export default EInvoicingFileUploader;
