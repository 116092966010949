import React, { useState } from 'react';
import { SelectControl } from '../../Components/SelectControl/SelectControl';
import Calendar from '../../Components/Calendar/Calendar';
import { dateFormat, register } from '../../Utilities/Utilities';
import HistoryTaxationSystem from '../HistoryTaxationSystem/HistoryTaxationSystem';
import Button from '../../Components/Button/Button';

const TaxationSystemForm = ({
  taxationSystemOptions,
  initialTaxSystemData,
  isButtonDisabled,
  onSubmit,
  handleTaxationChange,
  handleTaxationDate,
}) => {
  const { taxationSystemName, taxationDate } = initialTaxSystemData;
  const [showHistory, setShowHistory] = useState(false);

  const handleHistoryToggle = () => setShowHistory((prev) => !prev);

  return (
    <div className="L-company-taxation-system-form">
      <div className="taxation-form-section">
        <SelectControl
          isClearable={false}
          selectBoxTitle="Հարկման համակարգը"
          options={taxationSystemOptions}
          value={{
            label: taxationSystemName,
            value: taxationSystemName,
          }}
          onChange={(options) => handleTaxationChange(options)}
          requiredFiled={true}
        />

        <div className="L-taxation-system-history">
          <div className="L-taxation-system-date">
            <p className="G-title-p">Փոփոխման ամսաթիվը</p>
            <Calendar
              value={dateFormat(taxationDate)}
              changeHandler={(date) => handleTaxationDate(date)}
              name="taxationDate"
              openToDate={new Date(taxationDate)}
            />
          </div>
          <p className="L-taxation-history-link" onClick={handleHistoryToggle}>
            Պատմությունը
          </p>
        </div>

        <div className="G-company-taxationSystem-form-actions">
          <Button
            text={register}
            customClass={!isButtonDisabled ? 'G-disabled-button' : ''}
            disabled={!isButtonDisabled}
            onClick={onSubmit}
          />
        </div>
      </div>

      {showHistory && (
        <HistoryTaxationSystem closeModal={handleHistoryToggle} />
      )}
    </div>
  );
};

export default TaxationSystemForm;
