import {
  IS_CHECK_ALL_PRINT_SING,
  IS_CHECK_SINGLE_PRINT_SING,
  GET_CIRCULATION_BULLETIN_DATA,
  GET_PARTNER__BULLETIN_VIEW_DATA,
  LOADING,
} from "./constants";

const initialState = {
  partnerBulletinData: null,
  isLoading: false,
  isCheckAll: false,
  partnerSummaryData: null,
};

export const partnerCirculationBulletinReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_CIRCULATION_BULLETIN_DATA:
      return {
        ...state,
        partnerBulletinData: payload,
        isLoading: true,
      };

    case GET_PARTNER__BULLETIN_VIEW_DATA:
      return {
        ...state,
        partnerBulletinView: payload,
      };

    case IS_CHECK_ALL_PRINT_SING:
      return {
        ...state,
        isCheckAll: payload,
        ...state.partnerBulletinData,
        partnerBulletinData: [
          ...state.partnerBulletinData?.map((item) => {
            return { ...item, printSign: payload };
          }),
        ],
      };

    case IS_CHECK_SINGLE_PRINT_SING:
      return {
        ...state,
        ...state.partnerBulletinData,
        partnerBulletinData: [
          ...state.partnerBulletinData?.map((item) => {
            if (item.partnerID === payload.partnerID) {
              return { ...item, printSign: !payload.printSign };
            } else {
              return item;
            }
          }),
        ],
      };

    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return { ...state };
  }
};
