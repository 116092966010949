import React from 'react';
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';
import { Commas } from '../../Utilities/Utilities';

const PartnerBalancesTable = ({ mainData, loading }) => {
  const columnConfig = [
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountCode,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.accountName,
      customStyle: { maxWidth: 800, overflow: 'hidden' },
    },
    {
      title: 'Դեբետ AMD',
      cell: (row) => Commas(row.balanceDebitAMD),
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Կրեդիտ AMD',
      cell: (row) => Commas(row.balanceCreditAMD),
      customStyle: { maxWidth: 120 },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table customClass="" data={mainData} columnConfig={columnConfig} />
      ) : (
        ''
      )}
    </>
  );
};

export default PartnerBalancesTable;
