import React from 'react';
import Loading from '../../../Components/Loading/Loading';
import Table from '../../../Components/Table/Table';
import { Commas, dateFormat } from '../../../Utilities/Utilities';

const ClosedReportingTable = ({ loading, mainData }) => {
  const columnConfig = [
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 80 },
    },

    {
      title: 'Դեբետ',
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.amount),
      customStyle: { justifyContent: 'end' },
    },
  ];

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-closed-reporting-table G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default ClosedReportingTable;
