import { useSelector } from 'react-redux';
import { fetchSalaryTypeCoefficient } from '../services/fetchMakeAnAddition';

const useSalaryTypeCoefficient = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);

  const getSalaryTypeCoefficient = async (param) => {
    try {
      const response = await fetchSalaryTypeCoefficient(
        currentCompanyID,
        param,
      );
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return {
    getSalaryTypeCoefficient,
  };
};
export default useSalaryTypeCoefficient;
