import React from 'react';
import ProductForm from './ProductForm';
import { removeCommas } from '../../../../App/Utilities/Utilities';
import { editProductAction } from '../../../../App/Redux/materialValuesStor/warehouseEntrySlices/useAction';
import { useDispatch } from 'react-redux';

const EventEditForm = ({ handleClose, currentProductData }) => {
  const dispatch = useDispatch();
  const onUpdateHandler = (values) => {
    const formattedValues = {
      ...values,
      purPrice: removeCommas(values.purPrice),
      price: removeCommas(values.price),
      amount: removeCommas(values.amount),
      sign: true,
      editing: true,
    };
    dispatch(
      editProductAction({ inputs: formattedValues, isTableUpdate: true }),
    );

    handleClose();
  };

  return (
    <>
      <ProductForm
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
        initValues={currentProductData}
      />
    </>
  );
};

export default EventEditForm;
