import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteRequest, postRequest } from '../../App/Api/Api';
import CalendarType from '../CalendarType/CalendarType';
import './style.scss';

const OtherCalendars = ({ fetchCalendarData, fetchCurrentDayData }) => {
  const { calendarData } = useSelector((state) => state.getCalendarData);
  const { otherCalendarType } = calendarData || {};
  const { userID } = useSelector((state) => state.auth);

  const [selectCalInfo, setSelectCalInfo] = useState(false);
  const [inputIsChecked, setInputIsChecked] = useState({});

  useEffect(() => {
    const updateCalendar = async () => {
      if (inputIsChecked.calId) {
        let url = `deleteCalendar?userId=${userID}&calId=${inputIsChecked.calId}`;
        const requestData = {};

        if (!inputIsChecked.isChecked) {
          url = `addCalendar?userId=${userID}&calId=${inputIsChecked.calId}`;
        }

        try {
          const response = await (inputIsChecked.isChecked
            ? deleteRequest(url, requestData)
            : postRequest(url, requestData));

          const { errorCode } = response.data;
          if (errorCode === 0) {
            fetchCalendarData();
            fetchCurrentDayData();
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    updateCalendar();
  }, [
    inputIsChecked.calId,
    inputIsChecked.isChecked,
    userID,
    fetchCalendarData,
  ]);

  const onChangeCalendar = (data) => {
    setInputIsChecked(data);
  };

  return (
    <div className="other-calendar-wrap">
      <h3 className="calendar-title" style={{ marginLeft: '8px' }}>
        Օրացույցներ
      </h3>
      <div className="other-calendar-block">
        {otherCalendarType &&
          otherCalendarType?.map((i) => {
            return (
              <div className="L-calendar-checkbox-wrap G-flex" key={i.calId}>
                <label>
                  <input
                    type="checkbox"
                    value={i.calId}
                    checked={i.isChecked}
                    onChange={() => onChangeCalendar(i)}
                  />
                </label>
                <span
                  className="L-other-calendar-type"
                  onClick={() => setSelectCalInfo(i)}
                >
                  {i.calenarType}
                </span>
              </div>
            );
          })}

        {selectCalInfo && (
          <CalendarType
            calendarInfo={selectCalInfo}
            closeModal={setSelectCalInfo}
          />
        )}
      </div>
    </div>
  );
};;;;;;

export default OtherCalendars;
