import React from 'react';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
import MaterialNameForm from './MaterialNameForm';
import useMaterialValuesName from '../../hooks/useMaterialValuesName';

const EventEditForm = ({ handleClose, matValueID }) => {
  const { updateMaterialName, errorMess, setErrorMess } =
    useMaterialValuesName();
  const isMounted = useIsMounted();
  const onUpdateHandler = async (values) => {
    try {
      await updateMaterialName(values, handleClose);
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <MaterialNameForm
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
        matValueID={matValueID}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventEditForm;
