import React from 'react';
import './style.scss';
import Modal from '../../../Components/Modal/Modal';
import DayNotifications from '../../../../FullCalendar/DayNotifications/DayNotifications';

const NotificationForm = ({ closeModal, notificationData = [] }) => {
  const handleClose = () => closeModal(false);
  return (
    <Modal closeHandler={handleClose} customClass="L-notification-modal">
      <DayNotifications customClass="" notiData={notificationData} />
    </Modal>
  );
};

export default NotificationForm;
