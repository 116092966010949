import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import EventAddForm from './EventAddForm';
import './style.scss';
import WarehouseTable from './WarehouseTable';
import WarehouseFilter from './WarehouseFilter';
import useWarehouse from '../../hooks/useWarehouse';
import { ConvertWarehouseExcel } from '../../Config/excelConfig';

const Warehouse = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [param, setParam] = useState('');
  const { getWarehouse, warehouse, isLoading } = useWarehouse();
  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };
  useEffect(() => {
    getWarehouse(param);
  }, [param]);

  const dataToExcel = ConvertWarehouseExcel(warehouse?.stores);
  return (
    <div className="G-small-wrap">
      <Header pageTitle="Պահեստներ" handelClick={onClose} />
      <WarehouseFilter
        param={param}
        setParam={setParam}
        dataToExcel={dataToExcel}
      />
      <WarehouseTable warehouseData={warehouse?.stores} isLoading={isLoading} />
      {showAddForm && <EventAddForm handleClose={onClose} param={param} />}
    </div>
  );
};

export default Warehouse;
