import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postRequest } from '../../Api/Api';
import PurchaseView from './PurchaseView';
import { updateIncomeTaxAction } from '../../Redux/purchaseActSlices/useAction';
import { TAXATION_ID } from './Constant';

const AddPurchaseWrapper = ({
  getData,
  pageNumber,
  modalClick,
  setErrorHandler,
  setPartnerVal,
}) => {
  const { purchaseFormData } = useSelector((state) => state.getPurchaseActData);
  const dispatch = useDispatch();
  const { purchase, goods } = useMemo(
    () => purchaseFormData || {},
    [purchaseFormData],
  );
  const [getPurchaseID, setGetPurchaseID] = useState(null);
  const [isCloseForm, setIsCloseForm] = useState(null);
  const disabledSaveDocBtn = useMemo(
    () => !(goods?.length > 0 && purchase?.accountCredit && purchase?.docBase),
    [goods, purchase],
  );

  const [showRegisterBtn, setShowRegisterBtn] = useState(false);

  const handleClose = () => {
    modalClick(false);
    setPartnerVal({ value: '', id: 0 });
  };

  const saveDocumentData = () => {
    const handleSuccess = (id) => {
      setErrorHandler('Փաստաթուղթը գրանցվեց');
      setGetPurchaseID(id);
      getData(pageNumber);
      setShowRegisterBtn(true);
      dispatch(updateIncomeTaxAction(true));
    };

    postRequest('addpurchase', {
      purchase: { ...purchase },
      goods: [...goods],
    })
      .then((res) => {
        const { errorCode, message, id } = res.data;
        if (errorCode === 0 && !TAXATION_ID?.includes(isCloseForm)) {
          handleSuccess(id);
          handleClose();
        }
        if (errorCode === 0 && TAXATION_ID?.includes(isCloseForm)) {
          handleSuccess(id);
        }

        if (errorCode > 0) {
          setErrorHandler(message);
        } else {
          setShowRegisterBtn(true);
          dispatch(updateIncomeTaxAction(true));
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <PurchaseView
      closeModal={handleClose}
      mainData={purchaseFormData}
      saveDocumentHandler={saveDocumentData}
      disabledSaveDocBtn={disabledSaveDocBtn}
      getPurchaseID={getPurchaseID}
      isDisabledRegBtn={showRegisterBtn}
      setIsCloseForm={setIsCloseForm}
    />
  );
};

export default AddPurchaseWrapper;
