import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSubdivision,
  deleteSubdivision,
  fetchSubdivisions,
  updateSubdivision,
} from '../services/fetchSubdivisions';
import {
  fetchSubDivisionAction,
  removeSubDivisionAction,
  updateSubDivisionAction,
} from '../../App/Redux/SalaryStor/subdivisionsSlices/useAction';

const useSubdivisions = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { subDivision } = useSelector((state) => state.subDivisionData);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const { currentCompanyID } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const fetchSubdivisionsData = async (query) => {
    try {
      setIsLoading(true);
      const response = await fetchSubdivisions(currentCompanyID, query);
      dispatch(fetchSubDivisionAction(response));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const addSubdivisionItem = async (
    subdivisionData,
    closeFormCallback,
    query = '',
  ) => {
    try {
      const { errorCode, message } = await addSubdivision(subdivisionData);

      if (errorCode === 0) {
        fetchSubdivisionsData(query);
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateSubdivisionItem = async (subdivisionData, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await updateSubdivision(subdivisionData);
      if (errorCode === 0) {
        dispatch(updateSubDivisionAction(subdivisionData));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeSubdivisionsItem = async (id, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await deleteSubdivision(id);
      if (errorCode === 0) {
        dispatch(removeSubDivisionAction(id));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    subDivision,
    isLoading,
    fetchSubdivisionsData,
    addSubdivisionItem,
    updateSubdivisionItem,
    removeSubdivisionsItem,
    error,
    errorMess,
    setErrorMess,
  };
};

export default useSubdivisions;
