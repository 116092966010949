import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  deletedPaymentList,
  fetchExecutePaymentList,
  fetchNewPaymentList,
  fetchNewPaymentListForm,
  fetchPaymentList,
  fetchPaymentListNumber,
  savePaymentList,
} from '../services/fetchBankPaymentLists';

const useBankPaymentLists = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [paymentList, setPaymentList] = useState(null);
  const [executePaymentList, setExecutePaymentList] = useState([]);
  const [newPaymentListForm, setNewPaymentListForm] = useState(null);
  const getPaymentList = async () => {
    setIsLoading(true);
    try {
      const data = await fetchPaymentList(currentCompanyID);
      setPaymentList(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExecutePaymentList = async (paymentNum) => {
    setIsLoading(true);
    try {
      const data = await fetchExecutePaymentList(currentCompanyID, paymentNum);
      setExecutePaymentList(data);
    } catch (error) {
      console.log('error', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getNewPaymentList = async (params) => {
    setIsLoading(true);
    try {
      const data = await fetchNewPaymentList(currentCompanyID, params);
      setExecutePaymentList(data);
    } catch (error) {
      console.log('error', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getNewPaymentListForm = async (bankCode) => {
    setIsLoading(true);
    try {
      const data = await fetchNewPaymentListForm(currentCompanyID, bankCode);
      setNewPaymentListForm(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const confirmNewPaymentList = async (
    newPaymentList,
    paymentNum,
    bankCode,
  ) => {
    try {
      const { errorCode, message, id } = await savePaymentList(newPaymentList);
      const num = paymentNum ? paymentNum : id;

      if (errorCode === 0) {
        getExecutePaymentList(num);
        getPaymentListNumber(bankCode);
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const removePaymentList = async (paymentNum, resetTableData) => {
    if (!paymentNum) {
      return;
    }
    setIsLoading(true);
    try {
      const { errorCode, message } = await deletedPaymentList(
        currentCompanyID,
        paymentNum,
      );

      if (errorCode === 0) {
        resetTableData();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPaymentListNumber = async (bankCode) => {
    setIsLoading(true);
    try {
      const data = await fetchPaymentListNumber(currentCompanyID, bankCode);

      if (data?.paymentNums) {
        setPaymentList((prevFormData) => ({
          ...prevFormData,
          paymentNums: [...data?.paymentNums],
        }));
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    getPaymentList,
    paymentList,
    getExecutePaymentList,
    executePaymentList,
    setExecutePaymentList,
    getNewPaymentListForm,
    newPaymentListForm,
    getPaymentListNumber,
    getNewPaymentList,
    confirmNewPaymentList,
    removePaymentList,
    error,
    isLoading,
    errorMess,
    setErrorMess,
  };
};

export default useBankPaymentLists;
