import React from 'react';

import Calendar from '../Calendar/Calendar';
import './style.scss';

const DateInterval = ({ dateStart, setDateStart, dateEnd, setDateEnd }) => {
  return (
    <div className="date-interval-block">
      <p className="date-title">Ժամանակահատված</p>
      <div className="date-interval-flex">
        <div className="date-start-end">
          <Calendar
            selected={dateStart}
            value={dateStart}
            onChange={(value) => setDateStart(value)}
          />
        </div>
        <div className="date-start-end">
          <Calendar
            selected={dateEnd}
            value={dateEnd}
            onChange={(value) => setDateEnd(value)}
          />
        </div>
      </div>
    </div>
  );
};
export default DateInterval;
