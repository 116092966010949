import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRequest, putRequest } from '../../../Api/Api';
import { editCashWarrantAction } from '../../../Redux/cashWarrantSlices/useAction';
import {
  dataTimeFormat,
  editAgreeText,
  removeCommas,
  typOfString,
} from '../../../Utilities/Utilities';
import CashWarrantForm from './CashWarrantForm';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';

const EventEditForm = ({ modalClick, rowData, setErrorHandler }) => {
  const [isAllowEdit, setIsAllowEdit] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => modalClick(false);

  useEffect(() => {
    async function checkAllowEdit() {
      try {
        const url = `preDeleteEditCashRegister?documentID=${rowData.documentID}`;
        const res = await getRequest(url);
        if (res.data.errorCode === 100) {
          setIsAllowEdit(true);
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    if (rowData.documentID) {
      checkAllowEdit();
    }
  }, [rowData.documentID]);

  const onSubmit = async (values) => {
    if (isAllowEdit) {
      return;
    }

    try {
      const formatDocDate = dataTimeFormat(values.docDate);
      const res = await putRequest('editCashRegister', {
        ...values,
        docDate: formatDocDate,
        docNum: typOfString(values.docNum),
        amount: removeCommas(values.amount),
        amountAMD: removeCommas(values.amountAMD),
      });

      if (res.data.errorCode === 0) {
        dispatch(editCashWarrantAction(values));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    } catch (err) {
      console.error('Error submitting form:', err);
    }
  };

  return (
    <>
      <CashWarrantForm
        handleClose={handleClose}
        initialValue={rowData}
        onSubmit={onSubmit}
        setErrorHandler={setErrorHandler}
      />
      {isAllowEdit && (
        <AcceptOrCancelModal
          closeModal={modalClick}
          confirmClick={() => setIsAllowEdit(false)}
          modalContent={editAgreeText}
        />
      )}
    </>
  );
};

export default EventEditForm;
