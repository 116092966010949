import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import CalendarTime from '../../Components/Calendar/CalendarTime';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import SelectAccount from '../../Components/SelectBox/SelectAccount';
import SelectCurrency from '../../Components/SelectBox/SelectCurrency';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import {
  correctDate,
  executeText,
  inCorrectDateMess,
  viewPrintTitle,
} from '../../Utilities/Utilities';
import ConvertDataExcel from './ConvertDataExcel';
import { ExportExcel } from '../../Components/Excelexport/Excelexport';

const AccountStatementAction = (props) => {
  const {
    dateStart,
    setDateStart,
    dateEnd,
    setDateEnd,
    printHandler,
    executeHandler,
    accountVal,
    setAccountVal,
    setAccountStatementData,
    currencyVal,
    setCurrencyVal,
    accountStatementData,
  } = props;
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [accountData, setAccountData] = useState([]);
  const { data } = useFetchCurrency();
  const disabledBtn = accountVal.value === '' || currencyVal.value === '';
  const [errorHandler, setErrorHandler] = useState('');

  useEffect(() => {
    getRequest(`getAccountsList?CompanyID=${currentCompanyID}`)
      .then((res) => {
        setAccountData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  }, [currentCompanyID]);

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      setErrorHandler(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };

  const selectChangeHandler = (value, name) => {
    if (!value) {
      value = {
        value: '',
      };
      setAccountStatementData([]);
    }

    if (name === 'account') {
      setAccountVal(value);
    }

    if (name === 'currency') {
      setCurrencyVal(value);
    }
  };
  const dataForExcel = accountVal?.value
    ? ConvertDataExcel(accountStatementData)
    : null;

  return (
    <div className="G-t-account-form-flex">
      <CalendarTime
        dateStart={dateStart}
        setDateStart={setDateStart}
        dateEnd={dateEnd}
        setDateEnd={setDateEnd}
        onChangeHandler={timeChangeHandler}
      />

      <SelectAccount
        customClass="L-order-select-wrapper"
        title="Հաշիվ"
        accountData={accountData}
        accountVal={accountVal}
        selectChangeAccount={(values) => selectChangeHandler(values, 'account')}
      />

      <SelectCurrency
        currencyData={data?.currencies}
        currencyVal={currencyVal}
        selectChangeCurrency={(value) => selectChangeHandler(value, 'currency')}
      />

      <div className="G-excel-print-btn-flex">
        <GetSentDataBtn
          cancelPrintClickText={executeText}
          confirmExecuteText={viewPrintTitle}
          cancelPrintClick={executeHandler}
          confirmExecuteClick={printHandler}
          executeClass={disabledBtn ? 'G-disabled-button' : ''}
          executeDisabled={disabledBtn}
          customClass={disabledBtn ? 'G-disabled-button' : ''}
          disabled={disabledBtn}
        />
        {disabledBtn ? null : <ExportExcel excelData={dataForExcel} />}
      </div>
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </div>
  );
};

export default AccountStatementAction;
