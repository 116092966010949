import { createAction } from '../../actionCreators';
import {
  ADD_FORM_DATA,
  ADD_SAVING,
  DELETE_SAVING,
  FETCH_SAVINGS,
  UPDATE_SAVING,
} from './constants';

export const fetchSavingsAction = createAction(FETCH_SAVINGS);
export const deleteSavingAction = createAction(DELETE_SAVING);
export const updateSavingAction = createAction(UPDATE_SAVING);
export const addSavingFormAction = createAction(ADD_FORM_DATA);
export const addSavingAction = createAction(ADD_SAVING);
