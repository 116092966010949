import { createAction } from "../actionCreators";
import {
  GET_CHART_OF_ACCOUNT_DATA,
  LOADING,
  EDIT_CHART_OF_ACCOUNT,
  DELETE_CHART_OF_ACCOUNT,
} from "./constants";

export const getChartOfAccountAction = createAction(GET_CHART_OF_ACCOUNT_DATA);
export const loadingAction = createAction(LOADING, false);
export const editChartOfAccountAction = createAction(EDIT_CHART_OF_ACCOUNT);
export const deleteChartOfAccountAction = createAction(DELETE_CHART_OF_ACCOUNT);
