import React from 'react';
import './radioBtn.scss';

const RadioBtn = (props) => {
  const {
    id,
    onChange,
    value,
    checked,
    label,
    name,
    defaultChecked,
    disabled,
  } = props;
  return (
    <div className="radio-btn-block">
      <input
        defaultChecked={defaultChecked}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        type="radio"
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default RadioBtn;
