import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import { editOrganizationAction } from '../../Redux/organizationSlices/useAction';
import OrganizationForm from './OrganizationForm';
import { updateUser } from '../../Redux/authSlices/useAction';

const EventEditForm = () => {
  const { organizationData } = useSelector(
    (state) => state.getOrganizationData,
  );

  const [errorHandler, setErrorHandler] = useState('');
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prevCompanyName, setPrevCompanyName] = useState('');

  const onSubmit = (values, resetForm) => {
    setIsSubmitting(true);
    putRequest('editCompany', values)
      .then((res) => {
        const {
          errorCode,
          message,
          oldDate,
          endDate,
          companyTaxationID,
          taxationSystemName,
        } = res.data;

        if (errorCode > 0 && message) {
          setErrorHandler(message);
        }
        if (errorCode === 10) {
          resetForm();
          const updatedCompanyData = {
            ...organizationData.company,
            taxationDate: oldDate,
            taxationID: companyTaxationID,
            taxationSystemName: taxationSystemName?.trim() || '',
            endDate: endDate,
          };

          dispatch(editOrganizationAction(updatedCompanyData));
        }
        if (errorCode === 0) {
          setErrorHandler('Փոփոխությունը գրանցվեց');
          dispatch(editOrganizationAction(values));
          dispatch(
            updateUser({
              startDate: values.startDate,
              endDate: values.endDate,
            }),
          );
        }
        if (values.companyName !== prevCompanyName) {
          dispatch(
            updateUser({
              currentCompanyName: values.companyName,
              currentCompanyID: values.companyID,
            }),
          );
        }
      })
      .catch((err) => {
        console.log('err' + err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    setPrevCompanyName(organizationData?.company);
  }, [organizationData?.company]);

  return (
    <>
      <OrganizationForm
        initialData={organizationData?.company || {}}
        onSubmit={onSubmit}
        disabledTaxCode={true}
        setErrorHandler={setErrorHandler}
        isSubmitting={isSubmitting}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventEditForm;
