import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "../../Api/Api";
import GetSentDataBtn from "../../Components/GetSentDataBtn/GetSentDataBtn";
import InputFiled from "../../Components/InputFiled/InputFiled";
import Modal from "../../Components/Modal/Modal";
import { dataTimeFormat, dateFormat } from "../../Utilities/Utilities";

const AccountRevaluation = ({ closeModal, respData, getData }) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const handleClose = () => closeModal(false);
  const {
    accountCode,
    currencyCode,
    docDate,
    exchangeRate,
    currencyID,
    debitBalance,
    creditBalance,
    debitBalanceAMD,
    creditBalanceAMD,
    amountAMD,
    partnerID,
  } = respData || {};

  const [initialExchangeRate, setInitialExchangeRate] = useState(exchangeRate);
  const revalueAmountAMD = amountAMD - debitBalanceAMD - creditBalanceAMD;

  const initialAmountAMD =
    debitBalance * initialExchangeRate + creditBalance * initialExchangeRate;

  const formTitle = [
    'Հաշիվը',
    'Արժույթը',
    'Ամսաթիվը',
    'Դեբետային մնացորդ',
    'Կրեդիտային մնացորդ',
    'Դեբետային մնացորդ  AMD-ով',
    'Կրեդիտային մնացորդ  AMD-ով',
    'Արտարժույթի փոխարժեքը',
    'Գումարը AMD-ով',
    'Վերագնահատովող գումարը',
  ];
  const formValue = [
    accountCode,
    currencyCode,
    dateFormat(docDate),
    debitBalance,
    creditBalance,
    debitBalanceAMD,
    creditBalanceAMD,
  ];

  const [value, setValue] = useState({
    companyID: currentCompanyID,
  });

  useEffect(() => {
    const obj = {
      accountCode: accountCode,
      docDate: dataTimeFormat(docDate),
      currencyID: currencyID,
      currencyCode: currencyCode,
      exchangeRate: Number(initialExchangeRate),
      revalueAmountAMD: revalueAmountAMD,
      debitBalance: debitBalance,
      creditBalance: creditBalance,
      partnerID: partnerID,
    };

    setValue({ ...value, ...obj });
  }, [
    accountCode,
    creditBalance,
    currencyCode,
    currencyID,
    debitBalance,
    docDate,
    initialAmountAMD,
    initialExchangeRate,
    revalueAmountAMD,
  ]);

  const handleSubmit = () => {
    postRequest('addPartnerAccountRevaluation', value)
      .then((res) => {
        if (res.data.errorCode === 0) {
          handleClose();
          getData();
        } else if (res.data.errorCode > 0) {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <Modal
      closeHandler={handleClose}
      onDrag={true}
      customClass="G-account-revaluation-modal"
    >
      <div className="G-account-revaluation-content">
        <div className="G-modal-block-title">
          <h3>Հաշվի վերագնահատում</h3>
        </div>
        <div className="G-account-revaluation-flex G-flex-justify-between">
          <div className="G-account-revaluation-panel">
            {formTitle &&
              formTitle?.map((title, key) => {
                return (
                  <h3 className="title-h3 " index={key}>
                    {title}
                  </h3>
                );
              })}
          </div>
          <div className="G-account-revaluation-panel">
            {formValue &&
              formValue?.map((title, key) => {
                return <p index={key}>{title}</p>;
              })}

            <div className="L-exchange-rate-input">
              <InputFiled
                type="number"
                name="exchangeRate"
                value={initialExchangeRate}
                changeHandler={(e) => setInitialExchangeRate(e.target.value)}
              />
              <p>{initialAmountAMD.toFixed(2)}</p>
              <p>{revalueAmountAMD.toFixed(2)}</p>
            </div>
          </div>
        </div>

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={initialExchangeRate === '' ? 'G-disabled-button' : ''}
          executeDisabled={initialExchangeRate === ''}
        />
      </div>
    </Modal>
  );
};

export default AccountRevaluation;
