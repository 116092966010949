import React from "react";
import Button from "../../Components/Button/Button";
import { useHistory } from "react-router-dom";

const DataSentMessage = () => {
  const history = useHistory();
  const goBack = () => {
    history.push("/Login");
  };

  return (
    <div className="G-login-register-main-form">
      <div className="L-data-sent-message">
        <h2>
          Գրանցումն ավարտելու համար խնդրում ենք ձեր Էլ. հասցեում սեղմեք
          Հաստատել;
        </h2>
        <div className="L-go-back-btn">
          <Button text="Հետ գնալ" onClick={goBack} />
        </div>
      </div>
    </div>
  );
};

export default DataSentMessage;
