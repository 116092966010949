import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  executeSummaryData,
  fetchSummaryData,
  fetchSummaryDataMonth,
} from '../services/fetchSummaryData';

const useSummaryData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [error, setError] = useState('');
  const [errorMess, setErrorMess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [summaryTableData, setSummaryTableData] = useState(null);

  const getSummaryData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchSummaryData(currentCompanyID);
      setSummaryData(data);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExecuteSummaryData = async (query) => {
    setIsLoading(true);
    try {
      const data = await executeSummaryData(currentCompanyID, query);
      setSummaryTableData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    getSummaryData,
    getExecuteSummaryData,
    summaryTableData,
    setSummaryTableData,
    summaryData,
    isLoading,
    error,
    errorMess,
    setErrorMess,
  };
};

export default useSummaryData;
