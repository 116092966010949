import { useEffect, useState } from 'react';
import {
  executeGeneralSummary,
  fetchGeneralSummaryData,
} from '../services/fetchGeneralSummary';
import { useSelector } from 'react-redux';

const useGeneralSummary = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [error, setError] = useState('');
  const [errorMess, setErrorMess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [generalSummaryData, setGeneralSummaryData] = useState(null);
  const [generalSummaryTableData, setGeneralSummaryTableData] = useState(null);

  const getGeneralSummaryData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchGeneralSummaryData(currentCompanyID);
      setGeneralSummaryData(data);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExecuteGeneralSummary = async (query) => {
    setIsLoading(true);
    try {
      const data = await executeGeneralSummary(currentCompanyID, query);
      setGeneralSummaryTableData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    getGeneralSummaryData,
    generalSummaryData,
    getExecuteGeneralSummary,
    generalSummaryTableData,
    setGeneralSummaryTableData,
    isLoading,
    error,
    errorMess,
    setErrorMess,
  };
};

export default useGeneralSummary;
