import React from 'react';
import SubdivisionForm from './SubdivisionForm';
import useSubdivisions from '../../hooks/useSubdivisions';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';

const EventEditForm = ({ rowValues, handleClose }) => {
  const { updateSubdivisionItem, errorMess, setErrorMess } = useSubdivisions();
  const isMounted = useIsMounted();

  const onUpdateHandler = async (values) => {
    try {
      await updateSubdivisionItem(values, handleClose);
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <SubdivisionForm
        initialValues={rowValues}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventEditForm;
