import React from 'react';
// import correspondence from "../../../Assets/image/correspondence.png";
import computer from '../../../Assets/image/computer.png';
import './window.scss';
const Window = () => {
  return (
    <>
      <div className="L-Window-view ">
        <div className="L-Window-img">
          <img src={computer} alt="" />
        </div>
      </div>
      <h3 className="Testing-text">ծրագիրը գտնվում է փորձնական փուլում</h3>
    </>
  );
};

export default Window;
