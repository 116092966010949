import React, { useState } from 'react';
import { Commas, tableActionTitle } from '../../../App/Utilities/Utilities';

import Table from '../../../App/Components/Table/Table';
import Action from '../../../App/Components/Table/Action';
import { EInvoiceEntryActionItems } from '../../Config/tableConfig';
import Forms from './Forms';

const EInvoiceEntryTable = ({ invoiceEntryTableData }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Հ/հ',
      cell: (row) => row.goodNumber,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.description,
      customStyle: { maxWidth: 460, overflow: 'hidden' },
    },
    {
      title: 'Չ/Մ',
      cell: (row) => row.unit,
      customStyle: { maxWidth: 80, overflow: 'hidden' },
    },
    {
      title: 'Քանակը',
      cell: (row) => row.amount,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Գինը',
      cell: (row) => Commas(row.price),
      customStyle: { maxWidth: 160 },
    },
    { title: 'Ակցիզ', cell: (row) => row.eta, customStyle: { maxWidth: 80 } },
    { title: 'ԱԱՀ', cell: (row) => row.vat, customStyle: { maxWidth: 80 } },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.totalPrice),
      customStyle: { maxWidth: 160 },
    },
    {
      title: 'ՆԱ կոդը',
      cell: (row) => row.matValueNo,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ընդամենը',
      cell: (row) => Commas(row.total),
      customStyle: { maxWidth: 160 },
    },

    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          mainAction="authenticated"
          mainActionName="Նույնականացել"
          handelChange={(type, row) =>
            setShowForm({
              type: type,
              value: row,
            })
          }
          actionItem={EInvoiceEntryActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 140 },
    },
  ];

  return (
    <>
      {invoiceEntryTableData !== null &&
      invoiceEntryTableData &&
      invoiceEntryTableData?.length ? (
        <Table
          customClass=""
          data={invoiceEntryTableData}
          columnConfig={columnConfig}
        />
      ) : null}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default EInvoiceEntryTable;
