import React from 'react';

const HeaderItem = ({ name, value }) => (
  <div className="assign-storage-header-item">
    {name && <h4>{name}</h4>}
    <span>{value}</span>
  </div>
);

export default HeaderItem;
