import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  dataTimeFormat,
  dateFormat,
  formatNumberWithCommas,
  tableActionTitle,
} from '../../Utilities/Utilities';
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';
import Action from '../../Components/Table/Action';
import DeleteInitialBalances from './DeleteInitialBalances';
import EventEditForm from './EventEditForm';
import PartnerAccountBalances from './PartnerAccountBalances';
import { getRequest } from '../../Api/Api';

const InitialBalancesTable = ({ mainData, loading }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [accountEntriesData, setAccountEntriesData] = useState([]);
  const handleEditForm = useCallback((row) => {
    setOpenEditModal(row);
  }, []);

  const handleDeleteForm = useCallback((row) => {
    setOpenDeleteModal(row);
  }, []);
  const handleAccountClick = useCallback(
    (rowData) => {
      const { partnerID, accountCode, balanceDate, currencyID } = rowData || {};

      if (partnerID > 0) {
        getRequest(
          `GetPartnerAccountBalances?companyID=${currentCompanyID}&currencyID=${currencyID}&accountCode=${accountCode.trim()}&dateEnd=${dataTimeFormat(
            balanceDate,
          )} `,
        )
          .then((res) => {
            if (res.data.length > 0) {
              setAccountEntriesData(res.data);
            } else {
              setAccountEntriesData([]);
            }
          })
          .catch((err) => {
            console.log('Err' + err);
          });
      }
    },
    [currentCompanyID],
  );

  const accountHandler = useCallback(
    (row) => {
      const { accountCode, accountName, partnerID } = row || {};

      const x = accountName.includes(accountCode.trim())
        ? accountName
        : accountCode + ' ' + accountName;

      if (partnerID === 0) {
        return x;
      } else if (partnerID > 0) {
        return (
          <p
            onClick={() => handleAccountClick(row)}
            className="G-account-is-active"
            style={{ overflow: 'hidden' }}
          >
            {x}
          </p>
        );
      }
    },
    [handleAccountClick],
  );

  const handleActionChange = useCallback(
    (value, row) => {
      switch (value) {
        case 'edit':
          return handleEditForm(row);
        case 'delete':
          return handleDeleteForm(row);
        default:
          return null;
      }
    },
    [handleDeleteForm, handleEditForm],
  );

  const actionHandler = useCallback(({ partnerID }) => {
    const actionList = [];
    if (partnerID !== 1) {
      actionList.push(
        {
          name: 'Խմբագրել',
          value: 'edit',
        },
        {
          name: 'Ջնջել',
          value: 'delete',
        },
      );
    }
    return actionList;
  }, []);

  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.balanceDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Հաշիվ',
      cell: (row) => accountHandler(row),
      customStyle: {
        maxWidth: 1000,
        overflow: 'hidden',
      },
    },
    {
      title: 'Նշումներ',
      cell: (row) => row.comment,
      customStyle: {
        maxWidth: 150,
        overflow: 'hidden',
      },
    },
    {
      title: 'Արժույթ',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 55 },
    },
    {
      title: 'Դեբետ',
      cell: (row) => formatNumberWithCommas(row.amountDebit),
      customStyle: { maxWidth: 140 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => formatNumberWithCommas(row.amountCredit),
      customStyle: { maxWidth: 140 },
    },
    {
      title: 'Դեբետ AMD',
      cell: (row) => formatNumberWithCommas(row.amountDebitAMD),
      customStyle: { maxWidth: 140 },
    },
    {
      title: 'Կրեդիտ AMD',
      cell: (row) => formatNumberWithCommas(row.amountCreditAMD),
      customStyle: { maxWidth: 140 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          actionMess="Գործողությունը գործընկերոջ սկզբ.մն"
          show={row?.partnerID !== 1 ? false : true}
          handelChange={handleActionChange}
          actionItem={actionHandler(row?.partnerID)}
          modalIsOpen={openEditModal || openDeleteModal}
        />
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Table
        customClass="L-initial-balance-table"
        data={mainData}
        columnConfig={columnConfig}
      />

      {openEditModal && (
        <EventEditForm closeModal={setOpenEditModal} rowData={openEditModal} />
      )}
      {openDeleteModal && (
        <DeleteInitialBalances
          closeModal={setOpenDeleteModal}
          rowData={openDeleteModal}
        />
      )}

      {accountEntriesData?.length > 0 && (
        <PartnerAccountBalances
          data={accountEntriesData}
          closeModal={setAccountEntriesData}
        />
      )}
    </>
  );
};

export default React.memo(InitialBalancesTable);
