import React from 'react';
import Table from '../../../App/Components/Table/Table';
import { Commas } from '../../../App/Utilities/Utilities';
import Loading from '../../../App/Components/Loading/Loading';
import Tooltip from '../../../App/Components/Tooltip/Tooltip';

const GeneralSummaryTable = ({ salarySummaries, isLoading }) => {
  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { minWidth: 220, overflow: 'hidden' },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { minWidth: 80 },
    },
    {
      title: 'Ազգանուն, Անուն, Հայրանուն',
      cell: (row) => row.empName,
      customStyle: { minWidth: 320, overflow: 'hidden' },
    },
    {
      title: 'Հաստիք',
      cell: (row) => Commas(row.salary),
      customStyle: { minWidth: 150 },
    },
    {
      title: 'Արձակուրդ',
      cell: (row) => Commas(row.sT014),
      customStyle: { minWidth: 150 },
    },

    {
      title: 'Վերջնահաշվարկ',
      cell: (row) => Commas(row.sT015),
      customStyle: { minWidth: 150 },
    },
    {
      title: 'Նպաստ',
      cell: (row) => Commas(row.sT016),
      customStyle: { minWidth: 150 },
    },
    {
      title: 'Հղիության նպաստ',
      cell: (row) => Commas(row.sT018),
      customStyle: { minWidth: 150 },
    },
    {
      title: 'Այլ հավելում',
      cell: (row) => Commas(row.otherAdd),
      customStyle: { minWidth: 150 },
    },
    {
      title: 'Հաշվարկվել է հավելում',
      cell: (row) => Commas(row.totalAdd),
      customStyle: { minWidth: 160 },
    },
    {
      title: 'Եկամտային հարկ',
      cell: (row) => Commas(row.sT101),
      customStyle: { minWidth: 150 },
    },
    {
      title: 'Սոց. վճար',
      cell: (row) => Commas(row.sT102),
      customStyle: { minWidth: 150 },
    },
    {
      title: 'Դրոշմ. վճար',
      cell: (row) => Commas(row.sT103),
      customStyle: { minWidth: 150 },
    },
    {
      title: <Tooltip content="Արհմիութենական վճար"> Արհմ. վճար</Tooltip>,
      cell: (row) => Commas(row.sT104),
      customStyle: { minWidth: 150 },
    },
    {
      title: 'Այլ պահում',
      cell: (row) => Commas(row.otherDed),
      customStyle: { minWidth: 150 },
    },
    {
      title: 'Փոխ. է բանկ',
      cell: (row) => Commas(row.sT107),
      customStyle: { minWidth: 150 },
    },
    {
      title: 'Հաշվարկվել է պահում',
      cell: (row) => Commas(row.totalDed),
      customStyle: { minWidth: 160 },
    },
    {
      title: 'Վճ. ենթակա',
      cell: (row) => Commas(row.toBePayed),
      customStyle: { minWidth: 150 },
    },
  ];

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {salarySummaries !== null && salarySummaries?.length ? (
        <div className="L-general-table-wrap">
          <Table
            customClass="L-general-summary-table G-table-has-scroll"
            data={salarySummaries}
            columnConfig={columnConfig}
          />
        </div>
      ) : null}
    </>
  );
};

export default GeneralSummaryTable;
