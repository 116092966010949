import { createAction } from '../actionCreators';
//create constant
const GET_PRINT_URL_PARAM = 'GET_PRINT_URL_PARAM';

//create action
export const getPrintUrlAction = createAction(GET_PRINT_URL_PARAM);

//create reducer

const initialState = {
  title: '',
  param: '',
};

export const printReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PRINT_URL_PARAM:
      return {
        ...state,
        param: payload.param,
        title: payload.title,
      };

    default:
      return state;
  }
};
