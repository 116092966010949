import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { putRequest } from '../../Api/Api';
import { editOrganizationBankAction } from '../../Redux/organizationSlices/useAction';
import CompanyBankForm from './CompanyBankForm';

const EventEditForm = ({ rowData, closeModal, setErrorHandler }) => {
  const handleClose = useCallback(() => closeModal(false), [closeModal]);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values) => {
      putRequest('editCompanyBank', values)
        .then((res) => {
          const { errorCode, message } = res?.data;
          if (errorCode === 0) {
            dispatch(editOrganizationBankAction(values));
            handleClose();
          } else if (errorCode === 0) {
            setErrorHandler(message);
          }
        })
        .catch((err) => {
          console.log('err' + err);
        });
    },
    [dispatch, handleClose, setErrorHandler],
  );

  const initialData = React.useMemo(() => rowData, [rowData]);

  return (
    <>
      <CompanyBankForm
        closeModal={handleClose}
        initialData={initialData}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default EventEditForm;
