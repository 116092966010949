import React, { useState } from 'react';
import Table from '../../../../App/Components/Table/Table';
import {
  Commas,
  dateFormat,
  tableActionTitle,
} from '../../../../App/Utilities/Utilities';
import Action from '../../../../App/Components/Table/Action';
import { globalActionItems } from '../../../Config/tableConfig';
import Forms from './Forms';
const AssignAdditionalTable = ({ mainData }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Նշ.սկիզբը',
      cell: (row) => dateFormat(row.setDate),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Վերջը',
      cell: (row) => (row.endDate ? dateFormat(row.endDate) : ''),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Հ/Պ կոդը',
      cell: (row) => row.stNo,
      customStyle: { maxWidth: 80 },
    },

    {
      title: 'Հ/Պ Անվանումը',
      cell: (row) => row.stName,
      customStyle: { maxWidth: 400, overflow: 'hidden' },
    },
    {
      title: 'Աշխ.տաբել',
      cell: (row) => row.graphName,
      customStyle: { maxWidth: 220, overflow: 'hidden' },
    },
    {
      title: 'Տոկոսը',
      cell: (row) => row.sPercent,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.sAmount),
      customStyle: { maxWidth: 150 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(value, row) => {
            setShowForm({
              type: value,
              value: row,
            });
          }}
          actionItem={globalActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="assign-additional-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : null}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default AssignAdditionalTable;
