import React, { useEffect, useState } from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import SearchBar from '../../components/searchBar/SearchBar';
import { ExportExcel } from '../../../App/Components/Excelexport/Excelexport';
import { useDebounce } from '../../../App/Hooks/useDebounce';

const UnitsMeasureFilter = ({ params, setParams, getExcelData }) => {
  const [searchString, setSearchString] = useState(params.searchString);
  const debouncedSearchTerm = useDebounce(searchString, 1500);

  useEffect(() => {
    setParams((prevState) => ({
      ...prevState,
      searchString: debouncedSearchTerm,
    }));
  }, [debouncedSearchTerm, setParams]);

  const onInputChange = (searchString) => {
    setSearchString(searchString);
  };

  return (
    <SearchBackground>
      <div className="G-search-form-flex">
        <SearchBar query={searchString} setQuery={onInputChange} />
        <ExportExcel getExcelData={getExcelData} fileName="Չափման միավորներ" />
      </div>
    </SearchBackground>
  );
};

export default UnitsMeasureFilter;
