import React, { useEffect } from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../../App/Components/Alert';
import useWarehouseEntry from '../../hooks/useWarehouseEntry';

const EventRemove = ({ matInputDocID, handleClose }) => {
  const {
    errorMess,
    setErrorMess,
    getRemoveWarehouseEntry,
    isAllowDelete,
    deleteCurrentWarehouseEntry,
  } = useWarehouseEntry();

  // useEffect(() => {
  //   getRemoveWarehouseEntry(matInputDocID);
  // }, [matInputDocID]);

  const onRemoveHandler = async () => {
    try {
      await deleteCurrentWarehouseEntry(matInputDocID, handleClose);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  const handleAlertClose = () => {
    setErrorMess('');
    handleClose();
  };

  return (
    <>
      {/* {!isAllowDelete && (
        <Alert description={errorMess} onClose={handleAlertClose} />
      )} */}
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventRemove;
