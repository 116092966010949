import React, { useState } from "react";
import { useSelector } from "react-redux";
import ClickOutside from "../../Components/ClickOutside/ClickOutside";
import userImages from "../../Assets/icons/userImages.svg";
import "./auth.scss";
import AuthSettings from "./AuthSettings";

const AuthInfo = () => {
  const { firstName, lastName, picture } = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState(false);

  const handelClick = () => {
    setOpenModal(!openModal);
  };

  const userImg = picture ? `data:image/jpeg;base64,${picture}` : userImages;

  return (
    <ClickOutside onClickOutside={() => setOpenModal(false)}>
      <div className="G-user-info-block">
        <div className="G-user-flex">
          <div className="G-user-default-img" onClick={handelClick}>
            <img src={userImg} alt="" />
          </div>
          <span className="G-user-name-surname">
            {firstName} {lastName}
          </span>
        </div>
        <div className="G-user-open-modal">{openModal && <AuthSettings />}</div>
      </div>
    </ClickOutside>
  );
};

export default AuthInfo;
