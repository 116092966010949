import React from 'react';
import DeleteTables from './DeleteTables';
import EventEditForm from './EventEditForm';
import WorkCalendar from '../workCalendar/WorkCalendar';

const Forms = ({ formType, closeModal }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};
  const { graphNo, calendarID } = value || {};
  switch (type) {
    case 'delete':
      return <DeleteTables id={calendarID} handleClose={handleClose} />;
    case 'edit':
      return (
        <EventEditForm calendarID={calendarID} handleClose={handleClose} />
      );
    case 'workCalendar':
      return <WorkCalendar graphNo={graphNo} handleClose={handleClose} />;
    default:
      return null;
  }
};

export default Forms;
