export const GeneraleMatNameField = [
  {
    fieldType: 'input',
    type: 'number',
    name: 'matValueNo',
    label: 'Կոդը',
    value: '',
    required: true,
    maxLength: 3,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Խումբը',
    required: true,
    fieldName: 'groupName',
    dropdownId: 'groupNo',
    optionName: 'prodGroups',
    addNewItem: true,
    Children: (props) => {
      return props?.map((i) => ({
        label: i.groupName,
        id: i.groupNo,
        value: i.groupName,
      }));
    },
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'matValueName',
    label: 'Անվանումը',
    value: '',
    required: true,
    maxLength: 50,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Չափի միավորը',
    required: true,
    fieldName: 'unit',
    dropdownId: 'unit',
    optionName: 'units',
    addNewItem: true,
    Children: (props) => {
      return props?.map((i) => ({
        label: i.unitName,
        id: i.unitCode,
        value: i.unitName,
      }));
    },
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Հաշիվ',
    fieldName: 'accCode',
    dropdownId: 'accCode',
    optionName: 'accounts',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.accountCode + ' ' + i.accountName,
        id: i.accountCode,
        value: i.accountName,
      }));
    },
  },
];

export const AdditionalMatNameField = [
  {
    fieldType: 'textArea',
    type: 'text',
    name: 'description',
    label: 'Բնութագիր',
    value: '',
    maxLength: 200,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'barCode',
    label: 'Գծանիշ կոդը',
    value: '',
    required: false,
    maxLength: 50,
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'ԱՏԳԱԱ դասակարգիչ',
    required: false,
    fieldName: 'feaName',
    dropdownId: 'feaCode',
    optionName: 'feaCodes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.feaName,
        id: i.feaCode,
        value: i.feaName,
      }));
    },
  },
];
