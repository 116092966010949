import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import NonWorkingDaysTable from './NonWorkingDaysTable';
import useNonWorkingDays from '../../hooks/useNonWorkingDays';
import EventAddForm from './EventAddForm';
import './style.scss';
import UpdateNonWorkingDays from './UpdateNonWorkingDays';
const NonWorkingDays = () => {
  const [showAddForm, setShowAddForm] = useState(false);

  const { getNonWorkingDaysData, nonWorkingDays, updateNonWorkDays } =
    useNonWorkingDays();
  useEffect(() => {
    getNonWorkingDaysData();
  }, []);

  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };

  const onUpdateHandler = async () => {
    try {
      await updateNonWorkDays();
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };
  return (
    <div className="G-small-wrap L-non-working-days-block">
      <Header pageTitle="Ոչ աշխատանքային օրեր" handelClick={onClose} />
      <UpdateNonWorkingDays onUpdateHandler={onUpdateHandler} />
      <NonWorkingDaysTable mainData={nonWorkingDays} />
      {showAddForm && <EventAddForm handleClose={onClose} />}
    </div>
  );
};

export default NonWorkingDays;
