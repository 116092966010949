import React from 'react';
import GetSentDataBtn from '../GetSentDataBtn/GetSentDataBtn';
import Modal from '../Modal/Modal';
import './acceptOrCancelModal.scss';

const AcceptOrCancelModal = (props) => {
  const {
    closeModal,
    modalContent = 'Համոզվա՞ծ եք որ ուզում եք ջնջել այն',
    confirmClick,
  } = props;
  const handleClose = () => closeModal(false);
  return (
    <Modal closeHandler={handleClose} customClass="G-accept-or-cancel-modal">
      <div className="G-accept-or-cancel-content">
        <h3>{modalContent}</h3>
        <div className="G-accept-or-cancel-btn-block">
          <GetSentDataBtn
            cancelPrintClick={handleClose}
            confirmExecuteClick={confirmClick}
            confirmExecuteText="Այո"
            cancelPrintClickText="Ոչ"
          />
        </div>
      </div>
    </Modal>
  );
};

export default AcceptOrCancelModal;
