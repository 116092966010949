import React, { useState } from 'react';
import './style.scss';
import HelpBtn from '../../../App/Components/HelpBtn/HelpBtn';
import { useLocation } from 'react-router-dom';
import SalaryIcon from '../../../App/Assets/homeIcons/salary.png';
import { SalaryHelpers } from '../../salaryHelpers/SalaryHelpers';
import HelperContent from '../../../App/Components/HelperContent/HelperContent';
const RenderSalaryPage = ({ children }) => {
  const [text, setText] = useState(null);
  let location = useLocation();
  const mainPage = location.pathname === '/EmployeesDashboard';

  const helpHandler = () => {
    return SalaryHelpers?.map(({ path, text }) => {
      return path === location.pathname ? setText(text) : path;
    });
  };

  return (
    <div className="salary-main-container">
      {mainPage ? (
        <div className="salary-logo-block">
          <div className="salary-logo-wrap">
            <img src={SalaryIcon} alt="" />
          </div>
          <h3>Աշխատավարձ</h3>
        </div>
      ) : null}
      <HelpBtn helpHandler={helpHandler} />
      <div className="G-main-container">{children}</div>
      {text && (
        <HelperContent
          closeHelpBox={setText}
          helperMessage={text}
          locationPathname={location.pathname}
        />
      )}
    </div>
  );
};

export default RenderSalaryPage;
