import React from 'react';
import SalaryCalculator from './salaryCalculator/SalaryCalculator';
import EmployeesList from './employeesList/EmployeesList';
import Header from '../../../App/Components/Header/Header';

const EmployeesDashboard = () => {
  return (
    <>
      <Header headerBtn={false} />

      <div className="L-employees-dashboard-block">
        <SalaryCalculator />
        <EmployeesList />
      </div>
    </>
  );
};

export default EmployeesDashboard;
