import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../Components/Alert';
import { deleteProductAction } from '../../Redux/productSlices/useAction';

const DeleteProduct = ({ deleteRowId, closeModal }) => {
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  const deleteRowData = useCallback(() => {
    deleteRequest(`deleteProductName/${deleteRowId}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deleteProductAction(deleteRowId));
        handleClose(false);
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  }, [dispatch, deleteRowId, closeModal]);

  return (
    <>
      <AcceptOrCancelModal
        closeModal={closeModal}
        confirmClick={deleteRowData}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default DeleteProduct;
