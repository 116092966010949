const userSections = (
  firstName,
  lastName,
  phone,
  email,
  currentCompanyName,
) => [
  {
    title: 'Անձնական տվյալներ',
    info: [firstName, lastName, phone],
    modal: 'userData',
  },
  {
    title: 'Մուտքանուն',
    info: [email],
    modal: 'userEmail',
  },
  {
    title: 'Գաղտնագիր',
    info: ['**********'],
    modal: 'userPass',
  },
  {
    title: 'Կազմակերպության տվյալներ',
    info: [currentCompanyName],
    modal: 'organizationData',
  },
];

export default userSections;
