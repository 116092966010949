import React from 'react';
import EventEditForm from './EventEditForm';
import EventRemove from './EventRemove';
import useUpdateSignChecker from '../../../hooks/useUpdateSignChecker';

const ProdActionForms = ({
  formType,
  closeModal,
  updateSign,
  errorMess,
  setErrorMess,
}) => {
  const { checkUpdateSign } = useUpdateSignChecker();

  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};

  const handleAction = (actionType) => {
    const isAllowed = checkUpdateSign(updateSign, errorMess, setErrorMess);
    if (isAllowed) {
      return actionType;
    }
    return null;
  };

  switch (type) {
    case 'delete':
      return handleAction('delete') ? (
        <EventRemove matInputID={value?.matInputID} handleClose={handleClose} />
      ) : null;

    case 'edit':
      return handleAction('edit') ? (
        <EventEditForm currentProductData={value} handleClose={handleClose} />
      ) : null;

    default:
      return null;
  }
};

export default ProdActionForms;
