import React from 'react';
import FormulationBtn from './FormulationBtn';

const PrintAndFormulationsBtn = ({
  isPrintDisabled,
  isFormulationsDisabled,
  onPrintHandler,
  onNewFormulationsHandler,
  printSign,
}) => {
  const buttonsConfig = [
    {
      text: 'Տպել',
      onClick: () => onPrintHandler(),
      type: 'submit',
      disabled: isPrintDisabled || !printSign,
    },
    {
      text: 'Նոր ձևակերպում',
      onClick: () => onNewFormulationsHandler(),
      type: 'submit',
      disabled: isFormulationsDisabled,
    },
  ];
  return (
    <FormulationBtn
      buttonsConfig={buttonsConfig}
      className="L-print-and-formulations-btn"
    />
  );
};

export default PrintAndFormulationsBtn;
