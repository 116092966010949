import * as Yup from "yup";
import {
  SelectFieldMessage,
  InputFiledMessage,

  EnterNumberOnly,
} from './ErrorMessage';
import { matchesNumber } from './REG_EXP';
import { Amount } from './ValidationForAllFiled';

export const PaymentOrderValidation = () =>
  Yup.object().shape({
    amount: Amount,

    accountDebit: Yup.string().required(SelectFieldMessage),

    //1. Գործընկեր է,

    partnerID: Yup.string().when('docSign', {
      is: 1,
      then: Yup.string().required(SelectFieldMessage),
    }),
    partnerAccount: Yup.string().when('docSign', {
      is: 1 || 2,
      then: Yup.string().required(SelectFieldMessage),
      otherwise: Yup.string(),
    }),

    //2. Իմ բանկային հաշիվների միջև

    //3.  Գործընկեր չէ

    recipient: Yup.string().when('docSign', {
      is: 3,
      then: Yup.string().required(SelectFieldMessage),
    }),

    areAttached: Yup.string().when('docSign', {
      is: 3,
      then: Yup.string()
        .matches(matchesNumber, EnterNumberOnly)
        .max(25, 'Մաքսիմում 25 նիշ')
        .required(InputFiledMessage),
    }),
    appendix: Yup.string().when('docSign', {
      is: 3,
      then: Yup.string().required(SelectFieldMessage),
    }),

    companyAccount: Yup.string().required(SelectFieldMessage),
    otherInformation: Yup.string().required(InputFiledMessage),
    correspAccount: Yup.string().required(SelectFieldMessage),

    taxNumber: Yup.string()
      .matches(/^[0-9]+$/, EnterNumberOnly)
      .nullable(),
    // .length(2, '2-Նիշ'),
  });
