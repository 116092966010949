import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { postRequest } from '../../../Api/Api';
import {
  dataTimeFormat,
  removeCommas,
  typOfString,
} from '../../../Utilities/Utilities';
import CashWarrantForm from './CashWarrantForm';

const EventAddForm = (props) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { cashWarrantData } = useSelector((state) => state.getCashWarrantData);
  const { amdCurrCode, amdCurrID, isDrop } = cashWarrantData || {};
  const {
    modalClick,
    getData,
    pageNumber,
    setErrorHandler,
    isCopyData = {},
  } = props;
  const handleClose = () => modalClick(false);

  const initialData = useMemo(() => {
    return {
      companyID: currentCompanyID,
      docTypeID: 1,
      partnerID: 0,
      currencyID: amdCurrID,
      currencyCode: amdCurrCode,
      docNum: '',
      docDate: new Date(),
      amount: 0,
      accountDebit: isDrop ? '' : '2511',
      recipient: '',
      correspAccount: '',
      exchangeRate: 1,
      credCurrID: amdCurrID,
      credCurrCode: amdCurrCode,
    };
  }, [amdCurrCode, amdCurrID, currentCompanyID, isDrop]);

  const onSubmit = (values, resetForm) => {

    const formatDocDate = dataTimeFormat(values?.docDate);
    postRequest('addCashRegister', {
      ...values,
      docDate: formatDocDate,
      docNum: typOfString(values.docNum),
      amount: removeCommas(values.amount),
      amountAMD: removeCommas(values.amountAMD),
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          getData(pageNumber);
          resetForm();
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <CashWarrantForm
        handleClose={handleClose}
        initialValue={
          Object?.keys(isCopyData)?.length === 0 ? initialData : isCopyData
        }
        onSubmit={onSubmit}
        setErrorHandler={setErrorHandler}
        addEvent={true}
      />
    </>
  );
};

export default EventAddForm;
