import React, { useMemo } from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { getPaymentNumOptions, getBankOptions } from './optionsUtils';
import BankPaymentListsBtn from './BankPaymentListsBtn';
import BankPaymentListsDropdown from './BankStatementDropdown';

const BankPaymentListsActions = ({
  paymentList,
  query,
  setQuery,
  getExecutePaymentList,
  isDisabledButtons,
  setIsDisabledButtons,
  resetTableData,
  getPaymentListNumber,
  tableData,
  dataToExcel,
}) => {
  const { execute } = isDisabledButtons || {};

  const paymentNumOptions = useMemo(() => {
    return getPaymentNumOptions(paymentList?.paymentNums);
  }, [paymentList?.paymentNums]);

  const bankOptions = useMemo(() => {
    return getBankOptions(paymentList?.banks);
  }, [paymentList?.banks]);

  const onExecuteHandler = async () => {
    try {
      await getExecutePaymentList(query?.payList?.id);
      setIsDisabledButtons({
        ...isDisabledButtons,
        execute: true,
      });
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <SearchBackground>
      <div className="L-bank-paymentList-actions-block">
        <BankPaymentListsDropdown
          paymentNumOptions={paymentNumOptions}
          bankOptions={bankOptions}
          query={query}
          setQuery={setQuery}
          resetTableData={resetTableData}
          getPaymentListNumber={getPaymentListNumber}
        />
        <BankPaymentListsBtn
          onExecuteHandler={onExecuteHandler}
          isExecuteDisabled={execute}
          dataToExcel={dataToExcel}
          tableData={tableData}
        />
      </div>
    </SearchBackground>
  );
};

export default BankPaymentListsActions;
