export const actionConfigs = [
  {
    title: 'Ստորաբաժանում',
    type: 'dropdown',
    optionName: 'workDeps',
    optionID: 'depNo',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.depName,
        id: i.depNo,
        value: i.depName,
      }));
    },
  },
  {
    title: 'Ժամանակահատվածը',
    type: 'calendar',
    calendars: [
      {
        name: 'startDate',
      },
      {
        name: 'endDate',
      },
    ],
  },
  {
    title: 'Հավելում/պահում',
    type: 'dropdown',
    optionName: 'salaryTypes',
    optionID: 'stNo',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.stName,
        id: i.stNo,
        value: i.stName,
      }));
    },
  },

  {
    name: 'name',
    title: 'Ազգանուն, անուն, հայրանուն',
    type: 'input',
    required: false,
  },
  {
    type: 'checkbox',
    name: 'eContract',
    title: 'Աշխատանքային պայմանագիր',
    value: false,
  },
  {
    type: 'checkbox',
    name: 'cContract',
    title: 'Քաղիրավական պայմանագիր',
    value: false,
  },
];
