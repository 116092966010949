import React from 'react';
import {
  dataTimeFormat,
  dateFormat,
  removeCommas,
} from '../../../App/Utilities/Utilities';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import { ExportExcel } from '../../../App/Components/Excelexport/Excelexport';
import DateComponent from '../../components/dateComponent/DateComponent';
import { warehouseEntryFilterFields } from './FieldsConfig';
import Input from '../../components/Input/Input';
import SearchBtn from '../../../App/Components/SearchBtn/SearchBtn';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';

const WarehouseEntryFilter = ({
  wareHouseEntryData,
  query,
  setQuery,
  getExcelData,
  onSearch,
}) => {
  const handleDropdownChange = (parameterName, selectedOption) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    setQuery((prevQuery) => ({
      ...prevQuery,
      [parameterName]: {
        ...prevQuery[parameterName],
        label: selectedOption.value,
        id: selectedOption.id,
      },
    }));
  };

  const handleDateChange = (date, name) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: dataTimeFormat(date),
    }));
  };

  const handleInputChange = (event, name) => {
    const { value } = event.target;
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: removeCommas(value),
    }));
  };

  const mainClassName = 'G-warehouseEntry-filter-box';

  return (
    <>
      <SearchBackground>
        <div className="L-warehouseEntry-filter-block">
          <div className="L-warehouseEntry-filter-flex">
            {warehouseEntryFilterFields?.map((config, index) => {
              let dropDownOptions = [];
              if (typeof config.Children === 'function' && wareHouseEntryData) {
                dropDownOptions = config.Children(
                  wareHouseEntryData[config.optionName],
                );
              }
              return (
                <div className={mainClassName} key={config.dropdownId || index}>
                  {config.fieldType === 'dropdown' && (
                    <SelectControl
                      selectBoxTitle={config.dropdownTitle}
                      options={dropDownOptions || []}
                      onChange={(selectedOption) =>
                        handleDropdownChange(config.dropdownId, selectedOption)
                      }
                      requiredFiled={config.required}
                    />
                  )}
                  {config.fieldType === 'calendar' && (
                    <div className={mainClassName}>
                      <p className="G-title-p">{config.calendarTitle}</p>
                      <div className="G-flex">
                        {config.calendars?.map((i, calendarIndex) => (
                          <div
                            className="calendar-box"
                            key={`${config.calendarTitle}-${calendarIndex}`}
                          >
                            <DateComponent
                              value={dateFormat(query[i.name])}
                              onChange={(date) =>
                                handleDateChange(date, i.name)
                              }
                              name={i.name}
                              requiredFiled={false}
                              openToDate={new Date(query[i.name])}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {config.fieldType === 'input' && (
                    <div className={mainClassName}>
                      <Input
                        inputTitle={config.inputTitle}
                        name={query[config.name]}
                        requiredFiled={config.required}
                        onChange={(e) => handleInputChange(e, config.name)}
                        className="has-border"
                      />
                    </div>
                  )}
                  {config.fieldType === 'amount' && (
                    <div className={mainClassName}>
                      <NumericFormatFiled
                        value={query[config.name]}
                        allowNegative={true}
                        name={query[config.name]}
                        changeHandler={(e) => handleInputChange(e, config.name)}
                        inputTitle={config.inputTitle}
                        inputHasBorder={true}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <ExportExcel
            getExcelData={getExcelData}
            fileName="Պահեստի մուտքի օրդեր"
          />
        </div>
      </SearchBackground>
      <SearchBtn onClick={onSearch} />
    </>
  );
};

export default WarehouseEntryFilter;
