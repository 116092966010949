import { useEffect, useState } from 'react';
import { getRequest, putRequest } from '../../Api/Api';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../Redux/authSlices/useAction';

const useUserImage = (userID, picture) => {
  const [img, setImg] = useState(picture);
  const dispatch = useDispatch();

  const getUserImg = () => {
    getRequest(`getPicture?UserID=${userID}`)
      .then((res) => setImg(res.data))
      .catch((err) => console.log('Error: ' + err));
  };

  const onDeleteUserImg = () => {
    if (img?.length > 0) {
      putRequest(`deletePicture?userID=${userID}`).then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(updateUser({ picture: null }));
          setImg(null);
        }
      });
    }
  };

  useEffect(() => {
    getUserImg();
  }, [userID]);

  return { img, getUserImg, onDeleteUserImg };
};

export default useUserImage;
