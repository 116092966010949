import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import { useFetchBalanceDate } from '../../Hooks/useFetchBalanceDate';
import { dataTimeFormat, removeCommas } from '../../Utilities/Utilities';
import InitialBalancesForm from './InitialBalancesForm';
import { useSelector } from 'react-redux';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { InitBalancesAllowAddText } from '../../GlobalTitle/GlobalTitle';

const EventAddForm = ({ getData, pageNumber, closeModal }) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const handleClose = () => closeModal(false);
  const [errorHandler, setErrorHandler] = useState('');
  const [selectAccountCode, setSelectAccountCode] = useState(null);
  const [isAllowAdd, setIsAllowAdd] = useState(false);
  const [balanceDate] = useFetchBalanceDate();
  const { periodStart } = balanceDate || {};

  useEffect(() => {
    async function checkAllowEdit() {
      try {
        const url = `preDeleteEditInitBalance?companyID=${currentCompanyID}&accountCode=${selectAccountCode}`;
        const res = await getRequest(url);
        if (res.data.errorCode === 1) {
          setIsAllowAdd(true);
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    if (selectAccountCode) {
      checkAllowEdit();
    }
  }, [selectAccountCode, currentCompanyID]);
  const onSubmit = (values, resetForm) => {
    postRequest('addInitBalance', {
      ...values,
      balanceDate: dataTimeFormat(values?.balanceDate),
      amountDebit: removeCommas(values.amountDebit),
      amountCredit: removeCommas(values.amountCredit),
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          localStorage.setItem('initBalanceId', values?.accountCode);
          getData(pageNumber);
          resetForm();
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <InitialBalancesForm
        onSubmit={onSubmit}
        getInitBalanceID={0}
        balanceDate={periodStart}
        closeModal={handleClose}
        setSelectAccountCode={setSelectAccountCode}
      />

      <Alert description={errorHandler} onClose={setErrorHandler} />
      {isAllowAdd && (
        <AcceptOrCancelModal
          closeModal={closeModal}
          confirmClick={() => setIsAllowAdd(false)}
          modalContent={InitBalancesAllowAddText}
        />
      )}
    </>
  );
};

export default EventAddForm;
