export const GET_INVOICE_DATA = "GET_INVOICE_DATA";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const LOADING = "LOADING";
export const GET_INVOICE_FORM_DATA = "GET_INVOICE_FORM_DATA";
export const ADD_INVOICE_GOODS = "ADD_INVOICE_GOODS";
export const DELETE_INVOICE_GOODS = "DELETE_INVOICE_GOODS";
export const UPDATE_INVOICE_DATA = "UPDATE_INVOICE_DATA";
export const UPDATE_INVOICE_TABLE_DATA = "UPDATE_INVOICE_TABLE_DATA";
export const UPDATE_PARTNER_INVOICE = "UPDATE_PARTNER_INVOICE";
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_UNIT_OF_MEASURE = 'ADD_UNIT_OF_MEASURE';