import { deleteRequest, getRequest, postRequest } from '../../App/Api/Api';
import { dataTimeFormat } from '../../App/Utilities/Utilities';

export const fetchAccountingFormulations = async (currentCompanyID) => {
  try {
    const response = await getRequest(
      `getAccStatements?companyID=${currentCompanyID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchSalaryMonths = async (currentCompanyID, year) => {
  if (!year) {
    return;
  }
  try {
    const response = await getRequest(
      `getSalaryMonths?companyID=${currentCompanyID}&year=${year}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchDateOfFormation = async (currentCompanyID, query) => {
  const { year, month } = query || {};
  if (!year || !month) {
    return;
  }
  try {
    const response = await getRequest(
      `getStatements?companyID=${currentCompanyID}&year=${year}&month=${month}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchNewFormulations = async (companyID) => {
  try {
    const response = await getRequest(
      `getNewAccStatements?companyID=${companyID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const saveAccountingFormulation = async (data) => {
  try {
    const response = await postRequest('saveAccStatements ', data);
    return response.data;
  } catch (error) {
    console.error('Error adding saveAccountingFormulation:', error);
    throw error;
  }
};

export const removeAccountingFormulation = async (companyID, salaryDate) => {
  try {
    const response = await deleteRequest(
      `deleteStatements?companyID=${companyID}&salaryDate=${dataTimeFormat(
        salaryDate,
      )}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting Formulation:', error);
    throw error;
  }
};
