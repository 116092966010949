import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRequest, putRequest } from '../../../Api/Api';
import { editCashOutflowOrderAction } from '../../../Redux/cashOutflowOrderSlices/useAction';
import {
  dataTimeFormat,
  editAgreeText,
  removeCommas,
  typOfString,
} from '../../../Utilities/Utilities';
import CashOutflowOrderForm from './CashOutflowOrderForm';
import Alert from '../../../Components/Alert';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';

const EventEditForm = ({ modalClick, editRowData }) => {
  const [errorHandler, setErrorHandler] = useState('');
  const [isAllowEdit, setIsAllowEdit] = useState(false);
  const handleClose = () => modalClick(false);
  const dispatch = useDispatch();
  useEffect(() => {
    async function checkAllowEdit() {
      try {
        const url = `preDeleteEditCashOut?documentID=${editRowData.documentID}`;
        const res = await getRequest(url);
        if (res.data.errorCode === 100) {
          setIsAllowEdit(true);
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    if (editRowData.documentID) {
      checkAllowEdit();
    }
  }, [editRowData.documentID]);

  const onSubmit = async (values) => {
    if (isAllowEdit) {
      return;
    }

    try {
      const formatDocDate = dataTimeFormat(values.docDate);
      const res = await putRequest('editCashOut', {
        ...values,
        docDate: formatDocDate,
        docNum: typOfString(values.docNum),
        amount: removeCommas(values.amount),
        amountAMD: removeCommas(values.amountAMD),
      });

      if (res.data.errorCode === 0) {
        dispatch(editCashOutflowOrderAction(values));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    } catch (err) {
      console.error('Error submitting form:', err);
    }
  };

  return (
    <>
      <CashOutflowOrderForm
        handleClose={handleClose}
        initialData={editRowData}
        onSubmit={onSubmit}
        setErrorHandler={setErrorHandler}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
      {isAllowEdit && (
        <AcceptOrCancelModal
          closeModal={modalClick}
          confirmClick={() => setIsAllowEdit(false)}
          modalContent={editAgreeText}
        />
      )}
    </>
  );
};

export default EventEditForm;
