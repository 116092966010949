import React, { memo } from 'react';
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';

const BankTable = memo(({ mainData, loading }) => {
  const columnConfig = [
    {
      title: 'Հ/Հ',
      cell: (row) => row.bankID,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Հնգանիշ կոդ',
      cell: (row) => row.bankCode,
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Անվանում',
      cell: (row) => row.bankName,
      customStyle: { maxWidth: 1200 },
    },
  ];

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-bank-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}
    </>
  );
});

export default BankTable;
