import React from "react";
import './style.css'; 

const Close = ({
  onClick
}) => {
  return (
    <div className="close-wrap" onClick={onClick}>
      <div className="close"></div>
    </div>
  );
};

export default Close;
