import { createAction } from "../actionCreators";
import {
  GET_INVOICING_ACCOUNT_DATA,
  LOADING,
  UPDATE_INVOICING_ACCOUNT_VALUE,
  GET_OLD_INVOICING_DATA,
  UPDATE__OLD_INVOICING_ACCOUNT_VALUE,
} from './constants';

export const getInvoicingAccountAction = createAction(
  GET_INVOICING_ACCOUNT_DATA,
);
export const loadingAction = createAction(LOADING, false);

export const updateInvoicingAccountAction = createAction(
  UPDATE_INVOICING_ACCOUNT_VALUE,
);

export const getOldInvoicingAction = createAction(GET_OLD_INVOICING_DATA);

export const updateOldInvoicingAction = createAction(
  UPDATE__OLD_INVOICING_ACCOUNT_VALUE,
);