import * as Yup from 'yup';
import { firstLetterString, matchesChar } from './REG_EXP';
import { EnterTextOnly } from './ErrorMessage';
export const AuthLoginValidation = () =>
  Yup.object().shape({
    email: Yup.string()
      .min(1, '')
      .required('Այս դաշտն անհրաժեշտ է լրացնել')
      .email('Անվավեր էլեկտրոնային հասցե')
      .nullable(),
    password: Yup.string().required('Այս դաշտն անհրաժեշտ է լրացնել').nullable(),
  });

export const AuthRegisterValidation = () =>
  Yup.object().shape({
    firstname: Yup.string()
      .matches(matchesChar, EnterTextOnly)
      .required()
      .min(1, 'Մինիմում 3 նիշ')
      .required()
      .max(15, 'Մաքսիմում 15 նիշ')
      .required()
      .required('Այս դաշտն անհրաժեշտ է լրացնել'),
    lastname: Yup.string()
      .matches(matchesChar, EnterTextOnly)
      .required()
      .min(1, 'Մինիմում 1 նիշ')
      .max(25, 'Մաքսիմում 25 նիշ')
      .required()
      .required('Այս դաշտն անհրաժեշտ է լրացնել'),
    email: Yup.string()
      .matches(firstLetterString, 'Մուտքագրեք տառեր')
      .matches(/^[~`_@&;':",.a-zA-Z0-9-]+$/, 'Մուտքագրեք միայն լատինատառ')
      .email('Անվավեր էլեկտրոնային հասցե')
      .required('Անհրաժեշտ է լրացնել «Էլ. հասցե» դաշտը'),

    password: Yup.string()
      .min(6, 'Լրացրեք առնվազն 6 նիշ')
      .required()
      .max(20, 'Գաղտնաբառը չպետք է գերազանցի 20 նիշը')
      .required(''),
    confirmPassword: Yup.string()
      .label('')
      .required('Այս դաշտն անհրաժեշտ է լրացնել')
      .oneOf([Yup.ref('password'), ''], 'Հաստատել գաղտնաբառը'),
    phone: Yup.string(),

    privacyPulicySign: Yup.boolean()
      .required('Համաձայն եմ գաղտնիության հետ')
      .oneOf([true], 'Համաձայն եմ գաղտնիության հետ'),
  });

export const ValidationWriteToUs = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  fromEmail: Yup.string().email('Invalid email').required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  body: Yup.string().required('Body is required'),
});
