import React from 'react';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
import PartnersForm from './PartnersForm';
import usePartner from '../../hooks/usePartner';

const EventEditForm = ({ handleClose, rowValue }) => {
  const { updateCurrentPartner, errorMess, setErrorMess } = usePartner();
  const isMounted = useIsMounted();
  const onUpdateHandler = async (values) => {
    try {
      await updateCurrentPartner(values, handleClose);
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <PartnersForm
        partnerValues={rowValue}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventEditForm;
