import * as Yup from "yup";
import { matchesNumber } from './REG_EXP';
import { EnterNumberOnly, SelectFieldMessage } from './ErrorMessage';

const isRequired = 'Պահանջվում է';
export const myPartnerBakValidation = () => {
  return Yup.object().shape({
    bankName: Yup.string().when(['bankType'], (bankType) => {
      if (bankType === 0) {
        return Yup.string().required(isRequired);
      }
    }),
    foreignBankName: Yup.string().when(['bankType'], (bankType) => {
      if (bankType === 1) {
        return Yup.string().required(isRequired);
      }
    }),
    bankingAccount: Yup.string().when(['bankType'], (bankType) => {
      if (bankType === 0) {
        return Yup.string()
          .max(25, '25 նիշ')
          .matches(matchesNumber, EnterNumberOnly)
          .required(isRequired);
      }
      if (bankType === 1) {
        return Yup.string().max(40, '40 նիշ').required(isRequired);
      }
    }),
  });
};

export const myBakValidation = () => {
  return Yup.object().shape({
    bankName: Yup.string().required(SelectFieldMessage),
    bankingAccount: Yup.string()
      .max(25, '25 նիշ')
      .matches(matchesNumber, EnterNumberOnly)
      .required(isRequired),
  });
};


