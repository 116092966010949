import * as Yup from 'yup';
import { InputFiledMessage, SelectFieldMessage } from './ErrorMessage';
import { Amount } from './ValidationForAllFiled';

export const AssignAdditionalValidation = () =>
  Yup.object()
    .shape({
      stName: Yup.string().required(SelectFieldMessage),
      setDate: Yup.date().required(InputFiledMessage),

      sAmount: Yup.string().nullable(),
      sPercent: Yup.number().nullable(),
    })
    .test(
      'at-least-one-value',
      'Either sAmount or sPercent must have a value greater than 0',
      function (values) {
        const { sAmount, sPercent } = values;

        const parsedAmount = sAmount
          ? parseFloat(sAmount.replace(/,/g, ''))
          : null;

        if (!parsedAmount && !sPercent) {
          return this.createError({
            path: 'sAmount',
            message: 'Either sAmount or sPercent is required',
          });
        }

        if (parsedAmount && parsedAmount <= 0 && sPercent && sPercent <= 0) {
          return this.createError({
            path: 'sAmount',
            message: 'sAmount or sPercent must be greater than 0',
          });
        }

        return true;
      },
    );

export const AssignPositionModalValidation = () =>
  Yup.object().shape({
    stName: Yup.string().required(SelectFieldMessage),
    setDate: Yup.date().required(InputFiledMessage),
    sAmount: Amount,
  });
