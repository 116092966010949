import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../../Api/Api';
import Calendar from '../../../Components/Calendar/Calendar';
import GetSentDataBtn from '../../../Components/GetSentDataBtn/GetSentDataBtn';
import InputFiled from '../../../Components/InputFiled/InputFiled';
import Modal from '../../../Components/Modal/Modal';
import SelectAccount from '../../../Components/SelectBox/SelectAccount';
import SelectBank from '../../../Components/SelectBox/SelectBank';
import {
  dataTimeFormat,
  initialDate,
  removeCommas,
  amountIsNotEnough,
  Commas,
} from '../../../Utilities/Utilities';

import NumericFormatFiled from '../../../Components/NumericFormat/NumericFormatFiled';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { useSelector } from 'react-redux';
import useGetDocNum from '../../../Hooks/useGetDocNum';

const GetPaymentOrderData = ({ handleClose, rowData }) => {
  const [errorHandler, setErrorHandler] = useState('');
  const [paymentData, setPaymentData] = useState([]);
  const openPaymentForm = () => {
    const { excelInvoiceID, recivedWrittenOff } = rowData;
    if (recivedWrittenOff === 1) {
      getRequest(`getInvoicePaymentOrder?excelInvoiceID=${excelInvoiceID}`)
        .then((res) => {
          if (res.data.message === null) {
            setPaymentData(res.data);
          }
          if (res.data.message !== null) {
            setErrorHandler(res.data.message);
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }

    if (recivedWrittenOff === 2) {
      getRequest(`getInvoiceBankOrder?excelInvoiceID=${excelInvoiceID}`)
        .then((res) => {
          if (res.data.message === null) {
            setPaymentData(res.data);
          }
          if (res.data.message !== null) {
            setErrorHandler(res.data.message);
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  };

  useEffect(() => {
    openPaymentForm();
  }, []);

  const { currentCompanyID } = useSelector((state) => state.auth);

  const [values, setValues] = useState({});
  const [docDate, setDocDate] = useState(initialDate);
  const [accountCredit, setAccountCredit] = useState({});
  const [initAmount, setInitAmount] = useState(0);
  const [aggerBigAmount, setAggerBigAmount] = useState(true);
  const [showDebitBalance, setShowDebitBalance] = useState(false);
  const dateStartFormat = dataTimeFormat(docDate);
  const [companyBankData, setCompanyBankData] = useState([]);
  const [bankCode, setBankCode] = useState(null);
  const { partnerName, partnerTaxCode, otherInformation, currencyID } =
    paymentData || {};

  const mainAmount = paymentData?.amount;

  const [amount, setAmount] = useState(mainAmount);
  const { docNum, setDocNum } = useGetDocNum(currentCompanyID, dateStartFormat);

  const [settlementAccount, setSettlementAccount] = useState({
    label: '',
    value: '',
    id: '',
  });

  const [companyBankVal, setCompanyBankVal] = useState({
    label: '',
    value: '',
    id: '',
    bankName: '',
  });

  const [partnerBankVal, setPartnerBankVal] = useState({
    label: '',
    value: '',
    id: '',
    bankName: '',
  });

  useEffect(() => {
    setDocNum(docNum);
    setAmount(mainAmount);
  }, [docNum, mainAmount]);

  useEffect(() => {
    if (values.account) {
      getRequest(
        `getAccountBalance?companyID=${currentCompanyID}&account=${values.account}&currencyID=${currencyID}&dateEnd=${dateStartFormat}`,
      )
        .then((res) => {
          setInitAmount(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [values.account, currentCompanyID, dateStartFormat, currencyID]);

  useEffect(() => {
    if (settlementAccount) {
      getRequest(
        `getCurrentCompanyBanks?companyID=${currentCompanyID}&bankCode=${bankCode}`,
      )
        .then((res) => {
          setCompanyBankData(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [settlementAccount, currentCompanyID, bankCode]);

  const handlerSettlementAccountChange = (value) => {
    if (!value) {
      value = {
        value: '',
      };
    }
    setSettlementAccount(value);
    setBankCode(value.bankCode);
  };

  const handlerCompanyBankChange = (value) => {
    if (!value) {
      value = {
        value: '',
      };
    }
    setCompanyBankVal(value);
  };

  const handlerPartnerBankChange = (value) => {
    if (!value) {
      value = {
        value: '',
      };
    }
    setPartnerBankVal(value);
  };

  useEffect(() => {
    const addValueObj = {
      excelInvoiceID: rowData.excelInvoiceID,
      docNum: docNum?.toString(),
      docDate: dateStartFormat,
      companyAccount: companyBankVal?.value,
      partnerAccount: partnerBankVal?.value,
      account: settlementAccount?.value,
      amount,
      accountCredit: accountCredit.value,
    };
    setValues((prevValues) => ({ ...prevValues, ...addValueObj }));
  }, [
    amount,
    companyBankVal?.value,
    dateStartFormat,
    settlementAccount?.value,
    docNum,
    rowData.excelInvoiceID,
    partnerBankVal?.value,
    accountCredit.value,
  ]);
  const theDebitIsExceeded =
    removeCommas(values.amount) > initAmount && initAmount ? true : false;

  useEffect(() => {
    if (theDebitIsExceeded) {
      setShowDebitBalance(true);
    }
  }, [theDebitIsExceeded]);

  const changeCreditAccountHandler = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setAccountCredit(values);
  };
  const yesConfirmClick = () => {
    setAggerBigAmount(false);
    setShowDebitBalance(false);
  };
  const noHandler = () => {
    setAggerBigAmount(true);
    setShowDebitBalance(false);
  };

  const disableBtn = (val) => {
    if (theDebitIsExceeded && aggerBigAmount) {
      return true;
    } else if (val.companyAccount === '' || val.partnerAccount === '') {
      return true;
    } else if (!val.accountCredit) {
      return true;
    } else if (val.docNum === '') {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    postRequest('saveInvoicePaymentOrder', {
      ...values,
      amount: removeCommas(values.amount),
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <Modal
        closeHandler={handleClose}
        customClass="L-payment-order-modal"
        onDrag={true}
      >
        <div className="L-payment-order-modal-content">
          <div className="G-modal-block-title">
            <h3>Վճարման հանձնարարագիր</h3>
          </div>
          <div className="L-payment-order-header G-flex">
            <div className="G-form-data-picker">
              <p className="G-title-p">Ամսաթիվ</p>
              <Calendar
                selected={docDate}
                changeHandler={(e) => setDocDate(e, 'docDate')}
                name="docDate"
              />
            </div>
            <div className=" G-docNum-input-not-border">
              <InputFiled
                inputTitle="Փաստաթուղթ N"
                value={docNum}
                name="docNum"
                changeHandler={(e) => setDocNum(e.target.value)}
              />
            </div>
          </div>
          <div className="L-payment-form G-flex-justify-between">
            <div className="L-payment-form-panel">
              <div className="G-flex">
                <h3 className="h3">Վճարող</h3>
                <p className="G-title-p" style={{ marginLeft: '15px' }}>
                  Առկա գումարը{' '}
                  <strong className="G-available-amount">
                    {' '}
                    {Commas(initAmount)}
                  </strong>
                </p>
              </div>
              <SelectAccount
                customClass="L-payment-order-select-wrapper"
                title="Հաշիվ"
                accountData={paymentData?.debetAccounts}
                accountVal={{ label: settlementAccount.value }}
                selectChangeAccount={(values) =>
                  handlerSettlementAccountChange(values)
                }
              />

              <SelectBank
                customClass="L-payment-order-select-wrapper"
                bankData={companyBankData}
                bankVal={{ label: companyBankVal.value }}
                selectChangeBank={(values) => handlerCompanyBankChange(values)}
                requiredFiled={true}
              />
              <div className="L-payment-order-select-wrapper">
                <p className="G-title-p">Բանկ</p>
                <p> {companyBankVal?.bankName}</p>
              </div>

              <div className="G-amount-input">
                <NumericFormatFiled
                  value={amount}
                  name="amount"
                  changeHandler={(e) => setAmount(e.target.value)}
                  inputTitle="Գումարը"
                  requiredFiled={true}
                />
              </div>
              <div className="L-payment-order-select-wrapper">
                <p className="G-title-p">Վճարման նպատակը</p>
                <p> {otherInformation}</p>
              </div>
            </div>
            <div className="L-payment-form-panel">
              <h3 className="h3">Ստացող</h3>
              <div className="L-payment-order-select-wrapper">
                <p className="G-title-p">Գործընկեր</p>
                <p> {partnerName}</p>
              </div>
              <div className="L-payment-order-select-wrapper">
                <p className="G-title-p">Գործընկեր</p>
                <p> {partnerTaxCode}</p>
              </div>
              <SelectBank
                customClass="L-payment-order-select-wrapper"
                bankData={paymentData?.partnerBanks}
                bankVal={partnerBankVal}
                selectChangeBank={(values) => handlerPartnerBankChange(values)}
                requiredFiled={true}
              />
              <div className="L-payment-order-select-wrapper">
                <p className="G-title-p">Բանկ</p>
                <p> {partnerBankVal.bankName}</p>
              </div>
              <div className="L-payment-order-select-wrapper">
                <SelectAccount
                  customClass="L-payment-order-select-wrapper"
                  title="Թղթակցող հաշիվը"
                  accountData={paymentData?.correspAccounts}
                  accountVal={{
                    label: accountCredit.value,
                    value: accountCredit.value,
                  }}
                  selectChangeAccount={(values) =>
                    changeCreditAccountHandler(values)
                  }
                />
              </div>
            </div>
          </div>
          <GetSentDataBtn
            cancelPrintClick={handleClose}
            confirmExecuteClick={handleSubmit}
            executeClass={disableBtn ? 'G-disabled-button' : ''}
            executeDisabled={disableBtn(values)}
          />
        </div>
      </Modal>
      {showDebitBalance && (
        <AcceptOrCancelModal
          closeModal={noHandler}
          confirmClick={yesConfirmClick}
          modalContent={amountIsNotEnough}
        />
      )}
    </>
  );
};

export default GetPaymentOrderData;
