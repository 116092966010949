import React, { useEffect, useState } from 'react';

import useUnitsMeasure from '../../hooks/useUnitsMeasure';
import Header from '../../../App/Components/Header/Header';
import EventAddForm from './EventAddForm';
import UnitsMeasureTable from './UnitsMeasureTable';
import UnitsMeasureFilter from './UnitsMeasureFilter';
import Pagination from '../../../App/Components/Pagination/Pagination';
import './style.scss';
import useFetchExcel from '../../../App/Hooks/useFetchExcel';
import { useSelector } from 'react-redux';

const MaterialValuesUnitsMeasure = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [params, setParams] = useState({
    searchString: '',
    pageNumber: 0,
  });
  const { getUnitsMeasure, unitOfMeasureData, isLoading } = useUnitsMeasure();
  const { fetchExcelData } = useFetchExcel();
  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };
  useEffect(() => {
    getUnitsMeasure(params);
  }, [params]);

  const handlePageChange = (newPageNumber) => {
    setParams((prevState) => ({
      ...prevState,
      pageNumber: newPageNumber,
    }));
  };
  const getExcelData = () => {
    const trimmedSearchValue = params.searchString?.trim();
    const url = `getUnitsForExcel?companyID=${currentCompanyID}&searchString=${trimmedSearchValue}`;

    return fetchExcelData(url);
  };
  return (
    <div className="G-small-wrap">
      <Header pageTitle="Չափման միավորներ" handelClick={onClose} />
      <UnitsMeasureFilter
        params={params}
        setParams={setParams}
        getExcelData={getExcelData}
      />
      <UnitsMeasureTable
        unitOfMeasureData={unitOfMeasureData?.units}
        isLoading={isLoading}
      />
      <Pagination
        pageCount={unitOfMeasureData?.pageCount?.pageCount}
        setPageNumber={handlePageChange}
        currentPage={params.pageNumber}
      />
      {showAddForm && <EventAddForm handleClose={onClose} params={params} />}
    </div>
  );
};

export default MaterialValuesUnitsMeasure;
