import React, { useState } from 'react';
import { useFormik } from 'formik';

import InputFiled from '../../../Components/InputFiled/InputFiled';
import Button from '../../../Components/Button/Button';
import './writeToUs.scss';
import FileUpload from './FileUpload';
import { postRequest } from '../../../Api/Api';
import { ValidationWriteToUs } from '../../../Validation/AuthValidation';
import Alert from '../../../Components/Alert';

const WriteToUs = () => {
  const [postIsDone, setPostIsDone] = useState(false);
  const [localFile, setLocalFile] = useState(null);
  const [errorHandler, setErrorHandler] = useState('');
    const {
      handleSubmit,
      handleChange,
      values,
      touched,
      errors,
      isValid,
      handleBlur,
      dirty,
      setFieldValue,
    } = useFormik({
      mode: 'onBlur',
      initialValues: {},
      validationSchema: ValidationWriteToUs,
      onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
    });

    const onSubmit = async (values, resetForm) => {
      const data = new FormData();
      data.append('file', localFile);
      data.append('MailData', JSON.stringify(values));

      await postRequest('sendMailFromUser', data)
        .then((res) => {
          const { message, errorCode } = res.data;
          if (errorCode === 0) {
            setPostIsDone(true);
            resetForm();
          }
          if (message) {
            setErrorHandler(message);
          }
        })
        .catch((err) => {
          console.log('err' + err);
        });
    };
  return (
    <div className="write-to-us-form">
      <div className="contact-us-title-area">
        <span>Հեռախոս</span>
      </div>

      {postIsDone ? (
        <div className="show-form-mess">
          <p>Ձեր նամակը հաջողությամբ ուղարկվեց</p>
        </div>
      ) : (
        <>
          <div className="contact-us-address-and-phone">
            <p> 012239313 </p>
          </div>
          <form onSubmit={onSubmit}>
            <div className="write-to-us-input">
              <InputFiled
                customClass={`L-input-has-border ${
                  touched.name && errors.name
                    ? 'G-invalid-input'
                    : 'G-valid-input'
                }`}
                inputTitle=""
                placeholder="Անուն"
                name="name"
                value={values.name}
                changeHandler={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="write-to-us-input">
              <InputFiled
                customClass={`L-input-has-border ${
                  touched.fromEmail && errors.fromEmail
                    ? 'G-invalid-input'
                    : 'G-valid-input'
                }`}
                inputTitle=""
                placeholder="էլեկտրոնային հասցե"
                name="fromEmail"
                value={values.fromEmail}
                changeHandler={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="write-to-us-input">
              <FileUpload setLocalFile={setLocalFile} />
            </div>

            <div className="write-to-us-input">
              <InputFiled
                customClass={`L-input-has-border ${
                  touched.subject && errors.subject
                    ? 'G-invalid-input'
                    : 'G-valid-input'
                }`}
                inputTitle=""
                placeholder="Թեմա"
                name="subject"
                value={values.subject}
                changeHandler={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="write-to-us-textarea">
              <textarea
                className={` ${
                  touched.body && errors.body
                    ? 'G-invalid-input'
                    : 'G-valid-input'
                }`}
                name="body"
                value={values.body}
                placeholder="Մուտքագրեք ձեր հաղորդագրությունը այստեղ..."
                onChange={handleChange}
                id=""
                cols=""
                rows="5"
                onBlur={handleBlur}
              ></textarea>
            </div>
            <div className="sent-btn">
              <Button
                onClick={handleSubmit}
                customClass={!isValid ? 'G-disabled-button' : ''}
                disabled={!isValid}
                text="Ուղարկել"
                type="submit"
              />
            </div>
          </form>
        </>
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </div>
  );
};

export default WriteToUs;
