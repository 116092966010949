import { useEffect, useState } from 'react';
import {
  addAssignPosition,
  fetchAssignPositions,
  fetchExecuteAssignPositions,
} from '../services/fetchAssignPositions';
import { useSelector } from 'react-redux';
import { removeCommas } from '../../App/Utilities/Utilities';

const useAssignPositions = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [assignPosition, setAssignPosition] = useState(null);
  const [executeAssignPosition, setExecuteAssignPosition] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  useEffect(() => {
    const getAssignPosition = async () => {
      setIsLoading(true);
      try {
        const response = await fetchAssignPositions(currentCompanyID);
        setAssignPosition(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getAssignPosition();
    return () => {
      setAssignPosition(null);
    };
  }, [currentCompanyID]);

  const getExecuteAssignPosition = async (query) => {
    setIsLoading(true);
    try {
      const response = await fetchExecuteAssignPositions(
        currentCompanyID,
        query,
      );
      setExecuteAssignPosition(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const addAssignPositionsItems = async (newAssignSalaries) => {
    setIsLoading(true);
    if (!Array.isArray(newAssignSalaries?.salarySets)) {
      throw new Error('Input must be an array');
    }

    const updatedRows = newAssignSalaries?.salarySets?.map((row) => ({
      ...row,
      sAmount: removeCommas(row.sAmount),
    }));

    try {
      const { errorCode, message } = await addAssignPosition(updatedRows);
      if (errorCode === 0) {
        setIsRegister(true);
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const removeAssignPositionsItem = async (query) => {
    setIsLoading(true);
    const updatedData = executeAssignPosition?.salarySets?.map((item) => {
      if (item.empNo !== query.empNo) {
        return { ...item, amount: 0 };
      }
      return item;
    });
    setExecuteAssignPosition(updatedData);
  };
  useEffect(() => {
    return () => {
      setIsRegister(false);
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    assignPosition,
    getExecuteAssignPosition,
    executeAssignPosition,
    errorMess,
    isLoading,
    addAssignPositionsItems,
    removeAssignPositionsItem,
    isRegister,
  };
};

export default useAssignPositions;
