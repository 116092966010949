import React from 'react';
import Button from '../../../../App/Components/Button/Button';
import HeaderItem from './HeaderItem';
import Checkbox from '../../../../App/Components/Checkbox/Checkbox';

const AssignAdditionalStorageHeader = ({
  assignStorage,
  handelClick,
  disabledAddBtn,
  currentHold,
  setCurrentHold,
}) => {
  const { depName, empNo, empName } = assignStorage || {};

  const headerList = [
    {
      value: depName,
    },
    {
      name: 'Տաբել N',
      value: empNo,
    },
    {
      value: empName,
    },
  ];
  return (
    <div className="assign-storage-header">
      <div className="G-add-btn">
        <Button
          type="button"
          text="Ավելացնել"
          onClick={handelClick}
          disabled={disabledAddBtn}
          customClass={disabledAddBtn && 'G-disabled-button'}
        />
      </div>

      {headerList?.map((item) => (
        <HeaderItem key={item.value} name={item.name} value={item.value} />
      ))}

      <Checkbox
        checked={currentHold}
        label="Ընթացիկ Հավելում/պահում"
        name="current"
        value={currentHold}
        onChange={() => setCurrentHold(!currentHold)}
      />
    </div>
  );
};

export default AssignAdditionalStorageHeader;
