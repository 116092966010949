import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import CalculationDataTable from './CalculationDataTable';
import CalculationDataAction from './CalculationDataAction';
import CalculationDataTotal from './CalculationDataTotal';
import { filterObjKeys } from '../../../App/Utilities/Utilities';
import './style.scss';
import useCalculationData from '../../hooks/useCalculationData';
import { ConvertCalculationDataExcel } from '../../Config/excelConfig';

const CalculationDataViewing = () => {
  const {
    calculationData,
    getExecuteCalculationData,
    isLoading,
    calculationTableData,
    setCalculationTableData,
  } = useCalculationData();
  const { salaryEntries, totalAdd, totalDed } = calculationTableData || {};
  const [query, setQuery] = useState({
    startDate: '',
    endDate: '',
    stNo: '',
    depNo: '',
    name: '',
    eContract: '',
    cContract: '',
  });
  const keysToRemove = ['workDeps', 'salaryTypes'];

  const dataToExcel = ConvertCalculationDataExcel(salaryEntries);
  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      startDate: calculationData?.startDate || '',
      endDate: calculationData?.endDate || '',
      eContract: calculationData?.eContract,
      cContract: calculationData?.cContract,
    }));
  }, [calculationData]);

  const resetTableData = () => {
    setCalculationTableData(null);
  };

  const onExecuteHandler = async () => {
    try {
      const modifyObj = filterObjKeys(query, keysToRemove);
      await getExecuteCalculationData(modifyObj);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <div>
      <Header
        pageTitle="Հաշվարկային տվյալների դիտում"
        headerBtn={false}
        showCalculationDate={true}
      />
      <CalculationDataAction
        query={query}
        setQuery={setQuery}
        calculationData={calculationData}
        onExecuteHandler={onExecuteHandler}
        dataToExcel={dataToExcel}
        salaryEntries={salaryEntries}
        resetTableData={resetTableData}
      />

      <CalculationDataTable
        calculationTableData={salaryEntries}
        isLoading={isLoading}
      />
      {salaryEntries?.length > 0 && (
        <CalculationDataTotal total={{ totalAdd, totalDed }} />
      )}
    </div>
  );
};

export default CalculationDataViewing;
