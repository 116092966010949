import React from 'react';
import { Commas, dateFormat } from '../../Utilities/Utilities';
import Modal from '../../Components/Modal/Modal';
import Table from '../../Components/Table/Table';
import Tooltip from '../../Components/Tooltip/Tooltip';

const PartnerAccountBalances = ({ closeModal, data }) => {
  const handleClose = () => closeModal(false);
  const currentData = data;
  const columnConfig = [
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.balanceDate),
      customStyle: { maxWidth: 80 },
    },

    {
      title: 'Գործընկերը',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 350 },
    },
    {
      title: 'Արժույթը',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 70 },
    },
    {
      title: <Tooltip content="Դեբետային մնացորդ">Դ/մնացորդ</Tooltip>,
      cell: (row) => Commas(row.amountDebit),
      customStyle: { maxWidth: 110 },
    },

    {
      title: <Tooltip content="Կրեդիտային մնացորդ">Կ/մնացորդ</Tooltip>,
      cell: (row) => Commas(row.amountCredit),
      customStyle: { maxWidth: 110 },
    },

    {
      title: <Tooltip content="Դեբետային մնացորդ AMD">Դ/մնացորդ AMD</Tooltip>,

      cell: (row) => Commas(row.amountDebitAMD),
      customStyle: { maxWidth: 130 },
    },
    {
      title: <Tooltip content="Կրեդիտային մնացորդ AMD">Կ/մնացորդ AMD</Tooltip>,
      cell: (row) => Commas(row.amountCreditAMD),
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Նշումները',
      cell: (row) => row.comment,
      customStyle: { maxWidth: 300 },
    },
  ];

  return (
    <Modal closeHandler={handleClose} customClass="L-partner-account-balance">
      <>
        {currentData !== null && currentData && currentData?.length ? (
          <Table
            customClass="G-table-has-scroll"
            data={currentData}
            columnConfig={columnConfig}
          />
        ) : (
          ''
        )}
      </>
    </Modal>
  );
};

export default PartnerAccountBalances;
