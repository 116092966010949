import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { postRequest } from '../../Api/Api';
import InputFiled from '../../Components/InputFiled/InputFiled';
import { AuthRegisterValidation } from '../../Validation/AuthValidation';
import Button from '../../Components/Button/Button';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import { ReactComponent as User } from '../../Assets/icons/user.svg';
import { ReactComponent as Phone } from '../../Assets/icons/phone.svg';
import { ReactComponent as Lock } from '../../Assets/icons/lock.svg';
import { ReactComponent as Epistle } from '../../Assets/icons/epistle.svg';
import { ReactComponent as CloseEye } from '../../Assets/icons/eye-close.svg';
import { ReactComponent as ShowEye } from '../../Assets/icons/eye-show.svg';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import { authRegister } from '../../Redux/authSlices/useAction';
import DataSentMessage from './DataSentMessage';
import Spinner from '../../Components/Spinner/Spinner';
import Alert from '../../Components/Alert';
import OneWindowLogo from '../../Components/OneWindowLogo/OneWindowLogo';
import Checkbox from '../../Components/Checkbox/Checkbox';

const Register = ({ togglePage, onToggleTab }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorHandler, setErrorHandler] = useState(false);
  const [toggledPage, setToggledPage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    isValid,
    dirty,
    setFieldValue,
  } = useFormik({
    mode: 'onBlur',
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      phone: '',
      confirmPassword: '',
    },
    validationSchema: AuthRegisterValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const onSubmit = async (values, resetForm) => {
    setIsSubmitting(true);

    postRequest('addUser', values)
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(authRegister(res.data));
          resetForm();
          setToggledPage(true);
          setIsSubmitting(false);
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      {!toggledPage ? (
        <div className="G-login-register-main-form L-register-form">
          <div className="G-log-reg-form-header">
            <OneWindowLogo handleClick={onToggleTab} />
            <h3>Մեկ պատուհան</h3>
          </div>

          <div className="G-log-reg-container">
            <form onSubmit={handleSubmit}>
              <div className="L-reg-box">
                <span className="G-asterisk">*</span>
                <div className="G-log-reg-input-wrapper">
                  <InputFiled
                    name="firstname"
                    value={values.firstname}
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    placeholder="Անուն"
                    customClass={`L-input-has-border ${
                      touched.firstname && errors.firstname
                        ? 'G-invalid-input'
                        : 'G-valid-input'
                    }`}
                  />
                  <span className="L-input-icon">
                    <User />
                  </span>
                </div>
              </div>

              <div className="L-reg-box">
                <span className="G-asterisk">*</span>
                <div className="G-log-reg-input-wrapper">
                  <InputFiled
                    name="lastname"
                    value={values.lastname}
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    placeholder="Ազգանուն"
                    customClass={`L-input-has-border ${
                      touched.lastname && errors.lastname
                        ? 'G-invalid-input'
                        : 'G-valid-input'
                    }`}
                  />

                  <span className="L-input-icon">
                    <User />
                  </span>
                </div>
              </div>
              <div className="L-reg-box">
                <span className="G-asterisk">*</span>
                <div className="G-log-reg-input-wrapper">
                  <InputFiled
                    name="email"
                    value={values.email}
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    placeholder="Էլ.հասցե"
                    customClass={`L-input-has-border ${
                      touched.email && errors.email
                        ? 'G-invalid-input'
                        : 'G-valid-input'
                    }`}
                  />

                  <span className="L-input-icon">
                    <Epistle />
                  </span>
                </div>
              </div>

              <div className="L-reg-box">
                <span className="G-asterisk">*</span>
                <div className="G-log-reg-input-wrapper">
                  <InputFiled
                    name="password"
                    type={passwordShown ? 'text' : 'password'}
                    value={values.password}
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    placeholder="Գաղտնաբառ"
                    customClass={`L-input-has-border ${
                      touched.password && errors.password
                        ? 'G-invalid-input'
                        : 'G-valid-input'
                    }`}
                  />
                  {touched.password && errors.password && (
                    <ErrorFiled error={errors.password} />
                  )}
                  <span className="L-input-icon">
                    <Lock />
                  </span>
                  <span
                    className="L-toggle-icon"
                    onClick={togglePasswordVisibility}
                  >
                    {passwordShown ? <ShowEye /> : <CloseEye />}
                  </span>
                </div>
              </div>

              {values.password && (
                <div
                  className="G-log-reg-input-wrapper"
                  style={{ marginLeft: '7px' }}
                >
                  <PasswordStrengthMeter password={values.password} />{' '}
                </div>
              )}

              <div className="L-reg-box">
                <span className="G-asterisk">*</span>
                <div className="G-log-reg-input-wrapper">
                  <InputFiled
                    name="confirmPassword"
                    type={passwordShown ? 'text' : 'password'}
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    placeholder="Հաստատել գաղտնաբառը"
                    customClass={`L-input-has-border ${
                      touched.confirmPassword && errors.confirmPassword
                        ? 'G-invalid-input'
                        : 'G-valid-input'
                    }`}
                  />

                  <span className="L-input-icon">
                    <Lock />
                  </span>
                  <span
                    className="L-toggle-icon"
                    onClick={togglePasswordVisibility}
                  >
                    {passwordShown ? <ShowEye /> : <CloseEye />}
                  </span>
                </div>
              </div>

              <div className="L-reg-box" style={{ marginLeft: '12px' }}>
                <div className="G-log-reg-input-wrapper ">
                  <InputFiled
                    name="phone"
                    type="tel"
                    value={values.phone}
                    onBlur={handleBlur}
                    changeHandler={handleChange}
                    placeholder="Հեռախոսահամար"
                    customClass="L-input-has-border"
                  />

                  <span className="L-input-icon">
                    <Phone />
                  </span>
                </div>
              </div>

              <div className="G-log-reg-btn">
                <Button
                  onClick={handleSubmit}
                  customClass={
                    !isValid || !dirty || isSubmitting
                      ? 'G-disabled-button'
                      : ''
                  }
                  disabled={!isValid || !dirty || isSubmitting}
                  text={isSubmitting ? <Spinner /> : 'Գրանցել'}
                  type="submit"
                />
              </div>

              <div className="privacy-policy-block">
                <p>
                  Սեղմելով գրանցել կոճակը դուք ընդունում եք կայքի{' '}
                  <a href="#">Օգտագործման կանոնները</a>
                </p>
                <Checkbox
                  checked={values.privacyPulicySign}
                  onBlur={handleBlur}
                  label={
                    <>
                      Համաձայն եմ <a href="">գաղտնիության քաղաքականություն</a>{' '}
                      հետ
                    </>
                  }
                  name="privacyPulicySign"
                  value={values.privacyPulicySign}
                  onChange={() =>
                    setFieldValue(
                      'privacyPulicySign',
                      !values.privacyPulicySign,
                    )
                  }
                />

                <div className="L-do-not-account G-flex-justify-between">
                  <p>Արդեն գրանցվել ե՞ք </p>
                  <strong onClick={() => togglePage('Login')}>
                    Մուտք գործեք
                  </strong>
                </div>
              </div>
            </form>

            <Alert description={errorHandler} onClose={setErrorHandler} />
          </div>
        </div>
      ) : (
        <DataSentMessage />
      )}
    </>
  );
};

export default Register;
