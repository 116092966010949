import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Action from '../../../App/Components/Table/Action';
import { tableActionTitle } from '../../../App/Utilities/Utilities';
import { globalActionItems } from '../../Config/tableConfig';
import Forms from './Forms';
import Loading from '../../../App/Components/Loading/Loading';

const PartnersTable = ({ partnersList, isLoading }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Անվանումը',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 1100, overflow: 'hidden' },
    },
    {
      title: 'Տիպը',
      cell: (row) => row.partnerTypeName,
      customStyle: { maxWidth: 230 },
    },
    {
      title: 'ՀՎՀՀ',
      cell: (row) => row.taxCode,
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'ՀԾՀ/Անձն./ID',
      cell: (row) => row.identDoc,
      customStyle: { maxWidth: 180, overflow: 'hidden' },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(value, row) =>
            setShowForm({
              type: value,
              value: row,
            })
          }
          actionItem={globalActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {partnersList !== null && partnersList && partnersList?.length ? (
        <Table customClass="" data={partnersList} columnConfig={columnConfig} />
      ) : null}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default PartnersTable;
