import React from 'react';
import './Checkbox.scss';

const Checkbox = (props) => {
  const {
    disabled,
    onClick,
    checked,
    onChange,
    name,
    value,
    label,
    changeCol,
    defaultValue,
    defaultChecked,
    onBlur,
  } = props;

  return (
    <div className="G-checkbox-wrapper G-flex-align-center ">
      <label>
        <input
          type="checkbox"
          name={name}
          value={value}
          onChange={onChange}
          checked={checked}
          onClick={onClick}
          defaultValue={defaultValue}
          defaultChecked={defaultChecked}
          disabled={disabled}
          onBlur={onBlur}
        />
      </label>
      <span style={{ color: changeCol && '#fff' }} className="label">
        {label}
      </span>
    </div>
  );
};

export default Checkbox;
