import { createAction } from "../actionCreators";
import {
  DELETE_PAYMENT_ORDER,
  EDIT_PAYMENT_ORDER,
  GET_PAYMENT_ORDER_DATA,
  LOADING,
  UPDATE_PARTNER_PAYMENT_ORDER,
} from "./constants";

export const getPaymentOrderAction = createAction(GET_PAYMENT_ORDER_DATA);
export const loadingAction = createAction(LOADING, false);
export const deletePaymentOrderAction = createAction(DELETE_PAYMENT_ORDER);
export const editPaymentOrderAction = createAction(EDIT_PAYMENT_ORDER);
export const updatePartnerAction = createAction(UPDATE_PARTNER_PAYMENT_ORDER);
export const addNewPartnerBankAction = createAction(
  UPDATE_PARTNER_PAYMENT_ORDER,
);