export const HMAspect = `
Անձնական բյուջեն նախատեսված է օգտվողի ֆինանսական ելքերն ու մուտքերը գրանցելու համար :
Նախնական ներկայացված են որոշ տիպեր։ 
Սեղմեք "Ստեղծել նոր տիպ" կոճակը, կբացվի տվյալների մուտքի ձև, մուտքագրեք տիպի անվանումը,ընտրեք նկարը, գույնը, տիպը՝  ծախս (և) կամ եկամուտ տարբերակներից, սեղմեք "Գրանցել" կոճակը՝ տվյալների պահպանման համար,կամ դուրս եկեք առանց գրանցման էկրանի վերին աջ անկյան "x" նշանով։ Նոր գրանցված տիպը կարտահայտվի տիպերի ցանկում:
Գրանցված տիպերը կարող եք խմբագրել կամ հեռացնել տիպի նկարի աջ կողմում գտնվող համապատասխան կոճակներով :
Ձևակերպեք "Ծախս" կամ "Եկամուտ" գրանցված տիպերից ցանկացածով, սեղմելով այդ տիպի կոճակը։ Կբացվի տվյալների մուտքի ձև, մուտքագրեք գումարը, նպատակը, ընտրեք արժույթը, սեղմեք "Գրանցել" կոճակը։ Կկատարվի տվյալների գրանցում և միաժամանակ աջակողմյան աղյուսակում կներկայացվի հենց նոր գրանցված տվյալը իր խմբագրման և հեռացման կոճակներով:
"Ժամանակահատված"-ի "Սկիզբ" և "Վերջ" կոճակների զույգը որոշում է գրանցված տվյալների ցուցադրման ժամանակատիրույթը։ Նախնական լրացված է ընթացիկ ամսվա 1-ից մինչև ընթացիկ օրը
`;

export const HMRepo = `
Հաշվետվությունը արտածում է կատարված բոլոր գործողությունները։
Օգտագործելով ժամանակահատվածի, արժույթի, տիպի ֆիլտրերը, հնարավոր է արտածել ընտրված գործողությունների ամփոփումը։
Բացված հաշվետվությունը հնարավոր է արտածել  Excel տարբերակով։
Աջ կողմում ներկայացված են գործողությունների գրաֆիկական տեսքը՝ ըստ ծախս/եկամուտ տիպի և ըստ արժույթների։ Երևում են նաև տոկոսային ցուցանիշները 
`;
