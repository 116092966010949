import React from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import SearchBar from '../../components/searchBar/SearchBar';
import { ExportExcel } from '../../../App/Components/Excelexport/Excelexport';

const SearchForm = ({ query, setQuery, dataToExcel }) => {
  return (
    <SearchBackground>
      <div className="G-search-form-flex">
        <SearchBar query={query} setQuery={setQuery} />
        <ExportExcel excelData={dataToExcel} fileName="" />
      </div>
    </SearchBackground>
  );
};

export default SearchForm;
