import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HelperTextArr } from '../HelperText/HelperText';
import './table.scss';
import { ReactComponent as EditIconSVG } from '../../Assets/icons/editForTable.svg';

const EditIcon = () => <EditIconSVG className="isEdit" />;

const Table = ({ columnConfig = [], data, customClass = '', onTableClick }) => {
  const [activeRow, setActiveRow] = useState(null);
  const location = useLocation();

  // Retrieve and manage `initBalanceId`
  const initialValue = JSON.parse(
    localStorage.getItem('initBalanceId') || 'null',
  );
  useEffect(() => {
    if (initialValue) {
      const timer = setTimeout(() => {
        localStorage.removeItem('initBalanceId');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [initialValue]);

  // Helper function to get message based on location
  const getHelperMessage = () => {
    const foundItem = HelperTextArr.find(
      ({ path }) => path === location.pathname,
    );
    return foundItem?.mes || '';
  };

  // Render table header
  const renderHeader = () => (
    <thead className="G-table-header">
      <tr className="G-table-header-row">
        {columnConfig.map((item, index) => (
          <th
            key={index}
            className="G-table-header-cell"
            style={item?.customStyle}
          >
            {item?.title}
            {item?.isEdit && <EditIcon />}
          </th>
        ))}
      </tr>
    </thead>
  );

  // Render table rows
  const renderRows = () =>
    data.map((row, rowIndex) => {
      const isActive =
        (row.accountCode && row.accountCode.includes(initialValue)) ||
        activeRow === rowIndex;

      return (
        <tr
          key={rowIndex}
          className={`G-table-body-row ${row?.rowClass || ''} ${
            isActive ? 'G-active-table' : ''
          }`}
          onClick={() => {
            setActiveRow(rowIndex);
            onTableClick?.(row);
          }}
        >
          {columnConfig.map((column, colIndex) => (
            <td
              key={colIndex}
              className="G-table-body-cell"
              title={
                typeof column.cell(row) === 'string' ||
                typeof column.cell(row) === 'number'
                  ? String(column.cell(row))
                  : undefined
              }
              style={column.customStyle}
            >
              {column.cell(row)}
            </td>
          ))}
        </tr>
      );
    });

  return data ? (
    <table className={`G-table-block ${customClass}`}>
      {renderHeader()}
      <tbody className="G-table-body">{renderRows()}</tbody>
    </table>
  ) : (
    <p>{getHelperMessage()}</p>
  );
};

export default Table;
