import {
  DELETE_MATERIAL_VALUES_NAME,
  FETCH_MATERIAL_VALUES_NAME,
  UPDATE_MATERIAL_VALUES_NAME,
} from './constants';

const initialState = {
  materialValNameData: null,
};

export const materialValueNameReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_MATERIAL_VALUES_NAME:
      return {
        ...state,
        materialValNameData: payload,
      };

    case UPDATE_MATERIAL_VALUES_NAME:
      return {
        ...state,
        materialValNameData: {
          ...state.materialValNameData,
          matValues: state.materialValNameData.matValues?.map((i) => {
            return i.matValueID === payload.matValueID ? payload : i;
          }),
        },
      };
    case DELETE_MATERIAL_VALUES_NAME:
      return {
        ...state,
        materialValNameData: {
          ...state.materialValNameData,
          matValues: state.materialValNameData.matValues.filter(
            (item) => item.matValueID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
