import React, { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import Modal from '../../Components/Modal/Modal';
import InputFiled from '../../Components/InputFiled/InputFiled';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import Checkbox from '../../Components/Checkbox/Checkbox';
import { activePassiveType } from '../../OptionsList/OptionsList';
import { SelectControl } from '../../Components/SelectControl/SelectControl';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import { ChartOfAccountValidation } from '../../Validation/ChartOfAccountValidation';

import { getRequest } from '../../Api/Api';
import { useState } from 'react';
import { components } from 'react-select';
import { useSelector } from 'react-redux';
import { BankOptions, currencyOptions } from './SelectOptionData';

const ChartOfAccountForm = memo(
  ({
    closeModal,
    onSubmit,
    disabledParent = true,
    disabledAccountCode,
    toglHandler,
    rowData,
    setErrorHandler,
    isAddedAccount,
  }) => {
    const { chartOfAccountData } = useSelector(
      (state) => state.getChartOfAccountData,
    );
    const filterBankData = BankOptions(chartOfAccountData?.companyBanks);
    const [accountData, setAccountData] = useState([]);
    const [allAccountCode, setAllAccountCode] = useState([]);
    const currDat = accountData?.editCurrencies
      ? accountData?.editCurrencies
      : [];

    useEffect(() => {
      let newArr = chartOfAccountData?.accounts?.map((item) => {
        return item.accountCode;
      });
      setAllAccountCode(newArr);
    }, [chartOfAccountData.accounts]);

    const [generalData, setGeneralData] = useState(accountData?.account);
    useEffect(() => {
      setGeneralData(accountData?.account);
    }, [accountData?.account]);

    let aa = isAddedAccount ? accountData?.amdCurrencies : currDat;
    const {
      handleSubmit,
      handleChange,
      values,
      touched,
      errors,
      handleBlur,
      setFieldValue,
      isValid,
      dirty,
    } = useFormik({
      mode: 'onBlur',
      enableReinitialize: true,
      initialValues: {
        ...generalData,
        currencies: aa,
      },
      validationSchema: ChartOfAccountValidation,
      onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
    });
    const showAccountName = rowData?.accountName;

    useEffect(() => {
      const fetchAccountData = async () => {
        await getRequest(
          `getAccount?accountID=${rowData?.accountID}&add=${toglHandler}`,
        )
          .then((res) => {
            setAccountData(res?.data);
          })
          .catch((err) => {
            console.log('Err' + err);
          });
      };
      fetchAccountData();
    }, [rowData?.accountID, toglHandler]);

    const bankAccount = values.sign == 1;

    const activePassiveOptions = activePassiveType?.map(({ type, typeID }) => {
      return {
        label: type,
        value: type,
        id: typeID,
      };
    });

    const currencyOption = currencyOptions(accountData?.currencies);

    const curOption = values?.currSign
      ? currencyOption
      : accountData?.amdCurrencies;

    const handleChangeCurrency = (val) => {
      if (!values) {
        val = [];
      }

      setFieldValue('currencies', [...val]);
    };

    const handleChangeType = (values) => {
      if (!values) {
        values = {
          id: '',
        };
      }

      setFieldValue('type', values.id);
      setFieldValue('typeName', values.value);
      setFieldValue('typeName', values.label);
    };

    const Option = (props) => {
      return (
        <div className="select-option-comp">
          <components.Option {...props}>
            <Checkbox
              changeCol={props.isSelected}
              checked={props.isSelected}
              label={props.label}
              onChange={() => null}
            />
          </components.Option>
        </div>
      );
    };

    let defaultValue = accountData?.account?.accountCode;

    const handleAccountCodeChange = (e) => {
      if (e.target.value.length > defaultValue.length + 1) {
        setErrorHandler('Կարող եք ավելացնել միայն մեկ նիշ։');
        return;
      }
      if (
        e?.nativeEvent?.inputType !== 'deleteContentBackward' &&
        allAccountCode.includes(e.target.value)
      ) {
        setErrorHandler(`${e.target.value} Հաշիվը գոյություն ունի `);
        return;
      }
      if (e.target.value.indexOf(defaultValue) === 0) {
        handleChange(e);
      }
    };

    const changeCompanyBankHandler = (values) => {
      if (!values) {
        values = {
          value: '',
          id: 0,
        };
      }

      setFieldValue('bankName', values.value);
      setFieldValue('bankID', values.bankID);
    };

    return (
      <Modal
        closeHandler={closeModal}
        onDrag={true}
        customClass="L-chart-of-account-form"
      >
        <div className="L-chart-of-account-form-content">
          <form onSubmit={onSubmit}>
            <div className="L-chart-of-account-accountName-block G-flex">
              <div className="L-chart-of-account">
                <InputFiled
                  name="accountCode"
                  value={values.accountCode}
                  onBlur={handleBlur}
                  changeHandler={handleAccountCodeChange}
                  disabled={disabledAccountCode}
                  requiredFiled={true}
                  inputTitle="Հաշիվը"
                />
                {touched.accountCode && errors.accountCode && (
                  <ErrorFiled error={errors.accountCode} />
                )}
              </div>
              {toglHandler && (
                <div className="L-chart-of-accountName">
                  <InputFiled
                    value={showAccountName}
                    inputTitle=""
                    disabled={true}
                  />
                </div>
              )}
            </div>
            {bankAccount && (
              <div className="L-chart-of-account-wrapper">
                <SelectControl
                  matchFromParam=""
                  selectBoxTitle="Բանկ"
                  options={filterBankData}
                  value={{
                    label: values.bankName,
                    value: values.bankName,
                  }}
                  onChange={changeCompanyBankHandler}
                />
              </div>
            )}

            <div className="L-chart-of-account-wrapper">
              <InputFiled
                name="accountName"
                value={values.accountName}
                onBlur={handleBlur}
                changeHandler={handleChange}
                requiredFiled={true}
                inputTitle="Հաշվի անվանումը"
              />
              {touched.accountName && errors.accountName && (
                <ErrorFiled error={errors.accountName} />
              )}
            </div>
            <div className="L-chart-of-account-wrapper">
              <InputFiled
                name="parent"
                value={values.parent}
                onBlur={handleBlur}
                changeHandler={handleChange}
                disabled={disabledParent}
                inputTitle="Սինթետիկ"
              />
            </div>
            <div className="L-chart-of-account-wrapper">
              <Checkbox
                label="Անալիտիկ"
                value={values.leaf}
                checked={values.leaf}
                disabled={true}
                name="leaf"
                onChange={(e) => setFieldValue('leaf', e.target.checked)}
              />
            </div>
            <div className="L-chart-of-account-wrapper">
              {curOption && (
                <SelectControl
                  isMulti={true}
                  hideSelectedOptions={false}
                  options={curOption?.map((v) => ({
                    value: v.value,
                    label: v.label,
                  }))}
                  value={values.currencies?.map((v) => ({
                    value: v.label,
                    label: v.label,
                  }))}
                  components={{ Option }}
                  onChange={(values) => handleChangeCurrency(values)}
                  requiredFiled={true}
                  selectBoxTitle="Արժույթ"
                  matchFromParam=""
                  isClearable={false}
                />
              )}

              {touched.currencies && errors.currencies && (
                <ErrorFiled error={errors.currencies} />
              )}
            </div>
            <div className="L-chart-of-account-wrapper">
              <SelectControl
                options={activePassiveOptions}
                value={{ label: values.typeName, value: values.typeName }}
                onChange={(values) => handleChangeType(values)}
                selectBoxTitle="Տեսակը"
                matchFromParam=""
                disabled={true}
              />
            </div>
            <div className="L-chart-of-account-wrapper">
              <Checkbox
                label="Հաշիվը օգտագործվում է միայն գործընկերոջ հետ"
                value={values.ident}
                checked={values.ident}
                name="ident"
                onChange={(e) => setFieldValue('ident', e.target.checked)}
              />
            </div>
            <div className="L-chart-of-account-wrapper">
              <Checkbox
                label="Արտահաշվեկշռային հաշիվ"
                value={values.balance}
                checked={values.balance}
                disabled={true}
                name="balance"
                onChange={(e) => setFieldValue('balance', e.target.checked)}
              />
            </div>

            <GetSentDataBtn
              cancelPrintClick={closeModal}
              confirmExecuteClick={handleSubmit}
              executeClass={!isValid || !dirty ? 'G-disabled-button' : ''}
              executeDisabled={!isValid || !dirty}
            />
          </form>
        </div>
      </Modal>
    );
  },
);

export default ChartOfAccountForm;
