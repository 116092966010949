import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import Header from '../../Components/Header/Header';
import { dateFormat, printFunc, printText } from '../../Utilities/Utilities';
import Modal from '../../Components/Modal/Modal';
import ViewSummaryTable from './ViewSummaryTable';
import { ExportExcel } from '../../Components/Excelexport/Excelexport';
import ConvertDataExcel from './ConvertDataExcel';

const ViewSummary = (props) => {
  const {
    modalClick,
    dateStartFormat,
    dateEndFormat,
    dateStart,
    dateEnd,
    currencyID,
  } = props;

  const { currentCompanyID } = useSelector((state) => state.auth);
  const { summaryData } = useSelector((state) => state.getCirculationData);
  const dispatch = useDispatch();
  const handleClose = () => modalClick(false);

  const printHandler = () => {
    const url = `printCalculationReport?companyID=${currentCompanyID}&currencyID=${currencyID}&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}&calculationType=${summaryData?.calculationType}`;
    printFunc(url, summaryData?.calculationDetails, dispatch);
  };

  const dataForExcel = summaryData?.calculationDetails
    ? ConvertDataExcel(summaryData?.calculationDetails)
    : null;

  return (
    <Modal closeHandler={handleClose} customClass="G-modal-view-table">
      <>
        <Header
          pageTitle={
            summaryData?.calculationType === 0
              ? 'Շրջանառության տեղեկագիր'
              : 'Շրջանառություն'
          }
          headerBtn={false}
        />
        <div className="L-circulation-newsletter-cur G-flex">
          <h3>Արժույթը</h3>
          <p> {summaryData.currencyName}</p>
        </div>
        <h1 className="G-modal-view-date-style">
          {dateFormat(dateStart)} - {dateFormat(dateEnd)}
        </h1>
        <ViewSummaryTable
          mainData={summaryData?.calculationDetails}
          calculationType={summaryData?.calculationType}
        />
        <div className="G-excel-print-btn-flex">
          <div className="G-save-cancel-btn-width">
            <GetSentDataBtn
              confirmExecuteText={printText}
              cancelPrintClick={handleClose}
              confirmExecuteClick={printHandler}
            />
          </div>
          <ExportExcel excelData={dataForExcel} />
        </div>
      </>
    </Modal>
  );
};

export default ViewSummary;
