import React from 'react';
import Modal from '../../../../App/Components/Modal/Modal';
import VacationCalculation from '../../vacationCalculation/VacationCalculation';

const VacationCalculationModal = ({ handleClose, rowValues }) => {
  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-vacation-calculation-modal"
      title="Արձակուրդայինի հաշվարկ"
    >
      <VacationCalculation rowValues={rowValues} handleClose={handleClose} />
    </Modal>
  );
};

export default VacationCalculationModal;
