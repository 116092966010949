import { makeAutoObservable } from 'mobx';

export default class AspectStore {
  constructor() {
    //   this._selectedAspect={}
    this._aspects = [];

    makeAutoObservable(this);
  }
  //action

  setAspect(aspects) {

    this._aspects = aspects;
  }

  /*    setSelectedAspect(aspect){
        this._selectedAspect=aspect
    } */
  //computed
  get Aspect() {
    return this._aspects;
  }
  /*  get SelectedAspect(){
        return this._selectedAspect
    } */
}
