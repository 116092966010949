import React from 'react';
import AuthenticatedForm from './authenticated/AuthenticatedForm';
import EventRemove from './EventRemove';

const Forms = ({ formType, closeModal }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};

  switch (type) {
    case 'delete':
      return (
        <EventRemove invGoodID={value?.invGoodID} handleClose={handleClose} />
      );
    case 'authenticated':
      return <AuthenticatedForm rowData={value} handleClose={handleClose} />;

    default:
      return null;
  }
};

export default Forms;
