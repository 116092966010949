import React, { useState } from 'react';
import { Commas, dateFormat } from '../../../../App/Utilities/Utilities';
import Table from '../../../../App/Components/Table/Table';
import DeleteCalculationSheet from './DeleteCalculationSheet';
import Alert from '../../../../App/Components/Alert';

const CalculationSheetTable = ({
  mainData,
  isValid,
  removeCalculationSheetItem,
  empNo,
  errorMess,
  setErrorMess,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const columnConfig = [
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.calcDate),
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Հ/Պ',
      cell: (row) => row.stNo,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Հ/Պ անվանումը',
      cell: (row) => row.stName,
      customStyle: { maxWidth: 550, overflow: 'hidden' },
    },
    {
      title: 'Օր',
      cell: (row) => row.days,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Ժամ',
      cell: (row) => row.hours,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գործակից',
      cell: (row) => row.coeff,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Հավելում',
      cell: (row) => Commas(row.addAmount),
      customStyle: { maxWidth: 150 },
    },

    {
      title: 'Պահում',
      cell: (row) => Commas(row.dedAmount),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Գործողություն',
      cell: (row) => (
        <span
          className="G-delete-span"
          onClick={() => openDeleteForm(row?.salaryEntryID)}
        >
          {row.metod === 5 || row.metod === 2 || row.stNo === '107' || isValid
            ? ''
            : 'Ջնջել'}
        </span>
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  const openDeleteForm = (deleteRowId) => {
    setOpenDeleteModal(deleteRowId);
  };

  const enhancedTableData =
    mainData &&
    mainData?.map((row) => ({
      ...row,
      rowClass: row.enabled ? '' : 'row-has-old-date',
    }));

  return (
    <>
      {enhancedTableData !== null &&
      enhancedTableData &&
      enhancedTableData?.length ? (
        <Table
          customClass="L-calculation-sheet-table G-table-has-scroll"
          data={enhancedTableData}
          columnConfig={columnConfig}
        />
      ) : null}

      {openDeleteModal && (
        <DeleteCalculationSheet
          salaryEntryID={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          removeCalculationSheetItem={removeCalculationSheetItem}
          empNo={empNo}
        />
      )}

      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default CalculationSheetTable;
