import React, { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { getRequest } from '../../../Api/Api';
import Button from '../../../Components/Button/Button';
import Modal from '../../../Components/Modal/Modal';
import { SelectControl } from '../../../Components/SelectControl/SelectControl';
import { useFetch } from '../../../Hooks/useFetch';
import {
  dataTimeFormat,
  dateFormat,
  printHandler,
  printText,
} from '../../../Utilities/Utilities';
import ClosedReportingTable from './ClosedReportingTable';
import RemoveClosedAccount from './RemoveClosedAccount';
import { useDispatch } from 'react-redux';
import Alert from '../../../Components/Alert';

const AccountsClosedReporting = ({ currentCompanyID, closeModal, getData }) => {
  const handleClose = () => closeModal(false);
  const [closureDates] = useFetch(
    `getClosureDates?companyID=${currentCompanyID}`,
  );
  const [accountClosureData, setAccountClosureData] = useState([]);
  const [errorHandler, setErrorHandler] = useState('');
  const [closureDateVal, setClosureDateVal] = useState({});
  const formatClosureDate =
    closureDateVal && dataTimeFormat(closureDateVal?.value);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteClosedAccount, setDeleteClosedAccount] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (formatClosureDate) {
      setIsLoading(true);
      getRequest(
        `GetAccountClosure?companyID=${currentCompanyID}&closureDate=${formatClosureDate}`,
      )
        .then((res) => {
          setAccountClosureData(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentCompanyID, formatClosureDate]);

  const closureDateOptions = useMemo(() => {
    return (
      closureDates &&
      closureDates?.closureDates?.map(({ docDate }) => {
        return {
          label: dateFormat(docDate),
          value: docDate,
        };
      })
    );
  }, [closureDates]);

  useEffect(() => {
    if (closureDateOptions?.length === 1) {
      closureDateOptions?.map((item) => {
        setClosureDateVal({ label: item.label, value: item.value });
      });
    }
  }, [closureDateOptions, closureDateVal.label]);

  const closureDateHandler = (values) => {
    setClosureDateVal(values);
  };

  const removeHandler = () => {
    if (formatClosureDate !== dataTimeFormat(closureDates?.deleteDate)) {
      setErrorHandler('Կարող եք հեռացնել միայն վերջին ձևակերպված փակումները');
    } else if (formatClosureDate === dataTimeFormat(closureDates?.deleteDate)) {
      setDeleteClosedAccount(true);
    }
  };

  const handlePrint = () => {
    printHandler(
      `printAccountClosure?companyID=${currentCompanyID}&closureDate=${formatClosureDate}`,
      dispatch,
    );
  };

  return (
    <>
      <Modal
        closeHandler={handleClose}
        customClass="L-accounts-closed-reporting"
        onDrag={true}
      >
        <div className="G-modal-block-title">
          <h3>Փակված հաշիվներ</h3>
        </div>

        <div className="L-date-formulation">
          <SelectControl
            selectBoxTitle="Փակման ամսաթիվը"
            requiredFiled={true}
            value={{ label: closureDateVal.label }}
            onChange={closureDateHandler}
            options={closureDateOptions}
            id="docDate"
            isClearable={false}
          />
        </div>

        <ClosedReportingTable
          loading={isLoading}
          mainData={accountClosureData}
        />
        {accountClosureData?.length > 0 && (
          <div className="G-flex-justify-end">
            <div className="G-view-btn">
              <Button text={printText} onClick={handlePrint} />
            </div>
            <div className="G-view-btn">
              <Button text="Ջնջել" onClick={removeHandler} />
            </div>
          </div>
        )}
      </Modal>

      {deleteClosedAccount && (
        <RemoveClosedAccount
          formatClosureDate={formatClosureDate}
          closeModal={setDeleteClosedAccount}
          closeAccountModal={handleClose}
          getData={getData}
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default AccountsClosedReporting;
