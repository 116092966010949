import React from "react";
import Button from "../Button/Button";

const SearchBtn = ({ onClick, text = 'Որոնել' }) => {
  return (
    <div className="G-search-btn">
      <Button text={text} onClick={onClick} />
    </div>
  );
};

export default SearchBtn;
