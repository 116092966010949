import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "../../Api/Api";
import Header from "../../Components/Header/Header";
import Pagination from "../../Components/Pagination/Pagination";
import SearchBackground from "../../Components/SearchForm/SearchBackground";
import SearchBar from "../../Components/SearchForm/SearchBar";
import {
  getChartOfAccountAction,
  loadingAction,
} from "../../Redux/chartOfAccountSlices/useAction";
import { printHandler } from '../../Utilities/Utilities';
import ChartOfAccountTable from './ChartOfAccountTable';
import './chartOfAccount.scss';
import Button from '../../Components/Button/Button';
import Alert from '../../Components/Alert';

const GetChartOfAccountData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { chartOfAccountData, isLoading } = useSelector(
    (state) => state.getChartOfAccountData,
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [searchValue, setSearchValues] = useState('');
  const [errorHandler, setErrorHandler] = useState('');

  const dispatch = useDispatch();

  const getData = (pageNumber, searchValue = '') => {
    dispatch(loadingAction(true));
    getRequest(
      `getaccounts?companyID=${currentCompanyID}&pageNumber=${
        pageNumber + 1
      }&searchstring=${searchValue}`,
    )
      .then((res) => {
        dispatch(getChartOfAccountAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    getData(pageNumber, searchValue);
  }, [pageNumber, dispatch]);

  const handlePrint = () => {
    printHandler(
      `printaccounts?companyID=${currentCompanyID}&searchstring=${searchValue}`,
      dispatch,
    );
  };

  return (
    <>
      <Header headerBtn={false} pageTitle="Հաշվային պլան" />

      <SearchBackground>
        <div className="G-flex L-chart-of-account-page-flex">
          <SearchBar
            getData={getData}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            searchValue={searchValue}
            setSearchValue={setSearchValues}
            name="searchstring"
          />
          <div className="G-normal-btn">
            <Button onClick={handlePrint} text="Տպել" />
          </div>
        </div>
      </SearchBackground>
      <ChartOfAccountTable
        mainData={chartOfAccountData?.accounts}
        loading={isLoading}
        getData={getData}
        pageNumber={pageNumber}
        searchValue={searchValue}
        setErrorHandler={setErrorHandler}
      />
      <Pagination
        pageCount={chartOfAccountData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />

      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default GetChartOfAccountData;
