import {
  DELETE_MATERIAL_VALUES_GROUP,
  FETCH_MATERIAL_VALUES_GROUP,
  UPDATE_MATERIAL_VALUES_GROUP,
} from './constants';

const initialState = {
  materialValuesGroupData: null,
};

export const materialValuesGroupReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_MATERIAL_VALUES_GROUP:
      return {
        ...state,
        materialValuesGroupData: payload,
      };

    case UPDATE_MATERIAL_VALUES_GROUP:
      return {
        ...state,
        materialValuesGroupData: {
          ...state.materialValuesGroupData,
          prodGroups: state.materialValuesGroupData.prodGroups?.map((i) => {
            return i.prodGroupID === payload.prodGroupID ? payload : i;
          }),
        },
      };
    case DELETE_MATERIAL_VALUES_GROUP:
      return {
        ...state,
        materialValuesGroupData: {
          ...state.materialValuesGroupData,
          prodGroups: state.materialValuesGroupData.prodGroups.filter(
            (item) => item.prodGroupID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
