import React from 'react';
import Alert from '../../../../App/Components/Alert';
import AssignAdditionalForm from './AssignAdditionalForm';
import useAssignAdditionalStorage from '../../../hooks/useAssignAdditionalStorage';
import useIsMounted from '../../../hooks/useIsMounted';
import { removeCommas } from '../../../../App/Utilities/Utilities';
const EventAddForm = ({ rowValues, handleClose }) => {
  const { empNo } = rowValues || {};
  const { addAssignAddition, errorMess, setErrorMess } =
    useAssignAdditionalStorage();
  const isMounted = useIsMounted();
  const onAddHandler = async (values, resetForm) => {
    const val = {
      ...values,
      sPercent: Number(values.sPercent),
      sAmount: removeCommas(values.sAmount),
    };

    try {
      await addAssignAddition(val, handleClose, empNo);
      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (error) {}
  };

  return (
    <>
      <AssignAdditionalForm
        onSubmit={onAddHandler}
        handleClose={handleClose}
        empNo={empNo}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
