import React from 'react';
import TopArrow from '../../Components/TopArrow/TopArrow';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { mainPage } from '../../Utilities/Utilities';
import { useSelector } from 'react-redux';
const UserDataHeader = () => {
  const { currentCompanyName } = useSelector((state) => state.auth);
  const history = useHistory();

  const clearUser = () => {
    history.push(mainPage);
  };
  return (
    <div className="L-top-menu-block G-flex-justify-between L-user-data-header">
      <div className="" onClick={clearUser}>
        <TopArrow />
      </div>
      <h3>Օգտվողի տվյալները</h3>
      <h3>{currentCompanyName}</h3>
    </div>
  );
};

export default UserDataHeader;
