import { makeAutoObservable } from 'mobx';

export default class CurrencyStore {
  constructor() {
    this._currency = [
      {
        id: 1,
        name: 'AMD',
      },
      {
        id: 2,
        name: 'USD',
      },
      {
        id: 3,
        name: 'RUB',
      },
      {
        id: 4,
        name: 'EUR',
      },
      {
        id: 5,
        name: 'GEL',
      },
    ];
    makeAutoObservable(this);
  }
  //action
  setCurrency(currencys) {
    this._currency = currencys;
  }

  //computed

  get Currency() {
    return this._currency;
  }
}
