import React, { useEffect, useState } from 'react';
import Modal from '../../Components/Modal/Modal';
import Table from '../../Components/Table/Table';
import { Commas, dataTimeFormat, dateFormat } from '../../Utilities/Utilities';
import { getRequest } from '../../Api/Api';
import { useSelector } from 'react-redux';

const AnalyticalAccountsBalances = ({
  accountCode,
  closeModal,
  dateFormulation,
}) => {
  const { currentCompanyID } = useSelector((state) => state.auth);

  const handleClose = () => closeModal(false);
  const [analyticalAccountsData, setAnalyticalAccountsData] = useState([]);

  useEffect(() => {
    getRequest(
      `GetUnionAccountBalances?companyID=${currentCompanyID}&accountCode=${accountCode}&dateEnd=${dataTimeFormat(
        dateFormulation,
      )} `,
    )
      .then((res) => {
        setAnalyticalAccountsData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  }, [accountCode, dateFormulation, currentCompanyID]);

  const columnConfig = [
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountCode,
      customStyle: { width: 140 },
    },
    {
      title: 'Հաշիվի անվանումը',
      cell: (row) => row.accountName,
      customStyle: { maxWidth: 800, overflow: 'hidden' },
    },
    {
      title: 'Դեբետ',
      cell: (row) => Commas(row.amountDebit),
      customStyle: { maxWidth: 200 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => Commas(row.amountCredit),
      customStyle: { maxWidth: 200 },
    },
  ];

  return (
    <Modal
      closeHandler={handleClose}
      customClass="L-analytical-accounts-balances-block"
      onDrag={true}
    >
      <div className="G-modal-block-title">
        <h3>Հաշվի անալիտիկ հաշիվները</h3>
      </div>
      <>
        {analyticalAccountsData !== null &&
        analyticalAccountsData &&
        analyticalAccountsData?.length ? (
          <Table
            customClass="L-analytical-accounts-balances-table G-table-has-scroll"
            data={analyticalAccountsData}
            columnConfig={columnConfig}
          />
        ) : (
          ''
        )}
      </>
    </Modal>
  );
};

export default AnalyticalAccountsBalances;
