import React, { useEffect, useState } from 'react';
import { dateFormat } from '../../Utilities/Utilities';
import DeleteHistoryTaxationSystem from './DeleteHistoryTaxationSystem';
import Table from '../../Components/Table/Table';

const HistoryTable = ({ mainData }) => {
  const [generalData, setGeneralData] = useState(mainData);
  const [deleteHistory, setDeleteHistory] = useState(false);
  useEffect(() => {
    setGeneralData(mainData);
  }, [mainData]);

  const columnConfig = [
    {
      title: 'Հարկման համակարգի անվանումը',
      cell: (row) => row.taxationSystemName,
      customStyle: { maxWidth: 350 },
    },

    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.taxSystemDate),
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Մուտքագրման օրը',
      cell: (row) => (row.creationDate ? dateFormat(row.creationDate) : null),
      customStyle: { maxWidth: 150 },
    },

    {
      title: 'Գործողություն',
      cell: (row) => (
        <span
          className="G-delete-span"
          onClick={() => setDeleteHistory(row.taxSystemDateID)}
        >
          Ջնջել
        </span>
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  return (
    <>
      {generalData !== null && generalData && generalData?.length ? (
        <Table customClass="" data={generalData} columnConfig={columnConfig} />
      ) : (
        ''
      )}
      {deleteHistory ? (
        <DeleteHistoryTaxationSystem
          taxSystemDateID={deleteHistory}
          closeModal={setDeleteHistory}
          generalData={generalData}
          setGeneralData={setGeneralData}
        />
      ) : null}
    </>
  );
};

export default HistoryTable;
