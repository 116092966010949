import {
  DELETE_WAREHOUSE,
  FETCH_WAREHOUSE,
  UPDATE_WAREHOUSE,
} from './constants';

const initialState = {
  warehouseData: null,
};

export const warehouseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_WAREHOUSE:
      return {
        ...state,
        warehouseData: payload,
      };

    case UPDATE_WAREHOUSE:
      return {
        ...state,
        warehouseData: {
          ...state.warehouseData,
          stores: state.warehouseData.stores?.map((i) => {
            return i.storeID === payload.storeID ? payload : i;
          }),
        },
      };
    case DELETE_WAREHOUSE:
      return {
        ...state,
        warehouseData: {
          ...state.warehouseData,
          stores: state.warehouseData.stores.filter(
            (item) => item.storeID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
