import React, { useEffect } from 'react';
import { OtherDataFields } from './FieldsConfig';
import Input from '../../Input/Input';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import useGetBankByCode from '../../hooks/useGetBankByCode';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';

const OtherData = ({
  onChange,
  values,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  getPublicServiceNumber,
  publicServiceData,
  setPublicServiceData,
}) => {
  const { bankByCode } = useGetBankByCode(values.cardAcc);

  useEffect(() => {
    setFieldValue('bankName', bankByCode);
  }, [bankByCode]);

  const closeModal = () => {
    setPublicServiceData((prevState) => ({
      ...prevState,
      showForm: false,
      removePublicServices: true,
    }));
    setFieldValue('socCardNo', '');
  };

  const confirmClick = () => {
    setPublicServiceData((prevState) => ({
      ...prevState,
      showForm: false,
      removePublicServices: false,
    }));
  };
  const onCustomChange = async (e) => {
    const { value, name } = e.target;

    if (name === 'socCardNo') {
      setFieldValue(name, value);

      if (value.length === 10) {
        try {
          const res = await getPublicServiceNumber(value, values.employeeID);
          setPublicServiceData((prev) => ({ ...prev, showForm: res }));
        } catch (error) {
          console.error('Error fetching public service number:', error);
        }
      }
    } else {
      onChange(e);
    }
  };

  return (
    <div className="other-data-comp">
      {OtherDataFields?.map((field) => {
        const { fieldType, label, name, required, disabled, maxLength } = field;
        const fieldError = touched[name] && errors[name];
        switch (fieldType) {
          case 'input':
            return (
              <div className="input-container" key={name}>
                <Input
                  inputTitle={label}
                  name={name}
                  requiredFiled={required}
                  onChange={onCustomChange}
                  value={values[name]}
                  onBlur={handleBlur}
                  disabled={disabled}
                  maxLength={maxLength}
                />
                {fieldError && <ErrorFiled error={fieldError} />}
              </div>
            );

          default:
            return null;
        }
      })}

      {publicServiceData?.showForm && (
        <AcceptOrCancelModal
          modalContent="ՀԾՀ - ն կրկնվում է, շարունակել"
          closeModal={closeModal}
          confirmClick={confirmClick}
        />
      )}
    </div>
  );
};

export default OtherData;
