import React from "react";
import Button from "../Button/Button";
import "./helpBtn.scss";

const HelpBtn = ({ helpHandler, text = "?" }) => {
  return (
    <div className="G-help-btn">
      <Button text={text} onClick={helpHandler} />
    </div>
  );
};

export default HelpBtn;
