import React from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import { assignPositionFields } from './FieldsConfig';
import Button from '../../../App/Components/Button/Button';
import { executeText } from '../../../App/Utilities/Utilities';

const ExecuteAction = ({
  assignPositionData,
  query,
  setQuery,
  onExecuteHandler,
}) => {
  const isEveryFieldFilled = query && query.stNo;

  let dropDownOptions = [];
  const handleDropdownChange = (fieldName, selectedOption) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }

    setQuery((prevQuery) => ({
      ...prevQuery,
      [fieldName]: {
        ...prevQuery[fieldName],
        value: selectedOption.value,
        id: selectedOption.id?.trim(),
      },
    }));
  };

  return (
    <SearchBackground>
      <div className="confirm-action-wrap-flex">
        <div className="confirm-action-wrap">
          {assignPositionFields?.map((field) => {
            const {
              fieldType,
              fieldName,
              required,
              Children,
              optionName,
              dropdownTitle,
              dropdownId,
            } = field;

            if (typeof Children === 'function') {
              if (assignPositionData) {
                dropDownOptions = Children(assignPositionData[optionName]);
              }
            }

            switch (fieldType) {
              case 'dropdown':
                return (
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={required}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      handleDropdownChange(
                        fieldName,
                        selectedOption,
                        dropdownId,
                      )
                    }
                  />
                );

              default:
                return null;
            }
          })}
        </div>
        <div className="G-add-btn">
          <Button
            type="button"
            text={executeText}
            onClick={onExecuteHandler}
            disabled={!isEveryFieldFilled}
            customClass={!isEveryFieldFilled && 'G-disabled-button'}
          />
        </div>
      </div>
    </SearchBackground>
  );
};

export default ExecuteAction;
