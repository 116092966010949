import {
  DELETE_SUB_DIVISIONS,
  EDIT_SUB_DIVISIONS,
  GET_SUB_DIVISIONS,
} from './constants';

const initialState = {
  subDivision: null,
};

export const subDivisionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SUB_DIVISIONS:
      return {
        ...state,
        subDivision: payload,
      };
    case EDIT_SUB_DIVISIONS:
      return {
        ...state,
        subDivision: {
          ...state.subDivision,

          workDeps: state.subDivision.workDeps?.map((i) => {
            return i.workDepID === payload.workDepID ? payload : i;
          }),
        },
      };
    case DELETE_SUB_DIVISIONS:
      return {
        ...state,
        subDivision: {
          ...state.subDivision,
          workDeps: state.subDivision.workDeps.filter(
            (item) => item.workDepID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
