import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import arrow from '../../icons/arrowDown.png';
import business from '../../static/business.png';
import food from '../../static/food.png';
import salary from '../../static/salary.png';
import gift from '../../static/gift.png';
import bank from '../../static/bank.png';
import party from '../../static/party.png';
import utilities from '../../static/utilities.png';
import doctor from '../../static/doctor.png';
import taxs from '../../static/taxs.png';
import car from '../../static/car.png';
import beautiful from '../../static/beautiful.png';
import home from '../../static/home.png';
import plane from '../../static/plane.png';
import sport from '../../static/sport.png';
import students from '../../static/students.png';
import product from '../../static/product.png';
import defCircle from '../../icons/defCircle.png';

import './index.scss';

const ItemImg = ({
  imgColor,
  setImgColor,
  imgPath,
  setImgPath,
  imgIcon = null,
  setImgIcon,
}) => {
  const [isActiveImg, setIsActiveImg] = useState(false);

  const imgNameArray = [
    { iconName: 'business.png', iconimg: business },
    { iconName: 'salary.png', iconimg: salary },
    { iconName: 'gift.png', iconimg: gift },
    { iconName: 'bank.png', iconimg: bank },
    { iconName: 'party.png', iconimg: party },
    { iconName: 'utilities.png', iconimg: utilities },
    { iconName: 'doctor.png', iconimg: doctor },
    { iconName: 'taxs.png', iconimg: taxs },
    { iconName: 'car.png', iconimg: car },
    { iconName: 'beautiful.png', iconimg: beautiful },
    { iconName: 'home.png', iconimg: home },
    { iconName: 'plane.png', iconimg: plane },
    { iconName: 'sport.png', iconimg: sport },
    { iconName: 'students.png', iconimg: students },
    { iconName: 'product.png', iconimg: product },
    { iconName: 'food.png', iconimg: food },
    { iconName: 'defCircle.png', iconimg: defCircle },
  ];
  const foundItems = imgNameArray.filter((item) => item.iconName === imgPath);
  const iconimgSrc = foundItems?.length > 0 ? foundItems[0]?.iconimg : imgIcon;
  return (
    <div className="category-box-flex">
      <div className="item-img-block category-style">
        <h3 className="category-title">Նկարը</h3>
        <div
          className="category-btn"
          onClick={() => setIsActiveImg(!isActiveImg)}
        >
          <div className="circle-box" style={{ backgroundColor: imgColor }}>
            <img
              width={40}
              height={40}
              style={{ background: imgColor }}
              src={iconimgSrc}
              alt=""
            />
          </div>
          <div className="show-box-arrow">
            <img src={arrow} alt="" />
          </div>
        </div>

        {isActiveImg && (
          <div className="show-dropdown-box">
            {imgNameArray?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="img-box"
                  onClick={() => {
                    setImgPath(item?.iconName);
                    setImgIcon(item?.iconimg);
                    setIsActiveImg(false);
                  }}
                >
                  <img width={40} height={40} src={item.iconimg} alt="" />
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="item-img-block category-style">
        <h3 className="category-title">Գույնը</h3>
        <HexColorPicker color={imgColor} onChange={setImgColor} />
      </div>
    </div>
  );
};

export default ItemImg;
