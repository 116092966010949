import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import './helperContent.scss';
import { getRequest } from '../../Api/Api';

const HelperContent = ({ closeHelpBox, helperMessage, locationPathname }) => {
  useEffect(() => {
    getRequest(`getHelpContentName?detailName=${locationPathname?.slice(1)}`)
      .then((res) => {
        localStorage.setItem('documentTitle', JSON.stringify(res.data.trim()));
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  }, [locationPathname]);

  let max = helperMessage?.indexOf(':');
  const handleClose = () => closeHelpBox(false);
  const [showLess, setShowLess] = useState(true);
  const detailsForA = {
    target: '_blank',
    rel: 'noreferrer',
  };
  if (helperMessage.length <= max) {
    return <p className="G-helper-p">{helperMessage}</p>;
  }

  const toggleHandler = () => {
    setShowLess(!showLess);
  };

  const detailsHandler = () => {
    localStorage.setItem('initialName', locationPathname?.slice(1));
  };

  return (
    <Modal customClass="G-help-modal" onDrag={true} closeHandler={handleClose}>
      <div className="G-helper-content">
        <p className="G-helper-p">
          {showLess ? `${helperMessage.substring(0, max)}  ` : helperMessage}

          <span className="L-togl-text" onClick={toggleHandler}>
            {showLess && 'Տեսնել ավելին'}
          </span>
          {!showLess && (
            <>
              <a
                href={'/HelpDetails'}
                {...detailsForA}
                onClick={detailsHandler}
                className="details"
              >
                Մանրամասն...
              </a>
              {locationPathname === '/UnitOfMeasure' && (
                <a
                  href="https://hy.wikipedia.org/wiki/%D5%84%D5%AB%D5%A1%D5%BE%D5%B8%D6%80%D5%B6%D5%A5%D6%80%D5%AB_%D5%B4%D5%AB%D5%BB%D5%A1%D5%A6%D5%A3%D5%A1%D5%B5%D5%AB%D5%B6_%D5%B0%D5%A1%D5%B4%D5%A1%D5%AF%D5%A1%D6%80%D5%A3"
                  {...detailsForA}
                >
                  Չափման միավորներ
                </a>
              )}
            </>
          )}
        </p>
      </div>
    </Modal>
  );
};

export default HelperContent;
