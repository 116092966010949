import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Modal from '../../Components/Modal/Modal';
import {
  amountIsNotEnough,
  Commas,
  dataTimeFormat,
  dateFormat,
  removeCommas,
  textFiledLength,
} from '../../Utilities/Utilities';
import Calendar from '../../Components/Calendar/Calendar';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import SelectAccount from '../../Components/SelectBox/SelectAccount';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import SelectBank from '../../Components/SelectBox/SelectBank';
import InputFiled from '../../Components/InputFiled/InputFiled';
import SelectPartner from '../../Components/SelectBox/SelectPartner';
import { PaymentOrderValidation } from '../../Validation/PaymentOrderValidation';
import { updatePartnerAction } from '../../Redux/paymentOrderSlices/useAction';
import AddNew from '../../Elements/Add/AddNew';
import { default as PartnerForm } from '../../Pages/Partners/EventAddForm';
import NumericFormatFiled from '../../Components/NumericFormat/NumericFormatFiled';
import RadioButton from '../../Components/RadioButton/RadioButton';
import { useFetch } from '../../Hooks/useFetch';
import DocNumFiled from '../../Components/DocNumFiled';
import { default as BankForm } from '../../Pages/Partners/PartnerBank/EventAddForm';
import RegisterCalculationInReport from '../../Components/RegisterCalculationInReport/RegisterCalculationInReport';
const PaymentOrderForm = ({
  closeModal,
  initialData,
  onSubmit,
  addEvent = false,
  setErrorHandler,
}) => {
  const { paymentOrderData } = useSelector(
    (state) => state.getPaymentOrderData,
  );
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [settlementAccount, setSettlementAccount] = useState([]);
  const [partnerBankList, setPartnerBankList] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(initialData.exchangeRate);
  const [correspondentAccount, setCorrespondentAccount] = useState([]);
  const [showPartnerForm, setShowPartnerForm] = useState(false);
  const [showBankForm, setShowBankForm] = useState(false);
  const [companyBankData, setCompanyBankData] = useState([]);
  const { amdCurrID } = paymentOrderData || {};
  const [initAmount, setInitAmount] = useState(0);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    setFieldValue,
    isValid,
    dirty,
    handleBlur,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...initialData,
    },
    validationSchema: PaymentOrderValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const [betweenMyBankAccountsData] = useFetch(
    `getCompanyAccounts?companyID=${currentCompanyID}&accountCode=${252}&firstAccountCode=${
      values?.accountDebit
    } `,
  );
  const currentTaxCode = values.taxCode || values?.partnerTaxCode;

  const recipientRadioBtn = values.docSign;

  useEffect(() => {
    setFieldValue('exchangeRate', exchangeRate);
    setFieldValue('amountAMD', values.amount);
  }, [exchangeRate, setFieldValue, values.amount]);

  const changeCurrencyType = values.currencyID === amdCurrID ? 252 : 253;

  useEffect(() => {
    getRequest(
      `getCompanyAccounts?companyID=${currentCompanyID}&accountCode=${changeCurrencyType}`,
    )
      .then((res) => {
        setSettlementAccount(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  }, [changeCurrencyType, currentCompanyID, values.currencyID]);

  useEffect(() => {
    const checkCn =
      recipientRadioBtn === 3 && values?.areAttached.toString().length === 5;

    if (checkCn) {
      getRequest(`getAccountBankName?account=${values.areAttached} `)
        .then((res) => {
          setFieldValue('appendix', res?.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [recipientRadioBtn, values.areAttached]);

  useEffect(() => {
    if (recipientRadioBtn !== 1 && values.companyBankID) {
      getRequest(
        `getCompanyBanksList?companyID=${currentCompanyID}&companyBankID=${values.companyBankID}`,
      )
        .then((res) => {
          setPartnerBankList(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
    if (recipientRadioBtn === 1 && values.partnerID) {
      getRequest(
        `getPartnerBanksList?partnerID=${
          values.partnerID
        }&foreignBank=${false}`,
      )
        .then((res) => {
          setPartnerBankList(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [
    currentCompanyID,
    recipientRadioBtn,
    values.companyBankID,
    values.partnerID,
  ]);

  useEffect(() => {
    if (values.currencyCode !== 'AMD') {
      getRequest(
        `getRate?rateDate=${dataTimeFormat(values?.docDate)}&iso=${
          values?.currencyCode
        }`,
      )
        .then((res) => {
          if (res.data !== null) {
            setExchangeRate(res.data);
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
    if (values.currencyCode === 'AMD') {
      setExchangeRate(1);
    }
  }, [values?.currencyCode, values?.docDate]);

  useEffect(() => {
    if (values.accountDebit) {
      getRequest(
        `getCorrespAccounts?companyID=${currentCompanyID}&accountCode=${
          values.accountDebit
        }&debitCredit=${2}&partner=${values.docSign === 1 ? true : false}`,
      )
        .then((res) => {
          setCorrespondentAccount(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [currentCompanyID, values.accountDebit, values.docSign]);

  useEffect(() => {
    if (values.accountDebit) {
      getRequest(
        `getCurrentCompanyBanks?companyID=${currentCompanyID}&bankCode=${values.bankCode}`,
      )
        .then((res) => {
          setCompanyBankData(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [values.accountDebit, currentCompanyID, values.bankCode]);

  useEffect(() => {
    if (values.accountDebit) {
      getRequest(
        `getAccountBalance?companyID=${currentCompanyID}&account=${
          values.accountDebit
        }&currencyID=${values?.currencyID}&dateEnd=${dataTimeFormat(
          values.docDate,
        )}`,
      )
        .then((res) => {
          setInitAmount(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [
    currentCompanyID,
    values.accountDebit,
    values?.currencyID,
    values.docDate,
  ]);

  const onChangeData = (date) => {
    setFieldValue('docDate', dataTimeFormat(date));
  };

  const changeDebitAccountHandler = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('accountDebit', values.value);
    setFieldValue('bankCode', values.bankCode);
  };

  const changeCreditAccountHandler = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('correspAccount', values.value);
  };

  const changeCompanyBankHandler = (values) => {
    if (!values) {
      values = {
        value: '',
        id: '',
      };
    }
    setFieldValue('companyAccount', values.value);
    setFieldValue('companyBankID', values.id);
    setFieldValue('companyBankName', values.bankName);
  };

  const changePartnerHandler = (values) => {
    if (!values) {
      values = {
        value: '',
        id: '',
      };
    }

    setFieldValue('taxCode', values.taxCode);
    setFieldValue('partnerTaxCode', values.taxCode);
    setFieldValue('partnerID', values.id);
    setFieldValue('partnerName', values.value);
    //

    setFieldValue('partnerAccount', '');
    setFieldValue('correspAccount', '');
    setFieldValue('partnerBankName', '');
  };

  const changePartnerBankHandler = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('partnerAccount', values.value);
    setFieldValue('partnerBankName', values.bankName);
  };

  const isAPartner = () => {
    setFieldValue('partnerName', '');
    setFieldValue('partnerID', 0);
    setFieldValue('taxCode', '');
    setFieldValue('partnerTaxCode', '');
    setFieldValue('recipient', '');
    setFieldValue('partnerAccount', '');
    setFieldValue('correspAccount', '');
    setFieldValue('partnerBankName', '');
  };

  const betweenMyBankAccounts = () => {
    setFieldValue('partnerAccount', '');
    setFieldValue('correspAccount', '');
    setFieldValue('partnerBankName', '');
  };

  const notAPartner = () => {
    setFieldValue('appendix', '');
    setFieldValue('areAttached', '');
    setFieldValue('correspAccount', '');
  };

  const checkedDocSignHandler = (val) => {
    setFieldValue('docSign', val);
    if (val === 1) {
      betweenMyBankAccounts();
      notAPartner();
    } else if (val === 2) {
      isAPartner();
      notAPartner();
    } else if (val === 3) {
      isAPartner();
      betweenMyBankAccounts();
    }
  };

  const onAddNewPartner = ({ partnerID, partnerName, taxCode }) => {
    setFieldValue('partnerID', partnerID);
    setFieldValue('partnerName', partnerName);
    setFieldValue('taxCode', taxCode);
  };

  const onAddNewPartnerBank = ({ bankingAccount, bankName }) => {
    setFieldValue('partnerAccount', bankingAccount);
    setFieldValue('partnerBankName', bankName);
  };

  return (
    <>
      <Modal
        closeHandler={closeModal}
        onDrag={true}
        customClass="L-payment-order-form"
      >
        <div className="L-payment-order-form-content">
          <div className="G-modal-block-title">
            <h3>Վճարման հանձնարարագիր</h3>
          </div>
          <div className="L-payment-order-header">
            <DocNumFiled
              docTypeID={values.docTypeID}
              docDate={values.docDate}
              value={values.docNum}
              setFieldValue={setFieldValue}
              handleChangeDocNum={handleChange}
              addEvent={addEvent}
            />
            <div className="G-form-data-picker">
              <p className="G-title-p">Ամսաթիվ</p>
              <Calendar
                openToDate={new Date(values?.docDate)}
                value={dateFormat(values.docDate)}
                changeHandler={(date, e) => onChangeData(date, e)}
                name="docDate"
              />
            </div>
            <div className="L-currency-box">
              <p className="G-title-p">Արժույթ</p>
              <span>{values.currencyCode}</span>
            </div>
          </div>

          <form onSubmit={onSubmit}>
            <div className="L-payment-order-panel-flex G-flex">
              <div className="L-payment-order-panel">
                <div className="G-flex">
                  <h3 className="h3">Վճարող</h3>
                  <div className="L-taxNumber-filed G-relative">
                    <InputFiled
                      inputTitle="Մ/հ կոդ"
                      type="text"
                      name="taxNumber"
                      value={values?.taxNumber}
                      changeHandler={handleChange}
                      maxLength={2}
                      onBlur={handleBlur}
                    />
                    {touched.taxNumber && errors.taxNumber && (
                      <ErrorFiled error={errors.taxNumber} />
                    )}
                  </div>
                </div>
                <div className="L-payment-order-panel-content">
                  <div className="L-payment-order-wrapper">
                    <SelectAccount
                      title="Հաշիվը"
                      accountData={settlementAccount}
                      accountVal={{
                        label: values.accountDebit,
                        value: values.accountDebit,
                      }}
                      selectChangeAccount={(values) =>
                        changeDebitAccountHandler(values)
                      }
                    />
                    {touched.accountDebit && errors.accountDebit && (
                      <ErrorFiled error={errors.accountDebit} />
                    )}
                  </div>
                  <div className="L-payment-order-wrapper">
                    <SelectBank
                      requiredFiled={true}
                      bankData={companyBankData}
                      bankVal={{
                        label: values.companyAccount,
                        value: values.companyAccount,
                      }}
                      selectChangeBank={(values) =>
                        changeCompanyBankHandler(values)
                      }
                      onBlur={handleBlur}
                    />
                    {touched.companyAccount && errors.companyAccount && (
                      <ErrorFiled error={errors.companyAccount} />
                    )}
                  </div>

                  <div className="L-payment-order-wrapper">
                    <InputFiled
                      inputTitle="Բանկը"
                      name="companyBankName"
                      value={values?.companyBankName}
                      disabled
                    />
                  </div>
                  <p className="L-available-amount-block G-title-p">
                    Առկա գումարը{' '}
                    <strong className="G-available-amount">
                      {' '}
                      {Commas(initAmount)}
                    </strong>
                  </p>
                  <div className="G-amount-input-wrap L-payment-order-wrapper">
                    <NumericFormatFiled
                      value={values.amount}
                      name="amount"
                      onBlur={handleBlur}
                      changeHandler={handleChange}
                      inputTitle="Գումարը"
                      requiredFiled={true}
                    />
                    {touched.amount && errors.amount && (
                      <ErrorFiled error={errors.amount} />
                    )}
                  </div>

                  <div className="L-payment-order-wrapper">
                    <InputFiled
                      inputTitle="Վճարման նպատակը"
                      name="otherInformation"
                      value={values?.otherInformation}
                      requiredFiled={true}
                      changeHandler={handleChange}
                      onBlur={handleBlur}
                      maxLength={textFiledLength}
                    />
                    {touched.otherInformation && errors.otherInformation && (
                      <ErrorFiled error={errors.otherInformation} />
                    )}
                  </div>
                </div>
              </div>
              <div className="L-payment-order-panel">
                <div className="G-flex">
                  <h3 className="h3">Ստացող</h3>
                  <div className="L-payment-order-radio-btn-flex G-flex">
                    <RadioButton
                      value={values.docSign}
                      checked={values.docSign === 1}
                      id="docSign"
                      name="docSign"
                      label="Գործընկեր է"
                      onChange={() => checkedDocSignHandler(1)}
                    />

                    <RadioButton
                      value={values.docSign}
                      checked={values.docSign === 2}
                      id="docSign"
                      name="docSign"
                      label="Իմ բանկային հաշիվների միջև"
                      onChange={() => checkedDocSignHandler(2)}
                    />
                    <RadioButton
                      value={values.docSign}
                      checked={values.docSign === 3}
                      id="docSign"
                      name="docSign"
                      label="Գործընկեր չէ"
                      onChange={() => checkedDocSignHandler(3)}
                    />
                  </div>
                </div>
                <div className="L-payment-order-panel-content">
                  {recipientRadioBtn === 1 && (
                    <div className="L-payment-order-wrapper G-flex L-po-partner-flex">
                      <SelectPartner
                        requiredFiled={recipientRadioBtn === 1}
                        partnerData={paymentOrderData?.partners}
                        partnerVal={{
                          label: values.partnerName,
                          value: values.partnerName,
                        }}
                        selectChangePartner={(values) =>
                          changePartnerHandler(values)
                        }
                      />
                      <AddNew onClick={() => setShowPartnerForm(true)} />
                      {touched.partnerID && errors.partnerID && (
                        <ErrorFiled error={errors.partnerID} />
                      )}
                    </div>
                  )}
                  {recipientRadioBtn === 1 && (
                    <div className="G-taxCode-input-filed">
                      <InputFiled
                        inputTitle="ՀՎՀՀ"
                        name="taxCode"
                        value={currentTaxCode}
                        changeHandler={handleChange}
                        disabled
                      />
                    </div>
                  )}

                  {recipientRadioBtn === 3 && (
                    <div className="L-payment-order-wrapper">
                      <InputFiled
                        requiredFiled={true}
                        inputTitle="Ստացողը"
                        name="recipient"
                        value={values.recipient}
                        changeHandler={handleChange}
                      />
                    </div>
                  )}

                  {recipientRadioBtn !== 3 && (
                    <div className="L-payment-order-wrapper G-flex L-po-partner-flex">
                      <SelectBank
                        requiredFiled={recipientRadioBtn !== 3}
                        bankData={partnerBankList}
                        bankVal={{
                          label: values.partnerAccount,
                          value: values.partnerAccount,
                        }}
                        selectChangeBank={(values) =>
                          changePartnerBankHandler(values)
                        }
                      />
                      {values.docSign === 1 && (
                        <AddNew
                          onClick={() => setShowBankForm(values.partnerID)}
                          content="Բանկ"
                        />
                      )}

                      {touched.partnerAccount && errors.partnerAccount && (
                        <ErrorFiled error={errors.partnerAccount} />
                      )}
                    </div>
                  )}

                  {recipientRadioBtn === 3 && (
                    <div className="L-payment-order-wrapper">
                      <InputFiled
                        requiredFiled={true}
                        inputTitle="Բանկային հաշիվ"
                        name="areAttached"
                        value={values.areAttached}
                        changeHandler={handleChange}
                        onBlur={handleBlur}
                        maxLength={25}
                      />
                      {touched.areAttached && errors.areAttached && (
                        <ErrorFiled error={errors.areAttached} />
                      )}
                    </div>
                  )}

                  <div className="L-payment-order-wrapper">
                    <InputFiled
                      inputTitle="Բանկը"
                      name={
                        values.docSign === 3 ? 'appendix' : 'partnerBankName'
                      }
                      value={
                        values.docSign === 3
                          ? values.appendix
                          : values?.partnerBankName
                      }
                      disabled={true}
                      onChange={handleChange}
                    />

                    {touched.appendix && errors.appendix && (
                      <ErrorFiled error={errors.appendix} />
                    )}
                  </div>
                  <div className="L-payment-order-wrapper">
                    <SelectAccount
                      title="Թղթակցող հաշիվը"
                      accountData={
                        values.docSign === 2
                          ? betweenMyBankAccountsData
                          : correspondentAccount
                      }
                      accountVal={{
                        label: values.correspAccount,
                        value: values.correspAccount,
                      }}
                      selectChangeAccount={(values) =>
                        changeCreditAccountHandler(values)
                      }
                    />
                    {touched.correspAccount && errors.correspAccount && (
                      <ErrorFiled error={errors.correspAccount} />
                    )}
                  </div>
                  <RegisterCalculationInReport
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
            </div>
            <GetSentDataBtn
              cancelPrintClick={closeModal}
              confirmExecuteClick={handleSubmit}
              executeClass={!isValid || !dirty ? 'G-disabled-button' : ''}
              executeDisabled={!isValid || !dirty}
            />
          </form>
        </div>
      </Modal>
      {showPartnerForm && (
        <PartnerForm
          closeModal={setShowPartnerForm}
          updateUserOutside={true}
          customPartnerID={values.partnerID}
          updatePartnerAction={updatePartnerAction}
          setPartnerInfo={onAddNewPartner}
        />
      )}

      {showBankForm && (
        <BankForm
          closeModal={setShowBankForm}
          customPartnerID={values.partnerID}
          addNewBank={true}
          addNewBankAction={setPartnerBankList}
          onAddNewPartnerBank={onAddNewPartnerBank}
        />
      )}
    </>
  );
};

export default PaymentOrderForm;
