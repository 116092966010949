import React from 'react';
import { routing } from '../../Routing/routing';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import ClickOutside from '../ClickOutside/ClickOutside';
import './menu.scss';
import { useDispatch, useSelector } from 'react-redux';
import TopArrow from '../TopArrow/TopArrow';
import { filterRowAction } from '../../Redux/FilterRowData/useAction';
import { mainPage } from '../../Utilities/Utilities';

const Menu = ({ sidebar, setSidebar }) => {
  const { companySign } = useSelector((state) => state.auth);
  let location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userData =
    location.pathname === '/userData' || location.pathname === '/Calendar';

  const showMenu = companySign || false;
  const clearUser = () => {
    history.push(mainPage);
  };

  const removePathId = (path) => {
    const pathArr = [
      '/CashWarrant',
      '/CashOutflowOrders',
      '/PaymentOrder',
      '/PurchaseAct',
      '/AccountForSpendingMoney',
      '/E-InvoicingOperations',
    ];

    if (pathArr.includes(path)) {
      dispatch(filterRowAction({ currentId: 0 }));
    }
  };

  return (
    <>
      {userData ? null : (
        <ClickOutside E_Outside="" onClickOutside={() => setSidebar(false)}>
          <div className={`L-menu-block ${sidebar ? 'L-active-menu' : ''}`}>
            <div className="L-open-close-block"></div>
            <div className="L-menu-content">
              <div className={`L-logo-block   ${!sidebar && ' pl'}`}>
                <div
                  className={`L-logo-flex G-flex ${
                    !sidebar && 'G-flex-justify-center'
                  }`}
                  onClick={clearUser}
                >
                  <TopArrow />
                </div>
              </div>
              {!showMenu ? (
                <div className="L-menu-list">
                  <ul>
                    {routing.map((item) => {
                      return (
                        <li className="L-menu-item" key={item.name}>
                          <NavLink to={item.path}>
                            <img src={item.icon} alt="" />
                            <span className={`${sidebar ? '' : 'hidden'}`}>
                              {item.name}
                            </span>
                          </NavLink>
                          {item.chillers ? (
                            <div className="L-menu-children">
                              <h4 className="G-menu-title">{item.name}</h4>
                              <ul>
                                {item.chillers &&
                                  item.chillers.map((elem) => {
                                    return (
                                      <li
                                        key={elem.name}
                                        onClick={() => removePathId(elem.path)}
                                      >
                                        <NavLink to={elem.path}>
                                          {elem.name}
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
            </div>{' '}
          </div>{' '}
        </ClickOutside>
      )}
    </>
  );
};

export default Menu;
