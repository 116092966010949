import { executeText, printText } from '../../../App/Utilities/Utilities';

export const actionConfigs = [
  {
    title: 'Հաշվարկի տարին',
    type: 'dropdown',
    optionName: 'years',
    optionID: 'year',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.year,
        id: i.year,
        value: i.year,
      }));
    },
    required: true,
  },
  {
    title: 'Հաշվարկի ամիսը',
    type: 'dropdown',
    optionName: 'salaryMonths',
    optionID: 'month',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.salaryMonthName,
        id: i.month,
        value: i.salaryMonthName,
      }));
    },
    required: true,
  },

  {
    title: 'Ստորաբաժանում',
    type: 'dropdown',
    optionName: 'workDeps',
    optionID: 'depNo',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.depName,
        id: i.depNo,
        value: i.depName,
      }));
    },
  },
  {
    type: 'button',
    buttonText: executeText,
    actionType: 'execute',
    onClickHandler: (query) => {},
    disabled: (query) => {
      return !query.years || !query.salaryMonths;
    },
  },
  {
    type: 'button',
    buttonText: printText,
    actionType: 'print',
    onClickHandler: (query) => {},
    disabled: (query) => {
      return !query.years || !query.salaryMonths;
    },
  },
];
