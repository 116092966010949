import React from 'react';
import Select from 'react-select';
import styles from './select-control.module.scss';
import './dropdown.scss';

const Dropdown = ({
  id,
  options,
  value,
  disabled = false,
  onChange,
  closeMenuOnSelect = true,
  hideSelectedOptions = true,
  isSearchable = true,
  placeholder = 'Ընտրեք տիպ',
  defaultInputValue,
  customClass,
}) => {
  return (
    <div className={`dropdown-block ${customClass}`}>
      <div className={styles.selectControl}>
        <Select
          defaultInputValue={defaultInputValue}
          closeMenuOnSelect={closeMenuOnSelect}
          isSearchable={isSearchable}
          hideSelectedOptions={hideSelectedOptions}
          value={value}
          onChange={(e) => onChange(e, id)}
          options={options}
          placeholder={placeholder}
          styles={{
            option: (base) => ({ ...base, fontSize: '13px' }),
            noOptionsMessage: (base) => ({ ...base, fontSize: '12px' }),
          }}
        />
      </div>
    </div>
  );
};

export default Dropdown;
