import React from 'react';
import { useDispatch } from 'react-redux';
import { getInvoiceTotal } from './InvoiceDigitalCalculations';
import { updateInvoiceDateAction } from '../../Redux/invoiceSlices/useAction';
import { Commas, removeCommas } from '../../Utilities/Utilities';
import NumericFormatFiled from '../../Components/NumericFormat/NumericFormatFiled';

const InvoiceTotal = ({ mainData }) => {
  const { invoiceGoods, invoice } = mainData || {};
  const { prePayment } = invoice || {};

  const totalAmount = getInvoiceTotal(invoiceGoods);

  const totalVat =
    mainData?.companyTaxationID === 1 ? totalAmount - totalAmount / 1.2 : 0;

  const dispatch = useDispatch();

  const changePaymentHandler = (e) => {
    const { name, value } = e.target;

    dispatch(updateInvoiceDateAction({ [name]: removeCommas(value) }));
  };

  return (
    <div className="L-invoice-total-wrapper G-flex">
      <div className="L-invoice-total-box">
        <div className="L-invoice-select-value">
          <p className="G-title-p">Ընդամենը</p>
          <span>{Commas(totalAmount)}</span>
        </div>
        <div className="L-invoice-select-value">
          <p className="G-title-p">Որից ԱԱՀ</p>
          <span>{Commas(totalVat)}</span>
        </div>

        <div className="L-invoice-prePayment-input">
          <NumericFormatFiled
            value={prePayment}
            name="prePayment"
            changeHandler={changePaymentHandler}
            inputTitle="Կանխավճար"
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceTotal;
