import React from 'react';
import './index.scss';
import edit from '../icons/edit.png';
import trash from '../icons/trash.png';
import { deleteAspect } from '../http/aspectAPI';
import { useContext, useState } from 'react';
import { Context } from '../../index.js';
import { getEntr } from '../http/entrAPI';
import './index.scss';
import CreateEntr from '../components/modals/CreateEntr';
import UpdateAspect from '../components/modals/UpdateAspect';
import bank from '../static/bank.png';
import business from '../static/business.png';
import salary from '../static/salary.png';
import gift from '../static/gift.png';
import party from '../static/party.png';
import utilities from '../static/utilities.png';
import doctor from '../static/doctor.png';
import taxs from '../static/taxs.png';
import car from '../static/car.png';
import beautiful from '../static/beautiful.png';
import home from '../static/home.png';
import plane from '../static/plane.png';
import sport from '../static/sport.png';
import students from '../static/students.png';
import product from '../static/product.png';
import food from '../static/food.png';
import './AspectItem.scss';
import { useSelector } from 'react-redux';
import AspectAllEnters from './AspectAllEnters/AspectAllEnters.jsx';

const AspectItem = ({ aspectd }) => {
  const { userID } = useSelector((state) => state.auth);
  const uId = userID;
  const imgNameArray = [
    { iconName: 'business.png', iconimg: business },
    { iconName: 'salary.png', iconimg: salary },
    { iconName: 'gift.png', iconimg: gift },
    { iconName: 'bank.png', iconimg: bank },
    { iconName: 'party.png', iconimg: party },
    { iconName: 'utilities.png', iconimg: utilities },
    { iconName: 'doctor.png', iconimg: doctor },
    { iconName: 'taxs.png', iconimg: taxs },
    { iconName: 'car.png', iconimg: car },
    { iconName: 'beautiful.png', iconimg: beautiful },
    { iconName: 'home.png', iconimg: home },
    { iconName: 'plane.png', iconimg: plane },
    { iconName: 'sport.png', iconimg: sport },
    { iconName: 'students.png', iconimg: students },
    { iconName: 'product.png', iconimg: product },
    { iconName: 'food.png', iconimg: food },
  ];

  const imgPath = aspectd?.imgPath;

  const iconAspect = imgNameArray.find((e) => e.iconName === imgPath);

  const { aspect, entr } = useContext(Context);

  const [entrVisible, setEntrVisible] = useState(false);
  const [showA, setShowA] = useState(false);

  const [accId, setAccId] = useState(1);
  const [accName, setAccName] = useState('Ծախս');
  const [showModal, setShowModal] = useState(false);
  const IdAspect = aspectd.id;
  const aspectAllEnters = entr.Entr.filter((ent) => ent.aspectId === IdAspect);

  const clickTa = () => {
    setAccId(1);
    setAccName('Ծախս');
    setEntrVisible(true);
  };
  const clickEk = () => {
    setAccId(2);
    setAccName('Եկամուտ');
    setEntrVisible(true);
  };

  const deleteHandler = async () => {
    const Id = aspectd.id;
    const data = await deleteAspect(Id);

    aspect.setAspect(aspect.Aspect.filter((asp) => asp.id !== Id));

    try {
      const data = await getEntr(uId);
      if (data.data) {
        entr.setEntr(data.data);
      }
    } catch (e) {
      //alert(e.data.message)
    }
  };

  const showDelete = () => {
    setShowModal(true);
  };

  return (
    <div className="category-box-wrap">
      <div className="category-box">
        <div className="category-box-title">
          <h3>{aspectd.name}</h3>

          <div>
            <div className="aspect-item-wrap">
              <div
                className="icon-box"
                style={{ background: aspectd.imgColor }}
              >
                <img src={iconAspect?.iconimg} alt="" />
              </div>
              <div className="action-icon-wrap">
                <div className="category-action" onClick={() => setShowA(true)}>
                  <img src={edit} alt="" />
                </div>
                <div className="category-action" onClick={showDelete}>
                  <img src={trash} alt="" />
                </div>
              </div>
            </div>

            <div className="category-action-block">
              <div className="btn-column">
                {aspectd.singT ? (
                  <div
                    className="expense-income-btn-box"
                    style={aspectd.singT && { marginLeft: '0' }}
                  >
                    <button className="expense-income-btn " onClick={clickTa}>
                      Ծախս
                    </button>
                  </div>
                ) : (
                  ''
                )}

                {aspectd.singE ? (
                  <div
                    className="expense-income-btn-box"
                    style={
                      aspectd.singT
                        ? { marginLeft: '15px' }
                        : { marginLeft: '0' }
                    }
                  >
                    <button className="expense-income-btn" onClick={clickEk}>
                      Եկամուտ
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          {entrVisible && (
            <CreateEntr
              closeModal={setEntrVisible}
              aspect={aspectd}
              accId={accId}
              accName={accName}
            />
          )}

          {showA && <UpdateAspect onHideA={setShowA} aspectd={aspectd} />}

          {showModal && (
            <AspectAllEnters
              aspectAllEnters={aspectAllEnters}
              setShowModal={setShowModal}
              deleteItem={deleteHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default AspectItem;
