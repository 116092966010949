import React from 'react';
import StoreForm from '../warehouse/EventAddForm';
import PartnerForm from '../partners/EventAddForm';

const NewItemForms = ({ formType, closeModal, setFieldValue }) => {
  const handleClose = () => closeModal(false);

  switch (formType) {
    case 'storeName':
      return (
        <StoreForm
          setFieldValue={setFieldValue}
          handleClose={handleClose}
          formIsUsedExternally={true}
          customName="storeName"
          customId="storeNo"
        />
      );
    case 'partnerName':
      return (
        <PartnerForm
          handleClose={handleClose}
          setFieldValue={setFieldValue}
          formIsUsedExternally={true}
          customName="partnerName"
          customId="partnerID"
        />
      );

    default:
      return null;
  }
};

export default NewItemForms;
