import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import Pagination from '../../Components/Pagination/Pagination';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import SearchBar from '../../Components/SearchForm/SearchBar';
import AccountingCorrespondenceTable from './AccountingCorrespondenceTable';
import CheckDebitCreditAccount from './CheckDebitCreditAccount';
import './index.scss';

const GetAccountingCorrespondence = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [correspondence, setCorrespondence] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const [searchValue, setSearchValue] = useState('');
  const [isCheck, setIsCheck] = useState(true);

  const getData = useCallback(
    (pageNumber, searchValue = '') => {
      setIsLoading(true);
      getRequest(
        `getAccountCorrespondences?&pageNumber=${
          pageNumber + 1
        }&searchstring=${searchValue}&debet=${isCheck}&credit=${!isCheck}`,
      )
        .then((res) => {
          setCorrespondence(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isCheck],
  );

  useEffect(() => {
    getData(pageNumber, searchValue);
  }, [pageNumber, isCheck]);

  const memoizedCorrespondence = useMemo(
    () => correspondence?.correspondences,
    [correspondence],
  );

  return (
    <>
      <Header headerBtn={false} pageTitle="Հաշվային թղթակցություններ" />
      <SearchBackground>
        <div className="G-flex-align-center">
          <CheckDebitCreditAccount isCheck={isCheck} setIsCheck={setIsCheck} />
          <SearchBar
            pageNumber={pageNumber}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            getData={getData}
            setPageNumber={setPageNumber}
            name="searchstring"
          />
        </div>
      </SearchBackground>
      <AccountingCorrespondenceTable
        mainData={memoizedCorrespondence}
        loading={isLoading}
      />
      <Pagination
        pageCount={correspondence?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
    </>
  );
};

export default GetAccountingCorrespondence;
