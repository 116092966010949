import React from 'react';
import Alert from '../../../App/Components/Alert';
import NonWorkingDayForm from './NonWorkingDayForm';
import useNonWorkingDays from '../../hooks/useNonWorkingDays';
import useIsMounted from '../../hooks/useIsMounted';

const EventEditForm = ({ nwDayID, handleClose }) => {
  const { updateNonWorkingDay, errorMess, setErrorMess } = useNonWorkingDays();
  const isMounted = useIsMounted();

  const onUpdateHandler = async (values) => {
    try {
      await updateNonWorkingDay(values, handleClose);
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <NonWorkingDayForm
        nwDayID={nwDayID}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventEditForm;
