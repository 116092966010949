import {
 
    DELETE_CURRENCIES,
    EDIT_CURRENCIES,
    GET_CURRENCIES,
    LOADING,
  } from "./constants";
  
  const initialState = {
    currenciesData: null,
    isLoading: false,
  };

  
  
  export const currenciesReducer = (
    state = initialState,
    { type, payload }
  ) => {
    switch (type) {
      case GET_CURRENCIES:
        return {
          ...state,
          currenciesData: payload,
          isLoading: true,
        };
  
      case EDIT_CURRENCIES:
        return {
          ...state,
          currenciesData: {
            ...state.currenciesData,
            currencies: state.currenciesData.currencies.map((item) => {
              return item.currencyID === payload.currencyID ? payload : item;
            }),
          },
        };
      case DELETE_CURRENCIES:
        return {
          ...state,
          currenciesData: {
            ...state.currenciesData,
            currencies: state.currenciesData?.currencies.filter(
              (item) => item.currencyID !== payload
            ),
          },
        };
      case LOADING:
        return {
          ...state,
          isLoading: payload,
        };
      default:
        return { ...state };
    }
  };
  