import React, { useMemo } from 'react';
import DashboardTitle from '../DashboardTitle';
import Seducement from '../../../Assets/icons/carbon_money.svg';
import Calendar from '../../../Components/Calendar/Calendar';
import Table from '../../../Components/Table/Table';
import { Commas } from '../../../Utilities/Utilities';
import Tooltip from '../../../Components/Tooltip/Tooltip';

const PartnerBalance = (props) => {
  const { dateStart, data, setDateStart } = props;
  const partnerBalance = data !== null ? data?.remainderPartner : [];
  const columnConfig = useMemo(
    () => [
      {
        title: 'Անվանումը',
        cell: (row) => row.partnerName,
        customStyle: { maxWidth: 150, overflow: 'hidden' },
      },

      {
        title: 'Արժույթ',
        cell: (row) => row.currencyCode,
        customStyle: { maxWidth: 60, overflow: 'hidden' },
      },
      {
        title: 'Դեբետային',
        cell: (row) => Commas(row.amountDebit),
        customStyle: { maxWidth: 130 },
      },
      {
        title: 'Կրեդիտային',
        cell: (row) => Commas(row.amountCredit),
        customStyle: { maxWidth: 130 },
      },
      {
        title: <Tooltip content="Դեբիտային դրամով">Դ/դրամով</Tooltip>,
        cell: (row) => Commas(row.amountDebitAMD),
        customStyle: { maxWidth: 130 },
      },
      {
        title: <Tooltip content="Կրեդիտային դրամով">Կ/դրամով</Tooltip>,
        cell: (row) => Commas(row.amountCreditAMD),
        customStyle: { maxWidth: 130 },
      },
    ],
    [],
  );

  return (
    <div className="L-partner-balance G-block-style">
      <div className="G-flex">
        <DashboardTitle
          blockTitle="Իմ գործընկերների մնացորդները"
          icon={Seducement}
        />
        <div className="G-dashboard-calendar">
          <Calendar
            selected={dateStart}
            name="dateStart"
            changeHandler={(e) => setDateStart(e)}
            maxDate={new Date()}
            removeMinDate={true}
          />
        </div>
      </div>
      {partnerBalance && (
        <Table
          customClass="L-dashboard-tables"
          data={partnerBalance}
          columnConfig={columnConfig}
        />
      )}
    </div>
  );
};

export default PartnerBalance;
