import React from 'react';
import { postRequest } from '../../Api/Api';
import { removeCommas, typOfString } from '../../Utilities/Utilities';
import OperationsForm from './OperationsForm';

const EventAddForm = ({
  closeModal,
  getData,
  pageNumber,
  setErrorHandler,
  rowData,
  activeIsCopy,
}) => {
  const handleClose = () => closeModal(false);
  const onSubmit = (values, resetForm) => {
    postRequest('addEntry', {
      ...values,
      docNum: typOfString(values.docNum),
      amount: removeCommas(values.amount),
      amountAMD: removeCommas(values.amountAMD),
      exchangeRate: removeCommas(values.exchangeRate),
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          getData(pageNumber);
          resetForm();
          handleClose();
        }
        if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <OperationsForm
      closeModal={handleClose}
      onSubmit={onSubmit}
      rowData={rowData}
      entryIDForAdd={rowData?.entryID > 0 ? rowData?.entryID : 0}
      activeIsCopy={activeIsCopy}
      addEvent={true}
    />
  );
};

export default EventAddForm;
