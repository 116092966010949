import React, { useState } from 'react';
import { deleteRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../Components/Alert';
import { useDispatch } from 'react-redux';
import { deleteInvoicingAction } from '../../Redux/eInvoicingOperationsSlices/useAction';

const DeleteEInvoicing = ({ handleClose, excelInvoiceID }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      const response = await deleteRequest(
        `deleteExcelInvoice/${excelInvoiceID}`,
      );
      const { errorCode, message } = response.data;

      if (errorCode === 0) {
        dispatch(deleteInvoicingAction(excelInvoiceID));
        handleClose();
      } else {
        setErrorMessage(message);
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred. Please try again later.');
    }
  };

  const handleAlertClose = () => {
    setErrorMessage('');
    handleClose();
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={handleDelete}
      />

      <Alert description={errorMessage} onClose={handleAlertClose} />
    </>
  );
};

export default DeleteEInvoicing;
