import React from 'react';
import GetOperationsData from './Operations/GetOperationsData';
import GetPaymentOrderData from './PaymentOrder/GetPaymentOrderData';
import SignForm from './SignForm/SignForm';
import OperationByRows from './OperationByRows/OperationByRows';
import GetOperationData from './OperationForm/GetOperationData';
import WarehouseForm from './WarehouseForm/WarehouseForm';
import DeleteEInvoicing from './DeleteEInvoicing';

const Forms = ({ formType, closeModal }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};

  switch (type) {
    case 'operation':
      return (
        <GetOperationData
          handleClose={handleClose}
          excelInvoiceID={value?.excelInvoiceID}
        />
      );

    case 'payment':
      return <GetPaymentOrderData handleClose={handleClose} rowData={value} />;

    case 'operations':
      return (
        <GetOperationsData
          excelInvoiceID={value?.excelInvoiceID}
          handleClose={handleClose}
        />
      );

    case 'sign':
      return <SignForm rowData={value} handleClose={handleClose} />;

    case 'operationByRows':
      return (
        <OperationByRows
          excelInvoiceID={value?.excelInvoiceID}
          handleClose={handleClose}
        />
      );

    case 'delete':
      return (
        <DeleteEInvoicing
          handleClose={handleClose}
          excelInvoiceID={value?.excelInvoiceID}
        />
      );
    case 'warehouse':
      return (
        <WarehouseForm
          handleClose={handleClose}
          excelInvoiceID={value?.excelInvoiceID}
        />
      );

    default:
      return null;
  }
};

export default Forms;
