import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTables,
  deleteTables,
  fetchTables,
  fetchTablesForm,
  updateTables,
} from '../services/fetchTables';
import {
  deleteTablesAction,
  fetchTableFormAction,
  fetchTablesAction,
  updateTablesAction,
} from '../../App/Redux/SalaryStor/tablesSlices/useAction';

const useTables = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState('');
  const { currentCompanyID } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const tables = useSelector((state) => state.tablesData?.tables);
  const tablesFormData = useSelector((state) => state.tablesData?.tablesForm);
  const fetchTablesData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchTables(currentCompanyID);
      dispatch(fetchTablesAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addTableItem = async (newTable, closeFormCallback) => {
    try {
      const { errorCode, message } = await addTables(newTable);
      if (errorCode === 0) {
        fetchTablesData();
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateTableItem = async (newTable, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await updateTables(newTable);
      if (message) {
        setErrorMess(message);
      } else if (errorCode === 0) {
        dispatch(updateTablesAction(newTable));
        closeFormCallback();
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeTableItem = async (calendarID, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await deleteTables(calendarID);

      if (errorCode === 0) {
        dispatch(deleteTablesAction(calendarID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTablesFormData = async (calendarID) => {
    setIsLoading(true);
    try {
      const data = await fetchTablesForm(currentCompanyID, calendarID);
      dispatch(fetchTableFormAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    fetchTablesData,
    tables,
    addTableItem,
    updateTableItem,
    removeTableItem,
    fetchTablesFormData,
    tablesFormData,
    errorMess,
    setErrorMess,
  };
};

export default useTables;
