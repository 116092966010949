import React from 'react';
import { optionType } from '../ArrayList';

const OptionColor = ({ setFieldValue, value }) => {
  return (
    <div className="G-flex" style={{ paddingBottom: '22px' }}>
      {optionType.map((i) => {
        return (
          <div className="option-type-wrap " key={i.typeName}>
            <label className="container">
              <input
                type="checkbox"
                value={i.typeColor}
                checked={i.typeColor === value}
                onChange={() => setFieldValue('label', i.typeColor)}
              />
              <span
                className="checkmark"
                style={{ '--typeColor': i.typeColor }}
              ></span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default OptionColor;
