import React from 'react';
import './helpBtn.scss';

const HelpBtn = ({ helpHandler, text = '?' }) => {
  return (
    <div className="help-btn">
      <span onClick={helpHandler}>{text}</span>
    </div>
  );
};

export default HelpBtn;
