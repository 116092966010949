export const warehouseEntryFormFields = [
  {
    fieldType: 'radio',
    requiredRadioBtn: true,
    radioTitle: 'Փաստաթղթի տեսակը',
    radioBtn: [
      {
        label: 'Մուտք',
        value: 2,
        name: 'docTypeID',
      },
      {
        label: 'Մնացորդ',
        value: 1,
        name: 'docTypeID',
      },
    ],
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'docNum',
    label: 'Փաստաթուղթ N',
    value: '',
    required: true,
    maxLength: 8,
    disabled: (v) => {
      return !v.storeName;
    },
  },
  {
    fieldType: 'date',
    dateName: 'docDate',
    dateTitle: 'Կազմման ամսաթիվ',
    required: true,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Ձեռք բերման տեսակը',
    fieldName: 'inputTypeName',
    dropdownId: 'inputTypeId',
    optionName: 'inputTypes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.inputTypeName,
        id: i.inputTypeId,
        value: i.inputTypeName,
      }));
    },

    required: (i) => {
      return i.docTypeID !== 1 || !i.excelInvoiceID;
    },
    disabled: (i) => {
      return i.excelInvoiceID > 0;
    },
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Պահեստ',
    required: true,
    fieldName: 'storeName',
    dropdownId: 'storeNo',
    optionName: 'stores',
    addNewItem: true,
    Children: (props) => {
      return props?.map((i) => ({
        label: i.storeName,
        id: i.storeNo,
        value: i.storeName,
      }));
    },
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'Մատակարարի հաշիվ',
    fieldName: 'partnerAccCode',
    dropdownId: 'partnerAccCode',
    optionName: 'partnerAccounts',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.partnerAccName,
        id: i.partnerAccCode,
        value: i.partnerAccName,
        ident: i.ident,
      }));
    },
    required: (i) => {
      return i.docTypeID === 2;
    },
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Մատակարար',
    fieldName: 'partnerName',
    dropdownId: 'partnerID',
    optionName: 'partners',
    addNewItem: true,
    Children: (props) => {
      return props?.map((i) => ({
        label: i.partnerName,
        id: i.partnerID,
        value: i.partnerName,
      }));
    },
    required: (i) => {
      return i.docTypeID === 2 && i.ident;
    },
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'note',
    label: 'Նշումներ',
    value: '',
    required: false,
    maxLength: 100,
  },
  {
    fieldType: 'checkbox',
    name: 'incomeSign',
    label: 'Գնման գինը ներառում է եկամտային հարկը',
    value: '',
    required: false,
    disabled: (v) => {
      return v.docTypeID === 1 || (v.inputTypeId !== 3 && v.inputTypeId !== 4);
    },
  },
];

export const warehouseEntryFilterFields = [
  {
    calendarTitle: 'Ժամանակահատվածը',
    fieldType: 'calendar',
    calendars: [
      {
        name: 'startDate',
      },
      {
        name: 'endDate',
      },
    ],
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Պահեստ',
    required: false,
    fieldName: 'storeName',
    dropdownId: 'storeNo',
    optionName: 'docStores',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.storeName,
        id: i.storeNo,
        value: i.storeName,
      }));
    },
  },
  {
    fieldType: 'input',
    type: 'number',
    name: 'docNum',
    inputTitle: 'Փաստաթուղթ N',
    value: '',
    required: false,
    maxLength: 8,
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'Փաստաթղթի տեսակը',
    required: false,
    fieldName: 'docTypeName',
    dropdownId: 'docTypeID',
    optionName: 'docTypes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.docTypeName,
        id: i.docTypeID,
        value: i.docTypeName,
      }));
    },
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'Ձեռք բերման տեսակը',
    required: false,
    fieldName: 'inputTypeName',
    dropdownId: 'inputTypeId',
    optionName: 'docInputTypes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.inputTypeName,
        id: i.inputTypeId,
        value: i.inputTypeName,
      }));
    },
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'ՀՀ տարածք/Ներմուծում',
    required: false,
    fieldName: 'supplyName',
    dropdownId: 'supplyID',
    optionName: 'docSupplies',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.supplyName,
        id: i.supplyID,
        value: i.supplyName,
      }));
    },
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Մատակարար',
    required: false,
    fieldName: 'partnerName',
    dropdownId: 'partnerID',
    optionName: 'docPartners',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.partnerName,
        id: i.partnerID,
        value: i.partnerName,
      }));
    },
  },
  {
    fieldType: 'amount',
    type: 'text',
    name: 'fromMoney',
    inputTitle: 'Մին. գումարը',
    value: 0,
    required: false,
    maxLength: 15,
  },
  {
    fieldType: 'amount',
    type: 'text',
    name: 'toMoney',
    inputTitle: 'Մաքս. գումարը',
    value: 0,
    required: false,
    maxLength: 15,
  },
];
