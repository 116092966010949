import React from "react";
import "./contactUs.scss";
import WriteToUs from "../WriteToUs";

const ContactUs = () => {
  return (
    <div className="contact-us-block">
      <h3 className="window-view-title">Կապ մեզ հետ</h3>
      <div className="contact-us-content">
        <div className="contact-us-address-panel">
          <div className="contact-us-title-area">
            <span>Հասցե</span>
          </div>
          <div className="contact-us-address-and-phone">
            <p>Ք․Երևան, 0014 </p>
            <p>Ազատության 24</p>
          </div>

          <div className="iframe-wrapper">
            <iframe
              id=""
              title="Մեկ Պատուհան ՍՊԸ"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.4508691005303!2d44.51789569999999!3d40.199032599999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd3accdfb0fb%3A0xf099166f8eafb9e7!2zMjQg1LHVptWh1b_VuNaC1anVtdWh1bYg1brVuNWy1bjVv9WhLCDUtdaA1ofVodW2IDAwMjU!5e0!3m2!1shy!2s!4v1661780045896!5m2!1shy!2s "
            ></iframe>
          </div>
        </div>

        <div className="contact-us-phone-panel">
          <WriteToUs />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
