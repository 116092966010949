import React, { useState } from "react";
import CalendarTime from "../../Components/Calendar/CalendarTime";
import SelectPartner from "../../Components/SelectBox/SelectPartner";
import AddNew from "../../Elements/Add/AddNew";
import { updatePartnerAction } from "../../Redux/invoiceSlices/useAction";
import { correctDate, inCorrectDateMess } from '../../Utilities/Utilities';
import PartnerForm from '../Partners/EventAddForm';

const InvoiceAction = (props) => {
  const {
    dateStart,
    setDateStart,
    dateEnd,
    setDateEnd,
    mainData,
    partnerVal,
    setPartnerVal,
    setErrorHandler,
    setPartnerInfo,
  } = props;
  const [showPartnerForm, setShowPartnerForm] = useState(false);
  const changePartnerHandler = (value) => {
    if (!value) {
      value = {
        value: '',
        id: 0,
      };
    }

    setPartnerVal(value);
  };

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      setErrorHandler(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };
  const openPartnerAddForm = () => {
    setShowPartnerForm(true);
  };

  return (
    <div className="L-invoice-action G-flex">
      <CalendarTime
        dateStart={dateStart}
        setDateStart={setDateStart}
        dateEnd={dateEnd}
        setDateEnd={setDateEnd}
        onChangeHandler={timeChangeHandler}
      />
      <div className="G-flex-align-center G-purchase-act-partner-flex">
        <SelectPartner
          partnerData={mainData?.partners}
          partnerVal={partnerVal}
          selectChangePartner={(value) => changePartnerHandler(value)}
          requiredFiled={true}
        />
        <AddNew onClick={openPartnerAddForm} />
      </div>
      {showPartnerForm && (
        <PartnerForm
          closeModal={setShowPartnerForm}
          updateUserOutside={true}
          updatePartnerAction={updatePartnerAction}
          setPartnerInfo={setPartnerInfo}
        />
      )}
    </div>
  );
};

export default InvoiceAction;
