import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRequest, postRequest } from '../../../Api/Api';
import Alert from '../../../Components/Alert';
import { useFetchBalanceDate } from '../../../Hooks/useFetchBalanceDate';
import { dataTimeFormat, removeCommas } from '../../../Utilities/Utilities';
import PartnerBalanceForm from './PartnerBalanceForm';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { InitBalancesAllowAddText } from '../../../GlobalTitle/GlobalTitle';

const EventAddForm = ({ modalClick, getData, partnerData }) => {
  const { partnerID, partnerName } = partnerData || {};
  const [errorHandler, setErrorHandler] = useState('');
  const [isAllowAdd, setIsAllowAdd] = useState(false);
  const [selectRowData, setSelectRowData] = useState({});
  const { accountCode, cashSign = false } = selectRowData || {};
  const handleClose = () => modalClick(false);
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [balanceDate] = useFetchBalanceDate();
  const { periodStart } = balanceDate || {};
  const initialValues = {
    companyID: currentCompanyID,
    partnerID: partnerID,
    accountID: '',
    amountCredit: 0,
    amountDebit: 0,
    currencyID: 0,
    currencyCode: '',
    balanceDate: periodStart && periodStart,
    exchangeRate: 1,
  };

  useEffect(() => {
    async function checkAllowEdit() {
      try {
        const url = `preDeleteEditPartnerInitBalance?partnerID=${partnerID}&accountCode=${accountCode}&cashSign=${cashSign}`;
        const res = await getRequest(url);
        const { errorCode, message } = res.data;
        if (errorCode === 1) {
          setIsAllowAdd(true);
        } else if (errorCode === 100) {
          setErrorHandler(message);
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    checkAllowEdit();
  }, [accountCode, partnerID, cashSign]);

  const onSubmit = (values, resetForm) => {
    postRequest('addPartnerInitBalance', {
      ...values,
      balanceDate: dataTimeFormat(values?.balanceDate),
      amountDebit: removeCommas(values.amountDebit),
      amountCredit: removeCommas(values.amountCredit),
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          getData();
          resetForm();
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  };

  return (
    <>
      <PartnerBalanceForm
        closeModal={handleClose}
        initialData={initialValues}
        onSubmit={onSubmit}
        partnerName={partnerName}
        setSelectRowData={setSelectRowData}
        workForAdd={true}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
      {isAllowAdd && (
        <AcceptOrCancelModal
          closeModal={handleClose}
          confirmClick={() => setIsAllowAdd(false)}
          modalContent={InitBalancesAllowAddText}
        />
      )}
    </>
  );
};

export default EventAddForm;
