import { useEffect, useState, useCallback } from 'react';
import { getRequest } from '../Api/Api';

export const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      await getRequest(url)
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } catch (error) {
      setError(error);
    }
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, error];
};
