export const nonWorkingDaysFields = [
  {
    fieldType: 'date',
    dateName: 'nwDay',
    dateTitle: 'Ամսաթիվը',
    required: true,
  },
  {
    fieldType: 'radio',
    required: true,
    radioTitle: 'Տեսակը',
    radioBtn: [
      {
        label: 'Տոնական',
        value: 1,
        name: 'nwType',
      },
      {
        label: 'Ոչ աշխատանքային',
        value: 2,
        name: 'nwType',
      },
      {
        label: 'Աշխատանքային',
        value: 3,
        name: 'nwType',
      },
    ],
  },
];
