import { GET_HELP_DETAIL_NAME } from './constants';

const initialState = {
  initialName: null,
};
export const helpDetailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_HELP_DETAIL_NAME:
      return {
        ...state,
        initialName: payload,
      };

    default:
      return { ...state };
  }
};
