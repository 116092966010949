import React from 'react';
import EmployeesForm from './EmployeesForm';
import useEmployeesForm from '../../hooks/useEmployeesForm';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';

const EventAddForm = ({ handleClose, query, pageNumber }) => {
  const { addEmployee, errorMess, setErrorMess } = useEmployeesForm();
  const isMounted = useIsMounted();
  const onAddHandler = async (values, resetForm) => {
    const val = {
      ...values,
      socPercent: Number(values.socPercent),
      genderNo: Number(values.genderNo),
      rentNo: Number(values.rentNo),
      tuPercent: Number(values.tuPercent),
    };

    await addEmployee(val, handleClose, query, pageNumber);

    if (isMounted && !errorMess) {
      resetForm();
    }
  };

  return (
    <>
      <EmployeesForm onSubmit={onAddHandler} handleClose={handleClose} />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
