import React from 'react';
import { useFormik } from 'formik';
import NumericFormatFiled from '../../Components/NumericFormat/NumericFormatFiled';
import Modal from '../../Components/Modal/Modal';
import InputFiled from '../../Components/InputFiled/InputFiled';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import { SpendingFormValidation } from '../../Validation/SpendingMoneyValidation';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';

const SpendingForm = (props) => {
  const { closeModal, initialValue, onSubmit } = props;
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    isValid,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    initialValues: initialValue,
    validationSchema: SpendingFormValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  return (
    <Modal
      closeHandler={closeModal}
      onDrag={true}
      customClass="L-spending-form-block"
    >
      <div className="L-spending-form-content">
        <form onSubmit={onSubmit}>
          <div className="L-spending-input">
            <InputFiled
              inputTitle="Փաստաթղթի անվանումը, համարը, ամսաթիվը"
              requiredFiled={true}
              value={values.documentName}
              name="documentName"
              changeHandler={handleChange}
              onBlur={handleBlur}
            />
            {touched.documentName && errors.documentName && (
              <ErrorFiled error={errors.documentName} />
            )}
          </div>
          <div className="L-spending-input">
            <InputFiled
              inputTitle="Ծախսման նպատակը"
              requiredFiled={true}
              value={values.purpose}
              name="purpose"
              changeHandler={handleChange}
              onBlur={handleBlur}
              maxLength={100}
            />

            {touched.purpose && errors.purpose && (
              <ErrorFiled error={errors.purpose} />
            )}
          </div>
          <div className="L-spending-input">
            <NumericFormatFiled
              value={values.price}
              name="price"
              onBlur={handleBlur}
              changeHandler={handleChange}
              inputTitle="Գինը"
              requiredFiled={true}
            />
            {touched.price && errors.price && (
              <ErrorFiled error={errors.price} />
            )}
          </div>
          <div className="L-spending-input">
            <InputFiled
              inputTitle="Քանակը"
              requiredFiled={true}
              value={values.quantity}
              name="quantity"
              type="number"
              changeHandler={handleChange}
              onBlur={handleBlur}
            />
            {touched.quantity && errors.quantity && (
              <ErrorFiled error={errors.quantity} />
            )}
          </div>
          <GetSentDataBtn
            cancelPrintClick={closeModal}
            confirmExecuteClick={handleSubmit}
            executeClass={!(isValid && dirty) ? 'G-disabled-button' : ''}
            executeDisabled={!(isValid && dirty)}
          />
        </form>
      </div>
    </Modal>
  );
};

export default SpendingForm;
