import React, { useEffect, useState } from 'react';
import Input from '../../components/Input/Input';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import { GeneraleMatNameField } from './FieldsConfig';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import AddNew from '../../../App/Elements/Add/AddNew';
import NewItemForms from './NewItemForms';

const GeneraleMatNameForm = ({
  onChange,
  materialNameFormData,
  setFieldValue,
  values,
  errors,
  touched,
  handleBlur,
  getMaterialNameAccount,
}) => {
  let dropDownOptions = [];
  const customClass = 'L-material-value-name-filed';
  const [openNewItemForm, setOpenNewItemForm] = useState(false);

  const fetchAccountData = async (dropdownItemId) => {
    try {
      const res = await getMaterialNameAccount(dropdownItemId);
      if (res) {
        setFieldValue('accCode', res.accountCode);
        setFieldValue('accName', res.accountName);
      }
    } catch (error) {
      console.error('Error fetching account data:', error);
    }
  };

  const updateFieldValueAndDropdown = async (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id;

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);

    if (fieldName === 'groupName' && dropdownItemId) {
      await fetchAccountData(dropdownItemId);
    }
  };

  const addItem = (fieldName) => {
    setOpenNewItemForm(fieldName);
  };

  return (
    <div className="L-material-values-name scrollable">
      {GeneraleMatNameField?.map((field, index) => {
        const {
          fieldType,
          fieldName,
          label,
          name,
          required,
          Children,
          optionName,
          dropdownTitle,
          type,
          dropdownId,
          addNewItem,
        } = field;

        const fieldError = touched[name] && errors[name];

        if (typeof Children === 'function') {
          if (materialNameFormData) {
            dropDownOptions = Children(materialNameFormData[optionName]);
          }
        }

        switch (fieldType) {
          case 'input':
            return (
              <div key={name} className={customClass}>
                <Input
                  type={type}
                  inputTitle={label}
                  name={name}
                  requiredField={required}
                  onChange={onChange}
                  value={values[name]}
                  onBlur={handleBlur}
                />
                {fieldError && <ErrorFiled error={fieldError} />}
              </div>
            );
          case 'dropdown':
            return (
              <div
                key={fieldName}
                className={`${customClass} ${
                  addNewItem ? 'G-dropdown-flex-block' : ''
                }`}
              >
                <SelectControl
                  selectBoxTitle={dropdownTitle}
                  requiredFiled={required}
                  value={{
                    label: values[fieldName] || '',
                    value: values[fieldName] || '',
                  }}
                  options={dropDownOptions || []}
                  onChange={(selectedOption) =>
                    updateFieldValueAndDropdown(
                      fieldName,
                      selectedOption,
                      dropdownId,
                    )
                  }
                  onBlur={handleBlur}
                />
                {required && !values[fieldName] && fieldError && (
                  <ErrorFiled error={fieldError} />
                )}

                {addNewItem && (
                  <AddNew
                    onClick={() => addItem(fieldName)}
                    content={dropdownTitle}
                  />
                )}
              </div>
            );

          default:
            return null;
        }
      })}
      {openNewItemForm && (
        <NewItemForms
          formType={openNewItemForm}
          closeModal={setOpenNewItemForm}
          setFieldValue={setFieldValue}
        />
      )}
    </div>
  );
};

export default GeneraleMatNameForm;
