import {
  DELETE_TABLE,
  FETCH_TABLES,
  FETCH_TABLES_FORM,
  UPDATE_TABLE,
} from './constants';

const initialState = {
  tables: null,
  tablesForm: null,
};

export const tablesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TABLES:
      return {
        ...state,
        tables: payload,
      };
    case FETCH_TABLES_FORM:
      return {
        ...state,
        tablesForm: payload,
      };

    case UPDATE_TABLE:
      return {
        ...state,
        tables: {
          ...state.tables,
          graphs: state.tables.graphs?.map((i) => {
            return i.calendarID === payload.calendarID ? payload : i;
          }),
        },
      };
    case DELETE_TABLE:
      return {
        ...state,
        tables: {
          ...state.graphs,
          graphs: state.tables.graphs.filter(
            (item) => item.calendarID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
