import { createAction } from "../actionCreators";
import {
  DELETE_PARTNER_DATA,
  EDIT_PARTNER_DATA,
  GET_PARTNER_DATA,
  LOADING,
  GET_PARTNER_BALANCE,
  EDIT_PARTNER_BALANCE,
  DELETE_PARTNER_BALANCE,
  GET_PARTNER_BANK,
  EDIT_PARTNER_BANK,
  DELETE_PARTNER_BANK,
} from "./constants";

export const getPartnerAction = createAction(GET_PARTNER_DATA);
export const loadingAction = createAction(LOADING, false);
export const editPartnerAction = createAction(EDIT_PARTNER_DATA);
export const deletePartnerAction = createAction(DELETE_PARTNER_DATA);

export const getPartnerBalanceAction = createAction(GET_PARTNER_BALANCE);
export const editPartnerBalanceAction = createAction(EDIT_PARTNER_BALANCE);
export const deletePartnerBalanceAction = createAction(DELETE_PARTNER_BALANCE);

export const getPartnerBankAction = createAction(GET_PARTNER_BANK);
export const editPartnerBankAction = createAction(EDIT_PARTNER_BANK);
export const deletePartnerBankAction = createAction(DELETE_PARTNER_BANK);
