import React from 'react';
import { deleteRequest } from '../../App/Api/Api';
import { useDispatch } from 'react-redux';
import AcceptOrCancelModal from '../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import {
  deleteCalendarAction,
  removeDayNoteAction,
} from '../../App/Redux/calendarSlices/useAction';

const EventRemoveForm = ({ id, closeModal, closeEventForm }) => {
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  const deleteRowDate = () => {
    deleteRequest(`deleteCalendarEvent/${id}`).then((res) => {
      if (res.data.errorCode === 0) {
        //   dispatch(removeDayNoteAction(id)); id chka
        dispatch(deleteCalendarAction(id));

        handleClose();
        closeEventForm();
      }
    });
  };

  return (
    <AcceptOrCancelModal
      closeModal={handleClose}
      confirmClick={deleteRowDate}
      modalContent="Համոզվա՞ծ եք որ ուզում եք ջնջել այն"
    />
  );
};

export default EventRemoveForm;
