import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest, getRequest } from '../../../Api/Api';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../../Components/Alert';
import { deletePartnerBalanceAction } from '../../../Redux/partnerSlices/useAction';
import { modalContent } from '../../../Utilities/Utilities';
import { InitBalancesAllowDeleteText } from '../../../GlobalTitle/GlobalTitle';

const DeletePartnerBalance = ({ rowData, closeModal }) => {
  const { initBalanceID, partnerID, accountCode, cashSign } = rowData || {};
  const [errorHandler, setErrorHandler] = useState('');
  const [isAllowDelete, setIsAllowDelete] = useState(false);
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  useEffect(() => {
    async function checkAllowDelete() {
      try {
        const url = `preDeleteEditPartnerInitBalance?partnerID=${partnerID}&accountCode=${accountCode}&cashSign=${cashSign}`;
        const res = await getRequest(url);
        const { errorCode, message } = res.data;
        if (res.data.errorCode === 1) {
          setIsAllowDelete(true);
        } else if (errorCode === 100) {
          setErrorHandler(message);
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    checkAllowDelete();
  }, [partnerID, accountCode, cashSign]);

  const deleteRowDate = () => {
    deleteRequest(`deletePartnerInitBalance/${initBalanceID}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deletePartnerBalanceAction(initBalanceID));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={closeModal}
        confirmClick={deleteRowDate}
      />
      <AcceptOrCancelModal
        closeModal={closeModal}
        confirmClick={deleteRowDate}
        modalContent={
          isAllowDelete ? InitBalancesAllowDeleteText : modalContent
        }
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default DeletePartnerBalance;
