import React, { useEffect, useState } from 'react';
import CalculationSheetsTable from './CalculationSheetsTable';
import Header from '../../../App/Components/Header/Header';
import CalculationSheetTotal from './CalculationSheetTotal';
import CalculationSheetAction from './CalculationSheetAction';
import useCalculationSheets from '../../hooks/useCalculationSheets';
import './style.scss';

const CalculationSheetsViewing = () => {
  const {
    getCalculationSheets,
    calculationSheetData,
    getCalculationSheetMonth,
    getExecuteCalculationSheet,
    calculationTableData,
    setCalculationTableData,
    isLoading,
  } = useCalculationSheets();

  const { salaryTotals, totals } = calculationTableData || {};
  const [query, setQuery] = useState({});
  useEffect(() => {
    getCalculationSheets();
  }, []);

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      years: calculationSheetData?.currentYear,
      year: calculationSheetData?.currentYear,
    }));
  }, [calculationSheetData?.currentYear]);

  const resetTableData = () => {
    setCalculationTableData(null);
  };

  return (
    <div className="G-medium-wrap">
      <Header
        pageTitle="Հաշվարկային թերթիկների դիտում"
        headerBtn={false}
        showCalculationDate={true}
      />
      <CalculationSheetAction
        query={query}
        setQuery={setQuery}
        calculationSheetData={calculationSheetData}
        getCalculationSheetMonth={getCalculationSheetMonth}
        getExecuteCalculationSheet={getExecuteCalculationSheet}
        resetTableData={resetTableData}
      />
      <CalculationSheetsTable
        isLoading={isLoading}
        salaryTotals={salaryTotals}
      />
      {salaryTotals && <CalculationSheetTotal totals={totals} />}
    </div>
  );
};

export default CalculationSheetsViewing;
