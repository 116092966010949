import React, { useState } from 'react';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import { BenefitCalculationFields } from './FieldsConfig';
import Input from '../../Input/Input';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import DateComponent from '../../components/dateComponent/DateComponent';
import { dataTimeFormat, dateFormat } from '../../../App/Utilities/Utilities';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import InputTitle from '../../Input/InputTitle';

const GeneralBenefitCalculation = ({
  benefitCalculation,
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  isValid,
  dirty,
  handleBlur,
  handleSubmit,
  handleClose,
  getBenefitIsValid,
  isVacationDateValid,
  setIsVacationDateValid,
}) => {
  const [isDisConfirm, setIsDisConfirm] = useState(false);
  const onClose = () => setIsVacationDateValid(false);

  let dropDownOptions = [];

  const updateFieldValueAndDropdown = async (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }

    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id;

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
    const { empNo, benStartDate, benEndDate } = values || {};
    const newVal = {
      empNo: empNo,
      stNo: dropdownItemId,
      benStartDate: benStartDate,
      benEndDate: benEndDate,
    };
    await getBenefitIsValid(newVal);
  };

  const onChangeDate = async (date, name) => {
    if (!date) {
      setFieldValue(name, null);
      return;
    }

    const formattedDate = dataTimeFormat(date);
    setFieldValue(name, formattedDate);

    const { empNo, stNo, benStartDate, benEndDate } = values || {};

    const newVal = {
      empNo,
      stNo,
      benStartDate: name === 'benStartDate' ? date : benStartDate,
      benEndDate: name === 'benEndDate' ? date : benEndDate,
    };

    if (name === 'benStartDate' || name === 'benEndDate') {
      await getBenefitIsValid(newVal);
    }
  };

  const onConfirmClick = () => {
    setIsDisConfirm(false);
    onClose();
  };
  const onCancelClick = () => {
    setIsDisConfirm(true);
    onClose();
  };

  return (
    <div className="L-general-benefit-calculation-block">
      <form onSubmit={handleSubmit}>
        {BenefitCalculationFields?.map((field) => {
          const {
            fieldType,
            fieldName,
            label,
            name,
            required,
            Children,
            optionName,
            dropdownTitle,
            type,
            dropdownId,
            dateTitle,
            maxLength,
            maxDate,
            dateFields,
            disabled,
          } = field;

          const fieldError = touched[name] && errors[name];

          if (typeof Children === 'function' && benefitCalculation) {
            dropDownOptions = Children(benefitCalculation[optionName]);
          }

          switch (fieldType) {
            case 'input':
              return (
                <div key={`input-${name}`} className="L-general-benefit-filed">
                  <Input
                    type={type}
                    inputTitle={label}
                    name={name}
                    requiredFiled={required}
                    onChange={handleChange}
                    value={values[name]}
                    maxLength={maxLength}
                    disabled={disabled}
                  />
                </div>
              );
            case 'amount':
              return (
                <div key={name} className="L-general-benefit-filed">
                  <NumericFormatFiled
                    inputTitle={label}
                    name={name}
                    requiredFiled={required}
                    changeHandler={handleChange}
                    value={values[name]}
                    onBlur={handleBlur}
                    disabled={disabled}
                  />
                  {fieldError && <ErrorFiled error={fieldError} />}
                </div>
              );
            case 'checkbox':
              return (
                <Checkbox
                  name={name}
                  label={label}
                  value={values[name]}
                  checked={values[name]}
                  onChange={() => setFieldValue(name, !values[name])}
                  onBlur={handleBlur}
                />
              );
            case 'dropdown':
              return (
                <div
                  key={`dropdown-${fieldName}`}
                  className="L-general-benefit-filed"
                >
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={required}
                    value={{
                      label: values[fieldName] || '',
                      value: values[fieldName] || '',
                    }}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      updateFieldValueAndDropdown(
                        fieldName,
                        selectedOption,
                        dropdownId,
                      )
                    }
                    onBlur={handleBlur}
                  />
                  {required && !values[fieldName] && fieldError && (
                    <ErrorFiled error={fieldError} />
                  )}
                </div>
              );

            case 'dateFlex':
              return (
                <div className="L-vacation-date-wrap">
                  <InputTitle requiredField={required} inputTitle={dateTitle} />
                  <div className="employees-radio-flex G-flex">
                    {dateFields?.map(({ dateName, dateTitle }) => (
                      <DateComponent
                        key={dateName}
                        value={dateFormat(values[dateName] || '')}
                        onChange={(date) => onChangeDate(date, dateName)}
                        name={dateName}
                        title={dateTitle}
                        requiredFiled={false}
                        maxDate={maxDate}
                        disabled={disabled}
                        openToDate={new Date(values[dateName])}
                      />
                    ))}
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={!values.stNo || isDisConfirm ? 'G-disabled-button' : ''}
          executeDisabled={!values.stNo || isDisConfirm}
        />
      </form>

      {isVacationDateValid?.length > 0 && (
        <AcceptOrCancelModal
          modalContent={isVacationDateValid + 'Ցանկանում եք շարունակել'}
          closeModal={onCancelClick}
          confirmClick={onConfirmClick}
        />
      )}
    </div>
  );
};

export default GeneralBenefitCalculation;
