import React, { useState } from 'react';
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';
import Tooltip from '../../Components/Tooltip/Tooltip';

import Action from '../../Components/Table/Action';
import {
  Commas,
  dateFormat,
  tableActionTitle,
} from '../../Utilities/Utilities';

import { getEInvoicingActions } from './EInvoicingActionType';
import Forms from './Forms';

const EInvoicingOperationsTable = ({ loading, generalData }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: <Tooltip content="Ստորագրման ամսաթիվ">ՍՏ/Ա</Tooltip>,
      cell: (row) => row.signedByTheBuyer,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 750, overflow: 'hidden' },
    },
    {
      title: 'ՀՎՀՀ',
      cell: (row) => row.partnerTaxCode,
      customStyle: { maxWidth: 70 },
    },
    {
      title: <Tooltip content="Սերիա և համարը">Ս/Հ</Tooltip>,
      cell: (row) => row.invoiceSerialNumber,
      customStyle: { maxWidth: 90 },
    },
    {
      title: <Tooltip content="Մատակարարման ա/թ">Մատ.ա/թ</Tooltip>,
      cell: (row) => dateFormat(row.invoiceDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Արժեքը',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'ԱԱՀ',
      cell: (row) => Commas(row.valueAddedTax),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Դեբետ',
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 90 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          mainAction="operation"
          mainActionName="Գործառնության կատարում"
          handelChange={(value, row) =>
            setShowForm({
              type: value,
              value: row,
            })
          }
          actionItem={getEInvoicingActions(row)}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 190 },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {generalData !== null && generalData && generalData?.length ? (
        <Table
          customClass="L-EInvoicing-operations-table"
          data={generalData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default EInvoicingOperationsTable;
