import * as Yup from "yup";
import { SelectFieldMessage, InputFiledMessage } from './ErrorMessage';
import { Amount } from './ValidationForAllFiled';

export const PurchaseProductValidation = () =>
  Yup.object().shape({
    productName: Yup.string().required(SelectFieldMessage),
    accountID: Yup.string().required(SelectFieldMessage),
    unitName: Yup.string().required(SelectFieldMessage),
    quantity: Yup.number()
      .required(InputFiledMessage)
      .positive('Մուտքագրեք 0-ից մեծ թիվ')
      .moreThan(0, 'Մուտքագրեք 0-ից մեծ թիվ'),
    price: Yup.string().required(InputFiledMessage),
  });

  

export const IncomeTaxFormValidation = () =>
  Yup.object().shape({
    creditAccountCode: Yup.string().required(SelectFieldMessage),
    debitAccountCode: Yup.string().required(SelectFieldMessage),
    amount: Amount,
  });
