import React from 'react';
import { useContext, useState } from 'react';
import { Context } from '../../../index.js';
import { update } from '../../http/entrAPI';
import Calendar from '../Calendar/Calendar';
import '../modals/index.scss';
import { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { dataTimeFormat, dateFormat } from '../../Utilities/Utilities';
import Modal from '../Modal/Modal.jsx';
import FormControl from '../FormControl/FormControl.jsx';
import Button from '../Button/Button.jsx';
import Dropdown from '../Dropdown/Dropdown.jsx';
import './index.scss';
const UpdateEntr = ({ enterData, closeModal }) => {
  const {
    id,
    amount,
    comment,
    imgColor,
    curr,
    aspectName,
    accountName,
    userId,
    crAt,
    currencyId,
    aspectId,
    accountId,
  } = enterData || {};
  const handleClose = () => closeModal(false);
  const { currency, entr } = useContext(Context);

  const currs = currency.Currency;

  useEffect(() => {
    setInitComment(comment);
    setInitAmount(amount);
  }, [comment, amount]);

  const [initAmount, setInitAmount] = useState(amount);

  const [initComment, setInitComment] = useState(comment);

  const [crAtd, setCrAtd] = useState(crAt);

  const [currencyIdd, setCurrencyIdd] = useState(currencyId);
  const [currd, setCurrd] = useState(curr);

  useEffect(() => {
    setCrAtd(crAt);
    setCurrencyIdd(currencyId);
    setCurrd(curr);
  }, [crAt, currencyId, curr]);

  const upEntr = async (e) => {
    e.preventDefault();
    const amountD = parseFloat(initAmount);

    const formData = {
      amount: amountD,
      comment: initComment,
      id: id,
      crAt: dataTimeFormat(crAtd),
      accountId: accountId,
      accountName: accountName,
      currencyId: currencyIdd,
      curr: currd,
      userId: userId,
      aspectId: aspectId,
      aspectName: aspectName,
      imgColor: imgColor,
    };

    const data = await update(formData);
    if (data) {
      const searchIndex = entr.Entr.findIndex((ent) => ent.id === id);

      entr.Entr[searchIndex].amount = amountD;
      entr.Entr[searchIndex].comment = initComment;
      entr.Entr[searchIndex].crAt = dataTimeFormat(crAtd);
      entr.Entr[searchIndex].currencyId = currencyIdd;
      entr.Entr[searchIndex].curr = currd;
    }
    handleClose();
  };

  const dateChangeHandler = (val) => {
    setCrAtd(val);
  };

  const currencyHandler = (value) => {
    setCurrencyIdd(value.id);
    setCurrd(value.name);
  };

  const curreseOption = currs?.map((i) => ({
    ...i,
    label: i.name,
  }));

  return (
    <Modal customClass="enter-form-modal" closeModal={closeModal}>
      <div className="enter-form">
        <h3 className="enter-form-title">
          {accountName} / {aspectName}
        </h3>

        <form>
          <div className="calendar-wrap">
            <p>Ամսաթիվ</p>
            <Calendar
              value={dateFormat(crAtd)}
              name="crAtd"
              onChange={(date, e) => dateChangeHandler(date, e)}
            />
          </div>

          <Dropdown
            options={curreseOption}
            value={{ label: currd, value: currd }}
            onChange={(cr) => {
              currencyHandler(cr);
            }}
          />

          <div className="amount-filed">
            <NumericFormat
              className="input-filed"
              placeholder="Գումար"
              value={initAmount}
              type="text"
              thousandsGroupStyle={true}
              thousandSeparator=","
              valueIsNumericString={true}
              onValueChange={(values) => {
                const { value } = values;
                setInitAmount(value);
              }}
              isAllowed={({ formattedValue }) => formattedValue.length <= 15}
            />
          </div>

          <div className="comment-filed">
            <FormControl
              className=""
              placeholder="Գործարքի վերաբերյալ նշում"
              name="comment"
              value={initComment}
              onChange={(e) => setInitComment(e.target.value)}
              maxLength={83}
            />
          </div>

          <Button
            type="button"
            onClick={upEntr}
            customClass=""
            text="Խմբագրել"
          />
        </form>
      </div>
    </Modal>
  );
};

export default UpdateEntr;
