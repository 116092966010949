import { removeCommas } from '../../Utilities/Utilities';

export const getInvoiceTotal = (arr) => {
  const totalExpenditure =
    arr &&
    arr?.reduce(function (agger, val) {
      return agger + removeCommas(val?.totalPrice);
    }, 0);
  return totalExpenditure;
};
