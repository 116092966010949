import React, { useEffect, useState } from 'react';
import GeneralSummaryTable from './GeneralSummaryTable';
import GeneralSummaryAction from './GeneralSummaryAction';
import Header from '../../../App/Components/Header/Header';
import useGeneralSummary from '../../hooks/useGeneralSummary';
import './generalSummary.scss';
import { ConvertSummariesDataExcel } from '../../Config/excelConfig';

const GeneralSummary = () => {
  const {
    getGeneralSummaryData,
    generalSummaryData,
    getExecuteGeneralSummary,
    generalSummaryTableData,
    setGeneralSummaryTableData,
  } = useGeneralSummary();

  const { salarySummaries, message } = generalSummaryTableData || {};
  const [query, setQuery] = useState({
    eContract: '',
    cContract: '',
  });

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      eContract: generalSummaryData?.eContract,
      cContract: generalSummaryData?.cContract,
      startDate: generalSummaryData?.startDate,
      endDate: generalSummaryData?.endDate,
    }));
  }, [
    generalSummaryData?.cContract,
    generalSummaryData?.eContract,
    generalSummaryData?.endDate,
    generalSummaryData?.startDate,
  ]);

  useEffect(() => {
    getGeneralSummaryData();
  }, []);
  const summariesExcelData = ConvertSummariesDataExcel(salarySummaries);

  const resetTableData = () => {
    setGeneralSummaryTableData(null);
  };
  return (
    <>
      <Header
        pageTitle="Ընդհանուր ամփոփագիր"
        headerBtn={false}
        showCalculationDate={true}
      />

      <GeneralSummaryAction
        query={query}
        setQuery={setQuery}
        generalSummaryData={generalSummaryData}
        getExecuteGeneralSummary={getExecuteGeneralSummary}
        summariesExcelData={summariesExcelData}
        salarySummaries={salarySummaries}
        resetTableData={resetTableData}
      />

      <GeneralSummaryTable salarySummaries={salarySummaries} />
    </>
  );
};

export default GeneralSummary;
