import React, { useState, useEffect, useRef } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import DateComponent from '../dateComponent/DateComponent';
import Button from '../../../App/Components/Button/Button';
import useSalaryCalculationDate from '../../hooks/useSalaryCalculationDate';
import Alert from '../../../App/Components/Alert';
import {
  dataTimeFormat,
  dateFormat,
  windowReload,
} from '../../../App/Utilities/Utilities';

const SalaryCalculationDateForm = ({
  onClose,
  calculationDate,
  saveNewCalculationDate,
}) => {
  const { errorMess, setErrorMess } = useSalaryCalculationDate();
  const [date, setDate] = useState(calculationDate?.salaryDate);

  const [isDisabled, setIsDisabled] = useState(true);
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    setDate(calculationDate?.salaryDate);
  }, [calculationDate?.salaryDate]);

  useEffect(() => {
    setIsDisabled(date === calculationDate?.salaryDate);
  }, [date, calculationDate]);

  const onDateChange = (date) => {
    setDate(dataTimeFormat(date));
    setIsDisabled(false);
  };

  const onSaveDateChange = async () => {
    try {
      const data = {
        settingID: calculationDate.settingID,
        companyID: calculationDate.companyID,
        salaryDate: date,
      };
      const result = await saveNewCalculationDate(data, onClose);
      windowReload();
      if (result && isMountedRef.current) {
        setIsDisabled(true);
      }
    } catch (error) {
      console.error('Error occurred while saving:', error);
    }
  };

  return (
    <Modal
      customClass="G-salary-calculation-date-form"
      onDrag={true}
      closeHandler={onClose}
    >
      <DateComponent
        value={dateFormat(date)}
        onChange={(date) => onDateChange(date)}
        name="name"
        title="Հաշվարկի ամսաթիվը"
        openToDate={new Date(date)}
      />
      <div className="G-confirm-btn-wrap">
        <Button
          disabled={isDisabled}
          customClass={isDisabled && 'G-disabled-button'}
          text="Հաստատել"
          type="submit"
          onClick={onSaveDateChange}
        />
      </div>
      <Alert description={errorMess} onClose={setErrorMess} />
    </Modal>
  );
};

export default SalaryCalculationDateForm;
