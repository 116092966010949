import React from 'react';
import './SelectionOptions.scss';
import { useSelector } from 'react-redux';

const SelectionOptions = ({ selectedOptions, setSelectedOptions }) => {
  const { calendarData } = useSelector((state) => state.getCalendarData);
  const { optionType } = calendarData || {};

  const handleCheckboxChange = (id) => {
    const updatedOptions = selectedOptions ? selectedOptions.split(',') : [];
    const index = updatedOptions.indexOf(String(id));

    if (index !== -1) {
      updatedOptions.pop(id);
    } else {
      if (updatedOptions?.length === 1 && updatedOptions[0] == 0) {
        updatedOptions[0] = id;
      } else {
        updatedOptions.push(id);
      }
    }

    const updatedValue =
      updatedOptions?.length > 0 ? updatedOptions.join(',') : '0';
    setSelectedOptions(updatedValue);
  };

  return (
    <div className="selection-option-block">
      <h3 className="calendar-title">Ընտրության տարբերակներ</h3>
      <div className="selection-option">
        {optionType?.map((i, index) => {
          const isChecked = selectedOptions
            ? selectedOptions?.includes(i.id)
            : false;

          return (
            <div className="option-type-wrap" key={i.id}>
              <label className="container">
                {i.typeName}
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => handleCheckboxChange(i.id)}
                />
                <span
                  className="checkmark"
                  style={{ '--typeColor': i.typeColor }}
                ></span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectionOptions;
