import React, { useEffect, useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import { Commas } from '../../../App/Utilities/Utilities';
import checkIcon from '../../assets/icons/check.svg';
import ConfirmPositionsActionButtons from './ConfirmPositionsActionButtons';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import Alert from '../../../App/Components/Alert';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
const ConfirmPositionsTable = ({
  mainData,
  isDisabledConfBtn,
  setIsDisabledConfBtn,
  query,
  getConfirmData,
  executePosition,
  getBeforeBaselineState,
  getBaselineState,
  errorMess,
  setErrorMess,
}) => {
  const [newPosition, setNewPosition] = useState(mainData);
  const [checkSign, setCheckSign] = useState(false);
  const toggleErrorMess = () => setErrorMess((prev) => !prev);

  useEffect(() => {
    const checkEveryItem = newPosition?.every((item) => item.sign);
    setCheckSign(checkEveryItem);
  }, [newPosition]);

  useEffect(() => {
    setNewPosition(mainData);
  }, [mainData]);

  const handleChangeDays = (e, targetRow) => {
    const { value } = e.target;
    const newDays = Number(value);
    if (newDays > targetRow.calcDays) {
      setErrorMess('Գերազանցում է ամսվա աշխատանքային օրերի թվին՝ փոխեք ');
      return;
    }
    if (isNaN(newDays) || newDays < 0 || newDays > targetRow.calcDays) {
      return;
    }
    setIsDisabledConfBtn(false);
    const updatedRows = newPosition?.map((row) => {
      if (targetRow.positionID === row.positionID) {
        const newAmount = (row.salary / row.calcDays) * newDays;

        const updatedRow = {
          ...row,
          hours: +(row.graphHours * newDays).toFixed(2),
          addAmount: Math.round(newAmount),
          days: newDays,
          isModifed: true,
          confirmSign: true,
          sign: row.sign ? true : row.sign,
        };

        return updatedRow;
      }
      return row;
    });

    setNewPosition(updatedRows);
  };

  const handleChangeHours = (e, targetRow) => {
    const { value } = e.target;
    const newHours = Number(value);
    if (newHours > targetRow.calcHours) {
      return;
    }
    if (isNaN(newHours) || newHours < 0 || newHours > targetRow.calcHours) {
      return;
    }
    setIsDisabledConfBtn(false);
    const updatedRows = newPosition?.map((row) => {
      if (row.positionID === targetRow.positionID) {
        const newDay = newHours / row.graphHours;
        const newAddAmount = row.salary * newHours;
        const upDays = newDay % 2 > 0 ? Math.trunc(newDay) + 1 : newDay;

        const updatedRow = {
          ...row,
          hours: newHours,
          days: upDays,
          addAmount: Math.round(newAddAmount),
          isModifed: true,
          confirmSign: true,
          sign: row.sign ? true : row.sign,
        };
        return updatedRow;
      }
      return row;
    });

    setNewPosition(updatedRows);
  };

  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 700, overflow: 'hidden' },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ազգանուն, Անուն, Հայրանուն',
      cell: (row) => row.emplName,
      customStyle: { maxWidth: 600, overflow: 'hidden' },
    },
    {
      title: 'Աշխատաժամանակի տաբելը',
      cell: (row) => row.graphName,
      customStyle: { maxWidth: 300, overflow: 'hidden' },
    },
    {
      title: 'Օրը',
      cell: (row) => (
        <input
          type="number"
          value={Math.round(row?.days)}
          onChange={(e) => handleChangeDays(e, row)}
          disabled={row?.formSign === 2}
        />
      ),
      isEdit: true,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Ժամ',
      cell: (row) => (
        <input
          type="number"
          value={row?.hours}
          onChange={(e) => handleChangeHours(e, row)}
          disabled={row?.formSign === 1}
          maxLength={4}
        />
      ),
      isEdit: true,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գումար',
      cell: (row) => Commas(row.addAmount),
      customStyle: { maxWidth: 160 },
    },
    {
      title: (
        <span className="G-flex">
          <Checkbox onChange={(e) => updateCheckboxes(e)} checked={checkSign} />
        </span>
      ),
      cell: (row) => (
        <Checkbox
          checked={row.sign}
          value={row.sign}
          onChange={(e) => updateCheckboxes(e, row)}
        />
      ),
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Հաստատված',
      cell: (row) =>
        row.confirmSign && !row.isModifed ? (
          <img className="G-confirm-icon" src={checkIcon} alt="checkIcon" />
        ) : null,
      customStyle: { maxWidth: 100 },
    },
  ];
  const updateCheckboxes = (e, row = null) => {
    const { checked } = e.target;
    setIsDisabledConfBtn(false);

    const updatedList = newPosition?.map((item) =>
      row && item.positionID === row.positionID
        ? { ...item, isModifed: true, sign: checked }
        : {
            ...item,
            isModifed: row ? item.isModifed : true,
            sign: row ? item.sign : checked,
          },
    );

    setNewPosition(updatedList);
  };

  const onSaveHandler = async () => {
    try {
      await executePosition(newPosition);
      getConfirmData(query);
      setIsDisabledConfBtn(true);
    } catch (error) {
      console.error('Error occurred while saving:', error);
    }
  };

  const onBaselineState = async () => {
    try {
      await getBeforeBaselineState(query?.stNo);
    } catch (error) {
      console.error('Error fro baseline state:', error);
    }
  };

  const onRemoveHandler = async () => {
    try {
      await getBaselineState(query?.stNo, query);
      toggleErrorMess();
    } catch (error) {
      console.error('Error fro baseline state:', error);
    }
  };
  return (
    <>
      {newPosition !== null && newPosition?.length ? (
        <Table
          customClass="L-confirm-positions-table G-table-has-scroll"
          data={newPosition}
          columnConfig={columnConfig}
        />
      ) : null}
      {newPosition !== null && newPosition?.length && (
        <ConfirmPositionsActionButtons
          onBaselineState={onBaselineState}
          onSaveHandler={onSaveHandler}
          isDisabledConfBtn={isDisabledConfBtn}
        />
      )}
      {errorMess && (
        <AcceptOrCancelModal
          modalContent={errorMess}
          closeModal={toggleErrorMess}
          confirmClick={onRemoveHandler}
        />
      )}
    </>
  );
};

export default ConfirmPositionsTable;
