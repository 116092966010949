import { dateFormat } from '../../Utilities/Utilities';

export const ConvertDataExcel = (data) => {
  return (
    data !== null &&
    data?.length > 0 &&
    data?.map((i) => {
      return {
        Ամսաթիվ: dateFormat(i.supplierDate),
        Գործընկերներ: i.partnerName,
        ՀՎՀՀ: i.partnerTaxCode,
        'Սերիա և համարը': i.invoiceSerialNumber,
        Արժեքը: i.amount,
        ԱԱՀ: i.valueAddedTax,
        'Ստորագրված է': i.signedByTheBuyer,
      };
    })
  );
};

export default ConvertDataExcel;
