import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { putRequest } from '../../Api/Api';
import PartnerForm from './PartnerForm';
import { editPartnerAction } from '../../Redux/partnerSlices/useAction';
import Alert from '../../Components/Alert';

const EventEditForm = ({ rowData, closeModal }) => {
  const dispatch = useDispatch();
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = useCallback(() => closeModal(false), []);

  const onSubmit = useCallback(
    (values) => {
      const upVal = {
        ...values,
        identDoc: values.taxeServiceNumber || values.passport || values.idCard,
      };
      putRequest('editPartner', values)
        .then((res) => {
          if (res.data.errorCode === 0) {
            dispatch(editPartnerAction({ ...upVal }));
            handleClose();
          } else {
            setErrorHandler(res.data.message);
          }
        })
        .catch((err) => {
          console.log('err' + err);
        });
    },
    [dispatch, handleClose],
  );

  return (
    <>
      <PartnerForm
        initialData={rowData}
        closeModal={handleClose}
        onSubmit={onSubmit}
        partnerFormTitle="Խմբագրել Գործընկերոջը"
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventEditForm;
