import React, { useState } from 'react';
import Modal from '../../Components/Modal/Modal';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { removeCommas } from '../../Utilities/Utilities';
import { SelectControl } from '../../Components/SelectControl/SelectControl';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import InputFiled from '../../Components/InputFiled/InputFiled';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import AddProductForm from '../../Pages/Product/EventAddForm';
import AddUnitOfMeasureForm from '../../Pages/UnitOfMeasure/EventAddForm';
import { InvoiceGoodsValidation } from '../../Validation/InvoiceValidation';
import {
  updateProductAction,
  updateUnitOfMeasureAction,
} from '../../Redux/invoiceSlices/useAction';
import NumericFormatFiled from '../../Components/NumericFormat/NumericFormatFiled';
import SelectUnitsOfMeasurement from '../../Components/SelectBox/SelectUnitsOfMeasurement';
import AddNew from '../../Elements/Add/AddNew';

const ProductForm = ({ handleClose, initialValue, onSubmit }) => {
  const { invoiceFormData } = useSelector((state) => state.getInvoiceData);

  const [openProductModal, setOpenProductModal] = useState(false);
  const [openUnitOfMeasureModal, setOpenUnitOfMeasureModal] = useState(false);
  const [productData, setProductData] = useState({});
  const [unitOfMeasureData, setUnitOfMeasureData] = useState({});

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...initialValue,
      ...productData,
      ...unitOfMeasureData,
    },
    validationSchema: InvoiceGoodsValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const productNameOptions = invoiceFormData?.descriptions?.map(
    ({ productName, productNameID }) => {
      return {
        label: productName,
        value: productName,
        id: productNameID,
      };
    },
  );

  const handleChangeProduct = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('productNameID', values.id);
    setFieldValue('productName', values.value);
  };

  const handleChangeUnit = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('unitID', values.id);
    setFieldValue('unitName', values.value);
  };

  const openProductForm = () => {
    setOpenProductModal(true);
  };

  const openUnitOfMeasureForm = () => {
    setOpenUnitOfMeasureModal(true);
  };

  return (
    <>
      <Modal
        closeHandler={handleClose}
        onDrag={true}
        customClass="L-invoice-goods-form"
      >
        <div className="L-purchase-product-form">
          <form onSubmit={onSubmit}>
            <div className="L-invoice-goods-select-wrap G-flex">
              <div className="L-invoice-prod-box G-relative">
                <SelectControl
                  selectBoxTitle="Ապրանքի, ծառայություն անվանումը"
                  requiredFiled={true}
                  options={productNameOptions}
                  onBlur={handleBlur}
                  value={{
                    label: values.productName,
                    value: values.productName,
                  }}
                  onChange={(values) => handleChangeProduct(values)}
                />
                {touched.productNameID && errors.productNameID && (
                  <ErrorFiled error={errors.productNameID} />
                )}
              </div>
              <AddNew content="ապրանք" onClick={openProductForm} />
            </div>
            <div className="L-invoice-goods-select-wrap G-flex">
              <div className="L-invoice-prod-box G-relative">
                <SelectUnitsOfMeasurement
                  options={invoiceFormData?.units}
                  value={{ label: values.unitName, value: values.unitName }}
                  onChange={(values) => handleChangeUnit(values)}
                />
                {touched.unitID && errors.unitID && (
                  <ErrorFiled error={errors.unitID} />
                )}
              </div>
              <AddNew content="Չափման միավոր" onClick={openUnitOfMeasureForm} />
            </div>

            <div className="L-invoice-goods-input-wrap">
              <NumericFormatFiled
                value={values.price}
                name="price"
                onBlur={handleBlur}
                changeHandler={handleChange}
                inputTitle="Գինը"
                requiredFiled={true}
              />
              {touched.price && errors.price && (
                <ErrorFiled error={errors.price} />
              )}
            </div>
            <div className="L-invoice-goods-input-wrap">
              <InputFiled
                inputTitle="Քանակը"
                requiredFiled={true}
                type="number"
                name="amount"
                value={values.amount}
                onBlur={handleBlur}
                changeHandler={handleChange}
              />
              {touched.amount && errors.amount && (
                <ErrorFiled error={errors.amount} />
              )}
            </div>
            <div className="L-invoice-goods-input-wrap">
              <NumericFormatFiled
                value={
                  values?.amount > 0
                    ? values.amount * removeCommas(values.price)
                    : 0
                }
                name="totalPrice"
                changeHandler={handleChange}
                inputTitle="Գումարը"
                disabled={true}
              />
            </div>
            <GetSentDataBtn
              cancelPrintClick={handleClose}
              confirmExecuteClick={handleSubmit}
              executeClass={!(isValid && dirty) ? 'G-disabled-button' : ''}
              executeDisabled={!(isValid && dirty)}
            />
          </form>
        </div>
      </Modal>

      {openProductModal && (
        <AddProductForm
          closeModal={setOpenProductModal}
          updateProductOutside={true}
          updateProductAction={updateProductAction}
          customProductState={setProductData}
        />
      )}
      {openUnitOfMeasureModal && (
        <AddUnitOfMeasureForm
          closeModal={setOpenUnitOfMeasureModal}
          updateUnitOfMeasureOutside={true}
          updateUnitOfMeasureAction={updateUnitOfMeasureAction}
          customUnitOfMeasureData={setUnitOfMeasureData}
        />
      )}
    </>
  );
};

export default ProductForm;
