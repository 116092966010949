import React from 'react';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';

const FormulationDropdown = ({ dropdownConfigs, handleDropdownChange }) => {
  return (
    <div className="L-formulation-dropdown-flex">
      {dropdownConfigs?.map((config, index) => (
        <SelectControl
          value={config.value}
          key={index}
          selectBoxTitle={config.title}
          options={config.options || []}
          onChange={(selectedOption) =>
            handleDropdownChange(selectedOption, config.name)
          }
          requiredFiled={config.required}
        />
      ))}
    </div>
  );
};

export default FormulationDropdown;
