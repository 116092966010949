import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Modal from '../../../Components/Modal/Modal';
import { CashOutflowValidation } from '../../../Validation/CashOutflowValidation';
import { updatePartnerAction } from '../../../Redux/cashOutflowOrderSlices/useAction';
import InputFiled from '../../../Components/InputFiled/InputFiled';
import ErrorFiled from '../../../Components/ErrorFiled/ErrorFiled';
import Calendar from '../../../Components/Calendar/Calendar';
import SelectPartner from '../../../Components/SelectBox/SelectPartner';
import { getRequest } from '../../../Api/Api';
import GetSentDataBtn from '../../../Components/GetSentDataBtn/GetSentDataBtn';
import {
  amountIsNotEnough,
  Commas,
  dateFormat,
  exceedAmount,
  exceedAmountMess,
  removeCommas,
  textFiledLength,
} from '../../../Utilities/Utilities';
import SelectAccount from '../../../Components/SelectBox/SelectAccount';
import { dataTimeFormat } from '../../../Utilities/Utilities';
import AddNew from '../../../Elements/Add/AddNew';
import { default as PartnerForm } from '../../../Pages/Partners/EventAddForm';

import NumericFormatFiled from '../../../Components/NumericFormat/NumericFormatFiled';
import DocNumFiled from '../../../Components/DocNumFiled';
import RegisterCalculationInReport from '../../../Components/RegisterCalculationInReport/RegisterCalculationInReport';

const CashOutflowOrderForm = (props) => {
  const {
    handleClose,
    initialData,
    onSubmit,
    addEvent = false,
    setErrorHandler,
  } = props;
  const { cashOutflowData } = useSelector((state) => state.getCashOutflowData);
  const { amdCurrID } = cashOutflowData || {};
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [correspondentAccount, setCorrespondentAccount] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(initialData.exchangeRate);
  const [cashAccount, setCashAccount] = useState([]);
  const [showPartnerForm, setShowPartnerForm] = useState(false);
  const [initAmount, setInitAmount] = useState(null);

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    touched,
    errors,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...initialData,
    },
    validationSchema: CashOutflowValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  useEffect(() => {
    setFieldValue('exchangeRate', exchangeRate);
    setFieldValue('amountAMD', values.amount);
  }, [exchangeRate, values.amount]);

  useEffect(() => {
    const havePartner = values?.partnerID ? true : false;
    if (!values?.partnerID) {
      getRequest(
        `getCorrespAccounts?companyID=${currentCompanyID}&accountCode=${251}&currencyID=${
          values?.currencyID
        }&debitCredit=${2}&partner=${havePartner}`,
      )
        .then((res) => {
          setCorrespondentAccount(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    } else if (values?.partnerID) {
      getRequest(
        `getAccountsForPartner?companyID=${currentCompanyID}&partnerID=${values.partnerID}`,
      )
        .then((res) => {
          setCorrespondentAccount(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [currentCompanyID, values?.currencyID, values?.partnerID]);

  useEffect(() => {
    if (values.currencyCode !== 'AMD' || values?.credCurrCode !== 'AMD') {
      getRequest(
        `getRate?rateDate=${dataTimeFormat(values?.docDate)}&iso=${
          values?.currencyCode === 'AMD'
            ? values?.credCurrCode
            : values?.currencyCode
        }`,
      )
        .then((res) => {
          if (res.data !== null) {
            setExchangeRate(res.data);
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }

    if (values.currencyCode === 'AMD' || values?.credCurrCode === 'AMD') {
      setExchangeRate(1);
    }
  }, [values?.credCurrCode, values.currencyCode, values?.docDate]);

  const changAccount = values.currencyID === amdCurrID ? 2511 : 2512;

  useEffect(() => {
    getRequest(
      `getCompanyAccounts?companyID=${currentCompanyID}&accountCode=${changAccount}&currencyID=${values?.currencyID}`,
    )
      .then((res) => {
        setCashAccount(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });

    if (values.accountCredit) {
      getRequest(
        `getAccountBalance?companyID=${currentCompanyID}&account=${
          values.accountCredit
        }&currencyID=${values?.currencyID}&dateEnd=${dataTimeFormat(
          values.docDate,
        )}`,
      )
        .then((res) => {
          setInitAmount(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [
    changAccount,
    currentCompanyID,
    values.accountCredit,
    values?.currencyID,
    values.docDate,
  ]);

  const handleChangeCreditAccount = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('accountCredit', values.value);
  };

  const handleChangePartner = (values) => {
    if (!values) {
      values = {
        value: '',

        id: 0,
      };
      setFieldValue('correspAccount', '');
      setFieldValue('recipient', '');
    }

    setFieldValue('recipient', values.value);

    setFieldValue('partnerID', values.id);
    setFieldValue('partnerName', values.value);
  };

  const handleChangeCorrespondentAccount = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('correspAccount', values.value);
  };

  const onChangeData = (date) => {
    setFieldValue('docDate', date);
  };
  const openPartnerAddForm = () => {
    setShowPartnerForm(true);
  };

  const theAmountIsExceeded =
    !cashAccount?.length &&
    removeCommas(values.amount) > 0 &&
    removeCommas(values.amount) > initAmount;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (theAmountIsExceeded) {
        setErrorHandler(amountIsNotEnough);
      } else if (
        !values?.correspAccount.includes('252') &&
        removeCommas(values.amount) > exceedAmount
      ) {
        setErrorHandler(exceedAmountMess);
      } else {
        return;
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [
    setErrorHandler,
    theAmountIsExceeded,
    values.correspAccount,
    values.amount,
  ]);

  const disabledBtnHandler = () => {
    if (theAmountIsExceeded) {
      return true;
    } else if (
      !values?.correspAccount.includes('252') &&
      removeCommas(values.amount) > exceedAmount
    ) {
      return true;
    } else {
      return false;
    }
  };

  const newPartner = ({ partnerID, partnerName }) => {
    setFieldValue('partnerID', partnerID);
    setFieldValue('partnerName', partnerName);
  };

  const bankAccount = ['252'].some((value) =>
    values.correspAccount?.includes(value),
  );

  return (
    <>
      <Modal
        closeHandler={handleClose}
        onDrag={true}
        customClass="G-cash-order-modal"
      >
        <div className="G-cash-order-content">
          <div className="G-modal-block-title">
            <h3>Դրամարկղի ելքի օրդեր</h3>
          </div>
          <div className="G-cash-order-docNum-block G-flex">
            <DocNumFiled
              docTypeID={values.docTypeID}
              docDate={values.docDate}
              value={values.docNum}
              setFieldValue={setFieldValue}
              handleChangeDocNum={handleChange}
              addEvent={addEvent}
            />
            <div className="G-form-data-picker">
              <p className="G-title-p">Ամսաթիվ</p>
              <Calendar
                openToDate={new Date(values?.docDate)}
                value={dateFormat(values.docDate)}
                changeHandler={(date) => onChangeData(date)}
                name="docDate"
              />
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <div className="G-flex-justify-between G-currency-account-flex">
              <div className="L-currency-box">
                <p className="G-title-p">Արժույթ</p>
                <span>{values.currencyCode}</span>
              </div>
              <div className="G-select-account-box">
                <SelectAccount
                  customClass="L-order-select-wrapper"
                  title="Դրամարկղի հաշիվ"
                  disabled={cashAccount?.length > 1 ? false : true}
                  accountData={cashAccount}
                  accountVal={{
                    label: values.accountCredit,
                    value: values.accountCredit,
                  }}
                  selectChangeAccount={(values) =>
                    handleChangeCreditAccount(values)
                  }
                />
                {touched.accountCredit && errors.accountCredit && (
                  <ErrorFiled error={errors.accountCredit} />
                )}
              </div>
              <div className="L-available-amount">
                <p className="G-title-p">
                  Առկա գումարը{' '}
                  <strong className="G-available-amount">
                    {Commas(initAmount)}
                  </strong>
                </p>
              </div>
            </div>

            <div className="G-flex-align-center">
              <SelectPartner
                partnerData={cashOutflowData?.partnerOuts}
                partnerVal={{
                  label: values.partnerName,
                  value: values.partnerName,
                }}
                selectChangePartner={(values) => handleChangePartner(values)}
              />
              <AddNew onClick={openPartnerAddForm} />
            </div>
            <div className="G-cash-warrant-recipient-input  G-relative">
              <InputFiled
                inputTitle="Ստացողը"
                requiredFiled={true}
                name="recipient"
                value={values.recipient}
                changeHandler={handleChange}
                maxLength={50}
              />
              {touched.recipient && errors.recipient && (
                <ErrorFiled error={errors.recipient} />
              )}
            </div>
            <div className="G-cash-warrant-input">
              <SelectAccount
                customClass="L-order-select-wrapper"
                title="Թղթակցող հաշիվ"
                accountData={correspondentAccount}
                accountVal={{
                  label: values.correspAccount,
                  value: values.correspAccount,
                }}
                selectChangeAccount={(values) =>
                  handleChangeCorrespondentAccount(values)
                }
              />

              {touched.correspAccount && errors.correspAccount && (
                <ErrorFiled error={errors.correspAccount} />
              )}
            </div>
            <RegisterCalculationInReport
              values={values}
              setFieldValue={setFieldValue}
            />

            <div className="G-amount-input">
              <NumericFormatFiled
                value={values.amount}
                name="amount"
                onBlur={handleBlur}
                changeHandler={handleChange}
                inputTitle={
                  bankAccount
                    ? 'Գումարը'
                    : 'Գումարը (առավելագույնը 300 000 դրամ) '
                }
                requiredFiled={true}
              />

              {touched.amount && errors.amount && (
                <ErrorFiled error={errors.amount} />
              )}
            </div>

            <div className="G-cash-warrant-input  G-relative">
              <InputFiled
                inputTitle="Ստացման հիմքն ու նպատակը"
                name="docBase"
                value={values.docBase}
                changeHandler={handleChange}
                maxLength={textFiledLength}
              />
            </div>
            <div className="G-cash-warrant-input  G-relative">
              <InputFiled
                inputTitle="Հավելված"
                name="appendix"
                value={values.appendix}
                changeHandler={handleChange}
                maxLength={textFiledLength}
              />
            </div>
            <div className="G-cash-warrant-input  G-relative">
              <InputFiled
                inputTitle="Այլ տեղեկություններ"
                name="otherInformation"
                value={values.otherInformation}
                changeHandler={handleChange}
                maxLength={textFiledLength}
              />
            </div>

            <GetSentDataBtn
              cancelPrintClick={handleClose}
              confirmExecuteClick={handleSubmit}
              executeClass={
                !isValid || !dirty || disabledBtnHandler()
                  ? 'G-disabled-button'
                  : ''
              }
              executeDisabled={!isValid || !dirty || disabledBtnHandler()}
            />
          </form>
        </div>
      </Modal>
      {showPartnerForm && (
        <PartnerForm
          closeModal={setShowPartnerForm}
          updateUserOutside={true}
          updatePartnerAction={updatePartnerAction}
          setPartnerInfo={newPartner}
          setPartnerTaxCode={setFieldValue}
        />
      )}
    </>
  );
};

export default CashOutflowOrderForm;
