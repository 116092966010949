import { createAction } from '../../actionCreators';
import {
  ADD_SUB_DIVISIONS,
  DELETE_SUB_DIVISIONS,
  EDIT_SUB_DIVISIONS,
  GET_SUB_DIVISIONS,
} from './constants';

export const fetchSubDivisionAction = createAction(GET_SUB_DIVISIONS);
export const updateSubDivisionAction = createAction(EDIT_SUB_DIVISIONS);
export const removeSubDivisionAction = createAction(DELETE_SUB_DIVISIONS);
export const addSubDivisionAction = createAction(ADD_SUB_DIVISIONS);
