import {
  ADD_EMPLOYEE,
  ADD_FORM_DATA,
  DELETE_EMPLOYEE,
  FETCH_EMPLOYEES,
  FILTER_CURRENT_EMPLOYEES,
  UPDATE_EMPLOYEE,
} from './constants';

const initialState = {
  employees: null,
  employeesForm: null,
  filteredItem: {
    name: null,
    id: null,
  },
};

export const employeesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_EMPLOYEES:
      return {
        ...state,
        employees: payload,
      };

    case FILTER_CURRENT_EMPLOYEES:
      return {
        ...state,
        filteredItem: payload,
      };
    case ADD_EMPLOYEE:
      return {
        ...state,
        employees: payload,
      };
    case ADD_FORM_DATA:
      return {
        ...state,
        employeesForm: payload,
      };
    case UPDATE_EMPLOYEE:
      return {
        ...state,
        employees: {
          ...state.employees,
          employees: state.employees.employees?.map((i) => {
            return i.employeeID === payload.employeeID ? payload : i;
          }),
        },
      };
    case DELETE_EMPLOYEE:
      return {
        ...state,
        employees: {
          ...state.employees,
          employees: state.employees.employees.filter(
            (item) => item.employeeID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
