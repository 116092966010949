import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import PartnerCirculationTable from './PartnerCirculationTable';
import PartnerCirculationViewAccount from './PartnerCirculationViewAccount';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  correctDate,
  dataTimeFormat,
  debounceDelay,
  inCorrectDateMess,
} from '../../Utilities/Utilities';
import { getRequest, postRequest } from '../../Api/Api';
import {
  getCirculationBulletinAction,
  getPartnerNewsletterViewAction,
  loadingAction,
} from '../../Redux/partnerCirculationBulletinSlices/useAction';
import PartnerViewAccount from './PartnerViewAccount';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import Alert from '../../Components/Alert';
import { useDebounce } from '../../Hooks/useDebounce';

const GetPartnerCirculationData = () => {
  const { currentCompanyID, reportStartDate } = useSelector(
    (state) => state.auth,
  );
  const { partnerBulletinData, isLoading } = useSelector(
    (state) => state.getPartnerCirculationBulletin,
  );
  const [dateStart, setDateStart] = useState(new Date(reportStartDate));
  const [dateEnd, setDateEnd] = useState(new Date());
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
  const [printSignRow, setPrintSignRow] = useState([]);
  const [newsletterView, setNewsletterView] = useState(false);
  const [viewAccounts, setViewAccounts] = useState(false);
  const dispatch = useDispatch();
  const dateUrl = `dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}`;
  const { data } = useFetchCurrency();
  const [partnerTypeVal, setPartnerTypeVal] = useState({
    label: '',
    value: '',
    id: 0,
  });
  const [currencyVal, setCurrencyVal] = useState({
    label: 'AMD',
    value: 'AMD',
    id: 0,
  });
  const [errorHandler, setErrorHandler] = useState('');
  const [searchValue, setSearchValues] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, debounceDelay);
  const disabledBtn = currencyVal?.value === '' || printSignRow?.length === 0;

  const getData = () => {
    dispatch(loadingAction(true));
    getRequest(
      `getPartnersList?companyID=${currentCompanyID}&partnerType=${
        partnerTypeVal.id
      }&${dateUrl}&searchString=${debouncedSearchTerm?.trim()}`,
    )
      .then((res) => {
        dispatch(getCirculationBulletinAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };
  useEffect(() => {
    getData();
  }, [
    currentCompanyID,
    dispatch,
    partnerTypeVal.id,
    dateUrl,
    debouncedSearchTerm,
  ]);

  useEffect(() => {
    const filterRow = partnerBulletinData?.filter((row) => {
      return row.printSign;
    });
    setPrintSignRow(filterRow);
  }, [partnerBulletinData]);

  const openNewsletterView = () => {
    if (printSignRow.length > 0) {
      postRequest(
        `getPartnersCalculationReport?companyID=${currentCompanyID}&currencyID=${
          currencyVal.id === 0 ? data?.amdCurrencyID : currencyVal.id
        }&withAccounts=${viewAccounts}&${dateUrl}`,
        printSignRow,
      ).then((res) => {
        dispatch(getPartnerNewsletterViewAction(res.data));
        setNewsletterView(true);
      });
    } else {
      setNewsletterView(false);
    }
  };

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      setErrorHandler(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };

  return (
    <>
      <Header
        headerBtn={false}
        pageTitle="Գործընկերների շրջանառության տեղեկագիր"
      />
      <PartnerCirculationViewAccount
        dateStart={dateStart}
        dateEnd={dateEnd}
        timeChangeHandler={timeChangeHandler}
        viewClick={openNewsletterView}
        partnerTypeVal={partnerTypeVal}
        setPartnerTypeVal={setPartnerTypeVal}
        viewAccounts={viewAccounts}
        setViewAccounts={setViewAccounts}
        currencyVal={currencyVal}
        setCurrencyVal={setCurrencyVal}
        disabledBtn={disabledBtn}
        searchValue={searchValue}
        setSearchValue={setSearchValues}
        getData={getData}
      />
      <PartnerCirculationTable
        mainData={partnerBulletinData}
        loading={isLoading}
      />

      {newsletterView && (
        <PartnerViewAccount
          modalClick={setNewsletterView}
          dateStart={dateStart}
          dateEnd={dateEnd}
          dateUrl={dateUrl}
          currencyID={
            currencyVal.id === 0 ? data?.amdCurrencyID : currencyVal.id
          }
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default GetPartnerCirculationData;
