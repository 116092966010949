import React from "react";

const DashboardTitle = (props) => {
  const { blockTitle, icon } = props;
  return (
    <div className="G-dashboard-title G-flex-align-center">
      <h3>{blockTitle}</h3>
      <img src={icon} alt="icon" />
    </div>
  );
};

export default DashboardTitle;
