import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAssignAdditionalStorage,
  deleteAssignAdditionalStorage,
  fetchAssignAdditionalStorage,
  fetchAssignAdditionalStorageForm,
  updateAssignAdditionalStorage,
} from '../services/fetchAssignAdditionalStorage';
import {
  deleteAssignAdditionalStorageAction,
  fetchAssignAdditionalStorageAction,
  fetchAssignAdditionalStorageFormDataAction,
  updateAssignAdditionalStorageAction,
} from '../../App/Redux/SalaryStor/assignAdditionalStorage/useAction';

const useAssignAdditionalStorage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);

  const dispatch = useDispatch();
  const { currentCompanyID } = useSelector((state) => state.auth);
  const assignStorage = useSelector(
    (state) => state.assignStorage?.assignStorage,
  );
  const assignStorageFormData = useSelector(
    (state) => state.assignStorage?.assignStorageForm,
  );

  const getAssignAdditionalStorage = async (empNo, sign) => {
    setIsLoading(true);
    try {
      const data = await fetchAssignAdditionalStorage(
        currentCompanyID,
        empNo,
        sign,
      );
      dispatch(fetchAssignAdditionalStorageAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAssignAdditionalStorageFormData = async (
    empNo,
    salarySetID,
    sign,
  ) => {
    setIsLoading(true);
    try {
      const data = await fetchAssignAdditionalStorageForm(
        currentCompanyID,
        empNo,
        salarySetID,
        sign,
      );

      if (data.message) {
        setErrorMess(data.message);
      } else {
        dispatch(fetchAssignAdditionalStorageFormDataAction(data));
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addAssignAddition = async (
    newAssignAddition,
    closeFormCallback,
    empNo,
    sign,
  ) => {
    try {
      const { errorCode, message } = await addAssignAdditionalStorage(
        newAssignAddition,
      );

      if (errorCode === 0) {
        getAssignAdditionalStorage(empNo, sign);
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateAssignAdditional = async (salarySetID, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await updateAssignAdditionalStorage(
        salarySetID,
      );

      if (errorCode === 0) {
        dispatch(updateAssignAdditionalStorageAction(salarySetID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAssignAdditional = async (salarySetID, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await deleteAssignAdditionalStorage(
        salarySetID,
      );

      if (errorCode === 0) {
        dispatch(deleteAssignAdditionalStorageAction(salarySetID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    getAssignAdditionalStorage,
    assignStorage,
    getAssignAdditionalStorageFormData,
    assignStorageFormData,
    addAssignAddition,
    updateAssignAdditional,
    deleteAssignAdditional,
    error,
    isLoading,
    errorMess,
    setErrorMess,
  };
};

export default useAssignAdditionalStorage;
