export const EBDList = [
  {
    name: "Խմբագրել",
    value: "edit",
  },
  {
    name: "Բանկեր",
    value: "banks",
  },
  {
    name: "Ջնջել",
    value: "delete",
  },
];

export const EDPList = [
  {
    name: "Խմբագրել",
    value: "edit",
  },
  {
    name: "Ջնջել",
    value: "delete",
  },
  {
    name: "Տպել",
    value: "print",
  },
];

export const EDPOList = [
  {
    name: "Գործառնություններ",
    value: "operations",
  },
  {
    name: "Խմբագրել",
    value: "edit",
  },
  {
    name: "Ջնջել",
    value: "delete",
  },
  {
    name: "Տպել",
    value: "print",
  },
];

export const purchaseActActionLit = [
  {
    name: 'Մուտք փաստաթուղթ',
    value: 'watch',
  },
  {
    name: 'Գործառնություններ',
    value: 'operations',
  },

  {
    name: 'Ջնջել',
    value: 'delete',
  },
  {
    name: 'Տպել',
    value: 'print',
  },
];


export const EDList = [
  {
    name: "Խմբագրել",
    value: "edit",
  },
  {
    name: "Ջնջել",
    value: "delete",
  },
];
