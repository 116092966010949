import { $host } from './index';

export const create = async (fdata) => {
  const data = await $host.post(`api/CreateAspect`, fdata);

  return data;
};

export const createBulk = async (fdata) => {
  const data = await $host.post(`api/CreateBulkAspect`, fdata);

  return data;
};

export const getAspect = async (userId) => {
  const data = await $host.get(`api/GetAllAspects?UserID=${userId}`);

  return data;
};

export const update = async (fdata) => {
  const data = await $host.put(`api/UpdateAspect`, fdata);
  return data;
};

export const deleteAspect = async (aspectId) => {
  const data = await $host.delete(`api/DeleteAspect/${aspectId}`);
  return data;
};
