import React from 'react';
import './style.css';

const CloseIcon = ({ onClick }) => {
  return (
    <div className="close-wrap" onClick={onClick}>
      <div className="close"></div>
    </div>
  );
};

export default CloseIcon;
