import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Modal from '../../../Components/Modal/Modal';
import Calendar from '../../../Components/Calendar/Calendar';
import SelectCurrency from '../../../Components/SelectBox/SelectCurrency';
import SelectAccount from '../../../Components/SelectBox/SelectAccount';
import ErrorFiled from '../../../Components/ErrorFiled/ErrorFiled';
import GetSentDataBtn from '../../../Components/GetSentDataBtn/GetSentDataBtn';
import {
  textFiledLength,
  dataTimeFormat,
  dateFormat,
  GetAccountCurrencies,
  removeCommas,
} from '../../../Utilities/Utilities';
import { useSelector } from 'react-redux';
import { getRequest } from '../../../Api/Api';
import { InitialBalancesValidation } from '../../../Validation/InitialBalancesValidation';

import ExchangeRateFiled from '../../../Components/ExchangeRateFiled/ExchangeRateFiled';
import NumericFormatFiled from '../../../Components/NumericFormat/NumericFormatFiled';
import InputFiled from '../../../Components/InputFiled/InputFiled';
import RegisterCalculationInReport from '../../../Components/RegisterCalculationInReport/RegisterCalculationInReport';

const PartnerBalanceForm = ({
  closeModal,
  onSubmit,
  initialData,
  partnerName,
  setSelectRowData,
  workForAdd = false,
}) => {
  const { partnerData } = useSelector((state) => state.getPartnerData);
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [exchangeRate, setExchangeRate] = useState(initialData.exchangeRate);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  useEffect(() => {
    setFieldValue('exchangeRate', exchangeRate);
  }, [exchangeRate]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    setFieldValue,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...initialData },
    validationSchema: InitialBalancesValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  useEffect(() => {
    if (workForAdd) {
      setSelectRowData(values);
    }
  }, [values, workForAdd]);

  useEffect(() => {
    setFieldValue(
      'amountDebitAMD',
      removeCommas(values.amountDebit) * values.exchangeRate,
    );
  }, [setFieldValue, values.amountDebit, values.exchangeRate]);

  useEffect(() => {
    setFieldValue(
      'amountCreditAMD',
      removeCommas(values.amountCredit) * values.exchangeRate,
    );
  }, [setFieldValue, values.amountCredit, values.exchangeRate]);

  useEffect(() => {
    if (values.currencyID !== 1) {
      getRequest(
        `getRate?rateDate=${dataTimeFormat(values?.balanceDate)}&iso=${
          values?.currencyCode
        }`,
      )
        .then((res) => {
          if (res.data !== null) {
            setExchangeRate(res.data);
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
    if (values.currencyID === 1) {
      setExchangeRate(1);
    }
  }, [values?.currencyCode, values?.currencyID, values?.balanceDate]);

  useEffect(() => {
    getRequest(
      `getAccountsForPartner?companyID=${currentCompanyID}&currencyID=${values?.currencyID}&partnerID=${values.partnerID}`,
    )
      .then((res) => {
        setAccountData(res?.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  }, [
    currentCompanyID,
    partnerData.accounts,
    values?.currencyID,
    values.partnerID,
  ]);
  const onChangeData = (date) => {
    setFieldValue('balanceDate', dataTimeFormat(date));
  };

  const handleChangeCurrency = (values) => {
    if (!values) {
      values = {
        value: '',
        id: '',
      };
    }

    setFieldValue('currencyID', values.id);
    setFieldValue('currencyCode', values.value);
  };

  const handleChangeCreditAccount = (values) => {
    if (!values) {
      values = {
        value: '',
        id: '',
        accountName: '',
      };
    }

    if (values.type === '1') {
      setFieldValue('amountCredit', 0);
      setFieldValue('amountCreditAMD', 0);
    } else if (values.type === '2') {
      setFieldValue('amountDebit', 0);
      setFieldValue('amountDebitAMD', 0);
    } else {
      setFieldValue('amountDebit', 0);
      setFieldValue('amountCredit', 0);
      setFieldValue('amountCreditAMD', 0);
      setFieldValue('amountDebitAMD', 0);
    }
    setDisabledBtn(values.type);
    setFieldValue('accountCode', values.value);
    setFieldValue('accountName', values.accountName);
    setFieldValue('accountID', values.id);
    setFieldValue('type', values.type);
  };

  useEffect(() => {
    if (values.accountCode) {
      GetAccountCurrencies({
        companyID: values.companyID,
        accountCode: values.accountCode,
      }).then((res) => {
        res.data.length === 1 &&
          res.data?.map((item) => {
            setFieldValue('currencyCode', item.currencyCode);
            setFieldValue('currencyID', item.currencyID);
          });
        setCurrencies(res.data);
      });
    }
  }, [values.accountCode, values.companyID]);

  return (
    <Modal closeHandler={closeModal} customClass="L-init-balance-form-modal">
      <div className="L-init-balance-form-content">
        <div className="G-modal-block-title">
          <h3>{partnerName}</h3>
        </div>
        <div className="G-flex">
          <div className="G-form-data-picker">
            <p className="G-title-p">Ամսաթիվ</p>
            <Calendar
              value={dateFormat(values.balanceDate)}
              changeHandler={(date) => onChangeData(date)}
              name="balanceDate"
              disabled={true}
            />
          </div>
        </div>

        <form onSubmit={onSubmit}>
          <ExchangeRateFiled
            value={values.exchangeRate}
            changeHandler={handleChange}
            currencyCode={values.currencyCode}
          />
          <div className="L-initial-balances-wrap">
            <SelectAccount
              customClass="L-order-select-wrapper"
              accountData={accountData}
              accountVal={{
                label: values.accountCode,
                value: values.accountCode,
              }}
              onBlur={handleBlur}
              selectChangeAccount={(values) =>
                handleChangeCreditAccount(values)
              }
            />
            {touched.accountID && errors.accountID && (
              <ErrorFiled error={errors.accountID} />
            )}
          </div>
          <div className="L-initial-balances-wrap">
            <InputFiled
              inputTitle="Հաշվի անվանումը"
              value={values.accountName}
              disabled={true}
            />
          </div>
          <RegisterCalculationInReport
            values={values}
            defValue={values.accountCode}
            setFieldValue={setFieldValue}
          />
          <>
            <SelectCurrency
              currencyData={currencies}
              currencyVal={{
                label: values.currencyCode,
                value: values.currencyCode,
              }}
              selectChangeCurrency={(values) => handleChangeCurrency(values)}
            />

            {touched.currencyCode && errors.currencyCode && (
              <ErrorFiled error={errors.currencyCode} />
            )}
          </>

          {values.type !== '2' && (
            <div className="L-initial-balances-amount-wrap G-flex">
              <div className="L-balances-amount-box">
                <NumericFormatFiled
                  value={values.amountDebit}
                  name="amountDebit"
                  onBlur={handleBlur}
                  changeHandler={handleChange}
                  inputTitle="Դեբետ"
                  requiredFiled={values.type && values.type === '1'}
                  disabled={disabledBtn === '2' || !values.accountCode}
                />

                {touched.amountDebit && errors.amountDebit && (
                  <ErrorFiled error={errors.amountDebit} />
                )}
              </div>

              <div className="L-balances-amount-box">
                <NumericFormatFiled
                  value={values.amountDebitAMD}
                  name="amountDebitAMD"
                  changeHandler={handleChange}
                  inputTitle="Դեբետ AMD"
                  disabled={true}
                />
              </div>
            </div>
          )}

          {values.type !== '1' && (
            <div className="L-initial-balances-amount-wrap G-flex">
              <div className="L-balances-amount-box">
                <NumericFormatFiled
                  value={values.amountCredit}
                  name="amountCredit"
                  onBlur={handleBlur}
                  changeHandler={handleChange}
                  inputTitle="Կրեդիտ"
                  requiredFiled={values.type && values.type === '2'}
                  disabled={disabledBtn === '1' || !values.accountCode}
                />
                {touched.amountCredit && errors.amountCredit && (
                  <ErrorFiled error={errors.amountCredit} />
                )}
              </div>
              <div className="L-balances-amount-box">
                <NumericFormatFiled
                  value={values.amountCreditAMD}
                  name="amountCreditAMD"
                  changeHandler={handleChange}
                  inputTitle="Կրեդիտ AMD"
                  disabled={true}
                />
              </div>
            </div>
          )}

          <div className="G-form-input-wrapper">
            <InputFiled
              inputTitle="Նշումներ"
              name="comment"
              value={values.comment}
              changeHandler={handleChange}
              onBlur={handleBlur}
              maxLength={textFiledLength}
            />
          </div>
          <GetSentDataBtn
            cancelPrintClick={closeModal}
            confirmExecuteClick={handleSubmit}
            executeClass={!isValid || !dirty ? 'G-disabled-button' : ''}
            executeDisabled={!isValid || !dirty}
          />
        </form>
      </div>
    </Modal>
  );
};

export default PartnerBalanceForm;
