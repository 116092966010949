import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Button from '../../Components/Button/Button';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import Modal from '../../Components/Modal/Modal';
import { editPurchaseDataAction } from '../../Redux/purchaseActSlices/useAction';
import {
  cancelText,
  dataTimeFormat,
  registerDocument,
  typOfString,
} from '../../Utilities/Utilities';
import EventAddForm from './EventAddForm';
import IncomeTaxForm from './IncomeTaxForm';
import ProductTable from './ProductTable';
import PurchaseSelectBox from './PurchaseSelectBox';
import ReportingPersonDoc from './ReportingPersonDoc';
import { TAXATION_ID } from './Constant';

const PurchaseView = ({
  closeModal,
  mainData,
  saveDocumentHandler,
  disabledSaveDocBtn,
  disabledPartnerName,
  getPurchaseID,
  isDisabledRegBtn,
  setIsCloseForm,
}) => {
  const { purchase, hasIncomeTax } = mainData || {};
  const { docDate, partnerName, docNum, docBase, accountCredit } =
    purchase || {};
  const [openAddModal, setOpenAddModal] = useState(false);
  const [showIncomeTax, setShowIncomeTax] = useState(false);
  const [getTaxationID, setGetTaxationID] = useState(0);
  const isDisabledAddBtn = useMemo(
    () => !(docBase?.length && accountCredit?.length),
    [docBase, accountCredit],
  );
  const purchaseID = useMemo(
    () => getPurchaseID || purchase?.documentID,
    [getPurchaseID, purchase],
  );
  const { currentCompanyID } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const openAddProductForm = useCallback(() => {
    setOpenAddModal(true);
  }, [setOpenAddModal]);

  const changeHandler = useCallback(
    (e) => {
      let { value, name } = e.currentTarget;
      dispatch(editPurchaseDataAction({ [name]: value }));
    },
    [dispatch],
  );

  const fetchData = useCallback(async () => {
    try {
      const docDateFormat = dataTimeFormat(docDate);

      const taxationIDResponse = await getRequest(
        `getCompanyTaxationID?CompanyID=${currentCompanyID}&taxDate=${docDateFormat}`,
      );

      setGetTaxationID(taxationIDResponse.data);
      setIsCloseForm(taxationIDResponse.data);
    } catch (error) {
      console.error('Error fetching taxation ID:', error);
    }
  }, [currentCompanyID, docDate, setGetTaxationID]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const changeDocDate = useCallback(
    async (e) => {
      try {
        const docDateFormat = dataTimeFormat(e);

        if (docDate !== docDateFormat) {
          const docNumResponse = await getRequest(
            `getDocNum?companyID=${currentCompanyID}&docTypeID=${6}&docDate=${docDateFormat}`,
          );
          const docNum = typOfString(docNumResponse.data);

          dispatch(editPurchaseDataAction({ docNum }));
        }

        dispatch(editPurchaseDataAction({ docDate: docDateFormat }));
      } catch (error) {
        console.error('Error:', error);
      }
    },
    [currentCompanyID, docDate, dispatch],
  );

  const changeCreditAccount = useCallback(
    (values, name) => {
      if (!values) {
        values = {
          value: '',
        };
      }

      if (name === 'accountCredit') {
        dispatch(editPurchaseDataAction({ accountCredit: values.value }));
      }
    },
    [dispatch],
  );

  const shouldShowIncomeTaxButton =
    getTaxationID && TAXATION_ID?.includes(getTaxationID) && isDisabledRegBtn;

  return (
    <Modal closeHandler={closeModal} customClass="L-purchase-view-block">
      <div className="L-purchase-view-flex">
        <ReportingPersonDoc
          partnerName={partnerName}
          docNum={docNum}
          docDate={docDate}
          changeHandler={changeHandler}
          changeCalendar={changeDocDate}
          disabledPartnerName={disabledPartnerName}
        />
        <PurchaseSelectBox
          data={mainData}
          selectChange={changeCreditAccount}
          onChangeFromWhom={changeHandler}
          docBase={docBase}
        />
        <div className="G-flex-justify-between">
          {!isDisabledRegBtn && (
            <div className="G-add-btn">
              <Button
                onClick={openAddProductForm}
                disabled={isDisabledAddBtn}
                customClass={isDisabledAddBtn ? 'G-disabled-button' : ''}
              />
            </div>
          )}

          {shouldShowIncomeTaxButton && (
            <div className="G-income-tax-btn">
              <Button
                onClick={() => setShowIncomeTax(true)}
                text="եկամտային հարկ"
                disabled={!hasIncomeTax}
                customClass={!hasIncomeTax ? 'G-disabled-button' : ''}
              />
            </div>
          )}
        </div>
        <ProductTable
          productData={mainData?.goods}
          showActionList={isDisabledRegBtn}
        />

        <GetSentDataBtn
          cancelPrintClickText={cancelText}
          confirmExecuteText={registerDocument}
          confirmExecuteCustomClass="G-register-document-btn"
          cancelPrintClick={closeModal}
          confirmExecuteClick={saveDocumentHandler}
          executeDisabled={disabledSaveDocBtn || isDisabledRegBtn}
          executeClass={
            disabledSaveDocBtn || isDisabledRegBtn ? 'G-disabled-button' : ''
          }
        />
      </div>

      {openAddModal && <EventAddForm modalClick={setOpenAddModal} />}

      {showIncomeTax && (
        <IncomeTaxForm
          modalClick={setShowIncomeTax}
          purchaseID={purchaseID}
          closePurchaseForm={closeModal}
        />
      )}
    </Modal>
  );
};

export default PurchaseView;
