import React, { useEffect, useState } from 'react';
import Modal from '../../../Components/Modal/Modal';
import OperationsHeader from './OperationsHeader';
import OperationsTable from './OperationsTable';
import { getRequest } from '../../../Api/Api';

const GetOperationsData = ({ handleClose, excelInvoiceID }) => {
  const [operationsData, setOperationsData] = useState(null);
  const openOperationsForm = () => {
    getRequest(`getExcelInvoiceEntries?excelInvoiceID=${excelInvoiceID}`)
      .then((res) => {
        setOperationsData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  };

  useEffect(() => {
    if (excelInvoiceID) {
      openOperationsForm();
    }
  }, [excelInvoiceID]);

  return (
    <Modal
      closeHandler={handleClose}
      onDrag={true}
      customClass="L-operations-modal"
    >
      <div className="G-modal-block-title">
        <h3>Գործառնություններ</h3>
      </div>
      <OperationsHeader mainData={operationsData} />
      <OperationsTable
        mainData={operationsData}
        setOperationsData={setOperationsData}
      />
    </Modal>
  );
};

export default GetOperationsData;
