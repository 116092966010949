import {
  ADD_PURCHASE_PRODUCT,
  CHECKED_ALL_TAX_SING,
  CHECKED_ROW_TAX_SING,
  DELETE_PURCHASE_PRODUCT,
  EDIT_PURCHASE_DATA,
  EDIT_PURCHASE_PRODUCT,
  GET_PURCHASE_ACT_DATA,
  GET_PURCHASE_ADD_FORM_DATA,
  LOADING,
  DELETE_PURCHASE_ACT,
  EDIT_PURCHASE_TABLE_DATA,
  UPDATE_PARTNER_PURCHASE_FORM,
  UPDATE_PRODUCT_DATA,
  UPDATE_UNIT_OF_MEASURE,
  UPDATE_INCOME_TAX,
} from './constants';
import { createAction } from '../actionCreators';

export const getPurchaseDataActAction = createAction(GET_PURCHASE_ACT_DATA);
export const loadingAction = createAction(LOADING, false);
export const deletePurchaseActAction = createAction(DELETE_PURCHASE_ACT);
export const getPurchaseAddFormAction = createAction(
  GET_PURCHASE_ADD_FORM_DATA,
);

export const addProductAction = createAction(ADD_PURCHASE_PRODUCT);
export const editProductAction = createAction(EDIT_PURCHASE_PRODUCT);
export const deleteProductAction = createAction(DELETE_PURCHASE_PRODUCT);
export const editPurchaseDataAction = createAction(EDIT_PURCHASE_DATA);

export const checkedAllIncomeTaxSignAction = createAction(CHECKED_ALL_TAX_SING);
export const checkedRowIncomeTaxSignAction = createAction(CHECKED_ROW_TAX_SING);
export const updatePurchaseTableAction = createAction(EDIT_PURCHASE_TABLE_DATA);
export const updatePartnerAction = createAction(UPDATE_PARTNER_PURCHASE_FORM);

export const updateProductAction = createAction(UPDATE_PRODUCT_DATA);


export const updateUnitOfMeasureAction = createAction(UPDATE_UNIT_OF_MEASURE);
export const updateIncomeTaxAction = createAction(UPDATE_INCOME_TAX);