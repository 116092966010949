import React from 'react';
import Table from '../../../App/Components/Table/Table';
import { Commas, dateFormat } from '../../../App/Utilities/Utilities';
import Loading from '../../../App/Components/Loading/Loading';
const CalculationDataTable = ({ calculationTableData, isLoading }) => {
  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 400, overflow: 'hidden' },
    },
    {
      title: 'Ամսաթիվը',
      cell: (row) => (row.calcDate ? dateFormat(row.calcDate) : null),
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Ազգանուն, Անուն, Հայրանուն',
      cell: (row) => row.empName,
      customStyle: { maxWidth: 400, overflow: 'hidden' },
    },
    {
      title: 'Հ/Պ կոդ',
      cell: (row) => row.stNo,
      customStyle: { maxWidth: 90, overflow: 'hidden' },
    },
    {
      title: 'Հ/Պ անվանումը',
      cell: (row) => row.stName,
      customStyle: { maxWidth: 200, overflow: 'hidden' },
    },
    {
      title: 'Օրը',
      cell: (row) => row.days,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ժամ',
      cell: (row) => row?.hours,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Հավելում',
      cell: (row) => Commas(row.addAmount),
      customStyle: { maxWidth: 160 },
    },
    {
      title: 'Պահում',
      cell: (row) => Commas(row.dedAmount),
      customStyle: { maxWidth: 160 },
    },
  ];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      {calculationTableData !== null && calculationTableData?.length ? (
        <Table
          customClass="L-calculation-data-table G-table-has-scroll"
          data={calculationTableData}
          columnConfig={columnConfig}
        />
      ) : null}
    </div>
  );
};

export default CalculationDataTable;
