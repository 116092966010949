import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import {
  dataTimeFormat,
  initialDate,
  printHandler,
} from '../../Utilities/Utilities';
import BalanceBookAction from './BalanceBookAction';
import BalanceBookTable from './BalanceBookTable';
import './index.scss';
import ConvertDataExcel from './ConvertDataExcel';

const GetBalanceBookData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(initialDate());
  const dateFormat = dataTimeFormat(date);
  const [bookBalancesData, setBookBalancesData] = useState([]);
  const { data } = useFetchCurrency();
  const dispatch = useDispatch();
  const [currencyVal, setCurrencyVal] = useState({
    label: 'AMD',
    value: 'AMD',
    id: 0,
  });

  const generalUrl = `companyID=${currentCompanyID}&currencyID=${
    currencyVal.id === 0 ? data?.amdCurrencyID : currencyVal.id
  }&dateEnd=${dateFormat}`;

  const getData = () => {
    setIsLoading(true);
    getRequest(`getBalancesBook?${generalUrl}`)
      .then((res) => {
        setBookBalancesData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [generalUrl]);

  const handlePrint = () => {
    printHandler(`printBalancesBook?${generalUrl}`, dispatch);
  };
  const dataForExcel = ConvertDataExcel(bookBalancesData?.balances);
  return (
    <>
      <Header headerBtn={false} pageTitle="Մնացորդների մատյան" />
      <SearchBackground>
        <BalanceBookAction
          printHandler={handlePrint}
          mainData={bookBalancesData}
          date={date}
          setDate={setDate}
          currencyVal={currencyVal}
          setCurrencyVal={setCurrencyVal}
          dataForExcel={dataForExcel}
        />
      </SearchBackground>
      <BalanceBookTable
        mainData={bookBalancesData?.balances}
        loading={isLoading}
        getData={getData}
      />
    </>
  );
};

export default GetBalanceBookData;
