import React from 'react';
import TablesForm from './TablesForm';
import useTables from '../../hooks/useTables';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';

const EventEditForm = ({ calendarID, handleClose }) => {
  const { updateTableItem, errorMess, setErrorMess } = useTables();
  const isMounted = useIsMounted();

  const onUpdateHandler = async (values) => {
    const val = {
      ...values,
      sign56: Number(values.sign56),
      hours: Number(values.hours),
      graphNo: Number(values.graphNo),
    };
    try {
      await updateTableItem(val, handleClose);
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <TablesForm
        calendarID={calendarID}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
      />

      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventEditForm;
