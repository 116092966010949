import React, { useState } from 'react';
import { postRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import Button from '../../Components/Button/Button';
import Calendar from '../../Components/Calendar/Calendar';
import SelectAccount from '../../Components/SelectBox/SelectAccount';
import { dataTimeFormat, dateFormat } from '../../Utilities/Utilities';
import AccountsClosedReporting from './AccountsClosedReporting/AccountsClosedReporting';

const CloseAccountBalance = ({
  setDateFormulation,
  mainData,
  currentCompanyID,
  dateFormulation,
  getData,
}) => {
  const { eighthClass, accounts, closureAccounts, existClosureDates } =
    mainData || {};
  const [errorHandler, setErrorHandler] = useState('');
  const [openClosureDate, setOpenClosureDate] = useState(false);
  const [accountVal, setAccountVal] = useState({
    label: '',
    value: '',
    id: '',
  });
  const [disableCloseBtn, setDisableCloseBtn] = useState(true);
  const handleChangeAccount = (value) => {
    if (!value) {
      value = {
        value: '',
      };
      setDisableCloseBtn(true);
    }
    if (value) {
      setDisableCloseBtn(false);
    }

    setAccountVal(value);
  };

  const closingHandler = () => {
    setOpenClosureDate(true);
  };

  const closeAccountHandler = () => {
    if (eighthClass) {
      setErrorHandler(
        'Կան ութերորդ դասի չփակված հաշիվներ, փակեք համապատասխան հաշիվներով',
      );
      return;
    }
    return postRequest('saveAccountClosure', {
      companyID: currentCompanyID,
      closureAccountCode: accountVal.value,
      closureDate: dataTimeFormat(dateFormulation),
      closureAccounts: [...closureAccounts],
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          getData();
          setAccountVal({
            value: '',
          });
          setDisableCloseBtn(true);
          setErrorHandler(
            `${dateFormat(dateFormulation)} դրությամբ կատարվեց փակում`,
          );
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <div className="L-close-account-action-block G-flex-justify-between">
        <div className="G-form-data-picker">
          <p className="G-title-p">Փակման ամսաթիվը</p>
          <Calendar
            value={dateFormat(dateFormulation)}
            changeHandler={(date) => setDateFormulation(date)}
            name="closureDate"
          />
        </div>

        <div className="G-view-btn">
          <Button
            text="Փակման դիտում/ջնջում"
            onClick={closingHandler}
            disabled={!existClosureDates}
            customClass={!existClosureDates ? 'G-disabled-button' : ''}
          />
        </div>

        <SelectAccount
          title="Շահույթի/վնասի հաշիվը"
          accountData={accounts}
          accountVal={accountVal}
          selectChangeAccount={(value) => handleChangeAccount(value)}
        />

        <div className="G-view-btn">
          <Button
            text="Փակել"
            onClick={closeAccountHandler}
            disabled={!mainData?.closureAccounts?.length || disableCloseBtn}
            customClass={
              !mainData?.closureAccounts?.length || disableCloseBtn
                ? 'G-disabled-button'
                : ''
            }
          />
        </div>

        <Alert description={errorHandler} onClose={setErrorHandler} />
      </div>
      {openClosureDate && (
        <AccountsClosedReporting
          currentCompanyID={currentCompanyID}
          closeModal={setOpenClosureDate}
          getData={getData}
        />
      )}
    </>
  );
};

export default CloseAccountBalance;
