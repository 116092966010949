import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import { dataTimeFormat } from '../../Utilities/Utilities';
import CloseAccountBalance from './CloseAccountBalance';
import ClosingAccountBalanceTable from './ClosingAccountBalanceTable';
import './index.scss';
import Alert from '../../Components/Alert';

const GetClosingAccountBalances = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [closureBalance, setClosureBalanceData] = useState([]);
  const [dateFormulation, setDateFormulation] = useState(new Date());
  const [errorHandler, setErrorHandler] = useState('');

  const getData = () => {
    setIsLoading(true);
    getRequest(
      `getAccountClosureBalance?companyID=${currentCompanyID}&closureDate=${dataTimeFormat(
        dateFormulation,
      )}`,
    )
      .then((res) => {
        if (res.data) {
          setClosureBalanceData(res.data);
        } else {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [currentCompanyID, dateFormulation]);

  return (
    <>
      <Header
        headerBtn={false}
        pageTitle="Եկամուտ/ծախս հաշիվների մնացորդների կուտակում եռանիշ հաշիվներում և փակում"
      />
      <SearchBackground>
        <CloseAccountBalance
          mainData={closureBalance}
          currentCompanyID={currentCompanyID}
          setDateFormulation={setDateFormulation}
          dateFormulation={dateFormulation}
          getData={getData}
        />
      </SearchBackground>

      <ClosingAccountBalanceTable
        mainData={closureBalance?.closureAccounts}
        loading={isLoading}
      />

      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default GetClosingAccountBalances;
