import React from 'react';
import { Commas, dateFormat } from '../../../App/Utilities/Utilities';
import Table from '../../../App/Components/Table/Table';
import Loading from '../../../App/Components/Loading/Loading';

const EntryOperationExecutionTable = ({
  operationExecutionData,
  isLoading,
}) => {
  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Դեբետ',
      cell: (row) => row.debitAcc,
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => row.creditAcc,
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Գումար',
      cell: (row) => Commas(row.totalPrice),
      customStyle: { maxWidth: 200, overflow: 'hidden' },
    },
  ];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {operationExecutionData !== null &&
      operationExecutionData &&
      operationExecutionData?.length ? (
        <Table
          customClass="L-entry-operation-execution-table"
          data={operationExecutionData}
          columnConfig={columnConfig}
        />
      ) : null}
    </>
  );
};

export default EntryOperationExecutionTable;
