import { BrowserRouter } from "react-router-dom";
import Navigation from "./App/ReactNavigation/Navigation";
const App = () => {
  return (
    <BrowserRouter>
      <Navigation />
    </BrowserRouter>
  );
};

export default App;
