import React, { useState } from "react";
import { postRequest } from '../../Api/Api';
import Loading from '../../Components/Loading/Loading';
import { ReactComponent as Done } from '../../Assets/icons/done.svg';
import Table from '../../Components/Table/Table';
import Tooltip from '../../Components/Tooltip/Tooltip';
import { Commas } from '../../Utilities/Utilities';
import AccountRevaluation from './AccountRevaluation';
import PartnerAccountEntries from './PartnerAccountEntries';

const PartnerBalanceBookTable = ({
  mainData,
  loading,
  viewAccounts,
  getData,
}) => {
  const [accountRevaluation, setAccountRevaluation] = useState(false);
  const [accountEntries, setAccountEntries] = useState(false);
  const accountReevaluationHandler = (row) => {
    const { currencyCode, accountCode } = row || {};

    if (currencyCode !== 'AMD' && accountCode?.includes('25')) {
      return (
        <span
          onClick={() => getAccountRevaluation(row)}
          className="G-account-reevaluation-icon"
        >
          <Done />
        </span>
      );
    }
    if (currencyCode !== 'AMD' && viewAccounts) {
      return (
        <span
          onClick={() => getAccountRevaluation(row)}
          className="G-account-reevaluation-icon"
        >
          <Done />
        </span>
      );
    }

    if (currencyCode !== 'AMD' && !accountCode?.includes('25')) {
      return (
        <p className="partnerAccText">
          Նշում արված չէ Դիտել հաշիվներով դաշտում
        </p>
      );
    }
    if (currencyCode === 'AMD') {
      return null;
    }
  };

  const accountHandler = (row) => {
    const { accountCode } = row || {};
    if (accountCode) {
      return (
        <span
          onClick={() => setAccountEntries(row)}
          className="G-account-is-active"
        >
          {accountCode}
        </span>
      );
    }
    return null;
  };

  const columnConfig = [
    {
      title: 'Գործընկեր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 660, overflow: 'hidden' },
    },
    {
      title: 'Հաշիվը',
      cell: (row) => accountHandler(row),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Արժույթ',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 60 },
    },
    {
      title: <Tooltip content="Դեբետային մնացորդ">Դ/մնացորդ</Tooltip>,
      cell: (row) => Commas(row.balanceAmountDebit),
      customStyle: { maxWidth: 160 },
    },

    {
      title: <Tooltip content="Կրեդիտային մնացորդ">Կ/մնացորդ</Tooltip>,
      cell: (row) => Commas(row.balanceAmountCredit),
      customStyle: { maxWidth: 160 },
    },

    {
      title: 'Դեբետ AMD',
      cell: (row) => Commas(row.balanceAmountDebitAMD),
      customStyle: { maxWidth: 160 },
    },

    {
      title: 'Կրեդիտ AMD',
      cell: (row) => Commas(row.balanceAmountCreditAMD),
      customStyle: { maxWidth: 160 },
    },

    {
      title: <Tooltip content="Հաշվի վերագնահատում">Հ/վերագնահատում</Tooltip>,
      cell: (row) => accountReevaluationHandler(row),
      customStyle: { maxWidth: 240 },
    },
  ];

  const getAccountRevaluation = (row) => {
    postRequest('getPartnerAccountRevaluation', row)
      .then((res) => {
        setAccountRevaluation(res.data);
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-partner-balance-book-table G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {accountRevaluation && (
        <AccountRevaluation
          respData={accountRevaluation}
          closeModal={setAccountRevaluation}
          getData={getData}
        />
      )}

      {accountEntries && (
        <PartnerAccountEntries
          rowData={accountEntries}
          closeModal={setAccountEntries}
        />
      )}
    </>
  );
};

export default PartnerBalanceBookTable;
