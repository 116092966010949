import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Modal from '../../../../App/Components/Modal/Modal';
import NumericFormatFiled from '../../../../App/Components/NumericFormat/NumericFormatFiled';
import useAssignAdditionalStorage from '../../../hooks/useAssignAdditionalStorage';
import { AssignAdditionalFields } from './FieldsConfig';
import Input from '../../../Input/Input';
import ErrorFiled from '../../../../App/Components/ErrorFiled/ErrorFiled';
import { SelectControl } from '../../../../App/Components/SelectControl/SelectControl';
import DateComponent from '../../../components/dateComponent/DateComponent';
import GetSentDataBtn from '../../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import { AssignAdditionalValidation } from '../../../../App/Validation/AssignAdditionalValidation';
import useIsMounted from '../../../hooks/useIsMounted';
import {
  dataTimeFormat,
  dateFormat,
} from '../../../../App/Utilities/Utilities';

const AssignAdditionalForm = ({
  handleClose,
  onSubmit,
  salarySetID = 0,
  empNo,
}) => {
  const { getAssignAdditionalStorageFormData, assignStorageFormData } =
    useAssignAdditionalStorage();
  const isMounted = useIsMounted();
  useEffect(() => {
    getAssignAdditionalStorageFormData(empNo, salarySetID);
  }, [empNo, salarySetID]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...assignStorageFormData?.salarySet,
    },
    validationSchema: AssignAdditionalValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
      if (isMounted) {
        resetForm();
      }
    },
  });
  let dropDownOptions = [];

  const updateFieldValueAndDropdown = (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    const fieldValue = selectedOption.label;
    const dropdownItemId = selectedOption.id;
    const graphName = selectedOption.graphName;
    const graphNo = selectedOption.graphNo;
    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
    setFieldValue('graphName', graphName);
    setFieldValue('graphNo', graphNo);
  };
  const onChangeDate = (date, name) => {
    if (!date) {
      setFieldValue(name, null);
      return;
    }

    setFieldValue(name, dataTimeFormat(date));
  };

  return (
    <Modal
      closeHandler={handleClose}
      customClass="assign-additional-form-block"
    >
      <div className="assign-additional-form-content">
        {AssignAdditionalFields?.map((field) => {
          const {
            fieldType,
            fieldName,
            label,
            name,
            required,
            Children,
            optionName,
            dropdownTitle,
            dateName,
            type,
            dropdownId,
            flexFields,
            disabled,
            minDate,
          } = field;

          const fieldError = touched[name] && errors[name];

          if (typeof Children === 'function') {
            if (assignStorageFormData) {
              dropDownOptions = Children(assignStorageFormData[optionName]);
            }
          }

          switch (fieldType) {
            case 'input':
              return (
                <div key={name} className="input-container">
                  <Input
                    type={type}
                    inputTitle={label}
                    name={name}
                    requiredFiled={required}
                    onChange={handleChange}
                    value={values[name]}
                    onBlur={handleBlur}
                    disabled={disabled}
                  />
                  {fieldError && <ErrorFiled error={fieldError} />}
                </div>
              );

            case 'dropdown':
              return (
                <div key={fieldName} className="select-container">
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={required}
                    value={{
                      label: values[fieldName] || '',
                      value: values[fieldName] || '',
                    }}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      updateFieldValueAndDropdown(
                        fieldName,
                        selectedOption,
                        dropdownId,
                      )
                    }
                    onBlur={handleBlur}
                  />
                  {required && !values[fieldName] && fieldError && (
                    <ErrorFiled error={fieldError} />
                  )}
                </div>
              );
            default:
              return (
                <div className="flex-field-container">
                  {flexFields.map((field, index) => (
                    <div key={index} className="flex-field-item">
                      {field.fieldType === 'date' && (
                        <DateComponent
                          value={dateFormat(values[field.dateName] || '')}
                          onChange={(date) =>
                            onChangeDate(date, field.dateName)
                          }
                          name={field.dateName}
                          title={field.dateTitle}
                          requiredFiled={field.required}
                          openToDate={
                            new Date(values[field.dateName] || new Date())
                          }
                        />
                      )}
                      {field.fieldType === 'input' && (
                        <Input
                          type={field.type}
                          inputTitle={field.label}
                          name={field.name}
                          requiredFiled={field.required}
                          onChange={handleChange}
                          value={values[field.name]}
                          onBlur={handleBlur}
                          disabled={field.disabled(values)}
                        />
                      )}
                      {field.fieldType === 'amount' && (
                        <NumericFormatFiled
                          inputTitle={field.label}
                          name={field.name}
                          requiredFiled={field.required}
                          changeHandler={handleChange}
                          value={values[field.name]}
                          disabled={field.disabled(values)}
                        />
                      )}
                    </div>
                  ))}
                </div>
              );
          }
        })}
        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
          executeDisabled={!dirty || !isValid}
        />
      </div>
    </Modal>
  );
};

export default AssignAdditionalForm;
