export const materialGroupField = [
  {
    fieldType: 'input',
    type: 'number',
    name: 'groupNo',
    label: 'Կոդը',
    value: '',
    required: true,
    maxLength: 3,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'groupName',
    label: 'Անվանումը',
    value: '',
    required: true,
    maxLength: 50,
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'Հաշիվ',
    fieldName: 'accCode',
    dropdownId: 'accCode',
    optionName: 'accounts',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.accountCode + ' ' + i.accountName,
        id: i.accountCode,
        value: i.accountName,
      }));
    },
  },
];
