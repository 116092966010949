import { getRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';

export const fetchCalculationData = async (companyID) => {
  if (!companyID) {
    return;
  }
  try {
    const response = await getRequest(
      `getSalaryCalcData?companyID=${companyID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchExecuteCalculation = async (companyID, params) => {
  const queryRoot = urlEncodeQueryParams(params);
  if (!companyID) {
    return;
  }

  try {
    const response = await getRequest(
      `getSalaryCalcDataEntries?companyID=${companyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
