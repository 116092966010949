import React from 'react';
import { postRequest } from '../../Api/Api';
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';
import Tooltip from '../../Components/Tooltip/Tooltip';
import { dateFormat, register, windowReload } from '../../Utilities/Utilities';
import { SelectControl } from '../../Components/SelectControl/SelectControl';
import { useDispatch } from 'react-redux';
import { updateInvoicingAccountAction } from '../../Redux/eInvoicingAccountsSlices/useAction';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import { useState } from 'react';
import { default as PartnerForm } from '../../Pages/Partners/EventAddForm';
import PartnerBalances from '../PartnerBalances/PartnerBalances';

const EInvoicingAccountsTable = ({
  mainData,
  loading,
  accountTitle,
  invoicingData,
  registerBtnDisabled,
  setRegisterBtnDisabled,
  setErrorHandler,
  fetchData,
}) => {
  const { excelList, accounts } = mainData || {};
  const dispatch = useDispatch();

  const [showPartnerForm, setShowPartnerForm] = useState(false);
  const [showPartnerBalance, setShowPartnerBalance] = useState(null);
  const valueObj = {
    companyID: showPartnerForm.companyID,
    partnerName: showPartnerForm.partnerName,
    partnerForm: showPartnerForm.partnerForm,
    partnerType: showPartnerForm.partnerType,
    taxeServiceNumber: showPartnerForm.taxeServiceNumber,
    taxCode: showPartnerForm.partnerTaxCode,
    basis: showPartnerForm.basis,
    description: '',
    countryType: 0,
    idCard: showPartnerForm.partnerIdCard,
    passport: showPartnerForm.partnerPassport,
  };

  const accountOptions = accounts?.map(
    ({ accountCode, accountName, accountID }) => {
      return {
        label: accountCode + ' ' + accountName,
        value: accountCode,
        id: accountID,
      };
    },
  );
  const partnerBalancesHandle = (currentRow) => {
    const { partnerID, partnerName } = currentRow;

    return (
      <p
        onClick={partnerID > 0 ? () => setShowPartnerBalance(partnerID) : null}
        className={partnerID > 0 ? 'G-account-is-active' : ''}
        style={{ cursor: partnerID > 0 ? 'pointer' : 'default' }}
      >
        {partnerName}
      </p>
    );
  };

  const columnConfig = [
    {
      title: 'Անվանումը',
      cell: (row) => partnerBalancesHandle(row),
      customStyle: { maxWidth: 700, overflow: 'hidden' },
    },
    {
      title: 'ՀՎՀՀ',
      cell: (row) => row.partnerTaxCode,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Այլ փաստաթուղթ',
      cell: (row) => row.partnerOtherDocNumber,
      customStyle: { maxWidth: 130 },
    },
    {
      title: <Tooltip content="Սերիա և համարը">Ս/Հ</Tooltip>,
      cell: (row) => row.invoiceSerialNumber,
      customStyle: { maxWidth: 110 },
    },
    {
      title: <Tooltip content="Մատակարարման ա/թ">Ա/թ</Tooltip>,
      cell: (row) => dateFormat(row.supplierDate),
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Արժեքը',
      cell: (row) => row.amount,
      customStyle: { maxWidth: 70 },
    },
    {
      title: <Tooltip content="ԱԱՀ-ի գումարը">ԱԱՀ</Tooltip>,
      cell: (row) => row.valueAddedTax,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Նշումներ',
      cell: (row) => row.note,
      customStyle: { maxWidth: 400, overflow: 'hidden' },
    },

    {
      title: accountTitle,
      cell: (row) => (
        <div
          className={`L-invoicing-account-select ${
            row.note?.length || row.existPartner ? '' : 'is-disabled-account'
          }`}
        >
          <SelectControl
            placeholder="Հաշիվ"
            options={accountOptions}
            value={{
              value: row.account,
              label: row.account,
            }}
            onChange={(values) =>
              handleAccountChange(values, row.invoiceSerialNumber)
            }
            disabled={row.existPartner || row.note?.length}
          />
        </div>
      ),

      customStyle: { maxWidth: 300 },
    },
    {
      title: '',
      cell: (row) => (
        <div
          title="Ավելացնել գործընկեր"
          style={{
            fontSize: '22px',
            margin: '0 auto',
            cursor: 'pointer',
            display: !row.existPartner && 'none',
          }}
          onClick={() => {
            setShowPartnerForm(row);
          }}
        >
          +
        </div>
      ),
      customStyle: { maxWidth: 80 },
    },
  ];

  const handleAccountChange = (value, invoiceSerialNumber) => {
    if (!value) {
      value = {
        value: '',
      };
    }
    if (value !== '') {
      setRegisterBtnDisabled(false);
      dispatch(
        updateInvoicingAccountAction({
          account: value.value,
          invoiceSerialNumber,
        }),
      );
    }
  };

  const registerHandler = () => {
    postRequest('saveExcel', excelList)
      .then((res) => {
        if (res.data.errorCode === 0) {
          setRegisterBtnDisabled(true);
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="L-invoicing-file-title">
        <h3>{invoicingData?.titleAccount && invoicingData?.titleAccount}</h3>
      </div>

      {mainData !== null && mainData && mainData?.excelList?.length ? (
        <Table
          customClass="L-invoicing-table"
          data={mainData?.excelList}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {excelList && (
        <GetSentDataBtn
          confirmExecuteText={register}
          cancelPrintClick={windowReload}
          confirmExecuteClick={registerHandler}
          executeClass={registerBtnDisabled ? 'G-disabled-button' : ''}
          executeDisabled={registerBtnDisabled}
        />
      )}
      {showPartnerForm && (
        <PartnerForm
          closeModal={setShowPartnerForm}
          updateUserOutside={false}
          getData={fetchData}
          partnerInfo={valueObj}
        />
      )}
      {showPartnerBalance && (
        <PartnerBalances
          partnerID={showPartnerBalance}
          handleClose={() => setShowPartnerBalance(false)}
        />
      )}
    </>
  );
};

export default EInvoicingAccountsTable;
