import React, { useEffect, useState } from 'react';
import Modal from '../../../Components/Modal/Modal';
import WarehouseDetailsHeader from './WarehouseDetailsHeader';
import WarehouseDetailsTable from './WarehouseDetailsTable';
import { getRequest, postRequest } from '../../../Api/Api';
import Loading from '../../../Components/Loading/Loading';
import GetSentDataBtn from '../../../Components/GetSentDataBtn/GetSentDataBtn';

const WarehouseForm = ({ excelInvoiceID, handleClose }) => {
  const [errorHandler, setErrorHandler] = useState('');
  const [warehouseData, setWarehouseData] = useState({
    details: null,
    tableData: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (excelInvoiceID) fetchWarehouseData();
  }, [excelInvoiceID]);

  // Fetch warehouse data from the API
  const fetchWarehouseData = async () => {
    setIsLoading(true);
    setErrorHandler(''); // Clear any existing errors
    try {
      const { data } = await getRequest(
        `getExcelInvoiceGoodsForMatInput?excelInvoiceID=${excelInvoiceID}`,
      );
      setWarehouseData({
        details: data.details || {},
        tableData: data.tableData || [],
      });
    } catch (err) {
      console.error('Error fetching warehouse data:', err);
      setErrorHandler('Failed to fetch warehouse data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const { data } = await postRequest(
        'saveExcelInvoiceGoodsForMatInput',
        warehouseData?.tableData,
      );
      const { errorCode, message } = data || {};
      if (errorCode === 0) {
        handleClose();
      } else {
        setErrorHandler(message || 'Failed to save data.');
      }
    } catch (err) {
      console.error('Error saving warehouse data:', err);
      setErrorHandler('An unexpected error occurred. Please try again.');
    }
  };

  const updateWarehouseTableData = (updatedData) => {
    setWarehouseData((prev) => ({ ...prev, tableData: updatedData }));
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Modal
      closeHandler={handleClose}
      onDrag
      customClass="L-warehouse-modal"
      title="Ընտրել ապրանքներ ՊՄՕ ձևավորելու համար"
    >
      {errorHandler && <div className="error-message">{errorHandler}</div>}
      <WarehouseDetailsHeader warehouseDetailsData={warehouseData.details} />
      <WarehouseDetailsTable
        warehouseTableData={warehouseData.tableData}
        setWarehouseTableData={updateWarehouseTableData}
      />
      <GetSentDataBtn
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleSubmit}
      />
    </Modal>
  );
};

export default WarehouseForm;
