import * as Yup from 'yup';
import { isRequired } from './ErrorMessage';

export const SubdivisionValidation = () =>
  Yup.object().shape({
    depNo: Yup.string()
      .matches(/[0-9]/, 'Պետք է լինի թիվ')
      .min(2, 'Պարտադիր 2 նիշ')
      .max(2, 'Պարտադիր 2 նիշ ')
      .required(isRequired),
    depName: Yup.string().required(isRequired),
  });
