import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  addConfirmPositions,
  fetchBaselineState,
  fetchBeforeBaselineState,
  fetchConfirm,
  fetchConfirmPositions,
} from '../services/fetchConfirmPositions';

const useConfirmPositions = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [confirmPositions, setConfirmPositions] = useState([]);
  const [confirmData, setConfirmData] = useState([]);

  const getConfirmPositions = async () => {
    setIsLoading(true);
    try {
      const data = await fetchConfirmPositions(currentCompanyID);
      setConfirmPositions(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getConfirmData = async (query) => {
    setIsLoading(true);
    try {
      const data = await fetchConfirm(currentCompanyID, query);
      setConfirmData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const executePosition = async (updateData) => {
    const filtered = updateData?.filter(
      (item) => item.isModifed === true || !item.tableID,
    );
    try {
      const { errorCode, message } = await addConfirmPositions([...filtered]);
      if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const getBaselineState = async (stNo, query) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await fetchBaselineState(
        currentCompanyID,
        stNo,
      );
      if (errorCode === 0) {
        getConfirmData(query);
      } else {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBeforeBaselineState = async (stNo) => {
    setIsLoading(true);
    try {
      const { errorCode } = await fetchBeforeBaselineState(
        currentCompanyID,
        stNo,
      );
      if (errorCode === 111) {
        setErrorMess('Աշխատավարձը հաշվարկված է, ուզում եք ջնջել');
      } else if (errorCode === 0) {
        setErrorMess('Ելակետային վիճակ');
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    error,
    getConfirmPositions,
    getConfirmData,
    confirmData,
    confirmPositions,
    errorMess,
    setErrorMess,
    executePosition,
    getBaselineState,
    getBeforeBaselineState,
  };
};

export default useConfirmPositions;
