import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import {
  dataTimeFormat,
  debounceDelay,
  initialDate,
  printFunc,
} from '../../Utilities/Utilities';
import PartnersAccountStatementAction from './PartnersAccountStatementAction';
import PartnersAccountStatementTable from './PartnersAccountStatementTable';
import { useDebounce } from '../../Hooks/useDebounce';

const GetPartnersAccountStatement = () => {
  const { currentCompanyID, reportStartDate } = useSelector(
    (state) => state.auth,
  );
  const [partnerAccountStatement, setPartnerAccountStatement] = useState([]);
  const { partners } = partnerAccountStatement;
  const [isLoading, setIsLoading] = useState(false);
  const [dateStart, setDateStart] = useState(new Date(reportStartDate));
  const [dateEnd, setDateEnd] = useState(initialDate());
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
  const [selectPartner, setSelectPartner] = useState([]);
  const { data } = useFetchCurrency();
  const [currencyVal, setCurrencyVal] = useState({
    label: 'AMD',
    value: 'AMD',
    id: 0,
  });

  const [partnerTypeVal, setPartnerTypeVal] = useState({
    label: '',
    value: '',
    id: 0,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchValue, setSearchValues] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, debounceDelay);
  const disableViewBtn = !selectPartner?.length;
  const dispatch = useDispatch();
  const generalUrl = `companyID=${currentCompanyID}&currencyID=${
    currencyVal.id === 0 ? data?.amdCurrencyID : currencyVal.id
  }&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}&searchString=${debouncedSearchTerm.trim()}`;

  const getData = () => {
    setIsLoading(true);
    getRequest(`getTPartner?${generalUrl}&partnerType=${partnerTypeVal.id} `)
      .then((res) => {
        setPartnerAccountStatement(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [
    currentCompanyID,
    dateEndFormat,
    dateStartFormat,
    partnerTypeVal.id,
    debouncedSearchTerm,
  ]);

  useEffect(() => {
    const filterRow = partners?.filter((row) => {
      return row.printSign;
    });
    setSelectPartner(filterRow);
  }, [partners, partnerAccountStatement]);

  const clickHandlerView = () => {
    if (selectPartner?.length > 0) {
      setIsSubmitting(true);
      const url = `printTPartnerAnalisis?${generalUrl}`;
      printFunc(url, selectPartner, dispatch);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (isSubmitting) {
        setIsSubmitting(false);
      }
    }, 3500);
  }, [isSubmitting]);

  return (
    <>
      <Header
        headerBtn={false}
        pageTitle="Գործընկերների T – հաշիվ (քաղվածք հաշվից)"
      />
      <SearchBackground>
        <PartnersAccountStatementAction
          clickHandlerView={clickHandlerView}
          currencyVal={currencyVal}
          setCurrencyVal={setCurrencyVal}
          partnerTypeVal={partnerTypeVal}
          setPartnerTypeVal={setPartnerTypeVal}
          dateStart={dateStart}
          setDateStart={setDateStart}
          dateEnd={dateEnd}
          setDateEnd={setDateEnd}
          disableViewBtn={disableViewBtn}
          isSubmitting={isSubmitting}
          searchValue={searchValue}
          setSearchValue={setSearchValues}
        />
      </SearchBackground>
      <PartnersAccountStatementTable
        mainData={partnerAccountStatement}
        loading={isLoading}
        setPartnerAccountStatement={setPartnerAccountStatement}
      />
    </>
  );
};

export default GetPartnersAccountStatement;
