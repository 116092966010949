import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest } from '../../../Api/Api';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../../Components/Alert';
import { deletePartnerBankAction } from '../../../Redux/partnerSlices/useAction';

const DeletePartnerBank = ({ closeModal, deleteRowId }) => {
  const [errorHandler, setErrorHandler] = useState('');
    const handleClose = () => closeModal(false);
    const dispatch = useDispatch();
    const deleteRowDate = () => {
      deleteRequest(`deletePartnerBank/${deleteRowId}`).then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(deletePartnerBankAction(deleteRowId));
          handleClose();
        }
        if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      });
    };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={closeModal}
        confirmClick={deleteRowDate}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default DeletePartnerBank;
