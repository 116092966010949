import React, { useEffect } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import { useFormik } from 'formik';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import { materialGroupField } from './FieldsConfig';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import useIsMounted from '../../hooks/useIsMounted';
import Input from '../../components/Input/Input';
import useMaterialValueGroups from '../../hooks/useMaterialValueGroups';
import { MaterialGroupValidation } from '../../../App/Validation/MaterialGroupValidation';

const MaterialGroupsForm = ({ handleClose, onSubmit, prodGroupID = 0 }) => {
  const { getGroupForm, groupFormData } = useMaterialValueGroups();
  const isMounted = useIsMounted();
  useEffect(() => {
    getGroupForm(prodGroupID);
  }, [prodGroupID]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...groupFormData?.prodGroup,
    },
    validationSchema: MaterialGroupValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
      if (isMounted) {
        resetForm();
      }
    },
  });

  const updateFieldValueAndDropdown = (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: 0,
      };
    }
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id;

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-material-groups-form"
    >
      <form onSubmit={handleSubmit}>
        {materialGroupField.map((field) => {
          const {
            fieldType,
            fieldName,
            label,
            name,
            required,
            Children,
            optionName,
            dropdownTitle,
            type,
            dropdownId,
            maxLength,
            disabled,
          } = field;

          const fieldError = touched[name] && errors[name];
          let dropDownOptions = [];

          if (typeof Children === 'function' && groupFormData) {
            dropDownOptions = Children(groupFormData[optionName]);
          }

          switch (fieldType) {
            case 'input':
              return (
                <div key={name} className="L-material-group-input">
                  <Input
                    type={type}
                    inputTitle={label}
                    name={name}
                    requiredField={required}
                    onChange={handleChange}
                    value={values[name]}
                    maxLength={maxLength}
                    error={fieldError}
                  />
                </div>
              );
            case 'dropdown':
              return (
                <div key={fieldName} className="L-material-group-dropdown">
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={required}
                    value={{
                      label: values[fieldName] || '',
                      value: values[fieldName] || '',
                    }}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      updateFieldValueAndDropdown(
                        fieldName,
                        selectedOption,
                        dropdownId,
                      )
                    }
                    onBlur={handleBlur}
                  />
                  {required && !values[fieldName] && fieldError && (
                    <ErrorFiled error={fieldError} />
                  )}
                </div>
              );

            default:
              return null;
          }
        })}

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
          executeDisabled={!dirty || !isValid}
        />
      </form>
    </Modal>
  );
};

export default MaterialGroupsForm;
