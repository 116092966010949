import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { postRequest } from '../../Api/Api';
import {
  dataTimeFormat,
  removeCommas,
  typOfString,
} from '../../Utilities/Utilities';
import PaymentOrderForm from './PaymentOrderForm';

const EventAddForm = ({
  closeModal,
  getData,
  pageNumber,
  setErrorHandler,
  isCopyData = {},
}) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { paymentOrderData } = useSelector(
    (state) => state.getPaymentOrderData,
  );
  const handleClose = () => closeModal(false);

  const { amdCurrCode, amdCurrID } = paymentOrderData || {};

  const initialData = useMemo(() => {
    return {
      companyID: currentCompanyID,
      docTypeID: 3,
      partnerID: 0,
      docDate: new Date(),
      currencyID: amdCurrID,
      currencyCode: amdCurrCode,
      docNum: '',
      partnerAccount: '',
      exchangeRate: 1,
      docSign: 1,
      areAttached: '',
      appendix: '',
      recipient: '',
      amount: 0,
      taxNumber: null,
    };
  }, []);

  const onSubmit = (values, resetForm) => {
    postRequest('addPaymentOrder', {
      ...values,
      docDate: dataTimeFormat(values?.docDate),
      docNum: typOfString(values.docNum),
      amount: removeCommas(values.amount),
      amountAMD: removeCommas(values.amountAMD),
      areAttached: typOfString(values.areAttached),
      taxNumber: values.taxNumber !== null ? Number(values.taxNumber) : 0,
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          resetForm();
          handleClose();
          getData(pageNumber);
        }
        if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <PaymentOrderForm
      closeModal={handleClose}
      onSubmit={onSubmit}
      initialData={
        Object?.keys(isCopyData)?.length === 0 ? initialData : isCopyData
      }
      addEvent={true}
      setErrorHandler={setErrorHandler}
    />
  );
};

export default EventAddForm;
