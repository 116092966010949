import React from 'react';
import { useMemo } from 'react';
import { useFetch } from '../../Hooks/useFetch';
import { SelectControl } from '../SelectControl/SelectControl';

const SelectPartnerType = (props) => {
  const {
    partnerTypeVal,
    partnerTypeData = null,
    selectChangePartner,
    selectBoxTitle = 'Գործընկերոջ տեսակը',
    isClearable,
    placeholder = '',
    matchFromParam,
    requiredFiled,
    onBlur,
  } = props;

  const [partnerTypeList] = useFetch(`getPartnerTypes`);

  const partnerTypeOptions = useMemo(() => {
    return partnerTypeData
      ? partnerTypeData
      : partnerTypeList?.map(({ partnerTypeName, partnerTypeID }) => {
          return {
            label: partnerTypeName,
            value: partnerTypeName,
            id: partnerTypeID,
          };
        });
  }, [partnerTypeData, partnerTypeList]);

  return (
    <div className="G-partner-type-wrapper">
      <SelectControl
        selectBoxTitle={selectBoxTitle}
        isClearable={isClearable}
        matchFromParam={matchFromParam}
        requiredFiled={requiredFiled}
        options={partnerTypeOptions}
        onBlur={onBlur}
        id="partnerType"
        value={partnerTypeVal}
        onChange={selectChangePartner}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SelectPartnerType;
