import React from 'react';
import EmployeesForm from './EmployeesForm';
import useEmployeesForm from '../../hooks/useEmployeesForm';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';

const EventEditForm = ({ employeeID, handleClose, title }) => {
  const { updateEmployee, errorMess, setErrorMess } = useEmployeesForm();
  const isMounted = useIsMounted();

  const onUpdateHandler = async (values) => {
    const val = {
      ...values,
      socPercent: Number(values.socPercent),
      genderNo: Number(values.genderNo),
      rentNo: Number(values.rentNo),
      tuPercent: Number(values.tuPercent),
    };
    try {
      await updateEmployee(val, handleClose);
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <EmployeesForm
        employeeID={employeeID}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
        title={title}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventEditForm;
