import React from 'react';
import Button from '../../../App/Components/Button/Button';

const FormulationBtn = ({ buttonsConfig, className }) => {
  return (
    <div className={`L-formulation-btn-flex ${className || ''}`}>
      {buttonsConfig.map((button, index) => (
        <div className="G-formulation-btn" key={index}>
          <Button
            onClick={button.onClick}
            text={button.text}
            type={button.type || 'button'}
            disabled={button.disabled}
            customClass={button.disabled ? 'G-disabled-button' : ''}
          />
        </div>
      ))}
    </div>
  );
};

export default FormulationBtn;
