export const searchFields = [
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Ստորաբաժանումը',
    required: false,
    fieldName: 'depNo',
    optionName: 'workDeps',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.depName,
        id: i.depNo,
        value: i.depName,
      }));
    },
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'searchString',
    label: 'Ազգանուն Անուն Հայրանուն',
    value: '',
    required: false,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'empNo',
    label: 'Տաբել N',
    value: '',
    required: false,
  },
  {
    fieldType: 'checkbox',
    name: 'fireSign',
    label: 'Ցույց տալ նաև ազատվածներին',
    value: '',
  },
];
