import { createAction } from '../../actionCreators';
import {
  FETCH_ADDITIONS_TYPES,
  FETCH_ADDITIONS_TYPE_FORM,
  DELETE_ADDITIONS_TYPE,
  UPDATE_ADDITIONS_TYPE,
} from './constants';

export const fetchAdditionsTypesAction = createAction(FETCH_ADDITIONS_TYPES);
export const updateAdditionsTypeAction = createAction(UPDATE_ADDITIONS_TYPE);
export const deleteAdditionsTypeAction = createAction(DELETE_ADDITIONS_TYPE);

export const fetchAdditionsFormAction = createAction(FETCH_ADDITIONS_TYPE_FORM);
