import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addWarehouse,
  fetchWarehouse,
  fetchWarehouseForm,
  removeWarehouse,
  updateWarehouse,
} from '../services/fetchWarehouse';
import {
  deleteWarehouseAction,
  fetchWarehouseAction,
  updateWarehouseAction,
} from '../../App/Redux/materialValuesStor/warehouseSlices/useAction';

const useWarehouse = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [warehouseFormData, setWarehouseFormData] = useState(null);
  const { currentCompanyID } = useSelector((state) => state.auth);
  const warehouse = useSelector((state) => state.warehouse?.warehouseData);
  const dispatch = useDispatch();

  const getWarehouse = async (param = '') => {
    try {
      setIsLoading(true);
      const response = await fetchWarehouse(currentCompanyID, param);
      dispatch(fetchWarehouseAction(response));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouseForm = async (salaryTypeID) => {
    setIsLoading(true);
    try {
      const data = await fetchWarehouseForm(currentCompanyID, salaryTypeID);
      setWarehouseFormData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const addNewWarehouse = async (addMaterial, closeFormCallback, param) => {
    try {
      const { errorCode, message } = await addWarehouse(addMaterial);

      if (errorCode === 0) {
        getWarehouse(param);
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const editWarehouse = async (updateMaterial, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await updateWarehouse(updateMaterial);
      if (errorCode === 0) {
        dispatch(updateWarehouseAction(updateMaterial));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteWarehouse = async (id, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await removeWarehouse(id);
      if (errorCode === 0) {
        dispatch(deleteWarehouseAction(id));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    error,
    errorMess,
    setErrorMess,
    getWarehouse,
    warehouse,
    getWarehouseForm,
    warehouseFormData,
    addNewWarehouse,
    editWarehouse,
    deleteWarehouse,
  };
};

export default useWarehouse;
