import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import { tableActionTitle } from '../../../App/Utilities/Utilities';
import { actionItem } from './tableConfig';
import Action from '../../../App/Components/Table/Action';
import Forms from './Forms';
import Loading from '../../../App/Components/Loading/Loading';

const SubdivisionTable = ({ mainData, loading }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Կոդը',
      cell: (row) => row.depNo,
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 500, overflow: 'hidden' },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(value, row) =>
            setShowForm({
              type: value,
              value: row,
            })
          }
          actionItem={actionItem}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 165 },
    },
  ];
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table customClass="" data={mainData} columnConfig={columnConfig} />
      ) : null}
      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default SubdivisionTable;
