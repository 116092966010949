import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRequest } from '../../Api/Api';
import { getSpendingMoneyDataAction } from '../../Redux/accountForSpendingMoneySlices/useAction';
import { checkBeforeDelete, printHandler } from '../../Utilities/Utilities';

export const useAccountTableActions = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openOperationsModal, setOpenOperationsModal] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const [isCloseDocument, setIsCloseDocument] = useState(false);
  const dispatch = useDispatch();

  const fetchEditData = (row) => {
    if (row.partnerID && row.cashMoneyID) {
      getRequest(
        `getExpenses?partnerID=${row.partnerID}&CashMoneyID=${row.cashMoneyID}`,
      )
        .then((res) => {
          if (res.data) {
            dispatch(getSpendingMoneyDataAction(res.data));
          }
        })
        .catch((err) => console.log('Err:', err));
    }
  };

  const handleEdit = (rowData) => {
    fetchEditData(rowData);
    setOpenEditModal(rowData?.cashMoneyID);
  };

  const handleDelete = (deleteRowId) => {
    checkBeforeDelete(`letDeleteCashMoney?cashMoneyID=${deleteRowId}`).then(
      (res) => {
        if (res.errorCode === 0) setOpenDeleteModal(deleteRowId);
        else setErrorHandler(res.message);
      },
    );
  };

  const handlePrint = (partnerID, cashMoneyID) => {
    printHandler(
      `printCashMoney?partnerID=${partnerID}&cashMoneyID=${cashMoneyID}`,
      dispatch,
    );
  };

  const handleActionChange = (value, row) => {
    switch (value) {
      case 'operations':
        setOpenOperationsModal(row);
        break;
      case 'delete':
        handleDelete(row.cashMoneyID);
        break;
      case 'edit':
        handleEdit(row);
        break;
      case 'print':
        handlePrint(row.partnerID, row?.cashMoneyID);
        break;
      case 'closeDocument':
        setIsCloseDocument(row);
        break;
      default:
        break;
    }
  };

  return {
    openEditModal,
    setOpenEditModal,
    openDeleteModal,
    setOpenDeleteModal,
    openOperationsModal,
    setOpenOperationsModal,
    errorHandler,
    setErrorHandler,
    isCloseDocument,
    setIsCloseDocument,
    handleActionChange,
  };
};
