import React, { useState } from 'react';
import { getRequest } from '../../../Api/Api';
import Loading from '../../../Components/Loading/Loading';
import Action from '../../../Components/Table/Action';
import Table from '../../../Components/Table/Table';
import { cashOrdersList } from '../../../OptionsList/OptionsList';
import {
  checkBeforeDelete,
  dateFormat,
  formatNumberWithCommas,
  printHandler,
  tableActionTitle,
} from '../../../Utilities/Utilities';
import DeleteCashWarrant from './DeleteCashWarrant';
import EventEditForm from './EventEditForm';
import EventAddForm from './EventAddForm';
import GetDocumentEntries from '../../../Components/GetDocumentEntries/GetDocumentEntries';

import { useDispatch } from 'react-redux';
import { filterRowAction } from '../../../Redux/FilterRowData/useAction';
import Alert from '../../../Components/Alert';

const CashWarrantTable = ({
  mainData,
  loading,

  getData,
  pageNumber,
}) => {
  const [errorMess, setErrorMess] = useState('');
  const [openEditForm, setOpenEditForm] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState(false);
  const [copyRowData, setCopyRowData] = useState(false);
  const [openDocumentEntries, setOpenDocumentEntries] = useState(false);
  const dispatch = useDispatch();

  const handelChange = (value, row) => {
    switch (value) {
      case 'delete':
        return deleteHandler(row?.documentID);
      case 'edit':
        return editHandler(row);
      case 'print':
        return handlePrint(row?.documentID);
      case 'copy':
        return copyHandler(row?.documentID);
      default:
        return null;
    }
  };

  const accountHandler = (row) => {
    const { correspAccount } = row || {};
    if (correspAccount) {
      return (
        <span
          onClick={() => setOpenDocumentEntries(row?.documentID)}
          className="G-account-is-active"
        >
          {correspAccount}
        </span>
      );
    }
    return null;
  };

  const columnConfig = [
    {
      title: 'Օրդեր N',
      cell: (row) => row.docNum,
      customStyle: { maxWidth: 110 },
    },
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 980 },
    },

    {
      title: 'Հաշիվ',
      cell: (row) => accountHandler(row),
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գումար',
      cell: (row) => formatNumberWithCommas(row.amount),
      customStyle: { maxWidth: 200 },
    },

    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={handelChange}
          actionItem={cashOrdersList}
          modalIsOpen={openEditForm || deleteRowData || copyRowData}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];

  const deleteHandler = (documentID) => {
    checkBeforeDelete(`letDeleteCashRegister?documentID=${documentID}`).then(
      (res) => {
        if (res.errorCode === 0) {
          setDeleteRowData(documentID);
        }
        if (res.errorCode > 0) {
          setErrorMess(res.message);
        }
      },
    );
  };
  const editHandler = (row) => {
    setOpenEditForm(row);
  };

  const copyHandler = (documentID) => {
    dispatch(filterRowAction({ currentId: 0 }));
    getRequest(`getCashRegisterForCopy?documentID=${documentID}`)
      .then((res) => {
        setCopyRowData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  };

  const handlePrint = (id) => {
    printHandler(`cashRegistersPrint?id=${id}`, dispatch);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-cash-warrant-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {openEditForm && (
        <EventEditForm
          modalClick={setOpenEditForm}
          //documentID={openEditForm}
          rowData={openEditForm}
          setErrorHandler={setErrorMess}
        />
      )}
      {copyRowData && (
        <EventAddForm
          isCopyData={copyRowData}
          modalClick={setCopyRowData}
          setErrorHandler={setErrorMess}
          getData={getData}
          pageNumber={pageNumber}
        />
      )}
      {deleteRowData && (
        <DeleteCashWarrant
          closeModal={setDeleteRowData}
          deleteRowId={deleteRowData}
          setErrorHandler={setErrorMess}
        />
      )}

      {openDocumentEntries && (
        <GetDocumentEntries
          currentDocumentID={openDocumentEntries}
          isClose={setOpenDocumentEntries}
        />
      )}
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default CashWarrantTable;
