import React from "react";
import TopArrowIcon from '../../icons/TopArrow.svg';
import "./style.scss";

const TopArrow = ({ onClick, customClass }) => {
  return (
    <div className={`top-arrow-comp ${customClass}`} onClick={onClick}>
      <img src={TopArrowIcon} alt="" />
    </div>
  );
};

export default TopArrow;
