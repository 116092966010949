import Tooltip from '../../Components/Tooltip/Tooltip';
import { Commas } from '../../Utilities/Utilities';

export const prodServWorkNameList = [
  {
    title: <Tooltip content="">Հ/հ </Tooltip>,
    cell: (row) => row.goodNumber,
    customStyle: { maxWidth: 60 },
  },
  {
    title: 'Անվանում',
    cell: (row) => row.description,
    customStyle: { maxWidth: 700, overflow: 'hidden' },
  },
  {
    title: 'Չափման միավոր',
    cell: (row) => row.unit,
    customStyle: { maxWidth: 110 },
  },
  {
    title: 'Քանակը',
    cell: (row) => Commas(row.amount),
    customStyle: { maxWidth: 130 },
  },
  {
    title: 'Միավորի գինը',
    cell: (row) => Commas(row.price),
    customStyle: { maxWidth: 130 },
  },
  {
    title: 'Զեղչի %',
    cell: (row) => Commas(row.ef),
    customStyle: { maxWidth: 100 },
  },
  {
    title: 'Ակցիզային հարկ',
    cell: (row) => Commas(row.eta),
    customStyle: { maxWidth: 120 },
  },
  {
    title: 'Գումարը',
    cell: (row) => Commas(row.totalPrice),
    customStyle: { maxWidth: 140 },
  },
  {
    title: 'ԱԱՀ',
    cell: (row) => Commas(row.vat),
    customStyle: { maxWidth: 110 },
  },
  {
    title: 'Ընդամենը',
    cell: (row) => Commas(row.total),
    customStyle: { maxWidth: 110 },
  },
];
