// export const actionList = (isClosed) => {
//   const actionList = [
//     {
//       name: 'Խմբագրել',
//       value: 'edit',
//     },
//     {
//       name: 'Գործառնություն',
//       value: 'operations',
//     },

//     {
//       name: 'Ջնջել',
//       value: 'delete',
//     },
//     {
//       name: 'Դիտել/տպել',
//       value: 'print',
//     },
//     {
//       name: 'Փակել փաստաթուղթը',
//       value: 'closeDocument',
//     },
//     {
//       name: 'Գործառնություն',
//       value: 'operations',
//     },

//     {
//       name: 'Դիտել/տպել',
//       value: 'print',
//     },
//     {
//       name: 'Բացել փաստաթուղթը',
//       value: 'closeDocument',
//     },
//   ];
//   return actionList;
// };

export const actionList = (isClosed) => {
  const baseActions = [
    { name: 'Գործառնություն', value: 'operations' },
    { name: 'Ջնջել', value: 'delete' },
    { name: 'Դիտել/տպել', value: 'print' },
  ];

  if (isClosed) {
    return [
      ...baseActions,
      { name: 'Բացել փաստաթուղթը', value: 'closeDocument' },
    ];
  } else {
    return [
      { name: 'Խմբագրել', value: 'edit' },
      ...baseActions,
      { name: 'Փակել փաստաթուղթը', value: 'closeDocument' },
    ];
  }
};
