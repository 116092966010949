import { getRequest } from '../../App/Api/Api';

export const fetchBankCode = async (bankCode) => {
  if (!bankCode?.length) {
    return;
  }
  try {
    const response = await getRequest(`getBankByCode?bankCode=${bankCode}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
