import React from "react";
import { useDispatch } from "react-redux";
import Checkbox from "../../Components/Checkbox/Checkbox";
import Loading from "../../Components/Loading/Loading";
import Table from "../../Components/Table/Table";
import {
  checkAllPrintSignAction,
  checkSinglePrintSignAction,
} from "../../Redux/partnerCirculationBulletinSlices/useAction";

const PartnerCirculationTable = ({ mainData, loading }) => {
  const dispatch = useDispatch();
  const columnConfig = [
    {
      title: 'Գործընկերներ',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 1800, overflow: 'hidden' },
    },

    {
      title: (
        <span className="G-flex">
          Նշել բոլորը
          <Checkbox onChange={(e) => selectAllPrintSign(e)} />
        </span>
      ),
      cell: (row) => (
        <Checkbox
          checked={row.printSign}
          value={row}
          onChange={(e) => checkedSinglePrintSign(e, row)}
        />
      ),
      customStyle: { maxWidth: 120 },
    },
  ];

  const selectAllPrintSign = (e) => {
    dispatch(checkAllPrintSignAction(e.target.checked));
  };

  const checkedSinglePrintSign = (e, row) => {
    dispatch(checkSinglePrintSignAction(row));
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {mainData !== null && mainData && mainData?.length && (
        <Table
          customClass="G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      )}
    </>
  );
};

export default PartnerCirculationTable;
