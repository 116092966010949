import * as Yup from "yup";
import { SelectFieldMessage, InputFiledMessage } from './ErrorMessage';
import { Amount } from './ValidationForAllFiled';


export const CashOutflowValidation = () =>
  Yup.object().shape({
    amount: Amount,
    recipient: Yup.string().required(InputFiledMessage),
    accountCredit: Yup.string().required(SelectFieldMessage),
    correspAccount: Yup.string().required(SelectFieldMessage),
  });
