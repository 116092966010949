import React, { useState } from "react";
import { useFormik } from "formik";
import { useHistory } from 'react-router-dom';
import { putRequest } from '../../Api/Api';
import Button from '../../Components/Button/Button';
import InputFiled from '../../Components/InputFiled/InputFiled';
import { ReactComponent as CloseEye } from '../../Assets/icons/eye-close.svg';
import { ReactComponent as ShowEye } from '../../Assets/icons/eye-show.svg';
import { mainPage, register } from '../../Utilities/Utilities';
import './changePassword.scss';
import { ChangePasswordValidation } from '../../Validation/ChangePasswordValidation';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';

import { useSelector } from 'react-redux';
import PasswordStrengthMeter from '../Register/PasswordStrengthMeter';
import Modal from '../../Components/Modal/Modal';
import Alert from '../../Components/Alert';

const ChangePassword = ({ closeModal }) => {
  const handleClose = () => closeModal(false);
  const { email } = useSelector((state) => state.auth);
  const [errorHandler, setErrorHandler] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const history = useHistory();
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    initialValues: {
      email: email,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: ChangePasswordValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });
  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const onSubmit = (values, resetForm) => {
    putRequest('changePassword', values)
      .then((res) => {
        if (res.data.errorCode === 0) {
          resetForm();

          history.push(mainPage);
        }
        if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <Modal customClass="L-change-password-wrapper" closeHandler={handleClose}>
      <div className="L-change-password-form">
        <form onSubmit={onSubmit}>
          <div className="L-change-password-input">
            <InputFiled
              inputTitle="Էլեկտրոնային հասցեն"
              name="email"
              value={values.email}
              onBlur={handleBlur}
              changeHandler={handleChange}
              disabled={true}
            />
            {touched.email && errors.email && (
              <ErrorFiled error={errors.email} />
            )}
          </div>
          <div className="L-change-password-input">
            <InputFiled
              type={passwordShown ? 'text' : 'password'}
              requiredFiled={true}
              inputTitle="Ընթացիկ գաղտնաբառը"
              name="oldPassword"
              value={values.oldPassword}
              onBlur={handleBlur}
              changeHandler={handleChange}
            />
            {touched.oldPassword && errors.oldPassword && (
              <ErrorFiled error={errors.oldPassword} />
            )}
            <span className="L-toggle-icon" onClick={togglePasswordVisibility}>
              {passwordShown ? <ShowEye /> : <CloseEye />}
            </span>
          </div>
          <div className="L-change-password-input">
            <InputFiled
              type={passwordShown ? 'text' : 'password'}
              requiredFiled={true}
              inputTitle="Նոր գաղտնաբառը"
              name="newPassword"
              value={values.newPassword}
              onBlur={handleBlur}
              changeHandler={handleChange}
            />
            {touched.newPassword && errors.newPassword && (
              <ErrorFiled error={errors.newPassword} />
            )}
            {values.newPassword && (
              <PasswordStrengthMeter password={values.newPassword} />
            )}
            <span className="L-toggle-icon" onClick={togglePasswordVisibility}>
              {passwordShown ? <ShowEye /> : <CloseEye />}
            </span>
          </div>
          <div className="L-change-password-input">
            <InputFiled
              type={passwordShown ? 'text' : 'password'}
              requiredFiled={true}
              inputTitle="Հաստատեք նոր գաղտնաբառը"
              name="confirmNewPassword"
              value={values.confirmNewPassword}
              onBlur={handleBlur}
              changeHandler={handleChange}
            />
            {touched.confirmNewPassword && errors.confirmNewPassword && (
              <ErrorFiled error={errors.confirmNewPassword} />
            )}
            <span className="L-toggle-icon" onClick={togglePasswordVisibility}>
              {passwordShown ? <ShowEye /> : <CloseEye />}
            </span>
          </div>
        </form>
      </div>
      <div className="G-flex-justify-end">
        <div className="G-register-btn">
          <Button
            text={register}
            customClass={!(isValid && dirty) ? 'G-disabled-button' : ''}
            disabled={!(isValid && dirty)}
            onClick={handleSubmit}
          />
        </div>
      </div>
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </Modal>
  );
};

export default ChangePassword;
