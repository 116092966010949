import { dateFormat } from '../../Utilities/Utilities';

export const ConvertDataExcel = (data) => {
  return (
    data !== null &&
    data?.length > 0 &&
    data?.map((i) => {
      return {
        Ամսաթիվ: dateFormat(i.docDate),
        Փաստաթուղթ: i.docNum,
        Գործընկեր: i.partnerName,
        Արժույթ: i.currencyCode,
        Դեբետ: i.accountDebit,
        Արժույթ: i.credCurrCode,
        Կրեդիտ: i.accountCredit,
        Գումար: i.amount,
        'Գումարը AMD': i.amountAMD,
        Նկարագրություն: i.description,
      };
    })
  );
};

export default ConvertDataExcel;
