import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';

export const fetchPositions = async (companyId, query) => {
  try {
    let url = `getPositions?companyID=${companyId}`;
    if (query) {
      url += `&searchString=${query}`;
    }
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addPositions = async (positionData) => {
  try {
    const response = await postRequest('addPosition ', {
      ...positionData,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding position:', error);
    throw error;
  }
};

export const updatePositions = async (positionData) => {
  try {
    const response = await putRequest(`editPosition`, positionData);
    return response.data;
  } catch (error) {
    console.error('Error updating position:', error);
    throw error;
  }
};

export const deletePositions = async (positionID) => {
  try {
    const response = await deleteRequest(`deletePosition/${positionID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting position:', error);
    throw error;
  }
};

export const fetchPositionForm = async (currentCompanyID, positionID) => {
  if (!currentCompanyID) {
    return;
  }
  try {
    const response = await getRequest(
      `getPosition?companyID=${currentCompanyID}&positionID=${positionID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
