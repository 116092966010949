import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Action from '../../../App/Components/Table/Action';
import { tableActionTitle } from '../../../App/Utilities/Utilities';
import { globalActionItems } from '../../Config/tableConfig';
import Forms from './Forms';
import Loading from '../../../App/Components/Loading/Loading';

const WarehouseTable = ({ warehouseData, isLoading }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Կոդը',
      cell: (row) => row.storeNo,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.storeName,
      customStyle: { maxWidth: 400, overflow: 'hidden' },
    },

    {
      title: 'Պատասխանատու անձ',
      cell: (row) => row.person,
      customStyle: { maxWidth: 200, overflow: 'hidden' },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(type, row) =>
            setShowForm({
              type: type,
              value: row,
            })
          }
          actionItem={globalActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {warehouseData !== null && warehouseData && warehouseData?.length ? (
        <Table
          customClass=""
          data={warehouseData}
          columnConfig={columnConfig}
        />
      ) : null}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default WarehouseTable;
