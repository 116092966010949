import React from 'react';
import { useDispatch } from 'react-redux';
import { addProductAction } from '../../Redux/purchaseActSlices/useAction';
import { randomID, removeCommas } from '../../Utilities/Utilities';
import ProductForm from './ProductForm';

const EventAddForm = ({ modalClick }) => {
  const handleClose = () => modalClick(false);
  const dispatch = useDispatch();
  const addInitialValues = {
    productID: randomID(),
    unitID: '',
    incomeTaxSign: false,
    productNameID: '',
    accountID: '',
    quantity: '',
    price: '',
  };

  const onSubmit = (values, resetForm) => {
    if (values) {
      dispatch(
        addProductAction({
          ...values,
          price: removeCommas(values.price),
          amount: values.quantity * removeCommas(values.price),
        }),
      );
      resetForm();
      handleClose();
    } else {
      return null;
    }
  };

  return (
    <ProductForm
      initialValue={addInitialValues}
      closeModal={modalClick}
      onSubmit={onSubmit}
      handleClose={handleClose}
    />
  );
};

export default EventAddForm;
