import React from 'react';
import Modal from '../../../../App/Components/Modal/Modal';
import ConfirmEmployeePosition from '../../confirmEmployeePosition/ConfirmEmployeePosition';

const ConfirmEmployeePositionModal = ({ handleClose, rowValues }) => {
  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-confirm-employee-position-modal"
      title="Հաստատել հաստիքը"
    >
      <ConfirmEmployeePosition
        rowValues={rowValues}
        handleClose={handleClose}
      />
    </Modal>
  );
};

export default ConfirmEmployeePositionModal;
