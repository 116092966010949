import {
  DELETE_SERVICE_DATA,
  EDIT_SERVICE_DATA,
  GET_SERVICE_DATA,
  LOADING,
} from "./constants";

const initialState = {
  serviceData: null,
  isLoading: false,
};

export const serviceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SERVICE_DATA:
      return {
        ...state,
        serviceData: payload,
        isLoading: true,
      };
    case EDIT_SERVICE_DATA:
      return {
        ...state,
        serviceData: {
          ...state.serviceData,
          serviceNames: state.serviceData.serviceNames.map((service) => {
            return service.serviceNameID === payload.serviceNameID
              ? payload
              : service;
          }),
        },
      };
    case DELETE_SERVICE_DATA:
      return {
        ...state,
        serviceData: {
          ...state.serviceData,
          serviceNames: state.serviceData?.serviceNames.filter(
            (item) => item.serviceNameID !== payload
          ),
        },
      };
    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return { ...state };
  }
};
