import React from 'react';
import { useDispatch } from 'react-redux';
import { putRequest } from '../../Api/Api';
import { editChartOfAccountAction } from '../../Redux/chartOfAccountSlices/useAction';
import ChartOfAccountForm from './ChartOfAccountForm';

const EventEditForm = ({ rowData, closeModal, setErrorHandler }) => {
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  const editHandler = (values) => {
    const curData = values?.currencies?.map((item) => {
      item['currencyCode'] = item['label'];
      delete item['value'];
      delete item['label'];
      return {
        ...item,
      };
    });
    const result = curData?.reduce(
      (acc, cur) => (cur.currencyCode && acc.push(cur.currencyCode), acc),
      [],
    );
    putRequest('editAccount', {
      account: { ...values },
      currencies: curData,
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          handleClose();
          dispatch(
            editChartOfAccountAction({
              ...values,
              currency: result?.join('/'),
            }),
          );
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };
  return (
    <>
      <ChartOfAccountForm
        closeModal={handleClose}
        rowData={rowData}
        onSubmit={editHandler}
        disabledAccountCode={true}
        toglHandler={false}
        setErrorHandler={setErrorHandler}
      />
    </>
  );
};

export default EventEditForm;
