import React, { useRef } from 'react';
import InputTitle from './InputTitle';
import './input.scss';

const Input = (props) => {
  const {
    onChange,
    value,
    type,
    name,
    requiredFiled,
    inputTitle = '',
    className = '',
    onBlur,
    lang,
    disabled,
    inputClassName = '',
    ...restProps
  } = props;
  const inputRef = useRef(null);

  return (
    <div className={`G-s-input-wrap ${className}`}>
      <InputTitle requiredFiled={requiredFiled} inputTitle={inputTitle} />
      <input
        className={`G-s-input ${inputClassName}`}
        ref={inputRef}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        lang={lang}
        autoComplete="off"
        disabled={disabled}
        onInput={(e) => {
          e.target.value = e.target.value.replace(',', '.');
        }}
        {...restProps}
      />
    </div>
  );
};

export default Input;
