import React from 'react';
import Table from '../../../App/Components/Table/Table';
import { Commas, dateFormat } from '../../../App/Utilities/Utilities';

const BenefitCalculationStory = ({ benefitCalculationStory }) => {
  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.salaryDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Հավելում',
      cell: (row) => row.stName,
      customStyle: { maxWidth: 220, overflow: 'hidden' },
    },

    {
      title: 'Սկիզբը',
      cell: (row) => dateFormat(row.startDate),
      customStyle: { maxWidth: 70 },
    },

    {
      title: 'Վերջը',
      cell: (row) => dateFormat(row.endDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Օրեր',
      cell: (row) => row.days,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Միջին օրական',
      cell: (row) => Commas(row.dailyAvg),
      customStyle: { maxWidth: 110, justifyContent: 'end' },
    },
    {
      title: 'Տոկոսը',
      cell: (row) => row.benefitPercent,
      customStyle: { maxWidth: 60 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 140, justifyContent: 'end' },
    },
  ];
  return (
    <>
      {benefitCalculationStory !== null && benefitCalculationStory?.length ? (
        <Table
          customClass=" G-table-has-scroll"
          data={benefitCalculationStory}
          columnConfig={columnConfig}
        />
      ) : null}
    </>
  );
};

export default BenefitCalculationStory;
