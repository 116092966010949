import {
  ADD_PURCHASE_PRODUCT,
  CHECKED_ALL_TAX_SING,
  CHECKED_ROW_TAX_SING,
  DELETE_PURCHASE_ACT,
  DELETE_PURCHASE_PRODUCT,
  EDIT_PURCHASE_DATA,
  EDIT_PURCHASE_PRODUCT,
  EDIT_PURCHASE_TABLE_DATA,
  GET_PURCHASE_ACT_DATA,
  GET_PURCHASE_ADD_FORM_DATA,
  LOADING,
  UPDATE_PARTNER_PURCHASE_FORM,
  UPDATE_PRODUCT_DATA,
  UPDATE_UNIT_OF_MEASURE,
  UPDATE_INCOME_TAX,
} from './constants';

const initialState = {
  purchaseData: null,
  purchaseFormData: null,
  isLoading: false,
  isCheckAll: false,
};

export const purchaseActReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PURCHASE_ACT_DATA:
      return {
        ...state,
        purchaseData: payload,
        isLoading: true,
      };
    case DELETE_PURCHASE_ACT:
      return {
        ...state,
        purchaseData: {
          ...state.purchaseData,
          purchases: state.purchaseData?.purchases.filter(
            (item) => item.documentID !== payload,
          ),
        },
      };

    case UPDATE_PRODUCT_DATA:
      return {
        ...state,
        purchaseFormData: {
          ...state.purchaseFormData,
          productNames: [...state.purchaseFormData.productNames, ...payload],
        },
      };
    case UPDATE_UNIT_OF_MEASURE:
      return {
        ...state,
        purchaseFormData: {
          ...state.purchaseFormData,
          units: [...state.purchaseFormData.units, ...payload],
        },
      };

    case UPDATE_INCOME_TAX:
      return {
        ...state,
        purchaseFormData: {
          ...state.purchaseFormData,
          hasIncomeTax: payload,
        },
      };
    case GET_PURCHASE_ADD_FORM_DATA:
      return {
        ...state,
        purchaseFormData: payload,
      };

    case UPDATE_PARTNER_PURCHASE_FORM:
      return {
        ...state,
        purchaseData: {
          ...state.purchaseData,
          partners: [...state.purchaseData.partners, ...payload],
        },
      };

    case ADD_PURCHASE_PRODUCT:
      return {
        ...state,
        purchaseFormData: {
          ...state.purchaseFormData,
          goods: [...state.purchaseFormData.goods, payload],
        },
      };
    case EDIT_PURCHASE_PRODUCT:
      return {
        ...state,
        purchaseFormData: {
          ...state.purchaseFormData,
          goods: state.purchaseFormData.goods.map((item) => {
            return item.productID === payload.productID ? payload : item;
          }),
        },
      };
    case DELETE_PURCHASE_PRODUCT:
      return {
        ...state,
        purchaseFormData: {
          ...state.purchaseFormData,
          goods: state.purchaseFormData?.goods.filter(
            (item) => item.productID !== payload,
          ),
        },
      };

    case EDIT_PURCHASE_DATA:
      return {
        ...state,
        purchaseFormData: {
          ...state.purchaseFormData,
          purchase: { ...state.purchaseFormData.purchase, ...payload },
        },
      };

    case EDIT_PURCHASE_TABLE_DATA:
      return {
        ...state,
        purchaseData: {
          ...state.purchaseData,
          purchases: state.purchaseData.purchases.map((item) => {
            return item.documentID === payload.documentID
              ? { ...item, docNum: payload.docNum, docDate: payload.docDate }
              : item;
          }),
        },
      };

    case CHECKED_ALL_TAX_SING:
      return {
        ...state,
        isCheckAll: payload,
        purchaseFormData: {
          ...state.purchaseFormData,
          goods: [
            ...state.purchaseFormData?.goods.map((item) => {
              return { ...item, incomeTaxSign: payload };
            }),
          ],
        },
      };

    case CHECKED_ROW_TAX_SING:
      return {
        ...state,
        purchaseFormData: {
          ...state.purchaseFormData,
          goods: [
            ...state.purchaseFormData?.goods.map((item) => {
              if (item.actID === payload.actID) {
                return { ...item, incomeTaxSign: !payload.incomeTaxSign };
              } else {
                return item;
              }
            }),
          ],
        },
      };
    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return { ...state };
  }
};
