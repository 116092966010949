import React from 'react';
import InputTitle from '../../Input/InputTitle';
import './style.scss';
const DateInput = ({
  value,
  onChange,
  requiredFiled = false,
  title,
  name,
  maxDate,
  minDate,
  onBlur,
}) => {
  let dateString = value && value?.substring(0, 10);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    const inputDate = new Date(newDate);
    const maxAllowedDate = new Date(maxDate);

    if (inputDate > maxAllowedDate) {
      const formattedMaxDate = maxAllowedDate.toISOString().split('T')[0];
      onChange(formattedMaxDate);
    } else {
      onChange(newDate);
    }
  };

  return (
    <div className="date-wrap">
      <InputTitle requiredFiled={requiredFiled} inputTitle={title} />
      <input
        type="date"
        value={dateString || ''}
        name={name}
        onChange={handleDateChange}
        max={maxDate}
        min={minDate}
        onBlur={onBlur}
      />
    </div>
  );
};

export default DateInput;
