import React from 'react';
import Button from '../../Components/Button/Button';
import CalendarTime from '../../Components/Calendar/CalendarTime';
import Checkbox from '../../Components/Checkbox/Checkbox';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import SelectCurrency from '../../Components/SelectBox/SelectCurrency';
import SelectPartnerType from '../../Components/SelectBox/SelectPartnerType';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import { viewPrintTitle } from '../../Utilities/Utilities';
import SearchFiled from '../../Components/SearchFiled/SearchFiled';

const PartnerCirculationViewAccount = (props) => {
  const {
    partnerTypeVal,
    setPartnerTypeVal,
    viewAccounts,
    setViewAccounts,
    viewClick,
    dateStart,
    dateEnd,
    timeChangeHandler,
    currencyVal,
    setCurrencyVal,
    disabledBtn,
    searchValue,
    setSearchValue,
    getData,
  } = props;

 const { data } = useFetchCurrency();

  const changePartnerType = (value) => {
    if (!value) {
      value = {
        value: '',
        id: 0,
      };
    }
    setPartnerTypeVal(value);
  };

  const changeCurrency = (value) => {
    if (!value) {
      value = {
        value: '',
      };
    }
    setCurrencyVal(value);
  };

  return (
    <SearchBackground>
      <div className="L-partner-circulation-bulletin-block G-flex-justify-between">
        <CalendarTime
          dateStart={dateStart}
          dateEnd={dateEnd}
          onChangeHandler={timeChangeHandler}
        />
        <div className="G-partner-type-box">
          <SelectPartnerType
            partnerTypeVal={partnerTypeVal}
            selectChangePartner={(values) => changePartnerType(values)}
          />
        </div>
        <SearchFiled
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          getData={getData}
        />

        <SelectCurrency
          currencyData={data?.currencies}
          currencyVal={currencyVal}
          selectChangeCurrency={(value) => changeCurrency(value)}
        />

        <div className="L-partner-check-account">
          <Checkbox
            value={!viewAccounts}
            onChange={(e) => setViewAccounts(e.target.checked)}
            label="Դիտել հաշիվներով"
          />
        </div>
        <div className="G-view-btn">
          <Button
            text={viewPrintTitle}
            onClick={viewClick}
            disabled={disabledBtn}
            customClass={disabledBtn ? 'G-disabled-button' : ''}
          />
        </div>
      </div>
    </SearchBackground>
  );
};

export default PartnerCirculationViewAccount;
