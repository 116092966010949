import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getRequest } from '../../Api/Api';
import SelectBank from '../../Components/SelectBox/SelectBank';
import { updateInvoiceDateAction } from '../../Redux/invoiceSlices/useAction';
import ExchangeRateFiled from '../../Components/ExchangeRateFiled/ExchangeRateFiled';
import AddNew from '../../Elements/Add/AddNew';
import PartnerBankForm from '../../Pages/Partners/PartnerBank/EventAddForm';

const InvoiceSelectData = ({ mainData, partnerID }) => {
  const { invoice } = mainData || {};
  const dispatch = useDispatch();
  const [partnerBankList, setPartnerBankList] = useState([]);
  const [showBankForm, setShowBankForm] = useState(false);

  useEffect(() => {
    getRequest(`getPartnerBankList?partnerID=${partnerID}`)
      .then((res) => {
        setPartnerBankList(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  }, [partnerID]);

  const onChangeCompanyBank = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    dispatch(
      updateInvoiceDateAction({
        supplierBankAccount: values.value,
        supplierBankName: values.bankName,
      }),
    );
  };

  const onChangePartnerBank = (values, name = null) => {
    if (!values) {
      values = {
        value: '',
      };
    }

    if (name === 'isSelectAccount') {
      dispatch(
        updateInvoiceDateAction({
          buyerBankAccount: values.value,
          buyerBankName: values.bankName,
        }),
      );
    } else {
      dispatch(
        updateInvoiceDateAction({
          buyerBankAccount: values.bankingAccount,
          buyerBankName: values.bankName,
        }),
      );
    }
  };

  const changeExchangeRaterHandler = (e) => {
    const { name, value } = e.target;
    dispatch(updateInvoiceDateAction({ [name]: value }));
  };

  return (
    <>
      <div className="L-invoice-select-data-wrapper">
        <div className="L-invoice-select-data-block G-flex">
          <div className="L-invoice-select-data-box">
            <div className="G-relative">
              <SelectBank
                title="Կազմակերպության հ/հաշիվը"
                requiredFiled={true}
                bankData={mainData?.companyBanks}
                bankVal={{
                  label: invoice?.supplierBankAccount,
                  value: invoice?.supplierBankAccount,
                }}
                selectChangeBank={(value) => onChangeCompanyBank(value)}
              />
            </div>

            <div className="L-invoice-partner-box">
              <SelectBank
                title="Գործընկերոջ հ/հաշիվը"
                bankData={partnerBankList}
                bankVal={{
                  label: invoice?.buyerBankAccount,
                  value: invoice?.buyerBankAccount,
                }}
                selectChangeBank={(value) =>
                  onChangePartnerBank(value, 'isSelectAccount')
                }
              />
              <AddNew
                content="Գործընկերոջ հ/հաշիվը"
                onClick={() => setShowBankForm(partnerID)}
              />
            </div>
          </div>
          <div className="L-invoice-select-data-box">
            <ExchangeRateFiled
              value={invoice?.exchangeRate}
              changeHandler={changeExchangeRaterHandler}
              currencyCode={invoice?.currencyCode}
            />

            <div className="L-invoice-select-value">
              <span>{invoice?.supplierBankName}</span>
            </div>

            <div className="L-invoice-select-value">
              <span>{invoice?.buyerBankName}</span>
            </div>
          </div>
        </div>
      </div>

      {showBankForm && (
        <PartnerBankForm
          closeModal={setShowBankForm}
          customPartnerID={showBankForm}
          addNewBank={true}
          addNewBankAction={setPartnerBankList}
          onAddNewPartnerBank={onChangePartnerBank}
        />
      )}
    </>
  );
};

export default InvoiceSelectData;
