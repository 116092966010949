import React, { useState, useEffect, useMemo } from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import SearchBar from '../../components/searchBar/SearchBar';
import { useDebounce } from '../../../App/Hooks/useDebounce';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import { getGroupOptions } from './optionsUtils';
import MaterialNameExcel from './MaterialNameExcel';

const MaterialNameFilter = ({
  groupsData,
  params,
  setParams,
  getMaterialNameExcelData,
  modifyObj,
}) => {
  const [searchString, setSearchString] = useState(params.searchString);
  const debouncedSearchTerm = useDebounce(searchString, 1500);

  const groupOptions = useMemo(() => getGroupOptions(groupsData), [groupsData]);

  useEffect(() => {
    setParams((prevState) => ({
      ...prevState,
      searchString: debouncedSearchTerm,
    }));
  }, [debouncedSearchTerm, setParams]);

  const handleGroupChange = (fieldName, selectedOption) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    setParams((prevParams) => ({
      ...prevParams,
      [fieldName]: {
        ...prevParams[fieldName],
        value: selectedOption.value,
        id: selectedOption.id,
      },
    }));
  };

  const handleInputChange = (input) => setSearchString(input);

  return (
    <SearchBackground>
      <div className="L-material-val-name-filter-block">
        <div className="L-material-val-name-filter-panel">
          <SelectControl
            selectBoxTitle="ՆԱ խումբը"
            onChange={(selectedOption) =>
              handleGroupChange('groupNo', selectedOption)
            }
            options={groupOptions}
          />
          <SearchBar
            query={searchString}
            setQuery={handleInputChange}
            searchFiledTitle="Անունը"
          />
        </div>

        <MaterialNameExcel
          getMaterialNameExcelData={getMaterialNameExcelData}
          params={modifyObj}
        />
      </div>
    </SearchBackground>
  );
};

export default MaterialNameFilter;
