export const globalActionItems = [
  {
    name: 'Խմբագրել',
    value: 'edit',
  },

  {
    name: 'Ջնջել',
    value: 'delete',
  },
];

export const AdditionsActionItems = (row) => {
  const { systemSign } = row || {};
  const actionList = [
    {
      name: 'Խմբագրել',
      value: 'edit',
    },
  ];

  if (!systemSign) {
    actionList.push({
      name: 'Ջնջել',
      value: 'delete',
    });
  }
  return actionList;
};

export const warehouseEntryActionItems = [
  {
    name: 'Խմբագրել',
    value: 'edit',
  },

  {
    name: 'Ջնջել',
    value: 'delete',
  },
  {
    name: 'Գործառն. կատարում',
    value: 'operationExecution',
  },
  {
    name: 'Գործառնություններ',
    value: 'operations',
  },

  {
    name: 'Տպել',
    value: 'print',
  },
];

export const EInvoiceEntryActionItems = [
  {
    name: 'Նույնականացել',
    value: 'authenticated',
  },

  {
    name: 'Ջնջել',
    value: 'delete',
  },
];
