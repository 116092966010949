import { getRequest, postRequest } from '../../App/Api/Api';

export const fetchWorkCalendar = async (companyID, graphNo) => {
  try {
    const response = await getRequest(
      `getWorkCalendar?companyID=${companyID}&graphNo=${graphNo}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addWorkCalendar = async (workCalendarData) => {
  try {
    const response = await postRequest('saveWorkCalendar', workCalendarData);

    return response.data;
  } catch (error) {
    console.error('Error adding workCalendar:', error);
    throw error;
  }
};

export const fetchUpdateWorkCalendar = async (companyID, graphNo) => {
  try {
    const response = await getRequest(
      `updateWorkCalendar?companyID=${companyID}&graphNo=${graphNo}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
