import { useState, useEffect } from 'react';
import { fetchDepCode } from '../services/fetchSubdivisions';

const useGetWorkDepNo = (companyId) => {
  const [workDepNo, setWorkDepNo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDepCode(companyId);
        setWorkDepNo(response?.toString());
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      setWorkDepNo(null);
    };
  }, [companyId]);

  return { workDepNo };
};

export default useGetWorkDepNo;
