import { createAction } from '../../../actionCreators';
import {
  DELETE_MATERIAL_VALUES_GROUP,
  FETCH_MATERIAL_VALUES_GROUP,
  UPDATE_MATERIAL_VALUES_GROUP,
} from './constants';

export const fetchMaterialValueGroupAction = createAction(
  FETCH_MATERIAL_VALUES_GROUP,
);
export const updateMaterialValueGroupAction = createAction(
  UPDATE_MATERIAL_VALUES_GROUP,
);
export const deleteMaterialValueGroupAction = createAction(
  DELETE_MATERIAL_VALUES_GROUP,
);
