import { FILTER_ROW_ID } from './constants';

const initialState = {
  currentId: 0,
};

export const filterRowDataReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FILTER_ROW_ID:
      return {
        ...state,
        ...(state.currentId, payload),
      };

    default:
      return { ...state };
  }
};
