import React, { useEffect, useState } from 'react';
import Modal from '../../../../App/Components/Modal/Modal';
import AuthenticatedTable from './AuthenticatedTable';
import AuthenticatedHeader from './AuthenticatedHeader';
import AuthenticatedFilter from './AuthenticatedFilter';
import GetSentDataBtn from '../../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import './style.scss';
import useEInvoiceEntry from '../../../hooks/useEInvoiceEntry';
import useIsMounted from '../../../hooks/useIsMounted';
import Alert from '../../../../App/Components/Alert';

const AuthenticatedForm = ({ rowData, handleClose }) => {
  const [searchParam, setSearchParam] = useState({});
  const { description, invGoodID } = rowData || {};
  const isMounted = useIsMounted();
  const {
    getAuthenticated,
    authenticatedData,
    setAuthenticatedData,
    selectMatValueNo,
    setSelectMatValueNo,
    confirmIdentifyProduct,
    errorMess,
    setErrorMess,
  } = useEInvoiceEntry();

  useEffect(() => {
    getAuthenticated(searchParam, description, invGoodID);
  }, [description, searchParam]);

  const handleConfirmIdentifyProduct = async () => {
    try {
      await confirmIdentifyProduct(
        {
          invGoodID: authenticatedData?.invGoodID,
          matValueNo: selectMatValueNo?.matValueNo,
        },
        handleClose,
      );
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <Modal
      closeHandler={handleClose}
      customClass="L-authenticated-form"
      title="Նույնականացում"
    >
      <AuthenticatedHeader
        matValueName={authenticatedData?.matvalueName}
        selectMatValueNo={selectMatValueNo}
        setSelectMatValueNo={setSelectMatValueNo}
        setAuthenticatedData={setAuthenticatedData}
      />
      <AuthenticatedFilter
        prodGroups={authenticatedData?.prodGroups}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
      />
      <AuthenticatedTable
        authenticatedData={authenticatedData?.matValues}
        setSelectMatValueNo={setSelectMatValueNo}
      />
      <GetSentDataBtn
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleConfirmIdentifyProduct}
        executeClass={!selectMatValueNo?.matValueNo ? 'G-disabled-button' : ''}
        executeDisabled={!selectMatValueNo?.matValueNo}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </Modal>
  );
};

export default AuthenticatedForm;
