export const tablesFields = [
  {
    fieldType: 'input',
    type: 'number',
    name: 'graphNo',
    label: 'Կոդը',
    value: '',
    required: true,
    maxLength: 2,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'graphName',
    label: 'Անվանումը',
    value: '',
    required: true,
    maxLength: 40,
  },

  {
    fieldType: 'radio',
    required: true,
    radioTitle: 'Վճարման ձևը',
    radioBtn: [
      {
        label: 'Հաստիք',
        value: 1,
        name: 'formSign',
      },
      {
        label: 'Ժամավճար',
        value: 2,
        name: 'formSign',
      },
    ],
  },
  {
    fieldType: 'radio',
    required: true,
    radioTitle: 'Տեսակը',
    radioBtn: [
      {
        label: 'Հնգօրյա',
        value: 1,
        name: 'sign56',
      },
      {
        label: 'Վեցօրյա',
        value: 2,
        name: 'sign56',
      },
    ],
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'hours',
    label: 'Օրական ժամերի քանակը',
    value: '',
    required: true,
    maxLength: 5,
  },
];
