import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMaterialGroup,
  fetchGroupForm,
  fetchMaterialValueGroups,
  removeMaterialGroup,
  updateMaterialGroup,
} from '../services/fetchMaterialValueGroups';
import {
  deleteMaterialValueGroupAction,
  fetchMaterialValueGroupAction,
  updateMaterialValueGroupAction,
} from '../../App/Redux/materialValuesStor/materialValueGroupSlices/warehouseSlices/useAction';

const useMaterialValueGroups = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [groupFormData, setGroupFormData] = useState(null);
  const { currentCompanyID } = useSelector((state) => state.auth);
  const materialValuesGroup = useSelector(
    (state) => state.materialValuesGroup?.materialValuesGroupData,
  );
  const dispatch = useDispatch();

  const getMaterialValueGroups = async (param = '') => {
    try {
      setIsLoading(true);
      const response = await fetchMaterialValueGroups(currentCompanyID, param);
      dispatch(fetchMaterialValueGroupAction(response));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getGroupForm = async (salaryTypeID) => {
    setIsLoading(true);
    try {
      const data = await fetchGroupForm(currentCompanyID, salaryTypeID);
      setGroupFormData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const addNewMaterialGroup = async (addMaterial, closeFormCallback, param) => {
    try {
      const { errorCode, message } = await addMaterialGroup(addMaterial);

      if (errorCode === 0) {
        getMaterialValueGroups(param);
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const editMaterialGroup = async (updateMaterial, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await updateMaterialGroup(updateMaterial);
      if (errorCode === 0) {
        dispatch(updateMaterialValueGroupAction(updateMaterial));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteMaterialGroup = async (id, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await removeMaterialGroup(id);
      if (errorCode === 0) {
        dispatch(deleteMaterialValueGroupAction(id));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    error,
    errorMess,
    setErrorMess,
    getMaterialValueGroups,
    addNewMaterialGroup,
    editMaterialGroup,
    deleteMaterialGroup,
    getGroupForm,
    groupFormData,
    materialValuesGroup,
  };
};

export default useMaterialValueGroups;
