import React, { useEffect } from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import useEmployeesForm from '../../hooks/useEmployeesForm';
import Alert from '../../../App/Components/Alert';

const DeleteEmployees = ({ id, handleClose }) => {
  const {
    deleteEmployee,
    getIsAllowDelete,
    isAllowDelete,
    errorMess,
    setErrorMess,
  } = useEmployeesForm();

  useEffect(() => {
    getIsAllowDelete(id);
  }, [id]);

  const onRemoveHandler = async () => {
    try {
      await deleteEmployee(id, handleClose);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  const handleAlertClose = () => {
    setErrorMess('');
    handleClose();
  };

  return (
    <>
      {!isAllowDelete && (
        <Alert description={errorMess} onClose={handleAlertClose} />
      )}

      {isAllowDelete && (
        <AcceptOrCancelModal
          closeModal={handleClose}
          confirmClick={onRemoveHandler}
        />
      )}
    </>
  );
};

export default DeleteEmployees;
