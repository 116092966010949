import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import { dataTimeFormat } from '../../Utilities/Utilities';
import InputFiled from '../InputFiled/InputFiled';

const DocNumFiled = memo(
  ({
    docTypeID,
    docDate,
    value,
    setFieldValue,
    handleChangeDocNum,
    addEvent = false,
  }) => {
    const { currentCompanyID } = useSelector((state) => state.auth);
    useEffect(() => {
      if (addEvent && docTypeID) {
        getRequest(
          `getDocNum?companyID=${currentCompanyID}&docTypeID=${docTypeID}&docDate=${dataTimeFormat(
            docDate,
          )}`,
        )
          .then((res) => {
            setFieldValue('docNum', res.data);
          })
          .catch((err) => {
            console.log('Err' + err);
          });
      }
    }, [currentCompanyID, docTypeID, docDate, addEvent, setFieldValue]);

    return (
      <div className="G-docNum-input  G-relative">
        <InputFiled
          type="number"
          name="docNum"
          value={value}
          requiredFiled={true}
          inputTitle="Օրդեր N"
          changeHandler={handleChangeDocNum}
        />
      </div>
    );
  },
);

export default DocNumFiled;
