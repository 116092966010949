import { getRequest, postRequest, putRequest } from '../Api/Api';

export const fetchOrganizationDetails = async (companyID) => {
  try {
    let url = `getCompany/${companyID}`;
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addOrganizationDetails = async (newOrganization) => {
  try {
    const response = await postRequest('', {
      ...newOrganization,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding newOrganization:', error);
    throw error;
  }
};

export const updateOrganizationDetails = async (updateOrganization) => {
  try {
    const response = await putRequest('editCompany', updateOrganization);
    return response.data;
  } catch (error) {
    console.error('Error updating updateOrganization:', error);
    throw error;
  }
};
