import React from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { ExportExcel } from '../../../App/Components/Excelexport/Excelexport';

const ExcelForm = ({ dataToExcel }) => {
  return (
    <SearchBackground>
      <div className="G-flex-justify-end">
        <ExportExcel excelData={dataToExcel} fileName="" />
      </div>
    </SearchBackground>
  );
};

export default ExcelForm;
