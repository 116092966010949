import React from 'react';
import Table from '../../../App/Components/Table/Table';
import { Commas, dateFormat } from '../../../App/Utilities/Utilities';

const VacationStory = ({ vacationStory }) => {
  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.salaryDate),
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Հավելում',
      cell: (row) => row.stName,
      customStyle: { maxWidth: 220, overflow: 'hidden' },
    },
    {
      title: 'Ժամ. սկ',
      cell: (row) => dateFormat(row.periodStart),
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ժամ. վերջ',
      cell: (row) => dateFormat(row.periodEnd),
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Սկիզբը',
      cell: (row) => dateFormat(row.startDate),
      customStyle: { maxWidth: 90 },
    },

    {
      title: 'Վերջը',
      cell: (row) => dateFormat(row.endDate),
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Օրեր',
      cell: (row) => row.days,
      customStyle: { maxWidth: 60 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 140 },
    },
  ];
  return (
    <div>
      {vacationStory !== null && vacationStory?.length ? (
        <Table
          customClass="L-vacation-story-table G-table-has-scroll"
          data={vacationStory}
          columnConfig={columnConfig}
        />
      ) : null}
    </div>
  );
};

export default VacationStory;
