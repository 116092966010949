import React from 'react';

const tabs = {
  1: 'Հաշվարկված',
  2: 'Մաքուր',
};

const SalaryCalcTab = ({ handleTabChange, activeTab }) => {
  return (
    <div className="L-salaryCalc-tab">
      {Object.entries(tabs).map(([tab, label]) => (
        <button
          key={tab}
          onClick={() => handleTabChange(tab)}
          className={Number(tab) === activeTab ? 'tab-btn-isActive' : 'tab-btn'}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default SalaryCalcTab;
