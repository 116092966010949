import {
  DELETE_UNIT_OF_MEASURE,
  EDIT_UNIT_OF_MEASURE,
  GET_UNIT_OF_MEASURE,
  LOADING,
} from "./constants";

const initialState = {
  unitOfMeasureData: null,
  isLoading: false,
};

export const unitOfMeasureReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_UNIT_OF_MEASURE:
      return {
        ...state,
        unitOfMeasureData: payload,
        isLoading: true,
      };

    case EDIT_UNIT_OF_MEASURE:
      return {
        ...state,
        unitOfMeasureData: {
          ...state.unitOfMeasureData,
          units: state.unitOfMeasureData.units.map((unit) => {
            return unit.unitID === payload.unitID ? payload : unit;
          }),
        },
      };
    case DELETE_UNIT_OF_MEASURE:
      return {
        ...state,
        unitOfMeasureData: {
          ...state.unitOfMeasureData,
          units: state.unitOfMeasureData?.units.filter(
            (item) => item.unitID !== payload
          ),
        },
      };
    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return { ...state };
  }
};
