import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fullcalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import hyAmLocale from '@fullcalendar/core/locales/hy-am';
import './Calendar.scss';
import { useState } from 'react';
import EventAddForm from './EventAddForm';
import YearView from '../YearView/YearView';
import MonthView from '../YearView/MonthView';
import EventEditForm from './EventEditForm';
import './CalendarCustomStyle.scss';
import {
  customTitle,
  dayHeaderContent,
  eventContent,
  dayPopoverFormat,
  handleEventAllow,
} from './CustomMethods';
import { updateEventDropAction } from '../../App/Redux/calendarSlices/useAction';
import { getRequest, putRequest } from '../../App/Api/Api';

const Calendar = ({ fetchCalendarData }) => {
  const { calendarData } = useSelector((state) => state.getCalendarData);
  const [view, setView] = useState('timeGridWeek');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [isAllowEditable, setIsAllowEditable] = useState(false);
  const calendarRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();

    const updateMonthAndYear = () => {
      const currentView = calendarApi.view;
      if (currentView) {
        const currentDate = currentView.currentStart;
        const newMonth = currentDate.getMonth() + 1;
        const newYear = currentDate.getFullYear();
        setSelectedMonth(newMonth);
        setSelectedYear(newYear);
      }
    };

    calendarApi.on('datesSet', updateMonthAndYear);

    return () => {
      calendarApi.off('datesSet', updateMonthAndYear);
    };
  }, []);

  const handleMonthChange = (selectedOption) => {
    if (!selectedOption) {
      selectedOption = {
        value: new Date().getMonth() + 1,
      };
    }
    setSelectedMonth(selectedOption.value);
    const newDate = new Date(selectedYear, selectedOption.value - 1, 1);
    if (calendarRef.current && calendarRef.current.getApi()) {
      calendarRef.current.getApi().gotoDate(newDate);
    }
  };
  const handleYearChange = (selectedOption) => {
    const currentYear = new Date().getFullYear();
    if (!selectedOption) {
      selectedOption = {
        value: currentYear,
      };
    }

    setSelectedYear(selectedOption.value);
    const newDate = new Date(selectedOption.value, selectedMonth - 1, 1);
    if (calendarRef.current && calendarRef.current.getApi()) {
      calendarRef.current.getApi().gotoDate(newDate);
    }
  };

  const handleAddEvent = (info) => {
    const clickedDate = info.date;
    const clickedTime = clickedDate.toLocaleTimeString();

    const infoObj = {
      date: info.date,
      time: clickedTime?.includes('12:00:00') ? new Date() : clickedTime,
    };
    setShowAddModal(infoObj);
  };

  const handleEventClick = ({ event }) => {
    setShowEditModal(event.id);
  };

  const handleEventDrop = (info) => {
    const isAllow = info.event.extendedProps.calId;

    const eventData = {
      id: Number(info.event.id),
      date: info.event.startStr,
    };
    if (!isAllow) {
      getRequest(`getCalendarEventForEdit/${eventData.id}`)
        .then((res) => {
          dispatch(
            updateEventDropAction({ ...res.data, date: eventData.date }),
          );

          putRequest('editCalendarEvent', {
            ...res.data,
            date: eventData.date,
          })
            .then(() => {})
            .catch((err) => {
              console.error('PUT request error:', err);
            });
        })
        .catch((err) => {
          console.error('GET request error:', err);
        });
    }
  };

  return (
    <>
      <div className="L-year-and-mont-block G-flex">
        <YearView
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          handleYearChange={handleYearChange}
        />
        <MonthView
          handleMonthChange={handleMonthChange}
          selectedMonth={selectedMonth}
        />
      </div>
      <div className="full-calendar-block">
        <div style={{ marginTop: '20px' }}>
          <Fullcalendar
            ref={calendarRef}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: 'today prev,next',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
            }}
            initialView="dayGridMonth"
            initialDate={new Date()}
            view={view}
            onView={(newView) => setView(newView)}
            allDayText=""
            views={{
              listMonth: {
                buttonText: 'Ըստ օրերի',
              },
            }}
            //hiddenDays={[0]}
            events={calendarData}
            titleFormat={customTitle}
            dayHeaderFormat={{
              weekday: 'short',
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            }}
            dayHeaderContent={dayHeaderContent}
            locale={hyAmLocale}
            editable={true}
            eventAllow={handleEventAllow}
            eventDrop={handleEventDrop}
            selectable={true}
            selectMirror={true}
            weekends={true}
            dayMaxEvents={3}
            height={'80vh'}
            dateClick={(info) => handleAddEvent(info)}
            eventClick={(calEvent) => handleEventClick(calEvent)}
            eventContent={eventContent}
            dayPopoverFormat={dayPopoverFormat}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: false,
            }}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: false,
            }}
            minTime={'00:00:00'}
            maxTime={'24:00:00'}
            slotDuration={'00:60:00'}
          />
        </div>
        {showAddModal && (
          <EventAddForm
            info={showAddModal}
            closeHandler={setShowAddModal}
            fetchCalendarData={fetchCalendarData}
          />
        )}

        {showEditModal && (
          <EventEditForm
            eventId={showEditModal}
            closeHandler={setShowEditModal}
            fetchCalendarData={fetchCalendarData}
          />
        )}
      </div>
    </>
  );
};

export default Calendar;
