import {
  DELETE_INVOICE,
  GET_INVOICE_DATA,
  LOADING,
  GET_INVOICE_FORM_DATA,
  DELETE_INVOICE_GOODS,
  ADD_INVOICE_GOODS,
  UPDATE_INVOICE_DATA,
  UPDATE_INVOICE_TABLE_DATA,
  UPDATE_PARTNER_INVOICE,
  ADD_PRODUCT,
  ADD_UNIT_OF_MEASURE,
} from './constants';

const initialState = {
  invoiceData: null,
  invoiceFormData: null,
};

export const invoiceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_INVOICE_DATA:
      return {
        ...state,
        invoiceData: payload,
        isLoading: true,
      };

    case DELETE_INVOICE:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          invoices: state.invoiceData?.invoices.filter(
            (item) => item.invoiceID !== payload,
          ),
        },
      };

    case UPDATE_PARTNER_INVOICE:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          partners: [...state.invoiceData.partners, ...payload],
        },
      };

    case ADD_PRODUCT:
      return {
        ...state,
        invoiceFormData: {
          ...state.invoiceFormData,
          descriptions: [...state.invoiceFormData.descriptions, ...payload],
        },
      };

    case ADD_UNIT_OF_MEASURE:
      return {
        ...state,
        invoiceFormData: {
          ...state.invoiceFormData,
          units: [...state.invoiceFormData.units, ...payload],
        },
      };

    case GET_INVOICE_FORM_DATA:
      return {
        ...state,
        invoiceFormData: payload,
      };

    case ADD_INVOICE_GOODS:
      return {
        ...state,
        invoiceFormData: {
          ...state.invoiceFormData,
          invoiceGoods: [...state.invoiceFormData.invoiceGoods, payload],
        },
      };

    case DELETE_INVOICE_GOODS:
      return {
        ...state,
        invoiceFormData: {
          ...state.invoiceFormData,
          invoiceGoods: state.invoiceFormData?.invoiceGoods.filter(
            (item) => item.goodID !== payload,
          ),
        },
      };

    case UPDATE_INVOICE_DATA:
      return {
        ...state,
        invoiceFormData: {
          ...state.invoiceFormData,
          invoice: { ...state.invoiceFormData.invoice, ...payload },
        },
      };

    case UPDATE_INVOICE_TABLE_DATA:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          invoices: state.invoiceData.invoices.map((item) => {
            return item.invoiceID === payload.invoiceID
              ? {
                  ...item,
                  serialNumber: payload.serialNumber,
                  invoiceDate: payload.invoiceDate,
                  prePayment: payload.prePayment,
                  prePaymentAMD: payload.prePaymentAMD,
                }
              : item;
          }),
        },
      };
    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return { ...state };
  }
};
