import React from 'react';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
import useUnitsMeasure from '../../hooks/useUnitsMeasure';
import MaterialUnitsMeasureForm from './MaterialUnitsMeasureForm';

const EventEditForm = ({ handleClose, rowData }) => {
  const { updateCurrUnitsMeasure, errorMess, setErrorMess } = useUnitsMeasure();
  const isMounted = useIsMounted();
  const onUpdateHandler = async (values) => {
    try {
      await updateCurrUnitsMeasure(values, handleClose);
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <MaterialUnitsMeasureForm
        initialValues={rowData}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventEditForm;
