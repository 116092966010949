import { useEffect, useState } from 'react';
import {
  addWorkCalendar,
  fetchUpdateWorkCalendar,
  fetchWorkCalendar,
} from '../services/fetchWorkCalendar';
import { useSelector } from 'react-redux';

const useWorkCalendar = (graphNo) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [workCalendarData, setWorkCalendarData] = useState([]);

  const getWorkCalendar = async () => {
    if (!graphNo) {
      return;
    }
    try {
      const response = await fetchWorkCalendar(currentCompanyID, graphNo);
      setWorkCalendarData(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateWorkCalendar = async () => {
    try {
      const response = await fetchUpdateWorkCalendar(currentCompanyID, graphNo);

      if (response) {
        setWorkCalendarData(response);
      }
    } catch (error) {
      console.error('Error updating work calendar:', error);
    }
  };

  const addNewWorkCalendar = async (workCalendar, handleClose) => {
    try {
      const { errorCode } = await addWorkCalendar(workCalendar);
      if (errorCode === 0) {
        handleClose();
      }
    } catch (error) {
      console.error('Error adding new work calendar:', error);
    }
  };
  useEffect(() => {
    return () => {
      setWorkCalendarData(null);
    };
  }, []);

  return {
    workCalendarData,
    getWorkCalendar,
    setWorkCalendarData,
    addNewWorkCalendar,
    updateWorkCalendar,
  };
};

export default useWorkCalendar;
