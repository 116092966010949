import React from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import useSubdivisions from '../../hooks/useSubdivisions';
import Alert from '../../../App/Components/Alert';

const DeleteSubdivision = ({ id, handleClose }) => {
  const { removeSubdivisionsItem, errorMess, setErrorMess } = useSubdivisions();
  const onRemoveHandler = async () => {
    try {
      await removeSubdivisionsItem(id, handleClose);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />

      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default DeleteSubdivision;
