import React, { useState } from 'react';
import { numbering, tableActionTitle } from '../../../App/Utilities/Utilities';
import Action from '../../../App/Components/Table/Action';
import { globalActionItems } from '../../Config/tableConfig';
import Table from '../../../App/Components/Table/Table';
import Loading from '../../../App/Components/Loading/Loading';
import Forms from './Forms';

const UnitsMeasureTable = ({ unitOfMeasureData, isLoading }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: numbering,
      cell: (row) => row.sequenceNumber,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Կարճ անվանումը',
      cell: (row) => row.unitCode,
      customStyle: { maxWidth: 400, overflow: 'hidden' },
    },

    {
      title: 'Անվանումը',
      cell: (row) => row.unitName,
      customStyle: { maxWidth: 200, overflow: 'hidden' },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(type, row) =>
            setShowForm({
              type: type,
              value: row,
            })
          }
          actionItem={globalActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {unitOfMeasureData !== null &&
      unitOfMeasureData &&
      unitOfMeasureData?.length ? (
        <Table
          customClass=""
          data={unitOfMeasureData}
          columnConfig={columnConfig}
        />
      ) : null}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default UnitsMeasureTable;
