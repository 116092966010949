import React from 'react';
import './incomeExpenseSummary.scss';
import { Commas } from '../../Utilities/Utilities';

const IncomeExpenseSummary = ({ summaryData }) => {
  const summaryTitle = ['Եկամուտ', 'Ծախս', 'Տարբերություն'];
  return (
    <div className="income-expense-summary-block">
      <div className="income-expense-summary-title">
        {summaryTitle?.map((title) => {
          return <h4 key={title}>{title}</h4>;
        })}
      </div>
      <div className="income-expense-summary-list">
        {summaryData?.map(({ val, ek, ta, bl }, index) => {
          return (
            <div key={index} className="income-expense-summary-item">
              <strong style={{ color: 'green' }}>{Commas(ek)}</strong>
              <strong style={{ color: 'red' }}>{Commas(ta)}</strong>
              {bl >= 0 ? (
                <strong style={{ color: 'green' }}>{Commas(bl)}</strong>
              ) : (
                <strong style={{ color: 'red' }}>{Commas(bl)}</strong>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IncomeExpenseSummary;
