export const globalActionItems = [
  {
    name: 'Խմբագրել',
    value: 'edit',
  },

  {
    name: 'Ջնջել',
    value: 'delete',
  },
];

export const tablesActionItems = (row) => {
  const { systemSign } = row || {};
  const actionList = [
    {
      name: 'Աշխատ. օրացույց',
      value: 'workCalendar',
    },
    {
      name: 'Խմբագրել',
      value: 'edit',
    },
  ];

  if (!systemSign) {
    actionList.push({
      name: 'Ջնջել',
      value: 'delete',
    });
  }
  return actionList;
};

export const savingsActionItems = (row) => {
  const { systemSign } = row || {};
  const actionList = [
    {
      name: 'Խմբագրել',
      value: 'edit',
    },
  ];

  if (!systemSign) {
    actionList.push({
      name: 'Ջնջել',
      value: 'delete',
    });
  }
  return actionList;
};

export const AdditionsActionItems = (row) => {
  const { systemSign } = row || {};
  const actionList = [
    {
      name: 'Խմբագրել',
      value: 'edit',
    },
  ];

  if (!systemSign) {
    actionList.push({
      name: 'Ջնջել',
      value: 'delete',
    });
  }
  return actionList;
};

export const workWithEmployeesActionItem = [
  {
    name: 'Նշանակել հաստիք',
    value: 'assignPosition',
  },
  {
    name: 'Հաստատել հաստիք',
    value: 'confirmPosition',
  },
  {
    name: 'Նշանակել հավելում/պահում ',
    value: 'assignSupplement',
  },

  {
    name: 'Կատարել հավելում/պահում ',
    value: 'makeAdditionalSave',
  },
  {
    name: 'Աշխատավարձի հաշվարկ',
    value: 'salaryCalculation',
  },
  {
    name: 'Արձակուրդային հաշվարկ',
    value: 'vacationCalculation',
  },
  {
    name: 'Նպաստի հաշվարկ',
    value: 'benefitCalculation',
  },
  {
    name: 'Հաշվարկային թերթիկ',
    value: 'calculationSheet',
  },
  {
    name: 'Աշխատակցի տվյալների խմբագրում',
    value: 'editingEmployeeData',
  },
];
