import React, { useState } from 'react';
import './modal.scss';
import ModalBackground from '../ModalBackground/ModalBackground';
import Close from '../Close/Close';
import Draggable from 'react-draggable';

const Modal = ({
  children,
  customClass = '',
  closeHandler,
  showCloseIcon = true,
  onDrag = false,
  title,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDrag = () => {
    setIsDragging(true);
  };

  const handleDragStop = () => {
    setIsDragging(false);
  };

  const renderModalContent = () => {
    if (onDrag) {
      return (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Draggable
            bounds="parent"
            onDrag={handleDrag}
            onStop={handleDragStop}
            handle=".DraggableHooks-handle"
          >
            <div className={`G-modal-block ${customClass}`}>
              <div className="G-modal-header">
                {showCloseIcon && <Close onClick={closeHandler} />}

                <div className="DraggableHooks-handle move"></div>
                {title && <h3 className="G-modal-title">{title}</h3>}
              </div>

              <div
                className={`G-modal-body ${
                  isSmallWindow() ? 'scrollable' : ''
                }`}
              >
                {children}
              </div>
            </div>
          </Draggable>
        </div>
      );
    }

    return (
      <div
        className={`G-modal-block ${customClass}`}
        style={!isDragging ? { left: '50%', top: '50%' } : null}
      >
        <div className="G-modal-header">
          {showCloseIcon && <Close onClick={closeHandler} />}
          {title && <h3 className="G-modal-title">{title}</h3>}
        </div>

        <div className={`G-modal-body ${isSmallWindow() ? 'scrollable' : ''}`}>
          {children}
        </div>
      </div>
    );
  };

  return <ModalBackground>{renderModalContent()}</ModalBackground>;
};

export default Modal;

const isSmallWindow = () => {
  return window.innerWidth > 720;
};
