import React from "react";
import { Commas } from '../../Utilities/Utilities';
import './index.scss';

const TAccountBalance = ({ children, mainData }) => {
  const {
    currencyName,
    debitInitialAmount,
    creditInitialAmount,
    debitFinalAmount,
    creditFinalAmount,
  } = mainData || {};

  return (
    <div className="G-T-account-wrapper">
      <div className="G-t-account-initial-balance G-t-account-balance">
        <h4>Արժույթ</h4>
        <span>{currencyName}</span>
        <p>Սկզբնական մնացորդ</p>
        <h4>Դեբետ</h4>
        <span>{Commas(debitInitialAmount)}</span>
        <h4>Կրեդիտ</h4>
        <span>{Commas(creditInitialAmount)}</span>
      </div>
      {children}
      <div className="G-t-account-final-balance G-t-account-balance ">
        <p>Վերջնական մնացորդ</p>
        <h4>Դեբետ</h4>
        <span>{Commas(debitFinalAmount)}</span>
        <h4>Կրեդիտ</h4>
        <span>{Commas(creditFinalAmount)}</span>
      </div>
    </div>
  );
};

export default TAccountBalance;
