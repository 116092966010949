import React, { useEffect } from 'react';
import Modal from '../../../../App/Components/Modal/Modal';
import EntryOrderFormationTable from './EntryOrderFormationTable';
import EntryOrderFormationDetale from './EntryOrderFormationDetale';
import useEInvoiceEntry from '../../../hooks/useEInvoiceEntry';
import './style.scss';
import { useFormik } from 'formik';
import GetSentDataBtn from '../../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import useWarehouseEntry from '../../../hooks/useWarehouseEntry';
import { entryOrderFormationValidation } from '../../../../App/Validation/WarehouseEntryValidation';
const EntryOrderFormation = ({ handleClose, partnerID }) => {
  const { getEntryOrderFormation, entryOrderFormation, saveInvoiceEntryOrder } =
    useEInvoiceEntry();
  const { getWarehouseEntryDocNum } = useWarehouseEntry();

  useEffect(() => {
    getEntryOrderFormation(partnerID);
  }, [partnerID]);

  const {
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: { ...entryOrderFormation?.matInput },
    validationSchema: entryOrderFormationValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
    },
  });

  const commonProps = {
    entryOrderFormation,
    getWarehouseEntryDocNum,
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    handleClose,
  };
  const onSubmit = async (values, resetForm) => {
    const modifiedData = {
      matInput: {
        ...values,
        docNum: Number(values.docNum),
      },
      inputs: entryOrderFormation?.inputs,
    };
    try {
      await saveInvoiceEntryOrder(modifiedData, handleClose);
      // if (errorMess) {
      //   resetForm();
      // }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Modal
      closeHandler={handleClose}
      customClass="L-entry-order-formation"
      title=""
    >
      <EntryOrderFormationDetale {...commonProps} />
      <EntryOrderFormationTable
        entryOrderFormation={entryOrderFormation?.inputs}
      />

      <GetSentDataBtn
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleSubmit}
        executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
        executeDisabled={!dirty || !isValid}
      />
    </Modal>
  );
};

export default EntryOrderFormation;
