import React, { useCallback, useMemo } from 'react';
import InitialFinalBalanceTitle from '../../Components/InitialFinalBalanceTitle/InitialFinalBalanceTitle';
import Table from '../../Components/Table/Table';
import { TotalList } from '../../Components/TotalList/TotalList';
import { Commas, getTotalCount } from '../../Utilities/Utilities';

const ViewSummaryTable = ({ mainData, calculationType }) => {
  const useCalculationType = calculationType === 0;

  const columnConfigCalcTypeOne = useMemo(
    () => [
      {
        title: 'Դեբետ հաշիվ',
        cell: (row) => row.accountCode,
        customStyle: { width: 150 },
      },
      {
        title: 'Հաշվի անվանումը',
        cell: (row) => row.accountName,
        customStyle: { maxWidth: 450 },
      },

      {
        title: 'Կրեդիտ հաշիվ',
        cell: (row) => row.creditAccountCode,
        customStyle: { width: 150 },
      },
      {
        title: 'Հաշվի անվանումը',
        cell: (row) => row.creditAccountName,
        customStyle: { width: 450, overflow: 'hidden' },
      },
      {
        title: 'Գումարը',
        cell: (row) => Commas(row.calculationDebit),
        customStyle: { width: 150, justifyContent: 'end' },
      },
    ],
    [calculationType],
  );

  const columnConfig = useMemo(
    () => [
      {
        title: 'Հաշիվ',
        cell: (row) => row.accountCode,
        customStyle: { width: 100 },
      },
      {
        title: 'Անվանում',
        cell: (row) => row.accountName,
        customStyle: { maxWidth: 400 },
      },

      {
        title: 'Դեբետային',
        cell: (row) => Commas(row.initialDebit),
        customStyle: { width: 140 },
      },
      {
        title: 'Կրեդիտային',
        cell: (row) => Commas(row.initialCredit),
        customStyle: { width: 140 },
      },

      {
        title: 'Դեբետային',
        cell: (row) => Commas(row.calculationDebit),
        customStyle: { width: 140 },
      },
      {
        title: 'Կրեդիտային',
        cell: (row) => Commas(row.calculationCredit),
        customStyle: { width: 140 },
      },

      {
        title: 'Դեբետային',
        cell: (row) => Commas(row.finalDebit),
        customStyle: { width: 140 },
      },
      {
        title: 'Կրեդիտային',
        cell: (row) => Commas(row.finalCredit),
        customStyle: { width: 140 },
      },
    ],
    [calculationType],
  );

  const getTotalCountMemoized = useMemo(() => getTotalCount, []);

  const initialDebitTotal = useMemo(
    () => getTotalCountMemoized(mainData, 'initialDebit'),
    [mainData, getTotalCountMemoized],
  );

  const initialCreditTotal = useMemo(
    () => getTotalCountMemoized(mainData, 'initialCredit'),
    [mainData, getTotalCountMemoized],
  );

  const calculationDebitTotal = useMemo(
    () => getTotalCountMemoized(mainData, 'calculationDebit'),
    [mainData, getTotalCountMemoized],
  );

  const calculationCreditTotal = useMemo(
    () => getTotalCountMemoized(mainData, 'calculationCredit'),
    [mainData, getTotalCountMemoized],
  );

  const finalDebitTotal = useMemo(
    () => getTotalCountMemoized(mainData, 'finalDebit'),
    [mainData, getTotalCountMemoized],
  );

  const finalCreditTotal = useMemo(
    () => getTotalCountMemoized(mainData, 'finalCredit'),
    [mainData, getTotalCountMemoized],
  );

  const totalList = useMemo(
    () => [
      ...(useCalculationType
        ? [Commas(initialDebitTotal), Commas(initialCreditTotal)]
        : []),
      Commas(calculationDebitTotal),

      ...(useCalculationType ? [Commas(calculationCreditTotal)] : []),

      ...(useCalculationType
        ? [Commas(finalDebitTotal), Commas(finalCreditTotal)]
        : []),
    ],
    [
      calculationCreditTotal,
      calculationDebitTotal,
      finalCreditTotal,
      finalDebitTotal,
      initialCreditTotal,
      initialDebitTotal,
      useCalculationType,
    ],
  );

  const renderTable = useCallback(() => {
    if (mainData !== null && mainData && mainData?.length) {
      return (
        <Table
          customClass={`G-table-has-scroll ${
            useCalculationType ? 'L-circulation-system' : 'L-summary-table'
          }`}
          data={mainData}
          columnConfig={
            useCalculationType ? columnConfig : columnConfigCalcTypeOne
          }
        />
      );
    }
    return null;
  }, [mainData, useCalculationType, columnConfig, columnConfigCalcTypeOne]);

  return (
    <>
      <InitialFinalBalanceTitle showTitle={useCalculationType} />
      {renderTable()}
      <TotalList arr={totalList} />
    </>
  );
};

export default ViewSummaryTable;
