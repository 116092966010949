import React, { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import Action from "../../Components/Table/Action";
import Table from "../../Components/Table/Table";
import Tooltip from "../../Components/Tooltip/Tooltip";
import { EDPList } from "../../OptionsList/OptionsList";
import {
  checkBeforeDelete,
  Commas,
  dateFormat,
  MAIN_URL,
  printHandler,
} from '../../Utilities/Utilities';
import DeleteInvoice from './DeleteInvoice';
import EditInvoiceWrapper from './EditInvoiceWrapper';
import { useDispatch } from 'react-redux';
import { DocumentN, DocumentNumber } from '../../GlobalTitle/GlobalTitle';

const InvoiceTable = ({ mainData, loading, setErrorHandler }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const handelChange = (value, row) => {
    switch (value) {
      case 'delete':
        return openDeleteForm(row?.invoiceID);
      case 'edit':
        return openEditForm(row);
      case 'print':
        return handlePrint(row?.invoiceID);
      default:
        return null;
    }
  };

  const columnConfig = [
    {
      title: <Tooltip content="Մատակարարման ա/թ">Մատ.ա/թ</Tooltip>,
      cell: (row) => dateFormat(row.invoiceDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: <Tooltip content={DocumentNumber}>{DocumentN}</Tooltip>,
      cell: (row) => row.serialNumber,
      customStyle: { maxWidth: 110 },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => row.buyerName,
      customStyle: { maxWidth: 800, overflow: 'hidden' },
    },
    {
      title: 'Արժույթը',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Գումար',
      cell: (row) => Commas(row.totalAmount),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Գումար AMD',
      cell: (row) => Commas(row.totalAmountAMD),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Կանխավճար',
      cell: (row) => Commas(row.prePayment),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Կանխավճար AMD',
      cell: (row) => Commas(row.prePaymentAMD),
      customStyle: { maxWidth: 150 },
    },

    {
      title: 'Գործողություն',
      cell: (row) => (
        <Action
          row={row}
          handelChange={handelChange}
          actionItem={EDPList}
          modalIsOpen={openEditModal || openDeleteModal}
        />
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  const openEditForm = (rowData) => {
    const { invoiceID, partnerID, currencyCode } = rowData;

    setOpenEditModal({ partnerID, invoiceID, currencyCode });
  };

  const openDeleteForm = (invoiceID) => {
    checkBeforeDelete(`letDeleteInvoice?invoiceID=${invoiceID}`).then((res) => {
      if (res.errorCode === 0) {
        setOpenDeleteModal(invoiceID);
      }
      if (res.errorCode > 0) {
        setErrorHandler(res.message);
      }
    });
  };

  const handlePrint = (invoiceID) => {
    printHandler(`printInvoiceReport?InvoiceID=${invoiceID}`, dispatch);
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-invoice-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {openDeleteModal && (
        <DeleteInvoice
          closeModal={setOpenDeleteModal}
          deleteRowId={openDeleteModal}
          setErrorHandler={setErrorHandler}
        />
      )}

      {openEditModal && (
        <EditInvoiceWrapper
          closeModal={setOpenEditModal}
          rowData={openEditModal}
          setErrorHandler={setErrorHandler}
        />
      )}
    </>
  );
};

export default InvoiceTable;
