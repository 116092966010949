export const positionFields = [
  {
    fieldType: 'input',
    type: 'text',
    name: 'positionNo',
    label: 'Կոդը',
    value: '',
    required: true,
    maxLength: 2,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'positionName',
    label: 'Անվանումը',
    value: '',
    required: true,
    maxLength: 100,
  },
];
