import React from "react";
import Table from "../../Components/Table/Table";
import Loading from "../../Components/Loading/Loading";
import { Commas } from "../../Utilities/Utilities";

const AccountStatementTable = ({ loading, mainData }) => {
  const columnConfig = [
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountCode,
      customStyle: { maxWidth: 140 },
    },
    {
      title: 'Հաշվի անվանում',
      cell: (row) => row.accountName,
      customStyle: { maxWidth: 1300, overflow: 'hidden' },
    },
    {
      title: 'Դեբետ',
      cell: (row) => Commas(row.debitAmount),
      customStyle: { maxWidth: 180 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => Commas(row.creditAmount),
      customStyle: { maxWidth: 180 },
    },
  ];

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-account-statement-table G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AccountStatementTable;
