import React from 'react';

import './style.scss';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';

const DeleteMsg = ({ modalContent = '', setShowModal, deleteItem }) => {
  return (
    <Modal closeModal={setShowModal} customClass="accept-or-cancel-modal">
      <div className="accept-or-cancel-content">
        <p>{modalContent}</p>
        <div className="accept-or-cancel-btn-block">
          <Button onClick={deleteItem} text="Այո" />

          <Button onClick={() => setShowModal(false)} text="Ոչ" />
        </div>
      </div>
    </Modal>
  );
};
export default DeleteMsg;
