import { getRequest, postRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';
export const fetchMakeAdditionalSave = async (companyID, empNo) => {
  try {
    const response = await getRequest(
      `getMakeSalary?companyID=${companyID}&empNo=${empNo}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchIsExecuteMakeAddition = async (companyID, query) => {
  if (!query.empNo || !query.salaryDate) {
    return;
  }
  const queryRoot = urlEncodeQueryParams(query);
  try {
    const response = await getRequest(
      `getEmployeesForMakeSalary?companyID=${companyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const saveMakeAddition = async (data) => {
  try {
    const response = await postRequest('saveMakeSalaryEntries ', data);

    return response.data;
  } catch (error) {
    console.error('Error adding saveMakeAnAddition:', error);
    throw error;
  }
};
