export const getYeasOptions = (years) => {
  return (
    years?.map(({ year }) => ({
      label: year,
      value: year,
    })) || []
  );
};

export const getMonthOptions = (mont) => {
  return (
    mont?.map(({ salaryMonthName, month, year, yearMonth }) => ({
      label: salaryMonthName,
      value: salaryMonthName,
      month: month,
      year: year,
      yearMonth: yearMonth,
    })) || []
  );
};
