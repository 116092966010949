import React, { useState } from 'react';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import {
  correctDate,
  executeText,
  inCorrectDateMess,
  viewPrintTitle,
} from '../../Utilities/Utilities';
import CalendarTime from '../../Components/Calendar/CalendarTime';
import SelectCurrency from '../../Components/SelectBox/SelectCurrency';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import SelectDocTypes from '../../Components/SelectBox/SelectDocTypes';
import { useFetch } from '../../Hooks/useFetch';
import Alert from '../../Components/Alert';
import Button from '../../Components/Button/Button';
import SearchFiled from '../../Components/SearchFiled/SearchFiled';
import { ExportExcel } from '../../Components/Excelexport/Excelexport';
import ConvertDataExcel from './ConvertDataExcel';

const TransactionLogAction = (props) => {
  const {
    printHandler,
    searchValue,
    setSearchValue,
    mainData,
    currencyVal,
    setCurrencyVal,
    dateStart,
    setDateStart,
    dateEnd,
    setDateEnd,
    docTypesVal,
    setDocTypesVal,
  } = props;
  const { data } = useFetchCurrency();

  const [docTypes] = useFetch('getDocTypesForBook');
  const [errorHandler, setErrorHandler] = useState('');
  const changeCurrency = (value) => {
    if (!value) {
      value = {
        value: '',
        id: 0,
      };
    }
    setCurrencyVal(value);
  };

  const onChangeDocType = (value) => {
    if (!value) {
      value = {
        value: '',
        id: 0,
      };
    }
    setDocTypesVal(value);
  };

  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      setErrorHandler(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };
  const dataForExcel = mainData ? ConvertDataExcel(mainData) : null;
  return (
    <div className="G-book-main-block G-flex-justify-between L-transaction-log-action">
      <CalendarTime
        dateStart={dateStart}
        setDateStart={setDateStart}
        dateEnd={dateEnd}
        setDateEnd={setDateEnd}
        onChangeHandler={timeChangeHandler}
      />
      <SelectDocTypes
        docTypesData={docTypes}
        docTypesVal={docTypesVal}
        selectChangeDocType={onChangeDocType}
      />
      <SearchFiled searchValue={searchValue} setSearchValue={setSearchValue} />
      <SelectCurrency
        requiredFiled={false}
        currencyData={data?.currencies}
        currencyVal={currencyVal}
        selectChangeCurrency={(value) => changeCurrency(value)}
      />

      <div className="G-print-excel-block">
        <div className="G-view-btn ">
          <Button text="Դիտել / տպել" onClick={printHandler} />
        </div>
        <ExportExcel excelData={dataForExcel} />
      </div>

      <Alert description={errorHandler} onClose={setErrorHandler} />
    </div>
  );
};

export default TransactionLogAction;
