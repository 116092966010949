import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest, getRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../Components/Alert';
import { deleteCurrenciesAction } from '../../Redux/currenciesSlices/useAction';

const DeleteCurrencies = ({ deleteRowId, closeModal }) => {
  const [errorHandler, setErrorHandler] = useState('');
  const [isAllowDelete, setIsAllowDelete] = useState(false);
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  useEffect(() => {
    async function checkAllowDelete() {
      try {
        if (deleteRowId) {
          const url = `letDeleteCurrency?currencyID=${deleteRowId}`;
          const res = await getRequest(url);
          if (res.data.errorCode > 0) {
            setIsAllowDelete(true);
            setErrorHandler(res.data.message);
          }
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    checkAllowDelete();
  }, [deleteRowId]);

  const deleteRowDate = () => {
    deleteRequest(`deleteCurrency/${deleteRowId}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deleteCurrenciesAction(deleteRowId));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  };

  return (
    <>
      {!isAllowDelete && (
        <AcceptOrCancelModal
          modalContent="Համոզվա՞ծ եք որ ուզում եք ջնջել տվյալ արժույթը"
          closeModal={closeModal}
          confirmClick={deleteRowDate}
        />
      )}

      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default DeleteCurrencies;
