import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';

export const fetchAssignAdditionalStorage = async (
  companyId,
  empNo,
  sign = false,
) => {
  try {
    let url = `getEmployeeSalarySets?companyID=${companyId}&empNo=${empNo}&sign=${sign}`;
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addAssignAdditionalStorage = async (newEmployeeSalary) => {
  try {
    const response = await postRequest('addEmployeeSalarySet', {
      ...newEmployeeSalary,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding newEmployeeSalary:', error);
    throw error;
  }
};

export const updateAssignAdditionalStorage = async (newEmployeeSalary) => {
  try {
    const response = await putRequest(
      `editEmployeeSalarySet`,
      newEmployeeSalary,
    );
    return response.data;
  } catch (error) {
    console.error('Error updating subdivision:', error);
    throw error;
  }
};

export const deleteAssignAdditionalStorage = async (salarySetID) => {
  try {
    const response = await deleteRequest(
      `deleteEmployeeSalarySet/${salarySetID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting salarySet:', error);
    throw error;
  }
};

export const fetchAssignAdditionalStorageForm = async (
  currentCompanyID,
  empNo,
  salarySetID,
  sign = false,
) => {
  let url = `getEmployeeSalarySet?companyID=${currentCompanyID}&empNo=${empNo}&salarySetID=${salarySetID}&sign=${sign}`;

  if (!currentCompanyID) {
    return;
  }
  try {
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
