import React from 'react';
import { ExportExcel } from '../../Components/Excelexport/Excelexport';

const ServicesWorkExcel = ({ getExcelData }) => {
  return (
    <ExportExcel
      getExcelData={getExcelData}
      fileName="Ծառայություն / աշխատանք"
    />
  );
};

export default ServicesWorkExcel;
