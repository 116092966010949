import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import PartnerForm from './PartnerForm';
import { useLocation } from 'react-router-dom';

const EventAddForm = ({
  getData,
  pageNumber = 0,
  closeModal,
  updateUserOutside = false,
  updatePartnerAction,
  setPartnerInfo,
  setPartnerTaxCode,
  partnerInfo = {}
}) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [errorHandler, setErrorHandler] = useState('');
  const [partnerID, setPartnerID] = useState(null);
  const [partnerName, setPartnerName] = useState(null);
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  let location = useLocation();
  const currentPathname = location.pathname === '/Partners';
    const EInvoicingPage = location.pathname === '/DownloadEInvoicingAccounts';
    const initialValues = useMemo(() => {
      return {
        companyID: currentCompanyID,
        partnerName: '',
        partnerForm: false,
        partnerType: null,
        taxeServiceNumber: '',
        taxCode: '',
        basis: '',
        description: '',
        countryType: 0,
        idCard: '',
        passport: '',
      };
    }, [currentCompanyID]);

    const onSubmit = useCallback(
      async (values, resetForm) => {
        try {
          const res = await postRequest('addPartner', values);
          const { errorCode, message, id } = res.data;
          setPartnerID(id);
          setPartnerName(message);
          if (EInvoicingPage) {
            handleClose();
          }
          if (errorCode === 0 && !updateUserOutside) {
            getData(pageNumber);
            resetForm();
          } else if (errorCode === 0 && updateUserOutside) {
            dispatch(updatePartnerAction([values]));
            const partnerInfo = {
              partnerID: id,
              partnerName: message,
              taxCode: values.taxCode,
            };

            setPartnerInfo(partnerInfo);
            if (!values.taxCode) {
              setPartnerTaxCode('recipient', message);
            }
            resetForm();
          } else if (errorCode > 0) {
            setErrorHandler(message);
          }
        } catch (err) {
          console.error('An error occurred:', err);
        }
      },
      [
        EInvoicingPage,
        updateUserOutside,
        handleClose,
        getData,
        pageNumber,
        dispatch,
        updatePartnerAction,
        setPartnerInfo,
        setPartnerTaxCode,
      ],
    );

  return (
    <>
      <PartnerForm
        partnerFormTitle={
          partnerID ? `գործընկերը մուտքագրվեց` : 'Ավելացնել գործընկեր'
        }
        initialData={Object.keys(partnerInfo).length === 0 ? initialValues : partnerInfo}
        closeModal={handleClose}
        onSubmit={onSubmit}
        partnerID={partnerID}
        partnerName={partnerName}
        currentPathname={currentPathname}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventAddForm;
