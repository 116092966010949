import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import ProductForm from './ProductForm';

const EventAddForm = ({
  getData,
  pageNumber,
  closeModal,
  updateProductOutside,
  updateProductAction,
  customProductState,
}) => {
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = useCallback(() => closeModal(false), [closeModal]);
  const { currentCompanyID } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const initialValue = useMemo(
    () => ({
      companyID: currentCompanyID,
      productName: '',
    }),
    [currentCompanyID],
  );

  const onSubmit = useCallback(
    (values, resetForm) => {
      postRequest('addProductName', values)
        .then((res) => {
          const { message, errorCode } = res.data;
          if (errorCode === 0 && !updateProductOutside) {
            getData(pageNumber);
            resetForm();
            handleClose();
          } else if (errorCode === 0 && updateProductOutside) {
            dispatch(updateProductAction([values]));
            customProductState({ ...values, productNameID: res.data.id });

            resetForm();
            handleClose();
          } else if (errorCode > 0) {
            setErrorHandler(message);
          }
        })
        .catch((err) => {
          dispatch(updateProductAction([values]));
          console.log('err' + err);
        });
    },
    [
      updateProductOutside,
      getData,
      pageNumber,
      dispatch,
      updateProductAction,
      customProductState,
      handleClose,
    ],
  );

  return (
    <>
      <ProductForm
        onSubmit={onSubmit}
        initialData={initialValue}
        closeModal={handleClose}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventAddForm;
