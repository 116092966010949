import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';
import { getRate } from '../http/rateAPI';
import { Commas } from '../Utilities/Utilities';
const Repg = observer(() => {
  const { currency, entr } = useContext(Context);
  const [rate, setRate] = useState([]);

  const RateGet = async () => {
    try {
      const data = await getRate();
      if (data) {
        setRate(data);
      }
    } catch (e) {}
  };
  useEffect(() => {
    RateGet();
  }, []);

  const entrOfG = entr.Entr;

  const curAr = currency.Currency;

  const glo = curAr.map((cr) => {
    const prEntrOfG = entrOfG.filter(
      (ent) => ent.accountName === 'Եկամուտ' && ent.curr === cr.name,
    );

    let prEntrCur = prEntrOfG
      .map((item) => parseFloat(item.amount))
      .reduce((prev, curr) => prev + curr, 0);

    const rasEntrOfG = entrOfG.filter(
      (ent) => ent.accountName === 'Ծախս' && ent.curr === cr.name,
    );

    let rasEntrCur = rasEntrOfG
      .map((item) => parseFloat(item.amount))
      .reduce((prev, curr) => prev + curr, 0);

    return {
      val: cr.name,
      ek: prEntrCur,
      ta: rasEntrCur,
      bl: prEntrCur - rasEntrCur,
    };
  });

  const gl = glo.filter((ent) => ent.ek > 0 || ent.ta > 0);
  const glRate = gl.map((el) => {
    let r = rate.find((elr) => elr.iso === el.val);
    if (r) {
      return {
        val: el.val,
        ek: el.ek,
        ta: el.ek,
        bl: el.bl,
        curs: r.rate,
        valc: Math.round(el.bl * r.rate),
      };
    } else {
      return {
        val: el.val,
        ek: el.ek,
        ta: el.ek,
        bl: el.bl,
        curs: 1,
        valc: el.bl,
      };
    }
  });

  const totalRate = glRate
    .map((item) => parseFloat(item.valc))
    .reduce((prev, curr) => prev + curr, 0);

  let showTotal =
    glRate &&
    glRate?.length === 1 &&
    glRate?.some((item) => item.val === 'AMD');

  return (
    <div className="report-block">
      <table class="currency-table">
        <thead>
          <tr>
            <th>Ունեմ</th>
            <th>Արժույթը</th>
          </tr>
        </thead>
        <tbody>
          {glRate?.map((en) => (
            <tr key={en.val}>
              <td style={{ color: en.bl >= 0 ? 'green' : 'red' }}>
                {Commas(en.bl)}
              </td>
              <td>{en.val}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {showTotal ? null : (
        <div className="total-rate">
          <div className="total-rate-content">
            <strong>Ընդհանուր</strong>
            <p style={{ color: totalRate >= 0 ? 'green' : 'red' }}>
              {Commas(totalRate)}
            </p>
            <p>AMD</p>
          </div>
          <div style={{ display: 'flex' }}>
            <span>Հիմքը՝ ՀՀ ԿԲ Փոխարժեքներ</span>
          </div>
        </div>
      )}
    </div>
  );
});

export default Repg;
