import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { deleteOrganizationBankAction } from '../../Redux/organizationSlices/useAction';

const DeleteCompanyBank = memo(
  ({ deleteRowId, closeModal, setErrorHandler }) => {
    const handleClose = useCallback(() => closeModal(false), [closeModal]);
    const dispatch = useDispatch();
    const deleteRowDate = useMemo(() => {
      return () => {
        deleteRequest(`deleteCompanyBank/${deleteRowId}`).then((res) => {
          const { errorCode, message } = res?.data || {};
          if (errorCode === 0) {
            dispatch(deleteOrganizationBankAction(deleteRowId));
          } else if (errorCode > 0) {
            setErrorHandler(message);
          }
          handleClose();
        });
      };
    }, [deleteRowId, dispatch, handleClose, setErrorHandler]);

    return (
      <>
        <AcceptOrCancelModal
          closeModal={closeModal}
          confirmClick={deleteRowDate}
        />
      </>
    );
  },
);

export default DeleteCompanyBank;
