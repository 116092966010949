import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAdditionsTypes,
  deleteAdditionsTypes,
  fetchAdditionsFormData,
  fetchAdditionsTypes,
  updateEAdditionsTypes,
} from '../services/fetchAdditionsTypes';
import {
  deleteAdditionsTypeAction,
  fetchAdditionsFormAction,
  fetchAdditionsTypesAction,
  updateAdditionsTypeAction,
} from '../../App/Redux/SalaryStor/additionsTypesSlices/useAction';

const useAdditionsTypes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const dispatch = useDispatch();
  const { currentCompanyID } = useSelector((state) => state.auth);
  const additionsType = useSelector(
    (state) => state.additionsTypes?.additionsData,
  );
  const additionsTypesForm = useSelector(
    (state) => state.additionsTypes?.additionsForm,
  );

  const fetchAdditionsTypesData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchAdditionsTypes(currentCompanyID);
      dispatch(fetchAdditionsTypesAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const additionsTypesItem = async (additionsType, closeFormCallback) => {
    try {
      const { errorCode, message } = await addAdditionsTypes(additionsType);
      if (errorCode === 0) {
        fetchAdditionsTypesData();
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateAdditionsTypesItem = async (
    newAdditionsType,
    closeFormCallback,
  ) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await updateEAdditionsTypes(
        newAdditionsType,
      );

      if (errorCode === 0) {
        dispatch(updateAdditionsTypeAction(newAdditionsType));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAdditionsTypesItem = async (salaryTypeID, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await deleteAdditionsTypes(salaryTypeID);

      if (errorCode === 0) {
        dispatch(deleteAdditionsTypeAction(salaryTypeID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAdditionsForm = async (salaryTypeID) => {
    setIsLoading(true);
    try {
      const data = await fetchAdditionsFormData(currentCompanyID, salaryTypeID);
      dispatch(fetchAdditionsFormAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    fetchAdditionsTypesData,
    additionsTypesItem,
    updateAdditionsTypesItem,
    deleteAdditionsTypesItem,
    additionsType,
    fetchAdditionsForm,
    additionsTypesForm,
    error,
    isLoading,
    errorMess,
    setErrorMess,
  };
};

export default useAdditionsTypes;
