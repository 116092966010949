import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  EnterTextOnly,
  SelectFieldMessage,
} from '../../Validation/ErrorMessage';
import Modal from '../../Components/Modal/Modal';
import { InputFiledMessage } from '../../Validation/ErrorMessage';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import InputFiled from '../../Components/InputFiled/InputFiled';
import { onlyChar } from '../../Validation/REG_EXP';
import { useSelector } from 'react-redux';
import { SelectControl } from '../../Components/SelectControl/SelectControl';

const CurrenciesForm = ({
  closeModal,
  onSubmit,
  initialData,
  disableCurrencyCode = false,
}) => {
  const { currenciesData } = useSelector((state) => state.getCurrenciesData);
  const currenciesList = currenciesData?.allCurrencies?.map(
    ({ currCode, currID, currName }) => {
      return {
        label: currCode + ' ' + currName,
        value: currCode,
        id: currID,
        currName: currName,
      };
    },
  );

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
    setFieldValue,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: Yup.object({
      currencyCode: Yup.string().required(SelectFieldMessage),
      currencyName: Yup.string()
        .matches(onlyChar, EnterTextOnly)
        .max(20, 'Մուտքագրեկ 20 նիշից ոչ ավել')
        .required(InputFiledMessage),
    }),
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const handleChangeCurrency = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    setFieldValue('currencyCode', values.value);
    setFieldValue('currencyName', values.currName);
  };

  return (
    <Modal closeHandler={closeModal} onDrag={true} customClass="G-form-modal">
      <div className="G-form-content">
        <form onSubmit={onSubmit}>
          <div className="G-form-input-wrapper">
            <SelectControl
              options={currenciesList}
              value={{ label: values.currencyCode, value: values.currencyCode }}
              onChange={(values) => handleChangeCurrency(values)}
              requiredFiled={true}
              selectBoxTitle="Ընտրել արժույթը"
              matchFromParam=""
              onBlur={handleBlur}
              disabled={disableCurrencyCode}
              isClearable={false}
            />
            {touched.currencyCode && errors.currencyCode && (
              <ErrorFiled error={errors.currencyCode} />
            )}
          </div>

          <div className="G-form-input-wrapper">
            <InputFiled
              inputTitle="Անվանումը"
              name="currencyName"
              value={values.currencyName}
              changeHandler={handleChange}
              onBlur={handleBlur}
              maxLength={20}
            />
            {touched.currencyName && errors.currencyName && (
              <ErrorFiled error={errors.currencyName} />
            )}
          </div>

          <GetSentDataBtn
            cancelPrintClick={closeModal}
            confirmExecuteClick={handleSubmit}
            executeClass={!dirty ? 'G-disabled-button' : ''}
            executeDisabled={!dirty}
          />
        </form>
      </div>
    </Modal>
  );
};

export default CurrenciesForm;
