import React from 'react';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Context } from '../../index';
import AspectItem from './AspectItem.js';
import './index.scss';

const AspectList = observer(() => {
  const { aspect } = useContext(Context);
  const aspects = aspect?.Aspect;
  return (
    <div className="aspect-flex">
      {aspects.map((aspect) => (
        <AspectItem key={aspect.id} aspectd={aspect} />
      ))}
    </div>
  );
});
export default AspectList;
