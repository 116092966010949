import * as Yup from "yup";
import { firstLetterString } from "./REG_EXP";

export const ChangePasswordValidation = () =>
  Yup.object().shape({
    email: Yup.string()
      .matches(firstLetterString, "Մուտքագրեք տառեր")
      .required()
      .matches(/^[~`@&;':",.a-zA-Z0-9-]+$/, "Մուտքագրեք միայն լատինատառ")
      .required()
      .email("Անվավեր էլեկտրոնային հասցե")
      .required("Անհրաժեշտ է լրացնել «Էլ. հասցե» դաշտը"),
    oldPassword: Yup.string().required(
      "Անհրաժեշտ է լրացնել ընթացիկ գաղտնաբառը"
    ),
    newPassword: Yup.string()
      .min(6, "Գաղտնաբառը պետք է լինի առնվազն 6 նիշ")
      .required()
      .max(20, "Գաղտնաբառը չպետք է գերազանցի 20 նիշը")
      .required()
      .required("Անհրաժեշտ է լրացնել «Նոր գաղտնաբառ» դաշտը"),
    confirmNewPassword: Yup.string()
      .label("")
      .required()
      .oneOf([Yup.ref("newPassword"), null], "Հաստատել գաղտնաբառը"),
  });
