export const makeAnAdditionFields = [
  {
    fieldType: 'date',
    dateName: 'salaryDate',
    dateTitle: 'Ամսաթիվը',
    required: true,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Հավելում/պահում',
    fieldName: 'stName',
    dropdownId: 'stNo',
    optionName: 'salaryTypes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.stName,
        id: i.stNo,
        value: i.stName,
        formulaName: i.formulaName,
        formulaID: i.formulaID,
        coeff: i.coeff,
      }));
    },
    required: true,
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'Հաշվարկվում է',
    fieldName: 'formulaName',
    dropdownId: 'formulaID',
    optionName: 'formulas',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.formulaName,
        id: i.formulaID,
        value: i.formulaName,
      }));
    },
    required: true,
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'coeff',
    label: 'Գործակիցը',
    value: '',
    required: false,
    maxLength: 10,
    disabled: (q) => {
      return !q.stNo || !q.formulaName;
    },
  },
];
