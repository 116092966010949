import React, { useState } from 'react';
import ClickOutside from '../ClickOutside/ClickOutside';
import './toolbar.scss';
import { ReactComponent as DownIcon } from '../../Assets/icons/downIcon.svg';

const Toolbar = ({ children, modalIsOpen, list = [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleToolbar(e) {
    e.stopPropagation();
    setIsOpen(!isOpen);
  }

  function closeToolbar(e) {
    if (isOpen) {
      setIsOpen(false);
      e.stopPropagation();
    }
  }

  return (
    <div className="G-toolbar-block">
      <span
        style={list?.length < 1 ? { display: 'none' } : null}
        className="open-icon"
        onClick={toggleToolbar}
      >
        <DownIcon />
      </span>

      {isOpen && !modalIsOpen && (
        <ClickOutside onClickOutside={closeToolbar}>
          <div className={'P-toolbar-list'}>{children}</div>
        </ClickOutside>
      )}
    </div>
  );
};

export default Toolbar;
