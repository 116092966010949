import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../Components/Alert';
import { deleteUnitOfMeasureAction } from '../../Redux/unitOfMeasureSlices/useAction';

const DeleteUnitOfMeasure = ({ deleteRowId, closeModal }) => {
  const [errorHandler, setErrorHandler] = useState('');
    const handleClose = () => closeModal(false);
    const dispatch = useDispatch();
    const deleteRowDate = () => {
      deleteRequest(`deleteunit/${deleteRowId}`).then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(deleteUnitOfMeasureAction(deleteRowId));
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      });
    };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={closeModal}
        confirmClick={deleteRowDate}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default DeleteUnitOfMeasure;
