import React from "react";
import RadioButton from "../../Components/RadioButton/RadioButton";


const FormulationsType = ({ setReceivedWrittenOff, setPageNumber }) => {
  const handelChecked = (e) => {
    setPageNumber(0);
    setReceivedWrittenOff(Number(e.target.value));
  };

  return (
    <div className="L-eInvoicing-formulations-type-block">
      <div className="L-invoicing-formulations-type-box">
        <RadioButton
          defaultChecked="0"
          value={0}
          id="recivedWrittenOff"
          name="recivedWrittenOff"
          label="Ստացված և դուրս գրված"
          onChange={handelChecked}
        />
      </div>
      <div className="L-invoicing-formulations-type-box">
        <RadioButton
          value={1}
          id="recivedWrittenOff"
          name="recivedWrittenOff"
          label="Ստացված"
          onChange={handelChecked}
        />
      </div>
      <div className="L-invoicing-formulations-type-box">
        <RadioButton
          value={2}
          id="recivedWrittenOff"
          name="recivedWrittenOff"
          label="Դուրս գրված"
          onChange={handelChecked}
        />
      </div>
    </div>
  );
};

export default FormulationsType;
