import React, { useEffect, useState } from 'react';
import Modal from '../../../../App/Components/Modal/Modal';
import { printHandler, printText } from '../../../../App/Utilities/Utilities';
import { useDispatch, useSelector } from 'react-redux';
import CalculationPrintDropdown from './CalculationPrintDropdown';
import Button from '../../../../App/Components/Button/Button';
const CalculationSheetPrintForm = ({
  handleClose,
  empNo,
  getCalculationPrint,
  calculationPrintData,
}) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [query, setQuery] = useState({});
  const isDisabledPrint = !query.startYearMonth || !query.endYearMonth;

  const onPrintHandler = () => {
    const { startYearMonth, endYearMonth } = query || {};
    const params = {
      companyID: currentCompanyID,
      empNo,
      startYearMonth,
      endYearMonth,
    };

    if (Object.values(params).some((value) => !value)) {
      console.error('Missing required parameters for printing');
      return;
    }

    const queryString = new URLSearchParams(params).toString();
    printHandler(`printEmployeeDataAll?${queryString}`, dispatch);
  };

  useEffect(() => {
    if (empNo) {
      getCalculationPrint(empNo);
    }
  }, [empNo]);

  return (
    <Modal
      closeHandler={handleClose}
      customClass="L-calculation-print-modal"
      title=""
    >
      <CalculationPrintDropdown
        calculationPrintData={calculationPrintData}
        query={query}
        setQuery={setQuery}
      />

      <div className="G-add-btn">
        <Button
          type="button"
          text={printText}
          onClick={onPrintHandler}
          disabled={isDisabledPrint}
          customClass={isDisabledPrint && 'G-disabled-button'}
        />
      </div>
    </Modal>
  );
};

export default CalculationSheetPrintForm;
