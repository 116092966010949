import React from 'react';
import { useAccountTableActions } from './useAccountTableActions';
import Table from '../../Components/Table/Table';
import Loading from '../../Components/Loading/Loading';
import Checkbox from '../../Components/Checkbox/Checkbox';
import Tooltip from '../../Components/Tooltip/Tooltip';
import Action from '../../Components/Table/Action';
import EditSendingMoneyWrapper from './EditSendingMoneyWrapper';
import CloseDocument from './CloseDocument';
import DeleteDocument from './DeleteDocument';
import GetSpendingOperations from './SpendingOperations/GetSpendingOperations';
import Alert from '../../Components/Alert';
import {
  Commas,
  dateFormat,
  tableActionTitle,
} from '../../Utilities/Utilities';
import { actionList } from './Actionlist';

const AccountSpendingMoneyTable = ({ mainData, loading }) => {
  const {
    openEditModal,
    setOpenEditModal,
    openDeleteModal,
    setOpenDeleteModal,
    openOperationsModal,
    setOpenOperationsModal,
    errorHandler,
    setErrorHandler,
    isCloseDocument,
    setIsCloseDocument,
    handleActionChange,
  } = useAccountTableActions();
  const handleCloseDoc = () => {
    setIsCloseDocument((prevState) => !prevState);
  };
  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.creationDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Հաշվետու անձ',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 450, overflow: 'hidden' },
    },
    {
      title: 'Նշումներ',
      cell: (row) => row.basis,
      customStyle: { maxWidth: 350, overflow: 'hidden' },
    },
    {
      title: <Tooltip content="Սկզբնական մնացորդ">Սկզբն.մն.</Tooltip>,
      cell: (row) => Commas(row.initBalance),
      customStyle: { maxWidth: 140 },
    },
    {
      title: <Tooltip content="Տրամադրված գումար">Տրամադրված</Tooltip>,
      cell: (row) => Commas(row.total),
      customStyle: { maxWidth: 130 },
    },
    {
      title: <Tooltip content="Ծախսված գումար">Ծախսված</Tooltip>,
      cell: (row) => Commas(row.expense),
      customStyle: { maxWidth: 140 },
    },
    {
      title: <Tooltip content="Վերադարձված գումար">Վերադարձված</Tooltip>,
      cell: (row) => Commas(row.inputs),
      customStyle: { maxWidth: 140 },
    },
    {
      title: <Tooltip content="Վերջնական մնացորդ">Վերջն.մն.</Tooltip>,
      cell: (row) => Commas(row.finalBalance),
      customStyle: { maxWidth: 140 },
    },
    {
      title: 'Փակված է',
      cell: (row) => (
        <Checkbox checked={row.isClosed} value={row.isClosed} disabled={true} />
      ),
      customStyle: { maxWidth: 70, justifyContent: 'center' },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={handleActionChange}
          actionItem={actionList(row.isClosed)}
          mainAction={!row.isClosed ? 'edit' : 'delete'}
          mainActionName={!row.isClosed ? 'Խմբագրել' : 'Ջնջել'}
          modalIsOpen={
            openDeleteModal ||
            openEditModal ||
            openOperationsModal ||
            errorHandler ||
            isCloseDocument
          }
        />
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  if (loading) return <Loading />;

  return (
    <>
      {mainData && mainData.length ? (
        <Table
          customClass="L-account-sending-money-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : null}
      {openEditModal && (
        <EditSendingMoneyWrapper
          closeModal={setOpenEditModal}
          getCashMoneyId={openEditModal}
        />
      )}
      {openDeleteModal && (
        <DeleteDocument
          closeModal={setOpenDeleteModal}
          deleteRowId={openDeleteModal}
          setErrorHandler={setErrorHandler}
        />
      )}
      {openOperationsModal && (
        <GetSpendingOperations
          rowData={openOperationsModal}
          closeModal={setOpenOperationsModal}
          setErrorHandler={setErrorHandler}
        />
      )}
      {isCloseDocument && (
        <CloseDocument
          closeDocumentData={isCloseDocument}
          handleCloseDoc={handleCloseDoc}
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default AccountSpendingMoneyTable;
