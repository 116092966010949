import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "../../../Api/Api";
import Header from "../../../Components/Header/Header";
import Modal from "../../../Components/Modal/Modal";
import { getPartnerBalanceAction } from "../../../Redux/partnerSlices/useAction";
import EventAddForm from "./EventAddForm";
import PartnerBalanceTable from "./PartnerBalanceTable";
import Pagination from '../../../Components/Pagination/Pagination';
import { takeCount } from '../../../Utilities/Utilities';

const GetPartnerBalanceData = ({ closeModal, partnerInfo }) => {
  const { partnerID, partnerName } = partnerInfo;
  const { partnerBalanceData } = useSelector((state) => state.getPartnerData);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  const getData = () => {
    getRequest(
      `getPartnerAccounts?partnerID=${partnerID}&pageNumber=${
        pageNumber + 1
      }&takecount=${takeCount}`,
    )
      .then((res) => {
        dispatch(getPartnerBalanceAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  };

  useEffect(() => {
    getData();
  }, [partnerID, pageNumber]);

  const openAddForm = () => {
    setOpenAddModal(true);
  };

  return (
    <Modal
      closeHandler={handleClose}
      onDrag={true}
      customClass="L-partner-bank-modal"
    >
      <Header
        pageTitle="Գործընկերոջ մնացորդները"
        modalCurrentName={partnerName}
        handelClick={openAddForm}
      />
      <div className="L-partner-balance-modal-content">
        <PartnerBalanceTable mainData={partnerBalanceData?.partnerBalances} />
      </div>
      {openAddModal && (
        <EventAddForm
          modalClick={setOpenAddModal}
          getData={getData}
          partnerData={{ partnerID, partnerName }}
        />
      )}
      <Pagination
        pageCount={Math.ceil(partnerBalanceData?.pageCount?.pageCount || 0)}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
    </Modal>
  );
};

export default GetPartnerBalanceData;
