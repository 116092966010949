import React, { useEffect, useState } from 'react';
import useEInvoiceEntry from '../../hooks/useEInvoiceEntry';
import Modal from '../../../App/Components/Modal/Modal';
import './style.scss';
import EInvoiceEntryHeader from './EInvoiceEntryHeader';
import EInvoiceEntryTable from './EInvoiceEntryTable';
import Button from '../../../App/Components/Button/Button';
import Loading from '../../../App/Components/Loading/Loading';
import EntryOrderFormation from './entryOrderFormation/EntryOrderFormation';

const EInvoiceEntry = ({ partnerID = 0, handleClose }) => {
  const [invoiceEntryTableData, setInvoiceEntryTableData] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const { getEInvoiceEntry, materialValuesEInvoicing, isLoading } =
    useEInvoiceEntry();

  useEffect(() => {
    getEInvoiceEntry(partnerID);
  }, [partnerID]);

  useEffect(() => {
    setInvoiceEntryTableData(materialValuesEInvoicing?.tableData || []);
  }, [materialValuesEInvoicing?.tableData]);

  if (isLoading) {
    return <Loading />;
  }
  const toggleVisibility = () => setShowForm((prev) => !prev);

  const isButtonDisabled = invoiceEntryTableData.some(
    (item) => !item.matValueNo,
  );

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-EInvoice-entry-form"
      title="E-Invoicing-ից ստացված նյութական արժեքներ"
    >
      <EInvoiceEntryHeader details={materialValuesEInvoicing?.details} />
      <EInvoiceEntryTable invoiceEntryTableData={invoiceEntryTableData} />

      <div className="L-EInvoice-entry-btn-block G-flex-justify-end">
        <div className="L-EInvoice-entry-btn">
          <Button
            text="Մուտքի օրդերի ձևավորում"
            onClick={toggleVisibility}
            disabled={isButtonDisabled || !invoiceEntryTableData?.length > 0}
            customClass={
              isButtonDisabled || !invoiceEntryTableData?.length > 0
                ? 'G-disabled-button'
                : ''
            }
          />
        </div>
      </div>
      {showForm && (
        <EntryOrderFormation
          handleClose={toggleVisibility}
          partnerID={materialValuesEInvoicing?.details?.partnerID}
        />
      )}
    </Modal>
  );
};

export default EInvoiceEntry;
