import {
  ADD_WARE_HOUSE_PRODUCT,
  DELETE_WARE_HOUSE_ENTRY,
  DELETE_WARE_HOUSE_PRODUCT,
  FETCH_WARE_HOUSE_ENTRY,
  FETCH_WARE_HOUSE_FORM_ENTRY,
  GET_WARE_HOUSE_ENTRANCE_ID,
  IS_TABLE_UPDATE,
  UPDATE_WARE_HOUSE_DROP_DATA,
  UPDATE_WARE_HOUSE_ENTRY,
  UPDATE_WARE_HOUSE_PRODUCT,
} from './constants';

const initialState = {
  wareHouseEntryData: null,
  warehouseEntryForm: {
    inputs: [],
  },
  warehouseEntranceID: {
    inputTypeId: 0,
    docTypeID: 2,
    incomeSign: false,
  },
  isTableUpdate: false,
};

export const wareHouseEntryReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_WARE_HOUSE_ENTRY:
      return {
        ...state,
        wareHouseEntryData: payload,
      };

    case IS_TABLE_UPDATE:
      return {
        ...state,
        ...state.isTableUpdate,
        isTableUpdate: payload.isTableUpdate,
      };

    case FETCH_WARE_HOUSE_FORM_ENTRY:
      return {
        ...state,
        warehouseEntryForm: payload,
      };
    case UPDATE_WARE_HOUSE_DROP_DATA:
      return {
        ...state,
        warehouseEntryForm: {
          ...state.warehouseEntryForm,
          stores: [...state.warehouseEntryForm.stores, payload.stores],
        },
      };

    case GET_WARE_HOUSE_ENTRANCE_ID:
      return {
        ...state,
        warehouseEntranceID: {
          ...state.warehouseEntranceID,
          ...payload,
        },
      };

    case UPDATE_WARE_HOUSE_ENTRY:
      return {
        ...state,
        wareHouseEntryData: {
          ...state.wareHouseEntryData,
          matInputDocs: state.wareHouseEntryData.matInputDocs?.map((i) => {
            return i.matInputDocID === payload.matInputDocID ? payload : i;
          }),
        },
      };
    case DELETE_WARE_HOUSE_ENTRY:
      return {
        ...state,
        wareHouseEntryData: {
          ...state.wareHouseEntryData,
          matInputDocs: state.wareHouseEntryData.matInputDocs.filter(
            (item) => item.matInputDocID !== payload,
          ),
        },
      };

    case ADD_WARE_HOUSE_PRODUCT:
      return {
        ...state,
        ...state.warehouseEntryForm,
        isTableUpdate: payload.isTableUpdate,
        warehouseEntryForm: {
          ...state.warehouseEntryForm,
          inputs: [...state.warehouseEntryForm.inputs, payload?.inputs],
        },
      };

    case UPDATE_WARE_HOUSE_PRODUCT:
      return {
        ...state,
        ...state.warehouseEntryForm,
        isTableUpdate: payload.isTableUpdate,
        warehouseEntryForm: {
          ...state.warehouseEntryForm,
          inputs: state.warehouseEntryForm.inputs.map((item) =>
            item.matInputID === payload.inputs.matInputID
              ? payload.inputs
              : item,
          ),
        },
      };

    case DELETE_WARE_HOUSE_PRODUCT:
      return {
        ...state,

        isTableUpdate: payload.isTableUpdate,
        warehouseEntryForm: {
          ...state.warehouseEntryForm,
          inputs: state.warehouseEntryForm?.inputs.filter(
            (item) => item.matInputID !== payload.matInputID,
          ),
        },
      };

    default:
      return { ...state };
  }
};
