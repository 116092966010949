import { useState, useEffect } from 'react';
import { getRequest } from '../Api/Api';

const useEmailExistenceCheck = (email) => {
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (email) {
      getRequest(`isEmailExist?email=${email}`)
        .then((res) => {
          if (res.data) {
            setError('Այդ email-ով օգտվող գոյություն չունի:');
            setIsEmailExist(true);
          } else {
            setIsEmailExist(false);
          }
        })
        .catch((error) => {
          console.error('Error while checking email existence:', error);
        });
    }
  }, [email]);

  return { isEmailExist, error, setError };
};

export default useEmailExistenceCheck;
