import React from 'react';
import Alert from '../../../../App/Components/Alert';
import useAssignAdditionalStorage from '../../../hooks/useAssignAdditionalStorage';
import AssignAdditionalForm from './AssignAdditionalForm';
import useIsMounted from '../../../hooks/useIsMounted';
import { removeCommas } from '../../../../App/Utilities/Utilities';

const EventEditForm = ({ rowValue, handleClose }) => {
  const { salarySetID, empNo } = rowValue || {};
  const { updateAssignAdditional, errorMess, setErrorMess } =
    useAssignAdditionalStorage();
  const isMounted = useIsMounted();
  const onUpdateHandler = async (values) => {
    const val = {
      ...values,
      sPercent: Number(values.sPercent),
      sAmount: removeCommas(values.sAmount),
    };

    try {
      await updateAssignAdditional(val, handleClose);
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <AssignAdditionalForm
        salarySetID={salarySetID}
        empNo={empNo}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
      />

      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventEditForm;
