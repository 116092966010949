import React from 'react';
import { useContext, useState } from 'react';
import { Context } from '../../../index.js';
import { create } from '../../http/entrAPI';
import Calendar from '../Calendar/Calendar';
import {
  dateFormat,
  handleEnter,
  onFocusRemoveZero,
} from '../../Utilities/Utilities';
import { NumericFormat } from 'react-number-format';
import FormControl from '../FormControl/FormControl.jsx';
import Button from '../Button/Button.jsx';
import Modal from '../Modal/Modal.jsx';
import Dropdown from '../Dropdown/Dropdown.jsx';

const CreateEntr = ({ aspect, accId, accName, closeModal }) => {
  const { currency, entr } = useContext(Context);
  const handleClose = () => closeModal(false);
  const currs = currency.Currency;

  const [crAt, setCrAt] = useState(new Date());

  const [amount, setAmount] = useState(null);

  const [comment, setComment] = useState('');

  const [currencyId, setCurrencyId] = useState(1);

  const [currencyName, setCurrencyName] = useState('AMD');

  const [aspectId, setAspectId] = useState(aspect.id);
  const [aspectName, setAspectName] = useState(aspect.name);
  const [userId, setUserId] = useState(aspect.userId);
  const [imgColor, setImgColor] = useState(aspect.imgColor);

  const addEntr = async (e) => {
    e.preventDefault();
    const amountD = parseFloat(amount);

    const formData = {
      userId: userId,
      accountId: accId,
      aspectId: aspectId,
      currencyId: currencyId,
      accountName: accName,
      aspectName: aspectName,
      curr: currencyName,
      crAt: crAt,
      amount: amountD,
      comment: comment,
      imgColor: imgColor,
    };

    setAmount(0);
    setComment('');
    setCrAt(new Date());

    const data = await create(formData);

    if (data.data.id) {
      entr.setEntr([...entr.Entr, data.data]);
      handleClose();
    } else {
      alert(JSON.stringify('Մուտքագրված տվյալների սխալ...'));
    }
  };

  const curreseOption = currs?.map((i) => ({
    ...i,
    label: i.name,
  }));

  return (
    <Modal customClass="create-enter-modal" closeModal={closeModal}>
      <h3 className="enter-form-title">
        {accName} / {aspect.name}
      </h3>

      <div className="create-enter-content">
        <form>
          <div className="calendar-wrap">
            <p>Ամսաթիվ</p>
            <Calendar
              value={dateFormat(crAt)}
              name="crAtd"
              onChange={(value) => setCrAt(value)}
            />
          </div>

          <Dropdown
            options={curreseOption}
            value={{ label: currencyName, value: currencyName }}
            onChange={(cr) => {
              setCurrencyName(cr.name);
              setCurrencyId(cr.id);
            }}
          />

          <div className="amount-filed">
            <NumericFormat
              className="input-filed"
              placeholder="Գումար"
              value={amount}
              onFocus={onFocusRemoveZero}
              type="text"
              thousandsGroupStyle={true}
              thousandSeparator=","
              valueIsNumericString={true}
              onValueChange={(values) => {
                const { value } = values;
                setAmount(value);
              }}
              isAllowed={({ formattedValue }) => formattedValue.length <= 15}
            />
          </div>

          <FormControl
            placeholder="Գործարքի վերաբերյալ նշում"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={handleEnter}
            maxLength={100}
          />
          <Button onClick={addEntr} text="Գրանցել" />
        </form>
      </div>
    </Modal>
  );
};
export default CreateEntr;
