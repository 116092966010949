import React, { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import Action from "../../Components/Table/Action";
import { selectPartnerAction } from "../../OptionsList/OptionsList";
import { tableActionTitle } from "../../Utilities/Utilities";
import Table from "../../Components/Table/Table";
import EventEditForm from "./EventEditForm";
import DeletePartner from "./DeletePartner";
import GetPartnerBalanceData from "./PartnerBalance/GetPartnerBalanceData";
import GetPartnerBankData from "./PartnerBank/GetPartnerBankData";

const PartnerTable = ({ mainData, loading }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openBalanceModal, setOpenBalanceModal] = useState(false);
  const [openBankModal, setOpenBankModal] = useState(false);

  const handelChange = (value, row) => {
    switch (value) {
      case 'delete':
        return openDeleteForm(row?.partnerID);
      case 'edit':
        return openEditForm(row);
      case 'balance':
        return openBalanceForm(row);
      case 'bank':
        return openBankForm(row);
      default:
        return null;
    }
  };

  const columnConfig = [
    {
      title: 'Անվանումը',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 1100, overflow: 'hidden' },
    },
    {
      title: 'Տիպը',
      cell: (row) => row.partnerTypeName,
      customStyle: { maxWidth: 230 },
    },
    {
      title: 'ՀՎՀՀ',
      cell: (row) => row.taxCode,
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'ՀԾՀ/Անձն./ID',
      cell: (row) => row.identDoc,
      customStyle: { maxWidth: 180, overflow: 'hidden' },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={handelChange}
          actionItem={selectPartnerAction}
          modalIsOpen={
            openEditModal ||
            openDeleteModal ||
            openBalanceModal ||
            openBankModal
          }
        />
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  const openEditForm = (rowValue) => {
    setOpenEditModal(rowValue);
  };

  const openDeleteForm = (deleteRowId) => {
    setOpenDeleteModal(deleteRowId);
  };

  const openBalanceForm = (row) => {
    setOpenBalanceModal(row);
  };

  const openBankForm = (row) => {
    setOpenBankModal(row);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-partner-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ""
      )}

      {openEditModal && (
        <EventEditForm rowData={openEditModal} closeModal={setOpenEditModal} />
      )}

      {openDeleteModal && (
        <DeletePartner
          closeModal={setOpenDeleteModal}
          deleteRowId={openDeleteModal}
        />
      )}

      {openBalanceModal && (
        <GetPartnerBalanceData
          closeModal={setOpenBalanceModal}
          partnerInfo={openBalanceModal}
        />
      )}
      {openBankModal && (
        <GetPartnerBankData
          closeModal={setOpenBankModal}
          partnerInfo={openBankModal}
        />
      )}
    </>
  );
};

export default PartnerTable;
