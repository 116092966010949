import React, { useEffect, useState } from 'react';
import { Commas } from '../../../App/Utilities/Utilities';

const PaymentListTotal = ({ data }) => {
  const [totalDedAmount, setTotalDedAmount] = useState(0);

  useEffect(() => {
    const calculateTotalDedAmount = (items) => {
      return items.reduce((acc, item) => acc + (item.dedAmount || 0), 0);
    };

    if (Array.isArray(data)) {
      const total = calculateTotalDedAmount(data);
      setTotalDedAmount(total);
    }
  }, [data]);

  return (
    <div className="L-paymentList-total">
      <p>Ընդամենը</p>
      <p>{Commas(totalDedAmount)}</p>
    </div>
  );
};

export default PaymentListTotal;
