import {
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  LOGOUT,
  UPDATE_USER_DATA,
} from "./constants";

const initialState = {
  token: null,
  error: {},
};

export const authReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case LOGIN_REQUEST:
      return { ...payload };
    case REGISTER_REQUEST:
      return { ...payload };
    case UPDATE_USER_DATA:
      return { ...state, ...payload };
    case LOGOUT:
      return { token: null, loginUser: {} };
    default:
      return state;
  }
};
