import React from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import { useFormik } from 'formik';
import { SubdivisionValidation } from '../../../App/Validation/SubdivisionValidation';
import Input from '../../Input/Input';
import useIsMounted from '../../hooks/useIsMounted';

const SubdivisionForm = ({ initialValues, onSubmit, handleClose }) => {
  const isMounted = useIsMounted();

  const formik = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: SubdivisionValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
      if (isMounted) {
        resetForm();
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
  } = formik;

  return (
    <Modal closeHandler={handleClose} onDrag={true} customClass="G-form-modal">
      <div className="G-form-content">
        <form onSubmit={handleSubmit}>
          <div className="G-form-input-wrapper">
            <Input
              inputTitle="Կոդը"
              name="depNo"
              type="string"
              value={values.depNo}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={2}
            />
            {touched.depNo && errors.depNo && (
              <ErrorFiled error={errors.depNo} />
            )}
          </div>

          <div className="G-form-input-wrapper">
            <Input
              inputTitle="Անվանումը"
              name="depName"
              value={values.depName}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={100}
            />
            {touched.depName && errors.depName && (
              <ErrorFiled error={errors.depName} />
            )}
          </div>

          <GetSentDataBtn
            cancelPrintClick={handleClose}
            confirmExecuteClick={handleSubmit}
            executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
            executeDisabled={!dirty || !isValid}
          />
        </form>
      </div>
    </Modal>
  );
};

export default SubdivisionForm;
