import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Modal from '../../../../App/Components/Modal/Modal';
import { SelectControl } from '../../../../App/Components/SelectControl/SelectControl';
import AddNew from '../../../../App/Elements/Add/AddNew';
import GetSentDataBtn from '../../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import MatValueNameForm from '../../materialValuesNames/EventAddForm';
import useWarehouseEntry from '../../../hooks/useWarehouseEntry';
import { updatePriceAndTax } from './updatePriceAndTax';
import Input from '../../../components/Input/Input';
import NumericFormatFiled from '../../../../App/Components/NumericFormat/NumericFormatFiled';
import { fieldConfigs } from './fieldConfigs';
import { warehouseEntryProductV } from '../../../../App/Validation/WarehouseEntryValidation';
import { removeCommas } from '../../../../App/Utilities/Utilities';
const ProductForm = ({ handleClose, initValues = {}, onSubmit }) => {
  const [openNewMathNameForm, setOpenNewMathNameForm] = useState(false);

  const { warehouseEntryForm, warehouseEntranceID } = useWarehouseEntry();

  const inputTypeId =
    warehouseEntranceID?.inputTypeId ||
    warehouseEntryForm?.matInput?.inputTypeId;

  const docTypeID =
    warehouseEntranceID?.docTypeID || warehouseEntryForm?.matInput?.docTypeID;
  const incomeSign =
    warehouseEntranceID?.incomeSign ||
    warehouseEntryForm?.matInput?.incomeSign ||
    false;

  const mainClassName = 'L-warehouseEntry-produce-filed';
  let dropDownOptions = [];
  const formik = useFormik({
    initialValues: { ...initValues },
    validationSchema: warehouseEntryProductV,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
    enableReinitialize: true,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = formik;

  const handleSelectChange = (fieldName, dropdownId, selectedOption) => {
    const {
      value = '',
      id = '',
      unit = '',
      accCode = '',
    } = selectedOption || {};
    setFieldValue(fieldName, value);
    setFieldValue(dropdownId, id);

    const resetFieldsMap = {
      matValueName: ['matValueNo', 'unit', 'accCode', 'accName'],
    };

    if (resetFieldsMap[fieldName]) {
      resetFieldsMap[fieldName].forEach((resetField) =>
        setFieldValue(resetField, ''),
      );
    }

    if (fieldName === 'matValueName') {
      setFieldValue('matValueNo', id);
      setFieldValue('unit', unit);
      setFieldValue('accCode', accCode);
      setFieldValue('accName', accCode);
    }
  };
  useEffect(() => {
    if (docTypeID === 2) {
      updatePriceAndTax(values, inputTypeId, incomeSign, setFieldValue);
    } else {
      const parsedAmount = removeCommas(values.amount) || 0;
      const parsedPurPrice = removeCommas(values.purPrice) || 0;
      setFieldValue('totalPrice', parsedAmount * parsedPurPrice);
    }
  }, [
    docTypeID,
    incomeSign,
    inputTypeId,
    JSON.stringify(values),
    setFieldValue,
  ]);

  const fieldList = fieldConfigs(docTypeID, inputTypeId);

  const customHandleChange = (e) => {
    const { name, value } = e.target;
    const initIncomeTax = removeCommas(value);
    const parsedPurPrice = removeCommas(values.purPrice);
    const parsedAmount = Number(values.amount);
    const parsedPrices = removeCommas(values.price);

    if (name === 'incomeTax') {
      setFieldValue(name, initIncomeTax);
      setFieldValue('price', parsedPurPrice + initIncomeTax);
      setFieldValue('totalPrice', parsedAmount * parsedPrices);
    } else {
      handleChange(e);
    }
  };

  return (
    <Modal
      closeHandler={handleClose}
      customClass="L-warehouseEntry-produce-form"
    >
      <form onSubmit={handleSubmit}>
        {fieldList.map((field, index) => {
          const {
            fieldType,
            fieldName,
            title,
            name,
            required,
            Children,
            optionName,
            dropdownTitle,
            type,
            dropdownId,
            maxLength,

            addNewItem,
            disabled,
          } = field;
          const isRequired =
            typeof required === 'function' ? required(values) : required;
          const isLength =
            typeof maxLength === 'function' ? maxLength(values) : maxLength;

          if (typeof Children === 'function' && warehouseEntryForm) {
            dropDownOptions = Children(warehouseEntryForm[optionName]);
          }
          switch (fieldType) {
            case 'input':
              return (
                <div key={index} className={mainClassName}>
                  <Input
                    type={type}
                    inputTitle={title}
                    name={name}
                    requiredField={isRequired}
                    onChange={(e) => customHandleChange(e)}
                    value={values[name]}
                    maxLength={isLength}
                    onBlur={handleBlur}
                  />
                </div>
              );
            case 'amount':
              return (
                <div key={name} className={mainClassName}>
                  <NumericFormatFiled
                    inputTitle={title}
                    name={name}
                    requiredFiled={required}
                    changeHandler={handleChange}
                    value={values[name]}
                    onBlur={handleBlur}
                    disabled={disabled}
                  />
                </div>
              );
            case 'dropdown':
              return (
                <div
                  key={index}
                  className={`${mainClassName} ${
                    addNewItem ? 'G-dropdown-flex-block' : ''
                  }`}
                >
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={isRequired}
                    value={{
                      label: values[fieldName] || '',
                      value: values[fieldName] || '',
                    }}
                    onChange={(selectedOption) =>
                      handleSelectChange(fieldName, dropdownId, selectedOption)
                    }
                    options={dropDownOptions || []}
                    onBlur={handleBlur}
                  />
                  {addNewItem && (
                    <AddNew
                      onClick={() => setOpenNewMathNameForm(fieldName)}
                      content={dropdownTitle}
                    />
                  )}
                </div>
              );

            default:
              return null;
          }
        })}
      </form>
      <GetSentDataBtn
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleSubmit}
        executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
        executeDisabled={!dirty || !isValid}
      />

      {openNewMathNameForm && (
        <MatValueNameForm
          formIsUsedExternally
          setFieldValue={setFieldValue}
          handleClose={() => setOpenNewMathNameForm(false)}
        />
      )}
    </Modal>
  );
};

export default ProductForm;
