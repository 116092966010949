import React from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';

const DeleteEmployeePosition = ({
  handleClose,
  tableID,
  getDeleteEmployeePosition,
  empNo,
}) => {
  const onRemoveHandler = async () => {
    try {
      await getDeleteEmployeePosition(tableID, empNo, handleClose);
      handleClose();
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };
  return (
    <AcceptOrCancelModal
      closeModal={handleClose}
      confirmClick={onRemoveHandler}
    />
  );
};

export default DeleteEmployeePosition;
