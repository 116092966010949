import React from 'react';
const TableInfo = ({ tabelName, tabelYear }) => {
  const tableData = {
    Տաբել: tabelName,
    Տարին: tabelYear,
  };

  return (
    <div className="table-info-block">
      {Object.entries(tableData).map(([key, value]) => (
        <div key={key} className="table-info-item">
          <h3>{key}</h3>
          <p>{value}</p>
        </div>
      ))}
    </div>
  );
};
export default TableInfo;
