import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import './header.scss';
import SalaryCalculationDate from '../../../Salary/components/salaryCalculationDate/SalaryCalculationDate';

const Header = (props) => {
  const {
    handelClick,
    headerBtn = true,
    pageTitle,
    hasPageTitle = true,
    hasCompanyName = true,
    modalCurrentName = '',
    btnText = 'Ավելացնել',
    disabledAddBtn,
    className = '',
    showCalculationDate = false,
  } = props;
  const { currentCompanyName } = useSelector((state) => state.auth);
  return (
    <div className={`G-header-block${className}`}>
      <div className="G-header-title-date-wrap">
        {hasCompanyName && (
          <h1 className={`G-header-company-name `}>
            {modalCurrentName === '' ? currentCompanyName : modalCurrentName}
          </h1>
        )}
        {showCalculationDate && <SalaryCalculationDate />}
      </div>
      <div
        className={`G-header-bot-area G-flex-align-center ${
          !headerBtn ? 'G-flex-justify-end' : 'G-flex-justify-between'
        }`}
      >
        {headerBtn && (
          <div className="G-add-btn">
            <Button
              type="button"
              text={btnText}
              onClick={handelClick}
              disabled={disabledAddBtn}
              customClass={disabledAddBtn && 'G-disabled-button'}
            />
          </div>
        )}
        {hasPageTitle && <h1 className="G-page-title">{pageTitle}</h1>}
      </div>
    </div>
  );
};

export default Header;
