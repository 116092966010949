import {
  DELETE_INVOICING,
  GET_INVOICING_OPERATIONS_DATA,
  LOADING,
  UPDATE_SIGN_FILED,
} from './constants';

const initialState = {
  invoicingOperationData: null,
  isLoading: false,
};

export const invoicingOperationReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case GET_INVOICING_OPERATIONS_DATA:
      return { ...state, invoicingOperationData: payload, isLoading: true };

    case UPDATE_SIGN_FILED: {
      const { excelInvoiceID, signedByTheBuyer, isSigned } = payload;
      return {
        ...state,
        invoicingOperationData: {
          ...state.invoicingOperationData,
          invList: state.invoicingOperationData?.invList.map((item) =>
            item.excelInvoiceID === excelInvoiceID
              ? { ...item, signedByTheBuyer, isSigned }
              : item,
          ),
        },
      };
    }

    case DELETE_INVOICING: {
      return {
        ...state,
        invoicingOperationData: {
          ...state.invoicingOperationData,
          invList: state.invoicingOperationData?.invList.filter(
            (item) => item.excelInvoiceID !== payload,
          ),
        },
      };
    }

    case LOADING:
      return { ...state, isLoading: payload };

    default:
      return state;
  }
};
