const useUpdateSignChecker = () => {
  const checkUpdateSign = (updateSign, errorMess, setErrorMess) => {
    if (!updateSign) {
      const message =
        errorMess ||
        'Կան կատարված գործառնություններ, նախ հեռացրեք գործառնությունները';
      setErrorMess(message);

      return false;
    }
    return true;
  };

  return { checkUpdateSign };
};

export default useUpdateSignChecker;
