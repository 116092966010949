import React, { useEffect, useState } from 'react';
import MaterialNameTable from './MaterialNameTable';
import MaterialNameFilter from './MaterialNameFilter';
import useMaterialValuesName from '../../hooks/useMaterialValuesName';
import Header from '../../../App/Components/Header/Header';
import Pagination from '../../../App/Components/Pagination/Pagination';
import EventAddForm from './EventAddForm';
import './style.scss';
import { simplifyObject } from '../../../App/Utilities/Utilities';

const MaterialValuesNames = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [params, setParams] = useState({
    searchString: '',
    pageNumber: 0,
    groupNo: 0,
  });
  const modifyObj = simplifyObject(params);

  const {
    getMaterialValuesNames,
    materialValueName,
    isLoading,
    getMaterialNameExcelData,
  } = useMaterialValuesName();

  useEffect(() => {
    getMaterialValuesNames(modifyObj);
  }, [modifyObj]);

  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };

  const handlePageChange = (newPageNumber) => {
    setParams((prevState) => ({
      ...prevState,
      pageNumber: newPageNumber,
    }));
  };

  return (
    <>
      <Header
        pageTitle="Նյութական արժեքների անվանումներ"
        handelClick={onClose}
      />
      <MaterialNameFilter
        params={params}
        setParams={setParams}
        modifyObj={modifyObj}
        groupsData={materialValueName?.prodGroups}
        getMaterialNameExcelData={getMaterialNameExcelData}
      />
      <MaterialNameTable
        materialValuesName={materialValueName?.matValues}
        isLoading={isLoading}
      />
      <Pagination
        pageCount={getMaterialValuesNames?.pageCount?.pageCount}
        setPageNumber={handlePageChange}
        currentPage={params?.pageNumber}
      />
      {showAddForm && <EventAddForm handleClose={onClose} params={params} />}
    </>
  );
};

export default MaterialValuesNames;
