import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from '../../Components/Calendar/Calendar';
import InputFiled from '../../Components/InputFiled/InputFiled';
import {
  dataTimeFormat,
  dateFormat,
  typOfString,
} from '../../Utilities/Utilities';
import { updateCashMoneyDataAction } from '../../Redux/accountForSpendingMoneySlices/useAction';
import { getRequest } from '../../Api/Api';

const PurposeProvidingMoney = ({ mainData }) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { partnerName, docNum, creationDate, basis, isClosed } = mainData || {};

  const dispatch = useDispatch();

  const inputChangeHandler = (e) => {
    const { name, value } = e.currentTarget;

    dispatch(updateCashMoneyDataAction({ [name]: value }));
  };

  const onChangeCreationDate = (e) => {
    const creationDateFormat = dataTimeFormat(e);
    dispatch(updateCashMoneyDataAction({ creationDate: creationDateFormat }));
    if (creationDate !== creationDateFormat) {
      getRequest(
        `getCashMoneyDocNum?companyID=${currentCompanyID}&docDate=${creationDateFormat}`,
      )
        .then((res) => {
          dispatch(
            updateCashMoneyDataAction({ docNum: typOfString(res.data) }),
          );
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  };

  return (
    <div className="L-purpose-providing-money-block">
      <div className="L-reporting-person">
        <InputFiled
          inputTitle="Հաշվետու անձ"
          value={partnerName}
          name="partnerName"
          disabled={true}
        />
      </div>
      <div className="L-purpose-providing-money G-flex">
        <div className="L-docNum-block">
          <InputFiled
            inputTitle="Փաստաթուղթ N"
            value={docNum}
            name="docNum"
            changeHandler={inputChangeHandler}
            disabled={isClosed}
          />
        </div>
        <div className="L-calendar">
          <p className="G-title-p">Կազմման ամսաթիվ</p>
          <Calendar
            value={dateFormat(creationDate)}
            name="creationDate"
            changeHandler={(e) => onChangeCreationDate(e, 'creationDate')}
          />
        </div>
        <div className="L-money-basis-block G-relative">
          <InputFiled
            inputTitle="Գումարների տրամադրման նպատակը"
            requiredFiled={true}
            value={basis}
            name="basis"
            changeHandler={inputChangeHandler}
            disabled={isClosed}
          />
        </div>
      </div>
    </div>
  );
};

export default PurposeProvidingMoney;
