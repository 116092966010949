import React from 'react';
import { MAIN_URL } from '../../Utilities/Utilities';
import { useSelector } from 'react-redux';

const PrintDetails = () => {
  const { param, title } = useSelector((state) => state.getPrintParam);
  document.title = title;

  const url = param?.includes('blob:') ? param : `${MAIN_URL}/${param.trim()}`;

  return (
    <object data={url} width="100%" height="900px" name="">
      -
    </object>
  );
};

export default PrintDetails;
