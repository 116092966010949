import { createAction } from "../actionCreators";
import {
    DELETE_CURRENCIES,
    EDIT_CURRENCIES,
  GET_CURRENCIES,

  LOADING,
} from "./constants";

export const getCurrenciesAction = createAction(GET_CURRENCIES);
export const loadingAction = createAction(LOADING, false);
export const editCurrenciesAction = createAction(EDIT_CURRENCIES);
export const deleteCurrenciesAction = createAction(DELETE_CURRENCIES);
