import React, { useState, useEffect } from 'react';
import Table from '../../../App/Components/Table/Table';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import { Commas } from '../../../App/Utilities/Utilities';
import SalaryCalculationTotal from './SalaryCalculationTotal';

const SalaryCalculationTable = ({
  updateSalaryCalcData,
  setUpdateSalaryCalcData,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [totals, setTotals] = useState({
    toBePayed: 0,
    totalDed: 0,
    totalAdd: 0,
    hours: 0,
    salary: 0,
  });

  useEffect(() => {
    const allChecked = updateSalaryCalcData?.every((item) => item.sign);
    setSelectAll(allChecked);
  }, [updateSalaryCalcData]);

  useEffect(() => {
    const newTotals = updateSalaryCalcData?.reduce(
      (acc, item) => {
        acc.toBePayed += item.toBePayed || 0;
        acc.totalDed += item.totalDed || 0;
        acc.totalAdd += item.totalAdd || 0;
        acc.hours += item.hours || 0;
        acc.salary += item.salary || 0;
        return acc;
      },
      { toBePayed: 0, totalDed: 0, totalAdd: 0, hours: 0, salary: 0 },
    );
    setTotals(newTotals);
  }, [updateSalaryCalcData]);

  const onChecked = (e, row = null) => {
    const { checked } = e.target;

    const updatedList = updateSalaryCalcData?.map((item) =>
      row && item.empNo === row.empNo
        ? { ...item, sign: checked }
        : { ...item, sign: row ? item.sign : checked },
    );

    setUpdateSalaryCalcData(updatedList);
  };

  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 380, overflow: 'hidden' },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Ազգանուն, Անուն, Հայրանուն',
      cell: (row) => row.emplName,
      customStyle: { maxWidth: 380, overflow: 'hidden' },
    },
    {
      title: 'Հ/կոդ',
      cell: (row) => row.stNo,
      customStyle: { maxWidth: 60 },
    },
    {
      title: 'Օր',
      cell: (row) => row.days,
      customStyle: { maxWidth: 60 },
    },
    {
      title: 'Ժամ',
      cell: (row) => row.hours,
      customStyle: { maxWidth: 60 },
    },
    {
      title: 'Հաստիք',
      cell: (row) => Commas(row.salary),
      customStyle: { maxWidth: 170 },
    },
    {
      title: 'Ընդ. հավելում',
      cell: (row) => Commas(row.totalAdd),
      customStyle: { maxWidth: 170 },
    },
    {
      title: 'Ընդ. պահում',
      cell: (row) => Commas(row.totalDed),
      customStyle: { maxWidth: 170 },
    },
    {
      title: 'Ենթակա է վճարման',
      cell: (row) => Commas(row.toBePayed),
      customStyle: { maxWidth: 170 },
    },
    {
      title: (
        <span className="G-flex">
          <Checkbox checked={selectAll} onChange={onChecked} />
        </span>
      ),
      cell: (row) => (
        <Checkbox
          checked={row.sign}
          value={row.sign}
          onChange={(e) => onChecked(e, row)}
        />
      ),
      customStyle: { width: 50 },
    },
  ];

  return (
    <>
      {updateSalaryCalcData !== null && updateSalaryCalcData?.length ? (
        <Table
          customClass="L-salary-calculation-table G-table-has-scroll"
          data={updateSalaryCalcData}
          columnConfig={columnConfig}
        />
      ) : null}
      {totals && <SalaryCalculationTotal totals={totals} />}
    </>
  );
};

export default SalaryCalculationTable;
