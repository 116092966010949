import React from "react";
import zxcvbn from "zxcvbn";
import "./index.scss";

const PasswordStrengthMeter = ({ password }) => {
  const testResult = zxcvbn(password);
  const num = (testResult.score * 100) / 4;

  const createPassLabel = () => {
    switch (testResult.score || 123456789) {
      case 123456789:
        return "Թույլ";
      case 0:
        return "";
      case 1:
        return "Թույլ";
      case 2:
        return "Միջին";
      case 3:
        return "Լավ";
      case 4:
        return "Ուժեղ";
      default:
        return "";
    }
  };

  const funcProgressColor = () => {
    switch (testResult.score) {
      case 0:
        return "#828282";
      case 1:
        return "#EA1111";
      case 2:
        return "#FFAD00";
      case 3:
        return "#9bc158";
      case 4:
        return "#00b500";
      default:
        return "none";
    }
  };

  const changePasswordColor = () => ({
    width: `${num}%`,
    background: funcProgressColor(),
    height: "7px",
  });

  return (
    <div className="L-progress-main-block">
      <div className="L-progress" style={{ height: "7px" }}>
        <div className="L-progress-bar" style={changePasswordColor()}></div>
      </div>
      <p style={{ color: funcProgressColor() }}>{createPassLabel()}</p>
    </div>
  );
};

export default PasswordStrengthMeter;
