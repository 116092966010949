import {
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCT,
  LOADING,
  SEARCH_PRODUCT,
} from "./constants";

const initialState = {
  productData: null,
  isLoading: false,
};

export const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PRODUCT:
      return {
        ...state,
        productData: payload,
        isLoading: true,
      };
    case SEARCH_PRODUCT:
      return {
        ...state,
        productData: payload,
        isLoading: false,
      };
    case EDIT_PRODUCT:
      return {
        ...state,
        productData: {
          ...state.productData,
          productNames: state.productData.productNames.map((name) => {
            return name.productNameID === payload.productNameID
              ? payload
              : name;
          }),
        },
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        productData: {
          ...state.productData,
          productNames: state.productData?.productNames.filter(
            (item) => item.productNameID !== payload
          ),
        },
      };
    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return { ...state };
  }
};
