import { createAction } from "../actionCreators";
import {
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_BANK,
  EDIT_ORGANIZATION,
  EDIT_ORGANIZATION_BANK,
  GET_ORGANIZATION,
  GET_ORGANIZATION_BANK,
  LOADING,
} from "./constants";

export const getOrganizationAction = createAction(GET_ORGANIZATION);
export const deleteOrganizationAction = createAction(DELETE_ORGANIZATION);
export const editOrganizationAction = createAction(EDIT_ORGANIZATION);
export const loadingAction = createAction(LOADING);

export const getOrganizationBankAction = createAction(GET_ORGANIZATION_BANK);
export const deleteOrganizationBankAction = createAction(
  DELETE_ORGANIZATION_BANK
);
export const editOrganizationBankAction = createAction(EDIT_ORGANIZATION_BANK);
