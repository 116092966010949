import * as Yup from "yup";
import { InputFiledMessage } from "./ErrorMessage";

export const SpendingFormValidation = () =>
  Yup.object().shape({
    documentName: Yup.string().required(InputFiledMessage),
    purpose: Yup.string().required(InputFiledMessage),
    price: Yup.string().required('Մուտքագրեք գինը'),
    quantity: Yup.number()
      .required(InputFiledMessage)
      .positive('Մուտքագրեք 0-ից մեծ թիվ')
      .moreThan(0, 'Մուտքագրեք 0-ից մեծ թիվ'),
  });
