import React from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../../App/Components/Alert';
import useEInvoiceEntry from '../../hooks/useEInvoiceEntry';

const EventRemove = ({ invGoodID, handleClose }) => {
  const { deleteEntryOrder, errorMess, setErrorMess } = useEInvoiceEntry();

  const onRemoveHandler = async () => {
    try {
      await deleteEntryOrder(invGoodID, handleClose);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventRemove;
