import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import './style.scss';
import useBankPaymentLists from '../../hooks/useBankPaymentLists';
import BankPaymentListsActions from './BankPaymentListsActions';
import BankPaymentListsTable from './BankPaymentListsTable';
import NewPaymentListForm from './NewPaymentListForm';
import Alert from '../../../App/Components/Alert';
import { ConvertBankPaymentListDataExcel } from '../../Config/excelConfig';

const BankPaymentLists = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [query, setQuery] = useState({});
  const [isDisabledButtons, setIsDisabledButtons] = useState({
    execute: true,
    remove: true,
    confirm: true,
  });
  const {
    getPaymentList,
    paymentList,
    getExecutePaymentList,
    getNewPaymentListForm,
    newPaymentListForm,
    executePaymentList,
    getNewPaymentList,
    errorMess,
    setErrorMess,
    confirmNewPaymentList,
    removePaymentList,
    setExecutePaymentList,
    getPaymentListNumber,
  } = useBankPaymentLists();
  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };
  const resetTableData = () => {
    setExecutePaymentList(null);
  };
  useEffect(() => {
    const { bank, payList } = query || {};

    const isDisabled = !bank?.label || !payList?.label;

    setIsDisabledButtons((prevState) => ({
      ...prevState,
      execute: isDisabled,
      remove: !payList?.label,
    }));
  }, [query]);

  useEffect(() => {
    if (executePaymentList?.forSave) {
      const isConfirmed = executePaymentList?.forSave?.some(
        (row) => row.salaryEntryID > 0,
      );

      setIsDisabledButtons((prevState) => ({
        ...prevState,
        confirm: isConfirmed,
      }));
    }
  }, [executePaymentList?.forSave]);

  useEffect(() => {
    getPaymentList();
  }, []);

  const resetFields = () => {
    const resetObject = {
      label: '',
      value: '',
      id: '',
    };

    setQuery((prevQuery) => ({
      ...prevQuery,
      payList: { ...resetObject },
      bank: { ...resetObject },
    }));
  };

  const dataToExcel = ConvertBankPaymentListDataExcel(
    executePaymentList?.forSave,
  );

  return (
    <div>
      <Header
        btnText="Նոր վճարացուցակ"
        pageTitle="Բանկի վճարացուցակներ"
        handelClick={onClose}
        showCalculationDate={true}
        disabledAddBtn={!query?.bank?.value}
      />
      <BankPaymentListsActions
        paymentList={paymentList}
        query={query}
        setQuery={setQuery}
        getExecutePaymentList={getExecutePaymentList}
        isDisabledButtons={isDisabledButtons}
        setIsDisabledButtons={setIsDisabledButtons}
        resetTableData={resetTableData}
        getPaymentListNumber={getPaymentListNumber}
        tableData={executePaymentList?.forSave}
        dataToExcel={dataToExcel}
      />
      <BankPaymentListsTable
        confirmNewPaymentList={confirmNewPaymentList}
        removePaymentList={removePaymentList}
        mainData={executePaymentList?.forSave}
        paymentNum={query?.payList?.id}
        currentBankCode={query?.bank?.value}
        isDisabledButtons={isDisabledButtons}
        setIsDisabledButtons={setIsDisabledButtons}
        resetTableData={resetTableData}
        resetFields={resetFields}
      />

      {showAddForm && (
        <NewPaymentListForm
          handleClose={onClose}
          getNewPaymentListForm={getNewPaymentListForm}
          bankCode={query?.bank?.value}
          newPaymentListForm={newPaymentListForm}
          getNewPaymentList={getNewPaymentList}
          setIsDisabledButtons={setIsDisabledButtons}
        />
      )}

      <Alert description={errorMess} onClose={setErrorMess} />
    </div>
  );
};

export default BankPaymentLists;
