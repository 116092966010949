import React, { useEffect } from 'react';
import EventEditForm from './EventEditForm';
import DeleteSubdivision from './DeleteSubdivision';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { filterCurrentEmployItem } from '../../../App/Redux/SalaryStor/employeesSlices/useAction';

const Forms = ({ formType, closeModal }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (type === 'Employees') {
      history.push(`/Employees`);
      dispatch(
        filterCurrentEmployItem({ name: value.depName, id: value.depNo }),
      );
    }
    return () => {
      dispatch(filterCurrentEmployItem({ name: null, id: null }));
    };
  }, [type, history]);

  switch (type) {
    case 'delete':
      return (
        <DeleteSubdivision id={value?.workDepID} handleClose={handleClose} />
      );
    case 'edit':
      return <EventEditForm rowValues={value} handleClose={handleClose} />;

    default:
      return null;
  }
};

export default Forms;
