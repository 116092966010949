import upArrow from '../../assets/icons/upArrow.svg';

export const additionsTypeField = [
  {
    fieldType: 'input',
    type: 'string',
    name: 'stNo',
    label: 'Կոդը',
    value: '',
    required: true,
    maxlength: 3,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'stName',
    label: 'Անվանումը',
    value: '',
    required: true,
    maxlength: 50,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Տաբել',
    fieldName: 'graphName',
    dropdownId: 'graphNo',
    optionName: 'graphs',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.graphName,
        id: i.graphNo,
        value: i.graphName,
      }));
    },
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'Թղթակցող հաշիվ (դեբետ)',
    fieldName: 'tAcc',
    dropdownId: 'tAcc',
    optionName: 'accounts',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.accountCode + ' ' + i.accountName,
        id: i.accountCode,
        value: i.accountName,
      }));
    },
  },

  {
    fieldType: 'checkbox',
    name: 'taxSign',
    label: 'Եկամտահարկը պահվում է',
    value: '',
  },
  {
    fieldType: 'checkbox',
    name: 'pensionSign',
    label: 'Սոցիալական վճարը պահվում է',
    value: '',
  },
  {
    fieldType: 'checkbox',
    name: 'tuSign',
    label: 'Արհմիութենական հարկը պահվում է',
    value: '',
  },
  {
    fieldType: 'checkbox',
    name: 'stampSign',
    label: 'Դրոշմանիշային վճարը պահվում է',
    value: '',
  },

  {
    fieldType: 'checkbox',
    name: 'forTaxOnly',
    label: 'Միայն հարկերի հաշվառման համար',
    value: '',
    disabled: (graphNo) => {
      return graphNo > 0;
    },
  },

  {
    fieldType: 'radio',
    required: false,
    radioTitle: 'Արձակուրդային միջինին',
    radioBtn: [
      {
        label: 'Մասնակցում է',
        value: 0,
        name: 'hm',
      },
      {
        label: 'Չի մասնակցում',
        value: 1,
        name: 'hm',
      },

      {
        label: 'Մասնակցում է 1/12 մասով',
        value: 2,
        name: 'hm',
        disabled: (graphNo) => {
          return graphNo > 0;
        },
      },
    ],
  },

  {
    fieldType: 'upArrow',
    label: 'Հաշվարկվում է այլ հավելումներից',
    name: 'calcSign',
    value: false,
    icon: upArrow,
  },
  {
    fieldType: 'onHoldList',
  },
];
