export const GET_PARTNER_DATA = "GET_PARTNER_DATA";
export const EDIT_PARTNER_DATA = "EDIT_PARTNER_DATA";
export const DELETE_PARTNER_DATA = "DELETE_PARTNER_DATA";
export const LOADING = "LOADING";

export const GET_PARTNER_BALANCE = "GET_PARTNER_BALANCE";
export const EDIT_PARTNER_BALANCE = "EDIT_PARTNER_BALANCE ";
export const DELETE_PARTNER_BALANCE = "DELETE_PARTNER_BALANCE";

export const GET_PARTNER_BANK = "GET_PARTNER_BANK";
export const EDIT_PARTNER_BANK = "EDIT_PARTNER_BANK";
export const DELETE_PARTNER_BANK = "DELETE_PARTNER_BANK";
