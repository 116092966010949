import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest } from '../../Api/Api';
import { deletePartnerAction } from '../../Redux/partnerSlices/useAction';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../Components/Alert';

const DeletePartner = ({ deleteRowId, closeModal }) => {
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  const deleteRowDate = () => {
    deleteRequest(`deletePartner/${deleteRowId}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deletePartnerAction(deleteRowId));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  };

    return (
      <>
        <AcceptOrCancelModal
          closeModal={closeModal}
          confirmClick={deleteRowDate}
        />
        <Alert description={errorHandler} onClose={setErrorHandler} />
      </>
    );
};

export default DeletePartner;
