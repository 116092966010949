import React from 'react';
import { dateFormat } from '../../../Utilities/Utilities';

const OperationsHeader = ({ mainData }) => {
  const { accountType, invoiceSerialNumber, supplierDate } = mainData || {};
  const formulationsTitle = [
    'Տեսակը',
    'Սերիա և համարը',
    'Մատակարարման ամսաթիվ',
  ];

  const formulationValue = [
    accountType,
    invoiceSerialNumber,
    dateFormat(supplierDate && supplierDate),
  ];

  return (
    <>
      <div className="L-operations-wrapper G-flex">
        <div className="L-operations-panel">
          {formulationsTitle &&
            formulationsTitle?.map((title, index) => {
              return (
                <h3 className="title-h3 " key={index}>
                  {title}
                </h3>
              );
            })}
        </div>
        <div className="L-operations-panel">
          {formulationValue &&
            formulationValue?.map((value, index) => {
              return (
                <p key={index}>
                  {value !== '' && value !== null ? value : '...'}
                </p>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default OperationsHeader;
