export const AllowedAmountAmd = (values) => {
  const firstAccount = values?.firstAccount;
  const secondAccount = values?.secondAccount;

  if (values?.debitCredit === 1) {
    if (
      (firstAccount?.includes('2511') && secondAccount?.includes('253')) ||
      (firstAccount?.includes('253') && secondAccount?.includes('2511')) ||
      (firstAccount?.includes('252') && secondAccount?.includes('2512')) ||
      (firstAccount?.includes('2512') && secondAccount?.includes('252')) ||
      (firstAccount?.includes('253') && secondAccount?.includes('252')) ||
      (firstAccount?.includes('252') && secondAccount?.includes('253')) ||
      (firstAccount?.includes('2511') && secondAccount?.includes('2512')) ||
      (firstAccount?.includes('2512') && secondAccount?.includes('2511'))
    ) {
      return true;
    }
  }

  if (values?.debitCredit === 2) {
    if (
      (secondAccount?.includes('2511') && firstAccount?.includes('253')) ||
      (secondAccount?.includes('253') && firstAccount?.includes('2511')) ||
      (secondAccount?.includes('252') && firstAccount?.includes('2512')) ||
      (secondAccount?.includes('2512') && firstAccount?.includes('252')) ||
      (secondAccount?.includes('253') && firstAccount?.includes('252')) ||
      (secondAccount?.includes('252') && firstAccount?.includes('253')) ||
      (secondAccount?.includes('2511') && firstAccount?.includes('2512')) ||
      (secondAccount?.includes('2512') && firstAccount?.includes('2511'))
    ) {
      return true;
    }
  }

  return false;
};
