import React from 'react';
import AcceptOrCancelModal from '../../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../../../App/Components/Alert';
import useAssignAdditionalStorage from '../../../hooks/useAssignAdditionalStorage';
import { useDispatch } from 'react-redux';
import { updateWithEmployeesSalaryAction } from '../../../../App/Redux/SalaryStor/workWithEmployeesSlices/useAction';

const DeleteAssignPositionItem = ({ id, handleClose, assignPosition = [] }) => {
  const { deleteAssignAdditional, errorMess, setErrorMess } =
    useAssignAdditionalStorage();
  const dispatch = useDispatch();

  const { sAmount, empNo } = assignPosition[1] || {};

  const onRemoveHandler = async () => {
    try {
      await deleteAssignAdditional(id, handleClose);

      if (!errorMess && empNo && sAmount !== undefined) {
        dispatch(
          updateWithEmployeesSalaryAction({
            empNo,
            sAmount,
          }),
        );
      }
    } catch (err) {
      console.error('An error occurred during removal:', err);
    }
  };

  const handleAlertClose = () => {
    setErrorMess('');
    handleClose();
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />

      {errorMess && (
        <Alert description={errorMess} onClose={handleAlertClose} />
      )}
    </>
  );
};

export default DeleteAssignPositionItem;
