import React, { useMemo } from 'react';
import Table from '../../../Components/Table/Table';
import { Commas } from '../../../Utilities/Utilities';

const Banks = ({ data }) => {
  const bankData = data !== null ? data?.remainderBank : [];

  const columnConfig = useMemo(
    () => [
      {
        title: 'Հաշիվ',
        cell: (row) => row.accountCode,
        customStyle: { maxWidth: 60 },
      },

      {
        title: 'Հաշվի անվանումը',
        cell: (row) => row.accountName,
        customStyle: { maxWidth: 310, overflow: 'hidden' },
      },
      {
        title: 'Բանկ',
        cell: (row) => row.bankName,
        customStyle: { maxWidth: 250, overflow: 'hidden' },
      },
      {
        title: 'Արժույթ',
        cell: (row) => row.currencyName,
        customStyle: { maxWidth: 60 },
      },
      {
        title: 'Օրվա մնացորդը',
        cell: (row) => Commas(row.amountDebit),
        customStyle: { maxWidth: 120 },
      },
    ],
    [],
  );
  return (
    <>
      {bankData && (
        <Table
          customClass="L-dashboard-tables L-cash-registers-table"
          data={bankData}
          columnConfig={columnConfig}
        />
      )}
    </>
  );
};

export default Banks;
