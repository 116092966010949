import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Action from '../../../App/Components/Table/Action';
import {
  Commas,
  dateFormat,
  tableActionTitle,
} from '../../../App/Utilities/Utilities';
import { workWithEmployeesActionItem } from '../../Config/tableConfig';
import Forms from './Forms';
import Loading from '../../../App/Components/Loading/Loading';

const WorkWithEmployeesTable = ({ mainData, loading }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 280, overflow: 'hidden' },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Ազգանուն, Անուն, Հայրանուն',
      cell: (row) => row.emplName,
      customStyle: { maxWidth: 280, overflow: 'hidden' },
    },
    {
      title: 'Ընդունվել է',
      cell: (row) => dateFormat(row.hireDate),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Ազատվել է',
      cell: (row) => (row.fireDate ? dateFormat(row.fireDate) : null),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Պաշտոնը',
      cell: (row) => row.positionName,
      customStyle: { maxWidth: 300, overflow: 'hidden' },
    },
    {
      title: 'Աշխատաժամ. տաբել',
      cell: (row) => row.graphName,
      customStyle: { maxWidth: 300, overflow: 'hidden' },
    },
    {
      title: 'Հաստիքը',
      cell: (row) => Commas(row.salary),
      customStyle: { maxWidth: 110 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(value, row) => {
            setShowForm({
              type: value,
              value: row,
            });
          }}
          actionItem={workWithEmployeesActionItem}
          mainActionName=""
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 190 },
    },
  ];
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="work-with-employees-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : null}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default WorkWithEmployeesTable;
