import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addOrganizationDetails,
  fetchOrganizationDetails,
  updateOrganizationDetails,
} from '../../services/fetchOrganizationDetails';
import {
  editOrganizationAction,
  getOrganizationAction,
} from '../../Redux/organizationSlices/useAction';

const useOrganizationDetails = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [error, setError] = useState('');
  const [errorMess, setErrorMess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { organizationData } = useSelector(
    (state) => state.getOrganizationData,
  );

  const dispatch = useDispatch();
  const getOrganization = async () => {
    setIsLoading(true);
    try {
      const data = await fetchOrganizationDetails(currentCompanyID);
      dispatch(getOrganizationAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addOrganization = async (newOrganization) => {
    try {
      const { errorCode, message } = await addOrganizationDetails(
        newOrganization,
      );
      if (message) {
        setErrorMess(message);
      }
      if (errorCode === 0) {
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateOrganization = async (updateOrganization) => {
    setIsLoading(true);

    try {
      const { errorCode, message } = await updateOrganizationDetails(
        updateOrganization,
      );

      if (errorCode === 0 && !message) {
        setErrorMess('Փոփոխությունը գրանցվեց');
        dispatch(editOrganizationAction(updateOrganization));
      } else {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOrganization();
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    error,
    errorMess,
    setErrorMess,
    organizationData,
    updateOrganization,
  };
};

export default useOrganizationDetails;
