import { createAction } from "../actionCreators";
import {
  GET_CASH_OUT_FLOW_DATA,
  LOADING,
  DELETE_CASH_OUT_FLOW,
  EDIT_CAS_OUT_FLOW_DATA,
  UPDATE_PARTNER_CASH_OUT,
} from "./constants";

export const getCashOutflowOrderAction = createAction(GET_CASH_OUT_FLOW_DATA);
export const loadingAction = createAction(LOADING, false);
export const deleteCashOutflowOrderAction = createAction(DELETE_CASH_OUT_FLOW);
export const editCashOutflowOrderAction = createAction(EDIT_CAS_OUT_FLOW_DATA);
export const updatePartnerAction = createAction(UPDATE_PARTNER_CASH_OUT);
