import React from 'react';
import { useMemo } from 'react';
import { SelectControl } from '../SelectControl/SelectControl';

const SelectDocTypes = (props) => {
  const { docTypesData, docTypesVal, selectChangeDocType, customClass } = props;
  const docTypesOptions = useMemo(() => {
    return (
      docTypesData &&
      docTypesData?.map((data) => {
        return {
          label: data.docName,
          id: data.docTypeID,
          value: data.docName,
        };
      })
    );
  }, [docTypesData]);

  return (
    <div className={`${customClass} G-docTypes-wrapper`}>
      <p className="G-title-p">Փաստաթղթի տեսակը</p>
      <SelectControl
        id="docTypes"
        options={docTypesOptions}
        value={docTypesVal}
        onChange={selectChangeDocType}
      />
    </div>
  );
};

export default SelectDocTypes;
