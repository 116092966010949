import {
  CHECK_ALL_CREDIT,
  CHECK_ALL_DEBIT,
  CHECK_CREDIT,
  CHECK_DEBIT,
  GET_CIRCULATION_DATA,
  GET_SUMMARY_DATA,
  LOADING,
} from './constants';

const initialState = {
  circulationData: null,
  isLoading: false,
  checkPrintSingAll: false,
  summaryData: null,
};

export const circulationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CIRCULATION_DATA:
      return {
        ...state,
        circulationData: payload,
        isLoading: true,
      };

    case CHECK_ALL_DEBIT:
      return {
        ...state,
        ...state.circulationData,
        circulationData: [
          ...state.circulationData?.map((item) => {
            return { ...item, debitSign: payload };
          }),
        ],
      };

    case CHECK_DEBIT:
      return {
        ...state,
        ...state.circulationData,
        circulationData: [
          ...state.circulationData?.map((item) => {
            if (item.accountCode === payload.accountCode) {
              return { ...item, debitSign: !payload.debitSign };
            } else {
              return item;
            }
          }),
        ],
      };

    case CHECK_ALL_CREDIT:
      return {
        ...state,
        ...state.circulationData,
        circulationData: [
          ...state.circulationData?.map((item) => {
            return { ...item, creditSign: payload };
          }),
        ],
      };

    case CHECK_CREDIT:
      return {
        ...state,
        ...state.circulationData,
        circulationData: [
          ...state.circulationData?.map((item) => {
            if (item.accountCode === payload.accountCode) {
              return { ...item, creditSign: !payload.creditSign };
            } else {
              return item;
            }
          }),
        ],
      };
    case GET_SUMMARY_DATA:
      return {
        ...state,
        summaryData: payload,
      };

    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return { ...state };
  }
};
