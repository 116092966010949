export const partnerTypeOptions = [
  {
    name: 'Գնորդ',
    value: 1,
  },
  {
    name: 'Մատակարար',
    value: 2,
  },
  {
    name: 'Գնորդ/Մատակարար',
    value: 3,
  },
  {
    name: 'Առհաշիվ անձ',
    value: 4,
  },
  { name: 'Այլ', value: 5 },
];

export const spendingMoneyList = [
  {
    name: 'Գործառնություն',
    value: 'operations',
  },
  {
    name: 'Խմբագրել',
    value: 'edit',
  },
  {
    name: 'Ջնջել',
    value: 'delete',
  },
  {
    name: 'Դիտել/տպել',
    value: 'print',
  },
  {
    name: 'Փակել փաստաթուղթը',
    value: 'closeDocument',
  },
];

export const EDPList = [
  {
    name: 'Խմբագրել',
    value: 'edit',
  },
  {
    name: 'Ջնջել',
    value: 'delete',
  },
  {
    name: 'Տպել',
    value: 'print',
  },
];

export const cashOrdersList = [
  {
    name: 'Խմբագրել',
    value: 'edit',
  },
  {
    name: 'Ջնջել',
    value: 'delete',
  },
  {
    name: 'Տպել',
    value: 'print',
  },
  {
    name: 'Պատճենել',
    value: 'copy',
  },
];

export const AEPDList = [
  {
    name: 'Ավելացնել',
    value: 'add',
  },
  {
    name: 'Խմբագրել',
    value: 'edit',
  },

  {
    name: 'Ջնջել',
    value: 'delete',
  },
];

export const activePassiveType = [
  {
    type: 'Ակտիվային',
    typeID: '1',
  },
  {
    type: 'Պասիվային',
    typeID: '2',
  },
  {
    type: 'Ակտիվ/պասիվային',
    typeID: '3',
  },
];

export const currencyData = [
  {
    name: 'AMD',
    value: 'AMD',
  },
  {
    name: 'USD',
    value: 'USD',
  },
  {
    name: 'EUR',
    value: 'EUR',
  },
  {
    name: 'RUB',
    value: 'RUB',
  },
];

export const selectPartnerAction = [
  {
    name: 'Խմբագրել',
    value: 'edit',
  },
  {
    name: 'Սկզբ. մնացորդ',
    value: 'balance',
  },
  {
    name: 'Բանկ',
    value: 'bank',
  },
  {
    name: 'Ջնջել',
    value: 'delete',
  },
];





export const editDeleteList = [
  {
    name: "Խմբագրել",
    value: "edit",
  },
  {
    name: "Ջնջել",
    value: "delete",
  },
];

export const currencies = [
  {
    currencyCode: "AMD",
    currencyID: 1,
  },
  {
    currencyCode: "USD",
    currencyID: 2,
  },
  {
    currencyCode: "EUR",
    currencyID: 3,
  },
  {
    currencyCode: "RUB",
    currencyID: 4,
  },
  {
    currencyCode: "GBP",
    currencyID: 5,
  },
  {
    currencyCode: "GEL",
    currencyID: 6,
  },
];
