import React from 'react';
import pencilIcon from '../../Assets/icons/pencil.svg';

const RenderUserDataSections = ({ userSections, toggleModal }) => {
  return userSections.map(({ title, info, modal }, index) => (
    <div className="user-data user-data-block-solid" key={index}>
      <p className="G-title-p">{title}</p>
      <div className="user-info">
        {info.map((item, idx) => (
          <span key={idx}>{item}</span>
        ))}
      </div>
      <span className="pencil-box" onClick={() => toggleModal(modal)}>
        <img src={pencilIcon} alt="edit icon" />
      </span>
    </div>
  ));
};

export default RenderUserDataSections;
