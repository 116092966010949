import React from 'react';

const HeaderItem = ({ name, value }) => (
  <div className="L-employee-position-i">
    {name && <h4>{name}</h4>}
    <span>{value}</span>
  </div>
);

const EmployeePositionHeader = ({ data }) => {
  const { depName, empNo, emplName } = data || {};

  const headerList = [
    {
      value: depName,
    },
    {
      name: 'Տաբել N',
      value: empNo,
    },
    {
      value: emplName,
    },
  ];

  return (
    <div className="L-employee-position-header">
      {headerList.map((item, index) => (
        <HeaderItem key={index} name={item.name} value={item.value} />
      ))}
    </div>
  );
};

export default EmployeePositionHeader;
