import React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import HelpBtn from '../HelpBtn/HelpBtn';
import HelperContent from '../HelperContent/HelperContent';
import { HelperTextArr } from '../HelperText/HelperText';
import Accounting from '../../Assets/image/accounting.png';
import MainContainer from '../MainContainer/MainContainer';
import './rightPanel.scss';

const RightPanel = ({ children, sidebar }) => {
  const [helperText, setHelperText] = useState('');

  let location = useLocation();
  const dashboard = location.pathname === '/Dashboard';

  const helpHandler = () => {
    return HelperTextArr?.map(({ path, text }) => {
      return path === location.pathname ? setHelperText(text) : path;
    });
  };

  return (
    <div className={`G-right-panel ${sidebar ? 'G-active-side' : ''}`}>
      {dashboard ? (
        <div className="G-accounting-logo-wrap">
          <div className="L-logo-box">
            <img src={Accounting} alt="" />
          </div>
          <h3> Հաշվապահական հաշվառում</h3>
        </div>
      ) : null}

      <HelpBtn helpHandler={helpHandler} />
      <MainContainer children={children} />
      {helperText && (
        <HelperContent
          closeHelpBox={setHelperText}
          helperMessage={helperText}
          locationPathname={location.pathname}
        />
      )}
    </div>
  );
};

export default RightPanel;
