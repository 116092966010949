import React, { useEffect } from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import useSavingsForm from '../../hooks/useSavingsForm';
import Alert from '../../../App/Components/Alert';
const DeleteSaving = ({ id, handleClose }) => {
  const { removeSaving, errorMess, setErrorMess } = useSavingsForm();
  useEffect(() => {
    return () => {
      setErrorMess('');
    };
  }, []);

  const onRemoveHandler = async () => {
    try {
      await removeSaving(id, handleClose);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default DeleteSaving;
