import React from "react";
import { useDispatch } from "react-redux";
import Table from "../../Components/Table/Table";
import { deleteInvoiceGoodsAction } from "../../Redux/invoiceSlices/useAction";
import {
  formatNumberWithCommas,
  removeCommas,
} from '../../Utilities/Utilities';

const InvoiceGoodsTable = ({ mainData }) => {
  const dispatch = useDispatch();

  const columnConfig = [
    {
      title: 'Ապրանքի, ծառայություն անվանումը',
      cell: (row) => row.productName,
      customStyle: { maxWidth: 600 },
    },
    {
      title: 'Չափման միավորը',
      cell: (row) => row.unitName,
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Քանակը',
      cell: (row) => row.amount,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գինը',
      cell: (row) => formatNumberWithCommas(row.price),
      customStyle: { maxWidth: 110 },
    },

    {
      title: 'Գումարը',
      cell: (row) =>
        !row.totalPrice
          ? formatNumberWithCommas(row.amount * removeCommas(row.price))
          : formatNumberWithCommas(row.totalPrice),

      customStyle: { maxWidth: 110 },
    },

    {
      title: 'Գործողություն',
      cell: (row) => (
        <span
          className="G-delete-span"
          onClick={() => deleteInvoiceGood(row.goodID)}
        >
          Ջնջել
        </span>
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  const deleteInvoiceGood = (goodID) => {
    dispatch(deleteInvoiceGoodsAction(goodID));
  };

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="G-table-has-scroll L-invoice-goods-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default InvoiceGoodsTable;
