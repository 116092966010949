export const actionItem = [
  {
    name: 'Աշխատակիցներ',
    value: 'Employees',
  },
  {
    name: 'Խմբագրել',
    value: 'edit',
  },

  {
    name: 'Ջնջել',
    value: 'delete',
  },
];
