import { createAction } from '../../actionCreators';
import {
  DELETE_TABLE,
  FETCH_TABLES,
  FETCH_TABLES_FORM,
  UPDATE_TABLE,
} from './constants';

export const fetchTablesAction = createAction(FETCH_TABLES);
export const deleteTablesAction = createAction(DELETE_TABLE);
export const updateTablesAction = createAction(UPDATE_TABLE);
export const fetchTableFormAction = createAction(FETCH_TABLES_FORM);
