import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchProvisions } from '../services/fetchProvisions';

const useProvisions = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [provisions, setProvisions] = useState([]);

  const getProvisions = async () => {
    setIsLoading(true);
    try {
      const data = await fetchProvisions(currentCompanyID);
      setProvisions(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setIsLoading(false);
    };
  }, []);
  return {
    isLoading,
    error,
    getProvisions,
    provisions,
  };
};

export default useProvisions;
