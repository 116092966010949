import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';

export const fetchMaterialUnitsMeasure = async (currentCompanyID, params) => {
  const updatedParams = {
    ...params,
    pageNumber: params.pageNumber + 1,
  };
  const queryRoot = urlEncodeQueryParams(updatedParams);
  try {
    const response = await getRequest(
      `getUnits?companyID=${currentCompanyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addNewUnitsMeasure = async (newUnit) => {
  try {
    const response = await postRequest('addUnit', newUnit);
    return response.data;
  } catch (error) {
    console.error('Error adding UnitsMeasure:', error);
    throw error;
  }
};
export const updateUnitsMeasure = async (updateUnits) => {
  try {
    const response = await putRequest('editUnit', updateUnits);
    return response.data;
  } catch (error) {
    console.error('Error adding UnitsMeasure:', error);
    throw error;
  }
};

export const removeUnitsMeasure = async (unitID) => {
  try {
    const response = await deleteRequest(`deleteUnit/${unitID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting UnitsMeasure:', error);
    throw error;
  }
};
