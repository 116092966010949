import upArrow from '../../assets/icons/upArrow.svg';
export const SavingFields = [
  {
    fieldType: 'input',
    type: 'text',
    name: 'sTypeNo',
    label: 'Կոդը',
    value: '',
    required: true,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'sTypeName',
    label: 'Անվանումը',
    value: '',
    required: true,
    maxlength: 50,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Թղթակցող հաշիվ (կրեդիտ)',
    fieldName: 'tAcc',
    dropdownId: 'tAcc',
    optionName: 'accounts',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.accountCode + ' ' + i.accountName,
        id: i.accountCode,
        value: i.accountName,
      }));
    },
  },

  {
    fieldType: 'upArrow',
    label: 'Պահվում է հավելումներից',
    name: 'calcSign',
    value: '',
    icon: upArrow,
  },
  {
    fieldType: 'onHoldList',
  },
];
