import React from 'react';
import './FullCalendarHeader.scss';
import TopArrow from '../../App/Components/TopArrow/TopArrow';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { mainPage } from '../../App/Utilities/Utilities';

const FullCalendarHeader = () => {
  const history = useHistory();

  const clearUser = () => {
    history.push(mainPage);
  };

  return (
    <div className="L-full-calendar-header">
      <div className="" onClick={clearUser}>
        <TopArrow />
      </div>
    </div>
  );
};

export default FullCalendarHeader;
