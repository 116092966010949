import React, { useEffect } from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { actionConfigs } from './actionConfigs';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import Button from '../../../App/Components/Button/Button';
import { filterObjKeys, printHandler } from '../../../App/Utilities/Utilities';
import { useDispatch, useSelector } from 'react-redux';

const CalculationSheetAction = ({
  query,
  setQuery,
  calculationSheetData,
  getCalculationSheetMonth,
  getExecuteCalculationSheet,
  resetTableData,
}) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const optionsData = calculationSheetData || [];
  const dispatch = useDispatch();
  const handleDropdownChange = async (selectedOption, config) => {
    const { optionName, optionID } = config || {};
    const option = selectedOption || { value: '', id: null };
    resetTableData();
    setQuery((prevQuery) => ({
      ...prevQuery,
      [optionName]: option.value,
      [optionID]: option.id,
    }));

    // if (optionName === 'years') {
    //   await getCalculationSheetMonth(option.value);
    // }
  };
  const handleButtonClick = (config) => {
    const { onClickHandler, actionType } = config || {};
    const { year, month, depNo } = query || {};
    const keysToRemove = ['salaryMonths', 'workDeps', 'years'];

    const modifyObj = filterObjKeys(query, keysToRemove);

    if (typeof onClickHandler === 'function') {
      switch (actionType) {
        case 'print':
          if (!year || !month) {
            return;
          }
          const url = `printEmployeesData?companyID=${currentCompanyID}&year=${year}&month=${month}&depNo=${
            depNo || ''
          }`;
          printHandler(url, dispatch);
          break;

        default:
          getExecuteCalculationSheet(modifyObj);
          break;
      }
    }
  };

  useEffect(() => {
    if (query?.year) {
      getCalculationSheetMonth(query.year);
    }
  }, [query.year]);

  return (
    <SearchBackground>
      <div className="L-calculation-sheet-action-box">
        {actionConfigs?.map((config, index) => {
          if (config.type === 'dropdown') {
            const dropDownOptions = optionsData
              ? config.Children(optionsData[config.optionName])
              : [];

            return (
              <div className="L-calculation-sheet-filed" key={index}>
                <SelectControl
                  value={{ label: query[config.optionName] }}
                  selectBoxTitle={config.title}
                  options={dropDownOptions || []}
                  onChange={(selectedOption) =>
                    handleDropdownChange(selectedOption, config)
                  }
                  requiredFiled={config.required}
                />
              </div>
            );
          } else if (config.type === 'button') {
            return (
              <div className="G-add-btn" key={index}>
                <Button
                  type="button"
                  text={config.buttonText}
                  onClick={() => handleButtonClick(config)}
                  disabled={config.disabled(query)}
                  customClass={
                    config.disabled(query) ? 'G-disabled-button' : ''
                  }
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </SearchBackground>
  );
};

export default CalculationSheetAction;
