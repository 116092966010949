import React from 'react';
import { MAIN_URL } from '../../Utilities/Utilities';

const HelpDetails = () => {
  const initialName = localStorage.getItem('initialName');
  const documentTitle = localStorage.getItem('documentTitle');
  document.title = documentTitle;
  const url = `${MAIN_URL}/getDetailFile?detailName=${initialName}`;

  return (
    <object data={url} width="100%" height="900px" name="">
      -
    </object>
  );
};

export default HelpDetails;
