import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRequest, postRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import { updateUser } from '../../Redux/authSlices/useAction';
import { getOrganizationAction } from '../../Redux/organizationSlices/useAction';
import { dataTimeFormat } from '../../Utilities/Utilities';
import OrganizationForm from './OrganizationForm';

const OrganizationAddForm = () => {
  const { currentCompanyID, userID } = useSelector((state) => state.auth);
  const [errorHandler, setErrorHandler] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      await getRequest(`getFirstCompany?userID=${userID}`)
        .then((res) => {
          dispatch(getOrganizationAction(res.data));
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    };

    fetchData();
  }, [userID, dispatch, history]);

  const initialValues = useMemo(
    () => ({
      userID: userID,
      currentCompanyID: currentCompanyID,
      companyName: '',
      address: '',
      city: '',
      activityAddress: '',
      taxCode: '',
      phone: '+374',
      email: '',
      headPosition: '',
      headName: '',
      accauntantName: '',
      description: '',
      companyTypeID: '',
      taxationID: '',
    }),
    [userID, currentCompanyID],
  );

  const onSubmit = (values) => {
    postRequest('addCompany', {
      ...values,
      startDate: dataTimeFormat(values.startDate),
      endDate: dataTimeFormat(values.endDate),
    })
      .then((res) => {
        const { errorCode, message, id } = res.data;
        if (errorCode === 0) {
          if (id !== 0) {
            dispatch(
              updateUser({
                currentCompanyID: id,
                currentCompanyName: message,
                companySign: false,
              }),
            );
          }
          history.push('/Organization');
        } else {
          setErrorHandler(message || 'Unknown error occurred.');
        }
      })
      .catch((err) => {
        console.error('Error occurred:', err);
        setErrorHandler(
          'An unexpected error occurred. Please try again later.',
        );
      });
  };

  return (
    <div className="L-organization-wrapper">
      <div className="L-organization-title">
        <h3>Մուտքագրել ձեր կազմակերպության տվյալները </h3>
      </div>
      <div className="L-organization-form ">
        <OrganizationForm initialData={initialValues} onSubmit={onSubmit} />
      </div>
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </div>
  );
};

export default OrganizationAddForm;
