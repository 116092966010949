import React from 'react';
import Modal from '../../Components/Modal/Modal';
import Button from '../../Components/Button/Button';

const CheckNewEmailMes = ({
  currentEmail = '',
  onRemoveUserData,
  closeModal,
}) => {
  const handleClose = () => closeModal(false);
  return (
    <Modal
      customClass="L-email-mess-modal"
      closeHandler={handleClose}
      showCloseIcon={false}
    >
      <div className="">
        <p>{`Ստուգեք Ձեր " ${currentEmail} " Էլեկտրոնային հասցեն `}</p>
        <div className="G-flex-justify-end">
          <div className="email-btn-wrap">
            <Button text="ok" onClick={onRemoveUserData} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckNewEmailMes;
