export const confirmActionFields = [
  {
    fieldType: 'date',
    dateName: 'salaryDate',
    dateTitle: 'Ամսաթիվը',
    required: true,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Հավելում',
    fieldName: 'stNo',
    dropdownId: 'stNo',
    optionName: 'salaryTypes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.stName,
        id: i.stNo,
        value: i.stName,
      }));
    },
    required: false,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Ստորաբաժանում',
    fieldName: 'depNo',
    dropdownId: 'depNo',
    optionName: 'workDeps',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.depName,
        id: i.depNo,
        value: i.depName,
      }));
    },
    required: false,
  },
];
