import React from 'react';
import Calendar from '../../Components/Calendar/Calendar';
import InputFiled from '../../Components/InputFiled/InputFiled';

import { dateFormat } from '../../Utilities/Utilities';

const ReportingPersonDoc = (props) => {
  const {
    partnerName,
    disabledPartnerName,
    docNum,
    changeHandler,
    docDate,
    changeCalendar,
  } = props;
  return (
    <div className="L-purchase-act-view-block G-flex">
      <div className="G-purchase-rep-person-input">
        <InputFiled
          inputTitle="Հաշվետու անձ"
          requiredFiled={true}
          value={partnerName}
          name="partnerName"
          disabled={disabledPartnerName}
          changeHandler={changeHandler}
        />
      </div>
      <div className="G-docNum-input-not-border">
        <InputFiled
          inputTitle="Փաստաթուղթ N"
          name="docNum"
          value={docNum}
          changeHandler={changeHandler}
        />
      </div>
      <div className="">
        <p className="G-title-p">Կազմման ամսաթիվ</p>
        <Calendar value={dateFormat(docDate)} changeHandler={changeCalendar} />
      </div>
    </div>
  );
};

export default ReportingPersonDoc;
