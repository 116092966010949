import React from 'react';
import { useState } from 'react';

const FileUpload = ({ setLocalFile }) => {
  const [filename, setFilename] = useState('Չկա ընտրված ֆայլ');
  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    setLocalFile(selectedFile);
    setFilename(selectedFile.name);
  };

  return (
    <div className="file-upload-wrap">
      <div className="L-custom-label" htmlForm="file">
        {filename}
      </div>

      <label htmlFor="file" className="L-upload-label">
        <input
          type="file"
          name="file"
          id="file"
          onChange={handleChange}
          className="L-uploader-input"
        />
      </label>
    </div>
  );
};

export default FileUpload;
