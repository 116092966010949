import React from 'react';
import './salaryCalc.scss';
import { Commas } from '../../../../App/Utilities/Utilities';

const SalaryDisplay = ({ displayData }) => (
  <div className="">
    {displayData?.map((item, index) => (
      <div key={index} className="L-salary-display-item">
        <p>{item.title}</p>
        <p>{Commas(item.amount)}</p>
      </div>
    ))}
  </div>
);

export default SalaryDisplay;
