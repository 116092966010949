import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Pagination from "../../Components/Pagination/Pagination";
import SearchBackground from "../../Components/SearchForm/SearchBackground";
import SearchBar from "../../Components/SearchForm/SearchBar";
import BankTable from './BankTable';
import './bank.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import {
  getBankDataAction,
  loadingAction,
} from '../../Redux/bankSlices/useAction';

const GetBankData = () => {
  const { bankData, isLoading } = useSelector((state) => state.getBankData);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchBank, setSearchBank] = useState('');
  const dispatch = useDispatch();

  const getData = (pageNumber, searchValue = '') => {
    dispatch(loadingAction(true));
    getRequest(
      `getbanks?&pageNumber=${pageNumber + 1}&searchstring=${searchValue}`,
    )
      .then((res) => {
        dispatch(getBankDataAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    getData(pageNumber, searchBank);
  }, [pageNumber, dispatch]);

  return (
    <>
      <Header pageTitle="ՀՀ բանկեր" headerBtn={false} />
      <SearchBackground>
        <div className="G-flex">
          <SearchBar
            getData={getData}
            pageNumber={pageNumber}
            searchValue={searchBank}
            setSearchValue={setSearchBank}
            setPageNumber={setPageNumber}
            name="searchstring"
          />
        </div>
      </SearchBackground>
      <BankTable mainData={bankData?.banks} loading={isLoading} />
      <Pagination
        pageCount={bankData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
    </>
  );
};

export default GetBankData;
