import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Context } from '../../../index.js';
import { Commas, dateFormat, sortByDate } from '../../Utilities/Utilities';

import moment from 'moment';
import './repdetal.scss';
import Excel from '../Excel/Excel.jsx';
import ConvertRepDetalExcel from './ConvertRepDetalExcel.js';
import IncomeExpenseSummary from '../IncomeExpenseSummary/IncomeExpenseSummary.jsx';

const Repdetal = observer(
  ({ dateStart, dateEnd, income, cost, aspectId, curr, topping }) => {
    const tableRef = useRef(null);

    const { entr, currency } = useContext(Context);

    const entrArr = entr.Entr;

    let entrAr = [];
    let entrDat = {};
    if (aspectId === 0) {
      entrDat = entrArr.filter((entr) => {
        return (
          moment(entr.crAt).format('yyyy-MM-DD') >= dateStart &&
          moment(entr.crAt).format('yyyy-MM-DD') <= dateEnd &&
          entr.curr === curr
        );
      });
    }
    if (aspectId > 0) {
      entrDat = entrArr.filter((entr) => {
        return (
          moment(entr.crAt).format('yyyy-MM-DD') >= dateStart &&
          moment(entr.crAt).format('yyyy-MM-DD') <= dateEnd &&
          entr.aspectId === aspectId &&
          entr.curr === curr
        );
      });
    }

    entrAr = entrDat;

    if (topping === '1') {
      const entrIncom = entrDat.filter((entr) => {
        return entr.accountName === 'Եկամուտ';
      });
      entrAr = entrIncom;
    }
    if (topping === '2') {
      const entrCost = entrDat.filter((entr) => {
        return entr.accountName === 'Ծախս';
      });
      entrAr = entrCost;
    }

    const entrOfG = entrAr;

    const curAr = currency.Currency;

    const glo = curAr.map((cr) => {
      const prEntrOfG = entrOfG.filter(
        (ent) => ent.accountName === 'Եկամուտ' && ent.curr === cr.name,
      );

      let prEntrCur = prEntrOfG
        .map((item) => parseFloat(item.amount))
        .reduce((prev, curr) => prev + curr, 0);

      const rasEntrOfG = entrOfG.filter(
        (ent) => ent.accountName === 'Ծախս' && ent.curr === cr.name,
      );

      let rasEntrCur = rasEntrOfG
        .map((item) => parseFloat(item.amount))
        .reduce((prev, curr) => prev + curr, 0);

      return {
        val: cr.name,
        ek: prEntrCur,
        ta: rasEntrCur,
        bl: prEntrCur - rasEntrCur,
      };
    });
    const gl = glo.filter((ent) => ent.ek > 0 || ent.ta > 0);

    const sortData = entrAr.sort(sortByDate);

    const dataConvertToExcel = entrAr && ConvertRepDetalExcel(entrAr);

    return (
      <div className="repdetal-continer">
        <div className="income-expenseSummary-flex">
          <IncomeExpenseSummary summaryData={gl} />

          <div className="excel">
            <Excel excelData={dataConvertToExcel} fileName="Հաշվետվություն" />
          </div>
        </div>

        <div className="operation-table scrolling-table has-scroll">
          <table className="my-table" ref={tableRef}>
            <thead className="table-thead">
              <tr>
                <th>Ամսաթիվ</th>
                <th>Գործառնություն</th>
                <th>Տիպ</th>

                <th>Գումար</th>
                <th>Նշում</th>
              </tr>
            </thead>
            <tbody className="table-tbody">
              {sortData?.map((en) => (
                <tr key={en.id} className="tr-size">
                  <td>{dateFormat(en.crAt)}</td>
                  <td>{en.accountName}</td>
                  <td>{en.aspectName}</td>

                  {en.accountName === 'Եկամուտ' ? (
                    <td style={{ color: '#00B526', textAlign: 'right' }}>
                      {Commas(en.amount)}
                    </td>
                  ) : (
                    <td style={{ color: 'red', textAlign: 'right' }}>
                      {Commas(en.amount)}
                    </td>
                  )}

                  <td>{en.comment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  },
);
export default Repdetal;
