export const BenefitCalculationFields = [
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Հավելումի տեսակը',
    required: true,
    fieldName: 'stName',
    dropdownId: 'stNo',
    optionName: 'salaryTypes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.stName,
        id: i.stNo,
        value: i.stName,
      }));
    },
  },

  {
    fieldType: 'dateFlex',
    dateTitle: 'Ամսաթիվ',
    required: true,
    dateFields: [
      {
        dateName: 'benStartDate',
        required: false,
      },
      {
        dateName: 'benEndDate',
        required: false,
      },
    ],
  },
  {
    fieldType: 'checkbox',
    name: 'continuedSign',
    label: 'Շարունակելի',
    value: '',
  },
  {
    fieldType: 'input',
    name: 'benDays',
    label: 'Նպաստի օրերը',
    value: '',
    required: false,
    disabled: true,
  },

  {
    fieldType: 'amount',
    type: 'number',
    name: 'monthlyAvg',
    label: 'Ամսական միջին աշխատավարձ',
    value: '',
    required: false,
    disabled: true,
  },
  {
    fieldType: 'input',
    name: 'benefitPercent',
    label: 'Նպաստի հաշվարկի %',
    value: '',
    required: false,
    disabled: true,
  },
  {
    fieldType: 'amount',
    type: 'number',
    name: 'percAmount',
    label: 'Տոկոսով հաշվարկված միջինը',
    value: '',
    required: false,
    disabled: true,
  },
  {
    fieldType: 'amount',
    name: 'scaleAmount',
    label: 'Սանդղակով հաշվարկված միջինը',
    value: '',
    required: false,
    maxLength: 2,
    disabled: true,
  },
  {
    fieldType: 'input',
    name: 'avgDays',
    label: 'Միջին օրերի թիվը',
    value: '',
    required: false,
    maxLength: 2,
    disabled: true,
  },
  {
    fieldType: 'amount',
    name: 'dailyAvg',
    label: 'Օրական միջին աշխատավարձը',
    value: '',
    required: false,
    disabled: true,
  },
  {
    fieldType: 'amount',
    name: 'amount',
    label: 'Հաշվարկվել է',
    value: '',
    required: false,
    disabled: true,
  },
];
