import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import hy from 'date-fns/locale/hy';
import './react-datepicker.css';
import InputMask from 'react-input-mask';
import './datePicker.scss';
import InputTitle from '../Input/InputTitle';

const DateComponent = (props) => {
  registerLocale('hy', hy);
  const months = [
    'Հունվար',
    'Փետրվար',
    'Մարտ',
    'Ապրիլ',
    'Մայիս',
    'Հունիս',
    'Հուլիս',
    'Օգոստոս',
    'Սեպտեմբեր',
    'Հոկտեմբեր',
    'Նոյեմբեր',
    'Դեկտեմբեր',
  ];

  return (
    <div className="G-salary-calender">
      <InputTitle
        requiredField={props.requiredFiled}
        inputTitle={props.title}
      />
      <DatePicker
        disabled={props.disabled}
        customInput={<InputMask type="text" mask="99.99.9999" />}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="G-input-group-calender">
            <div className="input-group-prepend">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  decreaseMonth();
                }}
                disabled={prevMonthButtonDisabled}
                className="btn btn-outline-secondary"
                type="button"
              >
                {'<'}
              </button>
            </div>
            <input
              type="number"
              onChange={({ target: { value } }) => changeYear(value)}
              value={date?.getFullYear()}
              className="form-control"
            />

            <select
              className="select-form-control"
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months?.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <div className="input-group-append">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  increaseMonth();
                }}
                className="btn btn-outline-secondary"
                disabled={nextMonthButtonDisabled}
              >
                {'>'}
              </button>
            </div>
          </div>
        )}
        wrapperClassName={props.wrapperClassName}
        className="form-control"
        dateFormat="dd.MM.yyyy"
        locale="hy"
        selected={props.selected}
        onChange={(date) => props.onChange(date, props.name)}
        value={props.value || null}
        name={props.name}
        maxDate={props.maxDate}
        minDate={props.minDate}
        openToDate={props?.openToDate}
        autoComplete="off"
      />
    </div>
  );
};

export default DateComponent;
