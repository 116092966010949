import {
  ADD_NON_WORKING_DAY,
  DELETE_NON_WORKING_DAY,
  FETCH_NON_WORKING_DAYS,
  UPDATE_NON_WORKING_DAY,
} from './constants';

const initialState = {
  nonWorkingDays: null,
  nonWorkingDayForm: null,
};

export const nonWorkingDayReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_NON_WORKING_DAYS:
      return {
        ...state,
        nonWorkingDays: payload,
      };

    case ADD_NON_WORKING_DAY:
      return {
        ...state,
        nonWorkingDayForm: payload,
      };
    case UPDATE_NON_WORKING_DAY:
      return {
        ...state,
        nonWorkingDays: {
          ...state.nonWorkingDays,
          nwDays: state.nonWorkingDays.nwDays?.map((i) => {
            return i.nonWorkingDayID === payload.nonWorkingDayID ? payload : i;
          }),
        },
      };
    case DELETE_NON_WORKING_DAY:
      return {
        ...state,
        nonWorkingDays: {
          ...state.nonWorkingDays,
          nwDays: state.nonWorkingDays.nwDays.filter(
            (item) => item.nonWorkingDayID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
