import {
  DELETE_INITIAL_BALANCES,
  EDIT_INITIAL_BALANCES,
  GET_INITIAL_BALANCES,
  LOADING,
} from "./constants";

const initialState = {
  initialBalancesData: null,
  isLoading: false,
};
export const initialBalancesReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_INITIAL_BALANCES:
      return {
        ...state,
        initialBalancesData: payload,
        isLoading: true,
      };

    case EDIT_INITIAL_BALANCES:
      return {
        ...state,
        initialBalancesData: {
          ...state.initialBalancesData,
          initBalances: state.initialBalancesData.initBalances.map(
            (singleData) => {
              let updateData =
                singleData.initBalanceID === payload.initBalanceID
                  ? payload
                  : singleData;
              return {
                ...updateData,
              };
            }
          ),
        },
      };

    case DELETE_INITIAL_BALANCES:
      return {
        ...state,
        initialBalancesData: {
          ...state.initialBalancesData,
          initBalances: state.initialBalancesData?.initBalances.filter(
            (item) => item.initBalanceID !== payload
          ),
        },
      };

    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return { ...state };
  }
};
