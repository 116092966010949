import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Modal from '../../../App/Components/Modal/Modal';
import Input from '../../Input/Input';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import useTables from '../../hooks/useTables';
import { tablesFields } from './FieldsConfig';
import RadioButton from '../../../App/Components/RadioButton/RadioButton';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import InputTitle from '../../Input/InputTitle';
import { TablesValidation } from '../../../App/Validation/TablesValidation';
import useIsMounted from '../../hooks/useIsMounted';

const TablesForm = ({ handleClose, onSubmit, calendarID = 0 }) => {
  const { fetchTablesFormData, tablesFormData } = useTables();
  const isMounted = useIsMounted();

  useEffect(() => {
    fetchTablesFormData(calendarID);
  }, [calendarID]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...tablesFormData,
    },
    validationSchema: TablesValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
      if (isMounted) {
        resetForm();
      }
    },
  });

  return (
    <Modal
      closeHandler={handleClose}
      onDrag={true}
      customClass="tables-form-block"
    >
      <form onSubmit={handleSubmit}>
        {tablesFields?.map((field) => {
          const {
            fieldType,
            label,
            name,
            required,
            type,
            radioBtn,
            radioTitle,
            maxLength,
          } = field || {};
          const fieldError = touched[name] && errors[name];

          if (fieldType === 'input') {
            return (
              <div key={name} className="tables-inp-wrap">
                <Input
                  type={type}
                  inputTitle={label}
                  name={name}
                  requiredFiled={required}
                  onChange={handleChange}
                  value={values[name]}
                  maxLength={maxLength}
                />
                {required && !values[name] && fieldError && (
                  <ErrorFiled error={fieldError} />
                )}
              </div>
            );
          } else if (fieldType === 'radio') {
            return (
              <div className="tables-inp-wrap">
                <InputTitle requiredFiled={required} inputTitle={radioTitle} />
                <div className="tables-radio-btn-flex">
                  {radioBtn?.map(({ value, name, label }) => (
                    <RadioButton
                      name={name}
                      value={value}
                      checked={value === values[name]}
                      onChange={() => setFieldValue(name, value)}
                      label={label}
                    />
                  ))}
                </div>
              </div>
            );
          }
          return null;
        })}

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
          executeDisabled={!dirty || !isValid}
        />
      </form>
    </Modal>
  );
};

export default TablesForm;
