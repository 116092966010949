import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postRequest } from '../../Api/Api';

import Alert from '../../Components/Alert';
import Header from '../../Components/Header/Header';
import {
  getInvoicingAccountAction,
  loadingAction,
} from '../../Redux/eInvoicingAccountsSlices/useAction';
import EInvoicingAccountsTable from './EInvoicingAccountsTable';
import EInvoicingFileUploader from './EInvoicingFileUploader';
import './eInvoicingStyle.scss';
import OldEInvoicingUpload from '../OldEInvoicingUpload/OldEInvoicingUpload';
import Button from '../../Components/Button/Button';

const GetEInvoicingAccountsData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { invoicingAccountData, isLoading, oldInvoicingData } = useSelector(
    (state) => state.getInvoicingAccount,
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [filename, setFilename] = useState('Չկա ընտրված ֆայլ');
  const [disableButton, setDisableButton] = useState(true);

  const [registerBtnDisabled, setRegisterBtnDisabled] = useState(true);
  const [errorHandler, setErrorHandler] = useState('');
  const dispatch = useDispatch();
  const { excelList, recivedWrittenOff } = invoicingAccountData || [];
  const accountTitle = recivedWrittenOff == 1 ? ' Կրեդիտ հաշիվ' : 'Դեբետ հաշիվ';

  const uploadFileHandler = () => {
    const formData = new FormData();
    formData.append('name', filename);
    formData.append('file', selectedFile);
    dispatch(loadingAction(true));
    postRequest(`importExcelNew?companyID=${currentCompanyID}`, formData)
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(getInvoicingAccountAction(res.data));
          setFilename('Չկա ընտրված ֆայլ');
        } else if (res.data.errorCode > 0 && res.data.messageExcel) {
          setErrorHandler(res.data.messageExcel);
          setFilename('Չկա ընտրված ֆայլ');
        }
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    return () => {
      dispatch(getInvoicingAccountAction([]));
    };
  }, [dispatch]);

  return (
    <>
      <Header headerBtn={false} pageTitle="E-Invoicing հաշիվների ներբեռնում" />

      {oldInvoicingData?.excelList ? null : (
        <>
          <EInvoicingFileUploader
            fileUpload={uploadFileHandler}
            filename={filename}
            setFilename={setFilename}
            setSelectedFile={setSelectedFile}
            disableButton={disableButton}
            setDisableButton={setDisableButton}
            excelList={excelList}
          />

          <EInvoicingAccountsTable
            mainData={invoicingAccountData}
            loading={isLoading}
            registerBtnDisabled={registerBtnDisabled}
            setRegisterBtnDisabled={setRegisterBtnDisabled}
            accountTitle={accountTitle}
            setDisableButton={setDisableButton}
            setErrorHandler={setErrorHandler}
            fetchData={uploadFileHandler}
          />

          <Alert description={errorHandler} onClose={setErrorHandler} />
        </>
      )}

      <OldEInvoicingUpload />
    </>
  );
};

export default GetEInvoicingAccountsData;
