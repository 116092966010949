export function updateButtonStates(
  formulationsTable,
  query,
  setIsDisabledButtons,
) {
  const isSameMonth = formulationsTable?.salaryYearMonth === query?.yearMonth;
  const hasNoStatements = formulationsTable?.statements?.length === 0;
  const allConfirmed = formulationsTable?.statements?.every(
    (statement) => statement.entryID === 0,
  );

  if (query?.yearMonth && query.years && isSameMonth && hasNoStatements) {
    setIsDisabledButtons((prevButtons) => ({
      ...prevButtons,
      newFormulations: false,
    }));
  } else {
    setIsDisabledButtons((prevButtons) => ({
      ...prevButtons,
      newFormulations: true,
    }));
  }

  if (formulationsTable?.statements?.length > 0) {
    setIsDisabledButtons((prevButtons) => ({
      ...prevButtons,
      print: false,
    }));
  } else {
    setIsDisabledButtons((prevButtons) => ({
      ...prevButtons,
      print: true,
    }));
  }

  if (isSameMonth && allConfirmed) {
    setIsDisabledButtons((prevButtons) => ({
      ...prevButtons,
      confirm: false,
    }));
  } else {
    setIsDisabledButtons((prevButtons) => ({
      ...prevButtons,
      confirm: true,
    }));
  }
  if (isSameMonth && !allConfirmed) {
    setIsDisabledButtons((prevButtons) => ({
      ...prevButtons,
      remove: false,
    }));
  } else {
    setIsDisabledButtons((prevButtons) => ({
      ...prevButtons,
      remove: true,
    }));
  }
}
