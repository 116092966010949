import React from 'react';
import { Commas } from '../../../App/Utilities/Utilities';

const ProductTotal = ({ total }) => {
  return (
    total > 0 && (
      <div className="L-product-total">
        {' '}
        Ընդամենը: <p>{Commas(total)}</p>
      </div>
    )
  );
};

export default ProductTotal;
