import React, { useEffect, useState } from 'react';
import EmployeeTableList from './EmployeeTableList';
import EmployeesCountDate from './EmployeesCountDate';
import useSalaryCalc from '../../../hooks/useSalaryCalc';
import useSalaryCalculationDate from '../../../hooks/useSalaryCalculationDate';
import './employeesList.scss';
import { Commas } from '../../../../App/Utilities/Utilities';
import EmployeesListTotal from './EmployeesListTotal';
import Loading from '../../../../App/Components/Loading/Loading';

const EmployeesList = () => {
  const { calculationDate } = useSalaryCalculationDate();
  const [salaryDate, setSalaryDate] = useState(calculationDate?.salaryDate);
  const { getEmployeesList, employeesList, loading } = useSalaryCalc();

  useEffect(() => {
    setSalaryDate(calculationDate?.salaryDate);
  }, [calculationDate?.salaryDate]);

  useEffect(() => {
    if (!salaryDate) {
      return;
    }

    getEmployeesList(salaryDate);
  }, [salaryDate]);

  const handleDateChange = (date) => {
    setSalaryDate(date);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="L-dashboard-employees-list">
      <h3 className="G-salary-title">Աշխատակիցներ</h3>
      <EmployeesCountDate
        salaryDate={salaryDate}
        onDateChange={handleDateChange}
        employeesCount={employeesList?.emplCount}
      />
      <EmployeeTableList mainData={employeesList?.employees} />

      <EmployeesListTotal total={Commas(employeesList?.total)} />
    </div>
  );
};

export default EmployeesList;
