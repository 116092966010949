import moment from 'moment';
import { getRequest } from '../Api/Api';
import { getPrintUrlAction } from '../Redux/printSlices/printSlices';
import { routing } from '../Routing/routing';
export const MAIN_URL = 'http://212.34.230.218:5005/api';
export const takeCount = 15;

export const printText = 'Տպել';
export const executeText = 'Կատարել';
export const viewText = 'Դիտել';
export const viewPrintTitle = 'Դիտել / տպել';
export const cancelText = 'Ընդհատել';
export const registerDocument = 'Գրանցել փաստաթուղթը';
export const register = 'Գրանցել';
export const confirm = 'Հաստատել';
export const forward = 'Առաջ';
export const modalContent = 'Համոզվա՞ծ եք որ ուզում եք ջնջել այն';
export const tableActionTitle = 'Գործողություն';
export const StandardDocumentsDeleteMessage =
  'Համոզվա՞ծ եք որ ուզում եք ջնջել այն, կջնջվի նաև գործառնություները';
export const exchangeRateTitle = 'Փոխարժեքը';

export const exceedAmount = 300000;
export const exceedAmountMess = `Գումարը գերազանցում է թույլատրելի ${exceedAmount} դրամը`;
export const numbering = 'Հ/Հ';
export const amountIsNotEnough = 'Առկա գումարը չի բավարարում';
export const inCorrectDateMess =
  'Ժամանակահատվածի սկիզբը պետք է փոքր լինի վերջից';

export const editAgreeText =
  'Համոզվա՞ծ եք որ ուզում եք խմբագրել, այն մասնակցում է փակված առհաշիվ ծախսերի փաստաթղթում';
export const deleteAgreeText =
  'Համոզվա՞ծ եք որ ուզում եք ջնջել, այն մասնակցում է փակված առհաշիվ ծախսերի փաստաթղթում';
export const debounceDelay = 1000;
export const textFiledLength = 60;

export const mainPage = '/Home';
export const dateFormat = (date) => {
  let d = new Date(date),
    day = '' + d.getDate(),
    month = '' + (d.getMonth() + 1),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('.');
};

export const formatSignDate = (inputDate) => {
  const dateOnly = inputDate.split(',')[0];
  return dateOnly;
};
export const randomID = () => {
  return Math.floor(Math.random() * 100001);
};

export const onFocusRemoveZero = (e) => {
  const val = e.target.value;
  if (Number(val) === 0) {
    e.target.value = '';
  }
};

export const formatValue = (value) => {
  if (value !== null || value !== '') {
    const newLabel = value.label.split(' ')[0];
    return { ...value, label: newLabel };
  } else {
    return null;
  }
};

// export const removeCommas = (val) => {
//   let result = null;
//   if (typeof val === 'string') {
//     result = val && val?.replace(/,/g, '');
//   } else {
//     return val;
//   }
//   return Number(result);
// };
export const removeCommas = (val) => {
  if (typeof val === 'string') {
    const cleaned = val.replace(/,/g, '');
    return isNaN(cleaned) ? 0 : Number(cleaned);
  }
  return typeof val === 'number' ? val : 0;
};

export const formatNumberWithCommas = (value) => {
  if (typeof value === 'string') {
    return value;
  }
  return Commas(value);
};

export const typOfString = (value) => {
  if (typeof value !== 'string') {
    return value?.toString();
  }
  return value;
};

export const getTotalExpenditure = (arr) => {
  const totalAmount =
    arr !== undefined &&
    arr.map((item) => removeCommas(item?.price) * item?.quantity);
  const totalExpenditure =
    totalAmount &&
    totalAmount?.reduce(function (agger, val) {
      return agger + val;
    }, 0);
  return totalExpenditure;
};

export const getFinalBalance = (myObject, expenses) => {
  if (myObject) {
    return (
      myObject['initBalance'] +
      myObject['total'] -
      myObject['inputs'] -
      getTotalExpenditure(expenses)
    );
  }
  return null;
};

export const checkDisableBtn = (val) => {
  const values = Object?.values(val);
  if (values?.every((elem) => elem === '' || elem === undefined)) {
    return true;
  } else {
    return false;
  }
};

export const checkObjIsNotEmpty = (val = {}) => {
  const { partnerBankingAccount, partnerBankName, ...remainingProps } = val;
  const values = Object?.values(remainingProps);

  if (values?.some((elem) => elem === '' || elem === null)) {
    return true;
  } else {
    return false;
  }
};

export const startOfYear = moment()
  .clone()
  .startOf('year')
  .format('YYYY-MM-DD hh:mm');

export const currentDateStart = () => {
  const val = localStorage.getItem('currentTime');

  setTimeout(() => {
    localStorage.removeItem('currentTime');
  }, [150000]);

  return val ? val : startOfYear;
};

export const startOfMonth = moment()
  .clone()
  .startOf('month')
  .format('YYYY-MM-DD hh:mm');

export const dataTimeFormat = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const newDate = new Date();

export const initialDate = () => {
  return new Date();
};

export const halfOfMonth = newDate.setDate(
  newDate.getDate() - newDate.getDay() - 15,
);

export const getTotalCount = (arr, name) => {
  const totalExpenditure =
    arr &&
    arr?.reduce(function (agger, val) {
      return agger + val[name];
    }, 0);
  return totalExpenditure;
};

export const correctDate = (val, name, dateStart, dateEnd) => {
  const d1 = name === 'dateStart' ? val : dateStart;
  const d2 = name === 'dateEnd' ? val : dateEnd;

  if (d1 > d2 || d2 < d1) {
    return false;
  }

  return true;
};

export const windowReload = () => {
  return window.location.reload();
};

export function isEmpty(obj) {
  for (let key in obj) {
    if (!!obj[key]) return false;
  }
  return true;
}

export const toFixed = (num) => {
  return num.toFixed(2);
};

export const isNumber = (s) => {
  const regExp = /^[ ]*[+-]?((\d+[.]?\d*)|(\d*[.]?\d+))(e[+-]?\d+)?[ ]*$/i;
  return regExp.test(s);
};

export const Commas = (value) => {
  let parsedValue;

  if (typeof value === 'string') {
    parsedValue = parseFloat(value);
  } else if (typeof value === 'number') {
    parsedValue = value;
  } else {
    return value;
  }

  if (!isNaN(parsedValue)) {
    parsedValue = Math.ceil(parsedValue * 100) / 100;
    const parts = parsedValue.toString().split('.');
    const numberPart = parts[0];
    let decimalPart = parts[1] || '00';

    if (decimalPart.length === 1) {
      decimalPart += '0';
    }

    const thousandsRegExp = /\B(?=(\d{3})+(?!\d))/g;
    const formattedNumber = numberPart.replace(thousandsRegExp, ',');
    return `${formattedNumber}.${decimalPart}`;
  }

  return value;
};

export const incorrectReportingPeriod = 'Փոխեք հաշվետու ժամանակաշրջանը';

export const checkBeforeDelete = (url = '') => {
  const result = getRequest(url)
    .then((res) => {
      return res?.data;
    })
    .catch((Err) => {
      console.log('Error', Err);
    });
  return result;
};

export const GetAccountCurrencies = ({ companyID, accountCode }) => {
  const result = getRequest(
    `getAccountCurrencies?companyID=${companyID}&accountCode=${accountCode}`,
  );
  return result;
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const printHandler = (param, dispatch) => {
  const getPageName = (path) => {
    for (const route of routing) {
      if (route.path === path) {
        return route.name;
      } else if (route.chillers) {
        for (const chiller of route.chillers) {
          if (chiller.path === path) {
            return chiller.name;
          }
        }
      }
    }
    return '';
  };

  const currentPath = window.location.pathname;
  const pageName = getPageName(currentPath);

  dispatch(getPrintUrlAction({ param: param, title: pageName }));
  const newTab = window.open('/PrintDetails', '_blank');

  const path = '/PrintDetails';

  newTab.location.href = `${window.location.origin}${path}${currentPath}`;
};

export const printFunc = (fetchUrl, body, dispatch) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', 'Basic UG94b3M5OTlAZnZnLmFtOjEyMzQ1Ng==');
  fetchUrl &&
    fetch(`${MAIN_URL}/${fetchUrl}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: myHeaders,
    })
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => {
        printHandler(url, dispatch);
      });
};

export function simplifyObject(obj) {
  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if ('id' in obj[key]) {
        obj[key] = obj[key].id;
      } else {
        obj[key] = simplifyObject(obj[key]);
      }
    }
  }
  return obj;
}

export const onlyCommas = (value) => {
  if (typeof value === 'string' || typeof value === 'number') {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      const formattedNumber = parsedValue.toLocaleString('en-US', {
        maximumFractionDigits: 0,
      });
      return formattedNumber;
    }
  }
  return value;
};

export const filterObjKeys = (obj = {}, keysToRemove) => {
  const newObj = { ...obj };
  keysToRemove.forEach((key) => delete newObj[key]);
  return newObj;
};
export const getArmenianMonthAndDay = (dateString) => {
  const periodStart = new Date(dateString);
  const monthNames = [
    'Հունվար',
    'Փետրվար',
    'Մարտ',
    'Ապրիլ',
    'Մայիս',
    'Հունիս',
    'Հուլիս',
    'Օգոստոս',
    'Սեպտեմբեր',
    'Հոկտեմբեր',
    'Նոյեմբեր',
    'Դեկտեմբեր',
  ];
  const month = monthNames[periodStart.getMonth()];
  //const day = periodStart.getDate();
  const day = String(periodStart.getDate()).padStart(2, '0');

  return `${day} ${month}`;
};
