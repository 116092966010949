import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
const PrivateRoute = ({ children, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    if (!auth.token) {
      history.push('/OneWindow');
    }
  }, [auth.token, history]);

  return <Route {...rest} render={(props) => children} />;
};

export default PrivateRoute;
