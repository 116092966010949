import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCompanyTaxationSystem,
  registerReportingPeriod,
  registerTaxationSystem,
} from '../../services/fetchCompanyTaxationSystem';

const useCompanyTaxationSystem = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [error, setError] = useState('');
  const [errorMess, setErrorMess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [companyTaxationSystemData, setCompanyTaxationSystemData] =
    useState(null);
  const dispatch = useDispatch();

  const getCompanyTaxationSystem = async () => {
    setIsLoading(true);
    try {
      const data = await fetchCompanyTaxationSystem(currentCompanyID);
      setCompanyTaxationSystemData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addTaxationSystem = async (newTaxationSystem) => {
    setIsLoading(true);

    try {
      const { errorCode, message, taxationSystemName, companyTaxationID } =
        await registerTaxationSystem(newTaxationSystem);

      if (errorCode === 0 && message) {
        setErrorMess('Փոփոխությունը գրանցվեց');
        await getCompanyTaxationSystem();
      } else if (message) {
        setErrorMess(message);

        setCompanyTaxationSystemData((prevData) => ({
          ...prevData,
          company: {
            ...prevData?.company,
            taxationSystemName: taxationSystemName,
            taxationID: companyTaxationID,
          },
        }));
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addReportingPeriod = async (newReportingPeriod) => {
    setIsLoading(true);

    try {
      const { errorCode, message } = await registerReportingPeriod(
        newReportingPeriod,
      );

      if (errorCode === 0) {
        setError('Փոփոխությունը գրանցվեց');
        getCompanyTaxationSystem();
      }
      if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    error,
    errorMess,
    setErrorMess,
    getCompanyTaxationSystem,
    companyTaxationSystemData,
    setCompanyTaxationSystemData,
    addTaxationSystem,
    addReportingPeriod,
  };
};

export default useCompanyTaxationSystem;
