import moment from 'moment';

export const startOfMonth = moment()
  .clone()
  .startOf('month')
  .format('YYYY-MM-DD hh:mm');

export const startOfYear = moment()
  .clone()
  .startOf('year')
  .format('YYYY-MM-DD hh:mm');

export const startOfWeek = moment()
  .clone()
  .startOf('week')
  .format('YYYY-MM-DD hh:mm');

export const dateFormat = (date) => {
  let d = new Date(date),
    day = '' + d.getDate(),
    month = '' + (d.getMonth() + 1),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('.');
};

export const dateDDMMYYYY = (date) => {
  let d = new Date(date),
    day = '' + d.getDate(),
    month = '' + (d.getMonth() + 1),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year];
};

export const sortByDate = (a, b) => {
  if (a.crAt < b.crAt) {
    return 1;
  }
  if (a.crAt > b.crAt) {
    return -1;
  }
  return 0;
};

export const dataTimeFormat = (value) => {
  return moment(value).format('YYYY-MM-DD');
};

export const handleEnter = (event) => {
  if (event.key.toLowerCase() === 'enter') {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
};

export const Commas = (num) => {
  if (num) {
    num = Math.ceil(num * 100) / 100;
  }

  let parts = num && num?.toString()?.split('.');

  if (parts === 0) {
    return Number(0).toFixed(2);
  }

  const numberPart = parts && parts[0];

  const thousands = /\B(?=(\d{3})+(?!\d))/g;

  return numberPart?.replace(thousands, ',');
};

export const onFocusRemoveZero = (e) => {
  if (e.target.value == '0') {
    e.target.value = '';
  }
};

export const CommasDes = (num) => {
  if (num) {
    num = Math.ceil(num * 100) / 100;
  }

  let parts = num && num?.toString()?.split('.');

  if (parts === 0) {
    return Number(0).toFixed(2);
  }

  const numberPart = parts && parts[0];

  const decimalPart = parts && parts[1];

  const thousands = /\B(?=(\d{3})+(?!\d))/g;

  return (
    numberPart?.replace(thousands, ',') +
    (decimalPart ? '.' + decimalPart : '.00')
  );
};
