import React from 'react';

const InputTitle = ({ requiredField, inputTitle = '' }) => {
  return (
    <div className="G-s-input-title">
      {requiredField && <span className="G-asterisk">*</span>}
      {inputTitle && <p className="G-title-p">{inputTitle}</p>}
    </div>
  );
};

export default InputTitle;
