import React from 'react';
import InputTitle from './InputTitle';
import './input.scss';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';

const Input = (props) => {
  const {
    onChange,
    value,
    type,
    name,
    requiredField = false,
    inputTitle,
    className = '',
    onBlur,
    lang,
    disabled,
    error,
    maxLength,
  } = props;

  return (
    <div className={`${className}G-material-value-input-wrap`}>
      <InputTitle requiredField={requiredField} inputTitle={inputTitle} />
      <input
        className={`${className}G-material-value-input`}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        lang={lang}
        autoComplete="off"
        disabled={disabled}
        maxLength={maxLength}
        onInput={(e) => {
          e.target.value = e.target.value.replace(',', '.');
        }}
        {...props}
      />
      {error && <ErrorFiled error={error} />}
    </div>
  );
};

export default Input;
