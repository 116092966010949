import React from 'react';
import { deleteRequest } from '../../../Api/Api';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';

const DeleteEInvoicingOperations = ({
  deleteRowId,
  closeModal,
  operationData,
  setOperationsData,
}) => {
  const handleClose = () => closeModal(false);
  const deleteRowDate = () => {
    deleteRequest(`deleteExcelInvoiceEntry/${deleteRowId}`).then((res) => {
      if (res.data.errorCode === 0) {
        let filterOperationData = operationData?.excelInvoiceEntries?.filter(
          (item) => item.entryID !== deleteRowId,
        );
        setOperationsData((prev) => ({
          ...prev,
          excelInvoiceEntries: [...filterOperationData],
        }));
        handleClose();
      }
    });
  };
  //

  return (
    <AcceptOrCancelModal closeModal={closeModal} confirmClick={deleteRowDate} />
  );
};

export default DeleteEInvoicingOperations;
