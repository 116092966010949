import React from "react";
import Loading from "../../Components/Loading/Loading";
import Table from "../../Components/Table/Table";

const AccountingCorrespondenceTable = ({ mainData, loading }) => {
  const columnConfig = [
    {
      title: "Դեբետ հաշիվ",
      cell: (row) => row.acc,
      customStyle: { maxWidth: 120 },
    },
    {
      title: "Կրեդիտ հաշիվ",
      cell: (row) => row.corresp,
      customStyle: { maxWidth: 120 },
    },
    {
      title: "Բովանդակություն",
      cell: (row) => row.contents,
      customStyle: { maxWidth: 1200 },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-accounting-correspondence-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AccountingCorrespondenceTable;
