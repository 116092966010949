import React from "react";
import { Commas, dateFormat } from "../../Utilities/Utilities";
import Loading from "../../Components/Loading/Loading";
import Table from "../../Components/Table/Table";

const AccountAnalysisTable = ({ loading, mainData }) => {
  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Փաստաթուղթ',
      cell: (row) => row.docNum,
      customStyle: { maxWidth: 350, overflow: 'hidden' },
    },
    {
      title: 'Մեկնաբանություն',
      cell: (row) => row.description,
      customStyle: { maxWidth: 300, overflow: 'hidden' },
    },
    {
      title: 'Դեբետոր/Կրեդիտոր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 700, overflow: 'hidden' },
    },
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountCode,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Դեբետ',
      cell: (row) => Commas(row.debitAmount),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => Commas(row.creditAmount),
      customStyle: { maxWidth: 150 },
    },
  ];

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-account-analysis-table G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AccountAnalysisTable;
