import React, { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import Action from "../../Components/Table/Action";
import { purchaseActActionLit } from '../../Components/Table/ActionLIst';
import Table from '../../Components/Table/Table';
import {
  checkBeforeDelete,
  Commas,
  dateFormat,
  printHandler,
} from '../../Utilities/Utilities';
import DeletePurchaseAct from './DeletePurchaseAct';
import EditPurchaseWrapper from './EditPurchaseWrapper';
import GetPurchaseActOperations from './PurchaseActOperations/GetPurchaseActOperations';
import { useDispatch } from 'react-redux';
import { DocumentN, DocumentNumber } from '../../GlobalTitle/GlobalTitle';
import Tooltip from '../../Components/Tooltip/Tooltip';

const PurchaseActTable = (props) => {
  const { mainData, loading, setErrorHandler } = props;
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openOperationsModal, setOpenOperationsModal] = useState(false);
  const dispatch = useDispatch();
  const handelChange = (value, row) => {
    switch (value) {
      case 'operations':
        return openOperationsForm(row?.documentID);
      case 'delete':
        return openDeleteForm(row?.documentID);
      case 'watch':
        return openEditForm(row);
      case 'print':
        return handlePrint(row?.documentID);
      default:
        return null;
    }
  };

  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 90 },
    },
    {
      title: <Tooltip content={DocumentNumber}>{DocumentN}</Tooltip>,
      cell: (row) => row.docNum,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Հաշվետու անձ',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 1100 },
    },
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գումար',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 200 },
    },
    {
      title: 'Գործողություն',
      cell: (row) => (
        <Action
          row={row}
          mainAction="watch"
          mainActionName="Մուտք փաստաթուղթ"
          handelChange={handelChange}
          actionItem={purchaseActActionLit}
          modalIsOpen={openEditModal || openDeleteModal || openOperationsModal}
        />
      ),
      customStyle: { maxWidth: 160 },
    },
  ];

  const openEditForm = (rowValue) => {
    setOpenEditModal(rowValue);
  };

  const openDeleteForm = (documentID) => {
    checkBeforeDelete(`letDeletePurchase?purchaseID=${documentID}`).then(
      (res) => {
        if (res.errorCode === 0) {
          setOpenDeleteModal(documentID);
        }
        if (res.errorCode > 0) {
          setErrorHandler(res.message);
        }
      },
    );
  };
  const openOperationsForm = (rowValue) => {
    setOpenOperationsModal(rowValue);
  };

  const handlePrint = (id) => {
    printHandler(`printPurchase?purchaseID=${id}`, dispatch);
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-purchase-act-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}
      {openEditModal && (
        <EditPurchaseWrapper
          closeModal={setOpenEditModal}
          rowId={openEditModal}
          setErrorHandler={setErrorHandler}
        />
      )}
      {openDeleteModal && (
        <DeletePurchaseAct
          deleteRowId={openDeleteModal}
          closeModal={setOpenDeleteModal}
        />
      )}
      {openOperationsModal && (
        <GetPurchaseActOperations
          purchaseID={openOperationsModal}
          closeModal={setOpenOperationsModal}
        />
      )}
    </>
  );
};

export default PurchaseActTable;
