import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "../../Api/Api";
import Header from "../../Components/Header/Header";
import Pagination from '../../Components/Pagination/Pagination';
import {
  getCurrenciesAction,
  loadingAction,
} from '../../Redux/currenciesSlices/useAction';
import EventAddForm from './EventAddForm';
import CurrenciesTable from './CurrenciesTable';

const GetCurrencies = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { currenciesData, isLoading } = useSelector(
    (state) => state.getCurrenciesData,
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const dispatch = useDispatch();

  const getData = (pageNumber) => {
    dispatch(loadingAction(true));
    getRequest(
      `getCurrencies?companyID=${currentCompanyID}&pageNumber=${
        pageNumber + 1
      }`,
    )
      .then((res) => {
        dispatch(getCurrenciesAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, dispatch]);

  const openAddForm = () => {
    setOpenAddModal(true);
  };

  return (
    <div className="G-small-wrap">
      <Header pageTitle="Արժույթներ" handelClick={openAddForm} />

      <CurrenciesTable
        mainData={currenciesData?.currencies}
        loading={isLoading}
      />
      <Pagination
        pageCount={currenciesData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
      {openAddModal && (
        <EventAddForm
          closeModal={setOpenAddModal}
          getData={getData}
          pageNumber={pageNumber}
        />
      )}
    </div>
  );
};

export default GetCurrencies;
