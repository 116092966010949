const ArmenianMonths = [
  'Հունվար',
  'Փետրվար',
  'Մարտ',
  'Ապրիլ',
  'Մայիս',
  'Հունիս',
  'Հուլիս',
  'Օգոստոս',
  'Սեպտեմբեր',
  'Հոկտեմբեր',
  'Նոյեմբեր',
  'Դեկտեմբեր',
];

export const dayHeaderContent = (arg) => {
  const formattedDate = arg.date;

  const daysOfWeek = [
    'Կիրակի',
    'Երկուշաբթի',
    'Երեքշաբթի',
    'Չորեքշաբթի',
    'Հինգշաբթի',
    'Ուրբաթ',
    'Շաբաթ',
  ];

  const day = formattedDate.getDate();
  const month = ArmenianMonths[formattedDate.getMonth()];
  const dayOfWeek = daysOfWeek[formattedDate.getDay()];

  if (arg.view.type === 'dayGridMonth') {
    return (
      <div>
        <div className="G-flex-justify-center">
          <div>{dayOfWeek}</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="G-flex-justify-center">
        <div>{month}</div>
        <div>{day}</div>
      </div>
      <div>{dayOfWeek}</div>
    </div>
  );
};

export const eventContent = (eventInfo) => {

  return (
    <div
      className="custom-event"
      style={{
        backgroundColor: eventInfo.event._def.extendedProps.label,
        color:
          eventInfo.event._def.extendedProps?.calId > 0 ? 'black' : 'white',
        fontSize: '12px',
      }}
    >
      {eventInfo.event.title}
    </div>
  );
};

export const customTitle = (arg) => {
  const year = arg.date.year;
  const day = arg.date.day;

  return ` ${ArmenianMonths[arg.date.month]}  ${year}`;
};

export const dayPopoverFormat = (arg) => {
  const year = arg.date.year;
  const day = arg.date.day;

  return ` ${ArmenianMonths[arg.date.month]} ${day} ${year}`;
};

export const handleEventAllow = (dropInfo, draggedEvent) => {
  const isAllowCallID = draggedEvent?._def?.extendedProps?.calId;
  if (isAllowCallID > 0) {
    return false;
  }

  return true;
};
