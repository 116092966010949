import React from 'react';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';

const BankPaymentListsDropdown = ({
  paymentNumOptions,
  bankOptions,
  query,
  setQuery,
  resetTableData,
  getPaymentListNumber,
}) => {
  const PAY_LIST = 'payList';
  const BANK = 'bank';
  const { payList, bank } = query;
  const handleDropdownChange = async (selectedOption, name) => {
    const option = selectedOption || { value: '', id: null };
    const { label, value, id } = selectedOption || {};
    resetTableData();
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: {
        ...prevQuery[name],
        label: label,
        value: value,
        id: id,
      },
    }));

    if (name === BANK) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        payList: {
          label: '',
          value: '',
          id: '',
        },
      }));
      await getPaymentListNumber(value);
    }
  };
  return (
    <div className="L-bank-paymentList-dropdown-flex">
      <SelectControl
        value={{ label: bank?.label }}
        selectBoxTitle="Բանկը"
        options={bankOptions || []}
        onChange={(selectedOption) =>
          handleDropdownChange(selectedOption, BANK)
        }
        requiredFiled={true}
      />
      <SelectControl
        value={{ label: payList?.label }}
        selectBoxTitle="Վճարացուցակ N"
        options={paymentNumOptions || []}
        onChange={(selectedOption) =>
          handleDropdownChange(selectedOption, PAY_LIST)
        }
        requiredFiled={true}
      />
    </div>
  );
};

export default BankPaymentListsDropdown;
