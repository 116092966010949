import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { putRequest } from '../../../Api/Api';
import PartnerBankForm from './PartnerBankForm';
import { editPartnerBankAction } from '../../../Redux/partnerSlices/useAction';
import Alert from '../../../Components/Alert';

const EventEditForm = ({ rowData, closeModal }) => {
  const [errorHandler, setErrorHandler] = useState('');

  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    putRequest('editPartnerBank', values)
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(editPartnerBankAction(values));
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <PartnerBankForm
        closeModal={handleClose}
        initialData={rowData}
        onSubmit={onSubmit}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventEditForm;
