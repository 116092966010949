import React from 'react';
import Tooltip from '../../Components/Tooltip/Tooltip';
import './addNew.scss';

const AddNew = ({ onClick, content = 'Գործընկեր' }) => {
  return (
    <>
      <Tooltip content={`Ավելացնել ${content}`}>
        <div className="G-add-icon" onClick={onClick}></div>
      </Tooltip>
    </>
  );
};

export default AddNew;
