import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import Header from '../../Components/Header/Header';
import Pagination from '../../Components/Pagination/Pagination';
import {
  getOrganizationBankAction,
  loadingAction,
} from '../../Redux/organizationSlices/useAction';
import { takeCount } from '../../Utilities/Utilities';
import CompanyBankTable from './CompanyBankTable';
import EventAddForm from './EventAddForm';

const GetCompanyBank = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { organizationBankData, isLoading } = useSelector(
    (state) => state.getOrganizationData,
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const dispatch = useDispatch();

  const getData = useCallback(
    async (pageNumber) => {
      try {
        dispatch(loadingAction(true));
        const queryParams = new URLSearchParams();
        queryParams.append('companyID', currentCompanyID);
        queryParams.append('pageNumber', pageNumber + 1);
        queryParams.append('takecount', takeCount);

        const response = await getRequest(
          `getCompanyBanks?${queryParams.toString()}`,
        );
        dispatch(getOrganizationBankAction(response.data));
      } catch (error) {
        console.log('Error:', error);
      } finally {
        dispatch(loadingAction(false));
      }
    },
    [dispatch, currentCompanyID],
  );

  useEffect(() => {
    getData(pageNumber);
  }, [getData, pageNumber]);

  const openAddHandler = useCallback(() => {
    setOpenAddModal(true);
  }, []);

  const pageCount = useMemo(() => {
    return organizationBankData?.pageCount?.pageCount;
  }, [organizationBankData]);

  const mainData = useMemo(() => {
    return organizationBankData?.companyBanks;
  }, [organizationBankData]);

  return (
    <>
      <Header pageTitle="Իմ բանկերը" handelClick={openAddHandler} />

      <CompanyBankTable
        mainData={mainData}
        loading={isLoading}
        setErrorHandler={setErrorHandler}
      />
      <Pagination
        pageCount={pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />

      {openAddModal && (
        <EventAddForm
          closeModal={setOpenAddModal}
          getData={getData}
          pageNumber={pageNumber}
          setErrorHandler={setErrorHandler}
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default GetCompanyBank;
