import { dateFormat } from '../../Utilities/Utilities';

export const ConvertRepDetalExcel = (opertionData) => {
  return (
    opertionData &&
    opertionData?.map((i) => {
      return {
        Ամսաթիվ: dateFormat(i.crAt),
        Գործառնություն: i.accountName,
        Տիպ: i.aspectName,
        Արժույթ: i.curr,
        Գումարը: i.amount,
        Նշում: i.comment,
      };
    })
  );
};

export default ConvertRepDetalExcel;
