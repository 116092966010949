import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import Header from '../../Components/Header/Header';
import { dateFormat, printFunc, printText } from '../../Utilities/Utilities';
import PartnerViewAccountTable from './PartnerViewAccountTable';
import Modal from '../../Components/Modal/Modal';
import ConvertDataExcel from './ConvertDataExcel';
import { ExportExcel } from '../../Components/Excelexport/Excelexport';

const PartnerViewAccount = ({
  modalClick,
  dateUrl,
  dateStart,
  dateEnd,
  currencyID,
}) => {
  const dispatch = useDispatch();
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { partnerBulletinView } = useSelector(
    (state) => state.getPartnerCirculationBulletin,
  );

  const handleClose = () => modalClick(false);
  const onPrintHandler = () => {
    const url = `printPartnersCalculationReport?companyID=${currentCompanyID}&currencyID=${currencyID}&${dateUrl}`;
    printFunc(url, partnerBulletinView?.calculationDetails, dispatch);
  };
  const dataForExcel = partnerBulletinView?.calculationDetails
    ? ConvertDataExcel(partnerBulletinView?.calculationDetails)
    : null;

  return (
    <Modal closeHandler={handleClose} customClass="G-modal-view-table">
      <>
        <Header
          pageTitle="Գործընկերների շրջանառության տեղեկագիր"
          headerBtn={false}
        />
        <div className="L-circulation-newsletter-cur G-flex">
          <h3>Արժույթը</h3>
          <p> {partnerBulletinView.currencyName}</p>
        </div>
        <h1 className="G-modal-view-date-style">
          {dateFormat(dateStart)} - {dateFormat(dateEnd)}
        </h1>
        <PartnerViewAccountTable
          mainData={partnerBulletinView?.calculationDetails}
        />
        <div className="G-excel-print-btn-flex">
          <div className="G-save-cancel-btn-width">
            <GetSentDataBtn
              confirmExecuteText={printText}
              cancelPrintClick={handleClose}
              confirmExecuteClick={onPrintHandler}
            />
          </div>

          <ExportExcel excelData={dataForExcel} />
        </div>
      </>
    </Modal>
  );
};

export default PartnerViewAccount;
