import { format } from 'date-fns';
import hy from 'date-fns/locale/hy';

export const FormattedDateHy = (date) => {
  let capitalizedMonth = null;
  function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }
  if (isValidDate(date)) {
    const originalDate = new Date(date);

    const formattedDate = format(originalDate, 'MMMM dd, yyyy', {
      locale: hy,
    });

    capitalizedMonth =
      formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }

  return capitalizedMonth;
};
