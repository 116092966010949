import React from 'react';

const CheckBoxAllDay = ({ value, setFieldValue }) => {

  return (
    <div className="check-all-day G-flex">
      <label>
        <input
          type="checkbox"
          value={value}
          checked={value}
          onChange={() => setFieldValue('allDay', !value)}
        />
      </label>
      <span className="L-other-calendar-type">Ամբողջ օրը</span>
    </div>
  );
};

export default CheckBoxAllDay;
