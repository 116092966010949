import { createAction } from "../actionCreators";
import {
  DELETE_SERVICE_DATA,
  EDIT_SERVICE_DATA,
  GET_SERVICE_DATA,
  LOADING,
} from "./constants";

export const getServicesAction = createAction(GET_SERVICE_DATA);
export const loadingAction = createAction(LOADING, false);
export const editServicesAction = createAction(EDIT_SERVICE_DATA);
export const deleteServicesAction = createAction(DELETE_SERVICE_DATA);
