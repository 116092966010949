import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';

function ClickOutside({ onClickOutside, children, E_Outside = '' }) {
  const [container, setContainer] = useState([]);

  function getContainer(ref) {
    setContainer(ref);
  }
  useEffect(() => {
    function handle(e) {
      if (!container.contains(e.target)) {
        onClickOutside(e);
      }
    }
    document.addEventListener('click', handle, true);
    return () => {
      document.removeEventListener('click', handle, true);
    };
  }, [container, onClickOutside]);

  return (
    <div className={`E-click-outside ${E_Outside}`} ref={getContainer}>
      {children}
    </div>
  );
}

ClickOutside.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ClickOutside;
