import React from 'react';
import { useSelector } from 'react-redux';
import { deleteRequest } from '../../../Api/Api';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';


const RemoveClosedAccount = ({
  closeModal,
  formatClosureDate,
  closeAccountModal,
  getData,
}) => {
  const { currentCompanyID } = useSelector((state) => state.auth);

  const deleteRowDate = () => {
    deleteRequest(
      `deleteAccountClosure?companyID=${currentCompanyID}&closureDate=${formatClosureDate}`,
    ).then((res) => {
      if (res.data.errorCode === 0) {
        getData();
        closeAccountModal();
      } else if (res.data.errorCode > 0) {
        alert(res.data.message);
      }
    });
  };

  return (
    <AcceptOrCancelModal
      closeModal={closeModal}
      confirmClick={deleteRowDate}
      // modalContent
    />
  );
};

export default RemoveClosedAccount;
