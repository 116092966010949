import { makeAutoObservable } from 'mobx';

export default class StandardAspectsStore {
  constructor() {
    this._StandardAspects = [
      {
        name: 'Բիզնես',
        imgPath: 'business.png',
        imgColor: '#F5554A',
        singT: true,
        singE: true,
        userId: 0,
      },
      {
        name: 'Աշխատավարձ',
        imgPath: 'salary.png',
        imgColor: '#B07D5B',
        singT: false,
        singE: true,
        userId: 0,
      },
      {
        name: 'Նվեր',
        imgPath: 'gift.png',
        imgColor: '#D25D00',
        singT: true,
        singE: true,
        userId: 0,
      },
      {
        name: 'Բանկ',
        imgPath: 'bank.png',
        imgColor: '#FDA100',
        singT: true,
        singE: true,
        userId: 0,
      },
    ];

    makeAutoObservable(this);
  }
  //action

  setStandardAspects(aspects) {
    this._StandardAspects = aspects;
  }

  //computed
  get StandardAspects() {
    return this._StandardAspects;
  }
}
