import React from 'react';
import Calendar from '../../Components/Calendar/Calendar';
import SelectCurrency from '../../Components/SelectBox/SelectCurrency';
import { dateFormat } from '../../Utilities/Utilities';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import Button from '../../Components/Button/Button';
import { ExportExcel } from '../../Components/Excelexport/Excelexport';

const BalanceBookAction = (props) => {
  const {
    printHandler,
    currencyVal,
    setCurrencyVal,
    date,
    setDate,
    dataForExcel,
  } = props;
  const { data } = useFetchCurrency();
  const disabledBtn = currencyVal.value === '';

  const changeCurrency = (value) => {
    if (!value) {
      value = {
        value: '',
        id: 0,
      };
    }
    setCurrencyVal(value);
  };

  return (
    <div className="G-book-main-block G-flex-justify-between">
      <div className="L-balance-book-left-p G-flex">
        <div className="G-form-data-picker">
          <p className="G-title-p">Ամսաթիվ</p>
          <Calendar
            openToDate={new Date(date)}
            value={dateFormat(date)}
            changeHandler={(value) => setDate(value)}
          />
        </div>
        <SelectCurrency
          currencyData={data?.currencies}
          currencyVal={currencyVal}
          selectChangeCurrency={(value) => changeCurrency(value)}
        />
      </div>
      <div className="G-print-excel-block">
        <div className="G-view-btn">
          <Button
            text="Դիտել / տպել"
            onClick={printHandler}
            disabled={disabledBtn}
            customClass={disabledBtn ? 'G-disabled-button' : ''}
          />
        </div>
        <ExportExcel excelData={dataForExcel} />
      </div>
    </div>
  );
};

export default BalanceBookAction;
