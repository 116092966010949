import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRequest, putRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import { editInitialBalancesAction } from '../../Redux/InitialBalancesSlices/useAction';
import { removeCommas } from '../../Utilities/Utilities';
import InitialBalancesForm from './InitialBalancesForm';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { InitBalancesAllowEditText } from '../../GlobalTitle/GlobalTitle';

const EventEditForm = ({ rowData, closeModal }) => {
  const { initBalanceID, accountCode, companyID } = rowData || {};
  const [errorHandler, setErrorHandler] = useState('');
  const [isAllowEdit, setIsAllowEdit] = useState(false);
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAllowEdit() {
      try {
        const url = `preDeleteEditInitBalance?companyID=${companyID}&accountCode=${accountCode}`;
        const res = await getRequest(url);
        if (res.data.errorCode === 1) {
          setIsAllowEdit(true);
        }
      } catch (err) {
        console.error('Error checking allow edit:', err);
      }
    }

    if (accountCode) {
      checkAllowEdit();
    }
  }, [accountCode, companyID]);

  const onSubmit = (values) => {
    putRequest('editInitBalance', {
      ...values,
      amountDebit: removeCommas(values.amountDebit),
      amountCredit: removeCommas(values.amountCredit),
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(editInitialBalancesAction(values));
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      {isAllowEdit ? null : (
        <InitialBalancesForm
          onSubmit={onSubmit}
          getInitBalanceID={initBalanceID}
          closeModal={handleClose}
        />
      )}

      <Alert description={errorHandler} onClose={setErrorHandler} />
      {isAllowEdit && (
        <AcceptOrCancelModal
          closeModal={closeModal}
          confirmClick={() => setIsAllowEdit(false)}
          modalContent={InitBalancesAllowEditText}
        />
      )}
    </>
  );
};

export default EventEditForm;
