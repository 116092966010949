import * as Yup from "yup";
import {
  EnterTextOnly,
  InputFiledMessage,
  SelectFieldMessage,
  taxCodeMessage,
} from "./ErrorMessage";
import { matchesChar } from "./REG_EXP";
import { TaxCode } from "./ValidationForAllFiled";

export const OrganizationFormValidation = () =>
  Yup.object().shape({
    companyName: Yup.string()
      .matches(matchesChar, EnterTextOnly)
      .max(35, "")
      .required(InputFiledMessage),
    companyTypeID: Yup.string("").required(SelectFieldMessage).nullable(),
    taxationID: Yup.string("").required(SelectFieldMessage).nullable(),
    address: Yup.string().max(25, ""),
    city: Yup.string().max(25, ""),
    activityAddress: Yup.string().max(25, ""),
    taxCode: TaxCode.length(8, taxCodeMessage),
    phone: Yup.number().max(7, ""),
    email: Yup.string().email("Անվավեր էլեկտրոնային հասցե").max(155),
    headPosition: Yup.string().max(30),
    headName: Yup.string().max(40),
    accauntantName: Yup.string().max(40),
    description: Yup.string().max(100),
  });
