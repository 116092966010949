import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Components/Button/Button";
import GetSentDataBtn from "../../Components/GetSentDataBtn/GetSentDataBtn";
import Modal from "../../Components/Modal/Modal";
import { checkObjIsNotEmpty, dataTimeFormat } from '../../Utilities/Utilities';
import { getRequest } from '../../Api/Api';
import { updateInvoiceDateAction } from '../../Redux/invoiceSlices/useAction';
import EventAddForm from './EventAddForm';
import InvoiceCompilationDate from './InvoiceCompilationDate';
import InvoiceGoodsTable from './InvoiceGoodsTable';
import InvoiceSelectData from './InvoiceSelectData';
import InvoiceTotal from './InvoiceTotal';

const InvoiceForm = ({
  closeModal,
  partnerID,
  registerInvoiceData,
  cloneInvoice,
}) => {
  const { invoiceFormData } = useSelector((state) => state.getInvoiceData);
  const { invoice, invoiceGoods, supplyDate } = invoiceFormData || {};
  const { currencyID, currencyCode } = invoice || {};
  const [openAddModal, setOpenAddModal] = useState(false);
  const disabledSubmit = invoiceGoods?.length < 1 ? true : false;
  const checkInvoiceObj = checkObjIsNotEmpty(cloneInvoice);
const isDisabledAddBtn = !invoice?.supplierBankAccount?.length;
const dispatch = useDispatch();

useEffect(() => {
  if (currencyCode !== 'AMD') {
    getRequest(
      `getRate?rateDate=${dataTimeFormat(supplyDate)}&iso=${currencyCode}`,
    )
      .then((res) => {
        dispatch(updateInvoiceDateAction({ exchangeRate: res.data }));
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  }

  if (currencyID === 1) {
    dispatch(updateInvoiceDateAction({ exchangeRate: 1 }));
  }
}, [currencyCode, currencyID, supplyDate, dispatch]);

const openAddGoodsForm = () => {
  setOpenAddModal(true);
};

return (
  <Modal closeHandler={closeModal} customClass="L-invoice-form-wrapper">
    <div className="L-invoice-form-content">
      <InvoiceCompilationDate handleClose={closeModal} mainData={invoice} />
      <div className="L-invoice-total-data-block">
        <InvoiceSelectData mainData={invoiceFormData} partnerID={partnerID} />
        <InvoiceTotal mainData={invoiceFormData} />
      </div>
      <div className="G-add-btn">
        <Button
          disabled={isDisabledAddBtn}
          customClass={isDisabledAddBtn ? 'G-disabled-button' : ''}
          onClick={openAddGoodsForm}
        />
      </div>
      <InvoiceGoodsTable mainData={invoiceGoods} />

      <GetSentDataBtn
        confirmExecuteText="Հաստատել"
        cancelPrintClick={closeModal}
        confirmExecuteClick={registerInvoiceData}
        executeDisabled={checkInvoiceObj || disabledSubmit}
        executeClass={
          checkInvoiceObj || disabledSubmit ? 'G-disabled-button' : ''
        }
      />
    </div>

    {openAddModal && <EventAddForm closeModal={setOpenAddModal} />}
  </Modal>
);
};

export default InvoiceForm;
