import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';

export const fetchMaterialValueGroups = async (currentCompanyID, param) => {
  try {
    const response = await getRequest(
      `getProdGroups?companyID=${currentCompanyID}&searchString=${param}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchGroupForm = async (currentCompanyID, prodGroupID) => {
  try {
    const response = await getRequest(
      `getProdGroup?companyID=${currentCompanyID}&prodGroupID=${prodGroupID} `,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addMaterialGroup = async (newMaterial) => {
  try {
    const response = await postRequest('addProdGroup', newMaterial);
    return response.data;
  } catch (error) {
    console.error('Error adding material:', error);
    throw error;
  }
};
export const updateMaterialGroup = async (updateMaterial) => {
  try {
    const response = await putRequest('editProdGroup', updateMaterial);
    return response.data;
  } catch (error) {
    console.error('Error  update material:', error);
    throw error;
  }
};

export const removeMaterialGroup = async (prodGroupID) => {
  try {
    const response = await deleteRequest(`deleteProdGroup/${prodGroupID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting material:', error);
    throw error;
  }
};
