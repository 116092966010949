import * as Yup from "yup";
import { EnterNumberOnly, FillAmount, InputFiledMessage } from './ErrorMessage';
import { matchesNumber } from './REG_EXP';

export const TaxCode = Yup.string()
  .matches(matchesNumber, EnterNumberOnly)
  .required(InputFiledMessage)
  .nullable();

export const Amount = Yup.string()
  .required(FillAmount)
  .test(
    'Գումարը պետք է լինի 0-ից մեծ',
    'Գումարը պետք է լինի 0-ից մեծ',
    (value) => {
      if (value) {
        const numericValue = parseFloat(value);
        return numericValue > 0;
      }
      return true;
    },
  );