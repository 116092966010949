import React from 'react';
import AdditionsTypeForm from './AdditionsTypeForm';
import useAdditionsTypes from '../../hooks/useAdditionsTypes';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';

const EventEditForm = ({ handleClose, salaryTypeID }) => {
  const { updateAdditionsTypesItem, errorMess, setErrorMess } =
    useAdditionsTypes();
  const isMounted = useIsMounted();
  const onUpdateHandler = async (values) => {
    try {
      await updateAdditionsTypesItem(values, handleClose);
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <AdditionsTypeForm
        salaryTypeID={salaryTypeID}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventEditForm;
