import { makeAutoObservable } from 'mobx';

export default class AccountStore {
  constructor() {
    this._accounts = [
      {
        id: 1,
        name: 'Եկամուտ',
      },
      {
        id: 2,
        name: 'Ծախս',
      },
    ];
    makeAutoObservable(this);
  }
  //action
  setAccount(accounts) {
    this._accounts = accounts;
  }

  //computed
  get Account() {
    return this._accounts;
  }
}
