import React from "react";
import "./Checkbox.scss";

const Checkbox = (props)=>{
    const { disabled, onClick, checked, onChange, name, value, label } = props;
    return (
      <div className="checkbox-wrapper G-flex-align-center">
        <label>
          <input
            disabled={disabled}
            onClick={onClick}
            type="checkbox"
            checked={checked}
            onChange={onChange}
            name={name}
            value={value}
          />
        </label>
        <span>{label}</span>
      </div>
    );
}
export default Checkbox;