import React, { useState } from 'react';
import UsefulTool from '../UsefulTool';
import ContactUs from '../ContactUs';
import '../index.scss';
import Questions from '../Questions';
import WhoAreWe from '../WhoAreWe';
import Window from '../Window';
import Footer from '../Footer';
import Header from '../Header';
import Login from '../../Login/Login';
import Register from '../../Register/Register';

const OneWindowView = () => {
  const [selectTab, setSelectTab] = useState(
    'Login',
    // sessionStorage.getItem('selectTab') || 'window',
  );
  const [toggleLogRegPage, setToggleLogRegPage] = useState('Login');

  const toggleTabNameHandler = (name) => {
    if (name !== toggleLogRegPage) {
      setToggleLogRegPage('Login');
    }
    sessionStorage.setItem('selectTab', name);
    setSelectTab(name);
  };

  const choseTabBlock = () => {
    switch (selectTab) {
      case 'window':
        return <Window />;
      case 'WhoAreWe':
        return <WhoAreWe onToggleTab={setSelectTab} />;
      case 'Questions':
        return <Questions />;
      case 'UsefulTools':
        return <UsefulTool />;
      case 'Login':
        return toggleLogRegPage === 'Register' ? (
          <Register
            togglePage={setToggleLogRegPage}
            onToggleTab={onToggleTab}
          />
        ) : (
          <Login togglePage={setToggleLogRegPage} onToggleTab={onToggleTab} />
        );
      case 'ContactUs':
        return <ContactUs />;
      default:
        return '';
    }
  };
  const onToggleTab = () => {
    setSelectTab('window');
  };
  return (
    <div className="G-one-window-main-section">
      {selectTab == 'Login' ? null : (
        <Header
          toggleTabNameHandler={toggleTabNameHandler}
          selectTab={selectTab}
        />
      )}

      <div className="one-window-view-wrapper">{choseTabBlock()}</div>
      <Footer />
    </div>
  );
};

export default OneWindowView;
