import React, { useEffect, useState } from 'react';
import Modal from '../../../../App/Components/Modal/Modal';
import AssignPositionTable from './AssignPositionTable';
import EventAddForm from './EventAddForm';
import useAssignAdditionalStorage from '../../../hooks/useAssignAdditionalStorage';
import AssignPositionHeader from './AssignPositionHeader';
import './style.scss';
import { updateWithEmployeesSalaryAction } from '../../../../App/Redux/SalaryStor/workWithEmployeesSlices/useAction';
import { useDispatch } from 'react-redux';
const AssignPositionModal = ({ rowValues, handleClose }) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const { empNo } = rowValues || {};
  const setSign = true;
  const { getAssignAdditionalStorage, assignStorage } =
    useAssignAdditionalStorage();
  const dispatch = useDispatch();
  useEffect(() => {
    getAssignAdditionalStorage(empNo, setSign);
  }, [empNo, setSign]);

  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };

  useEffect(() => {
    if (assignStorage && !assignStorage?.salarySets?.length) {
      dispatch(updateWithEmployeesSalaryAction({ empNo, sAmount: 0 }));
    }
  }, [assignStorage, empNo, dispatch]);

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="assign-additional-storage-modal"
      title="Նշանակել հաստիք"
    >
      <AssignPositionHeader
        assignStorage={assignStorage}
        handelClick={setShowAddForm}
      />

      <AssignPositionTable mainData={assignStorage?.salarySets} />
      {showAddForm && (
        <EventAddForm handleClose={onClose} rowValues={rowValues} />
      )}
    </Modal>
  );
};

export default AssignPositionModal;
