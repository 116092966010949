import React, { useState, useEffect } from 'react';
import { Commas } from '../../../Utilities/Utilities';
import Checkbox from '../../../Components/Checkbox/Checkbox';
import Table from '../../../Components/Table/Table';

const WarehouseDetailsTable = ({
  warehouseTableData,
  setWarehouseTableData,
}) => {
  const [checkSign, setCheckSign] = useState(false);

  useEffect(() => {
    const checkEveryItem = warehouseTableData.every((item) => item.matSign);
    setCheckSign(checkEveryItem);
  }, [warehouseTableData]);

  const updateCheckboxes = (e, row = null) => {
    const { checked } = e.target;

    if (row) {
      const updatedData = warehouseTableData?.map((item) =>
        item.invGoodID === row.invGoodID ? { ...item, matSign: checked } : item,
      );
      setWarehouseTableData(updatedData);
    } else {
      const updatedData = warehouseTableData.map((item) => {
        if (item.entryID === 0) {
          return {
            ...item,
            matSign: checked,
          };
        }
        return item;
      });
      setWarehouseTableData(updatedData);
    }
  };

  const columnConfig = [
    {
      title: 'Հ/հ',
      cell: (row) => row.goodNumber,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.description,
      customStyle: { maxWidth: 500, overflow: 'hidden' },
    },
    {
      title: 'Չ/Մ',
      cell: (row) => row.unit,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Քանակը',
      cell: (row) => row.amount,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գինը',
      cell: (row) => Commas(row.price),
      customStyle: { maxWidth: 160 },
    },
    {
      title: 'Զեղչի %',
      cell: (row) => row.ef,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Ակցիզ',
      cell: (row) => row.eta,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.totalPrice),
      customStyle: { maxWidth: 160 },
    },
    {
      title: 'ԱԱՀ',
      cell: (row) => row.vat,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Ընդամենը',
      cell: (row) => Commas(row.total),
      customStyle: { maxWidth: 160 },
    },
    {
      title: (
        <span className="G-flex">
          <Checkbox onChange={(e) => updateCheckboxes(e)} checked={checkSign} />
        </span>
      ),
      cell: (row) => (
        <Checkbox
          checked={row.matSign}
          value={row.matSign}
          onChange={(e) => updateCheckboxes(e, row)}
          disabled={row.entryID !== 0}
        />
      ),
      customStyle: { maxWidth: 80 },
    },
  ];

  return (
    <>
      {warehouseTableData?.length > 0 && (
        <Table
          customClass="G-table-has-scroll"
          data={warehouseTableData}
          columnConfig={columnConfig}
        />
      )}
    </>
  );
};

export default WarehouseDetailsTable;
