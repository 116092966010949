import { createAction } from '../../actionCreators';
import {
  DELETE_EMPLOYEE,
  FETCH_EMPLOYEES,
  UPDATE_EMPLOYEE,
  ADD_FORM_DATA,
  ADD_EMPLOYEE,
  FILTER_CURRENT_EMPLOYEES,
} from './constants';

export const fetchEmployeesAction = createAction(FETCH_EMPLOYEES);
export const deleteEmployeeAction = createAction(DELETE_EMPLOYEE);
export const updateEmployeeAction = createAction(UPDATE_EMPLOYEE);
export const addFormDataAction = createAction(ADD_FORM_DATA);
export const addEmployeeAction = createAction(ADD_EMPLOYEE);

export const filterCurrentEmployItem = createAction(FILTER_CURRENT_EMPLOYEES);
