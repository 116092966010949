import * as Yup from "yup";
import {
  EnterNumberOnly,
  EnterTextOnly,
  InputFiledMessage,
  SelectFieldMessage,
} from "./ErrorMessage";
import { matchesChar } from "./REG_EXP";

export const ChartOfAccountValidation = () =>
  Yup.object().shape({
    accountCode: Yup.string()
      .matches(/^\d+$/, EnterNumberOnly)
      .required(InputFiledMessage),
    accountName: Yup.string()
      .matches(matchesChar, EnterTextOnly)
      .required(InputFiledMessage),
    currencies: Yup.string().required(SelectFieldMessage),
    // type: Yup.string("").required(SelectFieldMessage).nullable(),
  });
