import React from 'react';
import AcceptOrCancelModal from '../../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';

const DeleteCalculationSheet = ({
  salaryEntryID,
  handleClose,
  removeCalculationSheetItem,
  empNo,
}) => {
  const onRemoveHandler = async () => {
    try {
      await removeCalculationSheetItem(salaryEntryID, empNo, handleClose);
      handleClose();
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />
    </>
  );
};

export default DeleteCalculationSheet;
