import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { deleteSpendingMoneyDocAction } from '../../Redux/accountForSpendingMoneySlices/useAction';
import { StandardDocumentsDeleteMessage } from '../../Utilities/Utilities';

const DeleteDocument = ({ deleteRowId, closeModal, setErrorHandler }) => {
  const handleClose = () => closeModal(false);

  const dispatch = useDispatch();
  const deleteDocument = () => {
    deleteRequest(`deleteCashMoney/${deleteRowId}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deleteSpendingMoneyDocAction(deleteRowId));
        handleClose();
      } else if (res.data.errorCode === 112) {
        setErrorHandler(res.data.message);
      } else {
        setErrorHandler(res.data.message);
      }
    });
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={closeModal}
        confirmClick={deleteDocument}
        modalContent={StandardDocumentsDeleteMessage}
      />
    </>
  );
};

export default DeleteDocument;
