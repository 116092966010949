import {
  DELETE_PARTNER_BALANCE,
  DELETE_PARTNER_BANK,
  DELETE_PARTNER_DATA,
  EDIT_PARTNER_BALANCE,
  EDIT_PARTNER_BANK,
  EDIT_PARTNER_DATA,
  GET_PARTNER_BALANCE,
  GET_PARTNER_BANK,
  GET_PARTNER_DATA,
  LOADING,
} from "./constants";

const initialState = {
  partnerData: null,
  isLoading: false,
  partnerBalanceData: null,
  partnerBankData: null,
};

export const partnerReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case GET_PARTNER_DATA:
      return {
        ...state,
        partnerData: payload,
        isLoading: true,
      };

    case EDIT_PARTNER_DATA:
      return {
        ...state,
        partnerData: {
          ...state.partnerData,
          partners: state.partnerData.partners.map((singlePartner) => {
            return singlePartner.partnerID === payload.partnerID
              ? payload
              : singlePartner;
          }),
        },
      };

    case DELETE_PARTNER_DATA:
      return {
        ...state,
        partnerData: {
          ...state.partnerData,
          partners: state.partnerData?.partners.filter(
            (item) => item.partnerID !== payload,
          ),
        },
      };

    case GET_PARTNER_BALANCE:
      return {
        ...state,
        partnerBalanceData: payload,
      };

    case EDIT_PARTNER_BALANCE:
      return {
        ...state,
        partnerBalanceData: {
          ...state.partnerBalanceData,
          partnerBalances: state.partnerBalanceData.partnerBalances.map(
            (item) => {
              return item.initBalanceID === payload.initBalanceID
                ? payload
                : item;
            },
          ),
        },
      };

    case DELETE_PARTNER_BALANCE:
      return {
        ...state,
        partnerBalanceData: {
          ...state.partnerBalanceData,
          partnerBalances: state.partnerBalanceData.partnerBalances.filter(
            (item) => item.initBalanceID !== payload,
          ),
        },
      };

    case GET_PARTNER_BANK:
      return {
        ...state,
        partnerBankData: payload,
      };

    case EDIT_PARTNER_BANK:
      return {
        ...state,
        partnerBankData: {
          ...state.partnerBankData,
          partnerBanks: state.partnerBankData.partnerBanks.map(
            (singlePartner) => {
              return singlePartner.partnerBankDetailID ===
                payload.partnerBankDetailID
                ? payload
                : singlePartner;
            },
          ),
        },
      };

    case DELETE_PARTNER_BANK:
      return {
        ...state,
        partnerBankData: {
          ...state.partnerBankData,
          partnerBanks: state.partnerBankData?.partnerBanks.filter(
            (item) => item.partnerBankDetailID !== payload,
          ),
        },
      };

    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return { ...state };
  }
};
