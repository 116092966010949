import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import {
  dataTimeFormat,
  debounceDelay,
  initialDate,
  printHandler,
} from '../../Utilities/Utilities';
import PartnerBalanceBookAction from './PartnerBalanceBookAction';
import PartnerBalanceBookTable from './PartnerBalanceBookTable';
import './index.scss';
import { useFetchCurrency } from '../../Hooks/useFetchCurrency';
import { useDebounce } from '../../Hooks/useDebounce';

const GetPartnerBalanceBookData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(initialDate());
  const dateFormat = dataTimeFormat(date);
  const [partnerBalancesData, setPartnerBalancesData] = useState([]);
  const [viewAccounts, setViewAccounts] = useState(false);
  const [currencyVal, setCurrencyVal] = useState({
    label: 'AMD',
    value: 'AMD',
    id: 0,
  });
  const [partnerTypeVal, setPartnerTypeVal] = useState({
    label: '',
    value: '',
    id: 0,
  });
 const { data } = useFetchCurrency();
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();

  const debouncedSearchTerm = useDebounce(searchValue, debounceDelay);

  const generalUrl = useMemo(() => {
    return `companyID=${currentCompanyID}&currencyID=${
      currencyVal.id === 0 ? data?.amdCurrencyID : currencyVal.id
    }&dateEnd=${dateFormat}&partnerTypeID=${
      partnerTypeVal?.id
    }&searchString=${debouncedSearchTerm.trim()}`;
  }, [
    currentCompanyID,
    currencyVal,
    dateFormat,
    partnerTypeVal,
    debouncedSearchTerm,
    data,
  ]);

  const getData = useCallback(() => {
    setIsLoading(true);
    getRequest(
      `getPartnerBalanceBook?${generalUrl}&forAll=${viewAccounts}&partnerTypeID=${partnerTypeVal.id}`,
    )
      .then((res) => {
        setPartnerBalancesData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [generalUrl, viewAccounts, partnerTypeVal.id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handlePrint = useCallback(() => {
    printHandler(
      `printPartnerBalanceBook?${generalUrl}&forAll=${viewAccounts}`,
      dispatch,
    );
  }, [generalUrl, viewAccounts, dispatch]);

  return (
    <>
      <Header headerBtn={false} pageTitle="Գործընկերների մնացորդների մատյան" />

      <SearchBackground>
        <PartnerBalanceBookAction
          printHandler={handlePrint}
          mainData={partnerBalancesData}
          date={date}
          setDate={setDate}
          currencyVal={currencyVal}
          setCurrencyVal={setCurrencyVal}
          viewAccounts={viewAccounts}
          setViewAccounts={setViewAccounts}
          partnerTypeVal={partnerTypeVal}
          setPartnerTypeVal={setPartnerTypeVal}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </SearchBackground>

      <PartnerBalanceBookTable
        mainData={partnerBalancesData?.balances}
        loading={isLoading}
        viewAccounts={viewAccounts}
        getData={getData}
      />
    </>
  );
};

export default GetPartnerBalanceBookData;
