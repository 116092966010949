import * as Yup from "yup";
import { SelectFieldMessage, InputFiledMessage } from './ErrorMessage';

import { Amount } from './ValidationForAllFiled';

export const CashWarrantFormValidation = () =>
  Yup.object().shape({
    amount: Amount,
    recipient: Yup.string().required(InputFiledMessage),
    accountDebit: Yup.string().required(SelectFieldMessage),
    correspAccount: Yup.string().required(SelectFieldMessage),
  });
