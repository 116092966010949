import {makeAutoObservable} from "mobx"

export default class EntrStore{
    constructor(){
      
        this._entrs=[
            
        ]
        makeAutoObservable(this)
    }
//action
   
    setEntr(entrs){
        this._entrs=entrs
    }


//computed
    get Entr(){
        return this._entrs
    }
}