import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootreducer";
const middleware = [thunk];

const configureStore = () => {
  let store = null;
  if (process.env.NODE_ENV === "development") {
    const composeEnhancers =
      (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
      compose;

    store = createStore(
      rootReducer,
      {},
      composeEnhancers(applyMiddleware(...middleware))
    );
  } else {
    store = compose(applyMiddleware(...middleware))(createStore)(rootReducer);
  }
  return store;
};

export default configureStore();
