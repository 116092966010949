import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import AspectList from '../components/AspectList';
import CreateAspect from '../components/modals/CreateAspect';
import { Context } from '../../index.js';
import { createBulk } from '../http/aspectAPI';
import { getAspect } from '../http/aspectAPI';
import { getEntr } from '../http/entrAPI';
import edit from '../icons/edit.png';
import trash from '../icons/trash.png';
import { deleteEntr } from '../http/entrAPI';
import UpdateEntr from '../components/modals/UpdateEntr.js';
import { Commas, dateFormat, sortByDate } from '../Utilities/Utilities';
import { startOfMonth } from '../Utilities/Utilities.js';
import moment from 'moment';
import DateInterval from '../components/DateInterval/DateInterval';
import HelpBtn from '../components/HelpBtn/HelpBtn';
import HelperModal from '../components/HelperModal/HelperModal';
import { HMAspect } from '../components/helperMessage/HM';
import Header from '../components/Header/Header';
import { useSelector } from 'react-redux';
import '../Style/table.scss';
import './index.scss';
import Menu from '../components/Menu/Menu';
import Button from '../components/Button/Button.jsx';
import DeleteMsg from '../components/DeleteMsg/DeleteMsg';

const Aspect = observer(() => {
  const { userID } = useSelector((state) => state.auth);
  const { currency, entr, aspect, not, standardAspects } = useContext(Context);
  const [aspectVisible, setAspectVisible] = useState(false);
  const [enterData, setEnterData] = useState(false);
  const [dateStart, setDateStart] = useState(new Date(startOfMonth));
  const [dateEnd, setDateEnd] = useState(new Date());
  const [openHelperModal, setOpenHelperModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const HookGet = async (userID) => {
    try {
      const data = await getAspect(userID);
      if (data.data) {
        aspect.setAspect(data.data);
      }
    } catch (e) {}

    if (aspect.Aspect.length === 0) {
      const standartAsp = standardAspects.StandardAspects?.map((asp) => {
        return {
          name: asp.name,
          imgPath: asp.imgPath,
          imgColor: asp.imgColor,
          singT: asp.singT,
          singE: asp.singE,
          userId: userID,
        };
      });
      const data = await createBulk(standartAsp);
      aspect.setAspect(data.data);
    }

    try {
      const data = await getEntr(userID);
      if (data.data) {
        entr.setEntr(data.data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    HookGet(userID);
  }, [userID]);

  const entreArr = entr?.Entr;

  const dts = moment(dateStart).format('yyyy-MM-DD');

  const dte = moment(dateEnd).format('yyyy-MM-DD');

  const entreAr = entreArr.filter((entr) => {
    return (
      moment(entr.crAt).format('yyyy-MM-DD') >= dts &&
      moment(entr.crAt).format('yyyy-MM-DD') <= dte
    );
  });

  const deleteHandler = async (Id) => {
    await deleteEntr(Id);
    entr.setEntr(entr.Entr.filter((ent) => ent?.id !== Id));
    setShowRemoveModal(false);
  };

  const openEntreModal = (row) => {
    if (row) {
      setEnterData(row);
    }
  };

  const sortData = entreAr && [...entreAr].sort(sortByDate);

  const helpHandler = () => {
    setOpenHelperModal(true);
  };

  return (
    <div className="fin-help-wrap">
      <Menu />

      <div className="aspect-wrap">
        <HelpBtn helpHandler={helpHandler} />
        <Header pageTitle="Տվյալների մուտքագրում" />

        <div className="aspect-container">
          <div className="aspect-container-flex">
            <div className="left-panel">
              <div className="G-flex">
                <Button
                  text="Ստեղծել նոր տիպ"
                  customClass="add-category-type-btn"
                  onClick={() => setAspectVisible(true)}
                />
              </div>

              <AspectList />
            </div>
            <div className="right-panel">
              <DateInterval
                dateStart={dateStart}
                setDateStart={setDateStart}
                dateEnd={dateEnd}
                setDateEnd={setDateEnd}
              />

              <div className="data-entry-table scrolling-table has-scroll">
                <table className="my-table">
                  <thead className="table-thead">
                    <tr>
                      <th>Ամսաթիվ</th>
                      <th>Գործառնություն</th>
                      <th>Տիպ</th>
                      <th>Արժույթ</th>
                      <th>Գումար</th>
                      <th>Նշում</th>
                      <th>Խմբագրել</th>
                      <th>Հեռացնել</th>
                    </tr>
                  </thead>
                  <tbody className="table-tbody">
                    {sortData?.map((en) => (
                      <tr key={en.id} className="tr-size">
                        <td>{dateFormat(en.crAt)}</td>
                        <td>{en.accountName}</td>
                        <td>{en.aspectName}</td>
                        <td>{en.curr}</td>

                        {en.accountName === 'Եկամուտ' ? (
                          <td style={{ color: 'green', textAlign: 'right' }}>
                            {Commas(en.amount)}
                          </td>
                        ) : (
                          <td style={{ color: 'red', textAlign: 'right' }}>
                            {Commas(en.amount)}
                          </td>
                        )}

                        <td
                          title={en.comment}
                          style={{ maxWidth: '200px', overflow: 'hidden' }}
                        >
                          {en.comment}
                        </td>
                        <td>
                          <div
                            className="category-action"
                            onClick={() => openEntreModal(en)}
                          >
                            <img src={edit} alt="" />
                          </div>
                        </td>
                        <td>
                          <div
                            className="category-action"
                            //onClick={() => deleteHandler(en.id)}
                            onClick={() => setShowRemoveModal(en.id)}
                          >
                            <img src={trash} alt="" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {enterData && (
        <UpdateEntr closeModal={setEnterData} enterData={enterData} />
      )}

      {aspectVisible && <CreateAspect closeModal={setAspectVisible} />}
      {openHelperModal && (
        <HelperModal
          closeHelpBox={setOpenHelperModal}
          helperMessage={HMAspect}
        />
      )}

      {showRemoveModal && (
        <DeleteMsg
          setShowModal={setShowRemoveModal}
          deleteItem={() => deleteHandler(showRemoveModal)}
          modalContent="Համոզվա՞ծ եք որ ուզում եք ջնջել այն"
          customClass="remove-modal"
        />
      )}
    </div>
  );
});
export default Aspect;
