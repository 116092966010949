import { createAction } from "../actionCreators";
import {
  LOADING,
  GET_SPENDING_MONEY_DATA,
  DELETE_SPENDING_MONEY,
  GET_SPENDING_DEALS_DATA,
  UPDATE_CASH_MONEY_DATA,
  ADD_SPENDING_MONEY_DEALS_DATA,
  EDIT_SPENDING_MONEY_DEALS_DATA,
  DELETE_SPENDING_MONEY_DEALS_DATA,
  UPDATE_PARTNER_SPENDING_MONEY,
  IS_CLOSE_DOCUMENT,
  UPDATE_ACCOUNT_MONEY_FORM,
} from './constants';

export const getSpendingMoneyAction = createAction(GET_SPENDING_MONEY_DATA);
export const deleteSpendingMoneyDocAction = createAction(DELETE_SPENDING_MONEY);
export const loadingAction = createAction(LOADING, false);
export const getSpendingMoneyDataAction = createAction(GET_SPENDING_DEALS_DATA);
export const updateCashMoneyDataAction = createAction(UPDATE_CASH_MONEY_DATA);
export const addSpendingMoneyDealsAction = createAction(
  ADD_SPENDING_MONEY_DEALS_DATA,
);
export const editSpendingMoneyDealsAction = createAction(
  EDIT_SPENDING_MONEY_DEALS_DATA,
);
export const deleteSpendingMoneyDealsAction = createAction(
  DELETE_SPENDING_MONEY_DEALS_DATA,
);

export const updatePartnerAction = createAction(UPDATE_PARTNER_SPENDING_MONEY);

export const closeDocumentAction = createAction(IS_CLOSE_DOCUMENT);
export const updateAccountMoneyFormAction = createAction(
  UPDATE_ACCOUNT_MONEY_FORM,
);


