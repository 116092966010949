import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import EventEditForm from './EventEditForm';
import OperationForm from './OperationForm';
import Action from '../../Components/Table/Action';
import { deleteSpendingMoneyDealsAction } from '../../Redux/accountForSpendingMoneySlices/useAction';
import Table from '../../Components/Table/Table';
import {
  formatNumberWithCommas,
  tableActionTitle,
} from '../../Utilities/Utilities';
import { deleteRequest, getRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../Components/Alert';

const SpendingMoneyDealsTable = ({
  mainData,
  setSubmitted,
  getCashMoneyId,
  disabledCloseDoc,
}) => {
  const [errorHandler, setErrorHandler] = useState('');
  const expensesData = mainData !== null ? mainData?.expenses : [];
  const dispatch = useDispatch();

  const [editRowData, setEditRowData] = useState(false);
  const [openOperationsForm, setOpenOperationsForm] = useState(false);
  const [removeData, setRemoveData] = useState(false);

  const createActionItem = (row) => {
    const { isNew } = row || {};

    const actionList = [
      {
        name: 'Խմբագրել',
        value: 'edit',
      },
      {
        name: 'Ջնջել',
        value: 'delete',
      },
    ];

    if (!isNew) {
      actionList.push({
        name: 'Գործառնություն',
        value: 'operations',
      });
    }

    return actionList;
  };

  const columnConfig = [
    {
      title: 'Փաստաթղթի անվանումը, համարը, ամսաթիվը',
      cell: (row) => row.documentName,
      customStyle: { maxWidth: 350 },
    },
    {
      title: 'Ծախսման նպատակը',
      cell: (row) => row.purpose,
      customStyle: { maxWidth: 650 },
    },
    {
      title: 'Քանակը',
      cell: (row) => row.quantity,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Գինը',
      cell: (row) => formatNumberWithCommas(row.price),
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գումարը',
      cell: (row) => formatNumberWithCommas(row.amount),
      customStyle: { maxWidth: 120 },
    },
    {
      title: tableActionTitle,
      cell: (row) =>
        disabledCloseDoc ? null : (
          <Action
            row={row}
            handelChange={handelChange}
            actionItem={createActionItem(row)}
            modalIsOpen={
              editRowData || removeData || openOperationsForm || errorHandler
            }
          />
        ),
      customStyle: { maxWidth: 110 },
    },
  ];

  const handelChange = (value, row) => {
    switch (value) {
      // case 'delete':
      //   return row?.fromData
      //     ? setRemoveData(row)
      //     : removeFromFront(row?.expenseID);

      case 'delete':
        return setRemoveData(row);
      case 'edit':
        return editHandler(row);
      case 'operations':
        return openOperationsModal(row);
      default:
        return null;
    }
  };

  const removeFromFront = (expenseID) => {
    dispatch(deleteSpendingMoneyDealsAction(expenseID));
  };

  const removeHandler = () => {
    const { fromData, expenseID } = removeData || {};

    if (fromData) {
      deleteRequest(`deleteCashMoneyExpense/${expenseID}`).then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(deleteSpendingMoneyDealsAction(expenseID));
          setRemoveData(false);
        }
        if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      });
    } else {
      dispatch(deleteSpendingMoneyDealsAction(expenseID));
      setRemoveData(false);
    }
  };

  const editHandler = (row) => {
    const { fromData, expenseID } = row || {};
    if (fromData) {
      getRequest(`forEditCashMoneyExpense/${expenseID}`)
        .then((res) => {
          if (res.data.errorCode === 0) {
            setEditRowData(row);
          }
          if (res.data.errorCode > 0) {
            setErrorHandler(res.data.message);
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
    if (!fromData) {
      setEditRowData(row);
    }
  };

  const openOperationsModal = (row) => {
    const { cashMoneyID, expenseID } = row || {};
    const cashMoneyId = getCashMoneyId || cashMoneyID;

    if (cashMoneyId && expenseID) {
      setOpenOperationsForm({ cashMoneyId, expenseID });
    }
  };

  return (
    <>
      {expensesData !== null && expensesData && expensesData?.length ? (
        <Table
          customClass="L-spending-money-deals-table"
          data={expensesData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}
      {editRowData && (
        <EventEditForm
          rowData={editRowData}
          closeModal={setEditRowData}
          setSubmitted={setSubmitted}
        />
      )}

      {openOperationsForm && (
        <OperationForm
          getCashMoneyId={getCashMoneyId}
          closeModal={setOpenOperationsForm}
          selectRowData={openOperationsForm}
          setErrorHandler={setErrorHandler}
        />
      )}

      {removeData && (
        <AcceptOrCancelModal
          closeModal={setRemoveData}
          confirmClick={removeHandler}
          modalContent={
            removeData?.fromData
              ? 'Կհեռացվի նաև տվյալ տողի գործառնությունը համոզվա՞ծ եք որ ուզում եք ջնջել այն'
              : 'Համոզվա՞ծ եք որ ուզում եք ջնջել այն'
          }
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default SpendingMoneyDealsTable;
