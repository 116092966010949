export const BankOptions = (bankData) => {
  return (
    bankData &&
    bankData?.map(({ bankNameOnly, companyBankID, bankID }) => {
      return {
        label: bankNameOnly,
        value: bankNameOnly,
        companyBankID: companyBankID,
        bankID: bankID,
      };
    })
  );
};

export const currencyOptions = (currencData) => {
  return (
    currencData &&
    currencData?.map(
      ({ currencyCode, currencyID, companyID, currencyName }) => {
        return {
          companyID: companyID,
          currencyName: currencyName,
          label: currencyCode,
          value: currencyCode,
          currencyID: currencyID,
        };
      },
    )
  );
};
