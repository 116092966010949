import React from 'react';
import EventEditForm from './EventEditForm';
import EventRemove from './EventRemove';

const Forms = ({ formType, closeModal }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};

  switch (type) {
    case 'delete':
      return (
        <EventRemove
          prodGroupID={value?.prodGroupID}
          handleClose={handleClose}
        />
      );
    case 'edit':
      return (
        <EventEditForm
          prodGroupID={value?.prodGroupID}
          handleClose={handleClose}
        />
      );

    default:
      return null;
  }
};

export default Forms;
