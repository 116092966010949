import { deleteRequest, getRequest, postRequest } from '../../App/Api/Api';
export const fetchConfirmEmployeePosition = async (companyID, empNo) => {
  try {
    const response = await getRequest(
      `getConfirmEmployeeSalary?companyID=${companyID}&empNo=${empNo}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const saveConfirmEmployeePosition = async (data) => {
  try {
    const response = await postRequest('saveSalaries ', data);

    return response.data;
  } catch (error) {
    console.error('Error adding ConfirmEmployeePositions:', error);
    throw error;
  }
};

export const fetchDeleteEmployeePosition = async (tableId) => {
  try {
    const response = await deleteRequest(
      `deleteConfirmEmployeeSalary/${tableId}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
