import {
  ADD_INVOICE_GOODS,
  DELETE_INVOICE,
  DELETE_INVOICE_GOODS,
  GET_INVOICE_DATA,
  GET_INVOICE_FORM_DATA,
  LOADING,
  UPDATE_INVOICE_DATA,
  UPDATE_INVOICE_TABLE_DATA,
  UPDATE_PARTNER_INVOICE,
  ADD_PRODUCT,
  ADD_UNIT_OF_MEASURE,
} from './constants';
import { createAction } from '../actionCreators';

export const getInvoiceAction = createAction(GET_INVOICE_DATA);
export const loadingAction = createAction(LOADING, false);
export const deleteInvoiceActAction = createAction(DELETE_INVOICE);
export const getInvoiceFormAction = createAction(GET_INVOICE_FORM_DATA);
export const deleteInvoiceGoodsAction = createAction(DELETE_INVOICE_GOODS);
export const addInvoiceGoodsAction = createAction(ADD_INVOICE_GOODS);
export const updateInvoiceDateAction = createAction(UPDATE_INVOICE_DATA);
export const updateInvoiceTableDataAction = createAction(UPDATE_INVOICE_TABLE_DATA);
export const updatePartnerAction = createAction(UPDATE_PARTNER_INVOICE);
export const updateProductAction = createAction(ADD_PRODUCT);
export const updateUnitOfMeasureAction = createAction(ADD_UNIT_OF_MEASURE);
