import React, { useState } from "react";
import Action from "../../../Components/Table/Action";
import Table from "../../../Components/Table/Table";
import { editDeleteList } from "../../../OptionsList/OptionsList";
import { tableActionTitle } from "../../../Utilities/Utilities";
import DeletePartnerBank from "./DeletePartnerBank";
import EventEditForm from "./EventEditForm";

const PartnerBankTable = ({ mainData }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handelChange = (value, row) => {
    switch (value) {
      case "edit":
        return openEditForm(row);
      case "delete":
        return openDeleteForm(row?.partnerBankDetailID);
      default:
        return null;
    }
  };

  const columnConfig = [
    {
      title: 'Բանկի կոդը',
      cell: (row) => row.bankCode,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Բանկի անվանումը',
      cell: (row) =>
        row.bankType === 1 ? row.foreignBankName : row.bankName?.substring(5),
      customStyle: { maxWidth: 700, overflow: 'hidden' },
    },

    {
      title: 'Գործընկերոջ Հաշվ.հաշիվը',
      cell: (row) => row.bankingAccount,
      customStyle: { maxWidth: 230 },
    },
    {
      title: 'Նշումներ',
      cell: (row) => row.comment,
      customStyle: { maxWidth: 220, overflow: 'hidden' },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={handelChange}
          actionItem={editDeleteList}
          modalIsOpen={openEditModal || openDeleteModal}
        />
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  const openEditForm = (rowValue) => {
    setOpenEditModal(rowValue);
  };

  const openDeleteForm = (deleteRowId) => {
    setOpenDeleteModal(deleteRowId);
  };

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-partner-bank-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ""
      )}

      {openDeleteModal && (
        <DeletePartnerBank
          deleteRowId={openDeleteModal}
          closeModal={setOpenDeleteModal}
        />
      )}

      {openEditModal && (
        <EventEditForm closeModal={setOpenEditModal} rowData={openEditModal} />
      )}
    </>
  );
};

export default PartnerBankTable;
