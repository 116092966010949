import {
  DELETE_NON_WORKING_DAYS,
  FETCH_NON_WORKING_DAYS,
  FETCH_NON_WORKING_DAYS_FORM,
  UPDATE_NON_WORKING_DAYS,
} from './constants';

const initialState = {
  nonWorkingDays: null,
  nonWorkingDayForm: null,
};

export const nonWorkingDaysReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_NON_WORKING_DAYS:
      return {
        ...state,
        nonWorkingDays: payload,
      };

    case FETCH_NON_WORKING_DAYS_FORM:
      return {
        ...state,
        nonWorkingDayForm: payload,
      };
    case UPDATE_NON_WORKING_DAYS:
      return {
        ...state,
        nonWorkingDays: state.nonWorkingDays.map((item) => {
          if (item.nwDayID === payload.nwDayID) {
            return payload;
          }
          return item;
        }),
      };
    case DELETE_NON_WORKING_DAYS:
      return {
        ...state,
        nonWorkingDays: state.nonWorkingDays.filter(
          (item) => item.nwDayID !== payload,
        ),
      };
    default:
      return { ...state };
  }
};
