import React from 'react';
import Alert from '../../../App/Components/Alert';
import WarehouseEntryForm from './WarehouseEntryForm';
import useWarehouseEntry from '../../hooks/useWarehouseEntry';

const EventAddForm = ({ handleClose, params }) => {
  const { errorMess, setErrorMess, addWarehouseEntry, warehouseEntryForm } =
    useWarehouseEntry();

  const onAddHandler = async (values, resetForm) => {
    const modifiedData = {
      matInput: {
        ...values,
        docNum: Number(values.docNum),
      },
      inputs: warehouseEntryForm?.inputs,
    };
    try {
      await addWarehouseEntry(modifiedData, params, handleClose);
      if (errorMess) {
        resetForm();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <WarehouseEntryForm onSubmit={onAddHandler} handleClose={handleClose} />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
