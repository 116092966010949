import React, { useEffect } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import { useFormik } from 'formik';
import useIsMounted from '../../hooks/useIsMounted';
import PaymentListsBtn from './PaymentListsBtn';
import {
  dataTimeFormat,
  dateFormat,
  removeCommas,
} from '../../../App/Utilities/Utilities';
import DateComponent from '../../components/dateComponent/DateComponent';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import Input from '../../Input/Input';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';

const NewPaymentListForm = ({
  handleClose,
  getNewPaymentListForm,
  bankCode,
  newPaymentListForm,
  getNewPaymentList,
  setIsDisabledButtons,
}) => {
  const isMounted = useIsMounted();
  useEffect(() => {
    getNewPaymentListForm(bankCode);

    setIsDisabledButtons((prevState) => ({
      ...prevState,
      confirm: false,
    }));
  }, [bankCode]);

  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...newPaymentListForm, amount: 0 },
    //validationSchema: TablesValidation,
    onSubmit: async (values, { resetForm }) => {
      const params = {
        bankCode: values.bankCode,
        percent: values.percent,
        salaryDate: dataTimeFormat(values.salaryDate),
        amount: removeCommas(values.amount),
      };

      try {
        await getNewPaymentList(params, resetForm);
        if (isMounted) {
          resetForm();
          handleClose();
        }
      } catch (error) {
        console.error('Failed to submit form:', error);
      }
    },
  });

  const buttonsConfig = [
    {
      text: 'Չեղարկել',
      onClick: () => handleClose(),
      type: 'submit',
    },
    {
      text: 'Հաստատել',
      onClick: () => handleSubmit(),
      type: 'submit',
      //disabled: ,
    },
  ];
  const onChangeData = (date) => {
    setFieldValue('salaryDate', dataTimeFormat(date));
  };

  const customHandleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    if (name === 'percent') {
      setFieldValue('amount', 0);
    } else if (name === 'amount') {
      setFieldValue('percent', 0);
    }
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-new-paymentList-form"
    >
      <DateComponent
        openToDate={new Date(values?.salaryDate)}
        value={dateFormat(values?.salaryDate)}
        title="Ամսաթիվ"
        onChange={(date, e) => onChangeData(date, e)}
        name=""
      />
      <p className="bank-name">{values.bankName}</p>

      <Checkbox
        checked={values.preSign}
        value={values.preSig}
        onChange={() => setFieldValue('preSign', !values.preSign)}
        label="Կանխավճար"
      />
      {values?.preSign && (
        <div className="L-new-paymentList-input-flex">
          <Input
            type="number"
            inputTitle="Դրույքի %"
            name="percent"
            requiredFiled={false}
            onChange={customHandleChange}
            value={values.percent}
            disabled={!values.preSign}
          />

          <NumericFormatFiled
            value={values.amount}
            allowNegative={true}
            name="amount"
            changeHandler={customHandleChange}
            inputTitle="Գումար"
          />
        </div>
      )}

      <PaymentListsBtn
        buttonsConfig={buttonsConfig}
        className="L-new-paymentList-form-btn"
      />
    </Modal>
  );
};

export default NewPaymentListForm;
