import React from 'react';
import { Commas } from '../../../App/Utilities/Utilities';

const CalculationSheetTotal = ({ totals }) => (
  <div className="G-calculation-total">
    <div className="G-calculation-total-box">
      <h4>Ընդամենը</h4>
      <p>{Commas(totals?.totalAdd)}</p>
      <p>{Commas(totals?.totalDed)}</p>
    </div>

    <div className="G-calculation-total-box">
      <h4>Ենթակա է վճարման</h4>
      <p>{Commas(totals?.toBePayed)}</p>
    </div>
  </div>
);

export default CalculationSheetTotal;
