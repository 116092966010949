import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import Modal from '../../Components/Modal/Modal';
import SelectAccount from '../../Components/SelectBox/SelectAccount';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';

import { getRequest, postRequest } from '../../Api/Api';
import { IncomeTaxFormValidation } from '../../Validation/PurchaseValidation';

import NumericFormatFiled from '../../Components/NumericFormat/NumericFormatFiled';
import { useDispatch } from 'react-redux';
import { updateIncomeTaxAction } from '../../Redux/purchaseActSlices/useAction';
import { removeCommas } from '../../Utilities/Utilities';

const IncomeTaxForm = ({ modalClick, purchaseID, closePurchaseForm }) => {
  const handleClose = () => modalClick(false);
  const handleClosePurchase = () => closePurchaseForm(false);
  const dispatch = useDispatch();
  const [purchaseIncomeEntry, setPurchaseIncomeEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchPurchaseIncomeEntry = async () => {
    const abortController = new AbortController();
    setLoading(true);

    try {
      const response = await getRequest(
        `getPurchaseIncomeEntry?purchaseID=${purchaseID}`,
        {
          signal: abortController.signal,
        },
      );
      setPurchaseIncomeEntry(response.data);
    } catch (err) {
      if (!abortController.signal.aborted) {
        console.error('Error in fetchPurchaseIncomeEntry:', err);
      }
    } finally {
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    }

    return () => abortController.abort();
  };

  useEffect(() => {
    if (purchaseID) {
      fetchPurchaseIncomeEntry(purchaseID);
    }
  }, [purchaseID]);
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      purchaseID: purchaseID,
      amount: purchaseIncomeEntry?.amount,
    },
    validationSchema: IncomeTaxFormValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const handleChangeAccount = (values, name) => {
    if (!values) {
      values = {
        value: '',
      };
    }
    if (name === 'credit') {
      setFieldValue('creditAccountCode', values.value);
    }

    if (name === 'debit') {
      setFieldValue('debitAccountCode', values.value);
    }
  };

  const onSubmit = (values, resetForm) => {
    postRequest('savePurchaseIncomeEntry', {
      ...values,
      amount: removeCommas(values.amount),
    })
      .then((res) => {
        const { errorCode, message, toBeContinued } = res.data;
        if (errorCode === 0 && toBeContinued === false) {
          dispatch(updateIncomeTaxAction(false));
          resetForm();
          handleClose();
        }
        if (errorCode === 0) {
          resetForm();
          dispatch(updateIncomeTaxAction(false));
          fetchPurchaseIncomeEntry();
          handleClosePurchase();
        } else if (errorCode > 0 && !message) {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <Modal
      customClass="L-income-tax-modal"
      closeHandler={handleClose}
      onDrag={true}
    >
      {loading ? (
        <p>Բեռնում է...</p>
      ) : (
        <div className="L-income-tax-form">
          <div className="G-modal-block-title">
            <h3>եկամտային հարկ</h3>
          </div>
          <form onSubmit={onSubmit}>
            <div className="L-income-tax-filed">
              <SelectAccount
                onBlur={handleBlur}
                customClass=""
                title="Կրեդիտ"
                accountData={purchaseIncomeEntry?.creditAccounts}
                accountVal={{
                  label: values.creditAccountCode,
                  value: values.creditAccountCode,
                }}
                selectChangeAccount={(values) =>
                  handleChangeAccount(values, 'credit')
                }
              />

              {touched.creditAccountCode && errors.creditAccountCode && (
                <ErrorFiled error={errors.creditAccountCode} />
              )}
            </div>
            <div className="L-income-tax-filed">
              <SelectAccount
                onBlur={handleBlur}
                customClass=""
                title="Դեբետ"
                accountData={purchaseIncomeEntry?.debitAccounts}
                accountVal={{
                  label: values.debitAccountCode,
                  value: values.debitAccountCode,
                }}
                selectChangeAccount={(values) =>
                  handleChangeAccount(values, 'debit')
                }
              />

              {touched.debitAccountCode && errors.debitAccountCode && (
                <ErrorFiled error={errors.debitAccountCode} />
              )}
            </div>
            <div className="L-income-tax-filed">
              <NumericFormatFiled
                value={values.amount}
                name="amount"
                onBlur={handleBlur}
                changeHandler={handleChange}
                inputTitle="Գումարը"
                requiredFiled={true}
              />
              {touched.amount && errors.amount && (
                <ErrorFiled error={errors.amount} />
              )}
            </div>

            <GetSentDataBtn
              cancelPrintClick={handleClose}
              confirmExecuteClick={handleSubmit}
              executeClass={!(isValid && dirty) ? 'G-disabled-button' : ''}
              executeDisabled={!(isValid && dirty)}
            />
          </form>
        </div>
      )}
    </Modal>
  );
};

export default IncomeTaxForm;
