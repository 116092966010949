import React, { useState, useEffect } from 'react';
import './style.scss';
import Alert from '../../Components/Alert';
import TaxationSystemForm from './TaxationSystemForm';
import CompanyTaxSystemHeader from './CompanyTaxSystemHeader';
import useCompanyTaxationSystem from '../../Hooks/companyTaxationSystem/useCompanyTaxationSystem';
import ReportingPeriodForm from './ReportingPeriodForm';
import { dataTimeFormat } from '../../Utilities/Utilities';

const CompanyTaxationSystemForm = () => {
  const {
    getCompanyTaxationSystem,
    companyTaxationSystemData,
    errorMess,
    setErrorMess,
    addTaxationSystem,
    addReportingPeriod,
  } = useCompanyTaxationSystem();

  const [taxationFormData, setTaxationFormData] = useState({
    userID: '',
    companyID: '',
    taxationID: '',
    taxationSystemName: '',
    taxationDate: '',
  });

  const [reportingPeriodFormData, setReportingPeriodFormData] = useState({
    startDate: '',
    endDate: '',
  });

  const [hasTaxationChanged, setHasTaxationChanged] = useState(true);
  const [hasReportingPeriodChanged, setHasReportingPeriodChanged] =
    useState(true);

  useEffect(() => {
    getCompanyTaxationSystem();
  }, []);

  useEffect(() => {
    const companyData = companyTaxationSystemData?.company || {};
    setTaxationFormData((prev) => ({
      ...prev,
      ...companyData,
      taxationSystemName: companyData.taxationSystemName || '',
      taxationID: companyData.taxationID || 0,
      taxationDate: companyData.taxationDate || '',
    }));

    setReportingPeriodFormData({
      startDate: companyData.startDate || '',
      endDate: companyData.endDate || '',
    });
  }, [companyTaxationSystemData]);

  const handleTaxationChange = (options) => {
    setTaxationFormData((prev) => ({
      ...prev,
      taxationSystemName: options.value,
      taxationID: options.id,
    }));
    setHasTaxationChanged(false);
  };
  const handleTaxationDate = (date) => {
    setTaxationFormData((prev) => ({
      ...prev,
      taxationDate: dataTimeFormat(date),
    }));
    setHasTaxationChanged(false);
  };

  const handleReportingPeriodChange = (name, date) => {
    setReportingPeriodFormData((prev) => ({
      ...prev,
      [name]: dataTimeFormat(date),
    }));

    setHasReportingPeriodChanged(false);
  };

  const taxationSystemOptions =
    companyTaxationSystemData?.taxationSystems?.map(
      ({ taxationSystemName, taxationSystemID }) => ({
        label: taxationSystemName,
        value: taxationSystemName,
        id: taxationSystemID,
      }),
    ) || [];

  const addTaxationSystemHandler = async () => {
    try {
      const payload = {
        userID: taxationFormData.userID,
        companyID: taxationFormData.companyID,
        taxationID: taxationFormData.taxationID,
        taxationSystemType: taxationFormData.taxationSystemName,
        taxationDate: taxationFormData.taxationDate,
      };
      await addTaxationSystem(payload);
      setHasTaxationChanged(true);
    } catch (error) {
      setErrorMess(error.message || 'Failed to add taxation system');
    }
  };

  const addReportingPeriodHandler = async () => {
    try {
      const payload = {
        userID: taxationFormData.userID,
        companyID: taxationFormData.companyID,
        startDate: reportingPeriodFormData.startDate,
        endDate: reportingPeriodFormData.endDate,
      };
      await addReportingPeriod(payload);
      setHasReportingPeriodChanged(true);
    } catch (error) {
      setErrorMess(error.message || 'Failed to add reporting period');
    }
  };

  return (
    <div>
      <CompanyTaxSystemHeader
        companyName={companyTaxationSystemData?.company?.companyName || ''}
      />
      <div className="L-company-taxation-system-flex">
        <TaxationSystemForm
          taxationSystemOptions={taxationSystemOptions}
          initialTaxSystemData={taxationFormData}
          handleTaxationDate={handleTaxationDate}
          handleTaxationChange={handleTaxationChange}
          isButtonDisabled={!hasTaxationChanged}
          onSubmit={addTaxationSystemHandler}
        />
        <ReportingPeriodForm
          initialReportingPeriod={reportingPeriodFormData}
          handleReportingPeriodChange={handleReportingPeriodChange}
          isButtonDisabled={!hasReportingPeriodChanged}
          onSubmit={addReportingPeriodHandler}
        />
      </div>

      <Alert description={errorMess} onClose={() => setErrorMess(null)} />
    </div>
  );
};

export default CompanyTaxationSystemForm;
