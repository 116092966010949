import React from 'react';
import NumericFormatFiled from '../../../../App/Components/NumericFormat/NumericFormatFiled';

const SalaryAmount = ({ formData, handleInputChange }) => {
  return (
    <div className="L-salary-display-input L-salary-display-item">
      <NumericFormatFiled
        value={formData.amount}
        allowNegative={true}
        name="amount"
        changeHandler={(e) => handleInputChange(e)}
        inputTitle=""
        placeholder="Մուտքագրել"
      />
    </div>
  );
};

export default SalaryAmount;
