import { deleteRequest, getRequest, postRequest, putRequest } from '../Api/Api';

export const fetchCompanyTaxationSystem = async (companyID) => {
  try {
    let url = `getAccCompany/${companyID}`;
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
export const fetchHistoryOfTaxSystemChanges = async (companyID) => {
  try {
    let url = `getTaxSystemDates?companyID=${companyID}`;
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const registerTaxationSystem = async (newTaxationSystem) => {
  try {
    const response = await putRequest('editAccCompany', {
      ...newTaxationSystem,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding newTaxationSystem:', error);
    throw error;
  }
};

export const registerReportingPeriod = async (newReportingPeriod) => {
  try {
    const response = await putRequest('editCompanyPeriod', {
      ...newReportingPeriod,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding newReportingPeriod:', error);
    throw error;
  }
};

export const deleteHistoryOfTaxSystemChanges = async (id) => {
  try {
    const response = await deleteRequest(`deleteTaxSystemDate/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting deleteTaxSystemDate:', error);
    throw error;
  }
};
