import React from 'react';
import Button from '../../../App/Components/Button/Button';

const ConfirmPositionsActionButtons = ({
  onBaselineState,
  onSaveHandler,
  isDisabledConfBtn,
}) => {
  return (
    <div className="L-conf-pos-act-btn-flex">
      <div className="L-confirm-positions-btn">
        <Button
          onClick={onSaveHandler}
          text="Հաստատել"
          type="submit"
          disabled={isDisabledConfBtn}
          customClass={isDisabledConfBtn && 'G-disabled-button'}
        />
      </div>

      <div className="L-confirm-positions-btn">
        <Button
          onClick={onBaselineState}
          text="Ելակետային վիճակ"
          type="submit"
        />
      </div>
    </div>
  );
};

export default ConfirmPositionsActionButtons;
