export const QuestionsList = [
  {
    caption: 'Ընդհանուր հարցեր',
    title: 'Ու՞մ համար է նախատեսված այս հարթակը։',
    description:
      'Հարթակը նախատեսված է փոքր բիզնեսով զբաղվող ընկերություններին, ձեռներեցներին, անհատներին օգնելու համար։',
  },
  {
    title:
      'Ես անհատ եմ, ոչ ձեռներեց եմ, ոչ էլ փոքր բիզնես ունեմ։ Ին՞չ կտա ինձ այս հարթակը։',
    description:
      'Դուք կարող եք օգտվել "Անձնական բյուջե", "Օրացույց" ծրագրերից և մի շարք "Օգտակար հղումներ" մասերից։',
  },
  {
    title: 'Ին՞չ գիտելիքներ են պետք հարթակից օգտվելու համար։',
    description:
      'Մասնագիտական գիտելիքներ անհրաժեշտ են միայն  "Հաշվապահական հաշվառում" մասում, հարթակի մյուս մասերում առանձնակի գիտելիքներ չեն պահանջվում, առաջարկված օգնությամբ շատ պարզ  է աշխատելը:',
  },
  {
    title: 'Իսկ արդյո՞ք  անհրաժեշտ են հաշվապահական խորը գիտելիքներ։',
    description:
      'Ծրագրից օգտվելիս հաշվապահական խորը գիտելիքներ անհրաժեշտ չեն։ Ծրագիրը տալիս է պարզ, հարմար, դյուրին աշխատանքային միջոցներ՝ «թուղթ, մատիտ, ռետին»: ՈՒնի արագ կողմնորոշելու մեխանիզմ, կան բազմաթիվ  փաստաթղթեր, որոնք ճիշտ լրացնելիս, ինքնաշխատ տրվում են հաշվապահական ձևակերպումներ, ձեզ  անհրաժեշտ է  միայն ստուգել  և  տպել։',
  },
  {
    title: 'Ին՞չ յուրահատկություն ունի 1Պ  հարթակը։',
    description:
      'Այն գործում է մեկ պատուհանի սկզբունքով։ Հաշվապահական հաշվառման հետ զուգահեռ կան հարկային հաշվառմամ ինքնաշխատ կատարվող գործառնություններ: Մենք մեկ տեղում ենք հավաքել տարբեր գործիքներ, ապահովելով տեղեկատվական կայքերից օգտվելու հնարավորություն, սեփական բիզնեսի վարումը հեշտացնելու համար։',
  },
  {
    title:
      'Ես անհատ ձեռներեց եմ։ Կարո՞ղ եմ իմ հաշվապահության վարումը վստահել հարթակի թիմին։',
    description:
      'Այո, վստահաբար,  հարթակի  բազմամյա փորձ ունեցող թիմը լիարժեք կկատարի ձեր բիզնեսի հաշվապահական հաշվառումը։',
  },
  {
    title: 'Ինչ՞ է իրենից ներկայացնում «Անձնական բյուջե» ծրագիրը։',
    description:
      'Ծրագրի միջոցով հնարավոր է իրականացնել անձնական միջոցների հաշվառում, եկամուտների և ծախսերի մուտքագրելը  շատ պարզ ու հասկանալի է։',
  },
  {
    title: 'Ինչ՞ է իրենից ներկայացնում «Օրացույց» ծրագիրը։',
    description:
      'Ծրագիրն իրականացնում է ըստ օրերի ամենատարբեր միջոցառումների մուտքագրումը համակարգչի մեջ, ապահովելով  ավտոմատ հիշեցումներ։ Ցանկության դեպքում ձեր օրացույցում կարող եք ընդգրկել առաջարկվող մի քանի օրացույցներ',
  },

  {
    title: 'Որ՞քան արժե հարթակից օգտվելը:',
    description:
      'Այս տարբերակը անվճար է բոլորի համար, հետագայում՝ «Հաշվապահական հաշվառում» ծրագրի ամբողջական տարբերակի շահագործման դեպքում այն կլինի վճարովի։',
  },
  {
    caption: 'Տեխնիկական և անվտանգության հարցեր',
    title: 'Ին՞չ տեխնիկական միջոցներ են անհրաժեշտ հարթակից օգտվելու համար։',
    description:
      'Ցանկացած անձնական համակարգիչ՝ PC, WIN7 և բարձր օպերացիոն համակարգով կամ MAC և իհարկե ինտերնետ կապ, ցանկալի է նաև տպող սարք եթե օգտվելու եք «Հաշվապահական հաշվառում» ծրագրային համակարգից։',
  },
  {
    title: 'Որ՞տեղ են պահվելու իմ ընկերության տվյալները։',
    description:
      'Հարթակը հասանելի է ցանկացած վայրից,  տվյալները պահվում են անվտանգության բարձր մակարդակ ապահովող ամպային լավագույն համաշխարհային սերվերներում։',
  },
  {
    title:
      'Որ՞քանով է ապահովված իմ տվյալների անվտանգությունը և գաղտնիությունը։',
    description:
      'Տվյալների գաղտնիությունը ապահովված է Ձեր իսկ կողմից դրված գաղտնաբառով։ Տվյալներին հասանելիությունը անհնար է  առանց կոդավորված արտաքին բանալիի, որը հասանելի է միայն Ձեզ։',
  },
  {
    title: 'Տեղեկատվության ի՞նչ աղբյուրներից եք օգտվում։',
    description:
      'Օգտվում ենք գրեթե բոլոր այն աղբյուրներից՝ վեբ կայքերից, որոնցում  ներկայացված տեղեկատվությունը լիարժեք է իսկ կայքերն ունեն  բարձր վարկանիշ։ Օրինակ՝ ՀՀ Կենտրոնական բանկ, ՀՀ ՊԵԿ, rate.am, arlis.am, e-register.am, datalex.am, armstat.am, ictstat.am,e-request.am և այլն։',
  },
  {
    caption: 'Սպասարկման հարցեր',
    title: 'Սպասարկման ին՞չ որակ եք ապահովում։',
    description:
      'Ապահովում ենք սպասարկման բարձր որակ, որը պայմանավորված է ոչ միայն փորձառու մասնագետների ներկայությամբ այլ նաև օգտագործվող տեխնիկական արդի միջոցների՝ Զանգերի կենտրոնի (Call centre) օգտագործմամբ։',
  },
  {
    title: 'Ես նորեկ եմ բիզնեսում, Ձեր հարթակից օգտվելիս հարցեր շատ չե՞ն լինի։',
    description:
      'Հարթակը պարզ տեսք ունի, ամեն քայլում  հուշումներ կան, եթե այնուամենայնիվ հարցեր առաջանան, մեր բազմամյա փորձ ունեցող մասնագետները սիրով կպատասխանեն Ձեզ:',
  },
];
