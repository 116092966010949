import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { putRequest } from '../../App/Api/Api';
import CalendarForm from '../CalendarForm/CalendarForm';
import {
  editCalendarAction,
  updateDayNoteAction,
} from '../../App/Redux/calendarSlices/useAction';

const EventEditForm = ({ closeHandler, eventId, fetchCalendarData }) => {
  const dispatch = useDispatch();
  const handleClose = () => closeHandler(false);

  const onSubmit = useCallback(
    async (values) => {
      try {
        const res = await putRequest('editCalendarEvent', {
          ...values,
          delta: Number(values.delta),
        });
        const { errorCode, message, id } = res.data;
        if (errorCode === 0) {
          dispatch(updateDayNoteAction(values));
          dispatch(editCalendarAction(values));

          fetchCalendarData();
          handleClose();
        } else {
          console.error('Server error:', message);
        }
      } catch (err) {
        console.error('An error occurred:', err);
      }
    },
    [dispatch, handleClose],
  );

  return (
    <CalendarForm
      closeHandler={closeHandler}
      eventId={eventId}
      onSubmit={onSubmit}
    />
  );
};

export default EventEditForm;
