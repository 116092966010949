import { createAction } from '../../actionCreators';
import {
  FETCH_MATERIAL_VALUES_NAME,
  UPDATE_MATERIAL_VALUES_NAME,
  DELETE_MATERIAL_VALUES_NAME,
} from './constants';

export const fetchMaterialValNameAction = createAction(
  FETCH_MATERIAL_VALUES_NAME,
);
export const updateMaterialValNameAction = createAction(
  UPDATE_MATERIAL_VALUES_NAME,
);
export const deleteMaterialValNameAction = createAction(
  DELETE_MATERIAL_VALUES_NAME,
);
