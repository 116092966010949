import * as Yup from 'yup';
export const matchesChar = /[\p{L}-]+/gu;
export const matchesNumber = /^\d+$/;
export const emailRegex =
  /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
export const firstLetterString = /(?<=(\s|^))[a-z]/gi;
export const onlyCharAndNum = /[a-z0-9]/;
export const onlyChar = /^[\p{L}\p{M}\p{Zs}]{2,30}$/u;
export const onlyUppercase = `^[A-Z]+[A-Z]*[A-Z]$`;
export const stringOnly = /^[a-zA-Z]+$/g;
export const isNumber =
  /^[ ]*[+-]?((\d+[.]?\d*)|(\d*[.]?\d+))(e[+-]?\d+)?[ ]*$/i;

export const positiveDigits = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
export const regexUseStrNum = /^(?=.*\p{L})(?=.*\p{N}?)[\p{L}\p{N}\p{Z}-]*$/u;

export const positiveNumberValidation = Yup.number()
  .typeError('Պետք է լինի թիվ')
  .positive('Պետք է լինի դրական թիվ')
  .integer('Պետք է լինի ամբողջ թիվ');

export const curNum = Yup.string()
  .matches(/[0-9]/, 'Պետք է լինի թիվ')
  .length(3, 'Պարտադիր 3 նիշ');

export const textValidation = Yup.string().matches(
  /^[\p{L}\p{Z}-]+$/gu,
  'Պետք է պարունակի միայն տառեր',
);

export const stringInclude = /\p{L}/gu;
