export const searchFields = [
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Ստորաբաժանումը',
    required: false,
    fieldName: 'depNo',
    optionName: 'workDeps',
    filteredName: 'depName',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.depName,
        id: i.depNo,
        value: i.depName,
      }));
    },
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'name',
    label: 'Ազգանուն Անուն Հայրանուն',
    value: '',
    required: false,
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'Պայմանագիր',
    fieldName: 'rentNo',
    optionName: 'rents',
    filteredName: 'rentName',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.rentName,
        id: i.rentNo,
        value: i.rentName,
      }));
    },
  },
  {
    fieldType: 'checkbox',
    name: 'fireSign',
    label: 'Ցույց տալ նաև ազատվածներին',
    value: '',
  },
];
