import React, { useEffect } from 'react';
import WorkCalendarTable from './WorkCalendarTable';
import './index.scss';
import Modal from '../../../App/Components/Modal/Modal';
import useWorkCalendar from '../../hooks/useWorkCalendar';
import TableInfo from './TableInfo';
import Button from '../../../App/Components/Button/Button';

const WorkCalendar = ({ graphNo, handleClose }) => {
  const { getWorkCalendar, updateWorkCalendar, workCalendarData } =
    useWorkCalendar(graphNo);
  const { tabelName, tabelYear } = workCalendarData || {};

  useEffect(() => {
    getWorkCalendar();
  }, []);

  const onUpdateHandler = async () => {
    try {
      await updateWorkCalendar();
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="work-calendar-wrap"
    >
      <div className="G-flex-justify-between">
        <TableInfo tabelName={tabelName} tabelYear={tabelYear} />
        <Button onClick={onUpdateHandler} text="Թարմացնել" />
      </div>
      <WorkCalendarTable
        mainData={workCalendarData?.workCal}
        graphNo={graphNo}
        handleClose={handleClose}
      />
    </Modal>
  );
};

export default WorkCalendar;
