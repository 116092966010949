import React from "react";
import InitialFinalBalanceTitle from "../../Components/InitialFinalBalanceTitle/InitialFinalBalanceTitle";
import { TotalList } from "../../Components/TotalList/TotalList";
import { Commas, getTotalCount } from "../../Utilities/Utilities";
import Table from "../../Components/Table/Table";

const PartnerViewAccountTable = ({ mainData }) => {
  const columnConfig = [
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountCode,
      customStyle: { width: 100 },
    },
    {
      title: 'Գործընկերոջ անվանումը',
      cell: (row) => row.partnerName,
      customStyle: { width: 400, overflow: 'hidden' },
    },
    {
      title: 'Դեբետային',
      cell: (row) => Commas(row.initialDebit),
      customStyle: { width: 140 },
    },
    {
      title: 'Կրեդիտային',
      cell: (row) => Commas(row.initialCredit),
      customStyle: { width: 140 },
    },
    {
      title: 'Դեբետային',
      cell: (row) => Commas(row.calculationDebit),
      customStyle: { width: 140 },
    },
    {
      title: 'Կրեդիտային',
      cell: (row) => Commas(row.calculationCredit),
      customStyle: { width: 140 },
    },
    {
      title: 'Դեբետային',
      cell: (row) => Commas(row.finalDebit),
      customStyle: { width: 140 },
    },
    {
      title: 'Կրեդիտային',
      cell: (row) => Commas(row.finalCredit),
      customStyle: { width: 140 },
    },
  ];

  const initialDebitTotal = getTotalCount(mainData, "initialDebit"),
    initialCreditTotal = getTotalCount(mainData, "initialCredit"),
    calculationDebitTotal = getTotalCount(mainData, "calculationDebit"),
    calculationCreditTotal = getTotalCount(mainData, "calculationCredit"),
    finalDebitTotal = getTotalCount(mainData, "finalDebit"),
    finalCreditTotal = getTotalCount(mainData, "finalCredit");

  const totalList = [
    Commas(initialDebitTotal),
    Commas(initialCreditTotal),
    Commas(calculationDebitTotal),
    Commas(calculationCreditTotal),
    Commas(finalDebitTotal),
    Commas(finalCreditTotal),
  ];

  return (
    <>
      <InitialFinalBalanceTitle />
      {mainData !== null && mainData && mainData?.length && (
        <Table
          customClass="G-table-has-scroll L-partner-view-account-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      )}
      <TotalList arr={totalList} />
    </>
  );
};

export default PartnerViewAccountTable;
