import React from 'react';
import Table from '../../../App/Components/Table/Table';
import { Commas } from '../../../App/Utilities/Utilities';
import Loading from '../../../App/Components/Loading/Loading';

const CalculationSheetsTable = ({ salaryTotals, isLoading }) => {
  const columnConfig = [
    {
      title: 'Հ/Պ',
      cell: (row) => row.stNo,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Հ/Պ անվանումը',
      cell: (row) => row.stName,
      customStyle: { maxWidth: 600, overflow: 'hidden' },
    },

    {
      title: 'Հաշվարկվել է',
      cell: (row) => Commas(row.addAmount),
      customStyle: { maxWidth: 170 },
    },
    {
      title: 'Պահվել է',
      cell: (row) => Commas(row.dedAmount),
      customStyle: { maxWidth: 170 },
    },
  ];

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {salaryTotals !== null && salaryTotals?.length ? (
        <Table
          customClass="L-calculation-sheets-table G-table-has-scroll"
          data={salaryTotals}
          columnConfig={columnConfig}
        />
      ) : null}
    </>
  );
};

export default CalculationSheetsTable;
