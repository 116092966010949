import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MAIN_URL } from '../../Utilities/Utilities';
import Modal from '../../Components/Modal/Modal';
import PurposeProvidingMoney from './PurposeProvidingMoney';
import SpendingMoneyDealsInfo from './SpendingMoneyDealsInfo';
import Button from '../../Components/Button/Button';
import SpendingMoneyDealsTable from './SpendingMoneyDealsTable';
import EventAddForm from './EventAddForm';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';

const SpendingMoneyWrapper = (props) => {
  const { spendingDealsData } = useSelector(
    (state) => state.getSpendingMoneyData,
  );
  const {
    closeModal,
    onSubmitData,
    setSubmitted,
    disabledSaveDocBtn,
    getCashMoneyId,
    showInput = false,
  } = props;
  const { cashMoney } = spendingDealsData || {};
  const [openSpendingForm, setOpenSpendingForm] = useState(false);
  const { isClosed, total, inputs, expense, initBalance } = cashMoney || {};
  const disabledAddBtn = !spendingDealsData?.cashMoney?.basis?.length;
  const initInputs = initBalance + total - inputs - expense;
  const openingSpendingForm = () => {
    setOpenSpendingForm(true);
  };

  const printHandler = () => {
    const { partnerID, cashMoneyID } = cashMoney || {};
    window.open(
      `${MAIN_URL}/printCashMoney?partnerID=${partnerID}&cashMoneyID=${cashMoneyID}`,
    );
  };

  return (
    <Modal closeHandler={closeModal} customClass="L-spending-money-section">
      <div className="L-spending-money-section-content">
        <PurposeProvidingMoney mainData={spendingDealsData?.cashMoney} />
        <SpendingMoneyDealsInfo
          mainData={spendingDealsData}
          initInputs={showInput ? initInputs : null}
        />
        <div className="L-spending-money-add-operations-flex">
          <div className="L-spending-money-btn">
            <Button
              onClick={openingSpendingForm}
              disabled={disabledAddBtn}
              customClass={disabledAddBtn ? 'G-disabled-button' : ''}
            />
          </div>
        </div>
        <SpendingMoneyDealsTable
          mainData={spendingDealsData}
          setSubmitted={setSubmitted}
          getCashMoneyId={getCashMoneyId}
          disabledCloseDoc={isClosed}
        />

        <div className="L-spending-money-add-operations-flex">
          <div className="L-spending-money-btn">
            <Button text="Տպել" onClick={printHandler} />
          </div>

          <GetSentDataBtn
            cancelPrintClickText="Ընդհատել"
            confirmExecuteText="Գրանցել փաստաթուղթը"
            confirmExecuteCustomClass="G-register-document-btn"
            cancelPrintClick={closeModal}
            confirmExecuteClick={onSubmitData}
            executeClass={
              disabledSaveDocBtn || disabledAddBtn ? 'G-disabled-button' : ''
            }
            executeDisabled={disabledSaveDocBtn || disabledAddBtn}
          />
        </div>
      </div>

      {openSpendingForm && (
        <EventAddForm
          closeModal={setOpenSpendingForm}
          setSubmitted={setSubmitted}
        />
      )}
    </Modal>
  );
};

export default SpendingMoneyWrapper;
