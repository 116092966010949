import React, { useState } from 'react';
import Action from '../../Components/Table/Action';
import { checkBeforeDelete, tableActionTitle } from '../../Utilities/Utilities';
import Tooltip from '../../Components/Tooltip/Tooltip';
import Checkbox from '../../Components/Checkbox/Checkbox';
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';
import EventAddForm from './EventAddForm';
import EventEditForm from './EventEditForm';
import DeleteChartOfAccount from './DeleteChartOfAccount';

const ChartOfAccountTable = ({
  mainData,
  loading,
  getData,
  pageNumber,
  searchValue,
  errorHandler,
  setErrorHandler,
}) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handelChange = (value, row) => {
    switch (value) {
      case 'add':
        return openAddForm(row);
      case 'edit':
        return openEditForm(row);
      case 'delete':
        return openDeleteForm(row?.accountID);
      default:
        return null;
    }
  };

  const actionHandler = (row) => {
    const { accountCode } = row || {};

    const actionList = [];
    if (accountCode?.length >= 4 && !accountCode.includes('-')) {
      actionList.push(
        {
          name: 'Ավելացնել',
          value: 'add',
        },
        {
          name: 'Խմբագրել',
          value: 'edit',
        },

        {
          name: 'Ջնջել',
          value: 'delete',
        },
      );
    } else if (accountCode?.length >= 3 && !accountCode.includes('-')) {
      actionList.push(
        {
          name: 'Ավելացնել',
          value: 'add',
        },

        {
          name: 'Խմբագրել',
          value: 'edit',
        },
      );
    }

    return actionList;
  };

  const columnConfig = [
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountCode,
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Հաշվի անվանում',
      cell: (row) => row.accountName,
      customStyle: { maxWidth: 1000, overflow: 'hidden' },
    },
    {
      title: 'Սինթետիկ',
      cell: (row) => row.parent,
      customStyle: { maxWidth: 80 },
    },

    {
      title: 'Անալիտիկ',
      cell: (row) => <Checkbox defaultChecked={row.leaf} disabled={true} />,
      customStyle: { maxWidth: 70, textAlign: 'center' },
    },
    {
      title: 'Տեսակը',
      cell: (row) => row.typeName,
      customStyle: { maxWidth: 110 },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => (
        <Checkbox checked={row.ident} value={row.ident} disabled={true} />
      ),
      customStyle: { maxWidth: 85, textAlign: 'center' },
    },
    {
      title: <Tooltip content="Արտահաշվեկշռային հաշիվ">Արտ/Հ</Tooltip>,
      cell: (row) => (
        <Checkbox checked={row.balance} value={row.balance} disabled={true} />
      ),
      customStyle: { maxWidth: 60, textAlign: 'center' },
    },
    {
      title: 'Արժույթ',
      cell: (row) => row.currency,
      customStyle: { maxWidth: 150, overflow: 'hidden' },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          mainAction={''}
          mainActionName={''}
          handelChange={handelChange}
          actionItem={actionHandler(row)}
          modalIsOpen={
            openAddModal || openEditModal || openDeleteModal || errorHandler
          }
        />
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  const openAddForm = (row) => {
    setOpenAddModal(row);
  };

  const openEditForm = (row) => {
    setOpenEditModal(row);
  };

  const openDeleteForm = (accountID) => {
    checkBeforeDelete(`letDeleteAccount?accountID=${accountID}`).then((res) => {
      if (res.errorCode === 0) {
        setOpenDeleteModal(accountID);
      }
      if (res.errorCode > 0) {
        setErrorHandler(res.message);
      }
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-chart-of-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {openAddModal && (
        <EventAddForm
          modalClick={setOpenAddModal}
          rowData={openAddModal}
          getData={getData}
          pageNumber={pageNumber}
          searchValue={searchValue}
          setErrorHandler={setErrorHandler}
        />
      )}

      {openEditModal && (
        <EventEditForm
          closeModal={setOpenEditModal}
          rowData={openEditModal}
          setErrorHandler={setErrorHandler}
        />
      )}
      {openDeleteModal && (
        <DeleteChartOfAccount
          closeModal={setOpenDeleteModal}
          deleteRowId={openDeleteModal}
          setErrorHandler={setErrorHandler}
        />
      )}
    </>
  );
};

export default ChartOfAccountTable;
