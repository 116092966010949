import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import MaterialGroupsTable from './MaterialGroupsTable';
import MaterialGroupsFilter from './MaterialGroupsFilter';
import './style.scss';
import EventAddForm from './EventAddForm';
import useMaterialValueGroups from '../../hooks/useMaterialValueGroups';
import { ConvertMaterialGroupExcel } from '../../Config/excelConfig';
const MaterialValueGroups = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [param, setParam] = useState('');
  const { getMaterialValueGroups, materialValuesGroup, isLoading } =
    useMaterialValueGroups();
  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };
  useEffect(() => {
    getMaterialValueGroups(param);
  }, [param]);
  const dataToExcel = ConvertMaterialGroupExcel(
    materialValuesGroup?.prodGroups,
  );

  return (
    <div className="G-small-wrap">
      <Header pageTitle="Նյութական արժեքների խմբեր" handelClick={onClose} />
      <MaterialGroupsFilter
        param={param}
        setParam={setParam}
        dataToExcel={dataToExcel}
      />
      <MaterialGroupsTable
        materialGroupData={materialValuesGroup?.prodGroups}
        isLoading={isLoading}
      />
      {showAddForm && <EventAddForm handleClose={onClose} param={param} />}
    </div>
  );
};

export default MaterialValueGroups;
