import React from 'react';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';

const OnHoldSavingList = ({ data, values, setFieldValue, onBlur }) => {
  const onChange = (item, isChecked) => {
    let updatedAdditions;
    if (isChecked) {
      updatedAdditions = [
        ...(values.additions || []),
        { stNo: item.stNo, stName: item.stName },
      ];
    } else {
      updatedAdditions = (values.additions || []).filter(
        (addedItem) => addedItem.stNo !== item.stNo,
      );
    }

    setFieldValue('additions', updatedAdditions);
  };

  return (
    <div className="OnHoldSavingList">
      {data?.map((item) => (
        <Checkbox
          key={item.stNo}
          name={item.stName}
          label={item.stName}
          checked={(values.additions || []).some(
            (addedItem) => addedItem.stNo === item.stNo,
          )}
          onChange={(e) => onChange(item, e.target.checked)}
          onBlur={onBlur}
        />
      ))}
    </div>
  );
};

export default OnHoldSavingList;
