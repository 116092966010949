import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';

export const fetchTables = async (companyId) => {
  try {
    const response = await getRequest(`getGraphs?companyID=${companyId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addTables = async (newTables) => {
  try {
    const response = await postRequest('addGraph', {
      ...newTables,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding Tables:', error);
    throw error;
  }
};

export const updateTables = async (newTables) => {
  try {
    const response = await putRequest(`editGraph`, newTables);
    return response.data;
  } catch (error) {
    console.error('Error updating Tables:', error);
    throw error;
  }
};

export const deleteTables = async (calendarID) => {
  try {
    const response = await deleteRequest(`deleteGraph/${calendarID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting Tables:', error);
    throw error;
  }
};

export const fetchTablesForm = async (currentCompanyID, calendarID) => {
  if (!currentCompanyID) {
    return;
  }
  try {
    const response = await getRequest(
      `getGraph?companyID=${currentCompanyID}&calendarID=${calendarID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
