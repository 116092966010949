import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import {
  getFinalBalance,
  getTotalExpenditure,
} from '../../Utilities/Utilities';
import SpendingMoneyWrapper from './SpendingMoneyWrapper';
import { updateAccountMoneyFormAction } from '../../Redux/accountForSpendingMoneySlices/useAction';

const EditSendingMoneyWrapper = ({ closeModal, getCashMoneyId }) => {
  const handleClose = () => closeModal(false);
  const [errorHandler, setErrorHandler] = useState('');
  const { spendingDealsData } = useSelector(
    (state) => state.getSpendingMoneyData,
  );
  const { cashMoney } = useMemo(
    () => spendingDealsData || {},
    [spendingDealsData],
  );
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const currentData = useMemo(() => {
    return {
      cashMoney: {
        ...cashMoney,
        expense: getTotalExpenditure(spendingDealsData?.expenses),
        finalBalance: getFinalBalance(cashMoney, spendingDealsData?.expenses),
      },
    };
  }, [cashMoney, spendingDealsData?.expenses]);

  const editSendingMoneyData = useCallback(() => {
    putRequest('editCashMoney', {
      ...spendingDealsData,
      currentData,
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          setSubmitted(false);
          setErrorHandler('Փաստաթուղթը գրանցվեց');
          setTimeout(() => {
            handleClose();
          }, 1000);
          dispatch(updateAccountMoneyFormAction({ ...currentData?.cashMoney }));
        }
        if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  }, [currentData, dispatch, spendingDealsData]);

  return (
    <>
      <SpendingMoneyWrapper
        closeModal={handleClose}
        onSubmitData={editSendingMoneyData}
        setSubmitted={setSubmitted}
        submitted={submitted}
        getCashMoneyId={getCashMoneyId}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EditSendingMoneyWrapper;
