export const getDisplayData = (salaryCalcData, salaryTitle = 1) => {
  const { calculatedSalary, incomeTax, socialFee, stampFee } =
    salaryCalcData || {};

  return [
    {
      title: salaryTitle === 1 ? 'Մաքուր աշխատավարձ' : 'Հաշվարկվող աշխատավարձ',
      amount: calculatedSalary || 0,
    },
    {
      title: 'Եկամտային հարկ',
      amount: incomeTax || 0,
    },
    {
      title: 'Սոցիալական վճար',
      amount: socialFee || 0,
    },
    {
      title: 'Դրոշմանիշային վճար',
      amount: stampFee || 0,
    },
  ];
};
