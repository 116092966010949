import React, { useEffect, useState } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import { useFormik } from 'formik';
import OtherData from './OtherData';
import CalculationData from './CalculationData';
import PersonalCard from './PersonalCard';
import TabButtons from './TabButtons';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import { useSelector } from 'react-redux';
import useEmployeesForm from '../../hooks/useEmployeesForm';
import { EmployeeValidation } from '../../../App/Validation/EmployeeValidation';
import useIsMounted from '../../hooks/useIsMounted';

const EmployeesForm = ({
  handleClose,
  onSubmit,
  employeeID = 0,
  title = null,
}) => {
  const [activeTab, setActiveTab] = useState('PersonalCard');
  const [publicServiceData, setPublicServiceData] = useState({
    showForm: false,
    removePublicServices: false,
  });
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { employeesFormData, getEmployeesFormData, getPublicServiceNumber } =
    useEmployeesForm();
  const isMounted = useIsMounted();

  useEffect(() => {
    getEmployeesFormData(currentCompanyID, employeeID);
  }, [currentCompanyID, employeeID]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
    setFieldValue,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...employeesFormData?.employee,
    },
    validationSchema: EmployeeValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
      if (isMounted) {
        resetForm();
      }
    },
  });
  const generateTabComponent = (Component, props) => <Component {...props} />;
  const commonProps = {
    employeesFormData,
    getPublicServiceNumber,
    publicServiceData,
    setPublicServiceData,
    values,
    onChange: handleChange,
    setFieldValue,
    errors,
    touched,
    handleBlur,
  };

  const tabComponents = {
    PersonalCard: generateTabComponent(PersonalCard, commonProps),
    CalculationData: generateTabComponent(CalculationData, commonProps),
    OtherData: generateTabComponent(OtherData, commonProps),
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="employees-form-block"
      title={title}
    >
      <TabButtons activeTab={activeTab} handleTabChange={handleTabChange} />

      <div className="tab-content">{tabComponents[activeTab]}</div>
      <GetSentDataBtn
        customClassForBlock="G-salary-btn-block"
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleSubmit}
        executeClass={
          !dirty || !isValid || publicServiceData?.removePublicServices
            ? 'G-disabled-button'
            : ''
        }
        executeDisabled={
          !dirty || !isValid || publicServiceData?.removePublicServices
        }
      />
    </Modal>
  );
};

export default EmployeesForm;
