import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { putRequest } from '../../Api/Api';
import Modal from '../../Components/Modal/Modal';
import InputFiled from '../../Components/InputFiled/InputFiled';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import { register } from '../../Utilities/Utilities';
import Button from '../../Components/Button/Button';
import { useFetch } from '../../Hooks/useFetch';
import { matchesChar } from '../../Validation/REG_EXP';
import { EnterTextOnly } from '../../Validation/ErrorMessage';
import { updateUser } from '../../Redux/authSlices/useAction';

const UserInfo = ({ closeModal }) => {
  const handleClose = () => closeModal(false);
  const { userID } = useSelector((state) => state.auth);
  const [userData] = useFetch(`getUser/${userID}`);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...userData,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .matches(matchesChar, EnterTextOnly)
        .required()
        .min(1, 'Մինիմում 3 նիշ')
        .required()
        .max(15, 'Մաքսիմում 15 նիշ')
        .required()
        .required('Այս դաշտն անհրաժեշտ է լրացնել'),
      lastName: Yup.string()
        .matches(matchesChar, EnterTextOnly)
        .required()
        .min(1, 'Մինիմում 1 նիշ')
        .max(25, 'Մաքսիմում 25 նիշ')
        .required()
        .required('Այս դաշտն անհրաժեշտ է լրացնել'),
    }),
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const onSubmit = (values) => {
    putRequest(`editUser`, values).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(
          updateUser({
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
          }),
        );
        handleClose();
      }
    });
  };

  return (
    <Modal customClass="L-change-password-wrapper" closeHandler={handleClose}>
      <form onSubmit={onSubmit}>
        <div className="L-change-password-input">
          <InputFiled
            inputTitle="Անուն"
            name="firstName"
            value={values.firstName}
            changeHandler={handleChange}
            onBlur={handleBlur}
          />
          {touched.firstName && errors.firstName && (
            <ErrorFiled error={errors.firstName} />
          )}
        </div>
        <div className="L-change-password-input">
          <InputFiled
            inputTitle="Ազգանուն"
            name="lastName"
            value={values.lastName}
            changeHandler={handleChange}
            onBlur={handleBlur}
          />
          {touched.lastName && errors.lastName && (
            <ErrorFiled error={errors.lastName} />
          )}
        </div>
        <div className="L-change-password-input">
          <InputFiled
            inputTitle="Հեռախոսահամար"
            name="phone"
            value={values.phone}
            changeHandler={handleChange}
          />
        </div>
        <div className="G-flex-justify-end">
          <div className="G-register-btn">
            <Button
              text={register}
              customClass={!(isValid && dirty) ? 'G-disabled-button' : ''}
              disabled={!(isValid && dirty)}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default UserInfo;
