import React from 'react';

const IsAddBank = ({ confirmBank, closeHandler }) => {
  const handleClose = () => closeHandler(false);
  return (
    <div className="L-is-bank-block">
      <h3>Ավելացնել բանկ</h3>
      <div className="G-flex" style={{ marginTop: '10px' }}>
        <span onClick={confirmBank}> Այո</span>
        <span onClick={handleClose}> Ոչ</span>
      </div>
    </div>
  );
};

export default IsAddBank;
