import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Action from '../../../App/Components/Table/Action';
import { tableActionTitle } from '../../../App/Utilities/Utilities';
import { globalActionItems } from '../../Config/tableConfig';
import Forms from './Forms';
import Loading from '../../../App/Components/Loading/Loading';
const MaterialNameTable = ({ materialValuesName, isLoading }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'ՆԱ խումբը',
      cell: (row) => row.groupName,
      customStyle: { maxWidth: 350, overflow: 'hidden' },
    },
    {
      title: 'Կոդը',
      cell: (row) => row.matValueNo,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'ՆԱ անվանումը',
      cell: (row) => row.matValueName,
      customStyle: { maxWidth: 300, overflow: 'hidden' },
    },
    {
      title: 'Չ/միավոր',
      cell: (row) => row.unit,
      customStyle: { maxWidth: 120, overflow: 'hidden' },
    },

    {
      title: 'Բնութագիր',
      cell: (row) => row.description,
      customStyle: { maxWidth: 280, overflow: 'hidden' },
    },
    {
      title: 'ԱՏԳԱԱ',
      cell: (row) => row.feaName,
      customStyle: { maxWidth: 650, overflow: 'hidden' },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(value, row) =>
            setShowForm({
              type: value,
              value: row,
            })
          }
          actionItem={globalActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {materialValuesName !== null &&
      materialValuesName &&
      materialValuesName?.length ? (
        <Table
          customClass=""
          data={materialValuesName}
          columnConfig={columnConfig}
        />
      ) : null}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default MaterialNameTable;
