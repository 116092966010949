import WhoAreWeImg from '../../../Assets/image/WhoAreWe.png';
import QuestionImg from '../../../Assets/image/QuestionsAndAnswer.png';
import LoginIcon from '../../../Assets/image/loginIcon.png';
import ContactImg from '../../../Assets/image/ContactUs.png';

export const tabItems = [
  {
    tabImg: WhoAreWeImg,
    tabName: 'WhoAreWe',

    title: 'Մեր հարթակը',
  },
  {
    tabImg: QuestionImg,
    tabName: 'Questions',
    title: 'Հաճախ տրվող հարցեր',
  },

  {
    tabImg: ContactImg,
    tabName: 'ContactUs',

    title: 'Կապ մեզ հետ',
  },
  {
    tabImg: LoginIcon,
    tabName: 'Login',

    title: 'Մուտք',
  },
];
