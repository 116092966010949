import React from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { ReactComponent as LogOutIcon } from '../../Assets/icons/logOut.svg';
import { authClear } from '../../Redux/authSlices/useAction';
import { windowReload } from '../../Utilities/Utilities';
const AuthSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const list = [
    // {
    //   name: 'Ընտրել նկարը',
    //   path: 'SelectUserPicture',
    //   icon: '',
    // },
    // {
    //   name: 'Փոխել գաղտնաբառը',
    //   path: 'ChangePassword',
    //   icon: '',
    // },
    {
      name: 'Օգտվողի տվյալները',
      path: 'userData',
    },
  ];

  const clearUser = () => {
    dispatch(authClear());
    localStorage.removeItem('persist:root');
    history.push('/OneWindow');
    windowReload();
  };

  return (
    <div className="G-user-setting-list">
      <ul>
        {list?.map(({ name, path, icon }) => {
          return (
            <li key={path}>
              <Link to={path}>
                <span className="G-user-setting-icon">{icon}</span>
                <span>{name}</span>
              </Link>
            </li>
          );
        })}
        <li onClick={clearUser}>
          <span className="G-user-setting-icon">
            <LogOutIcon />
          </span>
          <span>Դուրս գալ</span>
        </li>
      </ul>
    </div>
  );
};

export default AuthSettings;
