import React from 'react';
import PropTypes from 'prop-types';

import { entryOrderFormationFields } from './FieldsConfig';
import {
  dataTimeFormat,
  dateFormat,
} from '../../../../App/Utilities/Utilities';
import Input from '../../../components/Input/Input';
import InputTitle from '../../../components/Input/InputTitle';
import { SelectControl } from '../../../../App/Components/SelectControl/SelectControl';
import DateComponent from '../../../components/dateComponent/DateComponent';
import ErrorFiled from '../../../../App/Components/ErrorFiled/ErrorFiled';
import RadioButton from '../../../../App/Components/RadioButton/RadioButton';

const EntryOrderFormationDetale = ({
  handleChange,
  touched,
  setFieldValue,
  values,
  handleBlur,
  errors,
  entryOrderFormation,
  getWarehouseEntryDocNum,
}) => {
  const updateDocNum = async (storeNo, date) => {
    const year = new Date(date).getFullYear();
    const docNum = await getWarehouseEntryDocNum({ storeNo, year });
    setFieldValue('docNum', docNum);
  };

  // Handle dropdown change
  const onDropdownChange = async (selectedOption, dropdownId, fieldName) => {
    const { value = '', id = '' } = selectedOption || {};
    setFieldValue(fieldName, value);
    setFieldValue(dropdownId, id);

    if (fieldName === 'storeName') {
      await updateDocNum(id, values.docDate);
    }
  };

  // Handle date change
  const onChangeDate = async (date, name) => {
    const formattedDate = dataTimeFormat(date);
    setFieldValue(name, formattedDate);

    await updateDocNum(values.storeNo, date);
  };

  // Render individual field based on its type
  const renderField = (field, index) => {
    const {
      fieldType,
      fieldName,
      label,
      name,
      required,
      Children,
      optionName,
      dropdownTitle,
      type,
      dropdownId,
      disabled,
      radioTitle,
      requiredRadioBtn,
      maxLength,
      title,
      fieldValue,
      dateName,
      dateTitle,
    } = field;

    const uniqueKey = fieldName || name || `field-${index}`;
    const fieldError = touched?.[name] && errors?.[name];

    const isRequired =
      typeof required === 'function' ? required(values) : required;
    const isDisabled =
      typeof disabled === 'function' ? disabled(values) : disabled;

    const dropDownOptions =
      Children && entryOrderFormation?.[optionName]
        ? Children(entryOrderFormation[optionName])
        : [];

    switch (fieldType) {
      case 'input':
        return (
          <div key={uniqueKey} className="G-entry-order-formation-wrap">
            <Input
              type={type}
              inputTitle={label}
              name={name}
              requiredField={required}
              onChange={handleChange}
              value={values[name]}
              onBlur={handleBlur}
              disabled={isDisabled}
              maxLength={maxLength}
            />
          </div>
        );

      case 'fieldNameAndValue':
        return (
          <div
            key={uniqueKey}
            className="G-entry-order-formation-wrap L-field-name-and-value"
          >
            <InputTitle inputTitle={title} />
            <h3>{values[fieldValue]}</h3>
          </div>
        );

      case 'radio':
        return (
          <div key={uniqueKey} className="G-entry-order-formation-wrap">
            <InputTitle
              requiredField={requiredRadioBtn}
              inputTitle={radioTitle}
            />
            <RadioButton
              name={name}
              value={values[name]}
              checked={values[name]}
              label={label}
            />
          </div>
        );

      case 'date':
        return (
          <div key={uniqueKey} className="G-entry-order-formation-wrap">
            <DateComponent
              value={dateFormat(values?.[dateName] || '')}
              onChange={(date) => onChangeDate(date, dateName)}
              name={dateName}
              title={dateTitle}
              requiredFiled={required}
              openToDate={new Date(values?.[dateName] || new Date())}
            />
          </div>
        );

      case 'dropdown':
        return (
          <div
            key={uniqueKey}
            className="G-entry-order-formation-wrap G-dropdown-flex-block"
          >
            <SelectControl
              selectBoxTitle={dropdownTitle}
              requiredFiled={isRequired}
              value={{ label: values?.[fieldName], value: values?.[fieldName] }}
              options={dropDownOptions || []}
              onChange={(selectedOption) =>
                onDropdownChange(selectedOption, dropdownId, fieldName)
              }
              disabled={!isRequired}
            />
            {fieldError && <ErrorFiled error={fieldError} />}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="L-entry-order-formation-form">
      {entryOrderFormationFields?.map(renderField)}
    </div>
  );
};

EntryOrderFormationDetale.propTypes = {
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.object,
  entryOrderFormation: PropTypes.object,
};

export default EntryOrderFormationDetale;
