import {
  DELETE_CASH_OUT_FLOW,
  EDIT_CAS_OUT_FLOW_DATA,
  GET_CASH_OUT_FLOW_DATA,
  LOADING,
  UPDATE_PARTNER_CASH_OUT,
} from "./constants";

const initialState = {
  cashOutflowData: null,
  isLoading: false,
};

export const cashOutflowReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CASH_OUT_FLOW_DATA:
      return {
        ...state,
        cashOutflowData: payload,
        isLoading: true,
      };

    case UPDATE_PARTNER_CASH_OUT:
      return {
        ...state,
        cashOutflowData: {
          ...state.cashOutflowData,
          partnerOuts: [...state.cashOutflowData.partnerOuts, ...payload],
        },
      };

    case DELETE_CASH_OUT_FLOW:
      return {
        ...state,
        cashOutflowData: {
          ...state.cashOutflowData,
          cashOuts: state.cashOutflowData?.cashOuts?.filter(
            (item) => item.documentID !== payload,
          ),
        },
      };
    case EDIT_CAS_OUT_FLOW_DATA:
      return {
        ...state,
        cashOutflowData: {
          ...state.cashOutflowData,
          cashOuts: state.cashOutflowData?.cashOuts?.map((cash) => {
            return cash.documentID === payload.documentID ? payload : cash;
          }),
        },
      };
    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return { ...state };
  }
};
