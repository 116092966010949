import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { postRequest } from '../../Api/Api';
import Alert from '../../Components/Alert';
import CurrenciesForm from './CurrenciesForm';

const EventAddForm = ({ getData, pageNumber, closeModal }) => {
  const [errorHandler, setErrorHandler] = useState('');
  const handleClose = () => closeModal(false);
  const { currentCompanyID } = useSelector((state) => state.auth);
  const initialValue = {
    companyID: currentCompanyID,
    currencyCode: '',
    currencyName: '',
  };

  const onSubmit = (values, resetForm) => {
    postRequest('addCurrency', values)
      .then((res) => {
        if (res.data.errorCode === 0) {
          getData(pageNumber);
          resetForm();
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <CurrenciesForm
        onSubmit={onSubmit}
        initialData={initialValue}
        closeModal={handleClose}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventAddForm;
