import React, { useState } from 'react';
import { dateFormat, tableActionTitle } from '../../../App/Utilities/Utilities';
import Table from '../../../App/Components/Table/Table';
import Action from '../../../App/Components/Table/Action';
import { globalActionItems } from '../../Config/tableConfig';
import Forms from './Forms';

const NonWorkingDaysTable = ({ mainData }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.nwDay),
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.nwTypeName,
      customStyle: { maxWidth: 500 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(value, row) =>
            setShowForm({
              type: value,
              value: row?.nwDayID,
            })
          }
          actionItem={globalActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 140 },
    },
  ];

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table customClass="" data={mainData} columnConfig={columnConfig} />
      ) : null}
      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default NonWorkingDaysTable;
