import React from "react";
import { useFormik } from "formik";
import ErrorFiled from "../../../Components/ErrorFiled/ErrorFiled";
import GetSentDataBtn from "../../../Components/GetSentDataBtn/GetSentDataBtn";
import InputFiled from "../../../Components/InputFiled/InputFiled";
import Modal from "../../../Components/Modal/Modal";
import { SelectControl } from '../../../Components/SelectControl/SelectControl';
import {
  BankFormValidation,
  myPartnerBakValidation,
} from '../../../Validation/BankValidation';
import RadioButton from '../../../Components/RadioButton/RadioButton';
import { textFiledLength } from '../../../Utilities/Utilities';

const PartnerBankForm = ({
  closeModal,
  initialData,
  onSubmit,
  partnerBankList,
}) => {
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isSubmitting,
    handleBlur,
    setFieldValue,
    dirty,
    isValid,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...initialData },
    validationSchema: myPartnerBakValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });
  const bankOptions =
    partnerBankList &&
    partnerBankList?.map(({ bankCode, bankName, bankID }) => {
      return {
        label: bankName,
        id: bankID,
        value: bankCode,
      };
    });

  const changeBankHandler = (values) => {
    setFieldValue('bankCode', values.value);
    setFieldValue('bankName', values.label);
    setFieldValue('bankID', values.id);
    setFieldValue('bankingAccount', values.value);
  };
  let defaultValue = values?.bankingAccount;

  const bankingAccountHandler = (e) => {
    if (values.bankType === 1) {
      handleChange(e);
    }
    if (e.target.value.indexOf(defaultValue) === 0) {
      handleChange(e);
    }

    if (e.target.value.length >= 5) {
      handleChange(e);
    }
  };

  return (
    <Modal
      closeHandler={closeModal}
      customClass="G-form-modal L-partner-bank-form"
    >
      <div className="G-form-content">
        <div className="G-modal-block-title">
          <h3>Ավելացնել բանկ </h3>
        </div>
        <form onSubmit={onSubmit}>
          <div className="L-partner-bank-type">
            <RadioButton
              value={values.bankType}
              checked={values.bankType === 0}
              id="bankType"
              name="bankType"
              label="ՀՀ բանկեր"
              onChange={() => setFieldValue('bankType', 0)}
            />
            <RadioButton
              value={values.bankType}
              checked={values.bankType === 1}
              id="bankType"
              name="bankType"
              label="Արտերկրի բանկեր"
              onChange={() => setFieldValue('bankType', 1)}
            />
          </div>

          {values.bankType === 0 ? (
            <div className="G-form-select-wrapper">
              <SelectControl
                matchFromParam=""
                requiredFiled={true}
                selectBoxTitle="Բանկ"
                options={bankOptions}
                value={{
                  label: values.bankName,
                  value: values.bankName,
                }}
                onChange={changeBankHandler}
                onBlur={handleBlur}
              />
              {touched.bankName && errors.bankName && (
                <ErrorFiled error={errors.bankName} />
              )}
            </div>
          ) : (
            <div className="G-form-input-wrapper">
              <InputFiled
                requiredFiled={true}
                inputTitle="Բանկի անվանումը"
                name="foreignBankName"
                value={values.foreignBankName}
                changeHandler={handleChange}
                onBlur={handleBlur}
                maxLength={40}
              />
              {touched.foreignBankName && errors.foreignBankName && (
                <ErrorFiled error={errors.foreignBankName} />
              )}
            </div>
          )}

          <div className="G-form-input-wrapper">
            <InputFiled
              requiredFiled={true}
              inputTitle="Հաշվարկային հաշիվ"
              name="bankingAccount"
              value={values.bankingAccount}
              changeHandler={bankingAccountHandler}
              onBlur={handleBlur}
              maxLength={20}
            />
            {touched.bankingAccount && errors.bankingAccount && (
              <ErrorFiled error={errors.bankingAccount} />
            )}
          </div>

          <div className="G-form-input-wrapper">
            <InputFiled
              inputTitle="Նշումներ"
              name="comment"
              value={values.comment}
              changeHandler={handleChange}
              onBlur={handleBlur}
              maxLength={textFiledLength}
            />
          </div>

          <GetSentDataBtn
            cancelPrintClick={closeModal}
            confirmExecuteClick={handleSubmit}
            executeClass={
              !isValid || !dirty || isSubmitting ? 'G-disabled-button' : ''
            }
            executeDisabled={!isValid || !dirty || isSubmitting}
          />
        </form>
      </div>
    </Modal>
  );
};

export default PartnerBankForm;
