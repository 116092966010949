import {
  DELETE_CASH_WARRANT,
  EDIT_CASH_WARRANT_DATA,
  GET_CASH_WARRANT_DATA,
  LOADING,
  UPDATE_PARTNER_CASH_WARRANT,
} from "./constants";

const initialState = {
  cashWarrantData: null,
  isLoading: false,
};

export const cashWarrantReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case GET_CASH_WARRANT_DATA:
      return {
        ...state,
        cashWarrantData: payload,
        isLoading: true,
      };

    case DELETE_CASH_WARRANT:
      return {
        ...state,
        cashWarrantData: {
          ...state?.cashWarrantData,
          cashRegisters: state.cashWarrantData?.cashRegisters?.filter(
            (item) => item.documentID !== payload,
          ),
        },
      };

    case UPDATE_PARTNER_CASH_WARRANT:
      return {
        ...state,
        cashWarrantData: {
          ...state.cashWarrantData,
          partners: [...state.cashWarrantData.partners, ...payload],
        },
      };

    case EDIT_CASH_WARRANT_DATA:
      return {
        ...state,
        cashWarrantData: {
          ...state?.cashWarrantData,
          cashRegisters: state.cashWarrantData?.cashRegisters?.map((cash) => {
            return cash.documentID === payload.documentID ? payload : cash;
          }),
        },
      };
    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return { ...state };
  }
};
