import React from 'react';
import { SelectControl } from '../../App/Components/SelectControl/SelectControl';
const MonthView = ({ selectedMonth, handleMonthChange }) => {
  const monthOptions = [
    'Հունվար',
    'Փետրվար',
    'Մարտ',
    'Ապրիլ',
    'Մայիս',
    'Հունիս',
    'Հուլիս',
    'Օգոստոս',
    'Սեպտեմբեր',
    'Հոկտեմբեր',
    'Նոյեմբեր',
    'Դեկտեմբեր',
  ];

  const options = monthOptions.map((month, index) => ({
    value: index + 1,
    label: month,
  }));

  return (
    <div className="mount-view">
      <SelectControl
        selectBoxTitle=""
        options={options}
        value={{
          value: selectedMonth,
          label: monthOptions[selectedMonth - 1],
        }}
        onChange={handleMonthChange}
        isClearable={true}
      />
    </div>
  );
};

export default MonthView;
