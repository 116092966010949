import {
  DELETE_CHART_OF_ACCOUNT,
  EDIT_CHART_OF_ACCOUNT,
  GET_CHART_OF_ACCOUNT_DATA,
  LOADING,
} from "./constants";

const initialState = {
  chartOfAccountData: null,
  isLoading: false,
};

export const chartOfAccountReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_CHART_OF_ACCOUNT_DATA:
      return {
        ...state,
        chartOfAccountData: payload,
        isLoading: true,
      };

    case EDIT_CHART_OF_ACCOUNT:
      return {
        ...state,
        chartOfAccountData: {
          ...state.chartOfAccountData,
          accounts: state.chartOfAccountData?.accounts?.map((account) => {
            return account.accountID === payload.accountID ? payload : account;
          }),
        },
      };
      case DELETE_CHART_OF_ACCOUNT:
        return {
          ...state,
          chartOfAccountData: {
            ...state.chartOfAccountData,
            accounts: state.chartOfAccountData?.accounts.filter(
              (item) => item.accountID !== payload
            ),
          },
        };
    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return { ...state };
  }
};
