import React, { useState } from 'react';
import './questions.scss';
import { QuestionsList } from './QuestionsList';
import { v4 as uuidv4 } from 'uuid';
import DynamicFontSize from '../../../Elements/DynamicFontSize';
const Questions = () => {
  const [size, setSize] = useState('normal');
  return (
    <div className="L-questions-list" id={`${`L-${size}`}`}>
      <div className="G-flex">
        {' '}
        <DynamicFontSize setSize={setSize} size={size} />
        <h1>Հաճախ տրվող հարցեր</h1>
      </div>

      <ul>
        {QuestionsList.map((i) => {
          const uniqueID = uuidv4();
          return (
            <li key={uniqueID}>
              {i.caption ? <h3>{i.caption}</h3> : null}
              <strong>{i.title}</strong>
              <p>{i.description}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Questions;
