import React from 'react';
import OrganizationForm from './OrganizationForm';
import useOrganizationDetails from '../../Hooks/organizationDetails/useOrganizationDetails';
import useIsMounted from '../../../MaterialValues/hooks/useIsMounted';
import Alert from '../../Components/Alert';

const UpdateOrganization = () => {
  const { updateOrganization, errorMess, setErrorMess } =
    useOrganizationDetails();
  const isMounted = useIsMounted();

  const onUpdateHandler = async (values) => {
    try {
      await updateOrganization(values);
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <OrganizationForm onSubmit={onUpdateHandler} />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default UpdateOrganization;
