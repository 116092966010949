import {
  DELETE_ADDITIONS_TYPE,
  FETCH_ADDITIONS_TYPE_FORM,
  FETCH_ADDITIONS_TYPES,
  UPDATE_ADDITIONS_TYPE,
} from './constants';

const initialState = {
  additionsData: null,
  additionsForm: null,
};

export const additionsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ADDITIONS_TYPES:
      return {
        ...state,
        additionsData: payload,
      };

    case FETCH_ADDITIONS_TYPE_FORM:
      return {
        ...state,
        additionsForm: payload,
      };

    case UPDATE_ADDITIONS_TYPE:
      return {
        ...state,
        additionsData: {
          ...state.additionsData,
          asTypes: state.additionsData.asTypes?.map((i) => {
            return i.salaryTypeID === payload.salaryTypeID ? payload : i;
          }),
        },
      };
    case DELETE_ADDITIONS_TYPE:
      return {
        ...state,
        additionsData: {
          ...state.additionsData,
          asTypes: state.additionsData.asTypes.filter(
            (item) => item.salaryTypeID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
