import { createAction } from '../actionCreators';
import {
  DELETE_UNIT_OF_MEASURE,
  EDIT_UNIT_OF_MEASURE,
  GET_UNIT_OF_MEASURE,
  LOADING,
} from './constants';

export const getUnitOfMeasureAction = createAction(GET_UNIT_OF_MEASURE);
export const loadingAction = createAction(LOADING, false);
export const editUnitOfMeasureAction = createAction(EDIT_UNIT_OF_MEASURE);
export const deleteUnitOfMeasureAction = createAction(DELETE_UNIT_OF_MEASURE);
