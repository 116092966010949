import React, { useState } from 'react';
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';
import { Commas } from '../../Utilities/Utilities';
import AnalyticalAccountsBalances from './AnalyticalAccountsBalances';

const ThreeDigitBalanceTable = ({ mainData, loading, dateFormulation }) => {
  const [analyticalAccount, setAnalyticalAccount] = useState(false);
  const accountHandler = (row) => {
    const { accountCode } = row || {};
    return (
      <span
        onClick={() => setAnalyticalAccount(accountCode)}
        className="G-account-is-active"
      >
        {accountCode}
      </span>
    );
  };
  const columnConfig = [
    {
      title: 'Հաշիվ',
      cell: (row) => accountHandler(row),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Հաշիվի անվանումը',
      cell: (row) => row.accountName,
      customStyle: { maxWidth: 1300, overflow: 'hidden' },
    },
    {
      title: 'Դեբետ',
      cell: (row) => Commas(row.amountDebit),
      customStyle: { maxWidth: 180 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => Commas(row.amountCredit),
      customStyle: { maxWidth: 180 },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-closing-account-balance-table G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {analyticalAccount && (
        <AnalyticalAccountsBalances
          accountCode={analyticalAccount}
          closeModal={setAnalyticalAccount}
          dateFormulation={dateFormulation}
        />
      )}
    </>
  );
};

export default ThreeDigitBalanceTable;
