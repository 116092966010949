import { getRequest } from '../../App/Api/Api';
import { dataTimeFormat } from '../../App/Utilities/Utilities';

export const fetchProvisions = async (companyID) => {
  let url = `getScales?companyID=${companyID}&startDate=${dataTimeFormat(
    new Date(),
  )}`;

  try {
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
