import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';
import moment from 'moment';
import { startOfMonth, startOfWeek } from '../Utilities/Utilities.js';
import Checkbox from '../components/Checkbox/Checkbox.jsx';
import Repg from '../components/Repg';
import Repdetal from '../components/RepDetal/Repdetal.js';
import { getAspect } from '../http/aspectAPI';
import { getEntr, getEntrTotalAspect } from '../http/entrAPI';
import { Pie } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import Header from '../components/Header/Header.jsx';
import HelpBtn from '../components/HelpBtn/HelpBtn.jsx';
import { HMRepo } from '../components/helperMessage/HM.js';
import HelperModal from '../components/HelperModal/HelperModal.jsx';
import { useSelector } from 'react-redux';
import Menu from '../components/Menu/Menu';
import Button from '../components/Button/Button.jsx';
import Dropdown from '../components/Dropdown/Dropdown';
import DateInterval from '../components/DateInterval/DateInterval';
import RadioBtn from '../components/RadioBtn/RadioBtn.jsx';
import './rep.scss';
const Rep = observer(() => {
  const { userID } = useSelector((state) => state.auth);
  const [openHelperModal, setOpenHelperModal] = useState(false);
  const [curr, setCurr] = useState('AMD');
  const [dateStart, setDateStart] = useState(new Date(startOfMonth));
  const [dateEnd, setDateEnd] = useState(new Date());
  const [income, setIncome] = useState(false);
  const [cost, setCost] = useState(false);
  const [aspectId, setAspectId] = useState(0);
  const [aspectName, setAspectName] = useState('Բոլորը');
  const [pieData, setPieData] = useState([]);
  const [pieDataE, setPieDataE] = useState([]);
  const { currency, entr, aspect } = useContext(Context);
  const [aspectAr, setAspectAr] = useState(aspect.Aspect);
  const [topping, setTopping] = useState('0');
  const [activeButton, setActiveButton] = useState(null);
  const onOptionChange = (e) => {
    setTopping(e.target.value);
  };

  useEffect(() => {
    if (aspect.Aspect) {
      const updatedAspectAr = [...aspect.Aspect];
      updatedAspectAr.unshift({ id: 0, name: 'Բոլորը' });
      setAspectAr(updatedAspectAr);
    }
  }, [aspect.Aspect]);

  let options = {
    legend: {
      position: 'top',
      labels: {
        boxWidth: 10,
      },
    },
    gridLines: {
      display: false,
    },

    datasets: [],
  };

  const HookGet = async (userID) => {
    try {
      const data = await getAspect(userID);
      if (data.data) {
        aspect.setAspect(data.data);
      }
    } catch (e) {}

    try {
      const data = await getEntr(userID);
      if (data.data) {
        entr.setEntr(data.data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    HookGet(userID);
  }, [userID]);

  const currs = currency.Currency;

  const Ndt = moment(dateStart).format('yyyy-MM-DD');
  const Kdt = moment(dateEnd).format('yyyy-MM-DD');

  const accountId = 1;

  const accountIdE = 2;

  const clickGraf = async () => {
    try {
      await getEntrTotalAspect(Ndt, Kdt, userID, curr, accountId)
        .then((data) => {
          const res = data.data;
          return res;
        })
        .then((res) => {
          let rasxG = res
            .map((item) => parseFloat(item.isAmount))
            .reduce((prev, curr) => prev + curr, 0);

          const label = [];
          const data = [];
          const backgroundColor = [];
          for (let i of res) {
            let proct = Math.round((i.isAmount * 10000) / rasxG) / 100;
            label.push(
              i.aspectName + ' - ' + i.aspectCount + 'տող -  ' + proct + ' %',
            );
            data.push(i.isAmount);

            let aspColor = aspectAr.find((el) => el.name === i.aspectName);
            backgroundColor.push(aspColor.imgColor);
          }
          setPieData({
            labels: label,
            datasets: [
              {
                data: data,
                backgroundColor: backgroundColor,
              },
            ],
          });
        });
    } catch (error) {
      console.log('err', error);
    }
  };

  const clickGrafE = async () => {
    try {
      await getEntrTotalAspect(Ndt, Kdt, userID, curr, accountIdE)
        .then((data) => {
          const res = data.data;
          return res;
        })
        .then((res) => {
          let prixG = res
            .map((item) => parseFloat(item.isAmount))
            .reduce((prev, curr) => prev + curr, 0);
          const label = [];
          const data = [];
          const backgroundColor = [];
          for (let i of res) {
            let proce = Math.round((i.isAmount * 10000) / prixG) / 100;
            label.push(
              i.aspectName + ' - ' + i.aspectCount + ' տող -  ' + proce + ' %',
            );
            data.push(i.isAmount);
            let aspColor = aspectAr.find((el) => el.name === i.aspectName);
            backgroundColor.push(aspColor.imgColor);
          }
          setPieDataE({
            labels: label,
            datasets: [
              {
                data: data,
                backgroundColor: backgroundColor,
              },
            ],
          });
        });
    } catch (error) {
      console.log('err', error);
    }
  };

  useEffect(() => {
    clickGraf();
    clickGrafE();
  }, [dateStart, dateEnd, curr]);

  const clickDay = () => {
    setActiveButton('day');
    setDateEnd(new Date());
    setDateStart(new Date());
  };

  const clickWeek = () => {
    setActiveButton('week');
    setDateEnd(new Date());
    setDateStart(new Date(startOfWeek));
  };

  const clickMonth = () => {
    setActiveButton('month');
    setDateEnd(new Date());
    setDateStart(new Date(startOfMonth));
  };

  const helpHandler = () => {
    setOpenHelperModal(true);
  };

  const aspectOption = aspectAr?.map((i) => ({
    ...i,
    label: i.name,
  }));

  const curreseOption = currs?.map((i) => ({
    ...i,
    label: i.name,
  }));

  return (
    <div className="budget-summary-section">
      <Menu />
      <div className="budget-summary-right-panel">
        <Header pageTitle="Հաշվետվություն" />

        <div className="budget-summary-block">
          <HelpBtn helpHandler={helpHandler} />

          <div className="budget-summary-flex">
            <div className="budget-summary-area">
              <div className="budget-summary-filter-flex">
                {<Repg />}

                <div className="filter-and-chose-wrap">
                  <div className="report-date-operations">
                    <DateInterval
                      dateStart={dateStart}
                      setDateStart={setDateStart}
                      dateEnd={dateEnd}
                      setDateEnd={setDateEnd}
                    />
                    <div className="radio-area">
                      <RadioBtn
                        name="costIncome"
                        id="costIncome"
                        value="0"
                        checked={topping === '0'}
                        label="Եկամուտ/Ծախս"
                        onChange={onOptionChange}
                      />

                      <RadioBtn
                        name="income"
                        id="income"
                        value="1"
                        label="Եկամուտ"
                        checked={topping === '1'}
                        onChange={onOptionChange}
                      />
                      <RadioBtn
                        name="cost"
                        id="cost"
                        value="2"
                        label="Ծախս"
                        checked={topping === '2'}
                        onChange={onOptionChange}
                      />
                    </div>
                  </div>

                  <div className="filter-date-block">
                    <Button
                      type="button"
                      onClick={clickDay}
                      customClass={activeButton === 'day' ? 'active_btn' : ''}
                      text="Այսօր"
                    />

                    <Button
                      type="button"
                      onClick={clickWeek}
                      customClass={activeButton === 'week' ? 'active_btn' : ''}
                      text="Շաբաթ"
                    />

                    <Button
                      type="button"
                      onClick={clickMonth}
                      customClass={activeButton === 'month' ? 'active_btn' : ''}
                      text="Ամիս"
                    />
                  </div>
                </div>
              </div>

              <div className="report-dropdowns">
                <Dropdown
                  customClass="currency-block"
                  options={curreseOption}
                  value={{ label: curr, value: curr }}
                  onChange={(cr) => {
                    setCurr(cr.name);
                  }}
                />
                <Dropdown
                  customClass="expense-income-types-block"
                  options={aspectOption}
                  value={{ label: aspectName, value: aspectName }}
                  onChange={(cr) => {
                    setAspectId(cr.id);
                    setAspectName(cr.name);
                  }}
                />
              </div>

              <Repdetal
                dateStart={moment(dateStart).format('yyyy-MM-DD')}
                dateEnd={moment(dateEnd).format('yyyy-MM-DD')}
                aspectId={aspectId}
                income={income}
                cost={cost}
                curr={curr}
                topping={topping}
              />
            </div>

            <div className="chart-block">
              <div className="chart-wrap">
                <h3> Եկամուտներ </h3>
                <Pie data={pieDataE} options={options} />
              </div>
              <div className="chart-wrap">
                <h3>Ծախսեր</h3>
                <Doughnut data={pieData} options={options} />
              </div>
            </div>
          </div>
        </div>
        {openHelperModal && (
          <HelperModal
            closeHelpBox={setOpenHelperModal}
            helperMessage={HMRepo}
          />
        )}
      </div>
    </div>
  );
});

export default Rep;
