import React from 'react';
import finHelp from '../../icons/finHelp.png';
import './style.scss';
import { useSelector } from 'react-redux';

const MainLogo = () => {
  const { firstName, lastName } = useSelector((state) => state.auth);
  return (
    <div className="personal-budget-logo-wrap">
      <div className="personal-budget-logo-wrap-box">
        <img src={finHelp} alt="" />
      </div>
      <div className="user-info">
        {firstName} {lastName}
      </div>
      <h3> Անձնական բյուջե </h3>
    </div>
  );
};

export default MainLogo;
