import React from 'react';
import { dateFormat } from '../../../Utilities/Utilities';

const WarehouseDetailsHeader = ({ warehouseDetailsData }) => {
  const { partnerName, accountType, serialNumber, supplierDate } =
    warehouseDetailsData || {};

  const executionDataArr = [
    { label: 'Գործընկերը', value: partnerName },
    { label: 'Տեսակը', value: accountType },
    { label: 'Սերիան, համարը', value: serialNumber },
    { label: 'Մատակարարման ամսաթիվը', value: dateFormat(supplierDate) },
  ];

  return (
    <div className="L-warehouse-details-header">
      <ul className="list">
        {executionDataArr.map((item, index) => (
          <li key={index} className="G-flex list-item">
            <strong className="label">{item.label}</strong>
            <span className="value">{item.value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WarehouseDetailsHeader;
