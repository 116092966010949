import React, { useEffect, useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import Button from '../../../App/Components/Button/Button';
import useMakeAnAddition from '../../hooks/useMakeAnAddition';
import { dateFormat } from '../../../App/Utilities/Utilities';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';
import {
  updateMakeAnAdditionData,
  calculateAmount,
} from './makeAnAdditionLogic';

const MakeAnAdditionTable = ({ mainData }) => {
  const { saveNewMakeAnAddition } = useMakeAnAddition();
  const [newMakeAnAddition, setNewMakeAnAddition] = useState(mainData?.makes);
  const [isDisabled, setIsDisabled] = useState(false);
  const [checkSign, setCheckSign] = useState(false);

  useEffect(() => {
    setNewMakeAnAddition(mainData?.makes);
  }, [mainData?.makes]);

  useEffect(() => {
    const checkEveryItem = newMakeAnAddition?.every((item) => item.sign);
    setCheckSign(checkEveryItem);
  }, [newMakeAnAddition]);

  const handleChange = (e, row, field) => {
    const updatedRows = updateMakeAnAdditionData(
      newMakeAnAddition,
      row,
      field,
      e.target.value,
    );
    setNewMakeAnAddition(updatedRows);
  };

  const handleChangeHours = (e, row) => handleChange(e, row, 'hours');
  const handleChangeCoefficient = (e, row) => handleChange(e, row, 'coeff');
  const onAmountChange = (e, row) => handleChange(e, row, 'amount');

  const onMarkAllCheckbox = (e) => {
    const updatedList = e.target.checked
      ? newMakeAnAddition.map((item) => ({
          ...item,
          sign: true,
          amount: calculateAmount(item),
          isModifed: true,
        }))
      : newMakeAnAddition.map((item) => ({
          ...item,
          sign: false,
          isModifed: true,
          amount: 0,
          hours: 0,
        }));
    setNewMakeAnAddition(updatedList);
  };

  const onMarkCurrentCheckbox = (e, row) => {
    const updatedList = newMakeAnAddition.map((item) =>
      item.empNo === row.empNo
        ? {
            ...item,
            sign: e.target.checked,
            amount: e.target.checked ? calculateAmount(item) : 0,
          }
        : item,
    );
    setNewMakeAnAddition(updatedList);
  };

  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 550 },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ազգանուն, Անուն, Հայրանուն',
      cell: (row) => row.emplName,
      customStyle: { maxWidth: 520, overflow: 'hidden' },
    },
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.calcDate),
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Ժամ',
      cell: (row) => (
        <input
          type="number"
          value={row?.hours}
          onChange={(e) => handleChangeHours(e, row)}
          disabled={!mainData?.hourEditSign}
          maxLength={4}
        />
      ),
      isEdit: mainData?.hourEditSign,
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Գործակիցը',
      cell: (row) => (
        <input
          type="number"
          value={row?.coeff}
          onChange={(e) => handleChangeCoefficient(e, row)}
          disabled={!mainData?.coeffEditSign}
          maxLength={5}
          pattern="[0-9.,]*"
        />
      ),
      isEdit: mainData?.coeffEditSign,
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Գումար',
      cell: (row) => (
        <NumericFormatFiled
          value={row.amount}
          allowNegative={true}
          name="amount"
          changeHandler={(e) => onAmountChange(e, row)}
          inputTitle=""
          disabled={!mainData?.amountEditSign}
        />
      ),
      isEdit: mainData?.amountEditSign,
      customStyle: { maxWidth: 150 },
    },
    {
      title: (
        <span className="G-flex">
          <Checkbox onChange={onMarkAllCheckbox} checked={checkSign} />
        </span>
      ),
      cell: (row) => (
        <Checkbox
          checked={row.hours > 0 || row.amount ? true : row.sign}
          onChange={(e) => onMarkCurrentCheckbox(e, row)}
        />
      ),
      customStyle: { maxWidth: 80 },
    },
  ];

  const onSaveHandler = async () => {
    try {
      await saveNewMakeAnAddition(newMakeAnAddition);
      setIsDisabled(true);
    } catch (error) {
      console.error('Error occurred while saving:', error);
    }
  };

  return (
    <>
      {newMakeAnAddition?.length > 0 && (
        <Table
          customClass="L-make-an-addition-table G-table-has-scroll"
          data={newMakeAnAddition}
          columnConfig={columnConfig}
        />
      )}
      {newMakeAnAddition?.length > 0 && (
        <div className="G-confirm-btn-wrap">
          <Button
            onClick={onSaveHandler}
            text="Հաստատել"
            type="submit"
            disabled={isDisabled}
            customClass={isDisabled && 'G-disabled-button'}
          />
        </div>
      )}
    </>
  );
};

export default MakeAnAdditionTable;
