import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "../../../Api/Api";
import Header from "../../../Components/Header/Header";
import Modal from "../../../Components/Modal/Modal";
import PartnerBankTable from "./PartnerBankTable";
import { getPartnerBankAction } from "../../../Redux/partnerSlices/useAction";
import { takeCount } from "../../../Utilities/Utilities";
import Pagination from "../../../Components/Pagination/Pagination";
import EventAddForm from "./EventAddForm";

const GetPartnerBankData = ({ closeModal, partnerInfo }) => {
  const { partnerID, partnerName } = partnerInfo;
  const { partnerBankData } = useSelector((state) => state.getPartnerData);
  const [pageNumber, setPageNumber] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();
  const getData = (pageNumber) => {
    getRequest(
      `getpartnerbanks?partnerID=${partnerID}&pageNumber=${
        pageNumber + 1
      }&takecount=${takeCount}`,
    )
      .then((res) => {
        dispatch(getPartnerBankAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, partnerID]);

  const openAddForm = () => {
    setOpenAddModal(true);
  };

  return (
    <Modal
      closeHandler={handleClose}
      onDrag={true}
      customClass="L-partner-bank-modal"
    >
      <Header
        pageTitle="Գործընկերոջ բանկերը"
        handelClick={openAddForm}
        modalCurrentName={partnerName}
      />
      <div className="L-partner-bank-modal-content">
        <PartnerBankTable mainData={partnerBankData?.partnerBanks} />
      </div>
      <Pagination
        pageCount={Math.ceil(
          (partnerBankData?.pageCount?.rowCount || 0) / takeCount,
        )}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
      {openAddModal && (
        <EventAddForm
          getData={getData}
          pageNumber={pageNumber}
          closeModal={setOpenAddModal}
          partnerData={{ partnerID, partnerName }}
        />
      )}
    </Modal>
  );
};

export default GetPartnerBankData;
