import { getRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';
export const fetchGeneralSummaryData = async (companyID) => {
  try {
    const response = await getRequest(
      `getTotalSummaryData?companyID=${companyID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const executeGeneralSummary = async (companyID, query) => {
  const queryRoot = urlEncodeQueryParams(query);
  try {
    const response = await getRequest(
      `getTotalSummary?companyID=${companyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
