import React from 'react';
import { useMemo } from 'react';
import { SelectControl } from '../SelectControl/SelectControl';

const SelectPartner = (props) => {
  const {
    partnerVal,
    partnerData,
    selectChangePartner,
    title = 'Գործընկերը',
    requiredFiled = false,
    customClass,
    matchFromParam = 'contains',
  } = props;
  const partnerOptions = useMemo(() => {
    return (
      partnerData &&
      partnerData?.map(({ partnerName, partnerID, taxCode, partnerForm }) => {
        return {
          label: partnerName,
          id: partnerID,
          value: partnerName,
          taxCode: taxCode,
          partnerForm: partnerForm,
        };
      })
    );
  }, [partnerData]);

  return (
    <div className={`${customClass} G-partner-wrapper`}>
      <SelectControl
        matchFromParam={matchFromParam}
        selectBoxTitle={title}
        requiredFiled={requiredFiled}
        id="partner"
        options={partnerOptions}
        value={partnerVal}
        onChange={selectChangePartner}
      />
    </div>
  );
};

export default SelectPartner;
