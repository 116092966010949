import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const YearPicker = ({
  initialYear,
  onYearChange,
  yearItemNumber = 9,
  dateFormat = 'yyyy',
  disabled,
}) => {
  return (
    <DatePicker
      selected={initialYear}
      onChange={onYearChange}
      showYearPicker
      dateFormat={dateFormat}
      yearItemNumber={yearItemNumber}
      disabled={disabled}
    />
  );
};

export default YearPicker;
