import { useEffect, useState } from 'react';
import {
  addNewWarehouseEntry,
  fetchInvoiceEntryForm,
  fetchIsRemoveWarehouseEntry,
  fetchWarehouseEntry,
  fetchWarehouseEntryDocNum,
  fetchWarehouseEntryExcel,
  fetchWarehouseEntryForm,
  fetchWarehouseTimePeriod,
  removeWarehouseEntry,
} from '../services/fetchWarehouseEntry';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWareHouseEntryAction,
  getWareHouseEntryFormAction,
  isTableUpdateAction,
  removeWareHouseEntryAction,
} from '../../App/Redux/materialValuesStor/warehouseEntrySlices/useAction';

const useWarehouseEntry = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [isAllowDelete, setIsAllowDelete] = useState(false);
  const { currentCompanyID, userID } = useSelector((state) => state.auth);
  const {
    wareHouseEntryData,
    warehouseEntryForm,
    warehouseEntranceID,
    isTableUpdate,
  } = useSelector((state) => state.wareHouseEntry);

  const dispatch = useDispatch();
  const getWarehouseTimePeriod = async () => {
    try {
      setIsLoading(true);
      const response = await fetchWarehouseTimePeriod(userID);
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouseEntry = async (query = '') => {
    try {
      setIsLoading(true);
      const response = await fetchWarehouseEntry(
        currentCompanyID,

        query,
      );

      dispatch(getWareHouseEntryAction(response));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouseEntryForm = async (matInputDocID) => {
    setIsLoading(true);
    try {
      const data = await fetchWarehouseEntryForm(
        currentCompanyID,
        matInputDocID,
      );

      dispatch(getWareHouseEntryFormAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getInvoiceEntryForm = async (partnerID, matInputDocID) => {
    setIsLoading(true);
    try {
      const data = await fetchInvoiceEntryForm(
        currentCompanyID,
        partnerID,
        matInputDocID,
      );

      dispatch(getWareHouseEntryFormAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const addWarehouseEntry = async (
    newWarehouseEntry,
    param,
    closeFormCallback,
  ) => {
    try {
      const { errorCode, message } = await addNewWarehouseEntry(
        newWarehouseEntry,
      );

      if (errorCode === 0) {
        dispatch(isTableUpdateAction({ isTableUpdate: false }));
        getWarehouseEntry(param);
        closeFormCallback();
      } else {
        setErrorMess(message || 'ինչ-որ բան սխալ է եղել');
      }
    } catch (error) {
      setError(error);
    }
  };

  const getRemoveWarehouseEntry = async (matInputDocID) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await fetchIsRemoveWarehouseEntry(
        matInputDocID,
      );

      if (errorCode === 0) {
        setIsAllowDelete(true);
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const deleteCurrentWarehouseEntry = async (
    matInputDocID,
    closeFormCallback,
  ) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await removeWarehouseEntry(matInputDocID);

      if (errorCode === 0) {
        dispatch(removeWareHouseEntryAction(matInputDocID));
        closeFormCallback();
      }
      if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouseEntryExcel = async (param) => {
    setIsLoading(true);
    try {
      const data = await fetchWarehouseEntryExcel(currentCompanyID, param);
      return data;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouseEntryDocNum = async (values) => {
    const { storeNo, year } = values || {};
    setIsLoading(true);
    try {
      const response = await fetchWarehouseEntryDocNum(
        currentCompanyID,
        storeNo,
        year,
      );

      return response;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    error,
    errorMess,
    setErrorMess,
    getWarehouseEntry,
    getWarehouseEntryForm,
    warehouseEntryForm,
    getInvoiceEntryForm,
    wareHouseEntryData,
    addWarehouseEntry,
    getRemoveWarehouseEntry,
    isAllowDelete,
    deleteCurrentWarehouseEntry,
    getWarehouseEntryExcel,
    getWarehouseEntryDocNum,
    warehouseEntranceID,
    getWarehouseTimePeriod,
    isTableUpdate,
  };
};

export default useWarehouseEntry;
