import {
  DELETE_OPERATIONS,
  EDIT_OPERATIONS_DATA,
  GET_OPERATIONS_DATA,
  LOADING,
  UPDATE_PARTNER_OPERATIONS,
} from './constants';

const initialState = {
  operationsData: null,
  accountFirst: null,
  operationsEditData: null,
  isLoading: false,
};

export const operationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_OPERATIONS_DATA:
      return {
        ...state,
        operationsData: payload,
        isLoading: true,
      };
    case UPDATE_PARTNER_OPERATIONS:
      return {
        ...state,
        operationsData: {
          ...state.operationsData,
          partners: [...state.operationsData.partners, ...payload],
        },
      };
    case DELETE_OPERATIONS:
      return {
        ...state,
        operationsData: {
          ...state.operationsData,
          entries: state.operationsData?.entries.filter(
            (item) => item.entryID !== payload,
          ),
        },
      };

    case EDIT_OPERATIONS_DATA:
      return {
        ...state,
        operationsData: {
          ...state.operationsData,
          entries: state.operationsData.entries.map((operation) => {
            let updateData =
              operation.entryID === payload.entryID ? payload : operation;
            return {
              accountDebit: payload.firstAccount,
              accountCredit: payload.secondAccount,
              ...updateData,
            };
          }),
        },
      };

    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return { ...state };
  }
};
