import {
  GET_CALENDAR_DATA,
  EDIT_EVENT,
  DELETE_EVENT,
  LOADING,
  UPDATE_CALENDAR_TYPE,
  UPDATE_EVENT_DROP,
  GET_DAY_NOTFICATION,
  IS_NOT_LOADING,
  UDPADE_DAY_NOTFICATION,
  ADD_DAY_NOTIFICATION,
  REMOVE_DAY_NOTIFICATION,
} from './constants';

const initialState = {
  calendarData: null,
  isLoading: false,
  notificationData: [],
  isNotLoading: false,
};

export const calendarReducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case GET_CALENDAR_DATA:
      return {
        ...state,
        calendarData: payload,
        isLoading: true,
      };
    case UPDATE_CALENDAR_TYPE:
      const updatedCalendarData = {
        ...state.calendarData,
        otherCalendarType: state.calendarData.otherCalendarType.map((item) => {
          if (item.calId === payload.calId) {
            return {
              ...item,
              isChecked: !payload.isChecked,
            };
          }
          return item;
        }),
        isLoading: true,
      };

      return {
        ...state,
        calendarData: updatedCalendarData,
      };

    case EDIT_EVENT:
      return {
        ...state,
        calendarData: {
          ...state.calendarData,
          events: state.calendarData.events.map((item) => {
            return item.id === payload.id && item.date === payload.date
              ? payload
              : item;
          }),
        },
      };
    case UPDATE_EVENT_DROP:
      return {
        ...state,
        calendarData: {
          ...state.calendarData,
          events: state.calendarData.events.map((item) => {
            return item.id === payload.id ? payload : item;
          }),
        },
      };
    case DELETE_EVENT:
      return {
        ...state,
        calendarData: {
          ...state.calendarData,
          events: state.calendarData?.events.filter(
            (item) => item.id !== payload,
          ),
        },
      };

    case GET_DAY_NOTFICATION:
      return {
        ...state,
        notificationData: payload,
        isNotLoading: true,
      };

    case IS_NOT_LOADING:
      return {
        ...state,
        isNotLoading: payload,
      };

    case ADD_DAY_NOTIFICATION:
      return {
        ...state,
        notificationData: [...state.notificationData, payload],
      };

    case UDPADE_DAY_NOTFICATION:
      return {
        ...state,
        notificationData: state.notificationData.map((item) => {
          return item.id === payload.id ? payload : item;
        }),
      };

    case REMOVE_DAY_NOTIFICATION:
      const updatedNotificationData = state.notificationData.filter(
        (item) => item.description !== payload,
      );

      return {
        ...state,
        notificationData: updatedNotificationData,
      };

    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return { ...state };
  }
};
