import React, { useState, useEffect } from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import SearchBar from '../../components/searchBar/SearchBar';
import SelectPartnerType from '../../../App/Components/SelectBox/SelectPartnerType';
import { useDebounce } from '../../../App/Hooks/useDebounce';

const PartnerFilter = ({ partnerParam, setPartnersParam }) => {
  const [searchString, setSearchString] = useState(partnerParam.searchString);
  const debouncedSearchTerm = useDebounce(searchString, 1500);

  useEffect(() => {
    setPartnersParam((prevState) => ({
      ...prevState,
      searchString: debouncedSearchTerm,
    }));
  }, [debouncedSearchTerm, setPartnersParam]);

  const selectChangePartner = (values) => {
    if (!values) {
      values = {
        value: '',
        id: 0,
      };
    }
    setPartnersParam((prevState) => ({
      ...prevState,
      partnerType: values.id,
      partnerTypeName: values.label,
    }));
  };

  const onInputChange = (searchString) => {
    setSearchString(searchString);
  };

  return (
    <SearchBackground>
      <div className="G-flex-align-center" style={{ width: '40%' }}>
        <SelectPartnerType
          partnerTypeVal={{ label: partnerParam.partnerTypeName }}
          selectChangePartner={(values) => selectChangePartner(values)}
        />
        <SearchBar
          query={searchString}
          setQuery={onInputChange}
          searchFiledTitle="Գործընկերոջ անվանումը"
        />
      </div>
    </SearchBackground>
  );
};

export default PartnerFilter;
