import React, { useMemo } from "react";
import { SelectControl } from "../SelectControl/SelectControl";

const SelectAccount = (props) => {
  const {
    accountData,
    accountVal,
    selectChangeAccount,
    title = 'Հաշիվ',
    id,
    customClass,
    requeuedAccount = true,
    disabled,
    isClearable = true,
  } = props;

  const accountOptions = useMemo(() => {
    return (
      accountData &&
      accountData?.map(
        ({ accountCode, accountName, accountID, type, bankCode }) => {
          return {
            label: accountCode + ' ' + accountName,
            id: accountID,
            value: accountCode,
            type: type ? type : '',
            accountName: accountName,
            bankCode: bankCode,
          };
        },
      )
    );
  }, [accountData]);

  return (
    <div className={`G-account-wrapper  ${customClass}`}>
      <SelectControl
        selectBoxTitle={title}
        requiredFiled={requeuedAccount}
        id={id}
        options={accountOptions}
        value={accountVal}
        onChange={selectChangeAccount}
        disabled={disabled}
        isClearable={isClearable}

      />
    </div>
  );
};

export default SelectAccount;
