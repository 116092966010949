import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { getRequest } from '../../App/Api/Api';
import Modal from '../../App/Components/Modal/Modal';
import './CalendarForm.scss';
import ToRemindDay from './ToRemindDay';
import InputFiled from '../../App/Components/InputFiled/InputFiled';
import GetSentDataBtn from '../../App/Components/GetSentDataBtn/GetSentDataBtn';
import OptionColor from '../OptionColor/OptionColor';
import EventRemoveForm from '../Calendar/EventRemoveForm';
import { useState } from 'react';
import Clock from './Clock';

import CheckBoxAllDay from '../CheckBoxAllDay/CheckBoxAllDay';
import { FormattedDateHy } from '../FormattedDateHy';
import Button from '../../App/Components/Button/Button';
const CalendarForm = ({
  closeHandler,
  eventId = null,
  onSubmit,
  initialValues,
}) => {
  const handleClose = () => closeHandler(false);
  const [eventData, setEventData] = useState([]);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  useEffect(() => {
    if (eventId) {
      getRequest(`getCalendarEventForEdit/${eventId}`)
        .then((res) => {
          setEventData(res.data);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  }, [eventId]);

  let checkData = eventId ? eventData : initialValues;
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...checkData },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Այս դաշտն անհրաժեշտ է լրացնել'),
    }),
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const handleEventRemove = (id) => {
    setShowRemoveModal(id);
  };

  return (
    <>
      <Modal customClass="calendar-form-modal" closeHandler={handleClose}>
        <div className="G-modal-block-title">
          <p className="current-day">{FormattedDateHy(values.date)}</p>
        </div>
        <form onSubmit={onSubmit}>
          <div className="calendar-form">
            {values.calId === 0 && (
              <OptionColor
                setFieldValue={setFieldValue}
                value={values.label?.trim()}
              />
            )}

            <div className="calendar-form-input">
              <InputFiled
                name="title"
                placeholder="Նշում"
                value={values.title}
                changeHandler={handleChange}
                onBlur={handleBlur}
                maxLength={60}
              />
            </div>
            <div className="calendar-form-input">
              <InputFiled
                name="description"
                placeholder="Նկարագրություն"
                value={values.description}
                changeHandler={handleChange}
                onBlur={handleBlur}
                maxLength={80}
              />
            </div>

            {values.calId === 0 ? (
              <>
                <CheckBoxAllDay
                  value={values.allDay}
                  setFieldValue={setFieldValue}
                />

                {!values.allDay ? (
                  <Clock setFieldValue={setFieldValue} value={values.time} />
                ) : null}

                {values.allDay && (
                  <ToRemindDay
                    setFieldValue={setFieldValue}
                    value={values.delta}
                  />
                )}
              </>
            ) : null}

            <div className="L-calendar-form-action-btn-flex">
              {values.id > 0 && (
                <div className="calendar-remove-btn">
                  <Button
                    text="Ջնջել"
                    onClick={() => handleEventRemove(values.id)}
                    type="button"
                  />
                </div>
              )}

              <GetSentDataBtn
                cancelPrintClick={handleClose}
                confirmExecuteClick={handleSubmit}
                executeClass={!(isValid && dirty) ? 'G-disabled-button' : ''}
                executeDisabled={!(isValid && dirty)}
              />
            </div>
          </div>
        </form>
      </Modal>
      {showRemoveModal && (
        <EventRemoveForm
          id={showRemoveModal}
          closeModal={setShowRemoveModal}
          closeEventForm={handleClose}
        />
      )}
    </>
  );
};

export default CalendarForm;
