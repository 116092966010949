import { createAction } from '../../actionCreators';
import {
  ADD_FORM_DATA,
  ADD_POSITIONS,
  DELETE_POSITIONS,
  FETCH_POSITIONS,
  UPDATE_POSITIONS,
} from './constants';

export const fetchPositionsAction = createAction(FETCH_POSITIONS);
export const deletePositionsAction = createAction(DELETE_POSITIONS);
export const updatePositionsAction = createAction(UPDATE_POSITIONS);
export const addPositionsFormAction = createAction(ADD_FORM_DATA);
export const addPositionsAction = createAction(ADD_POSITIONS);
