import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';

export const fetchPartners = async (currentCompanyID, params) => {
  const updatedParams = {
    ...params,
    pageNumber: params.pageNumber + 1,
  };
  const queryRoot = urlEncodeQueryParams(updatedParams);
  try {
    const response = await getRequest(
      `getPartners?companyID=${currentCompanyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchPartnerTypes = async () => {
  try {
    const response = await getRequest(`getPartnerTypes`);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addNewPartner = async (newPartner) => {
  try {
    const response = await postRequest('addPartner', newPartner);
    return response.data;
  } catch (error) {
    console.error('Error adding partner:', error);
    throw error;
  }
};
export const updatePartner = async (data) => {
  try {
    const response = await putRequest('editPartner', data);
    return response.data;
  } catch (error) {
    console.error('Error adding editPartner:', error);
    throw error;
  }
};

export const removePartner = async (partnerID) => {
  try {
    const response = await deleteRequest(`deletePartner/${partnerID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting Formulation:', error);
    throw error;
  }
};
