import React from "react";
import { useDispatch } from "react-redux";
import { editProductAction } from "../../Redux/purchaseActSlices/useAction";
import ProductForm from "./ProductForm";
import { removeCommas } from '../../Utilities/Utilities';

const EventEditForm = ({ modalClick, editRowData }) => {
  const handleClose = () => modalClick(false);
  const dispatch = useDispatch();

  const generateInitials = () => {
    return {
      accountCode: editRowData.accountCode,
      accountID: editRowData.accountID,
      amount: editRowData.amount,
      incomeTaxSign: editRowData.incomeTaxSign,
      price: editRowData.price,
      productName: editRowData.productName,
      productNameID: editRowData.productNameID,
      unitID: editRowData.unitID,
      quantity: editRowData.quantity,
      unitName: editRowData.unitName,
      productID: editRowData.productID,
    };
  };

  const handleSubmit = (values) => {
    const initPrices = removeCommas(values.price);
    dispatch(
      editProductAction({
        ...values,
        price: initPrices,
        amount: values.quantity * initPrices,
      }),
    );
    handleClose();
  };

  return (
    <ProductForm
      initialValue={generateInitials()}
      closeModal={modalClick}
      onSubmit={handleSubmit}
      handleClose={handleClose}
    />
  );
};

export default EventEditForm;
