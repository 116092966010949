import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import useBenefitCalculation from '../../hooks/useBenefitCalculation';
import useWorkWithEmployees from '../../hooks/useWorkWithEmployees';
import TabButtons from './TabButtons';
import { removeCommas } from '../../../App/Utilities/Utilities';
import Loading from '../../../App/Components/Loading/Loading';
import GeneralBenefitCalculation from './GeneralBenefitCalculation';
import BenefitCalculationAverages from './BenefitCalculationAverages';
import BenefitCalculationStory from './BenefitCalculationStory';
import './style.scss';
import BenefitCalculationHeader from './BenefitCalculationHeader';
import useIsMounted from '../../hooks/useIsMounted';

const BenefitCalculation = ({ rowValues, handleClose }) => {
  const { empNo } = rowValues || {};
  const { workWithEmployees } = useWorkWithEmployees();
  const isMounted = useIsMounted();
  const [activeTab, setActiveTab] = useState('GeneralBenefitCalculation');
  const {
    getBenefitCalculation,
    benefitCalculation,
    isLoading,
    addBenefitCalculation,
    addAverages,
    getBenefitIsValid,
    isVacationDateValid,
    setIsVacationDateValid,
  } = useBenefitCalculation();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    async function fetchBenefitCalculation() {
      try {
        await getBenefitCalculation(workWithEmployees?.salaryDate, empNo);
      } catch (error) {
        console.error('Failed to fetch benefit', error);
      }
    }
    if (empNo && workWithEmployees?.salaryDate) {
      fetchBenefitCalculation();
    }
  }, [empNo, workWithEmployees?.salaryDate]);

  const generateTabComponent = (Component, props) => <Component {...props} />;

  const {
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...benefitCalculation?.forBenefitSave },
    // validationSchema: BenefitValidation,
    onSubmit: async (values, { resetForm }) => {
      const newValues = {
        ...values,
        monthlyAvg: removeCommas(values.monthlyAvg),
        dailyAvg: removeCommas(values.dailyAvg),
        vacDays: Number(values.vacDays),
      };

      try {
        await addBenefitCalculation(newValues);
        if (isMounted) {
          setFieldValue('stName', '');
          setFieldValue('stNo', '');
        }
      } catch (error) {
        console.error('Failed to submit form:', error);
      }
    },
  });

  const commonProps = {
    benefitCalculation,
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    isValid,
    dirty,
    handleBlur,
    handleSubmit,
    handleClose,
    getBenefitIsValid,
    isVacationDateValid,
    setIsVacationDateValid,
    resetForm,
  };

  const tabComponents = {
    GeneralBenefitCalculation: generateTabComponent(
      GeneralBenefitCalculation,
      commonProps,
    ),
    BenefitCalculationAverages: generateTabComponent(
      BenefitCalculationAverages,
      {
        mainData: benefitCalculation,
        saveAverages: addAverages,
      },
    ),
    BenefitCalculationStory: generateTabComponent(BenefitCalculationStory, {
      benefitCalculationStory: benefitCalculation?.nonWorkings,
    }),
  };
  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <BenefitCalculationHeader data={benefitCalculation} />

      {benefitCalculation?.message?.length > 0 ? (
        <p className="G-title-p" style={{ textAlign: 'center' }}>
          {benefitCalculation?.message}
        </p>
      ) : (
        <>
          <TabButtons activeTab={activeTab} handleTabChange={handleTabChange} />
          <div className="L-vacation-calculation-tab-content">
            {tabComponents[activeTab]}
          </div>
        </>
      )}
    </>
  );
};

export default BenefitCalculation;
