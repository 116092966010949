import React from 'react';
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';
import { Commas } from '../../Utilities/Utilities';

const ClosingAccountBalanceTable = ({ mainData, loading }) => {
  const columnConfig = [
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountCode,
      customStyle: { width: 120 },
    },
    {
      title: 'Հաշիվի անվանումը',
      cell: (row) => row.accountName,
      customStyle: { maxWidth: 1280 },
    },
    {
      title: 'Դեբետ',
      cell: (row) => Commas(row.amountDebit),
      customStyle: { maxWidth: 200 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => Commas(row.amountCredit),
      customStyle: { maxWidth: 200 },
    },
  ];

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-closing-account-balance-table  G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default ClosingAccountBalanceTable;
