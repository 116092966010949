import { getRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';

export const fetchWorkWithEmployees = async (
  currentCompanyID,
  query,
  pageNumber,
) => {
  const queryRoot = urlEncodeQueryParams(query);

  try {
    const response = await getRequest(
      `getSalarySets?companyID=${currentCompanyID}&pageNumber=${
        pageNumber + 1
      }&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchWorkWithEmployeesExcel = async (currentCompanyID, query) => {
  const queryRoot = urlEncodeQueryParams(query);

  try {
    const response = await getRequest(
      `getSalarySetsForExcel?companyID=${currentCompanyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
