export const AssignAdditionalFields = [
  {
    fieldType: 'input',
    type: 'text',
    name: 'setOrder',
    label: 'Հրաման N',
    value: '',
    required: false,
    maxlength: 20,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Հավելում/պահում',
    required: true,
    fieldName: 'stName',
    dropdownId: 'stNo',
    optionName: 'salaryTypes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.stName,
        id: i.stNo,
        value: i.stName,
        graphName: i.graphName,
        graphNo: i.graphNo,
      }));
    },
  },

  {
    flexFiled: [
      {
        fieldType: 'date',
        dateName: 'setDate',
        dateTitle: 'Նշանակման սկիզբը',
        required: true,
      },
      {
        fieldType: 'date',
        dateName: 'endDate',
        dateTitle: 'Վերջը',
        required: false,
        minDate: (values) => {
          const startDate = new Date(values.setDate);

          const oneMonthAfterStartDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth() + 1,
            startDate.getDate(),
          );

          return oneMonthAfterStartDate.toISOString().split('T')[0];
        },
      },
    ],
  },
  {
    fieldType: 'amount',
    name: 'sAmount',
    label: 'Գումարը',
    value: '',
    required: true,
  },
];
