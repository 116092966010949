import { createAction } from "../actionCreators";
import {
  GET_CASH_WARRANT_DATA,
  LOADING,
  DELETE_CASH_WARRANT,
  EDIT_CASH_WARRANT_DATA,
  UPDATE_PARTNER_CASH_WARRANT,
} from "./constants";

export const getCashWarrantAction = createAction(GET_CASH_WARRANT_DATA);
export const loadingAction = createAction(LOADING, false);
export const editCashWarrantAction = createAction(EDIT_CASH_WARRANT_DATA);
export const deleteCashWarrantAction = createAction(DELETE_CASH_WARRANT);
export const updatePartnerAction = createAction(UPDATE_PARTNER_CASH_WARRANT);
