import { createAction } from '../../actionCreators';
import {
  DELETE_NON_WORKING_DAYS,
  FETCH_NON_WORKING_DAYS,
  FETCH_NON_WORKING_DAYS_FORM,
  UPDATE_NON_WORKING_DAYS,
} from './constants';

export const fetchNonWorkingDaysAction = createAction(FETCH_NON_WORKING_DAYS);
export const fetchNonWorkingDayFormAction = createAction(
  FETCH_NON_WORKING_DAYS_FORM,
);
export const deleteNonWorkingDayAction = createAction(DELETE_NON_WORKING_DAYS);
export const updateNonWorkingDayAction = createAction(UPDATE_NON_WORKING_DAYS);
