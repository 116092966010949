import React from "react";
import { Commas, dateFormat } from "../../Utilities/Utilities";
import Loading from "../../Components/Loading/Loading";
import Table from "../../Components/Table/Table";

const TransactionLogTable = ({ loading, mainData }) => {
  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Փաստաթուղթ',
      cell: (row) => row.docName,
      customStyle: { maxWidth: 250, overflow: 'hidden' },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 250, overflow: 'hidden' },
    },

    {
      title: 'Արժույթ',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 60 },
    },

    {
      title: 'Դեբետ',
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Արժույթ',
      cell: (row) => row.credCurrCode,
      customStyle: { maxWidth: 60 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գումար',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 150, textAlign: 'right' },
    },

    {
      title: 'Գումարը AMD',
      cell: (row) => Commas(row.amountAMD),
      customStyle: { maxWidth: 150, textAlign: 'right' },
    },
    {
      title: 'Նկարագրություն',
      cell: (row) => row.description,
      customStyle: { maxWidth: 130 },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-transaction-log-table G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default TransactionLogTable;
