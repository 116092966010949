import React from 'react';
import useAssignAdditionalStorage from '../../../hooks/useAssignAdditionalStorage';
import AssignPositionForm from './AssignPositionForm';
import useIsMounted from '../../../hooks/useIsMounted';
import { removeCommas } from '../../../../App/Utilities/Utilities';
import { useDispatch } from 'react-redux';
import { updateWithEmployeesSalaryAction } from '../../../../App/Redux/SalaryStor/workWithEmployeesSlices/useAction';
import Alert from '../../../../App/Components/Alert';

const EventEditForm = ({ rowValue, handleClose }) => {
  const { salarySetID, empNo } = rowValue || {};
  const { updateAssignAdditional, errorMess, setErrorMess } =
    useAssignAdditionalStorage();

  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  const onUpdateHandler = async (values) => {
    const amount = removeCommas(values.sAmount);
    const val = {
      ...values,
      sPercent: Number(values.sPercent),
      sAmount: amount,
    };

    try {
      await updateAssignAdditional(val, handleClose);
      if (!errorMess) {
        if (values.sAmount) {
          dispatch(
            updateWithEmployeesSalaryAction({
              empNo,
              sAmount: amount,
              graphName: values.graphName,
            }),
          );
        }
      }
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <AssignPositionForm
        salarySetID={salarySetID}
        empNo={empNo}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
      />

      {errorMess && (
        <Alert description={errorMess} onClose={() => setErrorMess('')} />
      )}
    </>
  );
};

export default EventEditForm;
