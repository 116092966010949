import React from "react";
import { useDispatch } from "react-redux";
import { deleteRequest, getRequest } from '../../../Api/Api';
import AcceptOrCancelModal from '../../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { deleteCashOutflowOrderAction } from '../../../Redux/cashOutflowOrderSlices/useAction';
import {
  StandardDocumentsDeleteMessage,
  deleteAgreeText,
} from '../../../Utilities/Utilities';
import { useState } from 'react';
import { useEffect } from 'react';

const DeleteCashOutflowOrder = ({
  closeModal,
  deleteRowId,
  setErrorHandler,
}) => {
  const [isAllowDelete, setIsAllowDelete] = useState(false);
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAllowDelete() {
      try {
        if (deleteRowId) {
          const url = `PreDeleteEditCashOut?documentID=${deleteRowId}`;
          const res = await getRequest(url);
          setIsAllowDelete(res.data.errorCode === 100);
        }
      } catch (err) {
        console.error('Error checking allow delete:', err);
      }
    }

    checkAllowDelete();
  }, [deleteRowId]);

  const deleteRowDate = () => {
    deleteRequest(`deleteCashOut/${deleteRowId}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deleteCashOutflowOrderAction(deleteRowId));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  };

  return (
    <AcceptOrCancelModal
      closeModal={closeModal}
      confirmClick={deleteRowDate}
      modalContent={
        isAllowDelete ? deleteAgreeText : StandardDocumentsDeleteMessage
      }
    />
  );
};

export default DeleteCashOutflowOrder;
