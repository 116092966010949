import { $host } from './index';

export const create = async (fdata) => {
  const data = await $host.post(`api/CreateEntr`, fdata);
  return data;
};

export const getEntr = async (userId) => {
  const data = await $host.get(`api/GetAllEntrs?UserID=${userId}`);
  return data;
};

export const deleteEntr = async (entrId) => {
  const data = await $host.delete(`api/DeleteEntr/${entrId}`);
  return data;
};

export const update = async (fdata) => {
  const data = await $host.put(`api/UpdateEntr`, fdata);
  return data;
};

export const getEntrTotalAspect = async (Ndt, Kdt, userId, curr, accountId) => {
  const data = await $host.get(
    `api/getEntrTotalAspect?userId=${userId}&dtStart=${Ndt}&dtEnd=${Kdt}&curr=${curr}&accountId=${accountId}`,
  );
  return data;
};

export const getEntrTotal = async (userId) => {
  const data = await $host.get(`api/getEntrTotal?UserID=${userId}`);

  return data;
};
