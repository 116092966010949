import React, { useEffect, useState } from 'react';
import useAssignAdditionalStorage from '../../../hooks/useAssignAdditionalStorage';
import Modal from '../../../../App/Components/Modal/Modal';
import './style.scss';
import AssignAdditionalStorageHeader from './AssignAdditionalStorageHeader';
import EventAddForm from './EventAddForm';
import AssignAdditionalTable from './AssignAdditionalTable';

const AssignAdditionalStorage = ({ rowValues, handleClose }) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const { empNo } = rowValues || {};
  const { getAssignAdditionalStorage, assignStorage } =
    useAssignAdditionalStorage();
  const [currentHold, setCurrentHold] = useState(false);
  useEffect(() => {
    getAssignAdditionalStorage(empNo, currentHold);
  }, [empNo, currentHold]);

  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="assign-additional-storage-modal"
      title="Նշանակել հավելում/պահում"
    >
      <AssignAdditionalStorageHeader
        assignStorage={assignStorage}
        handelClick={setShowAddForm}
        setCurrentHold={setCurrentHold}
        currentHold={currentHold}
      />

      <AssignAdditionalTable mainData={assignStorage?.salarySets} />
      {showAddForm && (
        <EventAddForm handleClose={onClose} rowValues={rowValues} />
      )}
    </Modal>
  );
};

export default AssignAdditionalStorage;
