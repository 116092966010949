import React from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { actionConfigs } from './actionConfigs';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import Button from '../../../App/Components/Button/Button';
import {
  dataTimeFormat,
  dateFormat,
  filterObjKeys,
  printHandler,
} from '../../../App/Utilities/Utilities';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import DateComponent from '../../components/dateComponent/DateComponent';

const SummaryDataAction = ({
  query,
  setQuery,
  summaryData,
  getExecuteSummaryData,
  resetTableData,
}) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const optionsData = summaryData || [];
  const dispatch = useDispatch();
  const handleDropdownChange = async (selectedOption, config) => {
    const { optionName, optionID } = config || {};
    const option = selectedOption || { value: '', id: null };
    resetTableData();
    setQuery((prevQuery) => ({
      ...prevQuery,
      [optionName]: option.value,
      [optionID]: option.id,
    }));
  };
  const handleButtonClick = (config) => {
    const { onClickHandler, actionType } = config || {};
    const { startDate, endDate, depNo } = query || {};
    const keysToRemove = ['salaryMonths', 'workDeps', 'years'];

    const modifyObj = filterObjKeys(query, keysToRemove);

    if (typeof onClickHandler === 'function') {
      switch (actionType) {
        case 'print':
          if (!startDate || !endDate) {
            return;
          }
          const url = `printSalaryTotals?companyID=${currentCompanyID}&startDate=${startDate}&endDate=${endDate}&depNo=${
            depNo || ''
          }`;
          printHandler(url, dispatch);
          break;

        default:
          getExecuteSummaryData(modifyObj);
          break;
      }
    }
  };
  const handleCheckboxChange = (event, name) => {
    const { checked } = event.target;
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: checked,
    }));
  };

  const handleDateChange = (date, name) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: dataTimeFormat(date),
    }));
  };

  return (
    <SearchBackground>
      <div className="L-calculation-sheet-action-box">
        {actionConfigs?.map((config, index) => {
          if (config.type === 'dropdown') {
            const dropDownOptions = optionsData
              ? config.Children(optionsData[config.optionName])
              : [];

            return (
              <div className="L-calculation-sheet-filed" key={index}>
                <SelectControl
                  value={{ label: query[config.optionName] }}
                  selectBoxTitle={config.title}
                  options={dropDownOptions || []}
                  onChange={(selectedOption) =>
                    handleDropdownChange(selectedOption, config)
                  }
                  requiredFiled={config.required}
                />
              </div>
            );
          } else if (config.type === 'calendar') {
            return (
              <div className="G-Summary-calendar-wrap" key={index}>
                <p className="G-title-p">{config.title}</p>
                <div className="G-flex">
                  {config.calendars?.map((i, calendarIndex) => (
                    <div className="calendar-box" key={calendarIndex}>
                      <DateComponent
                        value={dateFormat(query[i.name])}
                        onChange={(date) => handleDateChange(date, i.name)}
                        name={i.name}
                        requiredFiled={false}
                        openToDate={new Date(query[i.name])}
                      />
                    </div>
                  ))}
                </div>
              </div>
            );
          } else if (config.type === 'button') {
            return (
              <div className="G-add-btn" key={index}>
                <Button
                  type="button"
                  text={config.buttonText}
                  onClick={() => handleButtonClick(config)}
                  // disabled={config.disabled(query)}
                  // customClass={
                  //   config.disabled(query) ? 'G-disabled-button' : ''
                  // }
                />
              </div>
            );
          } else if (config.type === 'checkbox') {
            return (
              <div key={index}>
                <Checkbox
                  name={String(config.name)}
                  label={config.title}
                  checked={query[config.name]}
                  onChange={(e) => handleCheckboxChange(e, config.name)}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </SearchBackground>
  );
};

export default SummaryDataAction;
