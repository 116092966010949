import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';

export const fetchSavings = async (companyId) => {
  try {
    const response = await getRequest(`getDedTypes?companyID=${companyId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addSavings = async (savingsData) => {
  try {
    const response = await postRequest('addDedType', {
      ...savingsData,
    });

    return response.data;
  } catch (error) {
    console.error('Error adding savings:', error);
    throw error;
  }
};

export const updateSavings = async (savingsData) => {
  try {
    const response = await putRequest(`editDedType`, savingsData);
    return response.data;
  } catch (error) {
    console.error('Error updating savings:', error);
    throw error;
  }
};

export const deleteSavings = async (salaryTypeID) => {
  try {
    const response = await deleteRequest(`deleteDedType/${salaryTypeID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting Savings:', error);
    throw error;
  }
};

export const fetchSavingsForm = async (currentCompanyID, salaryTypeID) => {
  if (!currentCompanyID) {
    return;
  }
  try {
    const response = await getRequest(
      `getDedType?companyID=${currentCompanyID}&salaryTypeID=${salaryTypeID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
