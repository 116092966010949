import React from 'react';
import { CalculationDataFields } from './FieldsConfig';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import Input from '../../Input/Input';
import InputTitle from '../../Input/InputTitle';
import RadioButton from '../../../App/Components/RadioButton/RadioButton';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
const CalculationData = ({
  onChange,
  setFieldValue,
  values,
  handleBlur,
  employeesFormData,
  fieldError,
}) => {
  let dropDownOptions = [];
  const updateFieldValueAndDropdown = (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id
      ? selectedOption.id.toString().trim()
      : '';

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
  };

  return (
    <div className="L-calculation-data-comp">
      {CalculationDataFields?.map((field) => {
        const {
          fieldType,
          label,
          name,
          required,
          type,
          radioBtn,
          radioTitle,
          Children,
          optionName,
          dropdownTitle,
          fieldName,
          dropdownId,
          maxLength,
        } = field;

        if (typeof Children === 'function') {
          if (employeesFormData) {
            dropDownOptions = Children(employeesFormData[optionName]);
          }
        }

        switch (fieldType) {
          case 'input':
            return (
              <Input
                type={type}
                key={name}
                inputTitle={label}
                name={name}
                requiredFiled={required}
                onChange={onChange}
                value={values[name]}
                onBlur={handleBlur}
                maxLength={maxLength}
              />
            );
          case 'checkbox':
            return (
              <Checkbox
                name={name}
                label={label}
                value={values[name]}
                checked={values[name]}
                onChange={() => setFieldValue(name, !values[name])}
                onBlur={handleBlur}
              />
            );

          case 'radio':
            return (
              <div className="G-s-input-wrap">
                <InputTitle requiredFiled={required} inputTitle={radioTitle} />
                <div className="employees-radio-flex">
                  {radioBtn?.map(({ value, name, label }) => (
                    <RadioButton
                      name={name}
                      value={value}
                      checked={value === values[name]}
                      onChange={() => setFieldValue(name, value)}
                      label={label}
                    />
                  ))}
                </div>
              </div>
            );
          case 'dropdown':
            return (
              <div key={fieldName} className="select-container">
                <SelectControl
                  selectBoxTitle={dropdownTitle}
                  requiredFiled={required}
                  value={{
                    label: values[fieldName] || '',
                    value: values[fieldName] || '',
                  }}
                  options={dropDownOptions || []}
                  onChange={(selectedOption) =>
                    updateFieldValueAndDropdown(
                      fieldName,
                      selectedOption,
                      dropdownId,
                    )
                  }
                  onBlur={handleBlur}
                />
                {required && !values[fieldName] && fieldError && (
                  <ErrorFiled error={fieldError} />
                )}
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default CalculationData;
