import React from 'react';

const EmployeesListTotal = ({ total }) => {
  return (
    <div className="L-employees-list-total">
      <h4>Ընդամենը</h4>
      <span> {total}</span>
    </div>
  );
};

export default EmployeesListTotal;
