import React from 'react';
import './dayNotifications.scss';
import { useSelector } from 'react-redux';
import { FormattedDateHy } from '../FormattedDateHy';

const DayNotifications = ({ notiData, customClass = 'L-day-not-block' }) => {
  const { notificationData, isNotLoading } = useSelector(
    (state) => state.getCalendarData,
  );
  const currentDateNote = notiData ?? notificationData ?? [];

  if (isNotLoading) {
    return <p>Բեռնվում է...</p>;
  }

  return (
    <div className={` ${customClass}`}>
      <div className="L-day-not-list">
        {currentDateNote?.map(({ id, time, description, date }) => (
          <div key={id} className="L-day-not-item">
            <h3 className="calendar-title">{FormattedDateHy(date)}</h3>
            <div className="G-flex">
              {time !== '00:00' ? null : <span>{time}</span>}
              <span>{description}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DayNotifications;
