import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import Input from '../../Input/Input';
import { SavingFields } from './FieldsConfig';
import useSavingsForm from '../../hooks/useSavingsForm';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import OnHoldSavingList from './OnHoldSavingList';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import { SavingValidation } from '../../../App/Validation/SavingValidation';
import useIsMounted from '../../hooks/useIsMounted';
const SavingForm = ({ handleClose, onSubmit, salaryTypeID = 0 }) => {
  const { savingFormData, fetchSavingFormData } = useSavingsForm();
  const isMounted = useIsMounted();
  useEffect(() => {
    fetchSavingFormData(salaryTypeID);
  }, [salaryTypeID]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
    setFieldValue,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...savingFormData?.asType,
    },
    validationSchema: SavingValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
      if (isMounted) {
        resetForm();
      }
    },
  });
  let dropDownOptions = [];

  const updateFieldValueAndDropdown = (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    const fieldValue = selectedOption.label;
    const dropdownItemId = selectedOption.id
      ? selectedOption.id.toString().trim()
      : '';

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="saving-form-block"
    >
      <form onSubmit={onSubmit}>
        {SavingFields?.map((field) => {
          const {
            fieldType,
            fieldName,
            label,
            name,
            required,
            Children,
            optionName,
            dropdownTitle,
            type,
            dropdownId,
            accounts,
            maxlength,
            icon,
          } = field;
          if (typeof Children === 'function') {
            if (savingFormData) {
              dropDownOptions = Children(savingFormData[optionName]);
            }
          }
          const fieldError = touched[name] && errors[name];

          switch (fieldType) {
            case 'input':
              return (
                <div key={name} className="saving-input-container">
                  <Input
                    type={type}
                    inputTitle={label}
                    name={name}
                    requiredFiled={required}
                    onChange={handleChange}
                    value={values[name]}
                    maxLength={maxlength}
                  />
                  {/* {required && !values[name] && fieldError && (
                    <ErrorFiled error={fieldError} />
                  )} */}
                </div>
              );
            case 'dropdown':
              return (
                <div className="select-container">
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={required}
                    value={{
                      label: values[fieldName] || '',
                      value: values[fieldName] || '',
                    }}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      updateFieldValueAndDropdown(
                        fieldName,
                        selectedOption,
                        dropdownId,
                      )
                    }
                    onBlur={handleBlur}
                  />
                </div>
              );
            case 'checkbox':
              return (
                <Checkbox
                  name={name}
                  label={label}
                  value={values[name]}
                  checked={values[name]}
                  onChange={() => setFieldValue(name, !values[name])}
                  onBlur={handleBlur}
                />
              );
            case 'upArrow':
              if (values && values.graphNo > 0) {
                return null;
              }

              return (
                <div
                  key={fieldName}
                  className="G-up-arrow-block"
                  onClick={() => setFieldValue(name, !values[name])}
                >
                  <img
                    src={icon}
                    alt=""
                    style={{
                      transform: values?.calcSign && 'rotate(1deg)',
                    }}
                  />
                  <p>{label}</p>
                </div>
              );
            case 'onHoldList':
              return (
                values?.calcSign && (
                  <div key={fieldName} className="saving-input-container">
                    <OnHoldSavingList
                      values={values}
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      data={savingFormData?.additions}
                    />
                  </div>
                )
              );
            default:
              if (Array.isArray(accounts)) {
                return (
                  <div className="saving-account-block">
                    {accounts.map((account) => (
                      <Input
                        type={account.type}
                        inputTitle={account.label}
                        name={account.name}
                        requiredFiled={account.required}
                        onChange={handleChange}
                        value={values[account.name]}
                      />
                    ))}
                  </div>
                );
              } else {
                return null;
              }
          }
        })}

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
          executeDisabled={!dirty || !isValid}
        />
      </form>
    </Modal>
  );
};

export default SavingForm;
