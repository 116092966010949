import React from 'react';
import Input from '../../components/Input/Input';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import { AdditionalMatNameField } from './FieldsConfig';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import Textarea from '../../components/textarea/Textarea';

const AdditionalMatNameForm = ({
  onChange,
  materialNameFormData,
  setFieldValue,
  values,
  errors,
  touched,
  handleBlur,
}) => {
  let dropDownOptions = [];

  const updateFieldValueAndDropdown = (
    fieldName,
    selectedOption,
    dropdownId,
  ) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id
      ? selectedOption.id.toString().trim()
      : '';

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
  };
  const customClass = 'L-material-value-name-filed';
  return (
    <div className="L-material-values-name">
      {AdditionalMatNameField?.map((field, index) => {
        const {
          fieldType,
          fieldName,
          label,
          name,
          required,
          Children,
          optionName,
          dropdownTitle,
          type,
          dropdownId,
          maxLength,
        } = field;

        const fieldError = touched[name] && errors[name];

        if (typeof Children === 'function') {
          if (materialNameFormData) {
            dropDownOptions = Children(materialNameFormData[optionName]);
          }
        }

        switch (fieldType) {
          case 'input':
            return (
              <div key={name} className={customClass}>
                <Input
                  type={type}
                  inputTitle={label}
                  name={name}
                  requiredField={required}
                  onChange={onChange}
                  value={values[name]}
                  onBlur={handleBlur}
                  maxLength={maxLength}
                />
                {fieldError && <ErrorFiled error={fieldError} />}
              </div>
            );
          case 'dropdown':
            return (
              <div key={fieldName} className={customClass}>
                <SelectControl
                  selectBoxTitle={dropdownTitle}
                  requiredFiled={required}
                  value={{
                    label: values[fieldName] || '',
                    value: values[fieldName] || '',
                  }}
                  options={dropDownOptions || []}
                  onChange={(selectedOption) =>
                    updateFieldValueAndDropdown(
                      fieldName,
                      selectedOption,
                      dropdownId,
                    )
                  }
                  matchFromParam=""
                  onBlur={handleBlur}
                />
                {required && !values[fieldName] && fieldError && (
                  <ErrorFiled error={fieldError} />
                )}
              </div>
            );
          case 'textArea':
            return (
              <div key={name} className={`${customClass} text-area`}>
                <Textarea
                  title={label}
                  name={name}
                  onChange={onChange}
                  value={values[name]}
                  onBlur={handleBlur}
                  maxLength={100}
                />
                {fieldError && <ErrorFiled error={fieldError} />}
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default AdditionalMatNameForm;
