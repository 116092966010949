import * as Yup from 'yup';
import { InputFiledMessage } from './ErrorMessage';

export const TablesValidation = () =>
  Yup.object().shape({
    graphNo: Yup.number().required(InputFiledMessage),

    sign56: Yup.string().required(InputFiledMessage),
    graphName: Yup.string().required(InputFiledMessage),
    hours: Yup.number()
      .test(
        'փոքր է կամ հավասար է 24-ի',
        'Ժամը պետք է լինեն 24-ից փոքր կամ հավասար',
        (value) => value <= 24,
      )
      .required(InputFiledMessage),
  });
