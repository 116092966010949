export const fieldConfigs = (docTypeID, inputTypeId) => {
  const filedList = [
    {
      fieldType: 'dropdown',
      dropdownTitle: 'ՆԱ անվանումը',
      required: true,
      fieldName: 'matValueName',
      dropdownId: 'matValueNo',
      optionName: 'matValues',
      addNewItem: true,
      Children: (props) => {
        return props?.map((i) => ({
          label: i.matValueName,
          id: i.matValueNo,
          value: i.matValueName,
          accCode: i.accCode,
          accName: i.accName,
          unit: i.unit,
        }));
      },
    },
    {
      fieldType: 'dropdown',
      dropdownTitle: 'Հաշիվը',
      fieldName: 'accName',
      dropdownId: 'accCode',
      optionName: 'accounts',
      required: true,
      Children: (props) => {
        return props?.map((i) => ({
          label: i.accountName,
          id: i.accountCode,
          value: i.accountName,
        }));
      },
    },
    {
      fieldType: 'input',
      title: 'Քանակը',
      name: 'amount',
      maxLength: 8,
      required: true,
    },
  ];

  if (docTypeID === 2 && (inputTypeId === 3 || inputTypeId === 4)) {
    filedList.push(
      {
        fieldType: 'amount',
        title: 'Գնման գինը',
        name: 'purPrice',
        maxLength: 22,
        required: true,
      },
      {
        fieldType: 'input',
        title: 'Եկամտային հարկը',
        name: 'incomeTax',
        type: 'text',
        maxLength: 8,
        value: '',
        required: false,
      },
      {
        fieldType: 'amount',
        title: 'Գինը',
        name: 'price',
        value: '',
        maxLength: 22,
        required: false,
      },
    );
  } else {
    filedList.push({
      fieldType: 'amount',
      title: 'Գինը',
      name: 'purPrice',
      value: '',
      maxLength: 22,
      required: true,
    });
  }
  filedList.push({
    fieldType: 'amount',
    title: 'Գումարը',
    value: '',
    name: 'totalPrice',
    maxLength: 12,
    disabled: true,
  });
  return filedList;
};
