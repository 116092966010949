import React, { useEffect, useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Button from '../../../App/Components/Button/Button';
import { removeCommas } from '../../../App/Utilities/Utilities';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';
import TotalAmountForm from './TotalAmountForm';

const VacationAveragesForm = ({ mainData, saveAverages }) => {
  const [isDisConfirm, setIsDisConfirm] = useState(true);
  const [newVacationAverages, setNewVacationAverages] = useState(
    mainData?.averages,
  );

  const [newTotalAmount, setNewTotalAmount] = useState({
    totalAmount: mainData?.totalAmount,
    totalAm12: mainData?.totalAm12,
    totalCount: mainData?.totalCount,
  });

  useEffect(() => {
    setNewVacationAverages(mainData?.averages);
    setNewTotalAmount(mainData);
  }, [mainData?.averages, mainData]);

  const calculateTotalAmount = (averages) => {
    const initialTotals = { total: 0, totalAm12: 0, totalCount: 0 };

    const updatedTotals = averages.reduce((totals, item) => {
      if (item.sign === 1) {
        setIsDisConfirm(false);
        totals.total += item.amount;
        totals.totalAm12 += item.am12;
        totals.totalCount++;
      }
      setIsDisConfirm(true);
      return totals;
    }, initialTotals);

    setNewTotalAmount({
      totalAmount: updatedTotals.total,
      totalAm12: updatedTotals.totalAm12,
      totalCount: updatedTotals.totalCount,
    });
  };

  const onInputChange = (e, row) => {
    const { name, value } = e.target;
    const newValue = name === 'amount' ? removeCommas(value) : Number(value);

    const updatedRows = newVacationAverages?.map((r) => {
      if (r.averageID === row.averageID) {
        setIsDisConfirm(false);
        return { ...r, [name]: newValue, isModifed: true };
      } else {
        setIsDisConfirm(true);
        return r;
      }
    });

    setNewVacationAverages(updatedRows);
  };

  const onMarkCurrentCheckbox = (e, row) => {
    const { checked } = e.target;

    let updatedList = newVacationAverages.map((item) => {
      if (item.averageID === row.averageID) {
        setIsDisConfirm(false);
        return { ...item, sign: checked ? 1 : 0, isModifed: checked };
      }
      setIsDisConfirm(true);
      return item;
    });
    calculateTotalAmount(updatedList);
    setNewVacationAverages(updatedList);
  };

  const columnConfig = [
    {
      title: 'Ամիսը',
      cell: (row) => row.stMonth,
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Օրաց. օր',
      cell: (row) => (
        <input
          type="number"
          value={row?.workDays}
          name="workDays"
          onChange={(e) => onInputChange(e, row)}
          disabled={row.signAvg !== 1}
          maxLength={2}
        />
      ),
      isEdit: true,
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Փաստ. օր',
      cell: (row) => (
        <input
          type="number"
          value={row?.days}
          name="days"
          onChange={(e) => onInputChange(e, row)}
          disabled={row.signAvg !== 1}
        />
      ),
      isEdit: true,
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Միջին աշխատ.',
      cell: (row) => (
        <NumericFormatFiled
          value={row.amount}
          allowNegative={true}
          name="amount"
          changeHandler={(e) => onInputChange(e, row)}
          disabled={row.signAvg !== 1}
          maxLength={3}
          inputTitle=""
        />
      ),
      isEdit: true,
      customStyle: { maxWidth: 220 },
    },
    {
      title: '1/12 Մասով',
      cell: (row) => (
        <input
          type="number"
          value={row?.am12}
          name="am12"
          onChange={(e) => onInputChange(e, row)}
          disabled={row.signAvg !== 1}
        />
      ),
      customStyle: { maxWidth: 150 },
    },

    {
      title: 'Նշ',
      cell: (row) => (
        <Checkbox
          checked={row.sign}
          value={row.sign}
          onChange={(e) => onMarkCurrentCheckbox(e, row)}
          disabled={row.signAvg !== 1}
        />
      ),
      customStyle: { maxWidth: 80 },
    },
  ];
  const onSaveHandler = async () => {
    try {
      await saveAverages(newVacationAverages);
      // setIsDisabled(true);
    } catch (error) {
      console.error('Error occurred while saving:', error);
    }
  };

  return (
    <>
      {newVacationAverages !== null && newVacationAverages?.length ? (
        <Table
          customClass="L-vacation-averages-table G-table-has-scroll"
          data={newVacationAverages}
          columnConfig={columnConfig}
        />
      ) : null}

      <TotalAmountForm mainData={newTotalAmount} />
      {newVacationAverages !== null && newVacationAverages?.length && (
        <div className="G-confirm-btn-wrap">
          <Button
            onClick={onSaveHandler}
            disabled={isDisConfirm}
            text="Հաստատել"
            type="submit"
            customClass={isDisConfirm && 'G-disabled-button'}
          />
        </div>
      )}
    </>
  );
};

export default VacationAveragesForm;
