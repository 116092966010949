import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { getRequest, postRequest } from '../../../Api/Api';
import Alert from '../../../Components/Alert';
import PartnerBankForm from './PartnerBankForm';

const EventAddForm = ({
  closeModal,
  getData,
  pageNumber,
  partnerData,
  addNewBank = false,
  addNewPartnerBank = false,
  addNewBankAction,
  onAddNewPartnerBank,
  partnerBankInfo,
  customPartnerID = 0,
}) => {
  const { partnerID, partnerName } = partnerData || {};
  const [partnerBankData, setPartnerBankData] = useState({});
  const [errorHandler, setErrorHandler] = useState('');

  useEffect(() => {
    getRequest(
      `getBanksForPartner?partnerID=${
        customPartnerID ? customPartnerID : partnerID
      }`,
    ).then((res) => {
      setPartnerBankData(res?.data);
    });
  }, [customPartnerID, partnerID]);

  const handleClose = useCallback(() => {
    closeModal(false);
  }, [closeModal]);

  const onSubmit = useCallback(
    (values, resetForm) => {
      postRequest('addPartnerBank', values)
        .then((res) => {
          const { errorCode, message } = res.data;
          if (errorCode === 0 && !addNewBank && !addNewPartnerBank) {
            getData(pageNumber);
            resetForm();
            handleClose();
          } else if (errorCode === 0 && addNewPartnerBank) {
            partnerBankInfo({
              bankingAccount: values.bankingAccount,
              bankName: values.bankName,
            });
            resetForm();
            handleClose();
          } else if (errorCode === 0 && addNewBank) {
            addNewBankAction((prevPartnerBankList) => [
              ...prevPartnerBankList,
              values,
            ]);

            const partnerBankInfo = {
              bankingAccount: values.bankingAccount,
              bankName: values.bankName,
            };
            onAddNewPartnerBank(partnerBankInfo);
            handleClose();
          } else if (errorCode > 0) {
            setErrorHandler(message);
          } else {
            return null;
          }
        })
        .catch();
    },
    [
      addNewBank,
      addNewPartnerBank,
      getData,
      pageNumber,
      handleClose,
      addNewBankAction,
      onAddNewPartnerBank,
    ],
  );

  const memoizedPartnerBankData = useMemo(
    () => partnerBankData,
    [partnerBankData],
  );

  return (
    <>
      <PartnerBankForm
        closeModal={handleClose}
        initialData={memoizedPartnerBankData?.partnerBank}
        partnerBankList={memoizedPartnerBankData?.banks}
        onSubmit={onSubmit}
        partnerName={partnerName}
      />
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default EventAddForm;
