export const ConvertDataExcel = (data) => {
  return (
    data !== null &&
    data?.length > 0 &&
    data?.map((i) => {
      return {
        'Դեբետ հաշիվ': i.accountCode,
        'Հաշիվի անվանումը': i.accountName,
        'Կրեդիտ հաշիվ': i.creditAccountCode,
        'Հաշվի անվանումը': i.creditAccountName,
        Գումարը: i.calculationDebit,
      };
    })
  );
};

export default ConvertDataExcel;
