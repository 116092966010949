import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';

export const fetchEmployees = async (
  currentCompanyID,
  query,
  currentId,
  pageNumber,
) => {
  const queryRoot = urlEncodeQueryParams(query);
  let url = `getEmployees?companyID=${currentCompanyID}&pageNumber=${
    pageNumber + 1
  }&${queryRoot}`;
  if (currentId) {
    url += `&depNo=${currentId}`;
  }
  try {
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
export const fetchEmployeesExcelData = async (currentCompanyID, param) => {
  let url = `getEmployeesForExcel?companyID=${currentCompanyID}`;
  const { depNo, rentNo } = param || {};
  const params = [];
  if (depNo) params.push(`depNo=${depNo}`);
  if (rentNo) params.push(`rentNo=${rentNo}`);

  if (params.length) {
    url += `&${params.join('&')}`;
  }

  try {
    const response = await getRequest(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addEmployees = async (workerIData) => {
  try {
    const response = await postRequest('addEmployee', {
      ...workerIData,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding subdivision:', error);
    throw error;
  }
};

export const updateEmployees = async (workerIData) => {
  try {
    const response = await putRequest(`editEmployee`, workerIData);
    return response.data;
  } catch (error) {
    console.error('Error updating subdivision:', error);
    throw error;
  }
};

export const deleteEmployees = async (workerId) => {
  try {
    const response = await deleteRequest(`deleteEmployee/${workerId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting deleteEmployee:', error);
    throw error;
  }
};
export const fetchIsAllowDelete = async (id) => {
  try {
    const response = await deleteRequest(`preDeleteEmployee/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting preDeleteEmployee:', error);
    throw error;
  }
};

export const fetchEmployeesFormData = async (currentCompanyID, employeeID) => {
  if (!currentCompanyID) {
    return;
  }
  try {
    const response = await getRequest(
      `getEmployee?companyID=${currentCompanyID}&employeeID=${employeeID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchPublicServiceNumber = async (
  currentCompanyID,
  socCardNo,
  employeeID,
) => {
  if (!currentCompanyID) {
    return;
  }
  try {
    const response = await getRequest(
      `getSocCardNo?companyID=${currentCompanyID}&socCardNo=${socCardNo}&employeeID=${employeeID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
