import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import Pagination from '../../Components/Pagination/Pagination';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
import EventAddForm from './EventAddForm';
import InitialBalancesTable from './InitialBalancesTable';
import './initialBalances.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import { getInitialBalancesAction } from '../../Redux/InitialBalancesSlices/useAction';
import { loadingAction } from '../../Redux/bankSlices/useAction';
import FilteredBalanceData from './FilteredBalanceData';
import Button from '../../Components/Button/Button';
import { printHandler, viewPrintTitle } from '../../Utilities/Utilities';

const GetInitialBalancesData = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { initialBalancesData, isLoading } = useSelector(
    (state) => state.getInitialBalances,
  );

  const [pageNumber, setPageNumber] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);

  const [initBalancesItem, setInitBalancesItem] = useState({
    label: '',
    value: '',
    id: '',
  });
  const dispatch = useDispatch();

  const getData = (pageNumber, accountCode = '') => {
    dispatch(loadingAction(true));
    getRequest(
      `getinitbalances?companyID=${currentCompanyID}&pageNumber=${
        pageNumber + 1
      }&searchstring=${accountCode}`,
    )
      .then((res) => {
        dispatch(getInitialBalancesAction(res.data));
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        dispatch(loadingAction(false));
      });
  };

  useEffect(() => {
    getData(pageNumber, initBalancesItem.value);
  }, [pageNumber, dispatch]);

  const openAddHandler = () => {
    setOpenAddModal(true);
  };

  const handlePrint = () => {
    printHandler(
      `PrintInitBalances?companyID=${currentCompanyID}&searchString=${initBalancesItem.value}`,
      dispatch,
    );
  };

  return (
    <>
      <Header
        pageTitle="Իմ հաշիվների սկզբնական մնացորդները"
        handelClick={openAddHandler}
      />

      <SearchBackground>
        <div className="G-flex">
          {initialBalancesData?.initBalances?.length > 0 && (
            <FilteredBalanceData
              getData={getData}
              pageNumber={pageNumber}
              currentCompanyID={currentCompanyID}
              initBalancesItem={initBalancesItem}
              setInitBalancesItem={setInitBalancesItem}
              setPageNumber={setPageNumber}
            />
          )}
          <div className="G-normal-btn">
            <Button onClick={handlePrint} text={viewPrintTitle} />
          </div>
        </div>
      </SearchBackground>
      <InitialBalancesTable
        mainData={initialBalancesData?.initBalances}
        loading={isLoading}
      />
      <Pagination
        pageCount={initialBalancesData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />

      {openAddModal && (
        <EventAddForm
          closeModal={setOpenAddModal}
          getData={getData}
          pageNumber={pageNumber}
        />
      )}
    </>
  );
};

export default GetInitialBalancesData;
