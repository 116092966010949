import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { putRequest } from '../../Api/Api';
import Button from '../../Components/Button/Button';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import InputFiled from '../../Components/InputFiled/InputFiled';
import Modal from '../../Components/Modal/Modal';
import { register } from '../../Utilities/Utilities';
import { firstLetterString } from '../../Validation/REG_EXP';
import { updateUser } from '../../Redux/authSlices/useAction';

const CurrentEmailFiled = ({ closeModal, setErrorHandler, setSentEmail }) => {
  const handleClose = () => closeModal(false);
  const { userID, email } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    initialValues: {
      email: email,
      userID: userID,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .matches(firstLetterString, 'Մուտքագրեք տառեր')
        .matches(/^[~`_@&;':",.a-zA-Z0-9-]+$/, 'Մուտքագրեք միայն լատինատառ')
        .email('Անվավեր էլեկտրոնային հասցե')
        .required('Անհրաժեշտ է լրացնել «Էլ. հասցե» դաշտը'),
    }),
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const onSubmit = async (values) => {
    try {
      const res = await putRequest(`editUserEmail`, values);
      if (res.data.errorCode === 0) {
        dispatch(
          updateUser({
            email: res.data.message,
          }),
        );
        setSentEmail(values.email);
        localStorage.removeItem('persist:root');
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal customClass="L-change-password-wrapper" closeHandler={handleClose}>
        <form onSubmit={onSubmit}>
          <div className="L-change-password-input">
            <InputFiled
              inputTitle="Մուտքանուն"
              name="email"
              value={values.email}
              changeHandler={handleChange}
              onBlur={handleBlur}
            />
            {touched.email && errors.email && (
              <ErrorFiled error={errors.email} />
            )}
          </div>
          <div className="G-flex-justify-end">
            <div className="G-register-btn">
              <Button
                text={register}
                customClass={!(isValid && dirty) ? 'G-disabled-button' : ''}
                disabled={!(isValid && dirty)}
                onClick={handleSubmit}

                // executeDisabled={!selectedFile || isSubmitting}
                // confirmExecuteText={!isSubmitting ? 'Հաստատել' : <Spinner />}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CurrentEmailFiled;
