import React, { useEffect } from 'react';
import useProvisions from '../../hooks/useProvisions';
import Header from '../../../App/Components/Header/Header';
import ScaleOfStampDutyTableWrap from './ScaleOfStampDutyTable';
import ScaleOfSocialPaymentTableWrap from './ScaleOfSocialPaymentTable';
import IncomeTaxScaleTableWrap from './IncomeTaxScaleTable';
import './style.scss';
import ProvisionsList from './ProvisionsList';

const Provisions = () => {
  const { provisions, getProvisions } = useProvisions();
  const {
    pensionScale,
    incomeTaxScale,
    stampScale,
    settings,
    startDateIncomeTax,
    startDatePension,
    startDateStamp,
  } = provisions || {};
  useEffect(() => {
    getProvisions();
  }, []);

  return (
    <>
      <Header headerBtn={false} pageTitle="" />

      <div className="provisions-group">
        <ScaleOfSocialPaymentTableWrap
          mainData={pensionScale}
          title="Սոցիալական վճարի սանդղակ"
          date={startDatePension}
        />
        <IncomeTaxScaleTableWrap
          mainData={incomeTaxScale}
          title="Եկամտային հարկի սանդղակ"
          date={startDateIncomeTax}
        />

        <ScaleOfStampDutyTableWrap
          mainData={stampScale}
          title="Դրոշմանիշային վճարի սանդղակ"
          date={startDateStamp}
        />
        <ProvisionsList provisions={settings} />
      </div>
    </>
  );
};

export default Provisions;
