import React, { useEffect, useState } from 'react';
import SavingsTable from './SavingsTable';
import useSavingsForm from '../../hooks/useSavingsForm';
import Header from '../../../App/Components/Header/Header';
import ExcelForm from './ExcelForm';
import EventAddForm from './EventAddForm';
import './index.scss';
import { ConvertSavingExcel } from '../../Config/excelConfig';
const Savings = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const { savingData, fetchSavingsData } = useSavingsForm();
  const onClose = () => {
    setShowAddForm((prevState) => !prevState);
  };
  useEffect(() => {
    fetchSavingsData();
  }, []);

  const dataToExcel = ConvertSavingExcel(savingData?.dedTypes);

  return (
    <div className="">
      <Header pageTitle="Պահումների տեսակներ" handelClick={onClose} />
      <ExcelForm dataToExcel={dataToExcel} />
      <SavingsTable mainData={savingData?.dedTypes} />
      {showAddForm && <EventAddForm handleClose={onClose} />}
    </div>
  );
};

export default Savings;
