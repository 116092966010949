import React, { useEffect } from 'react';
import EventEditForm from './EventEditForm';
import EventRemove from './EventRemove';
import EntryOrderOperations from '../entryOrderOperations/EntryOrderOperations';
import EntryOrderOperationExecution from '../entryOrderOperationExecution/EntryOrderOperationExecution';
import { printHandler } from '../../../App/Utilities/Utilities';
import { useDispatch } from 'react-redux';

const Forms = ({ formType, closeModal, params }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (type === 'print') {
      const url = `printMatInput?MatInputDocID=${value?.matInputDocID}`;
      printHandler(url, dispatch);
      closeModal(false);
    }
  }, [type, value, dispatch, closeModal]);

  switch (type) {
    case 'delete':
      return (
        <EventRemove
          matInputDocID={value?.matInputDocID}
          handleClose={handleClose}
        />
      );
    case 'edit':
      return (
        <EventEditForm
          rowData={value}
          handleClose={handleClose}
          params={params}
        />
      );

    case 'operations':
      return (
        <EntryOrderOperations
          matInputDocID={value?.matInputDocID}
          handleClose={handleClose}
        />
      );

    case 'operationExecution':
      return (
        <EntryOrderOperationExecution
          matInputDocID={value?.matInputDocID}
          handleClose={handleClose}
        />
      );

    case 'print':
      return null;

    default:
      return null;
  }
};

export default Forms;
