import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRequest, getRequest } from '../../Api/Api';
import { deleteOperationsAction } from '../../Redux/operationsSlices/useAction';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { useState } from 'react';
import { deleteAgreeText } from '../../Utilities/Utilities';

const DeleteOperations = ({ selectRowID, closeModal, setErrorHandler }) => {
  const [isAllowDelete, setIsAllowDelete] = useState(false);
  const { entryID, docTypeID } = selectRowID;
  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAllowDelete() {
      try {
        if (entryID) {
          const url = `PreDeleteEditEntry?entryID=${entryID}`;
          const res = await getRequest(url);
          setIsAllowDelete(res.data.errorCode === 100);
        }
      } catch (err) {
        console.error('Error checking allow delete:', err);
      }
    }

    checkAllowDelete();
  }, [entryID]);

  const deleteRowDate = () => {
    deleteRequest(`deleteEntry/${entryID}`).then((res) => {
      if (res.data.errorCode === 0) {
        dispatch(deleteOperationsAction(entryID));
        handleClose();
      } else if (res.data.errorCode > 0) {
        setErrorHandler(res.data.message);
      }
    });
  };

  const deleteText =
    docTypeID === 10
      ? 'Հեռացնե՞լ բոլոր փակված հաշիվները'
      : 'Համոզվա՞ծ եք որ ուզում եք ջնջել այն';

  return (
    <AcceptOrCancelModal
      modalContent={isAllowDelete ? deleteAgreeText : deleteText}
      closeModal={closeModal}
      confirmClick={deleteRowDate}
    />
  );
};

export default DeleteOperations;
