import React, { useEffect, useState } from 'react';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import './style.scss';

const WorkGuide = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    getRequest(`getHelpContent`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  }, []);

  const onClick = ({ fileName, helpContent }) => {
    if (fileName.trim()) {
      const splitName = fileName?.split('.')[0];
      localStorage.setItem('initialName', splitName);
      localStorage.setItem('documentTitle', helpContent);
    }
  };

  return (
    <>
      <Header pageTitle="Աշխատանքի ուղեցույց" headerBtn={false} />
      <div className="work-guide-title-list">
        {data?.map((el) => {
          return (
            <ul key={el.helpContentID}>
              {el.fileName.includes('.pdf') ? null : <h3>{el.fileName}</h3>}
              {el.fileName.includes('.pdf') ? (
                <li onClick={() => onClick(el)}>
                  <a href="/HelpDetails" target="_blank">
                    {el.helpContent}
                  </a>
                </li>
              ) : null}
            </ul>
          );
        })}
      </div>
    </>
  );
};

export default WorkGuide;
