import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';

export const fetchNonWorkingDays = async (companyID) => {
  try {
    const response = await getRequest(
      `getNonWorkingDays?companyID=${companyID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
export const fetchNewNonWorkingDays = async (companyID) => {
  try {
    const response = await getRequest(
      `updateNonWorkingDays?companyID=${companyID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addNewNonWorkingDay = async (newWorkDay) => {
  try {
    const response = await postRequest('addNonWorkingDay', newWorkDay);

    return response.data;
  } catch (error) {
    console.error('Error adding workDay:', error);
    throw error;
  }
};

export const fetchNonWorkingDayForm = async (companyID, nwDayID) => {
  try {
    const response = await getRequest(
      `getNonWorkingDay?companyID=${companyID}&nwDayID=${nwDayID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const updateNonWorkingDays = async (newNonWorkingDays) => {
  try {
    const response = await putRequest(`editNonWorkingDay `, newNonWorkingDays);
    return response.data;
  } catch (error) {
    console.error('Error updating subdivision:', error);
    throw error;
  }
};

export const deleteNonWorkingDays = async (nwDayID) => {
  try {
    const response = await deleteRequest(`deleteNonWorkingDay/${nwDayID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting deleteNonWorkingDays:', error);
    throw error;
  }
};
