import React from 'react';
import './Modal.scss';
import CloseIcon from '../CloseIcon/CloseIcon';

const Modal = ({
  children,
  customClass = '',
  closeModal,
  showCloseIcon = true,
}) => {
  const handleClose = () => closeModal(false);
  return (
    <div className={`modal-block ${customClass}`}>
      {showCloseIcon && <CloseIcon onClick={handleClose} />}
      {children}
    </div>
  );
};

export default Modal;
