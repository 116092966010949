import React from 'react';
import DateComponent from '../../../components/dateComponent/DateComponent';
import { dateFormat } from '../../../../App/Utilities/Utilities';

const EmployeesCountDate = ({ employeesCount, salaryDate, onDateChange }) => {
  return (
    <div className="L-employees-count-date">
      <DateComponent
        value={dateFormat(salaryDate)}
        onChange={(date) => onDateChange(date)}
        name="name"
        title=""
        openToDate={new Date(salaryDate)}
        requiredFiled={false}
      />

      <div className="L-employees-count">
        <p>Թվաքանակը</p>
        <strong>{employeesCount}</strong>
      </div>
    </div>
  );
};

export default EmployeesCountDate;
