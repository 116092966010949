import React, { useEffect, useState } from 'react';
import {
  executeMakeAnAddition,
  fetchMakeAnAddition,
  saveMakeAnAddition,
} from '../services/fetchMakeAnAddition';
import { useSelector } from 'react-redux';

const useMakeAnAddition = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [makeAnAddition, setMakeAnAddition] = useState([]);
  const [makeAnAdditionData, setMakeAnAdditionData] = useState([]);
  const getMakeAnAddition = async () => {
    setIsLoading(true);
    try {
      const data = await fetchMakeAnAddition(currentCompanyID);
      setMakeAnAddition(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMakeAnAdditionExecute = async (query) => {
    setIsLoading(true);
    try {
      const data = await executeMakeAnAddition(currentCompanyID, query);

      setMakeAnAdditionData(data);
    } catch (error) {
      console.log('error', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveNewMakeAnAddition = async (updateData) => {
    try {
      const { errorCode, message } = await saveMakeAnAddition(updateData);
      if (errorCode === 0) {
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
    };
  }, []);

  return {
    isLoading,
    error,
    errorMess,
    getMakeAnAddition,
    makeAnAddition,
    getMakeAnAdditionExecute,
    makeAnAdditionData,
    setMakeAnAdditionData,
    saveNewMakeAnAddition,
  };
};

export default useMakeAnAddition;
