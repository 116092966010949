import * as Yup from 'yup';
import { InputFiledMessage, SelectFieldMessage } from './ErrorMessage';

export const MaterialValueNameValidation = () =>
  Yup.object().shape({
    matValueNo: Yup.string()
      .matches(/[0-9]/, 'Պետք է լինի թիվ')
      .required(InputFiledMessage),
    groupName: Yup.string().required(SelectFieldMessage),
    matValueName: Yup.string().required(InputFiledMessage),
    unit: Yup.string().required(SelectFieldMessage),
    method: Yup.string().required('a'),
  });
