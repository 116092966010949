import React from 'react';
import SavingForm from './SavingForm';
import useSavingsForm from '../../hooks/useSavingsForm';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';

const EventAddForm = ({ handleClose }) => {
  const { addSaving, errorMess, setErrorMess } = useSavingsForm();
  const isMounted = useIsMounted();
  const onAddHandler = async (values, resetForm) => {
    try {
      await addSaving(values, handleClose);
      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (error) {}
  };

  return (
    <>
      <SavingForm onSubmit={onAddHandler} handleClose={handleClose} />;
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
