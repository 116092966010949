import React from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { makeAnAdditionFields } from './MakeAnAdditionFields';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import DateComponent from '../../components/dateComponent/DateComponent';
import Button from '../../../App/Components/Button/Button';
import {
  dataTimeFormat,
  dateFormat,
  executeText,
} from '../../../App/Utilities/Utilities';
import Input from '../../Input/Input';
import useSalaryTypeCoefficient from '../../hooks/useSalaryTypeCoefficient';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';

const MakeAdditionalSaveAction = ({
  makeAdditionalData,
  query,
  setQuery,
  onExecuteHandler,
  isDisabledBtn,
  setAdditionalSaveData,
}) => {
  let dropDownOptions = [];
  const { getSalaryTypeCoefficient } = useSalaryTypeCoefficient();
  const resetMakeAnAdditionData = () => {
    setAdditionalSaveData(null);
  };
  const handleDropdownChange = (fieldName, selectedOption, dropdownId) => {
    const option = selectedOption || { value: '', id: null };
    resetMakeAnAdditionData();
    if (option.id !== 5) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        coeff: '',
      }));
    }
    if (fieldName === 'stName' && !option.value) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        formulaName: '',
        formulaID: '',
      }));
    }
    if (fieldName === 'formulaName') {
      const param = {
        stNo: query.stNo,
        formulaID: option.id,
      };

      getSalaryTypeCoefficient(param).then((res) => {
        setQuery((prevQuery) => ({
          ...prevQuery,
          coeff: res,
        }));
      });
    }

    if (option.coeff >= 0) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        coeff: option.coeff,
        formulaName: option.formulaName,
        formulaID: option.formulaID,
      }));
    }
    setQuery((prevQuery) => ({
      ...prevQuery,
      [fieldName]: option.value,
      [dropdownId]: option.id,
    }));
  };

  const onDateChange = (date, fieldName) => {
    resetMakeAnAdditionData();
    setQuery((prevQuery) => ({
      ...prevQuery,
      [fieldName]: dataTimeFormat(date),
    }));
  };
  const onInputChange = (e) => {
    resetMakeAnAdditionData();
    const { name, value } = e.target;

    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: name === 'amount' ? parseFloat(value.replace(/,/g, '')) : value,
    }));
  };

  return (
    <SearchBackground>
      <div className="L-make-additional-save-action confirm-action-wrap-flex">
        <div className="confirm-action-wrap">
          {makeAnAdditionFields?.map((field) => {
            const {
              fieldType,
              fieldName,
              required,
              Children,
              optionName,
              dropdownTitle,
              dateName,
              dateTitle,
              dropdownId,
              maxDate,
              name,
              label,
              title,
              disabled,
            } = field;

            if (typeof Children === 'function') {
              if (makeAdditionalData) {
                dropDownOptions = Children(makeAdditionalData[optionName]);
              }
            }

            switch (fieldType) {
              case 'dropdown':
                return (
                  <SelectControl
                    value={{ label: query[fieldName] }}
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={required}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      handleDropdownChange(
                        fieldName,
                        selectedOption,
                        dropdownId,
                      )
                    }
                  />
                );
              case 'date':
                return (
                  <DateComponent
                    value={dateFormat(query[dateName])}
                    onChange={(date) => onDateChange(date, dateName)}
                    name={dateName}
                    title={dateTitle}
                    requiredFiled={required}
                    maxDate={maxDate}
                  />
                );
              case 'input':
                return (
                  <div key={name} className="input-container">
                    {query.formulaID === 5 ? (
                      <NumericFormatFiled
                        value={query.coeff}
                        allowNegative={true}
                        name="coeff"
                        changeHandler={(e) => onInputChange(e)}
                        inputTitle="Գումար"
                        disabled={disabled(query)}
                      />
                    ) : (
                      <Input
                        inputTitle={label}
                        name={name}
                        requiredFiled={required}
                        onChange={(e) => onInputChange(e)}
                        value={query[name]}
                        pattern="[0-9.,]*"
                        disabled={disabled(query)}
                      />
                    )}
                  </div>
                );

              default:
                return null;
            }
          })}
        </div>
        <div className="G-add-btn">
          <Button
            type="button"
            text={executeText}
            onClick={onExecuteHandler}
            // disabled={isDisabledBtn}
            // customClass={isDisabledBtn && 'G-disabled-button'}
          />
        </div>
      </div>
    </SearchBackground>
  );
};

export default MakeAdditionalSaveAction;
