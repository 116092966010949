import React from 'react';
import { SelectControl } from '../SelectControl/SelectControl';
import { useMemo } from 'react';

const SelectUnitsOfMeasurement = ({
  value,
  options,
  onChange,
  title = 'Չափման միավորը',
  requiredFiled = true,
}) => {
  const unitOptions = useMemo(() => {
    return options?.map(({ unitName, unitID, unitCode }) => {
      return {
        label: unitCode,
        value: unitName,
        id: unitID,
      };
    });
  }, [options]);

  return (
    <SelectControl
      matchFromParam="start"
      selectBoxTitle={title}
      requiredFiled={requiredFiled}
      id="unit"
      options={unitOptions}
      value={value}
      onChange={onChange}

    />
  );
};

export default SelectUnitsOfMeasurement;
