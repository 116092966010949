import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import GeneralPartWarehouseEntrance from './GeneralPartWarehouseEntrance';
import ProductTable from './ProductTable';
import Modal from '../../../App/Components/Modal/Modal';
import Button from '../../../App/Components/Button/Button';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import EventAddForm from './warehouseEntryProduct/EventAddForm';
import useWarehouseEntry from '../../hooks/useWarehouseEntry';
import useUpdateSignChecker from '../../hooks/useUpdateSignChecker';
import { generalWarehouseEntryV } from '../../../App/Validation/WarehouseEntryValidation';
import Alert from '../../../App/Components/Alert';

const WarehouseEntryForm = ({
  handleClose,
  matInputDocID = 0,
  excelInvoiceID = 0,
  partnerID = 0,
  onSubmit,
}) => {
  const {
    getWarehouseEntryForm,
    warehouseEntryForm,
    getWarehouseEntryDocNum,
    isTableUpdate,
    getInvoiceEntryForm,
    errorMess,
    setErrorMess,
  } = useWarehouseEntry();

  const [showProductForm, setShowProductForm] = useState(false);

  const { checkUpdateSign } = useUpdateSignChecker();

  const {
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: { ...warehouseEntryForm?.matInput },
    validationSchema: generalWarehouseEntryV,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
    },
  });

  useEffect(() => {
    if (partnerID && excelInvoiceID) {
      getInvoiceEntryForm(partnerID, matInputDocID);
    } else {
      getWarehouseEntryForm(matInputDocID);
    }
  }, [partnerID, excelInvoiceID, matInputDocID]);

  const handleProductFormToggle = () => {
    const isAllowed = checkUpdateSign(
      warehouseEntryForm?.updateSign,
      errorMess,
      setErrorMess,
    );
    if (isAllowed) {
      setShowProductForm((prev) => !prev);
    }
  };

  const isProductFormDisabled =
    values.docTypeID === 2 ? values.inputTypeName : values.storeName;

  const commonProps = {
    warehouseEntryForm,
    getWarehouseEntryDocNum,
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    handleClose,
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-warehouse-entry-form"
    >
      <GeneralPartWarehouseEntrance {...commonProps} />

      <div className="L-product-add-btn">
        <Button
          disabled={
            !isProductFormDisabled || (values.ident && !values.partnerID)
          }
          customClass={
            !isProductFormDisabled || !(values.ident && values.partnerID)
              ? 'G-disabled-button'
              : ''
          }
          onClick={handleProductFormToggle}
        />
      </div>

      <ProductTable
        productData={warehouseEntryForm?.inputs}
        updateSign={warehouseEntryForm?.updateSign}
        errorMess={errorMess}
        setErrorMess={setErrorMess}
      />

      <GetSentDataBtn
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleSubmit}
        executeClass={
          (!dirty || !isValid) && !isTableUpdate ? 'G-disabled-button' : ''
        }
        executeDisabled={(!dirty || !isValid) && !isTableUpdate}
      />

      {showProductForm && (
        <EventAddForm
          handleClose={handleProductFormToggle}
          warehouseEntryForm={warehouseEntryForm}
        />
      )}
      <Alert description={errorMess} onClose={setErrorMess} />
    </Modal>
  );
};

export default WarehouseEntryForm;
