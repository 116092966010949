import React from 'react';
import Button from '../../../App/Components/Button/Button';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';

const UpdateNonWorkingDays = ({ onUpdateHandler }) => {
  return (
    <SearchBackground>
      <Button onClick={onUpdateHandler} text="Թարմացնել" />
    </SearchBackground>
  );
};

export default UpdateNonWorkingDays;
