import React from "react";
import { Commas, dateFormat } from "../../Utilities/Utilities";
import Table from "../../Components/Table/Table";
import Tooltip from "../../Components/Tooltip/Tooltip";
import Loading from "../../Components/Loading/Loading";
import { DocumentN, DocumentNumber } from '../../GlobalTitle/GlobalTitle';

const DocumentTable = ({ mainData, loading }) => {
  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: <Tooltip content={DocumentNumber}>{DocumentN}</Tooltip>,
      cell: (row) => row.docNum,
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Տեսակը',
      cell: (row) => row.docTypeName,
      customStyle: { maxWidth: 280 },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 630 },
    },
    {
      title: 'Արժույթ',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 60 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 160 },
    },
    {
      title: 'Հիմքը',
      cell: (row) => row.docBase,
      customStyle: { maxWidth: 320 },
    },
    {
      title: <Tooltip content="Հաշվարկային հաշիվ">ՀՀ</Tooltip>,
      cell: (row) => row.companyAccount,
      customStyle: { maxWidth: 170 },
    },
    {
      title: <Tooltip content="Գործընկերոջ հ/հաշիվ">ԳՀՀ</Tooltip>,
      cell: (row) => row.partnerAccount,
      customStyle: { maxWidth: 170 },
    },
  ];

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-document-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default DocumentTable;
