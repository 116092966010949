import React from 'react';
import useTables from '../../hooks/useTables';
import TablesForm from './TablesForm';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
const EventAddForm = ({ handleClose }) => {
  const { addTableItem, errorMess, setErrorMess } = useTables();
  const isMounted = useIsMounted();
  const onAddHandler = async (values, resetForm) => {
    const val = {
      ...values,
      sign56: Number(values.sign56),
      hours: Number(values.hours),
      graphNo: Number(values.graphNo),
    };
    try {
      await addTableItem(val, handleClose);
      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (error) {}
  };

  return (
    <>
      <TablesForm onSubmit={onAddHandler} handleClose={handleClose} />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
