import React from "react";
import Loading from "../../Components/Loading/Loading";
import Table from "../../Components/Table/Table";
import Checkbox from "../../Components/Checkbox/Checkbox";

const PartnersAccountStatementTable = ({
  mainData,
  loading,
  setPartnerAccountStatement,
}) => {
  const columnConfig = [
    {
      title: 'Գործընկերներ',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 1650, overflow: 'hidden' },
    },

    {
      title: (
        <span className="G-flex">
          Նշել բոլորը
          <Checkbox onChange={(e) => selectAllPrintSign(e)} />
        </span>
      ),
      cell: (row) => (
        <Checkbox
          checked={row.printSign}
          value={row}
          onChange={(e) => handelCheckedPrintSign(e, row)}
        />
      ),
      customStyle: { maxWidth: 140 },
    },
  ];

  const selectAllPrintSign = (e) => {
    const checked = e.target.checked;
    const checkedAll = mainData?.partners?.map((item) => {
      return (item.printSign = checked);
    });
    setPartnerAccountStatement((prev) => ({ ...prev, checkedAll }));
  };

  const handelCheckedPrintSign = (e, row) => {
    const checkedSingle = mainData?.partners?.map((item) => {
      if (item.partnerID === row.partnerID) {
        return (item.printSign = !item.printSign);
      } else {
        return item;
      }
    });
    setPartnerAccountStatement((prev) => ({ ...prev, checkedSingle }));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.partners?.length ? (
        <Table
          customClass="G-table-has-scroll"
          data={mainData?.partners}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default PartnersAccountStatementTable;
