import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';

export const fetchWarehouse = async (currentCompanyID, param) => {
  try {
    const response = await getRequest(
      `getStores?companyID=${currentCompanyID}&searchString=${param}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchWarehouseForm = async (currentCompanyID, storeID) => {
  try {
    const response = await getRequest(
      `getStore?companyID=${currentCompanyID}&storeID=${storeID} `,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addWarehouse = async (newWarehouse) => {
  try {
    const response = await postRequest('addStore', newWarehouse);
    return response.data;
  } catch (error) {
    console.error('Error adding Warehouse:', error);
    throw error;
  }
};
export const updateWarehouse = async (updateWarehouse) => {
  try {
    const response = await putRequest('editStore', updateWarehouse);
    return response.data;
  } catch (error) {
    console.error('Error  update Warehouse:', error);
    throw error;
  }
};

export const removeWarehouse = async (prodGroupID) => {
  try {
    const response = await deleteRequest(`deleteStore/${prodGroupID}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting Warehouse:', error);
    throw error;
  }
};
