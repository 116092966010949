import { createAction } from '../../actionCreators';
import {
  FETCH_MATERIAL_VAL_E_INVOICING,
  UPDATE_MATERIAL_VAL_CODE,
} from './constants';

export const fetchMaterialEInvoicingAction = createAction(
  FETCH_MATERIAL_VAL_E_INVOICING,
);

export const updateMaterialValCodeAction = createAction(
  UPDATE_MATERIAL_VAL_CODE,
);
