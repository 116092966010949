import React from 'react';
import { dropdownConfigs } from './dropdownConfigs';
import { SelectControl } from '../../../../App/Components/SelectControl/SelectControl';

const CalculationPrintDropdown = ({
  calculationPrintData,
  query,
  setQuery,
}) => {
  const optionsData = calculationPrintData || [];

  const handleDropdownChange = async (selectedOption, config) => {
    const { optionName, optionID } = config || {};
    const option = selectedOption || { value: '', id: null };

    setQuery((prevQuery) => ({
      ...prevQuery,
      [optionName]: option.value,
      [optionID]: option.id,
    }));
  };

  return (
    <div className="L-calculation-dropdown-block G-justify-between-align-center">
      {dropdownConfigs?.map((config, index) => {
        if (config.type === 'dropdown') {
          const dropDownOptions = optionsData
            ? config.Children(optionsData[config.optionName])
            : [];

          return (
            <div className="L-calculation-dropdown-filed" key={index}>
              <SelectControl
                value={{ label: query[config.optionName] }}
                selectBoxTitle={config.title}
                options={dropDownOptions || []}
                onChange={(selectedOption) =>
                  handleDropdownChange(selectedOption, config)
                }
                requiredFiled={config.required}
              />
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

export default CalculationPrintDropdown;
