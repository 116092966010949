import React from 'react';
import { postRequest } from '../../Api/Api';
import ChartOfAccountForm from './ChartOfAccountForm';

const EventAddForm = ({
  modalClick,
  rowData,
  getData,
  pageNumber,
  searchValue,
  setErrorHandler,
}) => {
  let isAddedAccount =
    rowData?.accountCode === '253' || rowData?.accountCode === '2512'
      ? false
      : true;

  const handleClose = () => modalClick(false);
  const onSubmit = (values, resetForm) => {
    postRequest('addaccount', {
      account: { ...values },
      currencies: values?.currencies?.map((item) => {
        item['currencyCode'] = item['label'];
        delete item['value'];
        delete item['label'];
        return {
          ...item,
        };
      }),
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          getData(pageNumber, searchValue);
          resetForm();
          handleClose();
        }
        if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }

        if (res.data.errorCode === 110) {
          setErrorHandler('Գրանցման սխալ');
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <>
      <ChartOfAccountForm
        closeModal={handleClose}
        onSubmit={onSubmit}
        rowData={rowData}
        toglHandler={true}
        setErrorHandler={setErrorHandler}
        isAddedAccount={isAddedAccount}
      />
    </>
  );
};

export default EventAddForm;
