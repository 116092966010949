import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchSalaryCalculationDate,
  saveSalaryCalculationDate,
} from '../services/fetchSalaryCalculationDate';

const useSalaryCalculationDate = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [calculationDate, setCalculationDate] = useState(null);

  const getSalaryCalculationDate = async () => {
    setIsLoading(true);
    try {
      const data = await fetchSalaryCalculationDate(currentCompanyID);
      setCalculationDate(data);

      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveNewCalculationDate = async (newDate, closeFormCallback) => {
    try {
      const { errorCode, message } = await saveSalaryCalculationDate(newDate);

      if (errorCode === 0) {
        await getSalaryCalculationDate();

        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    getSalaryCalculationDate();

    return () => {
      setError(null);
      setErrorMess('');
      setCalculationDate(null);
      setIsLoading(false);
    };
  }, [currentCompanyID]);

  return {
    getSalaryCalculationDate,
    saveNewCalculationDate,
    error,
    errorMess,
    setErrorMess,
    calculationDate,
    isLoading,
  };
};

export default useSalaryCalculationDate;
