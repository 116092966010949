import React from 'react';
import { SelectControl } from '../../App/Components/SelectControl/SelectControl';
import { dayList } from '../ArrayList';

const ToRemindDay = ({ setFieldValue, value }) => {
  const onSelectDay = (selectedValue) => {
    if (!selectedValue) {
      selectedValue = { label: '' };
    }
    setFieldValue('delta', selectedValue.label);
  };

  return (
    <div className="to-remind-wrap G-flex">
      <span>Հիշեցնել</span>
      <SelectControl
        selectBoxTitle=""
        options={dayList}
        value={{ label: value, value: value }}
        onChange={(val) => onSelectDay(val)}
      />
      <span>օր առաջ</span>
    </div>
  );
};

export default ToRemindDay;
