import React from 'react';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
import MaterialGroupsForm from './MaterialGroupsForm';
import useMaterialValueGroups from '../../hooks/useMaterialValueGroups';

const EventEditForm = ({ handleClose, prodGroupID }) => {
  const { editMaterialGroup, errorMess, setErrorMess } =
    useMaterialValueGroups();
  const isMounted = useIsMounted();
  const onUpdateHandler = async (values) => {
    try {
      await editMaterialGroup(values, handleClose);
    } catch (err) {
      if (isMounted) {
        console.error('An error occurred:', err);
      }
    }
  };

  return (
    <>
      <MaterialGroupsForm
        prodGroupID={prodGroupID}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventEditForm;
