export const fieldConfigs = [
  {
    fieldType: 'input',
    title: 'Կազմակերպության անվանումը',
    name: 'companyName',
    type: 'text',
    maxLength: 44,
    requiredFiled: true,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Տեսակը',
    requiredFiled: true,
    fieldName: 'companyTypeName',
    dropdownId: 'companyTypeID',
    optionName: 'companyTypes',

    Children: (props) => {
      return props?.map((i) => ({
        label: i.companyTypeName,
        id: i.companyTypeID,
        value: i.companyTypeName,
      }));
    },
  },
  {
    fieldType: 'input',
    title: 'ՀՎՀՀ',
    name: 'taxCode',
    type: 'text',
    maxLength: 8,
    requiredFiled: true,
  },
  {
    fieldType: 'input',
    title: 'Քաղաք/համայնք',
    name: 'city',
    type: 'text',
    maxLength: 50,
  },
  {
    fieldType: 'input',
    title: 'Հասցե',
    name: 'address',
    type: 'text',
    maxLength: 50,
  },
  {
    fieldType: 'input',
    title: 'Գործունեության հասցեն',
    name: 'activityAddress',
    type: 'text',
    maxLength: 50,
  },
  {
    fieldType: 'input',
    title: 'Հեռախոս',
    name: 'phone',
    type: 'text',
    maxLength: 17,
  },
  {
    fieldType: 'input',
    title: 'Էլեկտրոնային հասցեն',
    name: 'email',
    type: 'text',
    maxLength: 28,
  },
  {
    fieldType: 'input',
    title: 'Ղեկավարի պաշտոնը',
    name: 'headPosition',
    type: 'text',
    maxLength: 25,
  },
  {
    fieldType: 'input',
    title: 'Ղեկավարի անուն,ազգանուն',
    name: 'headName',
    type: 'headName',
    maxLength: 36,
  },
  {
    fieldType: 'input',
    title: 'Հաշվապահի անուն,ազգանուն',
    name: 'accauntantName',
    type: 'text',
    maxLength: 36,
  },
  {
    fieldType: 'input',
    title: 'Նշումներ',
    name: 'description',
    type: 'text',
    maxLength: 80,
  },
  {
    fieldType: 'radio',
    radioTitle: 'ՆԱ Հաշվառման մեթոդը',
    name: 'method',
    radioBtn: [
      { label: 'ԱՄԱԵ(FIFO)', value: 1 },
      { label: 'Միջին կշռված արժեքով', value: 2 },
    ],
  },
  // {
  //   fieldType: 'checkbox',
  //   name: 'itSign',
  //   label: 'ՏՏ ոլորտ',
  //   value: '',
  //   required: false,
  // },
];
