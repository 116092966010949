import React from "react";
import DoubleArrow from "../../Assets/icons/doubleArrow.svg";
import Button from "../Button/Button";

const AdvancedSearchBtn = ({ handelClick, openid }) => {
  return (
    <div className="G-advance-search-btn" onClick={handelClick}>
      <Button text="Ընդլայնված որոնում" />
      <span className="L-icon">
        <img
          className={`${openid ? "G-icon-rotate" : ""}`}
          src={DoubleArrow}
          alt=""
        />
      </span>
    </div>
  );
};

export default AdvancedSearchBtn;
