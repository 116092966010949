import React from 'react';
import { useContext, useState } from 'react';
import { Context } from '../../../index.js';
import { update } from '../../http/aspectAPI';
import Checkbox from '../Checkbox/Checkbox';
import ItemImg from '../imgSvg/ItemImg';
import './index.scss';
import Modal from '../Modal/Modal.jsx';
import FormControl from '../FormControl/FormControl.jsx';
import Button from '../Button/Button.jsx';
import { useSelector } from 'react-redux';

const UpdateAspect = ({ showA, onHideA, aspectd }) => {
  const { userID } = useSelector((state) => state.auth);
  const { aspect } = useContext(Context);

  const [name, setName] = useState(aspectd.name);
  const [imgColor, setImgColor] = useState(aspectd.imgColor);
  const [imgPath, setImgPath] = useState(aspectd.imgPath);

  const [income, setIncome] = useState(aspectd.singE);
  const [cost, setCost] = useState(aspectd.singT);
  const [imgIcon, setImgIcon] = useState(aspectd.imgPath);

  const id = aspectd.id;
  const checkBoxHandler = (name, e) => {
    if (name === 'income') {
      setIncome(e.target.checked);
    }
    if (name === 'cost') {
      setCost(e.target.checked);
    }
  };

  const upAspect = async (e) => {
    e.preventDefault();
    const formData = {
      name: name,
      imgPath: imgPath,
      imgColor: imgColor,
      singE: income,
      singT: cost,
      id: id,
      userId: userID,
    };

    const data = await update(formData);
    if (data) {
      const searchIndex = aspect.Aspect.findIndex((asp) => asp.id === id);

      aspect.Aspect[searchIndex].name = name;
      aspect.Aspect[searchIndex].imgPath = imgPath;
      aspect.Aspect[searchIndex].imgColor = imgColor;
      aspect.Aspect[searchIndex].singT = cost;
      aspect.Aspect[searchIndex].singE = income;

      onHideA();
    }
  };

  return (
    <Modal customClass="update-aspect-modal" closeModal={onHideA}>
      <h3 className="enter-form-title"> Խմբագրել տիպ</h3>
      <form>
        <div className="update-aspect-content">
          <div className="select-category-flex">
            <ItemImg
              imgPath={imgPath}
              setImgPath={setImgPath}
              imgColor={imgColor}
              setImgColor={setImgColor}
              imgIcon={imgIcon}
              setImgIcon={setImgIcon}
            />
          </div>
          <FormControl
            placeholder="Մուտքագրեք տիպի անվանումը"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={true}
          />

          {/* <div className="checkbox-flex">
            <Checkbox
              label="Ծախս"
              value={cost}
              checked={cost}
              name="cost"
              onChange={(val) => checkBoxHandler('cost', val)}
            />

            <Checkbox
              label="Եկամուտ"
              value={income}
              checked={income}
              name="income"
              onChange={(val) => checkBoxHandler('income', val)}
            />
          </div> */}

          <Button onClick={upAspect} text="Հաստատել" />
        </div>
      </form>
    </Modal>
  );
};
export default UpdateAspect;
