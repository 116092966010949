import React, { useEffect, useState } from 'react';
import SalaryCalcTab from './SalaryCalcTab';

import useSalaryCalc from '../../../hooks/useSalaryCalc';
import SalaryCalcCheckedBtn from './SalaryCalcCheckedBtn';
import SalaryDisplay from './SalaryDisplay';
import './salaryCalc.scss';
import SalaryAmount from './SalaryAmount';
import { removeCommas } from '../../../../App/Utilities/Utilities';
import { useDebounce } from '../../../../App/Hooks/useDebounce';
import { getDisplayData } from './displayDataConfig';
const SalaryCalculator = () => {
  const [formData, setFormData] = useState({
    calcType: 1,
    itSign: false,
    socialSign: false,
    amount: '',
  });
  const { calculateSalary, salaryCalcData } = useSalaryCalc();

  const handleTabChange = (activeTab) => {
    setFormData((prevState) => ({
      ...prevState,
      calcType: Number(activeTab),
    }));
  };
  const debouncedSearchTerm = useDebounce(formData?.amount, 1000);

  useEffect(() => {
    if (debouncedSearchTerm > 0) {
      const fetchData = async () => {
        await calculateSalary(formData);
      };
      fetchData();
    }
  }, [debouncedSearchTerm, formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : removeCommas(value),
    }));
  };

  const displayData = getDisplayData(salaryCalcData, formData?.calcType);
  return (
    <div className="L-salary-display-container">
      <h3 className="G-salary-title">Աշխատավարձի հաշվիչ</h3>
      <SalaryCalcTab
        activeTab={formData?.calcType}
        handleTabChange={handleTabChange}
      />
      <SalaryCalcCheckedBtn
        formData={formData}
        handleOptionChange={handleInputChange}
      />
      <SalaryAmount handleInputChange={handleInputChange} formData={formData} />
      <SalaryDisplay displayData={displayData} />
    </div>
  );
};

export default SalaryCalculator;
