import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Modal from '../../../App/Components/Modal/Modal';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import useNonWorkingDays from '../../hooks/useNonWorkingDays';
import RadioButton from '../../../App/Components/RadioButton/RadioButton';
import DateComponent from '../../components/dateComponent/DateComponent';
import InputTitle from '../../Input/InputTitle';
import { nonWorkingDaysFields } from './FieldsConfig';
import useIsMounted from '../../hooks/useIsMounted';
import { dataTimeFormat, dateFormat } from '../../../App/Utilities/Utilities';

const NonWorkingDayForm = ({ handleClose, onSubmit, nwDayID = 0 }) => {
  const { getNonWorkingDaysFormData, nonWorkingDayForm } = useNonWorkingDays();
  const isMounted = useIsMounted();
  useEffect(() => {
    getNonWorkingDaysFormData(nwDayID);
  }, [nwDayID]);

  const { handleSubmit, values, isValid, dirty, setFieldValue } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...nonWorkingDayForm,
    },
    // validationSchema: PositionValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
      if (isMounted) {
        resetForm();
      }
    },
  });
  const onChangeDate = (date, name) => {
    setFieldValue(name, dataTimeFormat(date));
  };

  const onChangeRadio = (name, value, label) => {
    setFieldValue(name, value);
    setFieldValue('nwTypeName', label);
  };
  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-non-working-days-modal"
    >
      <form onSubmit={onSubmit}>
        <div className="L-non-working-days-modal-content">
          {nonWorkingDaysFields?.map((field, index) => {
            const {
              fieldType,
              required,
              radioBtn,
              radioTitle,
              dateName,
              dateTitle,
              maxDate,
            } = field;

            switch (fieldType) {
              case 'date':
                return (
                  <div className="date-block" key={`date-${index}`}>
                    <DateComponent
                      value={dateFormat(values[dateName])}
                      onChange={(date) => onChangeDate(date, dateName)}
                      name={dateName}
                      title={dateTitle}
                      requiredFiled={required}
                      maxDate={maxDate}
                      openToDate={new Date(values[dateName])}
                    />
                  </div>
                );

              case 'radio':
                return (
                  <div className="G-s-input-wrap" key={`radio-${index}`}>
                    <InputTitle
                      requiredFiled={required}
                      inputTitle={radioTitle}
                    />
                    <div className="employees-radio-flex">
                      {radioBtn?.map(({ value, name, label }, index) => (
                        <div
                          className="L-radio-btn-b"
                          key={`radio-btn-${index}`}
                        >
                          <RadioButton
                            name={name}
                            value={value}
                            checked={value === values[name]}
                            onChange={() => onChangeRadio(name, value, label)}
                            label={label}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                );

              default:
                return null;
            }
          })}
        </div>
        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          // executeClass={!isValid ? 'G-disabled-button' : ''}
          // executeDisabled={!isValid}
        />
      </form>
    </Modal>
  );
};

export default NonWorkingDayForm;
