import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, postRequest } from '../../Api/Api';
import { getInvoiceFormAction } from '../../Redux/invoiceSlices/useAction';
import { dataTimeFormat } from '../../Utilities/Utilities';
import InvoiceForm from './InvoiceForm';

const AddInvoiceWrapper = ({
  closeModal,
  partnerID,
  getData,
  pageNumber,
  setErrorHandler,
}) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { invoiceFormData } = useSelector((state) => state.getInvoiceData);
  const { invoice, invoiceGoods } = invoiceFormData || {};

  const handleClose = () => closeModal(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getRequest(
          `getInvoiceGoods?companyID=${currentCompanyID}&partnerID=${partnerID}`,
        );
        dispatch(getInvoiceFormAction(res.data));
      } catch (err) {
        console.error(`Error fetching data: ${err}`);
      }
    };

    fetchData();
  }, [currentCompanyID, dispatch, partnerID]);

  const cloneInvoice = useMemo(
    () => ({
      companyID: currentCompanyID,
      partnerID: partnerID,
      currencyID: invoice?.currencyID,
      invoiceDate: dataTimeFormat(invoice?.invoiceDate),
      serialNumber: invoice?.serialNumber,
      exchangeRate: Number(invoice?.exchangeRate),
      companyBankingAccount: invoice?.supplierBankAccount,
      companyBankName: invoice?.supplierBankName,
      partnerBankingAccount: invoice?.buyerBankAccount,
      partnerBankName: invoice?.buyerBankName,
      prePayment: Number(invoice?.prePayment),
      prePaymentAMD: Number(invoice?.prePayment),
    }),
    [currentCompanyID, partnerID, invoice],
  );

  const addInvoiceData = () => {
    postRequest('addInvoice', {
      invoice: {
        ...cloneInvoice,
      },
      invoiceGoods: [...invoiceGoods],
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          handleClose();
          getData(pageNumber);
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <InvoiceForm
      closeModal={handleClose}
      registerInvoiceData={addInvoiceData}
      partnerID={partnerID}
      cloneInvoice={cloneInvoice}
    />
  );
};

export default AddInvoiceWrapper;
