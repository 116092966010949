import React from 'react';
import './formControl.scss';

const FormControl = ({
  onChange,
  name,
  value,
  placeholder,
  title = '',
  required = false,
  maxLength,
  disabled,
}) => {
  return (
    <div className="form-control">
      {title ? <p>{title}</p> : null}
      {/* {required && <span className="G-asterisk">*</span>} */}
      <label>
        <input
          type="text"
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
          maxLength={maxLength}
          disabled={disabled}
        />
      </label>
    </div>
  );
};

export default FormControl;
