import React, { useState } from "react";
import Action from "../../../Components/Table/Action";
import Table from "../../../Components/Table/Table";
import { editDeleteList } from "../../../OptionsList/OptionsList";
import {
  dateFormat,
  formatNumberWithCommas,
  tableActionTitle,
} from '../../../Utilities/Utilities';
import DeletePartnerBalance from './DeletePartnerBalance';
import EventEditForm from './EventEditForm';

const PartnerBalanceTable = ({ mainData }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handelChange = (value, row) => {
    switch (value) {
      case 'edit':
        return openEditForm(row);
      case 'delete':
        return openDeleteForm(row);
      default:
        return null;
    }
  };
  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.balanceDate),
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountCode,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Արժույթ',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Դեբետ',
      cell: (row) => formatNumberWithCommas(row.amountDebit),
      customStyle: { maxWidth: 180 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => formatNumberWithCommas(row.amountCredit),
      customStyle: { maxWidth: 180 },
    },
    {
      title: 'Դեբետ AMD',
      cell: (row) => formatNumberWithCommas(row.amountDebitAMD),
      customStyle: { maxWidth: 180 },
    },
    {
      title: 'Կրեդիտ AMD',
      cell: (row) => formatNumberWithCommas(row.amountCreditAMD),
      customStyle: { maxWidth: 180 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={handelChange}
          actionItem={editDeleteList}
          modalIsOpen={openEditModal || openDeleteModal}
        />
      ),
      customStyle: { maxWidth: 120 },
    },
  ];

  const openEditForm = (row) => {
    setOpenEditModal(row);
  };

  const openDeleteForm = (row) => {
    setOpenDeleteModal(row);
  };

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-partner-balance-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {openDeleteModal && (
        <DeletePartnerBalance
          rowData={openDeleteModal}
          closeModal={setOpenDeleteModal}
        />
      )}

      {openEditModal && (
        <EventEditForm closeModal={setOpenEditModal} rowData={openEditModal} />
      )}
    </>
  );
};

export default PartnerBalanceTable;
