import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

const Clock = ({ setFieldValue, value }) => {

  const [val, setVal] = useState(new Date());

  useEffect(() => {
    if (typeof value === 'string') {
      const [hours, minutes] = value?.split(':').map(Number);
      const newDate = new Date();
      newDate.setHours(hours, minutes);
      setVal(newDate);
    }
  }, [value]);

  const onSelectTime = (val) => {
    setVal(val);

    if (typeof value === 'string') {
      const hours = val.getHours();
      const minutes = val.getMinutes();
      const formattedTime = `${hours}:${minutes}`;
      setFieldValue('time', formattedTime);
    }
  };

  return (
    <div className="clock-wrap">
      <DatePicker
        selected={val}
        onChange={(date) => onSelectTime(date)}
        showTimeSelect
        timeFormat="HH:mm"
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Ժամ"
        dateFormat="HH:mm"
      />
    </div>
  );
};

export default Clock;
