export const AssignAdditionalFields = [
  {
    fieldType: 'input',
    type: 'text',
    name: 'setOrder',
    label: 'Հրաման N',
    value: '',
    required: false,
    maxLength: 20,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Հավելում/պահում',
    required: true,
    fieldName: 'stName',
    dropdownId: 'stNo',
    optionName: 'salaryTypes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.stName,
        id: i.stNo,
        value: i.stName,
        graphName: i.graphName,
        graphNo: i.graphNo,
      }));
    },
  },
  {
    flexFields: [
      {
        fieldType: 'date',
        dateName: 'setDate',
        dateTitle: 'Նշանակման սկիզբը',
        required: true,
      },
      {
        fieldType: 'date',
        dateName: 'endDate',
        dateTitle: 'Վերջը',
        required: false,
      },
      {
        fieldType: 'input',
        type: 'text',
        name: 'sPercent',
        label: 'Տոկոսը',
        value: '',
        required: false,
        maxLength: 2,
        disabled: (values) => values.graphNo !== 0 || values.sAmount > 0,
      },
      {
        fieldType: 'amount',
        name: 'sAmount',
        label: 'Գումարը',
        value: '',
        required: false,
        disabled: (values) => values.sPercent > 0,
      },
    ],
  },
];
