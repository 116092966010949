import React from 'react';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
import MaterialGroupsForm from './MaterialGroupsForm';
import useMaterialValueGroups from '../../hooks/useMaterialValueGroups';

const EventAddForm = ({
  handleClose,
  params,
  setFieldValue,
  formIsUsedExternally = false,
  customName,
  customId,
}) => {
  const { addNewMaterialGroup, errorMess, setErrorMess } =
    useMaterialValueGroups();
  const isMounted = useIsMounted();

  const onAddHandler = async (values, resetForm) => {
    try {
      await addNewMaterialGroup(values, handleClose, params);
      if (formIsUsedExternally) {
        setFieldValue(customName, values.groupName);
        setFieldValue(customId, values.groupNo);
      }

      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <MaterialGroupsForm onSubmit={onAddHandler} handleClose={handleClose} />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
