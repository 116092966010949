import React, { useState } from 'react';
import { postRequest } from '../../Api/Api';
import { ReactComponent as Done } from '../../Assets/icons/done.svg';
import Loading from '../../Components/Loading/Loading';
import Table from '../../Components/Table/Table';
import Tooltip from '../../Components/Tooltip/Tooltip';
import { Commas } from '../../Utilities/Utilities';
import AccountRevaluation from './AccountRevaluation';

const BalanceBookTable = ({ loading, mainData, getData }) => {
  const [accountRevaluation, setAccountRevaluation] = useState(false);

  const accountReevaluationHandler = (row) => {
    const { currencyCode, accountCode } = row || {};
    if (currencyCode !== 'AMD' && !accountCode.includes('25')) {
      return (
        <p className="partnerAccText">
          Հ/վերագնահատումը գործընկեր. մնացորդների մատյանում
        </p>
      );
    } else if (currencyCode !== 'AMD' && accountCode.includes('25')) {
      return (
        <span
          onClick={() => getAccountRevaluation(row)}
          className="G-account-reevaluation-icon"
        >
          <Done />
        </span>
      );
    } else if (currencyCode === 'AMD') {
      return null;
    }
  };

  const columnConfig = [
    {
      title: 'Հաշիվ',
      cell: (row) => row.accountCode + ' ' + row.accountName,
      customStyle: { maxWidth: 720, overflow: 'hidden' },
    },
    {
      title: <Tooltip content="Դեբետային մնացորդ">Դ/մնացորդ</Tooltip>,
      cell: (row) => Commas(row.balanceAmountDebit),
      customStyle: { maxWidth: 150 },
    },

    {
      title: <Tooltip content="Կրեդիտային մնացորդ">Կ/մնացորդ</Tooltip>,
      cell: (row) => Commas(row.balanceAmountCredit),
      customStyle: { maxWidth: 150 },
    },

    {
      title: 'Դեբետ AMD',
      cell: (row) => Commas(row.balanceAmountDebitAMD),
      customStyle: { maxWidth: 150 },
    },

    {
      title: 'Կրեդիտ AMD',
      cell: (row) => Commas(row.balanceAmountCreditAMD),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Արժույթ',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 90 },
    },

    {
      title: <Tooltip content="Հաշվի վերագնահատում">Հ/վերագնահատում</Tooltip>,
      cell: (row) => accountReevaluationHandler(row),
      customStyle: { maxWidth: 200 },
    },
  ];

  const getAccountRevaluation = (row) => {
    if (
      row.accountCode.substring(-1, 3) === '251' ||
      row.accountCode.substring(-1, 3) ||
      '253' ||
      row.accountCode.substring(-1, 3) ||
      '254'
    ) {
      postRequest('getAccountRevaluation', row)
        .then((res) => {
          setAccountRevaluation(res.data);
        })
        .catch((err) => {
          console.log('err' + err);
        });
    } else {
      setAccountRevaluation(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-balance-book-table G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {accountRevaluation && (
        <AccountRevaluation
          respData={accountRevaluation}
          closeModal={setAccountRevaluation}
          getData={getData}
        />
      )}
    </>
  );
};

export default BalanceBookTable;
