import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Action from '../../../App/Components/Table/Action';
import { tableActionTitle } from '../../../App/Utilities/Utilities';
import { AdditionsActionItems } from '../../Config/tableConfig';
import Forms from './Forms';

const AdditionsTypeTable = ({ mainData }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Կոդը',
      cell: (row) => row.stNo,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Անվանումը',
      cell: (row) => row.stName,
      customStyle: { maxWidth: 900, overflow: 'hidden' },
    },
    {
      title: 'Տաբել',
      cell: (row) => row.graphNo,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Ծախսի հաշիվ',
      cell: (row) => row.tAcc,
      customStyle: { maxWidth: 150 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(value, row) =>
            setShowForm({
              type: value,
              value: row?.salaryTypeID,
            })
          }
          actionItem={AdditionsActionItems(row)}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="G-table-has-scroll"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : null}

      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default AdditionsTypeTable;
