import React from 'react';
import SearchInput from '../../../App/Components/SearchForm/SearchInput';

const SearchBar = ({ searchFiledTitle, query, setQuery, name }) => {
  const onSearchHandler = (e) => {
    const value = e.target.value;
    setQuery(value.trim());
  };

  return (
    <SearchInput
      searchFiledTitle={searchFiledTitle}
      value={query}
      onChange={onSearchHandler}
      name={name}
    />
  );
};

export default SearchBar;
