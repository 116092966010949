import React from 'react';
import PaymentListsBtn from './PaymentListsBtn';
import { ExportExcel } from '../../../App/Components/Excelexport/Excelexport';
const BankPaymentListsBtn = ({
  onExecuteHandler,
  isExecuteDisabled,
  dataToExcel,
  tableData,
}) => {
  const buttonsConfig = [
    {
      text: 'Կատարել',
      onClick: () => onExecuteHandler(),
      type: 'submit',
      disabled: isExecuteDisabled,
    },
  ];
  return (
    <div className="L-bank-paymentList-btn-flex">
      <PaymentListsBtn buttonsConfig={buttonsConfig} className="" />
      {tableData?.length > 0 && (
        <ExportExcel excelData={dataToExcel} fileName="" />
      )}
    </div>
  );
};

export default BankPaymentListsBtn;
