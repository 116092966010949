import React from 'react';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';

import useUnitsMeasure from '../../hooks/useUnitsMeasure';
import { useSelector } from 'react-redux';
import MaterialUnitsMeasureForm from './MaterialUnitsMeasureForm';

const EventAddForm = ({
  handleClose,
  params,
  formIsUsedExternally = false,
  setFieldValue,
}) => {
  const { addUnitsMeasure, errorMess, setErrorMess } = useUnitsMeasure();
  const isMounted = useIsMounted();
  const { currentCompanyID } = useSelector((state) => state.auth);

  const initialValues = {
    companyID: currentCompanyID,
    unitCode: '',
    unitName: '',
  };
  const onAddHandler = async (values, resetForm) => {
    try {
      await addUnitsMeasure(values, handleClose, params);
      if (formIsUsedExternally) {
        setFieldValue('unit', values.unitName);
      }

      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <MaterialUnitsMeasureForm
        initialValues={initialValues}
        onSubmit={onAddHandler}
        handleClose={handleClose}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
